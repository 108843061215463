import { TfHops } from './actionEnum';

const meta = {
  TotalCount: 0,
  PageSize: 0,
  CurrentPage: 0,
  TotalPages: 1
};

const defaultState: any = {
  tfhops: [],
  tfhop: {},
  statuses: [],
  meta,
  loading: 0,
  error: '',
  tfhopComments: [],
  originActionsStats: [],
  tfhopInvalidID: null,
  tfhopDispute: null
};

const reducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case TfHops.GET_TFHOPS:
      return {
        ...state,
        tfhops: action.payload.data || [],
        meta: action.payload.paginationMeta || meta
      };

    case TfHops.GET_TFHOP:
      return { ...state, tfhop: action.payload.data };

    case TfHops.ADD_TFHOP:
      return { ...state };

    case TfHops.EDIT_TFHOP:
      return { ...state, tfhop: action.payload.data };

    case TfHops.GET_TFHOP_COMMENTS:
      return { ...state, tfhopComments: action.payload.data };

    case TfHops.SET_TFHOP_LOADING_STATUS:
      if (action.payload) return { ...state, loading: state.loading + 1 };
      else return { ...state, loading: state.loading === 0 ? state.loading : state.loading - 1 };

    case TfHops.SET_TFHOP_ERROR_MESSAGE:
      return { ...state, error: action.payload };

    case TfHops.GET_TFHOP_STATUSES:
      return { ...state, statuses: action.payload };

    case TfHops.GET_TFHOPS_COUNT:
      return { ...state, tfhopCount: action.payload.data.tfhopCount };

    case TfHops.SET_INVALID_TFHOP_ID:
      return {
        ...state,
        tfhopInvalidId: action.payload
      };

    default:
      return state;
  }
};

export default reducer;
