import React from 'react';
import BaseCard from '../components/BaseCard';

const About = () => {
  return (
    <div className="container-fluid px-md-5">
      <BaseCard containerClassnames="card-join" headerTitle="About the Industry Traceback Group">
        <div className="row p-4">
          <div className="col-lg-6">
            <span className="text-ust-green">What is the Industry Traceback Group?</span>
            <p>
              Since{' '}
              <a
                className="base-link"
                href="https://www.ustelecom.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                USTelecom | The Broadband Association
              </a>{' '}
              established the Industry Traceback Group (ITG) in 2015, the ITG has played a central
              and ever-growing role in the battle against illegal robocalls.{' '}
            </p>
            <p>
              Each day, companies from across the wireline, wireless, VoIP, and cable industries
              collaborate to trace, source, and ultimately, stop illegal robocalls.
            </p>
            <span className="text-ust-green">
              What is the TRACED Act, and how does it relate to the ITG?
            </span>
            <p>
              Congress recognized the ongoing illegal robocall problem and, in December 2019,
              enacted the TRACED Act to further aid enforcement efforts. In the TRACED Act, Congress
              acknowledged the beneficial collaboration between law enforcement agencies and the
              private sector on traceback issues, and required the Federal Communications Commission
              (FCC) to issue rules “for the registration of a single consortium that conducts
              private-led efforts to trace back the origin of suspected unlawful robocalls.” In July
              2020,{' '}
              <a
                className="base-link"
                href="https://www.ustelecom.org/fcc-names-ustelecoms-industry-traceback-group-as-official-robocall-traceback-consortium/"
                target="_blank"
                rel="noopener noreferrer"
              >
                the FCC first designated the ITG as the registered traceback consortium
              </a>
              .
            </p>
            <span className="text-ust-green">Is the ITG a law enforcement agency?</span>
            <p>
              While the ITG works closely with law enforcement agencies, the ITG itself is not a law
              enforcement agency nor is it an agent of law enforcement. The ITG does collaborate
              closely with federal and state law enforcement agencies, including but not limited to
              the FCC, Federal Trade Commission, U.S. Department of Justice, Social Security
              Administration, and states attorneys general in identifying voice service providers
              and their customers responsible for illegal robocalls.
            </p>

            <span className="text-ust-green">
              Do providers need to cooperate with ITG traceback requests?
            </span>
            <p>
              In late 2020, the FCC formally endorsed a mandatory traceback requirement, stating in
              December that all “voice service providers are now legally required to respond to
              traceback requests” from the ITG. The FCC also has indicated that “contractual
              provisions that prohibit, delay, or otherwise interfere with a voice service
              provider's cooperation with private-led traceback efforts are contrary to the spirit
              and goals” of the federal TRACED Act.
            </p>
            <span className="text-ust-green">What governs the ITG's operations?</span>
            <p>
              The ITG is governed by established{' '}
              <a
                className="base-link"
                href="https://tracebacks.org/itg-policies-and-procedures/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Policies and Procedures
              </a>
              . Adherence to the Policies and Procedures fosters cooperation by a broad range of
              supportive industry participants, including incumbent local exchange carriers,
              competitive local exchange carriers, wireless carriers, cable and VoIP providers, and
              wholesale providers, to enhance the trust of voice networks. The Policies and
              Procedures define the ITG's structure, the traceback process, the sources for ITG
              traceback candidates, and how the ITG works with enforcement agencies, among other
              things.
            </p>
            <p>
              More information on the Industry Traceback Group is available{' '}
              <a
                className="base-link"
                href="https://www.tracebacks.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              .
            </p>
          </div>
        </div>
      </BaseCard>
    </div>
  );
};

export default About;
