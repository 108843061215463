import React, { Fragment } from 'react';
import { Label } from 'reactstrap';
import { userTypes } from '../../enum/userTypes';
import { Hop } from '../../interfaces/hop';
import { Traceback } from '../../interfaces/traceback';
import { PersonalInfo } from '../../interfaces/user';
import ProviderPanel from '../ProviderPanel';
import { Notes } from '../shared/Notes';

interface IProps {
  hop: Hop;
  traceback: Traceback;
  user: PersonalInfo;
}

const HopNavigationRow: React.FC<IProps> = ({ hop, traceback, user }) => {
  // Open Hops
  const isOpen = hop.status === 1;
  // Exclude Open or Deleted Hops
  return (
    <div className="d-flex flex-wrap justify-content-between mb-3 ">
      <div className="col">
        <div className="hop-navigation-column">
          {(isOpen || (!isOpen && hop.upstreamProvider)) && (
            <Fragment>
              <label className="telecom-label">Call Received From</label>
              <div style={{ display: 'flex', alignItems: 'left' }}>
                {!hop.upstreamProvider && isOpen ? (
                  <span className="span-cell blue bold ms-2 text-nowrap center">PENDING</span>
                ) : traceback.lastHopId === hop.upstreamHopId ? null : null}
                {hop.upstreamProvider &&
                  hop.upstreamProvider.providerId !== 0 &&
                  (user.roleType === userTypes.Admin ? (
                    <Fragment>
                      <a
                        href={`/Hops/Hop/${hop.upstreamHopId}`}
                        className="span-cell blue me-2 ms-2 text-nowrap"
                      >
                        HOP{` `}
                        {hop.hopSequence + 1}
                      </a>
                      <ProviderPanel
                        provider={hop.upstreamProvider}
                        includeReputation
                        hyperlink
                        rmdFlag={hop.upstreamRMDFlag}
                        excludeEllipses
                      />
                      <div className="text-nowrap">
                        <Notes types={hop.upstreamNotes} />
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div className="me-2 ms-2 text-nowrap">
                        HOP{` `}
                        {hop.hopSequence + 1}
                      </div>
                      <ProviderPanel
                        provider={hop.upstreamProvider}
                        includeReputation
                        rmdFlag={hop.upstreamRMDFlag}
                        excludeEllipses
                      />
                      <div className="text-nowrap">
                        <Notes types={hop.upstreamNotes} />
                      </div>
                    </Fragment>
                  ))}
              </div>
            </Fragment>
          )}
        </div>
      </div>
      <div className="col">
        <div className="hop-navigation-column">
          <Label className="telecom-label text-center">This Hop's Provider</Label>
          <div className="d-flex justify-content-center align-items-center">
            <span className="d-inline-block pe-2 ms-2 text-nowrap align-self-start">
              HOP {hop.hopSequence}
            </span>
            {user.roleType === userTypes.Admin ? (
              <ProviderPanel
                provider={hop.hopProvider}
                hyperlink
                includeReputation
                rmdFlag={hop.rmdFlag}
                excludeEllipses
              />
            ) : (
              <ProviderPanel
                provider={hop.hopProvider}
                linkOverride={`/providers/provider/summaries/${hop.hopProvider.providerId}`}
                includeReputation
                rmdFlag={hop.rmdFlag}
                excludeEllipses
              />
            )}
          </div>
        </div>
      </div>
      <div className="col">
        <div className="hop-navigation-column">
          {hop.hopSequence !== 1 && (
            <Fragment>
              <Label className="telecom-label text-end">Call Sent To</Label>
              <div style={{ display: 'flex', alignItems: 'right', justifyContent: 'flex-end' }}>
                {hop.hopSequence !== 1 && (
                  <Fragment>
                    {user.roleType === userTypes.Admin ? (
                      <Fragment>
                        <a
                          href={`/Hops/Hop/${hop.downstreamHopId}`}
                          className="span-cell blue me-2 ms-2 text-nowrap"
                        >
                          HOP{` `}
                          {hop.hopSequence - 1}
                        </a>
                        <ProviderPanel
                          provider={hop.downstreamProvider}
                          hyperlink
                          includeReputation
                          rmdFlag={hop.downstreamRMDFlag}
                          excludeEllipses
                        />
                      </Fragment>
                    ) : (
                      <Fragment>
                        <div className="me-2 ms-2">
                          HOP{` `}
                          {hop.hopSequence - 1}
                        </div>
                        <ProviderPanel
                          provider={hop.downstreamProvider}
                          includeReputation
                          rmdFlag={hop.downstreamRMDFlag}
                          excludeEllipses
                        />
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default HopNavigationRow;
