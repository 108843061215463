import React, { FC, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import {
  IncidentCampaignTableRow,
  IncidentPhoneNumbers,
  IncidentTableRow,
  PostTracebackIncidentRequest
} from '../../../interfaces/incident';
import { Pagination } from '../../../interfaces/pagination';
import { getIncidentsList } from '../../../redux/incident/thunks';
import { stateMappings } from '../../../redux/stateMappings';
import CustomDataTable from '../../CustomDataTable';
import IncidentsExpandableRow from './IncidentsExpandableRow';
import { getIncidentCampaignAllSearchFilter, getIncidentCampaignSearchColumns } from './utilities';

interface IProps {
  incidentsMap: Map<number, IncidentTableRow[]>;
  data: IncidentCampaignTableRow;
  pagination: Pagination;
  setPagination?: Function;
  paginationTotalRows: number;
  selected?: IncidentPhoneNumbers[];
  toggleRow?: any;
  forAllSources?: boolean;
  transcriptFilter?: string;
  getIncidentsList: Function;
  campaignSelected?: boolean;
  setSelectedIncident?: (inc: PostTracebackIncidentRequest) => void;
}

const IncidentCampaignsSearchTable: FC<IProps> = ({
  incidentsMap,
  data,
  pagination,
  setPagination,
  paginationTotalRows,
  selected,
  toggleRow,
  forAllSources,
  transcriptFilter,
  getIncidentsList,
  campaignSelected,
  setSelectedIncident
}) => {
  const incidentsAllSource = useMemo(
    () =>
      incidentsMap.size && data.incidentCampaignId
        ? incidentsMap.get(data.incidentCampaignId) || []
        : [],
    [incidentsMap, data.incidentCampaignId]
  );

  const columns = useMemo(
    () =>
      getIncidentCampaignSearchColumns(
        data?.campaignId || 0,
        selected ? selected : [],
        toggleRow,
        !!forAllSources
      ),
    [data?.campaignId, selected, toggleRow, forAllSources]
  );

  useEffect(() => {
    if (!forAllSources || (!data.incidentCampaignId && !transcriptFilter)) return;
    const filter = getIncidentCampaignAllSearchFilter(
      data.incidentCampaignId,
      transcriptFilter || ''
    );
    getIncidentsList(pagination, filter, data?.incidentCampaignId);
  }, [data.incidentCampaignId, transcriptFilter, forAllSources]);

  return (
    <div className={`${forAllSources ? 'p-5' : ''}`}>
      <CustomDataTable
        tableData={incidentsAllSource}
        columns={columns}
        defaultSortFieldId={pagination.sort}
        defaultSortAsc={pagination.order === 'asc'}
        defaultPage={pagination.page}
        defaultPageSize={pagination.pageSize}
        useExpandableRows={true}
        expandableRowExpanded={() => true}
        expandableRowsHideExpander={true}
        pagination={!forAllSources}
        paginationTotalRows={paginationTotalRows}
        updatePaginationParams={setPagination}
        useExpandableRowsComponent={({ data }) => (
          <IncidentsExpandableRow
            data={data}
            campaignSelected={campaignSelected}
            setSelectedIncident={setSelectedIncident}
          />
        )}
      />

      {forAllSources && (
        <div className="mt-3 me-5">
          <a
            href={'/admin-dashboard#incidents-' + data?.sourceName + '-' + data?.incidentCampaignId}
          >
            {`See More >>`}
          </a>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    incidentsMap: new Map<number, IncidentTableRow[]>(sm.incident.incidents),
    paginationTotalRows: sm.incident.meta.TotalCount
  };
};

const mapActionsToProps = {
  getIncidentsList
};

export default connect(mapStateToProps, mapActionsToProps)(IncidentCampaignsSearchTable);
