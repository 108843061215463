import axios from 'axios';

import {
  ChartFilterElements,
  ChartFilterElementsForProviderSummary
} from '../../interfaces/charts';
import { Hop } from '../../interfaces/hop';
import { Pagination } from '../../interfaces/pagination';
import { FilterElement } from '../../lib/FilterElement';
import { logoutOnAuthError } from '../../lib/apiRequest';
import { downloadFile } from '../../lib/utilities';
import { getZeroBounceResponse } from '../zeroBounce/action';
import {
  getDisputeAction,
  getHop,
  getHopComments,
  getHopStatuses,
  getHops,
  getHopsCount,
  getHopsCountriesAction,
  getOriginStatsAction,
  setErrorMessage,
  setInvalidHopId,
  setLoadingStatus
} from './actions';
import {
  deleteHopApiCall,
  getAllHopsApiCall,
  getCSVApiCall,
  getHopApiCall,
  getHopCommentsApiCall,
  getHopDisputeApiCall,
  getHopStatusApiCall,
  getHopsCountApiCall,
  getHopsOrProvidersCountriesApiCall,
  getOriginStatsApiCall,
  resetHopApiCall,
  updateHopApiCall
} from './apiCalls';

const setStatus = (status: boolean, message: string) => async (dispatch: any) => {
  dispatch(setErrorMessage(message));
  dispatch(setLoadingStatus(status));
};

export const updateHopObject = (params: Hop) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  const postData = {
    ...params,
    added: params.added,
    completed: undefined,
    create_date: undefined,
    notified: undefined,
    update_date: undefined
  };

  try {
    const response = await updateHopApiCall(postData);
    if (response && response.data) {
      const data = params as any;
      if (data.dispute) dispatch(getDisputeAction(data.dispute));
      dispatch(getHop(response.data));
      dispatch(getZeroBounceResponse(response.data.zeroBounceResponse));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const resetHopObject = (hopId: number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    const response = await resetHopApiCall(hopId);
    //TODO: Figure out why the frontend gets a 400 response even though the backend sends a 200
    if (response && response.data) {
      dispatch(getHop(response.data));
      window.location.reload();
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    window.location.reload();
    logoutOnAuthError(error);
  }
};

export const getAllHopsList =
  (params: Pagination, filterElements: FilterElement) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    try {
      const hops = await getAllHopsApiCall(params, filterElements);

      if (hops && hops.data) {
        dispatch(getHops(hops.data));
      }
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      if (!axios.isCancel(error)) logoutOnAuthError(error as any);
    }
  };

export const getHopObject = (hopId: number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    const hop = await getHopApiCall(hopId);
    if (hop && hop.data) {
      dispatch(getHop(hop.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    if (error.response && error.response.status === 404) {
      dispatch(setStatus(false, error.response.status.toString()));
      dispatch(setInvalidHopId(hopId));
    } else {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  }
};

export const getHopStatusList = () => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    const hopStatuses = await getHopStatusApiCall();
    if (hopStatuses && hopStatuses.data) {
      dispatch(getHopStatuses(hopStatuses.data.data));
      dispatch(setStatus(false, ''));
    }
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const getHopsCountries = (filterElements: ChartFilterElements) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  try {
    const countries = await getHopsOrProvidersCountriesApiCall(filterElements, true);
    if (countries && countries.data) {
      dispatch(getHopsCountriesAction(countries.data));
    } else {
      dispatch(getHopsCountriesAction([]));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const deleteHop = (hopId: number, downstreamHopId: number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    await deleteHopApiCall(hopId);
    dispatch(setStatus(false, ''));
    window.location.href = `/hops/hop/${downstreamHopId}`;
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const getHopCommentsList = (hopId: number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    const comments = await getHopCommentsApiCall(hopId);
    if (comments && comments.data) {
      dispatch(getHopComments(comments.data));
    }
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const getHopsCountNumber = (filterElements: FilterElement) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    const hopCount = await getHopsCountApiCall(filterElements);
    if (hopCount) {
      dispatch(getHopsCount(hopCount));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const getOriginStats =
  (filterElements: ChartFilterElementsForProviderSummary) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    try {
      const actionOriginStats = await getOriginStatsApiCall(filterElements);
      if (actionOriginStats && actionOriginStats.data) {
        dispatch(getOriginStatsAction(actionOriginStats.data.data));
      }
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };

export const getHopsCSV =
  (filterElements: FilterElement, params: Pagination) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    try {
      const response = await getCSVApiCall(filterElements, params);
      downloadFile(response.data, response.headers.contentfilename);
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };

export const getHopDisputeObject = (hopId: number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    const dispute = await getHopDisputeApiCall(hopId);
    if (dispute && dispute.data) {
      dispatch(getDisputeAction(dispute.data));
    }
  } catch (error: any) {
    dispatch(getDisputeAction(null));
  }
  dispatch(setStatus(false, ''));
};
