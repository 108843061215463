import React, { FormEventHandler, Fragment } from 'react';
import { FormGroup, Input, InputGroup, InputGroupText } from 'reactstrap';
import { validUrlRegex } from '../lib/regex';
import InputError from './inputError';

interface IProps {
  containerClassName?: string;
  isPassword?: boolean;
  isTextarea?: boolean;
  isReadonly?: boolean;
  inputName?: string;
  inputId?: string;
  inputClassName?: string;
  inputPlaceholder?: string;
  inputValue?: string;
  inputOnChange?: FormEventHandler<HTMLInputElement>;
  inputAutoComplete?: string;
  isAppend?: boolean;
  inputIcon?: string;
  inputErrorClassName?: string;
  errorMessage?: any;
  invalid?: boolean;
  valid?: boolean;
  disabled?: boolean;
  label?: string | React.ReactNode;
  isRequired?: boolean;
}

const InputFormGroup: React.FC<IProps> = ({
  containerClassName,
  isPassword,
  isTextarea,
  isReadonly,
  inputName,
  inputId,
  inputClassName,
  inputPlaceholder,
  inputValue,
  inputOnChange,
  inputAutoComplete,
  isAppend,
  inputIcon,
  inputErrorClassName,
  errorMessage,
  invalid,
  valid,
  disabled,
  label,
  isRequired
}) => {
  return (
    <Fragment>
      <FormGroup className={containerClassName}>
        {label && (
          <label className="telecom-label">
            {label}
            {isRequired && <i className="fa fa-asterisk asterisk" />}
          </label>
        )}
        <InputGroup>
          {inputIcon && !isAppend && (
            <InputGroupText>
              <i
                className={`fa fa-${inputIcon}`}
                style={{ color: !errorMessage ? '#06335d' : 'red' }}
              />
            </InputGroupText>
          )}
          {isReadonly && !isPassword ? (
            validUrlRegex.test(inputValue || '') ? (
              <a className="telecom-text mb-0" style={{ padding: '5px 8px' }} href={inputValue}>
                {inputValue}
              </a>
            ) : (
              <p className="telecom-text mb-0" style={{ padding: '5px 8px' }}>
                {inputValue || 'None'}
              </p>
            )
          ) : (
            <Input
              type={isPassword ? 'password' : isTextarea ? 'textarea' : 'text'}
              name={inputName}
              className={`${inputClassName} ${!errorMessage ? '' : 'control-error'}`}
              id={inputId}
              placeholder={inputPlaceholder}
              value={inputValue || ''}
              onChange={inputOnChange}
              autoComplete={inputAutoComplete}
              invalid={invalid}
              valid={valid}
              disabled={disabled}
              readOnly={isReadonly}
            />
          )}

          {inputIcon && isAppend && (
            <InputGroupText>
              <i
                className={`fa fa-${inputIcon}`}
                style={{ color: !errorMessage ? '#06335d' : 'red' }}
              />
            </InputGroupText>
          )}
        </InputGroup>
      </FormGroup>
      {errorMessage && (
        <InputError className={`${inputErrorClassName} my-1 mx-0`}>{errorMessage}</InputError>
      )}
    </Fragment>
  );
};

export default InputFormGroup;
