import {
  GET_ACCESSLOGS,
  SET_ACCESSLOG_ERROR_MESSAGE,
  SET_ACCESSLOG_LOADING_STATUS
} from './actionEnum';

export const getAccessLogs = (response: any) => ({
  type: GET_ACCESSLOGS,
  payload: response
});

export const setLoadingStatus = (candidate: boolean) => ({
  type: SET_ACCESSLOG_LOADING_STATUS,
  payload: candidate
});

export const setErrorMessage = (message: string) => ({
  type: SET_ACCESSLOG_ERROR_MESSAGE,
  payload: message
});
