import { govFollower } from './actionEnum';

export const getAllFolowersAction = (followers: any) => ({
  type: govFollower.GET_ALL_FOLOWERS,
  payload: followers
});
export const getAllCampaignFolowersAction = (campaignFollowers: any) => ({
  type: govFollower.GET_ALL_CAMPAIGN_FOLLOWERS,
  payload: campaignFollowers
});

export const getAllFolowersSummariesAction = (followersSummaries: any) => ({
  type: govFollower.GET_ALL_FOLOWERS_SUMMARIES,
  payload: followersSummaries
});
export const getAllCampaignFolowersSummariesAction = (campaignFollowersSummaries: any) => ({
  type: govFollower.GET_ALL_CAMPAIGN_FOLOWERS_SUMMARIES,
  payload: campaignFollowersSummaries
});

export const setHomePageLoadingStatus = (message: boolean) => ({
  type: govFollower.SET_HOMEPAGE_LOADING_STATUS,
  payload: message
});

export const setHomePageErrorMessage = (message: string) => ({
  type: govFollower.SET_HOMEPAGE_ERROR_MESSAGE,
  payload: message
});
