import { ApiRequest, buildParams } from '../../lib/apiRequest';
import { Pagination } from '../../interfaces/pagination';
import { DNO } from '../../interfaces/dno';

export const getAllDnoApi = async (params: Pagination, phoneNumber?: string) =>
  ApiRequest.request({
    method: 'POST',
    url: `/dno/search?${buildParams({
      ...params
    })}`,
    data: { phoneNumber }
  });

export const getDnoApi = async (id: number) =>
  ApiRequest.request({
    method: 'GET',
    url: `/dno/entry/${id}`
  });

export const newDnoApi = async (dno: DNO) =>
  ApiRequest.request({
    method: 'POST',
    url: '/dno/new',
    data: { ...dno }
  });

export const updateDnoApi = async (id: number, dno: DNO) =>
  ApiRequest.request({
    method: 'POST',
    url: `/dno/entry/${id}`,
    data: { ...dno }
  });

export const downloadDnosCsvApi = async () =>
  ApiRequest.request({
    method: 'GET',
    url: '/dno/download'
  });

export const getLoaAttachmentApi = async (id: string) =>
  ApiRequest.request({
    method: 'GET',
    url: `/dno/loa/${id}`
  });

export const associateLoaApi = async (id: string, attachments: FormData) =>
  ApiRequest.request({
    method: 'POST',
    url: `/dno/loa/${id}`,
    data: attachments,
    headers: {
      'Content-type': 'multipart/form-data'
    }
  });
