import React, { Fragment, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { ExtraNoteType, Note } from './Notes';
import { stateMappings } from '../../redux/stateMappings';
import { connect } from 'react-redux';
import { getClientConfigurationInfo } from '../../redux/appinfo/thunks';
import { Configuration } from '../../interfaces/configuration';
import { NoteType } from '../../interfaces/hop';

interface IProps {
  configuration: Configuration;
  getClientConfigurationInfo: Function;
}

const Legend: React.FC<IProps> = ({ configuration, getClientConfigurationInfo }) => {
  useEffect(() => {
    getClientConfigurationInfo();
  }, []);
  const { reputationThresholds } = configuration;
  return (
    <div className="hop-legend">
      <div className="row">
        <p className="mb-0" style={{ fontWeight: 'bold' }}>
          LEGEND
        </p>
      </div>
      <div className="row">
        <div className="col-6 col-md-4">
          <p className="mb-0">
            <Note type={ExtraNoteType.UsItg} printInfo />
          </p>
          <p className="mb-0">
            <Note type={ExtraNoteType.NonUsItg} printInfo />
          </p>
          <p className="mb-0">
            <Note type={ExtraNoteType.UsNonItg} printInfo />
          </p>
          <p className="mb-0">
            <Note type={ExtraNoteType.NonUsNonItg} printInfo />
          </p>
        </div>
        <div className="col-6 col-md-4">
          <p className="mb-0">
            <Note type={NoteType.OriginHop} printInfo />
          </p>
          <p className="mb-0">
            <Note type={NoteType.NotFoundHop} printInfo />
          </p>
          <p className="mb-0">
            <Note type={NoteType.InternationOriginHop} printInfo />
          </p>
          <p className="mb-0">
            <Note type={NoteType.ForeignPointDepartureHop} printInfo />
          </p>

          <p className="mb-0">
            <Note type={NoteType.NoResponseHop} printInfo />
          </p>
          <p className="mb-0">
            <Note type={NoteType.USPointOfEntryHop} printInfo />
          </p>
          <p className="mb-0">
            <Note type={NoteType.RejectDispute} printInfo hideToolTip />
          </p>
          <p className="mb-0">
            <Note type={NoteType.StrikeExemptProvider} printInfo hideToolTip />
          </p>
        </div>
        <div className="col-12 col-md-4">
          {reputationThresholds &&
            reputationThresholds.map((reputationThreshold, index) => (
              <Fragment key={`${reputationThreshold.reputationThresholdId}-reputation-${index}`}>
                <p className="mb-0">
                  <i
                    className={`fa ${
                      reputationThreshold.reputationThresholdId === 2
                        ? 'fa-exclamation-triangle'
                        : 'fa-stop-circle orange'
                    }`}
                    style={{ color: reputationThreshold.symbolColor, paddingRight: '5px' }}
                  ></i>
                  <span>{reputationThreshold.name}</span>
                </p>
              </Fragment>
            ))}
          <p className="mb-0">
            <span className="span-cell red">RMD</span>
            <span> : Not/incorrectly listed in Robocall Mitigation Database</span>
          </p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return {
    configuration: sm.appinfo.configuration
  };
};

const mapActionsToProps = {
  getClientConfigurationInfo
};

export default connect(mapStateToProps, mapActionsToProps)(Legend);
