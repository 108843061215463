import React, { Children, FC, Fragment, useState } from 'react';
import { Button, ListGroup, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { SelectOption } from './CustomSelect';
import InputFormGroup from './inputFormGroup';

export interface IExtraProps<T = any> {
  value?: T;
  setValue: (v?: T) => void;
}

interface IProps<T = any> {
  id: string;
  buttonText: string;
  popoverHeaderMessage: string;
  popoverAddButtonMessage: string;
  options: Array<SelectOption>;
  setOptions: (opts: Array<SelectOption>) => void;
  activeOptions: Array<SelectOption>;
  addOrRemoveOption: (opt: SelectOption) => void;
  optionServer: (option: string, extra?: T) => boolean;
  radioType?: boolean;
  inputPlaceholder?: string;
  extraInputsComponent?: FC<IExtraProps<T>>;
}

const CustomPopover = <T extends any>({
  id,
  buttonText,
  popoverHeaderMessage,
  popoverAddButtonMessage,
  options,
  setOptions,
  activeOptions,
  addOrRemoveOption,
  optionServer,
  radioType = false,
  inputPlaceholder,
  extraInputsComponent
}: IProps<T>) => {
  const [openPopover, setOpenPopover] = useState(false);
  const [optionsName, setOptionsName] = useState('');
  const [currentActiveOptions, setCurrentActiveOptions] = useState(activeOptions);
  const [error, setError] = useState('');

  const [extraInputs, setExtraInputs] = useState<T>();

  const togglePopover = () => setOpenPopover((prev) => !prev);

  const initializeInputOptions = () => {
    setOptionsName('');
    setError('');
    setExtraInputs(undefined);
  };

  const handleActiveOptionsChange = () => {
    setOptions(currentActiveOptions);
    setOpenPopover(false);

    initializeInputOptions();
  };

  const handleCancelChanges = () => {
    setCurrentActiveOptions(activeOptions);
    setOpenPopover(false);

    initializeInputOptions();
  };

  const handleAddNewOption = () => {
    setError(
      !optionsName
        ? 'Please provide an option name.'
        : (options || []).map((option) => option.label).includes(optionsName)
          ? 'Duplicate name.'
          : ''
    );

    //can't use if error !== '' because setState is async
    if (
      (!optionsName
        ? 'Please provide an option name.'
        : (options || []).map((option) => option.label).includes(optionsName)
          ? 'Duplicate name.'
          : '') === ''
    ) {
      if (optionServer(optionsName, extraInputs)) initializeInputOptions();
    }
  };

  const handleAddOrRemoveOption = (option: SelectOption) => {
    const options = currentActiveOptions.map((item) => item.value);

    setCurrentActiveOptions(
      options.includes(option.value)
        ? currentActiveOptions.filter((item) => item.value !== option.value)
        : [...currentActiveOptions, option]
    );
  };

  return (
    <Fragment>
      {Children.toArray(
        activeOptions &&
          activeOptions.map((activeOption: SelectOption) => (
            <p className="telecom-text mb-0">
              {activeOption.label}{' '}
              <i
                className="fa fa-close"
                style={{ color: 'red', cursor: 'pointer' }}
                onClick={() => {
                  addOrRemoveOption(activeOption);
                  handleAddOrRemoveOption(activeOption);
                }}
              ></i>
            </p>
          ))
      )}

      <Button id={id} className="telecom-btn mt-1" onClick={togglePopover} type="button">
        {buttonText}
      </Button>

      <Popover
        placement="bottom"
        target={id}
        isOpen={openPopover}
        toggle={togglePopover}
        trigger="click"
      >
        <PopoverHeader className="mb-3">
          {popoverHeaderMessage}
          <button
            style={{ float: 'right', background: 'transparent', border: 0 }}
            onClick={togglePopover}
          >
            <i className="fa fa-close" />
          </button>
        </PopoverHeader>

        <PopoverBody>
          <ListGroup className="telecom-list-group">
            {Children.toArray(
              options &&
                options.map((option) => (
                  <ul className="mb-1 p-0">
                    <li style={{ listStyle: 'none' }}>
                      <label className="form-label source-label">
                        {option.label}
                        <input
                          type={radioType ? 'radio' : 'checkbox'}
                          checked={
                            radioType
                              ? currentActiveOptions[0] &&
                                option &&
                                currentActiveOptions[0].value === option.value
                              : [...currentActiveOptions]
                                  .map((item) => item.value)
                                  .includes(option.value)
                          }
                          onChange={() =>
                            radioType
                              ? setCurrentActiveOptions([option])
                              : handleAddOrRemoveOption(option)
                          }
                        />
                        <span className="checkmark"></span>
                      </label>
                    </li>
                  </ul>
                ))
            )}
          </ListGroup>
          <InputFormGroup
            isReadonly={false}
            inputName="custom-popover-name"
            inputClassName="input-campaign"
            inputValue={optionsName}
            inputOnChange={(e) => setOptionsName(e.currentTarget.value)}
            inputPlaceholder={inputPlaceholder}
            inputAutoComplete="off"
            errorMessage={error}
          />
          {extraInputsComponent &&
            extraInputsComponent({
              value: extraInputs,
              setValue: setExtraInputs
            })}
          <Button
            style={{ padding: '5px', height: '40px' }}
            outline
            color="success"
            onClick={() => handleAddNewOption()}
          >
            {popoverAddButtonMessage}
          </Button>
          <div className="d-flex flex-row justify-content-between m-0">
            <Button
              color="light"
              style={{ minWidth: '100px', height: '40px', padding: '5px' }}
              onClick={handleCancelChanges}
            >
              Cancel
            </Button>
            <Button
              style={{ minWidth: '100px', height: '40px', padding: '5px' }}
              onClick={handleActiveOptionsChange}
            >
              OK
            </Button>
          </div>
        </PopoverBody>
      </Popover>
    </Fragment>
  );
};

export default CustomPopover;
