import { User } from './actionEnum';

interface Pagination {
  TotalCount: number;
  PageSize: number;
  CurrentPage: number;
  TotalPages: number;
}

interface UserReducerState {
  users: any[];
  accessFailedCount: number;
  companyName: string;
  email: string;
  emailConfirmed: boolean;
  id: number;
  isActive: boolean;
  isEscalationEmail: boolean;
  isTBEmail: boolean;
  name: string;
  providerId: number;
  roleType: number;
  twoFactorEnabled: boolean;
  userName: string;
  params: {
    Filters: string;
    Sort: string;
    Page: number;
    PageSize: number;
  };
  userId: number;
  loading: number;
  error: string;
  redirectLocation: string;
  resetPasswordError: boolean;
  meta: Pagination;
}

const meta: Pagination = {
  TotalCount: 0,
  PageSize: 0,
  CurrentPage: 0,
  TotalPages: 1
};

const defaultState: UserReducerState = {
  users: [],
  userId: 0,
  accessFailedCount: 0,
  companyName: '',
  email: '',
  emailConfirmed: false,
  id: 0,
  isActive: false,
  isEscalationEmail: false,
  isTBEmail: false,
  name: '',
  providerId: 0,
  roleType: 0,
  twoFactorEnabled: false,
  userName: '',
  params: {
    Filters: '',
    Sort: '',
    Page: 0,
    PageSize: 0
  },
  loading: 0,
  error: '',
  redirectLocation: '',
  resetPasswordError: false,
  meta
};

const reducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case User.GET_USERS:
      return {
        ...state,
        users: action.payload.data,
        meta: action.payload.paginationMeta || meta,
        tbStats: action.payload.tbStats
      };

    case User.RESET_PASSWORD_ERROR:
      return { ...state, resetPasswordError: action.payload };

    case User.ADD_USER:
      return { ...state };

    case User.SET_USER_ID:
      return {
        ...state,
        userId: action.payload.id
      };

    case User.SET_USER_LOADING_STATUS:
      if (action.payload) return { ...state, loading: state.loading + 1 };
      else return { ...state, loading: state.loading === 0 ? state.loading : state.loading - 1 };

    case User.SET_USER_ERROR_MESSAGE:
      return { ...state, error: action.payload };

    case User.GET_REDIRECT_LOCATION:
      return { ...state, redirectLocation: action.payload };

    default:
      return state;
  }
};

export default reducer;
