import { Campaign } from './actionEnum';

export const getCampaigns = (response: any) => ({
  type: Campaign.GET_CAMPAIGNS,
  payload: response
});

export const getCampaign = (response: any) => ({
  type: Campaign.GET_CAMPAIGN,
  payload: response
});
export const getCampaignLabelsStats = (response: any) => ({
  type: Campaign.GET_CAMPAIGN_LABELS_STATS,
  payload: response
});

export const addCampaign = (response: any) => ({
  type: Campaign.ADD_CAMPAIGN,
  payload: response
});

export const editCampaign = (response: any) => ({
  type: Campaign.EDIT_CAMPAIGN,
  payload: response
});

export const setCampaignAction = (response: any) => ({
  type: Campaign.SET_CAMPAIGN,
  payload: response
});

export const getAttachments = (response: any) => ({
  type: Campaign.GET_ATTACHMENTS,
  payload: response
});

export const addAttachments = (response: any) => ({
  type: Campaign.ADD_ATTACHMENTS,
  payload: response
});

export const getSources = (response: any) => ({
  type: Campaign.GET_SOURCES,
  payload: response
});

export const getLabels = (response: any) => ({
  type: Campaign.GET_LABELS,
  payload: response
});

export const addSource = (response: any) => ({
  type: Campaign.ADD_SOURCE,
  payload: response
});

export const addLabel = (response: any) => ({
  type: Campaign.ADD_LABEL,
  payload: response
});

export const setLoadingStatus = (candidate: boolean) => ({
  type: Campaign.SET_CAMPAIGN_LOADING_STATUS,
  payload: candidate
});

export const setErrorMessage = (message: string) => ({
  type: Campaign.SET_CAMPAIGN_ERROR_MESSAGE,
  payload: message
});

export const getLanguagesCampaignAction = (languageList: string[]) => ({
  type: Campaign.GET_CAMPAIGN_LANGUAGES,
  payload: languageList
});
