import React from 'react';

interface IProps {
  className?: string;
  style?: object;
  children?: React.ReactNode;
}

const InputError: React.FC<IProps> = ({ children, className, style }: IProps) => {
  return (
    <p className={'input-error ' + className || ''} style={style || {}}>
      <span className="text-fadein">{children}</span>
    </p>
  );
};
export default InputError;
