import { Pagination } from '../../interfaces/pagination';
import { FilterElement } from '../../lib/FilterElement';
import { ApiRequest, buildParams } from '../../lib/apiRequest';

export const getPartnersApiCall = async (params: Pagination, filterElement: FilterElement) =>
  await ApiRequest.request({
    method: 'POST',
    url: `/partners?${buildParams({ ...params })}`,
    data: filterElement
  });

export const getPartnerTbStatsApiCall = async (params: Pagination, filterElement: FilterElement) =>
  await ApiRequest.request({
    method: 'POST',
    url: `/partners/stats?${buildParams({ ...params })}`,
    data: filterElement
  });

export const getPartnerTfStatsApiCall = async (params: Pagination, filterElement: FilterElement) =>
  await ApiRequest.request({
    method: 'POST',
    url: `/partners/tfstats?${buildParams({ ...params })}`,
    data: filterElement
  });

export const getAllPartnersApiCall = async () =>
  await ApiRequest.request({
    method: 'GET',
    url: `/partners`
  });

export const getPartnerApiCall = async (id: number, params: Pagination) =>
  await ApiRequest.request({
    method: 'GET',
    url: `/partners/stats/${id}?${buildParams({ ...params })}`
  });

export const getAllPartnerStatsApiCall = async (id: number, params: Pagination) =>
  await ApiRequest.request({
    method: 'GET',
    url: `/partners/events/${id}?${buildParams({ ...params })}`
  });

export const createPartnerEventApiCall = async (
  requestorId: number,
  tracebacks: number[],
  traceforwards: number[]
) =>
  await ApiRequest.request({
    method: 'POST',
    url: `/partners/events`,
    data: {
      requestorId,
      tracebacks,
      traceforwards
    }
  });

export const updateEventApiCall = async (
  eventId: number,
  tracebackIds: number[],
  traceforwardIds: number[]
) =>
  await ApiRequest.request({
    method: 'PUT',
    url: `/partners/events/${eventId}`,
    data: {
      tracebackIds,
      traceforwardIds
    }
  });

export const deleteEventApiCall = async (eventId: number) =>
  await ApiRequest.request({
    method: 'DELETE',
    url: `/partners/events/${eventId}`
  });

export const getTfPartnerApiCall = async (id: number, params: Pagination) =>
  await ApiRequest.request({
    method: 'GET',
    url: `/partners/tfstats/${id}?${buildParams({ ...params })}`
  });

export const getTfAllPartnerStatsApiCall = async (id: number, params: Pagination) =>
  await ApiRequest.request({
    method: 'GET',
    url: `/partners/tfevents/${id}?${buildParams({ ...params })}`
  });
