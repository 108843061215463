import React, { Fragment, useEffect, useMemo } from 'react';

import { connect } from 'react-redux';
import { getAverageDailyTrafficStats } from '../../redux/provider/thunks';
import { stateMappings } from '../../redux/stateMappings';

import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

import { ChartFilterElementsForProviderSummary } from '../../interfaces/charts';

import {
  dataCallerCustomerAndAverageDailyTraffic,
  setChartLegendPosition
} from '../../lib/chart/data';
import { CardTitle, Col } from 'reactstrap';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  BarController,
  LineController
);

const options = {
  plugins: {
    title: {
      display: false,
      text: 'Caller Customer Type / Average Daily Traffic'
    },
    legend: {}
  },
  responsive: true,
  scales: {
    x: {
      stacked: true
    },
    y: {
      stacked: true
    }
  }
};

interface CustomerCountriesStats {
  [index: string]: string;
}
interface CustomerEmailsStats {
  countTotalContactEmails: number;
  countInvalidEmails: number;
}

interface IProps {
  averageDailyTrafficStats: Object;
  customerEmailsStats: CustomerEmailsStats;
  customerCountriesStats: CustomerCountriesStats;
  getAverageDailyTrafficStats: Function;
  providerId: number;
  chartFilters: ChartFilterElementsForProviderSummary;
  height?: number;
  width?: number;
  legendPosition: string;
}

const CallerCustomerAndAverageDailyTrafficChart: React.FC<IProps> = ({
  averageDailyTrafficStats,
  customerEmailsStats,
  customerCountriesStats,
  getAverageDailyTrafficStats,
  providerId,
  chartFilters,
  height,
  legendPosition
}) => {
  const dataChart = dataCallerCustomerAndAverageDailyTraffic(averageDailyTrafficStats);
  const displayChart = useMemo(
    () =>
      (dataChart && dataChart.datasets[0].data.length > 0) ||
      (customerCountriesStats && Object.keys(customerCountriesStats).length > 0) ||
      (customerEmailsStats && customerEmailsStats.countInvalidEmails),
    [dataChart, customerCountriesStats, customerEmailsStats]
  );

  useEffect(() => {
    getAverageDailyTrafficStats(chartFilters, providerId);
  }, [
    providerId,
    chartFilters.labelId,
    chartFilters.note,
    chartFilters.termState,
    chartFilters.startDate,
    chartFilters.endDate
  ]);

  return (
    <Fragment>
      {displayChart && (
        <div className="col-xl-8">
          <CardTitle tag="h4">Caller Customer Type / Average Daily Traffic</CardTitle>
          <div>
            {dataChart && dataChart.datasets[0].data.length > 0 ? (
              <Chart
                type="bar"
                options={setChartLegendPosition(legendPosition, options)}
                data={dataChart}
                height={height}
              />
            ) : (
              <p>No Data Reported by Provider</p>
            )}
          </div>
          <div className="pt-3 ps-2">
            <h6>Customer Countries</h6>
            {customerCountriesStats && Object.keys(customerCountriesStats).length > 0 ? (
              <div className={Object.keys(customerCountriesStats).length > 3 ? 'addScroll' : ''}>
                {Object.keys(customerCountriesStats).map((itemKey: string, index) => (
                  <p key={index} className="m-0">
                    {itemKey} ({customerCountriesStats[itemKey]})
                  </p>
                ))}
              </div>
            ) : (
              <p style={{ fontSize: '14px' }}>No Data Available</p>
            )}
          </div>
          <div className="pt-3 ps-2">
            <h6>Customer Emails</h6>
            {customerEmailsStats && customerEmailsStats.countInvalidEmails ? (
              <p className="m-0">
                {Number.parseFloat(
                  (
                    (customerEmailsStats.countInvalidEmails * 100) /
                    customerEmailsStats.countTotalContactEmails
                  ).toString()
                ).toFixed()}
                % of customer emails were invalid
              </p>
            ) : (
              <p style={{ fontSize: '14px' }}>No Data Available</p>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    user: sm.user,
    averageDailyTrafficStats:
      sm.provider.providerAverageDailyTrafficStats.CallerCustomerAndAverageDailyTraffic,
    customerEmailsStats: sm.provider.providerAverageDailyTrafficStats.InvalidCustomerEmails,
    customerCountriesStats: sm.provider.providerAverageDailyTrafficStats.CustomerCountries
  };
};

const mapActionsToProps = {
  getAverageDailyTrafficStats
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(CallerCustomerAndAverageDailyTrafficChart);
