import React, { Fragment, useState } from 'react';
import { Button } from 'reactstrap';
import { DateFormat } from '../../enum/DateFormat';
import { userTypes } from '../../enum/userTypes';
import { TfHop } from '../../interfaces/tfhop';
import { Traceforward } from '../../interfaces/traceforward';
import { PersonalInfo } from '../../interfaces/user';
import { getClientFormattedDate, getElapsedTime } from '../../lib/utilities';
import ColumnTooltip from '../ColumnTooltip';
import { HoverCardArrow, HoverCardContent, HoverCardRoot, HoverCardTrigger } from '../HoverCard';

interface IProps {
  tfhop: TfHop;
  traceforward: Traceforward;
  user: PersonalInfo;
  statuses: { [key: number]: string };
  updateTfHopObject: Function;
}

const TfHopDetailsRow: React.FC<IProps> = ({
  tfhop,
  traceforward,
  user,
  statuses,
  updateTfHopObject
}) => {
  // Open Hops
  const isOpen = tfhop.status === 1;
  const hopStatus = statuses ? statuses[tfhop.status] : '';

  const [releaseTip, setReleaseTip] = useState(!localStorage.getItem('releaseTip'));

  const claimHop = () => {
    updateTfHopObject({
      tfhopId: tfhop.tfhopId,
      delegatedTo: user.email
    });
  };

  const releaseHop = () => {
    updateTfHopObject({
      tfhopId: tfhop.tfhopId,
      delegatedTo: 'Pending'
    });
  };

  const handleReleaseTooltipBtnClick = () => {
    localStorage.setItem('releaseTip', 'SET');
    setReleaseTip(false);
  };

  return (
    <Fragment>
      <h5 className="p-0 traceforward-sub-title mb-3">Hop Details</h5>
      <div className="d-flex flex-row justify-content-around mb-3">
        <div className="ms-3">
          <label className="telecom-label">Status</label>
          {hopStatus === 'DELETED' && <span className="span-cell red bold">{hopStatus}</span>}
          {hopStatus === 'NOT FOUND' && <span className="span-cell pink bold">{hopStatus}</span>}
          {hopStatus === 'COMPLETED' && <span className="span-cell green bold">{hopStatus}</span>}
          {hopStatus === 'NO RESPONSE' && (
            <span className="span-cell light-blue bold">{hopStatus}</span>
          )}
          {hopStatus === 'OPEN' && <span className="span-cell blue bold">{hopStatus}</span>}
          {hopStatus === 'PENDING APPROVAL' && (
            <Fragment>
              {user.roleType === userTypes.Admin ? (
                <span className="span-cell bold" style={{ color: '#209FAB' }}>
                  {hopStatus}
                </span>
              ) : (
                <span className="span-cell green bold">COMPLETED</span>
              )}
            </Fragment>
          )}
          {hopStatus === 'PENDING DISPUTE' && (
            <span className="span-cell blue bold">{hopStatus}</span>
          )}
        </div>
        {user.roleType === userTypes.Admin && (
          <div>
            <label className="telecom-label">Hops To Date</label>
            <span
              className="span-cell blue bold"
              style={{
                fontWeight: 'bold',
                paddingLeft: '40px'
              }}
            >
              {traceforward.numberOfTfHops}
            </span>
          </div>
        )}
        <div style={{ cursor: 'default' }}>
          {!isOpen ? (
            <span>
              <label className="telecom-label">Information Provided By</label>
              <span>{tfhop.delegatedTo}</span>
            </span>
          ) : (
            <span>
              <label className="telecom-label">Information Provided By</label>
              {tfhop.delegatedTo ? tfhop.delegatedTo : `Pending`}
              <span style={{ color: '#0f72b1', cursor: 'pointer' }}>
                <span onClick={() => claimHop()}>&emsp;Claim</span>
                <span> | </span>
                <HoverCardRoot openDelay={150}>
                  <HoverCardTrigger asChild>
                    <span onClick={() => releaseHop()}>Release</span>
                  </HoverCardTrigger>
                  {releaseTip && (
                    <HoverCardContent sideOffset={5} className="hoverCardGovWidth">
                      This Hop will automatically be claimed by you if you fill in the destination,
                      below. Manually claiming this Hop allows you to let other members of your
                      organization know that you are already researching the record, allowing you to
                      communicate without duplication effort. Release allows you to disassociate
                      yourself from the research.
                      <div className="d-flex justify-content-end">
                        <Button
                          color="primary"
                          onClick={handleReleaseTooltipBtnClick}
                          className="mt-2 me-3"
                        >
                          Got it
                        </Button>
                      </div>
                      <HoverCardArrow />
                    </HoverCardContent>
                  )}
                </HoverCardRoot>
              </span>
            </span>
          )}
        </div>
        <div>
          <label className="telecom-label">Time Elapsed</label>
          {tfhop.elapsedPoe ? (
            <ColumnTooltip tooltipVisible={tfhop.elapsedPoe} columnType="elapsed">
              <label className={'text-' + (tfhop.elapsedPoe ? 'red font-weight-bold' : 'dark')}>
                {getElapsedTime(tfhop.elapsed)}
              </label>
            </ColumnTooltip>
          ) : (
            <span className={'span-cell ' + (tfhop.elapsed > 86400 ? 'red bold' : 'grey')}>
              {getElapsedTime(tfhop.elapsed)}
            </span>
          )}
        </div>
        <div>
          <label className="telecom-label">Date & Time Notified</label>
          <span className="span-cell gray">
            {getClientFormattedDate(tfhop.notified, DateFormat.MediumBoth)}
          </span>
        </div>
        {tfhop.status === 2 && (
          <div>
            <label className="telecom-label">Date & Time Completed</label>
            {getClientFormattedDate(tfhop.completed, DateFormat.MediumBoth)}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default TfHopDetailsRow;
