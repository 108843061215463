import React, { Fragment } from 'react';
import { Traceback } from '../../interfaces/traceback';

interface IProps {
  traceback: Traceback;
}

const TerminatingProviderDetailsRow: React.FC<IProps> = ({ traceback }) => (
  <Fragment>
    <h5 className="p-0 traceback-sub-title mb-3">Terminating Provider Details</h5>
    <div className="d-flex flex-row flew-wrap justify-content-start">
      <div className="d-flex flex-column">
        <label className="telecom-label">Terminating Carrier ID</label>
        <span className="table-cell-gray">{traceback.termCarrierId}</span>
      </div>
      <div className="d-flex flex-column ms-5">
        <label className="telecom-label">Terminating Carrier OCN</label>
        <span className="table-cell-gray">{traceback.termCarrierOCN}</span>
      </div>
    </div>
  </Fragment>
);

export default TerminatingProviderDetailsRow;
