import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { DateFormat } from '../../enum/DateFormat';
import { DnoToolTip } from '../../lib/dno';
import { getClientFormattedDate } from '../../lib/utilities';
import CustomToolTip from '../CustomToolTip';

const TracebacksExpandedComponent = ({ data }: any) => (
  <Card className="expandedRow">
    <CardBody style={{ color: '#797979', padding: '0.5rem 1rem' }}>
      <Row>
        <Col md="auto me-3">
          <Row>
            <span className="special-title">Calling Number:</span>
          </Row>
          <Row>
            <span className="special-title">Called Number:</span>
          </Row>
          <Row>
            <span className="special-title">Terminating Line Type:</span>
          </Row>
          <Row>
            <span className="special-title">Terminating Carrier:</span>
          </Row>
          <Row>
            <span className="special-title">Traceback Created:</span>
          </Row>
          <Row>
            <span className="special-title">Strike Exemption:</span>
          </Row>
        </Col>
        <Col md="auto me-3">
          <Row>
            <span>{data.callingTN ? data.callingTN : 'None'}</span>
          </Row>
          <Row>
            <span>{data.calledTN ? data.calledTN : 'None'}</span>
          </Row>
          <Row>
            <span>{data.termLineType ? data.termLineType : 'None'}</span>
          </Row>
          <Row>
            <span>{data.termCarrierId ? data.termCarrierId : 'None'}</span>
          </Row>
          <Row>
            <span>
              {data.create_date
                ? getClientFormattedDate(data.create_date, DateFormat.MediumBoth)
                : 'None'}
            </span>
          </Row>
          <Row>
            <span>{data.strikeExempt ? 'Yes' : 'No'}</span>
          </Row>
        </Col>
        <Col md="auto me-3">
          <Row>
            <span className="special-title">Caller Name Displayed:</span>
          </Row>
          <Row>
            <span className="special-title">Call Date & Time:</span>
          </Row>
          <Row>
            <span className="special-title">Do Not Call Registry:</span>
          </Row>
          <Row>
            <span className="special-title">Terminating Carrier OCN:</span>
          </Row>
          <Row>
            <span className="special-title">Created By:</span>
          </Row>
        </Col>
        <Col md="auto me-3">
          <Row>
            <span>{data.callerName ? data.callerName : 'None'}</span>
          </Row>
          <Row>
            <span>
              {data.tracebackTime
                ? getClientFormattedDate(data.tracebackTime, DateFormat.ShortBoth)
                : 'None'}
            </span>
          </Row>
          <Row>
            <span>{data.dnc ? data.dnc : 'None'}</span>
          </Row>
          <Row>
            <span>{data.termCarrierOCN ? data.termCarrierOCN : 'None'}</span>
          </Row>
          <Row>
            <span>{data.createdBy ? data.createdBy : 'None'}</span>
          </Row>
        </Col>
        <Col md="auto me-3">
          <Row>
            <span className="special-title">Terminating State:</span>
          </Row>
          <Row>
            <span className="special-title">Creator's Provider:</span>
          </Row>
          <Row>
            <span className="special-title">Do Not Origin Registry:</span>
          </Row>
        </Col>
        <Col md="auto">
          <Row>
            <span>{data.termState ? data.termState : 'None'}</span>
          </Row>
          <Row>
            <span>{data.creatorProvider ? data.creatorProvider : 'None'}</span>
          </Row>
          <Row>
            <CustomToolTip
              message={
                DnoToolTip.find((v) => v.type === data?.dnoType)?.description || data?.dnoType || ''
              }
              tooltipVisible={!!data?.dnoType}
            >
              <span>{data?.dno ? 'Industry DNO' : 'Not Listed'}</span>
            </CustomToolTip>
          </Row>
        </Col>
      </Row>
    </CardBody>
  </Card>
);

export default TracebacksExpandedComponent;
