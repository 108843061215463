import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { OnChangeValue } from 'react-select';
import { Button, Card, CardBody, FormGroup } from 'reactstrap';
import { userTypes } from '../../enum/userTypes';
import { IncidentChangePhoneNumbers, RequestIncident } from '../../interfaces/request';
import { DnoToolTip } from '../../lib/dno';
import {
  callingNumberIsAllowed,
  checkInternationalPhoneNumber,
  validatePhoneNumber
} from '../../lib/phoneNumber';
import { getClientFormattedDate } from '../../lib/utilities';
import { selectUser } from '../../redux/stateMappings';
import { useAppSelector } from '../../redux/store';
import CampaignsSelect from '../CampaignsSelect';
import { SelectOption } from '../CustomSelect';
import CustomToolTip from '../CustomToolTip';
import StirShakenIncidentRow from '../StirShakenIncidentRow';
import ProvidersSelect from '../ProvidersSelect';

interface IProps {
  incident: RequestIncident;
  createTracebacks: Function;
}

const ExpandableRequest: FC<IProps> = ({ incident, createTracebacks }) => {
  const user = useAppSelector(selectUser);

  const [edit, setEdit] = useState(false);
  const [data, setData] = useState<IncidentChangePhoneNumbers>({
    id: incident.id,
    incidentCampaignId: incident.incidentCampaignId,
    destinationPhoneNumber: incident.destinationPhoneNumber,
    originatorPhoneNumber: incident.originatorPhoneNumber,
    callTime: incident.callTime || ''
  });
  const [campaignId, setCampaignId] = useState<number>();
  const [providerId, setProviderId] = useState<number>();

  const phoneNumbersText = useMemo(() => {
    if (!data || user.roleType !== userTypes.Admin) return '';
    const originatorPhoneNumber = checkInternationalPhoneNumber(data.originatorPhoneNumber);
    const destinationPhoneNumber = checkInternationalPhoneNumber(data.destinationPhoneNumber);
    if (originatorPhoneNumber && destinationPhoneNumber)
      return 'Originator and destination numbers are international';
    else if (originatorPhoneNumber && !destinationPhoneNumber)
      return 'Originator number is international';
    else if (!originatorPhoneNumber && destinationPhoneNumber)
      return 'Destination number is international';
    return '';
  }, [data]);

  const [acceptWrongNumberFormat, setAcceptWrongNumberFormat] = useState(false);
  const phoneNumbersAreWrong = useMemo(
    () =>
      !validatePhoneNumber(data.destinationPhoneNumber) ||
      (!validatePhoneNumber(data.originatorPhoneNumber) &&
        !callingNumberIsAllowed(data.originatorPhoneNumber)),
    [data.originatorPhoneNumber, data.destinationPhoneNumber]
  );

  useEffect(() => {
    if (incident)
      setData({
        id: incident.id,
        incidentCampaignId: incident.incidentCampaignId,
        destinationPhoneNumber: incident.destinationPhoneNumber,
        originatorPhoneNumber: incident.originatorPhoneNumber,
        callTime: incident.callTime || ''
      });
  }, [incident]);

  const handlePhoneNumbersChange = (e: React.FormEvent<HTMLInputElement>, key: string) => {
    const data = e?.currentTarget?.value;
    switch (key) {
      case 'originator':
        setData((v) => ({ ...v, originatorPhoneNumber: data }));
        break;
      case 'destination':
        setData((v) => ({ ...v, destinationPhoneNumber: data }));
        break;
    }
  };

  const handleCampaignChange = (selectedOption: OnChangeValue<SelectOption, boolean>) => {
    if (selectedOption) setCampaignId((selectedOption as SelectOption).value);
    else setCampaignId(undefined);
  };

  const setSelectedPhoneNumbers = () => {
    createTracebacks(data, campaignId, providerId);
    setEdit(false);
  };

  const handleClose = () => {
    setData((v) => ({
      ...v,
      originatorPhoneNumber: incident ? incident?.originatorPhoneNumber : '',
      destinationPhoneNumber: incident ? incident?.destinationPhoneNumber : ''
    }));
    setCampaignId(undefined);
    setProviderId(undefined);
    setEdit(false);
  };

  return (
    <Card className="expandedRow">
      <CardBody style={{ color: '#797979', fontSize: '1rem', padding: '0.5rem 1rem' }}>
        {phoneNumbersText && <div className="text-danger">{phoneNumbersText}</div>}
        <div className="d-flex justify-content-start mt-1">
          <span className="ms-4">
            <span className="font-calibri-bold">Caller Phone Number</span>{' '}
            {edit ? (
              <div className="d-inline">
                <input
                  onChange={(event: React.FormEvent<HTMLInputElement>) =>
                    handlePhoneNumbersChange(event, 'originator')
                  }
                  className="input-comment form-control"
                  value={data.originatorPhoneNumber}
                />
              </div>
            ) : (
              data?.originatorPhoneNumber
            )}
            {!validatePhoneNumber(data.originatorPhoneNumber) &&
              !callingNumberIsAllowed(data.originatorPhoneNumber) && (
                <div className="text-danger">Not a valid phone number!</div>
              )}
          </span>
          <span className="ms-4">
            <span className="font-calibri-bold">Caller ID</span> {incident.originatorCnam}
          </span>
          <span className="ms-4">
            <span className="font-calibri-bold">Caller Carrier</span> {incident.originatorCarrier}
          </span>
        </div>
        <div className="d-flex justify-content-start mt-1">
          <span className="ms-4">
            <span className="font-calibri-bold">Call Time</span>{' '}
            {getClientFormattedDate(incident.callTime)}
          </span>
          <span className="ms-4">
            <span className="font-calibri-bold">Call Result</span> {incident.callResult}
          </span>
        </div>
        <div className="d-flex justify-content-start mt-1">
          <span className="ms-4">
            <span className="font-calibri-bold mt-1">Callee Phone Number</span>{' '}
            {edit ? (
              <div className="d-inline ms-4">
                <input
                  onChange={(event: React.FormEvent<HTMLInputElement>) =>
                    handlePhoneNumbersChange(event, 'destination')
                  }
                  className="input-comment form-control"
                  value={data.destinationPhoneNumber}
                />
              </div>
            ) : (
              data?.destinationPhoneNumber
            )}
            {!validatePhoneNumber(data.destinationPhoneNumber) && (
              <div className="text-danger">Not a valid phone number!</div>
            )}
          </span>
          <span className="ms-4">
            <span className="font-calibri-bold">Callee Carrier</span> {incident.destinationCarrier}
          </span>
          <span className="ms-4">
            <span className="font-calibri-bold">Do Not Call</span> {incident.doNotCall}
          </span>
          <span className="ms-4">
            <span className="font-calibri-bold">Do Not Origin</span>{' '}
            <CustomToolTip
              message={
                DnoToolTip.find((v) => v.type === incident.dnoType)?.description ||
                incident.dnoType ||
                ''
              }
              tooltipVisible={!!incident.dnoType}
            >
              <span className="text-secondary">{incident.dno ? 'Industry DNO' : 'Not Listed'}</span>
            </CustomToolTip>
          </span>
        </div>
        <div className="d-flex justify-content-start mt-1">
          <span className="ms-4">
            <span className="font-calibri-bold">Transcription</span> {incident.transcriptBody}
          </span>
        </div>
        {incident.description && (
          <div className="d-flex justify-content-start mt-1">
            <span className="ms-4">
              <span className="font-calibri-bold">Incident Description</span> {incident.description}
            </span>
          </div>
        )}
        {incident.natureOfRequest && (
          <div className="d-flex justify-content-start mt-1">
            <span className="ms-4">
              <span className="font-calibri-bold">Nature of Request</span>{' '}
              {incident.natureOfRequest}
            </span>
          </div>
        )}
        {incident.live !== null && (
          <div className="d-flex justify-content-start mt-1">
            <span className="ms-4">
              <span className="font-calibri-bold">Live or Prerecorded/Artificial</span>{' '}
              {incident.live === true
                ? 'Live'
                : incident.live === false && 'Prerecorded or Artificial Voice'}
            </span>
          </div>
        )}
        {incident.suspectedLegalViolation && (
          <div className="d-flex justify-content-start mt-1">
            <span className="ms-4">
              <span className="font-calibri-bold">Suspected Legal Violation(s)</span>{' '}
              {incident.suspectedLegalViolation}
            </span>
          </div>
        )}
        {incident.calledPartyType && (
          <div className="d-flex justify-content-start mt-1">
            <span className="ms-4">
              <span className="font-calibri-bold">Called Party Type</span>{' '}
              {incident.calledPartyType}
            </span>
          </div>
        )}
        <div className="d-flex justify-content-start mt-1">
          <span className="ms-4">
            <span className="font-calibri-bold">More Information</span>{' '}
            {incident.audioUrl ? (
              <Fragment>
                <br />
                <audio controls>
                  <source src={incident.audioUrl} />
                </audio>
                <br />
                <a
                  href={incident.audioUrl}
                  target={'audio'}
                  rel={'noopener noreferrer'}
                  className="telecom-text "
                  style={{ paddingTop: '5px', fontSize: '0.9em' }}
                  type={'audio/mp3'}
                >
                  {incident.audioUrl}
                </a>
              </Fragment>
            ) : (
              'None'
            )}
          </span>
        </div>
        {incident.stirShaken && <StirShakenIncidentRow stirShaken={incident.stirShaken} />}
        {user.roleType === userTypes.Admin ? (
          edit ? (
            <>
              {phoneNumbersAreWrong && (
                <div className="row mt-2 ms-2">
                  <div className="col-4">
                    <input
                      type="checkbox"
                      onChange={() => setAcceptWrongNumberFormat((v) => !v)}
                      checked={acceptWrongNumberFormat}
                    />
                    <span className="ms-1">Are the phone numbers correct?</span>
                  </div>
                </div>
              )}
              <div className="row mt-2 ms-2">
                <div className="col-2">
                  <CampaignsSelect
                    value={campaignId}
                    onChange={handleCampaignChange}
                    isClearable
                    selectClass="customselect-small"
                    placeholder="Select Campaign"
                    maxMenuHeight={150}
                  />
                </div>
                <div className="col-2">
                  <ProvidersSelect
                    noneSelectedByDefault={true}
                    className={'customselect-small'}
                    onChange={(option: any) => {
                      setProviderId(option.value);
                    }}
                    placeholder="Choose Existing Provider..."
                    isSearchable={true}
                    option={providerId}
                  />
                </div>
                <div className="col-1">
                  <Button color="primary" onClick={() => handleClose()}>
                    Cancel
                  </Button>
                </div>
                <div className="col-2">
                  <Button
                    color="primary"
                    disabled={!campaignId || (phoneNumbersAreWrong && !acceptWrongNumberFormat)}
                    onClick={() => setSelectedPhoneNumbers()}
                  >
                    Create Traceback
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <Button color="primary" onClick={() => setEdit(true)} className="ms-4 mt-2">
              Edit
            </Button>
          )
        ) : (
          incident.reason && <div className="row mt-2 ms-4">Decline Reason: {incident.reason}</div>
        )}
      </CardBody>
    </Card>
  );
};

export default ExpandableRequest;
