import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();
const getPhoneNumber = (number: string) => {
  if (number.length === 10) {
    try {
      return phoneUtil.parseAndKeepRawInput(number, 'US');
    } catch (error: any) {}
  }
  try {
    return phoneUtil.parseAndKeepRawInput(number);
  } catch (error: any) {
    return phoneUtil.parseAndKeepRawInput(number, 'US');
  }
};

export const checkInternationalPhoneNumber = (number: string) => {
  try {
    return phoneUtil.parseAndKeepRawInput(number).getCountryCode() !== 1 || number.length > 12;
  } catch (error: any) {}
  return false;
};

export const validatePhoneNumber = (phoneNumber: string) => {
  try {
    const number = getPhoneNumber(phoneNumber);
    return phoneUtil.isValidNumber(number);
  } catch (error: any) {
    return false;
  }
};

const normalizeNumber = (p: string) => {
  try {
    return getPhoneNumber(p);
  } catch (error: any) {}
  try {
    return getPhoneNumber('+' + p);
  } catch (error: any) {}
  return null;
};

export const phoneNumberEqual = (p1: string, p2: string): boolean => {
  if (p1 === p2 || '+' + p1 === p2 || p1 === '+' + p2) return true;

  const pn1 = normalizeNumber(p1);
  const pn2 = normalizeNumber(p2);

  return (
    pn1 !== null &&
    pn2 !== null &&
    phoneUtil.format(pn1, PhoneNumberFormat.E164) === phoneUtil.format(pn2, PhoneNumberFormat.E164)
  );
};

export const callingNumberIsAllowed = (number: string): boolean => {
  if (
    number.toLowerCase() === 'anonymous' ||
    number.toLowerCase() === 'unknown' ||
    number.toLowerCase() === 'restricted' ||
    number === ''
  ) {
    return true;
  }

  const ipPattern = /^.*@((\d{1,3}\.){3}\d{1,3}|([a-fA-F0-9]{1,4}:){7}[a-fA-F0-9]{1,4})$/;

  return ipPattern.test(number);
};
