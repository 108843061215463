//This file holds the API calls that hit the /user route for DRY purposes
import { CancelTokenSource } from 'axios';

import { Pagination } from '../../interfaces/pagination';
import { Traceforward, TraceforwardPostData } from '../../interfaces/traceforward';
import { FilterElement } from '../../lib/FilterElement';
import { ApiRequest, buildParams, withCancelRequestFunctionality } from '../../lib/apiRequest';

const getTraceforwardsWithCancelApiCalls = withCancelRequestFunctionality();

export const getTraceforwardsApiCall = async (params: Pagination, filterElements: FilterElement) =>
  getTraceforwardsWithCancelApiCalls((cancelToken: CancelTokenSource) =>
    ApiRequest.request({
      method: 'POST',
      url:
        `/traceforwards/search?` +
        buildParams({
          ...params
        }),
      data: filterElements,
      cancelToken: cancelToken.token
    })
  );

export const getTraceforwardApiCall = async (traceforwardId: number) =>
  ApiRequest.request({
    method: 'GET',
    url: `/traceforwards/${traceforwardId}`
  });

// export const getTraceforwardStatusApiCall = async () =>
//   ApiRequest.request({
//     method: 'GET',
//     url: '/AppInfo/Enums/TraceforwardStatusCodes'
//   });

export const addTraceforwardApiCall = async (content: TraceforwardPostData) =>
  ApiRequest.request({
    method: 'POST',
    url: `/traceforwards`,
    data: {
      ...content
    }
  });

export const editTraceforwardApiCall = async (content: Traceforward) =>
  ApiRequest.request({
    method: 'PUT',
    url: `/traceforwards/${content.id}`,
    data: {
      ...content
    }
  });

export const addAttachmentsApiCall = async (traceforwardId: number, content: FormData) =>
  ApiRequest.request({
    method: 'POST',
    url: `/attachments?traceforwardId=${traceforwardId}`,
    data: content,
    headers: {
      'Content-type': 'multipart/form-data'
    }
  });

export const deleteTraceforwardApiCall = async (traceforwardId: number) =>
  ApiRequest.request({
    method: 'DELETE',
    url: `/traceforwards/${traceforwardId}`
  });

export const addAudioApiCall = async (traceforwardId: number, content: FormData) =>
  ApiRequest.request({
    method: 'POST',
    url: `/UploadAudioFile?traceforwardId=${traceforwardId}`,
    data: content,
    headers: {
      'Content-type': 'multipart/form-data'
    }
  });

export const getAudioApiCall = async (traceforwardId: number) =>
  ApiRequest.request({
    method: 'GET',
    url: `/traceforwards/${traceforwardId}/UploadAudioFile`
  });

export const getCurrentTraceforward = async (
  calledTN: any,
  callingTN: any,
  traceforwardTime: any
) => {
  return ApiRequest.request({
    method: 'POST',
    url: `/existing-traceforward`,
    data: {
      callingTN: callingTN,
      calledTN: calledTN,
      traceforwardTime: traceforwardTime
    }
  });
};

export const getTraceforwardsByObjectTypeApiCall = async (
  objectId: number,
  objectType: string,
  filterElements: FilterElement
) =>
  ApiRequest.request({
    method: 'POST',
    url: `/traceforwards/${objectId}/${objectType}`,
    data: filterElements
  });

export const getCSVApiCall = async (filterElements: FilterElement, params: Pagination) =>
  ApiRequest.request({
    method: 'POST',
    url:
      `/DownloadTraceforwardsCsv?` +
      buildParams({
        ...params
      }),
    data: filterElements,
    responseType: 'blob',
    headers: { accept: 'text/csv' }
  });
