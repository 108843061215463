import { ConfigurationSettings } from '../../interfaces/configuration';
import { logoutOnAuthError } from '../../lib/apiRequest';
import { getConfigurationObject, setErrorMessage, setLoadingStatus } from './actions';
import { getConfigurationApiCall, updateConfigurationApiCall } from './apiCalls';

const setStatus = (status: boolean, message: string) => async (dispatch: any) => {
  dispatch(setErrorMessage(message));
  dispatch(setLoadingStatus(status));
};

export const getConfiguration = () => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    const config = await getConfigurationApiCall();
    if (config && config.data) {
      dispatch(getConfigurationObject(config.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const updateConfiguration =
  (configuration: ConfigurationSettings) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    try {
      const config = await updateConfigurationApiCall(configuration);
      if (config && config.data) {
        dispatch(getConfigurationObject(config.data));
      }
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };
