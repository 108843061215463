import { Incident } from './actionEnum';

const meta = {
  TotalCount: 0,
  PageSize: 0,
  CurrentPage: 0,
  TotalPages: 1
};

const defaultState: any = {
  incident: {},
  incidents: new Map(),
  incidentSummary: {},
  incidentCampaignSummary: null,
  campaignNames: [],
  loading: 0,
  error: '',
  metaSummary: meta,
  meta,
  selectedTracebackCampaign: ''
};

const reducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case Incident.GET_INCIDENT:
      return { ...state, incident: action.payload.data };
    case Incident.GET_INCIDENTS:
      state.incidents.set(action.payload[1], action.payload[0].data);
      return {
        ...state,
        meta: action.payload[0].paginationMeta || meta
      };
    case Incident.GET_INCIDENT_SUMMARY:
      return {
        ...state,
        incidentSummary: action.payload.data,
        metaSummary: action.payload.paginationMeta || meta
      };
    case Incident.GET_INCIDENT_CAMPAIGN_SOURCES:
      return {
        ...state,
        incidentCampaignSources: (action.payload.data || []).sort((a: any, b: any) =>
          a.sourceId > b.sourceId ? 1 : -1
        )
      };
    case Incident.PUT_INCIDENT_CAMPAIGN:
      return { ...state };
    case Incident.TRACEBACK_INCIDENTS:
      return { ...state };
    case Incident.POST_INCIDENT_CSV:
      return { ...state };
    case Incident.SET_INCIDENT_LOADING_STATUS:
      if (action.payload) return { ...state, loading: state.loading + 1 };
      else return { ...state, loading: state.loading === 0 ? state.loading : state.loading - 1 };
    case Incident.SET_INCIDENT_ERROR_MESSAGE:
      return { ...state, error: action.payload };
    case Incident.GET_INCIDENT_CAMPAIGN_SUMMARY:
      return { ...state, incidentCampaignSummary: action.payload.data };
    case Incident.GET_CAMPAIGN_NAMES:
      return { ...state, campaignNames: action.payload.data };
    default:
      return state;
  }
};

export default reducer;
