import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import Breadcrumb from '../components/Breadcrumbs';
import TraceforwardEditor from '../components/traceforward/traceforwardEditor';
import TraceforwardView from '../components/traceforward/traceforwardView';
import { userTypes } from '../enum/userTypes';
import { Traceforward } from '../interfaces/traceforward';
import { PersonalInfo } from '../interfaces/user';
import { logOut } from '../lib/apiRequest';
import { stateMappings } from '../redux/stateMappings';
import { editTraceforwardObject, getTraceforwardObject } from '../redux/traceforward/thunks';

type IProps = {
  getTraceforwardObject: Function;
  editTraceforwardObject: Function;
  traceforward: Traceforward;
  statuses: { [key: number]: string };
  attachments: any[];
  createdBy: string;
  error: string;
  user: PersonalInfo;
};

const TraceforwardDetail: React.FC<IProps> = ({
  getTraceforwardObject,
  editTraceforwardObject,
  traceforward,
  statuses,
  attachments,
  createdBy,
  error,
  user
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isViewMode, setIsViewMode] = useState(true);
  const [isDelete, setIsDelete] = useState(false);
  const [archiveTraceforwardTip, setArchiveTraceforwardTip] = useState(
    !localStorage.getItem('archiveTraceforwardTip')
  );

  useEffect(() => {
    if (user.roleType !== userTypes.Admin) {
      logOut();
    }
    getTraceforwardObject(Number(id) || 0);
  }, []);

  useEffect(() => {
    if (error) navigate('/404');
  }, [error]);

  const handleClickEdit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setViewMode();
  };

  const archiveConfirmModalToggle = () => {
    setIsDelete(!isDelete);
  };

  const setViewMode = () => {
    setIsViewMode(!isViewMode);
  };

  const handleArchiveTraceforwardTooltipBtnClick = () => {
    localStorage.setItem('archiveTraceforwardTip', 'SET');
    setArchiveTraceforwardTip(false);
  };

  const archiveTraceforward = () => {
    editTraceforwardObject(
      {
        ...traceforward,
        status: 5
      },
      attachments,
      false
    );

    navigate('/traceforwards');
  };

  return (
    <Fragment>
      <Breadcrumb
        title="traceforwards"
        destination="traceforwards"
        detail={`Traceforward ${traceforward.id || id}`}
        modify={''}
        modifyFunction={archiveConfirmModalToggle}
        tooltipContents={
          <Fragment>
            <p>
              If a mistake has been made or you need to archive this Traceforward for some other
              reason, this is the spot to do that.
            </p>
            <p>The Traceforward will remain visible but won't be active any longer.</p>
            <p>
              The Traceforward will always appear at the end of the Main Traceforwards section,
              reached by going to the last page.
            </p>
          </Fragment>
        }
        isModifyTooltip={archiveTraceforwardTip}
        handleModifyTooltipBtnClick={handleArchiveTraceforwardTooltipBtnClick}
        isAdmin={user.roleType === userTypes.Admin}
      />
      <Card className="telecom-card">
        <CardHeader className="telecom-card-header">
          {`Traceforward ${traceforward.id || id}`}
        </CardHeader>
        <CardBody>
          <div className="d-flex justify-content-center">
            <Col xs="12" sm="11" lg="10">
              {isViewMode ? (
                <TraceforwardView
                  traceforward={traceforward}
                  traceforwardStatuses={statuses}
                  user={user}
                />
              ) : (
                <TraceforwardEditor
                  traceforward={traceforward}
                  traceforwardStatuses={statuses}
                  createdBy={createdBy}
                  traceforwardServer={editTraceforwardObject}
                  tAttachments={attachments}
                ></TraceforwardEditor>
              )}
            </Col>
          </div>
          {isViewMode && (
            <div className="d-flex justify-content-center">
              <Button className="telecom-btn" onClick={handleClickEdit}>
                Edit
              </Button>
            </div>
          )}
        </CardBody>
      </Card>

      <Modal
        centered
        isOpen={isDelete}
        className="submit-confirm-modal"
        toggle={archiveConfirmModalToggle}
      >
        <ModalHeader toggle={archiveConfirmModalToggle} />
        <ModalBody className="delete-modal-body">
          <h5 className="traceback-sub-title">Archive this Traceforward?</h5>
          <p className="telecom-text mb-2">Archiving this traceforward is final.</p>
        </ModalBody>
        <ModalFooter className="m-auto">
          <Button
            className="btn-default telecom-btn"
            color="light"
            onClick={archiveConfirmModalToggle}
          >
            Cancel
          </Button>
          <Button className="telecom-btn red" onClick={archiveTraceforward}>
            Proceed
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    traceforward: sm.traceforward.traceforward,
    statuses: sm.traceback.statuses,
    attachments: sm.traceforward.attachments,
    createdBy: sm.user.name,
    error: sm.traceforward.error,
    user: sm.user
  };
};

const mapActionsToProps = {
  getTraceforwardObject,
  editTraceforwardObject
};

export default connect(mapStateToProps, mapActionsToProps)(TraceforwardDetail);
