import React, { useCallback, useState } from 'react';

import { toPng } from 'html-to-image';
import { jsPDF } from 'jspdf';
import { Button } from 'reactstrap';

const DownloadPdfFromView = React.forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false);
  const onPrint = useCallback(async () => {
    setLoading(true);
    try {
      if (ref === null) {
        return;
      }
      // @ts-ignore
      const dataUrl = await toPng(ref.current, { cacheBust: true });
      const justForSize = new jsPDF();
      const sizes = justForSize.getImageProperties(dataUrl);
      const pdf = new jsPDF('p', 'mm', [sizes.width, sizes.height]);
      pdf.addImage(dataUrl, 0, 0, sizes.width, sizes.height, undefined, 'FAST');
      await pdf.save('HomePagePDF.pdf', { returnPromise: true });
      setLoading(false);
    } catch (err) {
      console.log(`FAILED TO SAVE PDF ${err}`);
    }
  }, [ref]);

  return (
    <div className="d-flex align-items-center">
      <Button className="downloadCsvStyle pe-2 ps-2" onClick={onPrint}>
        <i className="fa fa-download pe-1" />
        {`Download PDF`}
      </Button>
      {loading && <p className="ps-2 m-0 text-blue ">In Progress...</p>}
    </div>
  );
});

export default DownloadPdfFromView;
