import React, { Fragment } from 'react';
import { Label } from 'reactstrap';
import { DateFormat } from '../../../enum/DateFormat';
import { Hop } from '../../../interfaces/hop';
import { Traceback } from '../../../interfaces/traceback';
import { getClientFormattedDate } from '../../../lib/utilities';
import { Notes } from '../../shared/Notes';
import { tbStatus } from '../../stateConverter';

interface IProps {
  hops: Hop[];
  traceback: Traceback;
  callingParty: string;
  campaignFirstSeen: string;
  mostRecentTraceback: string;
}

const TracebackTile: React.FC<IProps> = ({
  traceback,
  hops,
  callingParty,
  campaignFirstSeen,
  mostRecentTraceback
}) => {
  return (
    <Fragment>
      <div className="d-flex flex-row m-4" style={{ padding: '0 10px' }}>
        <div className="col-4">
          <div className="row">
            <div className="telecom-label">Campaign Name</div>
            <div className="ps-1">{traceback && traceback.campaignName}</div>
          </div>
        </div>
        <div className="col-4">
          <div className="row">
            <div className="telecom-label">Campaign First Seen</div>
            <div className="ps-1">
              {traceback && getClientFormattedDate(campaignFirstSeen, DateFormat.ShortDate)}
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="row">
            <div className="telecom-label">Most Recent Traceback</div>
            <div className="ps-1">
              {traceback && getClientFormattedDate(mostRecentTraceback, DateFormat.ShortDate)}
            </div>
          </div>
        </div>
      </div>
      <div className="row m-4">
        <div className="col-1" />
        <div className="col-1">
          <div className="d-flex flex-column">
            <div className="telecom-label">TB #</div>
            <div className="half-margin-left">{traceback && traceback.tracebackId}</div>
          </div>
        </div>
        <div className="col-3">
          <div className="row">
            <div className="telecom-label">Date & Time of Call</div>
            <div className="half-margin-left">
              {traceback && getClientFormattedDate(traceback.tracebackTime, DateFormat.MediumBoth)}
            </div>
          </div>
        </div>
        <div className="col">
          <div className="row">
            <div className="telecom-label">Calling TN</div>
            <div className="half-margin-left">{traceback && traceback.callingTN}</div>
          </div>
        </div>
        <div className="col">
          <div className="row">
            <div className="telecom-label">Called TN</div>
            <div className="half-margin-left">{traceback && traceback.calledTN}</div>
          </div>
        </div>
        <div className="col">
          <div className="row">
            <div className="telecom-label">FTC DNC List</div>
            <div className="half-margin-left">{traceback && traceback.dnc ? 'Yes' : 'No'}</div>
          </div>
        </div>
        <div className="col">
          <div className="row">
            <div className="telecom-label">Line Type</div>
            <div className="half-margin-left">{traceback && traceback.termLineType}</div>
          </div>
        </div>
      </div>
      {hops && hops.length > 0 && (
        <Fragment>
          <div className="row me-4 ms-4">
            <div className="col-1" />
            <div className="col-1">
              <div className="telecom-label">TB Hop #</div>
            </div>
            <div className="col-4">
              <div className="telecom-label">Hop Provider</div>
            </div>
            <div className="col-" style={{ maxWidth: '110px' }}>
              <div className="telecom-label">Notes</div>
            </div>
            <div className="col">
              <div className="telecom-label">Comments</div>
            </div>
          </div>
          {hops.map((hop, index) => (
            <div style={{ width: '100%' }} key={hop.hopId}>
              <div className="row me-4 ms-4">
                <div className="col-1" />
                <div className="col-1">
                  <div className="half-margin-left">{(index + 1).toString()}</div>
                </div>
                <div className="col-4">
                  <div className="half-margin-left">{hop.hopProvider.name}</div>
                </div>
                <div className="col-" style={{ maxWidth: '110px' }}>
                  {hop.notes && hop.notes.length && (
                    <div className="half-margin-left">
                      <Notes types={hop.notes} />;
                    </div>
                  )}
                </div>
                <div className="col">
                  {hop.comments &&
                    hop.comments.length > 0 &&
                    hop.comments.map((comment, index) => (
                      <div className="half-margin-left" key={index}>
                        {comment.contentText}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ))}
        </Fragment>
      )}
      <div className="row me-4 ms-4">
        <div className="col-1" />
        <div className="col-1">
          <div className="row">
            <div className="telecom-label">Result</div>
          </div>
        </div>
        <div className="col">
          <div className="half-margin-left">{traceback && tbStatus(traceback.status)}</div>
        </div>
      </div>
      <div className="row me-4 ms-4">
        <div className="col-1" />
        <div className="col-1">
          <div className="row">
            <div className="telecom-label">Calling Party</div>
          </div>
        </div>
        <div className="col">
          <Label className="half-margin-left">{callingParty}</Label>
        </div>
      </div>
      <div className="row m-4" style={{ borderBottom: '1px solid #ccc' }} />
    </Fragment>
  );
};

export default TracebackTile;
