import React from 'react';

const Default: React.FC = () => (
  <div className="d-flex justify-content-center m-0">
    <img
      src={require('../assets/images/404-page.png')}
      width="900"
      height="450"
      className="page-notfound_img"
      alt="404-page"
    />
  </div>
);

export default Default;
