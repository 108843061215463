import React, { Fragment, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { userTypes } from '../../enum/userTypes';
import { Hop } from '../../interfaces/hop';
import { Traceback } from '../../interfaces/traceback';
import { PersonalInfo } from '../../interfaces/user';
import Breadcrumb from '../Breadcrumbs';

interface IProps {
  hop: Hop;
  traceback: Traceback;
  user: PersonalInfo;
  resetHopObject: Function;
  deleteHop: Function;
}

const HopBreadcrumb: React.FC<IProps> = ({ hop, traceback, user, resetHopObject, deleteHop }) => {
  const [modifyTooltip, setModifyTooltip] = useState(!localStorage.getItem('deleteHopTip'));
  const [isDelete, setIsDelete] = useState(false);

  const handleModifyTooltipBtnClick = () => {
    localStorage.setItem('deleteHopTip', 'SET');
    setModifyTooltip(false);
  };

  const deleteConfirmModalToggle = () => {
    setIsDelete((v) => !v);
  };

  const modifyFunction = () => {
    // Only Admins & Transit/Originating Hops
    if (user.roleType !== userTypes.Admin) return;
    // Transit Hops can be deleted
    if (
      hop.hopSequence !== 1 &&
      (hop.hopId !== traceback.lastHopId || hop.status === 1 || hop.status === 6)
    ) {
      deleteConfirmModalToggle();
    } // Originating & NFNR Hops can be reset
    else if (
      hop.upstreamHopId === 0 &&
      ((hop.status >= 2 && hop.status <= 4) || hop.status === 7)
    ) {
      //TODO add system comment before reset
      resetHopObject(hop.hopId);
    }
  };

  return (
    <Fragment>
      <Breadcrumb
        title={'hops'}
        isAdmin={user.roleType === userTypes.Admin}
        destination={'/hops'}
        detail={`Hop ${hop.hopSequence || 'Unknown'} for Traceback ${hop.tracebackId}`}
        detailComponent={
          <Fragment>
            <a
              className={`breadcrum-sub-main`}
              href={`/campaigns/campaign/${traceback.campaignId}`}
            >
              {traceback.campaignName}
            </a>
            <span className="breadcrum-after">{' / '}</span>
            <a
              className="breadcrum-sub-main"
              style={{ marginLeft: '0px' }}
              href={`/tracebacks/traceback/${hop.tracebackId}`}
            >
              {hop.tracebackId}
            </a>
            <span className="breadcrum-after">{` / Hop ${hop.hopSequence || 'Unknown'}`}</span>
          </Fragment>
        }
        modify={
          user.roleType !== userTypes.Admin
            ? null // Only Admins & Transit/Originating Hops
            : hop.hopSequence !== 1 &&
                (hop.hopId !== traceback.lastHopId || hop.status === 1 || hop.status === 6)
              ? 'Delete Hop' // Open Hops can be deleted
              : hop.upstreamHopId === 0 && hop.status >= 2 && hop.status <= 4
                ? 'Reset Hop' // Originating & NFNR Hops can be reset
                : null
        }
        modifyFunction={() => modifyFunction()}
        tooltipContents={
          hop.hopSequence !== 1 &&
          (hop.hopId !== traceback.lastHopId || hop.status === 1 || hop.status === 6) ? ( // Transit Hops can be deleted
            <Fragment>
              <p>If you need to delete this Hop for some reason, this is the spot to do that.</p>
              <p>
                The Hop will be archived, and the previous Hop will be re-opened to allow the
                Provider to give a new response.
              </p>
            </Fragment>
          ) : hop.upstreamHopId === 0 && hop.status >= 2 && hop.status <= 4 ? ( // Originating & NFNR Hops can be reset
            <Fragment>
              <p>If you need to reset this Hop for some reason, this is the spot to do that.</p>
              <p>The Hop will be re-opened to allow the Provider to give a new response.</p>
            </Fragment>
          ) : null
        }
        isModifyTooltip={modifyTooltip}
        handleModifyTooltipBtnClick={handleModifyTooltipBtnClick}
      />
      <Modal
        centered
        isOpen={isDelete}
        className="submit-confirm-modal"
        toggle={deleteConfirmModalToggle}
      >
        <ModalHeader toggle={deleteConfirmModalToggle} />
        <ModalBody className="delete-modal-body">
          <h5 className="traceback-sub-title">Delete this hop?</h5>
          <p className="telecom-text mb-0">
            Information will be removed and the downstream hop will be re-opened.
          </p>
        </ModalBody>
        <ModalFooter style={{ margin: 'auto' }}>
          <div>
            <Button
              className="btn-default telecom-btn"
              color="light"
              onClick={deleteConfirmModalToggle}
            >
              Cancel
            </Button>
            <Button
              className="telecom-btn red ms-2"
              onClick={() => {
                deleteHop(hop.hopId, hop.downstreamHopId);
              }}
            >
              Proceed
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};
export default HopBreadcrumb;
