import React, { Fragment, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { StirShaken } from '../../enum/StirShaken';
import { StirShakenInfo, StirShakenModalErrors } from '../../interfaces/stirShaken';

import StirShakenBody from '../StirShakenBody';

import { clearStirShakenValues, validateStirShakenInputFields } from '../../lib/utilities';

interface IProps {
  submitConfirmModalToggle: Function;
  setStirShakenInfo: Function;
  stirShakenInfo: StirShakenInfo;
  forceStirShakenHeader?: boolean;
}

export const defaultStirShakenErrors: StirShakenModalErrors = {
  signedStirShaken: false,
  stirShakenToken: false,
  destinationNumber: false,
  callingNumber: false,
  timeStamp: false,
  attestation: false
};

const StirShakenModal: React.FC<IProps> = ({
  setStirShakenInfo,
  submitConfirmModalToggle,
  stirShakenInfo,
  forceStirShakenHeader
}) => {
  const { toggleStirShaken } = stirShakenInfo;

  const [stirShakenModalErrors, setStirShakenModalErrors] =
    useState<StirShakenModalErrors>(defaultStirShakenErrors);

  const submitFormWithStirShaken = () => {
    const stirShakenErrors = validateStirShakenInputFields(stirShakenModalErrors, stirShakenInfo);
    if (stirShakenErrors) {
      setStirShakenModalErrors(stirShakenErrors);
      return;
    }
    setStirShakenInfo({ ...stirShakenInfo, toggleStirShaken: false });
    submitConfirmModalToggle();
  };

  const handleCancelButton = () => {
    setStirShakenInfo(
      clearStirShakenValues([
        StirShaken.toggleStirShaken,
        StirShaken.signedStirShaken,
        StirShaken.stirShakenToken,
        StirShaken.attestationRadio,
        StirShaken.destinationNumber,
        StirShaken.callingNumber,
        StirShaken.timeStamp,
        StirShaken.originationIdentifier,
        StirShaken.isCallSigner
      ])
    );
    setStirShakenModalErrors(defaultStirShakenErrors);
  };

  return (
    <Fragment>
      <Modal centered className="modal-template" size="md" isOpen={toggleStirShaken}>
        <ModalHeader
          toggle={() => {
            handleCancelButton();
          }}
        >
          Stir Shaken Header
        </ModalHeader>

        <ModalBody>
          <StirShakenBody
            setStirShakenInfo={setStirShakenInfo}
            stirShakenInfo={stirShakenInfo}
            stirShakenModalErrors={stirShakenModalErrors}
            setStirShakenModalErrors={setStirShakenModalErrors}
            forceStirShakenHeader={forceStirShakenHeader}
          />
        </ModalBody>

        {stirShakenInfo.signedStirShaken && (
          <ModalFooter>
            <div>
              <Button
                className="btn-default telecom-btn"
                color="light"
                onClick={handleCancelButton}
              >
                Cancel
              </Button>
              <Button
                className="btn-default telecom-btn ms-2"
                onClick={() => submitFormWithStirShaken()}
              >
                Submit
              </Button>
            </div>
          </ModalFooter>
        )}
      </Modal>
    </Fragment>
  );
};

export default StirShakenModal;
