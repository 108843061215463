import React, { Fragment } from 'react';
import { Col, Label, Row } from 'reactstrap';
import { DateFormat } from '../../../../enum/DateFormat';
import {
  RollupReportCampaignDataRow,
  RollupReportCampaignRow
} from '../../../../interfaces/report';
import { getClientFormattedDate } from '../../../../lib/utilities';
import CampaignDataRow from './CampaignDataRow';

interface IProps {
  rollupReportCampaignRow: RollupReportCampaignRow;
}

const CampaignHeaderRow: React.FC<IProps> = ({ rollupReportCampaignRow }) => (
  <Fragment>
    <Row className="mt-1" style={{ padding: '0 10px' }}>
      <Col className="text-center p-0">
        <Label className="telecom-label ps-0">Name</Label>
      </Col>
      <Col className="text-center p-0">
        <Label className="telecom-label ps-0">Date 1st TB</Label>
      </Col>
      <Col className="text-center p-0">
        <Label className="telecom-label ps-0">Most Recent TB</Label>
      </Col>
      <Col className="text-center p-0">
        <Label
          title="* Tracebacks are occasionally archived for various reasons, resulting in a discrepancy
                            between the number of total tracebacks and the tracebacks outcome figures."
          className="telecom-label ps-0"
        >
          Total TBs*
        </Label>
      </Col>
    </Row>
    <Row style={{ padding: '0 10px' }}>
      <Col className="text-center p-0">
        <span>{rollupReportCampaignRow.campaignName}</span>
      </Col>
      <Col className="text-center p-0">
        <span>
          {getClientFormattedDate(rollupReportCampaignRow.firstTracebackDate, DateFormat.ShortDate)}
        </span>
      </Col>
      <Col className="text-center p-0">
        <span>
          {getClientFormattedDate(
            rollupReportCampaignRow.mostRecentTracebackDate,
            DateFormat.ShortDate
          )}
        </span>
      </Col>
      <Col className="text-center p-0">
        <span>{rollupReportCampaignRow.count}</span>
      </Col>
    </Row>
    {rollupReportCampaignRow.rollupReportCampaignDataRow && (
      <Fragment>
        <Row className="mt-1" style={{ padding: '0 10px' }}>
          <Col>
            <Label className="telecom-label ps-0">Orig. or PoE</Label>
          </Col>
          <Col>
            <Label className="telecom-label ps-0">FPD</Label>
          </Col>
          <Col>
            <Label className="telecom-label ps-0">IOR (if appl.)</Label>
          </Col>
          <Col>
            <Label className="telecom-label ps-0">Calling Entity</Label>
          </Col>
          <Col className="md-1 text-end">
            <Label className="telecom-label ps-0">Count</Label>
          </Col>
        </Row>
        {rollupReportCampaignRow.rollupReportCampaignDataRow.map(
          (rollupReportCampaignDataRow: RollupReportCampaignDataRow, key) => (
            <div key={key}>
              <CampaignDataRow campaignDataRow={rollupReportCampaignDataRow} />
            </div>
          )
        )}
      </Fragment>
    )}
    <Row className="m-4" style={{ borderBottom: '1px solid #ccc' }} />
  </Fragment>
);

export default CampaignHeaderRow;
