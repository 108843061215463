import { sub } from 'date-fns';
import React, { Fragment, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Button, Col, FormGroup, Input, Label } from 'reactstrap';
import { ReportFormat, ReportPostData, ReportType } from '../../../interfaces/report';
import { getEndOfTheDay, getStartOfTheDay } from '../../../lib/utilities';
import { getReportObject } from '../../../redux/report/thunks';
import { stateMappings } from '../../../redux/stateMappings';
import { SelectOption } from '../../CustomSelect';

interface IProps {
  getReportObject: Function;
  requestors: any[];
}

const PartnerReport: React.FC<IProps> = ({ getReportObject, requestors }) => {
  const [startDate, setStartDate] = useState(sub(new Date(), { months: 1 }));
  const [endDate, setEndDate] = useState(new Date());
  const [selectedRequestor, setSelectedRequestor] = useState<any>();
  const [tracebackRequestors, settracebackRequestors] = useState<SelectOption[]>([]);
  const [includeStirShaken, setIncludeStirShaken] = useState(false);

  useEffect(() => {
    settracebackRequestors(
      (requestors || []).map((item: any) => ({ value: item.name, label: item.name }))
    );
  }, [requestors]);

  const getReport = () => {
    let reportPostData = {
      startDate: getStartOfTheDay(startDate).toISOString(),
      endDate: getEndOfTheDay(endDate).toISOString(),
      requestorId: selectedRequestor.requestorId,
      includeStirShaken
    } as ReportPostData;
    getReportObject(reportPostData, ReportType.PartnerReport, ReportFormat.Excel);
  };

  const handleCheck = (e: any, key: string) => {
    switch (key) {
      case 'startDate':
        setStartDate(e);
        break;
      case 'endDate':
        setEndDate(e);
        break;
      case 'requestor':
        setSelectedRequestor(requestors.find((request: any) => request.name === e.label));
        break;
      case 'excel':
        getReport();
        break;
    }
  };

  const handleStirShaken = () => {
    setIncludeStirShaken((v) => !v);
  };

  return (
    <Fragment>
      <Label className="telecom-label ps-0">Date Range *</Label>
      <div className="d-flex flex-row mb-4">
        <DatePicker
          maxDate={endDate || new Date()}
          selected={startDate}
          onChange={(option) => handleCheck(option, 'startDate')}
          placeholderText="&#xf133; mm/dd/yyyy"
          clearButtonTitle={'Clear'}
          isClearable
        />
        <span className="ms-2 me-2">-</span>
        <DatePicker
          maxDate={new Date()}
          selected={endDate}
          minDate={startDate}
          onChange={(option) => handleCheck(option, 'endDate')}
          placeholderText="&#xf133; mm/dd/yyyy"
          clearButtonTitle={'Clear'}
          isClearable
        />
        <FormGroup check className="d-flex align-items-center" onClick={handleStirShaken}>
          <Input
            id="checkbox1"
            className="mt-0 ms-2"
            type="checkbox"
            checked={includeStirShaken}
            valid={includeStirShaken}
            readOnly
          />{' '}
          <Label check>Include Stir/Shaken</Label>
        </FormGroup>
      </div>
      <Label className="telecom-label ps-0">Traceback Requestor *</Label>
      <div className="ps-0 me-0 mb-4">
        <Col className={'me-0 ps-0 col-md-4'}>
          <Select
            className={'customselect-small'}
            classNamePrefix={'customselect'}
            options={tracebackRequestors}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0
            })}
            onChange={(option: any) => handleCheck(option, 'requestor')}
            isSearchable
            placeholder="Select a Requestor"
          />
        </Col>
      </div>
      <div className="ps-0 me-0 mb-4">
        Report will include both Tracebacks and Traceforwards for the time period
      </div>
      <div className="m-4" style={{ borderBottom: '1px solid #ccc' }} />
      <div className="d-flex justify-content-md-center">
        <Button
          className="telecom-btn me-2"
          color="primary"
          style={{
            height: '28px',
            padding: '5px',
            fontSize: '14px',
            width: '150px'
          }}
          disabled={!(selectedRequestor && startDate && endDate)}
          onClick={(option) => handleCheck(option, 'excel')}
        >
          Create Report
        </Button>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    requestors: sm.traceback.requestors
  };
};

const mapActionsToProps = {
  getReportObject
};

export default connect(mapStateToProps, mapActionsToProps)(PartnerReport);
