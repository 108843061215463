export const supportedAudioFileFormats = [
  '.mp3',
  '.wav',
  '.aiff',
  '.flac',
  '.aac',
  '.alac',
  '.wma',
  '.dsd',
  '.amr',
  '.m4a'
];
export const supportedFilesFormats = [
  '.html',
  '.htm',
  '.pdf',
  '.csv',
  '.docx',
  '.doc',
  '.bmp',
  '.pptx',
  '.ACCDA',
  '.one',
  '.ecf',
  '.JPG',
  '.png',
  '.gif',
  '.txt',
  '.pcap',
  '.xlsx',
  '.jpeg',
  '.msg',
  '.pages',
  '.xls',
  '.pcapn',
  '.eml'
].concat(supportedAudioFileFormats);

export const MAX_FILE_SIZE = 1024 * 1024 * 5; //5MB

export const verifyAttachmentsList = (files: File[], suffix: string[]) => {
  return files.some(
    (file: File) =>
      !suffix.some((index) => file.name.toLowerCase().endsWith(index.toLocaleLowerCase()))
  );
};
export const verifySizeAttachments = (files: File[]) => {
  return files.some((file: File) => file.size > MAX_FILE_SIZE);
};
