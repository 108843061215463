export enum Campaign {
  GET_CAMPAIGNS = 'GET_CAMPAIGNS',
  GET_CAMPAIGN = 'GET_CAMPAIGN',
  GET_CAMPAIGN_LABELS_STATS = 'GET_CAMPAIGN_LABELS_STATS',
  ADD_CAMPAIGN = 'ADD_CAMPAIGN',
  SET_CAMPAIGN = 'SET_CAMPAIGN',
  EDIT_CAMPAIGN = 'EDIT_CAMPAIGN',
  ADD_ATTACHMENTS = 'ADD_ATTACHMENTS',
  GET_ATTACHMENTS = 'GET_ATTACHMENTS',
  GET_SOURCES = 'GET_SOURCES',
  GET_LABELS = 'GET_LABELS',
  ADD_LABEL = 'ADD_LABEL',
  ADD_SOURCE = 'ADD_SOURCE',
  SET_CAMPAIGN_LOADING_STATUS = 'SET_CAMPAIGN_LOADING_STATUS',
  SET_CAMPAIGN_ERROR_MESSAGE = 'SET_CAMPAIGN_ERROR_MESSAGE',
  GET_CAMPAIGN_LANGUAGES = 'GET_CAMPAIGN_LANGUAGES'
}
