import React, { FC, Fragment, useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, CardBody, Col, Label, Row } from 'reactstrap';
import { userTypes } from '../../../enum/userTypes';
import { DNO } from '../../../interfaces/dno';
import { Pagination, defaultPagination } from '../../../interfaces/pagination';
import { PersonalInfo } from '../../../interfaces/user';
import { downloadDnosCsv, getAllDno, setDno } from '../../../redux/dno/thunk';
import { stateMappings } from '../../../redux/stateMappings';
import { dnoColumns } from '../.././../lib/dataTableUtils/dnoColumns';
import CustomDataTable from '../../CustomDataTable';
interface IProp {
  user: PersonalInfo;
  paginationTotalRows: number;
  getAllDno: Function;
  downloadDnosCsv: Function;
  setDno: Function;
  allDno: DNO[];
}

const Dno: FC<IProp> = ({
  user,
  paginationTotalRows,
  allDno,
  getAllDno,
  downloadDnosCsv,
  setDno
}) => {
  const navigate = useNavigate();
  const [paginationParams, setPaginationParams] = useState({
    ...defaultPagination(),
    sort: 'name',
    order: 'asc'
  });
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    if (phoneNumber === '') {
      getAllDno(paginationParams);
    }
  }, [paginationParams, phoneNumber]);

  const updatePagination = (params: Pagination) => {
    setPaginationParams({ ...paginationParams, ...params });
  };

  const handleCheck = (e: any, key: string) => {
    switch (key) {
      case 'phoneNumber':
        setPhoneNumber(e.target.value.trim());
        break;
    }
  };

  const searchPhoneNumber = () => {
    getAllDno({ ...paginationParams, page: 1 }, phoneNumber);
  };

  const editDno = (dno: DNO) => {
    setDno(dno);
    navigate(`/admin-dashboard#dno/edit/${dno.id}`);
  };

  return (
    <Fragment>
      <CardBody>
        <Row className="d-flex align-items-center justify-content-between">
          <Col lg="8" className="d-flex align-items-center flex-wrap">
            {user.roleType === userTypes.Admin && (
              <Col lg="4">
                <Label className="traceback-heading ps-0">Do Not Origin</Label>
              </Col>
            )}

            <Col md="3" sm="4" className="me-2">
              <DebounceInput
                type={'text'}
                debounceTimeout={1000}
                className={'comments-search-with-border'}
                placeholder={'Search PhoneNumber'}
                onChange={(option) => handleCheck(option, 'phoneNumber')}
                value={phoneNumber}
              />
            </Col>
            <Col md="2" className="d-flex justify-content-start">
              <Button color="primary" size="sm" onClick={searchPhoneNumber}>
                <i className="fa fa-search pe-2" />
                Search
              </Button>
            </Col>
          </Col>

          <div className="col-3 d-flex justify-content-sm-end me-4">
            {user.roleType === userTypes.Admin && (
              <Button
                className="downloadCsvStyle ms-2 pe-2 ps-2 me-2"
                onClick={() => navigate('/admin-dashboard#dno/add')}
              >
                {`New DNO#`}
              </Button>
            )}

            <Button className="downloadCsvStyle pe-2 ps-2" onClick={() => downloadDnosCsv()}>
              <i className="fa fa-download pe-1" />
              {`Download CSV`}
            </Button>
          </div>
        </Row>
      </CardBody>
      <CardBody className="card-body-traceback">
        <CustomDataTable
          tableData={allDno}
          columns={dnoColumns(user, editDno)}
          defaultSortAsc={paginationParams.order === 'asc'}
          defaultSortFieldId={paginationParams.sort}
          defaultPage={paginationParams.page}
          defaultPageSize={paginationParams.pageSize}
          paginationTotalRows={paginationTotalRows}
          pagination={true}
          updatePaginationParams={updatePagination}
        />
      </CardBody>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    user: sm.user,
    paginationTotalRows: sm.dno.meta.TotalCount,
    allDno: sm.dno.dnos
  };
};

const mapActionsToProps = {
  getAllDno,
  downloadDnosCsv,
  setDno
};

export default connect(mapStateToProps, mapActionsToProps)(Dno);
