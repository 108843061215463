import { getLabelList, getSourceList } from '../redux/campaign/thunks';
import { getHopStatusList } from '../redux/hop/thunks';
import { getProviderTypeList } from '../redux/provider/thunks';
import { getTfHopStatusList } from '../redux/tfhop/thunks';
import { getRequestorList, getTracebackStatusList } from '../redux/traceback/thunks';

export const setupDataManager = (isAdmin: boolean) => async (dispatch: any) => {
  if (isAdmin) {
    await Promise.all([
      dispatch(getSourceList()),
      dispatch(getLabelList()),
      dispatch(getProviderTypeList()),
      dispatch(getHopStatusList()),
      dispatch(getTfHopStatusList()),
      dispatch(getTracebackStatusList()),
      dispatch(getRequestorList())
    ]);
  } else {
    await Promise.all([
      dispatch(getProviderTypeList()),
      dispatch(getHopStatusList()),
      dispatch(getTfHopStatusList()),
      dispatch(getTracebackStatusList())
    ]);
  }
};
