import React, { Fragment, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { DebounceInput } from 'react-debounce-input';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { Button, CardHeader, Col, Row } from 'reactstrap';
import { DateFormat } from '../../enum/DateFormat';
import { Attachment, Comment } from '../../interfaces/comment';
import { defaultPagination } from '../../interfaces/pagination';
import { decodeQueryParams, getFromLocalStorage } from '../../lib/history-utils';
import {
  getClientFormattedDate,
  getEndOfDayAsString,
  getStartOfDayAsString
} from '../../lib/utilities';
import { clearCommentsList, setCommentObject } from '../../redux/comment/thunks';

import { TableColumn } from 'react-data-table-component';
import {
  addCondition,
  FilterElement,
  newLeafFilterElement,
  simplifyFilterElement
} from '../../lib/FilterElement';
import ProvidersSelect from '../ProvidersSelect';
import CommentsTable, {
  getCommentsBasicFilter,
  saveCommentParams,
  setCommentsFiltersFromHistory
} from './commentsTable';

const PAGENAME = 'commentsGov';

const columns = (edit: (comment: Comment) => void): TableColumn<Comment>[] => [
  {
    width: '30px'
  },
  {
    name: 'Create Date',
    selector: () => 'create_date',
    sortable: true,
    grow: 1,
    cell: (comment: Comment) => (
      <div className="table-cell gray">
        <span>
          {comment.create_date
            ? getClientFormattedDate(comment.create_date, DateFormat.ShortDate)
            : 'TBD'}
        </span>
      </div>
    )
  },
  {
    name: 'Provider',
    selector: () => 'relatedProviderName',
    sortable: true,
    grow: 1,
    cell: (comment: Comment) =>
      comment.relatedProvider && (
        <div className="table-cell blue">
          <a className="pe-2" href={`/providers/provider/${comment.relatedProvider.providerId}`}>
            {comment.relatedProvider.name}
          </a>
        </div>
      )
  },
  {
    name: 'Tracebacks',
    selector: () => 'tracebacks',
    sortable: false,
    grow: 2,
    cell: (comment: Comment) => (
      <span className="table-cell gray center">{comment.callRecordTracebacks}</span>
    )
  },
  {
    name: 'Campaigns',
    selector: () => 'campaigns',
    sortable: false,
    grow: 2,
    cell: (comment: Comment) => (
      <div className="table-cell gray center text-wrap">{comment.callRecordCampaigns}</div>
    )
  },
  {
    name: 'Created By',
    selector: () => 'userName',
    id: 'column-userName',
    sortable: true,
    grow: 2,
    cell: (comment: Comment) => <span className="table-cell gray">{comment.userName}</span>
  },
  {
    name: 'Comments',
    selector: () => 'contentText',
    sortable: false,
    grow: 4,
    cell: (comment: Comment) => <span className="table-cell gray">{comment.contentText}</span>
  },
  {
    name: 'Attachment',
    selector: () => 'isAttchmt',
    sortable: false,
    maxWidth: '1px',
    cell: (comment: Comment) => (
      <div className={'table-cell center'}>
        {comment.attachments &&
          comment.attachments.length > 0 &&
          comment.attachments.map((attachment: Attachment, index: number) => (
            <Fragment key={`${comment.commentId}-attachment-${index}`}>
              <a
                style={{ fontWeight: 'bold' }}
                target="_blank"
                rel="noopener noreferrer"
                href={`/api/attachments/${attachment.attachmentId}`}
              >
                <i className={'fa fa-paperclip'} />
              </a>
            </Fragment>
          ))}
      </div>
    )
  },
  {
    name: 'Edit',
    sortable: false,
    maxWidth: '1px',
    cell: (comment: Comment) => (
      <div className="div-center">
        <span
          onClick={() => {
            edit(comment);
          }}
        >
          <span className="fa fa-pencil" style={{ color: 'black', cursor: 'pointer' }} />
        </span>
      </div>
    )
  }
];

const getFilter = ({ startDate, endDate, providerId, searchKey }: any): FilterElement => {
  let filterElements = getCommentsBasicFilter({ startDate, endDate }, 'gov');
  if (providerId) {
    addCondition(
      filterElements,
      newLeafFilterElement('relatedProviderId', 'EQ', providerId.toString())
    );
  }
  if (searchKey) {
    addCondition(filterElements, newLeafFilterElement('searchKey', 'EQ', searchKey));
  }
  return simplifyFilterElement(filterElements);
};

interface IProps {
  clearCommentsList: Function;
  setCommentObject: Function;
}

const TracebacksComments: React.FC<IProps> = ({ clearCommentsList, setCommentObject }) => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const searchParams = getFromLocalStorage(PAGENAME) || search || '';
  const savedSearchDetails = decodeQueryParams(PAGENAME, searchParams);
  const filtersFromHistory = setCommentsFiltersFromHistory(savedSearchDetails);
  const [startDate, setStartDate] = useState<string | undefined>(filtersFromHistory.startDate);
  const [endDate, setEndDate] = useState<string | undefined>(filtersFromHistory.endDate);
  const [providerId, setProviderId] = useState<number | undefined>(
    filtersFromHistory.relatedProviderId
  );
  const [searchKey, setSearchKey] = useState<string | undefined>(filtersFromHistory.searchKey);
  const [filterElements, setFilterElements] = useState(
    getFilter({ startDate, endDate, providerId, searchKey })
  );
  const [pagination, setPagination] = useState(
    savedSearchDetails.paginationParams || {
      ...defaultPagination(),
      sort: 'callDate',
      order: 'desc'
    }
  );

  const handleCheck = (e: any, key: string) => {
    switch (key) {
      case 'startDate':
        setStartDate(getStartOfDayAsString(e, DateFormat.DBDate));
        break;
      case 'endDate':
        setEndDate(getEndOfDayAsString(e, DateFormat.DBDate));
        break;
      case 'searchComments':
        setSearchKey(e.target.value);
        break;
      case 'providerId':
        setProviderId(Number(e.value));
        break;
    }
  };

  const updateFilter = () => {
    setFilterElements(getFilter({ startDate, endDate, providerId, searchKey }));
    setPagination({
      ...pagination,
      page: 1
    });
  };

  const clearFilters = () => {
    setStartDate(undefined);
    setEndDate(undefined);
    setProviderId(undefined);
    setSearchKey(undefined);
    clearCommentsList();
    saveCommentParams(PAGENAME, 7, getFilter({}), pagination);
  };

  const edit = (comment: Comment) => {
    setCommentObject(comment);
    navigate(`/comments/comment/${comment.commentId}`);
  };

  useEffect(() => {
    saveCommentParams(PAGENAME, 7, filterElements, pagination);
  }, [filterElements, pagination]);

  return (
    <Fragment>
      <CardHeader className="card-header-hops">
        <div className="d-flex align-items-center flex-wrap p-0">
          <Col xl="1" md="2" style={{ minWidth: '10rem' }} className="me-2">
            <DatePicker
              maxDate={endDate ? new Date(endDate) : new Date()}
              selected={startDate ? new Date(startDate) : undefined}
              onChange={(option) => handleCheck(option, 'startDate')}
              placeholderText="&#xf133; startDate"
              isClearable
            />
          </Col>
          <Col xl="1" md="2" style={{ minWidth: '10rem' }} className="me-2">
            <DatePicker
              maxDate={new Date()}
              selected={endDate ? new Date(endDate) : undefined}
              minDate={startDate ? new Date(startDate) : undefined}
              onChange={(option) => handleCheck(option, 'endDate')}
              placeholderText="&#xf133; endDate"
              isClearable
            />
          </Col>

          <Col xl="2" md="2" style={{ minWidth: '15rem' }} className="me-2">
            <ProvidersSelect
              onChange={(option) => handleCheck(option, 'providerId')}
              isSearchable
              addAllItem
              option={providerId}
              includeInactive
            />
          </Col>

          <Col xl="2" md="2" style={{ minWidth: '15rem' }} className="me-2">
            <DebounceInput
              type={'text'}
              name={'searchComments'}
              debounceTimeout={1000}
              className={'comments-search-with-border'}
              id={'searchComments'}
              placeholder={'Search Comments'}
              onChange={(option) => handleCheck(option, 'searchComments')}
              value={searchKey}
            />
          </Col>
          <Col lg="1" md="2" className="d-flex justify-content-start" style={{ minWidth: '10rem' }}>
            <Button color="primary" size="sm" onClick={() => updateFilter()}>
              <i className="fa fa-search pe-2" />
              Search
            </Button>
          </Col>
          <Col lg="1" md="2" style={{ minWidth: '10rem' }}>
            <button
              type="button"
              className="btn btn-link"
              onClick={() => {
                clearFilters();
              }}
            >
              Clear Filters
            </button>
          </Col>
        </div>
      </CardHeader>
      <Row>
        <Col>
          <CommentsTable
            columns={columns(edit)}
            filterElements={filterElements}
            pagination={pagination}
            setPagination={setPagination}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

const mapActionsToProps = { clearCommentsList, setCommentObject };

export default connect(null, mapActionsToProps)(TracebacksComments);
