import React from 'react';
import { TableColumn } from 'react-data-table-component';
import { StirShakenSigner, StirShakenSignerEqual } from '../../../interfaces/stirShaken';
import ProvidersSelect from '../../ProvidersSelect';

export const stirShakenSignerColumns = (
  selected: StirShakenSigner[],
  update: (row: StirShakenSigner, providerId?: number) => void,
  toggle: (row: StirShakenSigner) => void,
  done: (row: StirShakenSigner) => void
): TableColumn<StirShakenSigner>[] => {
  return [
    {
      name: '',
      width: '20px',
      cell: () => <div />
    },
    {
      name: 'SignerOrganization',
      selector: () => 'signerOrganization',
      sortable: true,
      grow: 3,
      cell: (row: StirShakenSigner) => (
        <div className="table-cell text-center">
          <span>{row.signerOrganization}</span>
        </div>
      )
    },
    {
      name: 'SignerOrganizationalUnit',
      sortable: true,
      selector: () => 'signerOrganizationalUnit',
      grow: 2,

      cell: (row: StirShakenSigner) => (
        <div className="table-cell text-center">
          <span>
            {row.signerOrganizationalUnit === null ? 'NULL' : row.signerOrganizationalUnit}
          </span>
        </div>
      )
    },
    {
      name: 'SignerCommonName',
      sortable: true,
      selector: () => 'signerCommonName',
      grow: 3,

      cell: (row: StirShakenSigner) => (
        <div className="table-cell text-center">
          <span>{row.signerCommonName}</span>
        </div>
      )
    },
    {
      name: 'ProviderName',
      sortable: true,
      selector: () => 'providerName',
      grow: 3,
      allowOverflow: true,
      cell: (row: StirShakenSigner) => {
        const selectedRow = selected.find((v) => StirShakenSignerEqual(row, v));
        return selectedRow ? (
          <div className="table-cell overflow-visible">
            <ProvidersSelect
              option={selectedRow.providerId}
              className="customselect-small overflow-visible"
              onChange={(option: any) => update(selectedRow, option.value)}
              placeholder="None"
              noneSelectedByDefault
              maxMenuHeight={150}
            />
          </div>
        ) : (
          <div className="table-cell overflow-hidden">
            <span>{row.providerName}</span>
          </div>
        );
      }
    },
    {
      name: 'Edit ',
      grow: 1,
      cell: (row: StirShakenSigner) => {
        const selectedRow = selected.find((v) => StirShakenSignerEqual(row, v));
        return selectedRow ? (
          <div data-tag="allowRowEvents" className="table-cell text-center">
            <div className="table-cell text-center">
              <a
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  done(row);
                }}
              >
                <i className="fa-solid fa-check fa-lg green"></i>
              </a>
              <a
                className="ms-3"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  toggle(row);
                }}
              >
                <i className="fa-solid fa-times fa-lg" style={{ color: 'red' }}></i>
              </a>
            </div>
          </div>
        ) : (
          <div data-tag="allowRowEvents" className="table-cell text-center">
            <a
              style={{ cursor: 'pointer' }}
              onClick={() => {
                toggle(row);
              }}
            >
              <i className="fa-solid fa-pencil blue"></i>
            </a>
          </div>
        );
      }
    }
  ];
};
