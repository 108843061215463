import { RMDEntry } from '../../interfaces/rmd';
import { GET_RMDS } from './actionEnum';

interface rmdState {
  rmds: RMDEntry[];
}
const defaultState: rmdState = {
  rmds: []
};

const reducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case GET_RMDS:
      return {
        ...state,
        rmds: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
