import React, { FC, useEffect, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { connect } from 'react-redux';
import { Button, Card, CardBody, CardHeader, Label } from 'reactstrap';
import { DateFormat } from '../../../enum/DateFormat';
import { Event } from '../../../interfaces/messaging';
import { Pagination, defaultPagination } from '../../../interfaces/pagination';
import {
  FilterElement,
  addCondition,
  newConditionalFilterElement,
  newLeafFilterElement,
  simplifyFilterElement
} from '../../../lib/FilterElement';
import {
  decodeQueryParams,
  encodeQueryParams,
  getFromLocalStorage,
  saveToLocalStorage
} from '../../../lib/history-utils';
import { getClientFormattedDate } from '../../../lib/utilities';
import { getEventsList } from '../../../redux/messaging/thunks';
import { stateMappings } from '../../../redux/stateMappings';
import CustomDataTable from '../../CustomDataTable';
import CustomToolTip from '../../CustomToolTip';
import ProvidersSelect from '../../ProvidersSelect';
import InputFormGroup from '../../inputFormGroup';

interface IProp {
  events: Event[];
  paginationTotalRows: number;
  getEventsList: Function;
}

const columns: TableColumn<Event>[] = [
  {
    name: '',
    width: '20px',
    cell: () => <div />
  },
  {
    name: 'Date and Time',
    selector: () => 'event_date',
    sortable: true,
    grow: 1,
    cell: (event: Event) => (
      <div className="table-cell gray">
        {getClientFormattedDate(event.event_date, DateFormat.ShortBoth)}
      </div>
    )
  },
  {
    name: 'Provider',
    selector: () => 'providerName',
    sortable: true,
    grow: 2,
    cell: (event: Event) => (
      <div className="table-cell gray center">{event.providerName || 'None'}</div>
    )
  },
  {
    name: 'Email',
    selector: () => 'email',
    sortable: true,
    grow: 2,
    cell: (event: Event) => (
      <div className="table-cell gray d-flex justify-content-center text-wrap">
        {event.userEmail}
      </div>
    )
  },
  {
    name: 'Subject',
    selector: () => 'subject',
    sortable: true,
    grow: 2,
    cell: (event: Event) => <div className="table-cell gray center">{event.subject}</div>
  },
  {
    name: 'Status',
    selector: () => 'ed.status',
    sortable: false,
    grow: 1,
    cell: (event: Event) => (
      <div className="table-cell gray center">
        {' '}
        <CustomToolTip tooltipVisible={!!event.failMessage} message={event.failMessage || ''}>
          {event.status}
        </CustomToolTip>
      </div>
    )
  }
];

const setFiltersFromHistory: any = (savedSearchDetails: any) => {
  let filterableArray = [];
  const { filterElements } = savedSearchDetails;
  let providerId = 0;
  let subject = '';
  let email = '';

  if (!filterElements) {
    return {};
  }

  if (!Array.isArray(filterElements.conditionals)) {
    filterableArray.push(filterElements);
  } else {
    filterableArray = filterElements.conditionals;
  }

  filterableArray.forEach(
    (condition: {
      comparator: string;
      conditionals: any;
      name: string;
      value: any;
      label?: string;
    }) => {
      const { name, value } = condition;

      switch (name) {
        case 'providerId':
          providerId = Number(value);
          break;
        case 'subject':
          subject = value;
          break;
        case 'email':
          email = value;
          break;
        case 'userRole':
          if (value === 'gov') {
            providerId = -1;
          } else if (value === 'dno') {
            providerId = -2;
          } else providerId = -3;
          break;
        default:
          break;
      }
    }
  );

  return { providerId, email, subject };
};
const getFilter = (selectedProvider: number, email: string, subject: string) => {
  let filterElements = newConditionalFilterElement('AND');

  if (email) {
    addCondition(filterElements, newLeafFilterElement('email', 'LIKE', email.trim()));
  }

  if (subject) {
    addCondition(filterElements, newLeafFilterElement('subject', 'LIKE', subject.trim()));
  }

  if (selectedProvider) {
    if (selectedProvider === -1) {
      addCondition(filterElements, newLeafFilterElement('userRole', 'EQ', 'gov'));
    } else if (selectedProvider === -2) {
      addCondition(filterElements, newLeafFilterElement('userRole', 'EQ', 'dno'));
    } else if (selectedProvider === -3) {
      addCondition(filterElements, newLeafFilterElement('userRole', 'EQ', 'partner'));
    } else {
      addCondition(
        filterElements,
        newLeafFilterElement('providerId', 'EQ', selectedProvider.toString())
      );
    }
  }
  return simplifyFilterElement(filterElements);
};
const PAGENAME = 'emailEvents';

const EmailEvents: FC<IProp> = ({ events, paginationTotalRows, getEventsList }) => {
  const searchParams = getFromLocalStorage(PAGENAME) || '';
  const savedSearchDetails = decodeQueryParams(PAGENAME, searchParams);
  const [selectedProvider, setSelectedProvider] = useState<number>(
    setFiltersFromHistory(savedSearchDetails).providerId
  );
  const [email, setEmail] = useState<string>(setFiltersFromHistory(savedSearchDetails).email);
  const [subject, setSubject] = useState<string>(setFiltersFromHistory(savedSearchDetails).subject);
  const [filterElements, setFilterElements] = useState<FilterElement | undefined>(
    savedSearchDetails.filterElements || newConditionalFilterElement('')
  );
  const [paginationParams, setPaginationParams] = useState<Pagination>(
    savedSearchDetails.paginationParams || {
      ...defaultPagination(),
      sort: 'event_date',
      order: 'desc'
    }
  );
  useEffect(() => {
    getEventsList(paginationParams, filterElements);
  }, []);

  useEffect(() => {
    setFilterElements(getFilter(selectedProvider, email, subject));
  }, [selectedProvider, email, subject]);

  useEffect(() => {
    const defaultSearchParams: Pagination = { ...paginationParams, page: 1 };

    const searchParams = encodeQueryParams(
      PAGENAME,
      { paginationParams: defaultSearchParams, filterElements },
      {}
    );

    saveToLocalStorage(PAGENAME, searchParams);
    getEventsList(paginationParams, filterElements);
  }, [paginationParams]);

  const handleChange = (e: any, key: string) => {
    switch (key) {
      case 'providerId':
        setSelectedProvider(e.value);
        break;
      case 'email':
        setEmail(e.value);
        break;
      case 'subject':
        setSubject(e.value);
        break;
    }
  };

  const searchEvents = () => {
    const defaultSearchParams: Pagination = { ...paginationParams, page: 1 };

    const searchParams = encodeQueryParams(
      PAGENAME,
      { paginationParams: defaultSearchParams, filterElements },
      {}
    );
    saveToLocalStorage(PAGENAME, searchParams);
    getEventsList(defaultSearchParams, filterElements);
  };

  const clearFilters = () => {
    setSelectedProvider(0);
    setEmail('');
    setSubject('');
  };

  const updatePagination = (params: Pagination) => {
    if (
      paginationParams.page === params.page &&
      paginationParams.order === params.order &&
      paginationParams.sort === params.sort &&
      paginationParams.pageSize === params.pageSize
    ) {
      return;
    }
    setPaginationParams({ ...paginationParams, ...params });
  };

  return (
    <Card>
      <CardHeader>
        <Label className="telecom-label mb-3" style={{ fontSize: '20px' }}>
          Sent Email Deliverability
        </Label>
        <div className="d-flex justify-content-between">
          <div className="d-flex ms-3 mb-4 flex-row col-9">
            <div className="col-3">
              <ProvidersSelect
                onChange={(option) => handleChange(option, 'providerId')}
                option={selectedProvider}
                addAllItem={true}
                addGovProvider
                includeInactive
              />
            </div>
            <div className="col-3 ms-2">
              <InputFormGroup
                inputClassName="csd-search"
                inputPlaceholder="Enter Email Adress"
                inputValue={email}
                inputOnChange={(option) => handleChange(option.target, 'email')}
              />
            </div>
            <div className="col-3 ms-2">
              <InputFormGroup
                inputClassName="csd-search"
                inputPlaceholder="Enter Email Subject"
                inputValue={subject}
                inputOnChange={(option) => handleChange(option.target, 'subject')}
              />
            </div>
          </div>
          <div className="ms-2 row-1">
            <Button color="primary" onClick={searchEvents}>
              <i className="fa fa-search pe-2" />
              Search
            </Button>
            <button
              type="button"
              className="btn btn-link ms-2"
              onClick={() => {
                clearFilters();
              }}
            >
              Clear
            </button>
          </div>
        </div>
      </CardHeader>
      <CardBody className="card-body-traceback">
        <CustomDataTable
          columns={columns}
          defaultSortFieldId={paginationParams.sort}
          defaultSortAsc={paginationParams.order === 'asc'}
          defaultPage={paginationParams.page}
          defaultPageSize={paginationParams.pageSize}
          pagination={true}
          paginationTotalRows={paginationTotalRows}
          tableData={events}
          updatePaginationParams={updatePagination}
        />
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state: any, ownProps: any) => {
  const sm = stateMappings(state);

  return {
    events: sm.messaging.events,
    paginationTotalRows: sm.messaging.eventMeta.TotalCount
  };
};

const mapActionsToProps = {
  getEventsList
};

export default connect(mapStateToProps, mapActionsToProps)(EmailEvents);
