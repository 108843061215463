import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';

interface IProps {
  containerClassnames?: string;
  headerTitle?: string;
  children?: React.ReactNode;
}
const BaseCard: React.FC<IProps> = ({ containerClassnames, headerTitle, children }) => {
  return (
    <Card className={`card-base_main ${containerClassnames}`}>
      <CardHeader>
        <h4 className="mb-0">{headerTitle}</h4>
      </CardHeader>
      <CardBody>{children}</CardBody>
    </Card>
  );
};
export default BaseCard;
