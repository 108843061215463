import React, { Fragment, MouseEvent, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Row } from 'reactstrap';
import { Comment } from '../../../interfaces/comment';
import { TfHop } from '../../../interfaces/tfhop';
import { DirectedValues } from '../../comments/AddHopComment';
import TfHopComment from './TfHopComment';

interface IProps {
  tfhop: TfHop;
  isCallDestinationPopulated: boolean;
  directedTo: DirectedValues;
}

const HopComments: React.FC<IProps> = ({ tfhop, isCallDestinationPopulated, directedTo }) => {
  const { hash } = useLocation();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (hash !== '' || tfhop.comments?.length || tfhop.relatedComments?.length) {
      setVisible(true);
    }
  }, [hash, tfhop.comments?.length, tfhop.relatedComments?.length]);

  const handleDropDown = (event: MouseEvent<HTMLElement>) => {
    setVisible(!visible);
    event.stopPropagation();
  };

  const comments = (tfhop.comments || [])
    .filter((comment) => comment.directedTo === directedTo.value)
    .sort((a, b) => b.commentId - a.commentId);

  const additionalComments = (
    directedTo.value === 'upstream'
      ? (tfhop.relatedComments || []).filter((comment) => comment.directedTo === 'downstream')
      : directedTo.value === 'downstream'
        ? (tfhop.relatedComments || []).filter((comment) => comment.directedTo === 'upstream')
        : []
  ).sort((a, b) => b.commentId - a.commentId);

  return (
    <Fragment>
      <div
        className="traceback-sub-title d-flex align-items-center"
        onClick={(e) => handleDropDown(e)}
        role="button"
      >
        <h5 className="p-0">
          <i
            className={`${visible ? 'fa-solid fa-angle-down' : 'fa-solid fa-angle-right'} pe-2  
              ${comments.length + additionalComments.length > 0 ? 'd-inline' : 'd-none'}`}
            style={{ color: '#08335d' }}
          ></i>
          {directedTo.label}:
        </h5>
        <h5 className="ps-2">{comments.length + additionalComments.length}</h5>
      </div>
      <Row
        className={visible ? 'd-inline' : 'd-none'}
        style={{ minWidth: '-webkit-fill-available' }}
      >
        {comments.map(
          (comment: Comment) =>
            !comment.replyToCommentID && (
              <TfHopComment
                key={'tfhopComment-' + comment.commentId}
                uniqueId={'button' + comment.commentId}
                tfhop={tfhop}
                hash={hash}
                isCallDestinationPopulated={isCallDestinationPopulated}
                comment={comment}
                directedTo={directedTo}
                replies={[
                  ...comments.filter((reply) => reply.replyToCommentID === comment.commentId)
                ].sort((a, b) => a.commentId - b.commentId)}
              />
            )
        )}
        {additionalComments.map(
          (comment) =>
            !comment.replyToCommentID && (
              <TfHopComment
                key={'tfhopComment-' + comment.commentId}
                tfhop={tfhop}
                hash={hash}
                isCallDestinationPopulated={isCallDestinationPopulated}
                comment={comment}
                directedTo={directedTo}
                replies={[
                  ...additionalComments.filter(
                    (reply) => reply.replyToCommentID === comment.commentId
                  )
                ].sort((a, b) => a.commentId - b.commentId)}
              />
            )
        )}
      </Row>
    </Fragment>
  );
};

export default HopComments;
