import {
  addEmailSubscriptionApiCall,
  addUserApiCall,
  getAllUsersApiCall,
  getRedirectLocationApiCall,
  resetPasswordApiCall,
  setUserInsightAgreedApiCall
} from './apiCalls';

import {
  getAllUsers,
  getRedirectLoc,
  resetPasswordError,
  setErrorMessage,
  setLoadingStatus
} from './actions';

import { Pagination } from '../../interfaces/pagination';
import { CreatePasswordPostData, EmailSubscription } from '../../interfaces/user';
import { FilterElement } from '../../lib/FilterElement';
import { logoutOnAuthError } from '../../lib/apiRequest';
import { getUser, rememberLastPage, signInUser } from '../auth/actions';
import { getUserInfo } from '../auth/apiCalls';

const setStatus = (status: boolean, message: string) => async (dispatch: any) => {
  dispatch(setErrorMessage(message));
  dispatch(setLoadingStatus(status));
};

export const getUsers =
  (params: Pagination, filterElements: FilterElement) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    try {
      const users = await getAllUsersApiCall(params, filterElements);
      if (users && users.data) {
        dispatch(getAllUsers(users.data));
      }
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };

export const addUser = (user: any) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    await addUserApiCall(user);
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const getRedirectLocation =
  (token: String, userId: Number, location: string) => async (dispatch: any) => {
    dispatch(getRedirectLoc(''));
    try {
      const response = await getRedirectLocationApiCall(token, userId, location);
      const user = await getUserInfo(response.data.data.id);
      if (!location.startsWith('/create-password') && user && user.data && user.data.data) {
        localStorage.setItem('user', JSON.stringify(user.data.data));
        localStorage.setItem('magicLinkLogIn', 'true');
        if (location.startsWith('/hops/hop')) {
          localStorage.setItem('locationPermitted', location);
        }
      }
      dispatch(signInUser(response.data));
      dispatch(getUser(user.data));
      dispatch(getRedirectLoc(location.toString()));
    } catch (error: any) {
      dispatch(rememberLastPage(location));
      dispatch(
        getRedirectLoc(
          (location.startsWith('/create-password') ? '/reset-password' : `/login`) +
            `?errorMessage=invalid`
        )
      );
    }
  };

export const resetPasswordAction =
  (userId: Number, content: CreatePasswordPostData) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    dispatch(resetPasswordError(false));
    try {
      const response = await resetPasswordApiCall(userId, content);
      const user = await getUserInfo(userId);
      if (user && user.data && user.data.data) {
        localStorage.setItem('user', JSON.stringify(user.data.data));
      }
      dispatch(signInUser(response.data));
      dispatch(setStatus(false, ''));
      window.location.href = `/`;
    } catch (error: any) {
      dispatch(setStatus(false, ''));
      dispatch(resetPasswordError(true));
    }
  };

export const addEmailSubscription = (subscription: EmailSubscription) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  try {
    await addEmailSubscriptionApiCall(subscription);
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const setUserInsightAgreed = (userId: number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  try {
    const response = await setUserInsightAgreedApiCall(userId);
    const user = await getUserInfo(userId);
    if (user && user.data && user.data.data) {
      localStorage.setItem('user', JSON.stringify(user.data.data));
      dispatch(getUser(user.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};
