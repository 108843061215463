import { sub } from 'date-fns';
import { convertToTimeZone } from 'date-fns-timezone';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { Button, Label } from 'reactstrap';
import { ReportFormat, ReportPostData, ReportType } from '../../../interfaces/report';
import { getEndOfTheDay, getStartOfTheDay } from '../../../lib/utilities';
import { getReportObject } from '../../../redux/report/thunks';
import CampaignsSelect from '../../CampaignsSelect';

interface IProps {
  reportName: string;
  narrativeIntro: string;
  getReportObject: Function;
}

const CampaignReport: React.FC<IProps> = ({ reportName, narrativeIntro, getReportObject }) => {
  const [startDate, setStartDate] = useState(sub(new Date(), { months: 1 }));
  const [endDate, setEndDate] = useState(new Date());
  const [campaign, setCampaign] = useState(0);
  const [includeFullCallPath, setIncludeFullCallPath] = useState(false);
  const [obscurePhoneNumbers, setObscurePhoneNumbers] = useState(false);
  const [removeComments, setRemoveComments] = useState(false);
  const [markConfidential, setMarkConfidential] = useState(false);
  const submit = (format: ReportFormat) => {
    if (campaign) {
      getReportObject(
        {
          startDate: getStartOfTheDay(startDate).toISOString(),
          endDate: getEndOfTheDay(endDate).toISOString(),
          includeFullCallPath,
          markConfidential,
          name: reportName,
          narrativeIntro,
          obscurePhoneNumbers,
          campaignId: campaign,
          removeComments
        } as ReportPostData,
        ReportType.CampaignReport,
        format
      );
    }
  };
  const handleCheck = (e: any, key: string) => {
    switch (key) {
      case 'startDate':
        setStartDate(e);
        break;
      case 'endDate':
        setEndDate(e);
      case 'campaign':
        setCampaign(e ? e.value : 0);
        break;
      case 'includeFullCallPath':
        setIncludeFullCallPath((v) => !v);
        break;
      case 'obscurePhoneNumbers':
        setObscurePhoneNumbers((v) => !v);
        break;
      case 'removeComments':
        setRemoveComments((v) => !v);
        break;
      case 'markConfidential':
        setMarkConfidential((v) => !v);
        break;
      case 'word':
        submit(ReportFormat.Word);
        break;
    }
  };

  return (
    <div className="d-flex flex-column">
      <Label className="telecom-label ps-0">Provide The Date Range *</Label>
      <div className="d-flex flex-row mb-4">
        <div style={{ minWidth: '150px', maxWidth: '200px' }}>
          <DatePicker
            maxDate={endDate ? convertToTimeZone(endDate, { timeZone: 'GMT' }) : new Date()}
            selected={startDate ? convertToTimeZone(startDate, { timeZone: 'GMT' }) : undefined}
            onChange={(option) => handleCheck(option, 'startDate')}
            placeholderText="&#xf133; mm/dd/yyyy"
            clearButtonTitle={'Clear'}
            dateFormat="MM/dd/yyyy"
            isClearable
          />
        </div>
        <span className="ms-2 me-2">-</span>
        <div style={{ minWidth: '150px', maxWidth: '200px' }}>
          <DatePicker
            maxDate={new Date()}
            selected={endDate ? convertToTimeZone(endDate, { timeZone: 'GMT' }) : undefined}
            minDate={convertToTimeZone(startDate, { timeZone: 'GMT' })}
            onChange={(option) => handleCheck(option, 'endDate')}
            placeholderText="&#xf133; mm/dd/yyyy"
            clearButtonTitle={'Clear'}
            isClearable
          />
        </div>
      </div>
      <div className="telecom-label ps-0">Select a Campaign *</div>
      <div className="ps-0 mb-4 col-2">
        <CampaignsSelect
          onChange={(option) => handleCheck(option, 'campaign')}
          value={campaign}
          isSearchable
          placeholder="Select a Campaign..."
        />
      </div>

      <div className="me-0 mb-4 d-flex">
        <label className="form-label tile-label">
          Include Full Traceback Call Paths
          <input
            type="checkbox"
            onChange={(option) => handleCheck(option, 'includeFullCallPath')}
            checked={includeFullCallPath}
          />
          <span className="checkmark tile-checkmark" />
        </label>
        <label className="form-label tile-label" style={{ fontSize: '15px' }}>
          Partially Obscure Phone Numbers
          <input
            type="checkbox"
            onChange={(option) => handleCheck(option, 'obscurePhoneNumbers')}
            checked={obscurePhoneNumbers}
          />
          <span className="checkmark tile-checkmark" />
        </label>
        <label className="form-label tile-label" style={{ fontSize: '15px' }}>
          Without Per-Hop Comments
          <input
            type="checkbox"
            onChange={(option) => handleCheck(option, 'removeComments')}
            checked={removeComments}
          />
          <span className="checkmark tile-checkmark" />
        </label>
        <label className="form-label tile-label" style={{ fontSize: '15px' }}>
          Mark as Confidential
          <input
            type="checkbox"
            onChange={(option) => handleCheck(option, 'markConfidential')}
            checked={markConfidential}
          />
          <span className="checkmark tile-checkmark" />
        </label>
      </div>
      <div className="d-flex justify-content-md-center">
        <Button
          className="telecom-btn me-2"
          color="primary"
          disabled={!campaign}
          style={{
            height: '28px',
            padding: '5px',
            fontSize: '14px',
            width: '150px'
          }}
          onClick={(option) => handleCheck(option, 'word')}
        >
          {`Create Word Doc`}
        </Button>
      </div>
      <div className="m-4" style={{ borderBottom: '1px solid #ccc' }} />
    </div>
  );
};

const mapActionsToProps = { getReportObject };
export default connect(null, mapActionsToProps)(CampaignReport);
