import { Traceback } from './actionEnum';

const meta = {
  TotalCount: 0,
  PageSize: 0,
  CurrentPage: 0,
  TotalPages: 1
};

const defaultState: any = {
  tracebacks: [],
  attachments: [],
  requestors: [],
  traceback: {
    tracebackId: 0,
    campaignId: 0,
    campaignName: '',
    callingTN: '',
    calledTN: '',
    tracebackTime: '',
    termCarrierId: '',
    termCarrierOCN: '',
    termLineType: '',
    termState: '',
    callerName: '',
    create_date: '',
    createdBy: '',
    creatorProvider: '',
    elapsed: 0,
    strikeExempt: true,
    dnc: '',
    dno: false,
    description: '',
    audioName: 0,
    audioUrl: 0,
    audioUrlPath: 0,
    updateFlag: 0,
    update_date: ''
  },
  spreadsheetTracebacks: [],
  spreadSheetTracebackCount: null,
  submitterDetails: {},
  meta,
  loading: 0,
  error: ''
};

const reducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case Traceback.GET_TRACEBACKS:
      return {
        ...state,
        tracebacks: action.payload.data,
        meta: action.payload.paginationMeta || meta
      };

    case Traceback.GET_TRACEBACK_STATS:
      return { ...state, tbStats: action.payload.data };

    case Traceback.GET_TRACEBACK:
      return { ...state, traceback: action.payload.data };

    case Traceback.ADD_TRACEBACK:
      return { ...state };

    case Traceback.CLEAR_TRACEBACKS:
      return { ...state, tracebacks: undefined, meta: meta };

    case Traceback.EDIT_TRACEBACK:
      return { ...state, traceback: action.payload.data };

    case Traceback.ADD_ATTACHMENTS:
      return { ...state, attachments: [...state.attachments, action.payload.data] };

    case Traceback.GET_ATTACHMENTS:
      return { ...state, attachments: action.payload.data };

    case Traceback.SET_TRACEBACK_LOADING_STATUS:
      if (action.payload) return { ...state, loading: state.loading + 1 };
      else return { ...state, loading: state.loading === 0 ? state.loading : state.loading - 1 };

    case Traceback.SET_TRACEBACK_ERROR_MESSAGE:
      return { ...state, error: action.payload };

    case Traceback.GET_TRACEBACK_STATUSES:
      return { ...state, statuses: action.payload };

    case Traceback.GET_REQUESTORS:
      return { ...state, requestors: action.payload.data };
    case Traceback.GET_SPREADSHEET_TRACEBACKS:
      return {
        ...state,
        spreadsheetTracebacks: action.payload.data ? action.payload.data : [],
        spreadSheetTracebackCount: action.payload.paginationMeta.TotalCount,
        submitterDetails: action.payload.submitterDetails
      };
    default:
      return state;
  }
};

export default reducer;
