import { Pagination } from '../../interfaces/pagination';
import { logoutOnAuthError } from '../../lib/apiRequest';
import {
  getNotifications,
  getNotificationsCount,
  setErrorMessage,
  setLoadingStatus
} from './actions';
import {
  deleteNotificationApiCall,
  deleteUserNotificationsApiCall,
  getAllNotificationsApiCall,
  getNotificationsCountApiCall
} from './apiCalls';

export const getUserNotificationsCount = () => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  try {
    const notificationsCount = await getNotificationsCountApiCall();
    if (notificationsCount && notificationsCount.data >= 0) {
      dispatch(getNotificationsCount(notificationsCount.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};
export const getAllNotifications = (params: Pagination) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  try {
    const notifications = await getAllNotificationsApiCall(params);
    if (notifications && notifications.data) dispatch(getNotifications(notifications.data));
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const deleteNotification = (notificationId: number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  try {
    const response = await deleteNotificationApiCall(notificationId);
    if (response.data === 'success') {
      dispatch(setStatus(false, ''));
    }
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const deleteUserNotifications = () => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  try {
    const response = await deleteUserNotificationsApiCall();
    if (response.data === 'success') {
      dispatch(setStatus(false, ''));
    }
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};
const setStatus = (status: boolean, message: string) => async (dispatch: any) => {
  dispatch(setErrorMessage(message));
  dispatch(setLoadingStatus(status));
};
