import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import Breadcrumb from '../components/Breadcrumbs';
import TraceforwardEditor from '../components/traceforward/traceforwardEditor';
import { emptyTraceforward } from '../interfaces/traceforward';
import { stateMappings } from '../redux/stateMappings';
import { addTraceforwardObject } from '../redux/traceforward/thunks';

interface IProps {
  addTraceforwardObject: Function;
  createdBy: string;
}

const traceforward = emptyTraceforward();

const TraceforwardAdd: React.FC<IProps> = ({ addTraceforwardObject, createdBy }) => {
  return (
    <Fragment>
      <Breadcrumb title="traceforwards" destination={'/traceforwards'} detail="Add Traceforward" />
      <Card className="telecom-card">
        <CardHeader className="telecom-card-header">Add Traceforward</CardHeader>
        <CardBody>
          <div className="d-flex justify-content-center">
            <Col xs="12" sm="11" lg="10">
              <TraceforwardEditor
                isAdd
                traceforward={traceforward}
                createdBy={createdBy}
                traceforwardServer={addTraceforwardObject}
              />
            </Col>
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return { createdBy: sm.user.name };
};

const mapActionsToProps = { addTraceforwardObject };

export default connect(mapStateToProps, mapActionsToProps)(TraceforwardAdd);
