import React from 'react';
import { Col, FormGroup, Row } from 'reactstrap';
import { DateFormat } from '../../enum/DateFormat';
import { TfHop } from '../../interfaces/tfhop';
import { Traceforward } from '../../interfaces/traceforward';
import { getClientFormattedDate, getElapsedTime } from '../../lib/utilities';

interface IProps {
  tfhop: TfHop;
  traceforward: Traceforward;
}

const NFnrSubmit: React.FC<IProps> = ({ tfhop, traceforward }) => (
  <div className="highlighted-background">
    <h5 className="p-0 traceforward-sub-title mb-3">Call Destination Details</h5>
    <div className="d-flex flex-row justify-content-around mb-1">
      <div style={{ marginLeft: '25px' }}>
        <label className="telecom-label">Reason</label>
        {tfhop.nfNr === 'isRecordUn' ? (
          <span>Record Unavailable</span>
        ) : tfhop.nfNr === 'isCallOld' ? (
          <span>Call is too old</span>
        ) : tfhop.nfNr === 'isRefusing' ? (
          <span>We refuse to share this information</span>
        ) : (
          <span>Provider is non-responsive</span>
        )}
      </div>
      <div>
        <label className="telecom-label">Date & Time Completed</label>
        {getClientFormattedDate(tfhop.completed, DateFormat.MediumBoth)}
      </div>
      <div>
        <label className="telecom-label">Total Time Elapsed</label>
        {getElapsedTime(traceforward.elapsed)}
      </div>
    </div>
    <div className="mb-3">
      <div style={{ marginLeft: '25px' }}>
        <label className="telecom-label">Explanation</label>
        {tfhop.nfnrReason}
      </div>
    </div>
  </div>
);

const CustomerSubmitForm: React.FC<IProps> = ({ tfhop, traceforward }) => (
  <div className="highlighted-background mb-3">
    <h5 className="p-0 traceforward-sub-title mb-3">Call Destination Details</h5>
    <Row className="mb-1">
      <Col md="4">
        <FormGroup>
          <label className="telecom-label">Business Name</label>
          {tfhop.customer}
        </FormGroup>
      </Col>
      <Col md="4">
        <FormGroup>
          <label className="telecom-label">Country</label>
          {tfhop.country}
        </FormGroup>
      </Col>
      <Col md="4">
        <FormGroup>
          <label className="telecom-label">Address</label>
          {tfhop.address}
        </FormGroup>
      </Col>
    </Row>
    <Row className="mb-1">
      <Col md="4">
        <FormGroup>
          <label className="telecom-label">Contact Email</label>
          {tfhop.contactEmail}
          {(tfhop.contactEmailValidation.status === 'invalid' ||
            tfhop.contactEmailValidation.aws_reason) && (
            <span className="me-2" style={{ color: 'red' }}>
              {tfhop.contactEmailValidation.aws_reason
                ? 'SES Blocked'
                : tfhop.contactEmailValidation.status}{' '}
              {tfhop.contactEmailValidation.domain}
            </span>
          )}
        </FormGroup>
      </Col>
      <Col md="4">
        <FormGroup>
          <label className="telecom-label">IP Address</label>
          {tfhop.ipAddress}
        </FormGroup>
      </Col>
      <Col md="4">
        <FormGroup>
          <label className="telecom-label">City</label>
          {tfhop.city}
        </FormGroup>
      </Col>
    </Row>
    <Row className="mb-1">
      <Col md="4">
        <FormGroup>
          <label className="telecom-label">Contact Phone</label>
          {tfhop.contactPhone}
        </FormGroup>
      </Col>
      <Col md="4">
        <FormGroup>
          <label className="telecom-label">Customer Type</label>
          {tfhop.customerType}
        </FormGroup>
      </Col>
      <Col md="4">
        <FormGroup>
          <label className="telecom-label">State / Region</label>
          {tfhop.state}
        </FormGroup>
      </Col>
    </Row>
    <Row className="mb-1">
      <Col md="4">
        <FormGroup>
          <label className="telecom-label">Contact Name</label>
          {tfhop.contactName}
        </FormGroup>
      </Col>
      <Col md="4">
        <FormGroup>
          <label className="telecom-label">Average Daily Traffic</label>
          {tfhop.averageTraffic}
        </FormGroup>
      </Col>
      <Col md="4">
        <FormGroup>
          <label className="telecom-label">Zip / Postal Code</label>
          {tfhop.zipCode}
        </FormGroup>
      </Col>
    </Row>
    <Row className="mb-3">
      <Col>
        <FormGroup>
          <label className="telecom-label">Both Originator+Calling Party</label>
          {tfhop.isCallOrig ? 'Yes' : 'No'}
        </FormGroup>
      </Col>
    </Row>
    <Row className="mb-3">
      <Col md="4">
        <FormGroup>
          <label className="telecom-label">Action Taken</label>
          {tfhop.actionTaken}
        </FormGroup>
      </Col>
      <Col md="8">
        <FormGroup>
          <label className="telecom-label">Steps Taken</label>
          {tfhop.explanation}
        </FormGroup>
      </Col>
    </Row>
  </div>
);

const CallDestinationDetailsRow: React.FC<IProps> = ({ tfhop, traceforward }) => {
  return tfhop.status !== 1 ? (
    tfhop.customer ? (
      <CustomerSubmitForm tfhop={tfhop} traceforward={traceforward} />
    ) : tfhop.nfNr ? (
      <NFnrSubmit tfhop={tfhop} traceforward={traceforward} />
    ) : null
  ) : null;
};

export default CallDestinationDetailsRow;
