import React, { Fragment } from 'react';
import { TableColumn } from 'react-data-table-component';
import ColumnTooltip from '../../components/ColumnTooltip';
import CustomToolTip from '../../components/CustomToolTip';
import ProviderPanel from '../../components/ProviderPanel';
import ReputationDetails from '../../components/stateConverter';
import { DateFormat } from '../../enum/DateFormat';
import { userTypes } from '../../enum/userTypes';
import { ProviderSummaryRow } from '../../interfaces/provider';
import { buildParams } from '../apiRequest';
import { getClientFormattedDate, getElapsedTime } from '../utilities';

export const providerColumns = (
  userType: number,
  followingTable?: boolean
): TableColumn<ProviderSummaryRow>[] => [
  {
    name: '',
    width: '20px',
    cell: () => <div />
  },
  {
    name: 'Provider',
    selector: () => 'name',
    id: 'column-name',
    sortable: true,
    grow: 2,
    cell: (row: ProviderSummaryRow) => (
      <ProviderPanel
        provider={row.provider}
        hyperlink
        rmdFlag={row.summary && !!row.summary.numOfHopsWithRMDFlag && 'multipleRMD'}
      />
    )
  },
  {
    name: (
      <ColumnTooltip columnType="Tracebacks" tooltipVisible={userType === userTypes.GovUser}>
        Tracebacks
      </ColumnTooltip>
    ),
    selector: () => 'numberOfHops',
    sortable: true,
    grow: 1,
    cell: (providerSummary: ProviderSummaryRow) =>
      providerSummary.summary.numberOfHops ? (
        <>
          {userType === userTypes.Admin ? (
            <div className="table-cell blue center">
              <a
                href={'/hops?' + buildParams({ providerId: providerSummary.provider?.providerId })}
              >
                {providerSummary.summary.numberOfHops}
              </a>
            </div>
          ) : (
            <div className="table-cell  center">
              <span>{providerSummary.summary.numberOfHops}</span>
            </div>
          )}
        </>
      ) : (
        <span className=" table-cell gray center">{providerSummary.summary.numberOfHops || 0}</span>
      )
  },
  {
    name: 'Open',
    selector: () => 'numOfOpenHops',
    sortable: true,
    grow: 1,
    omit: userType === userTypes.GovUser,
    cell: (providerSummary: ProviderSummaryRow) => {
      return (
        <span className="table-cell gray center">{providerSummary.summary.numOfOpenHops || 0}</span>
      );
    }
  },
  {
    name: (
      <ColumnTooltip columnType="POE" tooltipVisible={userType === userTypes.GovUser}>
        POE
      </ColumnTooltip>
    ),
    selector: () => 'numOfUSPoEHops',
    sortable: true,
    grow: 1,
    cell: (providerSummary: ProviderSummaryRow) => (
      <span className="table-cell gray center">{providerSummary.summary.numOfUSPoEHops || 0}</span>
    )
  },
  {
    name: 'Downstream',
    selector: () => 'numOfDownstreamHops',
    sortable: true,
    grow: 1,
    omit: true,
    cell: (providerSummary: ProviderSummaryRow) => (
      <span className="table-cell gray center">
        {providerSummary.summary.numOfDownstreamHops || 0}
      </span>
    )
  },
  {
    name: (
      <ColumnTooltip columnType="DownStreamOrigin" tooltipVisible={userType === userTypes.GovUser}>
        <Fragment>
          <i className="fa-solid fa-arrow-down" /> Origin
        </Fragment>
      </ColumnTooltip>
    ),
    selector: () => 'numOfDownstreamOriginHops',
    sortable: true,
    grow: 1,
    cell: (providerSummary: ProviderSummaryRow) => (
      <span className="table-cell gray center">
        {providerSummary.summary.numOfDownstreamOriginHops || 0}
      </span>
    )
  },
  {
    name: (
      <ColumnTooltip columnType="Origin" tooltipVisible={userType === userTypes.GovUser}>
        Origin
      </ColumnTooltip>
    ),
    selector: () => 'numOfOriginHops',
    sortable: true,
    grow: 1,
    cell: (providerSummary: ProviderSummaryRow) =>
      followingTable ? (
        <div className="table-cell center">
          <CustomToolTip
            tooltipVisible={providerSummary.summary.existOriginHopWithShakenErrors}
            message="one or more Stir/Shaken issues"
          >
            <span className="text-dark"> {providerSummary.summary.numOfOriginHops || 0}</span>
            {providerSummary.summary.existOriginHopWithShakenErrors && (
              <span className="text-red">*</span>
            )}
          </CustomToolTip>
        </div>
      ) : (
        <span className="table-cell gray center">
          {providerSummary.summary.numOfOriginHops || 0}
        </span>
      )
  },
  {
    name: (
      <ColumnTooltip columnType="DownStreamNoResp" tooltipVisible={userType === userTypes.GovUser}>
        <Fragment>
          <i className="fa-solid fa-arrow-down" /> No Resp
        </Fragment>
      </ColumnTooltip>
    ),
    selector: () => 'numOfDownstreamNoResponseHops',
    sortable: true,
    grow: 1,
    cell: (providerSummary: ProviderSummaryRow) => (
      <span className="table-cell gray center">
        {providerSummary.summary.numOfDownstreamNoResponseHops || 0}
      </span>
    )
  },
  {
    name: (
      <ColumnTooltip columnType="NoResp" tooltipVisible={userType === userTypes.GovUser}>
        No Resp
      </ColumnTooltip>
    ),
    selector: () => 'numOfNoResponseHops',
    sortable: true,
    grow: 1,
    cell: (providerSummary: ProviderSummaryRow) => (
      <span className="table-cell gray center">
        {providerSummary.summary.numOfNoResponseHops || 0}
      </span>
    )
  },
  {
    name: 'Transit',
    selector: () => 'numOfTransitHops',
    sortable: true,
    omit: true,
    grow: 1,
    cell: (providerSummary: ProviderSummaryRow) => (
      <span className="table-cell gray center">
        {providerSummary.summary.numOfTransitHops || 0}
      </span>
    )
  },
  {
    name: 'Term',
    selector: () => 'numOfTermHops',
    sortable: true,
    grow: 1,
    omit: true,
    cell: (providerSummary: ProviderSummaryRow) => (
      <span className="table-cell gray center">{providerSummary.summary.numOfTermHops || 0}</span>
    )
  },
  {
    name: (
      <ColumnTooltip columnType="NotFound" tooltipVisible={userType === userTypes.GovUser}>
        Not Found
      </ColumnTooltip>
    ),
    selector: () => 'numOfNotFoundHops',
    sortable: true,
    grow: 1,
    cell: (providerSummary: ProviderSummaryRow) => (
      <span className="table-cell gray center">
        {providerSummary.summary.numOfNotFoundHops || 0}
      </span>
    )
  },
  {
    name: <div>Avg Response</div>,
    selector: () => 'avgResponseMinOfHops',
    sortable: true,
    grow: 1,
    cell: (providerSummary: ProviderSummaryRow) => (
      <span className="table-cell gray">
        {getElapsedTime(providerSummary.summary.avgResponseMinOfHops) || 0}
      </span>
    )
  },
  {
    name: (
      <ColumnTooltip columnType="60DayStatus" tooltipVisible={userType === userTypes.GovUser}>
        60-day <br /> Status
      </ColumnTooltip>
    ),
    selector: () => 'ps.score',
    sortable: true,
    width: '75px',
    cell: (providerSummary: ProviderSummaryRow) => {
      return <ReputationDetails reputation={providerSummary.provider?.reputation} />;
    }
  },
  {
    name: (
      <ColumnTooltip columnType="Campaigns" tooltipVisible={userType === userTypes.GovUser}>
        Campaigns
      </ColumnTooltip>
    ),
    selector: () => 'numOfCampaigns',
    sortable: true,
    grow: 1,
    cell: (providerSummary: ProviderSummaryRow) => (
      <span className="table-cell gray center">
        {providerSummary.summary.numOfCampaigns || '-'}
      </span>
    )
  },
  {
    name: (
      <ColumnTooltip columnType="Campaigns" tooltipVisible={userType === userTypes.GovUser}>
        DNOAccess
      </ColumnTooltip>
    ),
    selector: () => 'dno_access',
    sortable: true,
    grow: 1,
    omit: userType !== userTypes.Admin,
    cell: (providerSummary: ProviderSummaryRow) => (
      <span className="table-cell gray center">
        {providerSummary.provider?.dnoAccess ? 'Yes' : 'No'}
      </span>
    )
  },
  {
    //add providers.create date here
    name: 'Date Added',
    selector: () => 'providerCreateDate',
    sortable: true,
    omit: userType === userTypes.GovUser,
    grow: 3,
    cell: (providerSummary: ProviderSummaryRow) => (
      <span className="table-cell gray center">
        {getClientFormattedDate(providerSummary.provider?.create_date, DateFormat.ShortDate) || '-'}
      </span>
    )
  },
  {
    //add providers.create date here
    name: (
      <ColumnTooltip columnType="LastActive" tooltipVisible={userType === userTypes.GovUser}>
        Last Activity
      </ColumnTooltip>
    ),
    selector: () => 'lastActivity',
    sortable: true,
    omit: userType !== userTypes.GovUser,
    grow: 1,
    cell: (providerSummary: ProviderSummaryRow) => (
      <span className="table-cell gray center">
        {getClientFormattedDate(providerSummary.summary.lastActivity, DateFormat.ShortDate) || '-'}
      </span>
    )
  }
];
