import { Pagination } from '../../interfaces/pagination';
import {
  ReportArchiveSearchRequest,
  ReportFormat,
  ReportPostData,
  ReportType
} from '../../interfaces/report';
import { FilterElement } from '../../lib/FilterElement';
import { ApiRequest, buildParams } from '../../lib/apiRequest';

export const getReportApiCall = async (
  content: ReportPostData,
  type: ReportType,
  format: ReportFormat
) =>
  ApiRequest.post(
    `/report/${type}`,
    { ...content },
    {
      responseType:
        type === 'QueryReport' || type === 'SubpoenaReport'
          ? 'json'
          : format === ReportFormat.Excel ||
              format === ReportFormat.Word ||
              format === ReportFormat.Zip
            ? 'blob'
            : 'json',
      headers:
        type === 'QueryReport' || type === 'SubpoenaReport'
          ? { accept: 'application/json' }
          : format === ReportFormat.Excel
            ? { accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
            : format === ReportFormat.Word
              ? {
                  accept: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                }
              : format === ReportFormat.Zip
                ? { accept: 'application/zip' }
                : { accept: 'application/json' }
    }
  );

export const getArchivedReportsApiCall = async (
  params: Pagination,
  filterElements: FilterElement
) =>
  ApiRequest.request({
    method: 'POST',
    url: `/report-archives/search?` + buildParams({ ...params }),
    data: filterElements
  });

export const deleteArchivedReportApiCall = async (id: number) =>
  ApiRequest.request({
    method: 'DELETE',
    url: `/report-archives/${id}`
  });

export const downloadReportApiCall = async (type: ReportType, format: ReportFormat) =>
  ApiRequest.get(`/reportdownload/${type}`, {
    responseType:
      format === ReportFormat.Excel || format === ReportFormat.Word || format === ReportFormat.Zip
        ? 'blob'
        : 'json',
    headers:
      format === ReportFormat.Excel
        ? { accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
        : format === ReportFormat.Word
          ? { accept: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }
          : format === ReportFormat.Zip
            ? { accept: 'application/zip' }
            : { accept: 'application/json' }
  });

export const downloadReportArchiveApiCall = async (id: number) =>
  ApiRequest.get(`/reportarchive/download/${id}`, { responseType: 'blob' });

export const getReportArchiveApiCall = async (
  params: Pagination,
  filterElements: ReportArchiveSearchRequest
) =>
  ApiRequest.request({
    method: 'POST',
    url: `/reportarchive/search?` + buildParams({ ...params }),
    data: filterElements
  });

export const cancelReportArchiveApiCall = async (id: number) =>
  ApiRequest.delete(`/reportarchive/report/${id}`);

export const getInProgresReportArchivesIDs = async () =>
  ApiRequest.get(`/reportarchive/inprogressids`);

export const deleteReportArchiveApiCall = async (id: number) =>
  ApiRequest.delete(`/reportarchive/tracebacks/${id}`);
