import React, { Fragment } from 'react';
import { TableColumn } from 'react-data-table-component';
import ColumnTooltip from '../../components/ColumnTooltip';
import ProviderPanel from '../../components/ProviderPanel';
import { userTypes } from '../../enum/userTypes';
import { ProviderSummaryRow } from '../../interfaces/provider';
import { getElapsedTime } from '../utilities';

export const providerSummaryColumns = (
  userType: number,
  isInternational?: boolean
): TableColumn<ProviderSummaryRow>[] => [
  {
    name: '',
    width: '20px',
    cell: () => <div />
  },
  {
    name: 'Provider',
    selector: () => 'name',
    grow: 2,
    cell: (row: ProviderSummaryRow) =>
      userType === userTypes.Admin ? (
        <ProviderPanel
          provider={row.provider}
          hyperlink
          includeReputation
          rmdFlag={row.summary && !!row.summary.numOfHopsWithRMDFlag && 'multipleRMD'}
        />
      ) : (
        <div className="table-cell gray">{row.provider?.name}</div>
      )
  },

  {
    name: (
      <ColumnTooltip
        columnType="Hops"
        tooltipVisible={userType === userTypes.Provider || userType === userTypes.ProviderManager}
      >
        Total Hops
      </ColumnTooltip>
    ),
    selector: () => 'numberOfHops',
    grow: 1,
    cell: (row: ProviderSummaryRow) =>
      userType === userTypes.Admin ? (
        <>
          {row.summary && row.summary.numberOfHops ? (
            <div className="table-cell blue center">
              <a href={`/hops?providerId=${row.provider?.providerId}`}>
                {row.summary.numberOfHops}
              </a>
            </div>
          ) : (
            <div className="table-cell gray center">
              <span>0</span>
            </div>
          )}
        </>
      ) : (
        <>
          {row.summary && row.summary.numberOfHops ? (
            <div className="table-cell gray center">{row.summary.numberOfHops}</div>
          ) : (
            <div className="table-cell gray center">
              <span>0</span>
            </div>
          )}
        </>
      )
  },
  {
    name: 'Open',
    selector: () => 'numOfOpenHops',
    grow: 1,
    cell: (row: ProviderSummaryRow) => (
      <span className="table-cell gray center">
        {row.summary && row.summary.numOfOpenHops ? row.summary.numOfOpenHops : 0}
      </span>
    )
  },
  {
    name: 'Term',
    selector: () => 'numOfTermHops',
    grow: 1,
    omit: true,
    cell: (row: ProviderSummaryRow) => (
      <span className="table-cell gray center">
        {row.summary && row.summary.numOfTermHops ? row.summary.numOfTermHops : 0}
      </span>
    )
  },
  {
    name: 'Transit',
    selector: () => 'numOfTransitHops',
    grow: 1,
    omit: true,
    cell: (row: ProviderSummaryRow) => (
      <span className="table-cell gray center">
        {row.summary && row.summary.numOfTransitHops ? row.summary.numOfTransitHops : 0}
      </span>
    )
  },
  {
    name: (
      <ColumnTooltip
        columnType="POE"
        tooltipVisible={userType === userTypes.Provider || userType === userTypes.ProviderManager}
      >
        POE
      </ColumnTooltip>
    ),
    selector: () => 'numOfUSPoEHops',
    grow: 1,
    omit: isInternational,
    cell: (row: ProviderSummaryRow) => (
      <span className="table-cell gray center">
        {(row.summary && row.summary.numOfUSPoEHops) || 0}
      </span>
    )
  },
  {
    name: 'Downstream',
    selector: () => 'numOfDownstreamHops',
    grow: 1,
    omit: true,
    cell: (row: ProviderSummaryRow) => (
      <span className="table-cell gray center">
        {(row.summary && row.summary.numOfDownstreamHops) || 0}
      </span>
    )
  },
  {
    name: (
      <ColumnTooltip
        columnType="DownStreamOrigin"
        tooltipVisible={userType === userTypes.Provider || userType === userTypes.ProviderManager}
      >
        <Fragment>
          <i className="fa-solid fa-arrow-down" /> Origin
        </Fragment>
      </ColumnTooltip>
    ),
    selector: () => 'numOfDownstreamOriginHops',
    sortable: true,
    grow: 1,
    cell: (row: ProviderSummaryRow) => (
      <span className="table-cell gray center">
        {(row.summary && row.summary.numOfDownstreamOriginHops) || 0}
      </span>
    )
  },
  {
    name: (
      <ColumnTooltip
        columnType="Origin"
        tooltipVisible={userType === userTypes.Provider || userType === userTypes.ProviderManager}
      >
        Origin
      </ColumnTooltip>
    ),
    selector: () => 'numOfOriginHops',
    grow: 1,
    cell: (row: ProviderSummaryRow) => (
      <span className="table-cell gray center">
        {row.summary && row.summary.numOfOriginHops ? row.summary.numOfOriginHops : 0}
      </span>
    )
  },
  {
    name: (
      <ColumnTooltip
        columnType="DownStreamNoResp"
        tooltipVisible={userType === userTypes.Provider || userType === userTypes.ProviderManager}
      >
        <Fragment>
          <i className="fa-solid fa-arrow-down" /> No Resp
        </Fragment>
      </ColumnTooltip>
    ),
    selector: () => 'numOfDownstreamNoResponseHops',
    sortable: true,
    grow: 1,
    cell: (row: ProviderSummaryRow) => (
      <span className="table-cell gray center">
        {(row.summary && row.summary.numOfDownstreamNoResponseHops) || 0}
      </span>
    )
  },
  {
    name: (
      <ColumnTooltip
        columnType="NoResp"
        tooltipVisible={userType === userTypes.Provider || userType === userTypes.ProviderManager}
      >
        No Resp
      </ColumnTooltip>
    ),
    selector: () => 'numOfNoResponseHops',
    grow: 1,
    cell: (row: ProviderSummaryRow) => (
      <span className="table-cell gray center">
        {row.summary && row.summary.numOfNoResponseHops ? row.summary.numOfNoResponseHops : 0}
      </span>
    )
  },
  {
    name: (
      <ColumnTooltip
        columnType="NotFound"
        tooltipVisible={userType === userTypes.Provider || userType === userTypes.ProviderManager}
      >
        Not Found
      </ColumnTooltip>
    ),
    selector: () => 'numOfNotFoundHops',
    grow: 1,
    cell: (row: ProviderSummaryRow) => (
      <span className="table-cell gray center">
        {row.summary && row.summary.numOfNotFoundHops ? row.summary.numOfNotFoundHops : 0}
      </span>
    )
  },
  {
    name: 'Avg Response',
    selector: () => 'avgResponseMinOfHops',
    grow: 1,
    cell: (row: ProviderSummaryRow) => (
      <span className="table-cell gray center">
        {row.summary && row.summary.avgResponseMinOfHops
          ? getElapsedTime(row.summary.avgResponseMinOfHops)
          : 0}
      </span>
    )
  },
  {
    name: ' ',
    width: '100px',
    cell: () => <div />
  },
  {
    name: (
      <ColumnTooltip
        columnType="Campaigns"
        tooltipVisible={userType === userTypes.Provider || userType === userTypes.ProviderManager}
      >
        Campaigns
      </ColumnTooltip>
    ),
    selector: () => 'numOfCampaigns',
    grow: 1,
    cell: (row: ProviderSummaryRow) => (
      <span className="table-cell gray center">
        {row.summary && row.summary.numOfCampaigns ? row.summary.numOfCampaigns : 0}
      </span>
    )
  }
];
