import { AxiosResponse } from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import Select, { FormatOptionLabelMeta, OnChangeValue } from 'react-select';

export interface SelectOption {
  value: any;
  label: string;
}

export interface ApiLookup {
  apiCall: () => Promise<AxiosResponse<any>>;
  nameLookupFunction: (item: any) => string;
  valueLookupFunction: (item: any) => string;
}

interface IProps {
  className?: string;
  classNamePrefix?: string | null;
  isSearchable?: boolean;
  isClearable?: boolean;
  placeholder?: string;
  inputId?: string;
  addLink?: string;
  typeLabel: string;
  getOptions: SelectOption[] | ApiLookup;
  addAllItem?: boolean;
  noneSelectedByDefault?: boolean;
  removeId?: number;
  selectedOption: any;
  setSelectedOption: (item: OnChangeValue<SelectOption, boolean>) => void;
  formatOptionLabel?: (
    option: SelectOption,
    labelMeta: FormatOptionLabelMeta<SelectOption>
  ) => React.ReactNode;
  isDisabled?: boolean;
  maxMenuHeight?: number;
}

export const getApiItems = async (api: ApiLookup): Promise<SelectOption[]> => {
  const { apiCall, nameLookupFunction, valueLookupFunction } = api;
  const result = await apiCall();
  return result.data.data
    ? result.data.data
        .map(
          (item: any): SelectOption => ({
            label: nameLookupFunction(item),
            value: valueLookupFunction(item)
          })
        )
        .sort((a: SelectOption, b: SelectOption): number => (a.label > b.label ? 1 : -1))
    : [];
};

const CustomSelect: React.FC<IProps> = ({
  className,
  classNamePrefix,
  isSearchable,
  isClearable,
  placeholder,
  inputId,
  addLink,
  typeLabel,
  getOptions,
  addAllItem,
  noneSelectedByDefault,
  removeId,
  selectedOption,
  setSelectedOption,
  formatOptionLabel,
  isDisabled,
  maxMenuHeight
}) => {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<SelectOption[]>([]);

  const addFirstItem = (items: SelectOption[]): SelectOption[] => {
    const firstItem: SelectOption | null = addAllItem
      ? { label: typeLabel, value: 0 }
      : noneSelectedByDefault
        ? {
            label: placeholder ? placeholder : 'Select An Option...',
            value: 0
          }
        : null;
    if (firstItem) {
      items = [firstItem, ...items];
    }
    return items;
  };

  const populateOptions = async (api: ApiLookup) => {
    try {
      setOptions(
        addFirstItem(
          (await getApiItems(api)).filter(
            (item: SelectOption): boolean => Number(item.value) !== removeId
          )
        )
      );
    } catch (error: any) {
      console.log('Error:', error);
    }
  };
  useEffect(() => {
    if (!Array.isArray(getOptions)) {
      populateOptions(getOptions);
    } else {
      setOptions(addFirstItem(getOptions));
    }
    setLoading(false);
  }, [getOptions]);

  const getOptionValue = (): SelectOption | undefined | null => {
    const opt = options.find(
      (item: SelectOption): boolean =>
        item === selectedOption ||
        item.value === selectedOption ||
        item.value === selectedOption?.value ||
        (!selectedOption && item.value === 0)
    );
    return !opt && !selectedOption ? null : opt;
  };

  return !loading ? (
    <Fragment>
      <Select
        className={className}
        classNamePrefix={classNamePrefix || 'customselect'}
        isSearchable={isSearchable}
        isClearable={isClearable}
        placeholder={placeholder}
        inputId={inputId}
        onChange={setSelectedOption}
        options={options}
        value={getOptionValue()}
        formatOptionLabel={formatOptionLabel}
        isDisabled={isDisabled}
        maxMenuHeight={maxMenuHeight}
        styles={{
          placeholder: (base, props) => ({
            ...base,
            marginLeft: '4px'
          }),
          singleValue: (base, props) => ({
            ...base,
            marginLeft: '4px'
          })
        }}
      />

      {addLink && (
        <a href={addLink} style={{ marginLeft: '10px' }}>
          + Add {typeLabel}
        </a>
      )}
    </Fragment>
  ) : null;
};

export default CustomSelect;
