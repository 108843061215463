//This file holds the API calls that hit the /user route for DRY purposes
import { CommentFormat, CommentPostData } from '../../interfaces/comment';
import { Pagination } from '../../interfaces/pagination';
import { FilterElement } from '../../lib/FilterElement';
import { ApiRequest, buildParams } from '../../lib/apiRequest';

export const getCommentsApiCall = async (
  params: Pagination,
  filterElements: FilterElement,
  commentFormat: CommentFormat
) =>
  ApiRequest.request({
    method: 'POST',
    url:
      '/comments/search?' +
      buildParams({
        commentFormat,
        ...params
      }),
    data: filterElements
  });

export const downloadCommentsApiCall = async (params: Pagination, filterElements: FilterElement) =>
  ApiRequest.post(
    '/comments/search?' + buildParams({ ...params }),
    { ...filterElements },
    { headers: { accept: 'application/zip' }, responseType: 'blob' }
  );

export const getCommentApiCall = async (commentId: number) =>
  ApiRequest.request({
    method: 'GET',
    url: `/comments/${commentId}`
  });

export const addCommentApiCall = async (content: any) =>
  ApiRequest.request({
    method: 'POST',
    url: `/comments`,
    data: {
      ...content
    }
  });

export const editCommentApiCall = async (content: CommentPostData) =>
  ApiRequest.request({
    method: 'PUT',
    url: `/comments/${content.commentId}`,
    data: {
      ...content
    }
  });

export const addAttachmentsApiCall = async (commentId: number, content: FormData) =>
  ApiRequest.request({
    method: 'POST',
    url: `/attachments?commentId=${commentId}`,
    data: content,
    headers: {
      'Content-type': 'multipart/form-data'
    }
  });

export const addOrRemoveAttachmentApiCall = async (commentId: number, content: FormData) =>
  ApiRequest.request({
    method: 'PUT',
    url: `/attachment?commentId=${commentId}`,
    data: content,
    headers: {
      'Content-type': 'multipart/form-data'
    }
  });

export const getGroupsApiCall = async () =>
  ApiRequest.request({
    method: 'GET',
    url: `/Groups`
  });

export const addGroupApiCall = async (group: string) =>
  ApiRequest.request({
    method: 'POST',
    url: `/Groups`,
    data: {
      name: group
    }
  });

export const deleteCommentApiCall = async (commentId: number) =>
  ApiRequest.request({
    method: 'DELETE',
    url: `/comments/${commentId}`
  });

export const getNotificationsNewCommentsListApiCall = async (type: string, hopId: string) =>
  ApiRequest.request({
    method: 'GET',
    url: `/newComments/newCommentsNotificationsList/${type}/${hopId}`
  });

export const getHopsNotificationsApiCall = async () =>
  ApiRequest.request({
    method: 'GET',
    url: `/newComments/hopsNotificationList`
  });
