import React, { Fragment, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import BreadCrumb from '../components/Breadcrumbs';
import { getUserApiCall } from '../redux/user/apiCalls';
import UserEditor from './UserEditor';

const UserDetail = () => {
  const [user, setUser] = useState<any>({});

  const { id } = useParams();

  const userId: number = id ? parseInt(id) : 0;

  useEffect(() => {
    (async () => {
      const response = await getUserApiCall(userId, true);
      setUser(response.data.data);
    })();
  }, []);

  return (
    <Fragment>
      <BreadCrumb title="Users" destination="admin-dashboard#users" detail={user.name} />
      <Card className="telecom-card">
        <CardHeader className="telecom-card-header">{user.name}</CardHeader>
        <CardBody>
          <div className="d-flex justify-content-center">
            <Col xs="12" sm="11" lg="10">
              {Object.keys(user).length !== 0 || id === '' ? (
                <UserEditor user={user} id={id} />
              ) : null}
            </Col>
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default UserDetail;
