import * as HoverCardPrimitive from '@radix-ui/react-hover-card';
import styled, { keyframes } from 'styled-components';

const slideUpAndFade = keyframes`
  0% {
    opacity: 0;
    transform: translateY(2px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;

const slideRightAndFade = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-2px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
`;

const slideDownAndFade = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-2px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;

const slideLeftAndFade = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-2px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const StyledContent = styled(HoverCardPrimitive.Content)`
  border-radius: 4px;
  padding: 24px 15px;
  background-color: #2b5b8a;
  color: white;
  z-index: 1;
  white-space: 'normal';
  box-shadow:
    hsl(206, 22%, 7% / 35%) 0px 10px 38px -10px,
    hsl(206, 22%, 7% / 20%) 0px 10px 20px -15px;

  '@media (prefers-reduced-motion: no-preference)' {
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
    '&[data-state="delayed-open"]' {
      '&[data-side="top"]' {
        animation-name: ${slideDownAndFade};
      }

      '&[data-side="right"]' {
        animation-name: ${slideLeftAndFade};
      }

      '&[data-side="bottom"]' {
        animation-name: ${slideUpAndFade};
      }

      '&[data-side="left"]' {
        animation-name: ${slideRightAndFade};
      }
    }
  }
`;

export const StyledArrow = styled(HoverCardPrimitive.Arrow)`
  fill: #2b5b8a;
`;
