import React from 'react';
import ColumnTooltip from '../ColumnTooltip';

const NotSignedByOrigin = () => (
  <ColumnTooltip tooltipVisible columnType={'notSignedByORG'}>
    <span className="span-cell red">Origin Signing Not Provided</span>
  </ColumnTooltip>
);

export default NotSignedByOrigin;
