import isEqual from 'lodash.isequal';
import React, { useEffect, useState } from 'react';
import DataTable, { PaginationOptions, TableColumn } from 'react-data-table-component';
import {
  ExpandableIcon,
  ExpandableRowsComponent,
  RowState,
  TableStyles
} from 'react-data-table-component/dist/DataTable/types';
import { NoDataComponent } from './shared/NoDataComponent';

export interface Pagination {
  order: string;
  page: number;
  pageSize: number;
  sort: string;
}

interface IProps {
  tableData: any[];
  columns: TableColumn<any>[];
  defaultSortFieldId: string;
  noDataText?: string;
  pagination?: boolean;
  paginationTotalRows?: number;
  defaultSortAsc?: boolean;
  useExpandableRows?: boolean;
  useExpandableRowsComponent?: ExpandableRowsComponent<any>;
  updatePaginationParams?: Function;
  defaultPageSize?: number;
  defaultPage?: number;
  selectableRow?: boolean;
  onSelectedRowsChange?: (selected: {
    allSelected: boolean;
    selectedCount: number;
    selectedRows: any[];
  }) => void;
  selectableRowDisabled?: RowState<any>;
  selectableRowSelected?: RowState<any>;
  selectableRowsHighlight?: boolean;
  clearSelectedRows?: boolean;
  expandableRowExpanded?: any;
  expandableIcon?: ExpandableIcon | undefined;
  selectableRowsComponent?: any;
  selectableRowsComponentProps?: any;
  expandableRowsHideExpander?: boolean;
  onRowExpandToggled?: any;
  customStyles?: TableStyles | undefined;
  style?: any;
  maxPageSize?: number;
  conditionalRowStyles?: any;
}

const CustomDataTable: React.FC<IProps> = (props) => {
  const [paginationParams, setPaginationParams] = useState({
    sort: props.defaultSortFieldId,
    order: props.defaultSortAsc ? 'asc' : 'desc',
    page: props.defaultPage || 1,
    pageSize: props.pagination ? props.defaultPageSize || 10 : props.maxPageSize || 10000
  });
  const [expandedRows, setExpandedRows] = useState<any[]>([]);

  const handlePagination = (params: any) => {
    if (props.updatePaginationParams) {
      props.updatePaginationParams(params);
    }
  };

  const handleExpandedRowsChange = (isExpanded: boolean, currentRow: any) => {
    if (isExpanded) {
      setExpandedRows([...expandedRows, currentRow]);
    } else {
      const filteredExpandedRows = expandedRows.filter((row: any) => !isEqual(row, currentRow));
      setExpandedRows(filteredExpandedRows);
    }
  };
  const checkIfRowExpanded = (currentRow: any) => {
    return expandedRows.some((row: any) => isEqual(row, currentRow));
  };

  useEffect(() => {
    handlePagination(paginationParams);
  }, [paginationParams]);
  const handlePageChange = (page: number) => {
    setPaginationParams({ ...paginationParams, page: page });
  };

  useEffect(() => {
    if (typeof props.defaultPage === 'number') handlePageChange(props.defaultPage);
  }, [props.defaultPage]);
  useEffect(() => {
    setExpandedRows([]);
  }, [props.tableData]);
  const handleOnSort = (column: TableColumn<any>, sortDirection: string) => {
    setPaginationParams({
      ...paginationParams,
      order: sortDirection,
      page: 1,
      sort: column.selector ? column.selector(undefined).toString() : props.defaultSortFieldId
    });
  };
  const handleChangeRowsPerPage = (currentRowsPerPage: number, currentPage: number) => {
    setPaginationParams({
      ...paginationParams,
      page: 1,
      pageSize: currentRowsPerPage
    });
  };
  const paginationComponentOptions: PaginationOptions = {
    selectAllRowsItem: false,
    selectAllRowsItemText: 'All'
  };

  const styledColumns =
    props.columns &&
    props.columns.map((col) => {
      let newColumn = col;
      newColumn.conditionalCellStyles = [
        {
          when: (row: any) =>
            row.summary &&
            (row.summary[col.selector ? col.selector(undefined).toString() : ''] === 0 ||
              row.summary[col.selector ? col.selector(undefined).toString() : ''] === null),
          style: {
            span: {
              color: 'lightgray !important'
            }
          }
        }
      ];
      return newColumn;
    });

  return (
    <DataTable
      data={props.tableData || []}
      columns={styledColumns}
      pagination={props.pagination}
      paginationServer={true}
      noHeader
      persistTableHead
      selectableRows={props.selectableRow}
      onSelectedRowsChange={props.onSelectedRowsChange}
      clearSelectedRows={props.clearSelectedRows}
      selectableRowDisabled={props.selectableRowDisabled}
      selectableRowsComponent={props.selectableRowsComponent}
      selectableRowsComponentProps={props.selectableRowsComponentProps}
      selectableRowSelected={props.selectableRowSelected}
      selectableRowsHighlight={props.selectableRowsHighlight}
      expandableRows={props.useExpandableRows}
      expandableRowExpanded={props.expandableRowExpanded || checkIfRowExpanded}
      expandableIcon={props.expandableIcon}
      expandableRowsHideExpander={props.expandableRowsHideExpander}
      onRowExpandToggled={handleExpandedRowsChange}
      paginationTotalRows={props.paginationTotalRows}
      expandableRowsComponent={props.useExpandableRowsComponent}
      noDataComponent={<NoDataComponent noDataText={props.noDataText} />}
      className="data-table"
      defaultSortFieldId={props.defaultSortFieldId}
      defaultSortAsc={props.defaultSortAsc}
      onSort={handleOnSort}
      sortServer={true}
      sortIcon={<i className="fa fa-caret-down" />}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      paginationRowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
      paginationComponentOptions={paginationComponentOptions}
      paginationDefaultPage={paginationParams.page}
      paginationPerPage={paginationParams.pageSize}
      customStyles={props.customStyles}
      style={props.style}
      conditionalRowStyles={props.conditionalRowStyles}
    />
  );
};
export default CustomDataTable;
