import { Pagination } from 'reactstrap';
import { ApiRequest, buildParams } from '../../lib/apiRequest';

export const getInsightsApiCall = async (params: Pagination, req: any) =>
  ApiRequest.request({
    method: 'POST',
    url:
      `insights?` +
      buildParams({
        ...params
      }),
    data: req
  });

export const getInsightsForProviderApiCall = async (providerId: number) =>
  ApiRequest.request({
    method: 'GET',
    url: `insights/${providerId}`
  });
export const getInsightFinalScoreForProviderApiCall = async (providerId: number) =>
  ApiRequest.request({
    method: 'GET',
    url: `insights/${providerId}/finalScore`
  });

export const addInsightWeightApiCall = async (req: any) =>
  ApiRequest.request({
    method: 'PUT',
    url: `insightweights`,
    data: req
  });

export const getInsightWeightsApiCall = async () =>
  ApiRequest.request({
    method: 'GET',
    url: `insightweights`
  });

export const getHoneyBadgerhqApiCall = async (providerId: number) =>
  ApiRequest.request({
    method: 'GET',
    url: `honeybadgerhq/get/${providerId}`
  });

export const generateHoneyBadgerhqApiCall = async (providerId: number) =>
  ApiRequest.request({
    method: 'GET',
    url: `honeybadgerhq/generate/${providerId}`
  });

export const getChartFinalScoreApiCall = async () =>
  ApiRequest.request({
    method: 'GET',
    url: `insights/chartFinalScore`
  });
