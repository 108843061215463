import { DependencyList, EffectCallback, RefObject, useEffect, useRef, useState } from 'react';

export const useDidUpdateEffect = (effect: EffectCallback, deps?: DependencyList) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      return effect();
    }
    didMountRef.current = true;
  }, deps);
};

export function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export type ParamKeyValuePair = [string, string];
export type URLSearchParamsInit =
  | string
  | ParamKeyValuePair[]
  | Record<string, string | string[]>
  | URLSearchParams;

function createSearchParams(init: URLSearchParamsInit = ''): URLSearchParams {
  return new URLSearchParams(
    typeof init === 'string' || Array.isArray(init) || init instanceof URLSearchParams
      ? init
      : Object.keys(init).reduce((memo, key) => {
          const value = init[key];
          return memo.concat(Array.isArray(value) ? value.map((v) => [key, v]) : [[key, value]]);
        }, [] as ParamKeyValuePair[])
  );
}

interface ValidationRule {
  rule: (val: string) => boolean;
  message: string;
}
export interface ValidationRules {
  [key: string]: ValidationRule[];
}

interface ValidationResult {
  [key: string]: string;
}

export const requiredRule = (v: string | boolean | number): boolean => !!v;
export const lengthRule = (l: number) => (v: string) => v.length === l;
export const minLengthRule = (l: number) => (v: string) => v.length > l;
export const regexRule = (r: RegExp) => (v: string) => r.test(v);
export const isFormValid = (rules: ValidationRules, obj: any): boolean =>
  Object.keys(rules).every((key) => {
    const value = obj[key];
    const rule = rules[key];
    return rule.every((v) => v.rule(value));
  });

export const useValidator = (
  rules: ValidationRules
): [(v: any, touch?: boolean) => ValidationResult, () => void] => {
  const [touched, setTouched] = useState<string[]>([]);
  const valid = (key: string, value: any) => {
    const rule = rules[key];
    return rule.reduce((a, v) => (!!a ? a : !v.rule(value) ? v.message : ''), '');
  };
  const validate = (obj: any, key: string, touch?: boolean): string => {
    const value = obj[key];
    if (touched.find((v) => v === key)) {
      return valid(key, value);
    } else if (value || touch) {
      setTouched((v) => [...v, key]);
      return valid(key, value);
    }
    return '';
  };
  return [
    (obj: any, touch?: boolean): ValidationResult => {
      return Object.keys(rules).reduce(
        (a, key) => ({ ...a, [key]: validate(obj, key, touch) }),
        {}
      );
    },
    () => {
      setTouched([]);
    }
  ];
};

export const useOnOutsideClick = (f: () => void) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref && ref.current && !ref.current.contains(event.target as Node | null)) {
        f();
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
  return ref;
};

export const useOnOutsideClickWithRefs = (f: () => void, refs: RefObject<HTMLDivElement>[]) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        refs.every(
          (ref) => !(ref && ref.current) || !ref.current.contains(event.target as Node | null)
        )
      )
        f();
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refs]);
};

export const useIntersection = (element: RefObject<HTMLDivElement>, rootMargin: string) => {
  const [isVisible, setVisible] = useState(false);
  useEffect(() => {
    if (!element.current) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setVisible(entry.isIntersecting);
      },
      { rootMargin }
    );
    element.current && observer.observe(element.current);

    return () => (element.current ? observer.unobserve(element.current) : undefined);
  }, [element.current, rootMargin]);

  return isVisible;
};
