import React, { FC, Fragment, useEffect, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import Select from 'react-select';
import { Button, CardBody, Col, Label, Row } from 'reactstrap';
import { Message } from '../../../interfaces/messaging';
import { Pagination, defaultPagination } from '../../../interfaces/pagination';
import {
  FilterElement,
  addCondition,
  newConditionalFilterElement,
  newLeafFilterElement,
  simplifyFilterElement
} from '../../../lib/FilterElement';
import { getMessageFilterInfo } from '../../../lib/utilities';
import { getMessageList, setMessage } from '../../../redux/messaging/thunks';
import { stateMappings } from '../../../redux/stateMappings';
import CustomDataTable from '../../CustomDataTable';
import { SelectOption } from '../../CustomSelect';
import InputFormGroup from '../../inputFormGroup';
import CancelMessage from './CancelMessage';
import EditMessageButon from './EditMessageButton';

interface IProps {
  messages: Message[];
  setMessage: Function;
  getMessageList: Function;
  paginationTotalRows: number;
  shouldReload: boolean;
}

const Messaging: FC<IProps> = ({
  getMessageList,
  setMessage,
  messages,
  paginationTotalRows,
  shouldReload
}) => {
  const navigate = useNavigate();
  const [subject, setSubject] = useState('');
  const [status, setStatus] = useState<SelectOption[]>([]);
  const [paginationParams, setPaginationParams] = useState<Pagination>({
    ...defaultPagination(),
    sort: 'status',
    order: 'asc'
  });

  const getFilterElement = () => {
    let filterElements = newConditionalFilterElement('AND');
    if (subject !== '') {
      addCondition(filterElements, newLeafFilterElement('subject', 'LIKE', subject));
    }
    if (status) {
      if (status.length > 0 && status.length !== 3) {
        let elements = newConditionalFilterElement('OR');
        const statusTypes = ['PENDING', 'SENT', 'CANCELED'];
        status.forEach((item) => {
          addCondition(elements, newLeafFilterElement('status', 'EQ', statusTypes[item.value - 1]));
        });

        addCondition(filterElements, simplifyFilterElement(elements));
      }
    }
    return simplifyFilterElement(filterElements);
  };

  const getData = async () => {
    const filterElements = getFilterElement();
    getMessageList(paginationParams, filterElements);
  };

  const handlePaginationChange = (params: Pagination) => {
    const newParams = { ...paginationParams, ...params };
    setPaginationParams(newParams);
  };

  useEffect(() => {
    getData();
  }, [subject, status, paginationParams]);

  useEffect(() => {
    if (shouldReload) {
      getData();
    }
  }, [shouldReload]);

  return (
    <Fragment>
      <CardBody>
        <Label className="traceback-heading mb-3">Messaging</Label>
        <CardBody>
          <Row>
            <Col md="3">
              <InputFormGroup
                inputName="Subject"
                inputId="subject"
                inputClassName="input-traceback"
                inputValue={subject}
                inputPlaceholder="Subject"
                inputOnChange={(e) => setSubject(e.currentTarget.value)}
                inputAutoComplete="off"
              />
            </Col>
            <Col md="3">
              <Select
                className="customselect-large"
                classNamePrefix="customselect"
                options={statuses}
                value={status}
                isMulti
                isSearchable={false}
                onChange={(e) => {
                  setStatus(e as []);
                }}
                placeholder="Status"
                getOptionLabel={(e) => e.label}
              />
            </Col>
            <Col md="3" />
            <Col md="3">
              <Button
                color="primary"
                className="telecom-btn"
                onClick={() => {
                  setMessage(null);
                  navigate('/admin-dashboard#messaging/new');
                }}
              >
                Schedule Message
              </Button>
            </Col>
          </Row>
          <CardBody className="p-0">
            <CustomDataTable
              tableData={messages}
              columns={tableColumns(paginationParams, getFilterElement())}
              defaultSortFieldId={paginationParams.sort}
              defaultSortAsc={paginationParams.order === 'asc'}
              defaultPage={paginationParams.page}
              defaultPageSize={paginationParams.pageSize}
              pagination={true}
              paginationTotalRows={paginationTotalRows}
              updatePaginationParams={handlePaginationChange}
            />
          </CardBody>
        </CardBody>
      </CardBody>
    </Fragment>
  );
};

const audienceStatus = (messageFilters: any) => {
  const filters = JSON.parse(messageFilters);
  const { isDno, isGov, isITG, isUS } = getMessageFilterInfo(filters);
  switch (true) {
    case !!isDno:
      return 'DNO';
    case !!isGov:
      return 'GOV';
    case isUS !== undefined && isITG !== undefined:
      return `${isUS ? 'US' : 'Non-US'} ${Number(isITG) ? 'ITG Member' : 'Non-ITG Member'}`;
    case isUS === undefined && isITG !== undefined:
      return Number(isITG) ? 'ITG Member' : 'Non-ITG Member';
    case isUS !== undefined && isITG === undefined:
      return isUS ? 'US' : 'Non-US';
    default:
      return '';
  }
};
const tableColumns = (params: Pagination, filters: FilterElement): TableColumn<any>[] => [
  {
    name: '',
    width: '20px',
    cell: () => <div />
  },
  {
    name: 'Id',
    grow: 1,
    selector: () => 'id',
    sortable: true,
    cell: (data: any) => <span className="table-cell gray">{data.id}</span>
  },
  {
    name: 'Subject',
    grow: 1,
    selector: () => 'subject',
    sortable: true,
    cell: (data: any) => <span className="table-cell gray">{data.subject}</span>
  },
  {
    name: 'Body',
    selector: () => 'body',
    grow: 3,
    sortable: true,
    cell: (data: any) => <span className="table-cell gray">{data.body}</span>
  },
  {
    name: 'Status',
    grow: 1,
    selector: () => 'status',
    sortable: true,
    cell: (data: any) => <span className="table-cell gray center">{data.status}</span>
  },
  {
    name: 'Scheduled',
    selector: () => 'send_date',
    grow: 2,
    sortable: true,
    cell: (data: any) => <span className="table-cell gray center">{data.sendDate}</span>
  },
  {
    name: 'Audience',
    grow: 2,
    selector: () => '-',
    sortable: false,
    cell: (data: any) => (
      <span className="table-cell gray center">{audienceStatus(data.filters)}</span>
    )
  },
  {
    name: 'Last Active',
    grow: 1,
    selector: () => '-',
    sortable: false,
    cell: (data: any) => {
      const filters = JSON.parse(data.filters);
      let { startDate } = getMessageFilterInfo(filters);
      if (startDate) return <span className="table-cell gray center">{startDate} days</span>;
    }
  },
  {
    name: 'Actions',
    grow: 1,
    selector: () => '-',
    sortable: false,
    width: '150px',
    cell: (data: any) => {
      return (
        <Fragment>
          <EditMessageButon message={data} />
          <CancelMessage message={data} params={params} filters={filters} />
        </Fragment>
      );
    }
  }
];

const statuses: SelectOption[] = [
  {
    label: 'Pending',
    value: 1
  },
  {
    label: 'Sent',
    value: 2
  },
  {
    label: 'Canceled',
    value: 3
  }
];

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return {
    messages: sm.messaging.messages,
    paginationTotalRows: sm.messaging.meta.TotalCount,
    shouldReload: sm.messaging.shouldReload
  };
};

const mapActionsToProps = {
  getMessageList,
  setMessage
};

export default connect(mapStateToProps, mapActionsToProps)(Messaging);
