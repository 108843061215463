import { ApiRequest, buildParams } from '../../lib/apiRequest';
import { Pagination } from '../../interfaces/pagination';

export const getAllFollowersSummariesApiCall = async (params: Pagination) =>
  ApiRequest.request({
    method: 'GET',
    url:
      '/gov/followersummaries?' +
      buildParams({
        ...params
      })
  });
export const getAllCampaignFollowersSummariesApiCall = async (params: Pagination) =>
  ApiRequest.request({
    method: 'GET',
    url:
      '/gov/followersCampaignSummaries?' +
      buildParams({
        ...params
      })
  });

export const getAllFollowersApiCall = async () =>
  ApiRequest.request({
    method: 'GET',
    url: '/gov/followers'
  });

export const getAllCampaignFollowersApiCall = async () =>
  ApiRequest.request({
    method: 'GET',
    url: '/gov/followersCampaign'
  });

export const addFollowerApiCall = async (providerId: number) =>
  ApiRequest.request({
    method: 'PUT',
    url: `/gov/followers/${providerId}`,
    data: { providerId }
  });
export const addCampaignFollowerApiCall = async (labelId: number) =>
  ApiRequest.request({
    method: 'PUT',
    url: `/gov/followersCampaign/${labelId}`,
    data: { labelId }
  });
export const deleteFollowerApiCall = async (providerId: number) =>
  ApiRequest.request({
    method: 'DELETE',
    url: `/gov/followers/${providerId}`,
    data: { providerId }
  });
export const deleteCampaignFollowerApiCall = async (labelId: number) =>
  ApiRequest.request({
    method: 'DELETE',
    url: `/gov/followersCampaign/${labelId}`,
    data: { labelId }
  });
