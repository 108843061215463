import { Pagination } from '../../interfaces/pagination';
import { FilterElement } from '../../lib/FilterElement';
import { ApiRequest, buildParams } from '../../lib/apiRequest';

export const getAccessLogsApiCall = async (params: Pagination, filterElements: FilterElement) =>
  ApiRequest.request({
    method: 'POST',
    url:
      `/accesslogs/search?` +
      buildParams({
        ...params
      }),
    data: filterElements
  });
