import { getAccessLogs, setErrorMessage, setLoadingStatus } from './actions';

import { Pagination } from '../../interfaces/pagination';
import { logoutOnAuthError } from '../../lib/apiRequest';
import { FilterElement } from '../../lib/FilterElement';
import { getAccessLogsApiCall } from './apiCalls';

export const getAccessLogsList =
  (params: Pagination, filterElements: FilterElement) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    try {
      const response = await getAccessLogsApiCall(params, filterElements);
      if (response && response.data) {
        dispatch(getAccessLogs(response.data));
      }
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }

    dispatch(setLoadingStatus(false));
  };

const setStatus = (status: boolean, message: string) => async (dispatch: any) => {
  dispatch(setErrorMessage(message));
  dispatch(setLoadingStatus(status));
};
