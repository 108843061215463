import React, { Fragment } from 'react';

import { Card } from 'reactstrap';

import Dno from '../components/AdminDashboard/Dno/Dno';
import Breadcrumb from '../components/Breadcrumbs';

const DnoProviders = () => {
  return (
    <Fragment>
      <Breadcrumb title={`Do not Origin`} className="table-breadcrumbs" />
      <Card className="table-card hide-border">
        <Dno />
      </Card>
    </Fragment>
  );
};

export default DnoProviders;
