import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import { defaultPagination } from '../../interfaces/pagination';
import { SpreadsheetTraceback, SubmitterDetails } from '../../interfaces/traceback';
import { spreadsheetTracebackColumn } from '../../lib/dataTableUtils/spreadsheetTracebackColumn';
import { convertUtcFromTimezone, isValidDateTime } from '../../lib/utilities';
import { getCampaignNamesList } from '../../redux/appinfo/thunks';
import { stateMappings } from '../../redux/stateMappings';
import {
  createTracebacksFromSpreadsheet,
  getSpreadSheetTracebacks
} from '../../redux/traceback/thunks';
import CampaignsSelect from '../CampaignsSelect';
import CustomDataTable, { Pagination } from '../CustomDataTable';
import { SelectOption } from '../CustomSelect';
import RequestorPopover from '../shared/RequestorPopover';
import SpreadsheetTracebackExpandableRow from './spreadSheetTracebackExpandableRow';

interface IProps {
  activeTab: string;
  spreadsheetTracebacks: SpreadsheetTraceback[];
  spreadSheetTracebackCount: number;
  getSpreadSheetTracebacks: Function;
  submitterDetails: SubmitterDetails;
  createTracebacksFromSpreadsheet: Function;
  getCampaignNamesList: Function;
  campaignNames: { name: string; campaignId: number }[];
}

interface Error {
  campaign: string;
  calledTN: string;
  callingTN: string;
  timeZone: string;
  callTime: string;
}

const defaultError = (): Error => ({
  campaign: '',
  calledTN: '',
  callingTN: '',
  timeZone: '',
  callTime: ''
});

const getError = (
  campaign: number | undefined,
  calledTN: string | undefined,
  callingTN: string | undefined,
  callTime: string | undefined,
  timeZone: string | undefined
): Error => {
  return {
    campaign: !campaign ? 'Please provide a campaign' : '',
    callingTN: !callingTN ? 'Please provide a valid calling number.' : '',
    callTime: !callTime
      ? 'Please provide a valid date and time (YYYY-MM-DD HH:MM).'
      : !isValidDateTime(callTime)
        ? 'Please provide a valid date and time (YYYY-MM-DD HH:MM).'
        : '',
    calledTN: !calledTN ? 'Please provide a valid called number.' : '',
    timeZone: !timeZone ? 'Please provide a valid time zone' : ''
  };
};

const SpreadsheetTracebacks: React.FC<IProps> = ({
  activeTab,
  spreadsheetTracebacks,
  spreadSheetTracebackCount,
  getSpreadSheetTracebacks,
  submitterDetails,
  createTracebacksFromSpreadsheet,
  getCampaignNamesList,
  campaignNames
}) => {
  const [paginationParams, setPaginationParams] = useState({
    ...defaultPagination(),
    sort: 'create_date',
    order: 'desc'
  });
  const [requestor, setRequestor] = useState<SelectOption[]>([]);
  const [createSpreadsheetTracebacks, setCreateSpreadsheetTracebacks] = useState<
    SpreadsheetTraceback[]
  >([]);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [modal, setModal] = useState(false);
  const [editRowId, setEditRowId] = useState(0);
  const [callTime, setCallTime] = useState<string | undefined>('');
  const [callingNumber, setCallingNumber] = useState<string | undefined>('');
  const [calledNumber, setCalledNumber] = useState<string | undefined>('');
  const [timezone, setTimezone] = useState<string | undefined>('');
  const [campaign, setCampaign] = useState<number | undefined>(0);

  const [errors, setErrors] = useState<Error>(defaultError());

  useEffect(() => {
    getSpreadSheetTracebacks(paginationParams);
    if (!campaignNames) getCampaignNamesList();
  }, [paginationParams, activeTab]);

  useEffect(() => {
    setCreateSpreadsheetTracebacks(spreadsheetTracebacks);
  }, [spreadsheetTracebacks]);

  const updateSelectedRows = (checked: boolean, id: number) => {
    if (checked) setSelectedRows((v) => [...v, id]);
    else setSelectedRows(selectedRows.filter((select: number) => select !== id));
  };

  const updatePagination = (params: Pagination) => {
    setPaginationParams(params);
  };

  const setRequestorOptions = (options: Array<SelectOption>) => {
    setCreateSpreadsheetTracebacks(
      createSpreadsheetTracebacks.map((v: SpreadsheetTraceback) => {
        return {
          ...v,
          requestors: options.map((v) => ({ requestorId: v.value, name: v.label }))
        };
      })
    );
    setRequestor(options);
  };

  const findSpreadSheetTracebackById = (id: number) =>
    createSpreadsheetTracebacks.find((v: SpreadsheetTraceback) => v.id === id);

  const setEditRow = (id: number) => {
    setEditRowId(id);
    setCallTime(findSpreadSheetTracebackById(id)?.callTime);
    setCalledNumber(findSpreadSheetTracebackById(id)?.dialedNumber);
    setCallingNumber(findSpreadSheetTracebackById(id)?.callingNumber);
    setTimezone(findSpreadSheetTracebackById(id)?.timeZoneCallTime);
    setCampaign(findSpreadSheetTracebackById(id)?.campaignId);
    setModal(true);
  };

  const editSpreadSheetTraceback = (id: number) => {
    if (!canEdit()) return;
    setCreateSpreadsheetTracebacks(
      createSpreadsheetTracebacks.map((v: SpreadsheetTraceback) => {
        if (v.id === id)
          return {
            ...v,
            campaignId: campaign,
            callTime: callTime,
            dialedNumber: calledNumber,
            callingNumber: callingNumber,
            timeZoneCallTime: timezone
          };
        else return v;
      })
    );
    setModal((v) => !v);
  };

  const canEdit = (): boolean => {
    const errors = getError(campaign, calledNumber, callingNumber, callTime, timezone);
    setErrors(errors);
    return (
      !errors.callTime &&
      !errors.calledTN &&
      !errors.callingTN &&
      !errors.timeZone &&
      !errors.campaign
    );
  };

  const getSelectedSpreadSheetTracebacks = () => {
    return createSpreadsheetTracebacks
      .filter((v: SpreadsheetTraceback) => selectedRows.some((row: number) => row === v.id))
      .map((v: SpreadsheetTraceback) => ({
        ...v,
        callTime: convertUtcFromTimezone(v.callTime || '', v.timeZoneCallTime || '')
      }));
  };

  const canCreate = () => {
    const payload = getSelectedSpreadSheetTracebacks();
    return payload.some((v: SpreadsheetTraceback) => {
      const errors = getError(
        v.campaignId,
        v.dialedNumber,
        v.callingNumber,
        v.callTime,
        v.timeZoneCallTime
      );
      return (
        errors.callTime || errors.calledTN || errors.callingTN || errors.timeZone || errors.campaign
      );
    });
  };

  const createTracebacks = async () => {
    const payload = getSelectedSpreadSheetTracebacks();
    await createTracebacksFromSpreadsheet(payload);
    setSelectedRows([]);
    getSpreadSheetTracebacks(paginationParams);
  };

  return (
    <Fragment>
      {spreadsheetTracebacks && spreadsheetTracebacks.length > 0 && (
        <div className="d-flex ms-1 mt-4">
          <Card className="w-50">
            <CardHeader>
              <h5>Submitter Details</h5>
            </CardHeader>
            <CardBody>
              <div className="d-flex">
                <Label className="telecom-label me-1">Requesting Organization:</Label>
                <p>{submitterDetails.requestingOrganization}</p>
              </div>
              <div className="d-flex">
                <Label className="telecom-label me-1">Date of request:</Label>
                <p>{submitterDetails.dateOfRequest}</p>
              </div>
              <div className="d-flex">
                <Label className="telecom-label me-1">Contact Name:</Label>
                <p>{submitterDetails.contactName}</p>
              </div>
              <div className="d-flex">
                <Label className="telecom-label me-1">Contact Title:</Label>
                <p>{submitterDetails.contactTitle}</p>
              </div>
              <div className="d-flex">
                <Label className="telecom-label me-1">Contact Email:</Label>
                <p>{submitterDetails.contactEmail}</p>
              </div>
              <div className="d-flex">
                <Label className="telecom-label me-1">Contact Phone:</Label>
                <p>{submitterDetails.contactPhone}</p>
              </div>
            </CardBody>
          </Card>
          <Card className="w-25">
            <CardHeader>
              <h5>Certification</h5>
            </CardHeader>
            <CardBody>
              <div className="row">
                <p className="m-1">{submitterDetails.certificationText}</p>
              </div>
            </CardBody>
          </Card>
        </div>
      )}
      <div className="mt-4">
        <CustomDataTable
          columns={spreadsheetTracebackColumn(
            selectedRows,
            updateSelectedRows,
            setEditRow,
            campaignNames
          )}
          tableData={createSpreadsheetTracebacks}
          pagination
          defaultSortFieldId={paginationParams.sort}
          defaultSortAsc={paginationParams.order === 'asc'}
          defaultPage={paginationParams.page}
          defaultPageSize={paginationParams.pageSize}
          updatePaginationParams={updatePagination}
          paginationTotalRows={spreadSheetTracebackCount}
          useExpandableRows
          useExpandableRowsComponent={SpreadsheetTracebackExpandableRow}
        />
      </div>
      <div className="row ms-3">
        <label className="label-bold">Traceback Requestor</label>
        <p className="telecom-text mb-0" style={{ paddingTop: '5px' }}>
          <RequestorPopover
            id="edit-tb-add-requestor"
            values={requestor}
            setValues={(value) => {
              if (typeof value === 'function') {
                setRequestorOptions(value(requestor));
              } else {
                setRequestorOptions(value);
              }
            }}
            radioType={true}
            popoverHeaderMessage="Select requestor"
          />
        </p>
      </div>
      <Modal
        centered
        className="modal-template mt-5 mb-5"
        isOpen={modal}
        toggle={() => {
          setModal((v) => !v);
        }}
      >
        <ModalHeader
          toggle={() => {
            setModal((v) => !v);
            setErrors(defaultError());
          }}
        >
          Edit spreadsheet traceback informations
        </ModalHeader>
        <ModalBody>
          <div className="w-75">
            <Label className="telecom-label">
              Campaign
              <i className="fa fa-asterisk asterisk" />
            </Label>
            <CampaignsSelect
              value={campaign}
              onChange={(option: any) => {
                setCampaign(option.value);
                setErrors((v) => ({ ...v, calledTN: '' }));
              }}
              isSearchable={true}
              addLink={false}
              placeholder="All Campaigns"
              addAllItem
              maxMenuHeight={150}
            />
            <span className="text-danger">{errors.campaign}</span>
            <Label className="telecom-label mt-2">
              Call Time
              <i className="fa fa-asterisk asterisk" />
            </Label>
            <Input
              type="text"
              className="input-traceback"
              value={callTime}
              placeholder="2020-03-07 19:55"
              onChange={(option) => {
                setCallTime(option.currentTarget.value);
                setErrors((v) => ({ ...v, callTime: '' }));
              }}
            />
            <span className="text-danger">{errors.callTime}</span>
            <Label className="telecom-label mt-2">
              Called Number
              <i className="fa fa-asterisk asterisk" />
            </Label>
            <Input
              type="text"
              className="input-traceback"
              value={calledNumber}
              placeholder="2025558387"
              onChange={(option) => {
                setCalledNumber(option.currentTarget.value);
                setErrors((v) => ({ ...v, calledTN: '' }));
              }}
            />
            <span className="text-danger">{errors.calledTN}</span>
            <Label className="telecom-label mt-2">
              Calling Number
              <i className="fa fa-asterisk asterisk" />
            </Label>
            <Input
              type="text"
              className="input-traceback"
              value={callingNumber}
              placeholder="2025558387"
              onChange={(option) => {
                setCallingNumber(option.currentTarget.value);
                setErrors((v) => ({ ...v, callingTN: '' }));
              }}
            />
            <span className="text-danger">{errors.callingTN}</span>

            <Label className="telecom-label mt-2">
              Time Zone
              <i className="fa fa-asterisk asterisk" />
            </Label>
            <Input
              type="text"
              className="input-traceback"
              value={timezone}
              placeholder="UTC"
              onChange={(option) => {
                setTimezone(option.currentTarget.value);
                setErrors((v) => ({ ...v, timeZone: '' }));
              }}
            />
            <span className="text-danger">{errors.timeZone}</span>
          </div>
        </ModalBody>
        <ModalFooter style={{ margin: 'auto' }}>
          <div className="d-flex flex-wrap">
            <Button
              className="btn-default telecom-btn"
              color="light"
              onClick={() => {
                setModal((v) => !v);
                setErrors(defaultError());
              }}
            >
              Cancel
            </Button>
            <Button
              className="telecom-btn ms-2"
              onClick={() => {
                editSpreadSheetTraceback(editRowId);
              }}
            >
              Save
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <div className="d-flex justify-content-center mt-4">
        <Button
          className="telecom-btn"
          style={{ minWidth: '100px', marginRight: '0px' }}
          disabled={selectedRows.length === 0 || canCreate()}
          onClick={async () => await createTracebacks()}
        >
          Create Tracebacks
        </Button>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    spreadsheetTracebacks: sm.traceback.spreadsheetTracebacks,
    spreadSheetTracebackCount: sm.traceback.spreadSheetTracebackCount,
    submitterDetails: sm.traceback.submitterDetails,
    campaignNames: sm.appinfo.campaignNames
  };
};

const mapActionsToProps = {
  getSpreadSheetTracebacks,
  createTracebacksFromSpreadsheet,
  getCampaignNamesList
};
export default connect(mapStateToProps, mapActionsToProps)(SpreadsheetTracebacks);
