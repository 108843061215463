export enum Traceforward {
  GET_TRACEFORWARDS = 'GET_TRACEFORWARDS',
  GET_TRACEFORWARD = 'GET_TRACEFORWARD',
  ADD_TRACEFORWARD = 'ADD_TRACEFORWARD',
  EDIT_TRACEFORWARD = 'EDIT_TRACEFORWARD',
  ADD_ATTACHMENTS = 'ADD_ATTACHMENTS',
  GET_ATTACHMENTS = 'GET_ATTACHMENTS',
  //   GET_TRACEFORWARD_STATUSES = 'GET_TRACEFORWARD_STATUSES',
  SET_TRACEFORWARD_LOADING_STATUS = 'SET_TRACEFORWARD_LOADING_STATUS',
  SET_TRACEFORWARD_ERROR_MESSAGE = 'SET_TRACEFORWARD_ERROR_MESSAGE'
}
