import { convertToTimeZone } from 'date-fns-timezone';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import { getDefaultEndDate } from '../components/AdminDashboard/Reporting/ReportsArchive/data';
import Breadcrumb from '../components/Breadcrumbs';
import CustomDataTable from '../components/CustomDataTable';
import ProviderPanel from '../components/ProviderPanel';
import ProvidersSelect from '../components/ProvidersSelect';
import RmdInfoElement from '../components/RmdInfoElement';
import Legend from '../components/shared/Legend';
import StirShakenNotInCallPathExpandableRow from '../components/stirShaken/StirShakenNotInCallPathExpandableRow';
import { ViewMode } from '../enum/ViewMode';
import { Pagination, defaultPagination } from '../interfaces/pagination';
import { StirShakenProviderNotInCallPathStats } from '../interfaces/stirShaken';
import { PersonalInfo } from '../interfaces/user';
import { buildParams } from '../lib/apiRequest';
import { decodeQueryParams, getFromLocalStorage, saveToLocalStorage } from '../lib/history-utils';
import { getApiFormattedDate, parseDateToISOString } from '../lib/utilities';
import { stateMappings } from '../redux/stateMappings';
import { getProviderNotInCallPathStatsQuery } from '../redux/stirShaken/thunks';

const columns: TableColumn<StirShakenProviderNotInCallPathStats>[] = [
  {
    name: 'Provider',
    selector: () => 'sq.providerName',
    id: 'column-name',
    sortable: true,
    grow: 2,
    cell: (row) => (
      <ProviderPanel
        provider={{
          name: row.providerName,
          providerId: row.providerId,
          isITGMember: row.isITGMember,
          isDomestic: row.providerCountry != 'United States'
        }}
        hyperlink
      />
    )
  },
  {
    name: 'Tracebacks',
    selector: () => 'tracebackCount',
    id: 'column-tb',
    sortable: true,
    grow: 1,
    cell: (row) =>
      row.tracebackCount ? (
        <div className="table-cell blue center">
          <a href={'/hops?' + buildParams({ providerId: row.providerId })}>{row.tracebackCount}</a>
        </div>
      ) : (
        <span className=" table-cell gray center"> 0</span>
      )
  },
  {
    name: 'Headers',
    selector: () => 'signedStirShakens',
    id: 'column-headers',
    sortable: true,
    grow: 1,
    cell: (row) =>
      row.signedStirShakens ? (
        <div className="table-cell blue center">
          <a href={'/hops?' + buildParams({ providerId: row.providerId })}>
            {row.signedStirShakens}
          </a>
        </div>
      ) : (
        <span className=" table-cell gray center"> 0</span>
      )
  },
  {
    name: 'RMD',
    selector: () => 'r.rmd_number',
    id: 'column-rmd',
    sortable: true,
    grow: 1,
    cell: (row) => {
      return (
        row.rmdNumber &&
        row.rmdSysId && (
          <div className="d-flex flex-fill justify-content-center align-items-center">
            <RmdInfoElement
              rmdEntry={{
                id: 0,
                number: row.rmdNumber,
                sysId: row.rmdSysId,
                intermediateProvider: false,
                voiceServiceProviderChoice: false
              }}
              viewMode={ViewMode.VIEW}
            />
          </div>
        )
      );
    }
  }
];

interface IProps {
  user: PersonalInfo;
  data: StirShakenProviderNotInCallPathStats[];
  getProviderNotInCallPathStatsQuery: Function;
  paginationTotalRows: number;
}

const StirShakneIssues: React.FC<IProps> = ({
  user,
  data,
  getProviderNotInCallPathStatsQuery,
  paginationTotalRows
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchQueryParams = useMemo(() => {
    let search = searchParams.toString();
    if (!search) {
      search = getFromLocalStorage('StirShaken') || '';
      setSearchParams(search);
    }
    return decodeQueryParams('stirshakenIssues', search);
  }, [searchParams]);
  const [collapseFilters, setCollapseFilters] = useState(false);
  const [paginationParams, setPaginationParams] = useState(
    searchQueryParams.paginationParams || {
      ...defaultPagination(),
      sort: 'sq.providerName',
      order: 'desc'
    }
  );

  const [providerId, setSelectedProvider] = useState(Number(searchQueryParams.providerId) || 0);
  const [startDate, setStartDate] = useState(
    parseDateToISOString(searchQueryParams.startDate, 1) || '2012-01-01'
  );
  const [endDate, setEndDate] = useState(
    parseDateToISOString(searchQueryParams.endDate, 2) || getDefaultEndDate()
  );

  useEffect(() => {
    setPaginationParams({ ...paginationParams, page: 1 });
  }, [startDate, endDate, providerId]);

  useEffect(() => {
    const urlQueryParams = new URLSearchParams({
      startDate,
      endDate
    });
    urlQueryParams.set('startDate', startDate.slice(0, 10));
    urlQueryParams.set('endDate', endDate.slice(0, 10));
    if (providerId && providerId !== 0 && !isNaN(providerId)) {
      urlQueryParams.set('providerId', providerId.toString());
    } else {
      urlQueryParams.delete('providerId');
    }

    setSearchParams(urlQueryParams.toString());
    saveToLocalStorage('StirShaken', urlQueryParams.toString());
  }, [startDate, endDate, providerId]);

  useEffect(() => {
    getProviderNotInCallPathStatsQuery(paginationParams, {
      startDate: getApiFormattedDate(startDate),
      endDate: getApiFormattedDate(endDate),
      providerId
    });
    window.scrollTo(0, 0);
  }, [paginationParams, startDate, endDate, providerId]);

  const updatePagination = (params: Pagination) => {
    setPaginationParams({ ...paginationParams, ...params });
  };

  const handleProviderChange = (e: any) => {
    setSelectedProvider(e.value);
  };

  const handleChange = (e: any, key: string) => {
    let date = new Date(Date.parse(e));
    switch (key) {
      case 'startDate':
        if (e) {
          const startDate = new Date(
            Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
          );
          setStartDate(startDate.toISOString());
        } else {
          setStartDate('2012-01-01');
        }
        break;
      case 'endDate':
        if (e) {
          const endDate = new Date(
            Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)
          );
          setEndDate(endDate.toISOString());
        } else {
          setEndDate(getDefaultEndDate());
        }
        break;
    }
  };

  const clearFilters = () => {
    setStartDate('2012-01-01 00:00:00');
    setEndDate(getDefaultEndDate());
    setPaginationParams({ ...defaultPagination(), sort: 'sq.providerName', order: 'desc' });
    setSelectedProvider(0);
  };

  return !user ? null : (
    <Fragment>
      <Breadcrumb title="Provider Signing Issues" className="table-breadcrumbs" />
      <Card className="table-card hide-border">
        <CardHeader className="card-header-provider">
          <CardHeader
            className="card-header-filters"
            onClick={() => setCollapseFilters(!collapseFilters)}
            data-type="collapseBanner"
          >
            Filters
            <i
              className={`filters fa-solid ${collapseFilters ? 'fa-arrow-up' : 'fa-arrow-down'}`}
            ></i>
          </CardHeader>
          <Collapse isOpen={collapseFilters}>
            <CardBody>
              <div className="row">
                <div className="col-2">
                  <DatePicker
                    maxDate={endDate ? convertToTimeZone(endDate, { timeZone: 'GMT' }) : new Date()}
                    selected={
                      startDate ? convertToTimeZone(startDate, { timeZone: 'GMT' }) : undefined
                    }
                    onChange={(option) => handleChange(option, 'startDate')}
                    showYearDropdown
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown
                    placeholderText="&#xf133; startDate"
                    clearButtonTitle={'Clear'}
                    isClearable
                  />
                </div>
                <div className="col-2">
                  <DatePicker
                    maxDate={new Date()}
                    selected={endDate ? convertToTimeZone(endDate, { timeZone: 'GMT' }) : undefined}
                    minDate={convertToTimeZone(startDate, { timeZone: 'GMT' })}
                    onChange={(option) => handleChange(option, 'endDate')}
                    showYearDropdown
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown
                    placeholderText="&#xf133; endDate"
                    clearButtonTitle={'Clear'}
                    isClearable
                  />
                </div>
                <div className="col-2">
                  <ProvidersSelect
                    onChange={(option) => handleProviderChange(option)}
                    isSearchable
                    addAllItem
                    option={providerId}
                    includeInactive
                  />
                </div>
                <div className="col-2">
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={() => {
                      clearFilters();
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </CardBody>
          </Collapse>
        </CardHeader>

        <CardBody className="card-body-provider">
          <CustomDataTable
            tableData={data}
            columns={columns}
            defaultSortFieldId={paginationParams.sort}
            defaultSortAsc={false}
            defaultPage={paginationParams.page}
            defaultPageSize={paginationParams.pageSize}
            pagination={true}
            paginationTotalRows={paginationTotalRows}
            useExpandableRows
            useExpandableRowsComponent={({ data }) => (
              <StirShakenNotInCallPathExpandableRow
                row={data}
                filter={{
                  startDate: getApiFormattedDate(startDate),
                  endDate: getApiFormattedDate(endDate)
                }}
              />
            )}
            updatePaginationParams={updatePagination}
          />
        </CardBody>
      </Card>
      <div className="d-flex justify-content-center pt-4 pb-4">
        <Legend />
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return {
    user: sm.user,
    data: sm.stirShaken.stirShakensIssues,
    paginationTotalRows: sm.stirShaken.metaIssues.TotalCount
  };
};

const mapActionsToProps = {
  getProviderNotInCallPathStatsQuery
};

export default connect(mapStateToProps, mapActionsToProps)(StirShakneIssues);
