import { sub } from 'date-fns';
import React, { Fragment, useState } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { Button, Label } from 'reactstrap';
import { ReportFormat, ReportType } from '../../../interfaces/report';
import { getEndOfTheDay, getStartOfTheDay } from '../../../lib/utilities';
import { getReportObject } from '../../../redux/report/thunks';

interface IProps {
  getReportObject: Function;
}

const QuarterlyReport: React.FC<IProps> = ({ getReportObject }) => {
  const [startDate, setStartDate] = useState(sub(new Date(), { months: 1 }));
  const [endDate, setEndDate] = useState(new Date());

  const getReport = () => {
    getReportObject(
      {
        startDate: getStartOfTheDay(startDate).toISOString(),
        endDate: getEndOfTheDay(endDate).toISOString()
      },
      ReportType.QuarterlyReport,
      ReportFormat.Excel
    );
  };

  const handleCheck = (e: any, key: string) => {
    switch (key) {
      case 'startDate':
        setStartDate(e);
        break;
      case 'endDate':
        setEndDate(e);
        break;
      case 'excel':
        getReport();
        break;
    }
  };

  return (
    <Fragment>
      <Label className="telecom-label ps-0">Date Range *</Label>
      <div className="d-flex flex-row mb-4">
        <DatePicker
          maxDate={endDate || new Date()}
          selected={startDate}
          onChange={(option) => handleCheck(option, 'startDate')}
          placeholderText="&#xf133; mm/dd/yyyy"
          clearButtonTitle={'Clear'}
          isClearable
        />
        <span className="ms-2 me-2">-</span>
        <DatePicker
          maxDate={new Date()}
          selected={endDate}
          minDate={startDate}
          onChange={(option) => handleCheck(option, 'endDate')}
          placeholderText="&#xf133; mm/dd/yyyy"
          clearButtonTitle={'Clear'}
          isClearable
        />
      </div>
      <div className="m-4" style={{ borderBottom: '1px solid #ccc' }} />
      <div className="d-flex justify-content-md-center">
        <Button
          className="telecom-btn me-2"
          color="primary"
          style={{
            height: '28px',
            padding: '5px',
            fontSize: '14px',
            width: '150px'
          }}
          onClick={(option) => handleCheck(option, 'excel')}
        >
          {`Create Report `}
        </Button>
      </div>
    </Fragment>
  );
};

const mapActionsToProps = {
  getReportObject
};

export default connect(null, mapActionsToProps)(QuarterlyReport);
