import React from 'react';

export const NoDataComponent = (props: any) => {
  return (
    <p className="no-data-text mt-4">
      {props.noDataText
        ? props.noDataText
        : 'Your search or filter combination has not returned any results.'}
    </p>
  );
};
