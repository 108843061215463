import { Report } from './actionEnum';

export const getReport = (response: any) => ({
  type: Report.GET_REPORT,
  payload: response
});

export const getRollupReport = (response: any) => ({
  type: Report.GET_ROLLUP_REPORT,
  payload: response
});

export const getArchivedReports = (response: any) => ({
  type: Report.GET_ARCHIVED_REPORTS,
  payload: response
});

export const setLoadingStatus = (status: boolean) => ({
  type: Report.SET_REPORT_LOADING_STATUS,
  payload: status
});

export const setErrorMessage = (message: string) => ({
  type: Report.SET_REPORT_ERROR_MESSAGE,
  payload: message
});

export const getArchivedReportsListCall = (response: any) => ({
  type: Report.GET_ARCHIVED_REPORTS_LIST,
  payload: response
});
