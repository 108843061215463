export enum Traceback {
  GET_TRACEBACKS = 'GET_TRACEBACKS',
  GET_TRACEBACK_STATS = 'GET_TRACEBACK_STATS',
  GET_TRACEBACK = 'GET_TRACEBACK',
  ADD_TRACEBACK = 'ADD_TRACEBACK',
  EDIT_TRACEBACK = 'EDIT_TRACEBACK',
  ADD_ATTACHMENTS = 'ADD_ATTACHMENTS',
  GET_ATTACHMENTS = 'GET_ATTACHMENTS',
  GET_TRACEBACK_STATUSES = 'GET_TRACEBACK_STATUSES',
  SET_TRACEBACK_LOADING_STATUS = 'SET_TRACEBACK_LOADING_STATUS',
  SET_TRACEBACK_ERROR_MESSAGE = 'SET_TRACEBACK_ERROR_MESSAGE',
  CLEAR_TRACEBACKS = 'CLEAR_TRACEBACKS',
  ADD_REQUESTOR = 'ADD_REQUESTOR',
  GET_REQUESTORS = 'GET_REQUESTORS',
  GET_SPREADSHEET_TRACEBACKS = ' GET_SPREADSHEET_TRACEBACKS'
}
