import React, { Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import { RollupReportTracebackRow } from '../../../../interfaces/report';

interface IProps {
  rollupReportStatisticsRow: RollupReportTracebackRow;
}

const TracebackDataRow: React.FC<IProps> = ({ rollupReportStatisticsRow }) => (
  <Fragment>
    <Row className="me-2" style={{ padding: '0 10px' }}>
      <Col md="4">
        <Row>
          <Col className="p-0">
            <span>{rollupReportStatisticsRow.period || 0}</span>
          </Col>
          <Col className="p-0 text-center">
            <span>{rollupReportStatisticsRow.newTracebacks || 0}</span>
          </Col>
          <Col className="p-0 text-center">
            <span>{rollupReportStatisticsRow.totalHops || 0}</span>
          </Col>
        </Row>
      </Col>
      {rollupReportStatisticsRow.notFound && (
        <Col md="4">
          <Row>
            <Col className="p-0 text-center">
              <span>{rollupReportStatisticsRow.notFound || 0}</span>
            </Col>
            <Col className="p-0 text-center">
              <span>{rollupReportStatisticsRow.noInfo || 0}</span>
            </Col>
            <Col className="p-0 text-center">
              <span>{rollupReportStatisticsRow.complete || 0}</span>
            </Col>
            <Col className="p-0 text-center">
              <span>{rollupReportStatisticsRow.open || 0}</span>
            </Col>
          </Row>
        </Col>
      )}
      {rollupReportStatisticsRow.responded && (
        <Col md="4">
          <Row>
            <Col className="p-0 text-center">
              <span>{rollupReportStatisticsRow.responded || 0}</span>
            </Col>
            <Col className="p-0 text-center">
              <span>{rollupReportStatisticsRow.sometimes || 0}</span>
            </Col>
            <Col className="p-0 text-center">
              <span>{rollupReportStatisticsRow.noResponse || 0}</span>
            </Col>
            <Col className="p-0 text-center">
              <span>{rollupReportStatisticsRow.total || 0}</span>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  </Fragment>
);

export default TracebackDataRow;
