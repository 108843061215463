import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Label } from 'reactstrap';
import { stateMappings } from '../redux/stateMappings';

import RmdInfoElement from './RmdInfoElement';

import { ViewMode } from '../enum/ViewMode';
import { userTypes } from '../enum/userTypes';
import { RMDEntry } from '../interfaces/rmd';
import { PersonalInfo } from '../interfaces/user';

interface IProps {
  user: PersonalInfo;
  rmdEntries: RMDEntry[];
  deleteRMD?: Function;
  handleAddRMD?: Function;
  viewMode?: ViewMode;
}

const ProviderRmdInfo: React.FC<IProps> = ({
  viewMode,
  rmdEntries,
  deleteRMD,
  handleAddRMD,
  user
}) => {
  return (
    <div className="mb-5">
      <h5 className="mt-5 p-0 traceback-sub-title">FCC RMD</h5>
      <Label className="telecom-label">RMD</Label>
      <div className={`my-1 ps-2 ${rmdEntries && rmdEntries.length > 3 && 'bodyHeight'}`}>
        {rmdEntries && rmdEntries.length > 0 ? (
          <Fragment>
            {rmdEntries.map((item: RMDEntry) => (
              <RmdInfoElement
                key={item.id}
                rmdEntry={item}
                viewMode={viewMode}
                deleteRMD={deleteRMD}
              />
            ))}
          </Fragment>
        ) : (
          <span className="text-red ">NONE</span>
        )}
      </div>
      <div className={'add-ocn mt-4'}>
        {user.roleType === userTypes.Admin &&
          (viewMode === ViewMode.EDIT ||
            typeof viewMode === 'undefined' ||
            viewMode === ViewMode.APPROVAL) && (
            <span onClick={() => handleAddRMD?.()} className="telecom-text add-contact">
              + Add RMD#
            </span>
          )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    user: sm.user
  };
};

export default connect(mapStateToProps)(ProviderRmdInfo);
