import React, { Fragment, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { DateFormat } from '../../enum/DateFormat';
import { StirShakenReponse } from '../../interfaces/stirShaken';
import { Traceback } from '../../interfaces/traceback';
import { PersonalInfo } from '../../interfaces/user';
import { DnoToolTip } from '../../lib/dno';
import { getClientFormattedDate, getElapsedTime } from '../../lib/utilities';
import CustomToolTip from '../CustomToolTip';
import BasisForTraceback from '../HopDetail/BasisForTraceback';
import StirShakenRow from '../StirShakenRow';
import CommentComponent from '../shared/CommentComponent';

interface IProps {
  traceback: Traceback;
  className?: string;
  tracebackStatuses?: { [key: number]: string };
  user: PersonalInfo;
}

const TracebackView: React.FC<IProps> = ({ traceback, className, tracebackStatuses, user }) => {
  const { campaignName, tracebackTime, status, elapsed, numberOfHops, source, hopsStirShaken } =
    traceback;
  const [isStirShakenVisible, setIsStirShakenVisible] = useState(
    !!(traceback.stirShaken || (hopsStirShaken && hopsStirShaken.length))
  );
  return (
    <Fragment>
      <div
        className={`traceback-view ${className}`}
        style={{
          marginTop: '15px',
          borderColor: 'transparent',
          background: 'transparent'
        }}
      >
        <Row>
          <Col>
            <div className="label-bold">Status</div>
            <p className="telecom-text " style={{ paddingTop: '5px' }}>
              {tracebackStatuses && tracebackStatuses[status] ? tracebackStatuses[status] : 'None'}
            </p>
          </Col>
          <Col>
            <div className="label-bold">Campaign Name</div>
            <p className="telecom-text blue" style={{ paddingTop: '5px' }}>
              <a style={{ color: '#0f72b1' }} href={`/campaigns/campaign/${traceback.campaignId}`}>
                {campaignName}
              </a>
            </p>
          </Col>
          <Col>
            <div className="label-bold">Traceback Source</div>
            <p className="telecom-text blue" style={{ paddingTop: '5px' }}>
              {source !== '' ? source : 'None'}
            </p>
          </Col>
          <Col>
            <div className="label-bold center">Hops</div>
            <p className="telecom-text blue center" style={{ paddingTop: '5px' }}>
              <a style={{ color: '#0f72b1' }} href={`/hops?tracebackId=${traceback.tracebackId}`}>
                {numberOfHops}
              </a>
            </p>
          </Col>
          <div className="col">
            <div className="label-bold center">Elapsed Time</div>
            <p className="telecom-text center" style={{ paddingTop: '5px' }}>
              {getElapsedTime(elapsed)}
            </p>
          </div>
        </Row>
        <div>
          <h5 className="col-md-12 p-0 traceback-sub-title">Call Details</h5>
          <Row>
            <Col md="5">
              <div className="label-bold">Called Number</div>
              <p className="telecom-text " style={{ paddingTop: '5px' }}>
                <span className="fa fa-phone" style={{ color: 'RED', marginRight: '5px' }} />
                {traceback.calledTN}
              </p>
            </Col>
            <Col md="5">
              <div className="label-bold">Calling Number</div>
              <p className="telecom-text " style={{ paddingTop: '5px' }}>
                <span className="fa fa-phone" style={{ color: 'GREEN', marginRight: '5px' }} />
                {traceback.callingTN}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md="5">
              <div className="label-bold">Date & Time of Call</div>
              <p className="telecom-text " style={{ paddingTop: '5px' }}>
                {getClientFormattedDate(tracebackTime, DateFormat.LongBoth)}
              </p>
            </Col>
            <Col md="7">
              <div className="label-bold">Audio URL</div>
              {traceback.audioUrl ? (
                <Fragment>
                  <audio controls>
                    <source src={traceback.audioUrl} />
                  </audio>
                  <br />
                  <a
                    href={traceback.audioUrl}
                    target={'audio'}
                    rel={'noopener noreferrer'}
                    className="telecom-text "
                    style={{ paddingTop: '5px', fontSize: '0.9em' }}
                    type={'audio/mp3'}
                  >
                    {traceback.audioUrl}
                  </a>
                  {traceback.representativeAudio === false && ( // null means something else
                    <p>Voicemail May Be a Representative Sample of Campaign</p>
                  )}
                </Fragment>
              ) : (
                <p className="telecom-text " style={{ paddingTop: '5px' }}>
                  {'None'}
                </p>
              )}
            </Col>
          </Row>
          <Row>
            <Col md="5">
              <div className="label-bold">Terminating Line Type</div>
              <p className="telecom-text " style={{ paddingTop: '5px' }}>
                {traceback.termLineType}
              </p>
            </Col>
            <Col md="5">
              <div className="label-bold">Do Not Call Registry</div>
              <p className="telecom-text " style={{ paddingTop: '5px' }}>
                {traceback.dnc || 'None'}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md="5">
              <div className="label-bold">Terminating State</div>
              <p className="telecom-text " style={{ paddingTop: '5px' }}>
                {traceback.termState}
              </p>
            </Col>
            <Col md="5">
              <div className="label-bold">Caller Name</div>
              <p className="telecom-text " style={{ paddingTop: '5px' }}>
                {traceback.callerName}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md="5">
              <label className="label-bold">Do Not Origin Registry</label>
              <CustomToolTip
                message={
                  DnoToolTip.find((v) => v.type === traceback.dnoType)?.description ||
                  traceback.dnoType ||
                  ''
                }
                tooltipVisible={!!traceback.dnoType}
              >
                <p className="telecom-text mb-0" style={{ paddingTop: '5px' }}>
                  {traceback.dno ? 'Industry DNO' : 'Not Listed'}
                </p>
              </CustomToolTip>
            </Col>
          </Row>
        </div>
        <div className="mb-4">
          <h5
            className="p-0 traceback-sub-title mb-3"
            onClick={() => {
              setIsStirShakenVisible((v) => !v);
            }}
            role="button"
          >
            {(traceback.stirShaken || (hopsStirShaken && hopsStirShaken.length)) && (
              <i
                className={`${
                  isStirShakenVisible ? 'fa-solid fa-angle-down' : 'fa-solid fa-angle-right'
                } pe-2`}
                style={{ color: '#08335d' }}
              />
            )}
            Stir/Shaken
          </h5>
          <div className={isStirShakenVisible ? '' : 'd-none'}>
            <StirShakenRow stirShaken={traceback.stirShaken} traceback={traceback} />
            {hopsStirShaken &&
              hopsStirShaken
                .sort((a: StirShakenReponse, b: StirShakenReponse) => a.hopSequence - b.hopSequence)
                .map((item: StirShakenReponse) => (
                  <StirShakenRow key={item.hopID} stirShaken={item} traceback={traceback} />
                ))}
          </div>
        </div>
        <div>
          <h5 className="col-md-12 p-0 traceback-sub-title">Terminating Provider Details</h5>
          <Row>
            <Col md="5">
              <div className="label-bold">Terminating Carrier ID</div>
              <p className="telecom-text " style={{ paddingTop: '5px' }}>
                {traceback.termCarrierId}
              </p>
            </Col>
            <Col md="5">
              <div className="label-bold">Terminating Carrier OCN</div>
              <p className="telecom-text " style={{ paddingTop: '5px' }}>
                {traceback.termCarrierOCN}
              </p>
            </Col>
          </Row>
        </div>
        <div>
          <h5 className="col-md-12 p-0 traceback-sub-title">System Comments</h5>
          <CommentComponent input={traceback.comments} includeSystem />
        </div>
        <div>
          <h5 className="col-md-12 p-0 traceback-sub-title">User Comments + Attachments</h5>
          <CommentComponent input={traceback.comments} includeUser />
        </div>
        <div>
          <h5 className="col-md-12 p-0 traceback-sub-title">Additional Details</h5>
          <Row>
            <Col md="4">
              <div className="label-bold">Strike Exempt</div>
              <p className="telecom-text " style={{ paddingTop: '5px' }}>
                {traceback.strikeExempt ? 'Yes' : 'No'}
              </p>
            </Col>
            <Col md="4">
              <div className="label-bold">Traceback Created</div>
              <p className="telecom-text " style={{ paddingTop: '5px' }}>
                {getClientFormattedDate(traceback.create_date, DateFormat.MediumBoth)}
              </p>
            </Col>
            <Col md="4">
              <div className="label-bold">Traceback Created By</div>
              <p className="telecom-text " style={{ paddingTop: '5px' }}>
                {traceback.createdBy}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <div className="label-bold">Traceback Requestor</div>
              <p className="telecom-text " style={{ paddingTop: '5px' }}>
                {traceback.requestors &&
                  traceback.requestors.map((requestor: any) => requestor.name).join(', ')}
              </p>
            </Col>
            <Col md="4">
              <div className="label-bold">Creator's Provider</div>
              <p className="telecom-text " style={{ paddingTop: '5px' }}>
                {traceback.creatorProvider || 'None'}
              </p>
            </Col>
            <Col md="4">
              <div className="label-bold">Traceback No.</div>
              <p className="telecom-text " style={{ paddingTop: '5px' }}>
                {traceback.tracebackId}
              </p>
            </Col>
            <div className="ms-3 w-50">
              <div className="label-bold">Campaign description</div>
              <p className="telecom-text " style={{ paddingTop: '5px' }}>
                {traceback.campaignDescription}
              </p>
            </div>
          </Row>
        </div>
      </div>
      <BasisForTraceback source={traceback.campaignAttributes} user={user} />
    </Fragment>
  );
};

export default TracebackView;
