import { Traceforward } from './actionEnum';

export const getTraceforwards = (response: any) => ({
  type: Traceforward.GET_TRACEFORWARDS,
  payload: response
});

export const getTraceforward = (response: any) => ({
  type: Traceforward.GET_TRACEFORWARD,
  payload: response
});

export const addTraceforward = (response: any) => ({
  type: Traceforward.ADD_TRACEFORWARD,
  payload: response
});

export const editTraceforward = (response: any) => ({
  type: Traceforward.EDIT_TRACEFORWARD,
  payload: response
});

export const getAttachments = (response: any) => ({
  type: Traceforward.GET_ATTACHMENTS,
  payload: response
});

export const addAttachments = (response: any) => ({
  type: Traceforward.ADD_ATTACHMENTS,
  payload: response
});

export const setLoadingStatus = (candidate: boolean) => ({
  type: Traceforward.SET_TRACEFORWARD_LOADING_STATUS,
  payload: candidate
});

export const setErrorMessage = (message: string) => ({
  type: Traceforward.SET_TRACEFORWARD_ERROR_MESSAGE,
  payload: message
});

// export const getTraceforwardStatuses = (response: any) => ({
//   type: Traceforward.GET_TRACEFORWARD_STATUSES,
//   payload: response
// });
