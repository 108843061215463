import React, { useEffect, useMemo } from 'react';

import { connect } from 'react-redux';
import { getCampaignsLabelStatsObject } from '../..//redux/campaign/thunks';
import { stateMappings } from '../../redux/stateMappings';

import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

import { dataCampaignLabels, setChartLegendPosition } from '../../lib/chart/data';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  BarController,
  LineController
);

const options = {
  plugins: {
    title: {
      display: false,
      text: 'Campaign Traceback Volume'
    },
    legend: {},
    tooltip: {
      callbacks: {
        label: (tooltipItem: any) => {
          return tooltipItem.dataset.label + ': ' + tooltipItem.raw.toFixed(2) + '%';
        }
      }
    }
  },
  responsive: true,
  scales: {
    x: {
      stacked: true
    },
    y: {
      ticks: {
        // Converts the value to a percentage
        callback: function (value: any) {
          return value + '%';
        }
      },
      min: 0,
      max: 100,
      stacked: true
    }
  }
};

interface IProps {
  allCampaignLabelsStats: Object;
  getCampaignsLabelStatsObject: Function;
  height?: number;
  width?: number;
  legendPosition: string;
}

const CampaignTracebackVolumeChart: React.FC<IProps> = ({
  allCampaignLabelsStats,
  getCampaignsLabelStatsObject,
  height,
  legendPosition
}) => {
  const dataCampaignLabelsChart = useMemo(
    () => dataCampaignLabels(allCampaignLabelsStats),
    [allCampaignLabelsStats]
  );

  useEffect(() => {
    getCampaignsLabelStatsObject();
  }, []);

  return dataCampaignLabelsChart && Object.values(allCampaignLabelsStats).length > 0 ? (
    <Chart
      type="bar"
      options={setChartLegendPosition(legendPosition, options)}
      data={dataCampaignLabelsChart}
      height={height}
    />
  ) : (
    <h5 className="pt-5">No Data Available</h5>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    allCampaignLabelsStats: sm.campaign.campaignLabelsStats
  };
};

const mapActionsToProps = {
  getCampaignsLabelStatsObject
};

export default connect(mapStateToProps, mapActionsToProps)(CampaignTracebackVolumeChart);
