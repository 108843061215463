import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import { PersonalInfo } from '../../../interfaces/user';
import { addCommentObject } from '../../../redux/comment/thunks';
import { stateMappings } from '../../../redux/stateMappings';
import { getTfHopObject } from '../../../redux/tfhop/thunks';
import DropZone from '../../DropZone';
import { directedToValues } from '../../comments/AddHopComment';
import InputFormGroup from '../../inputFormGroup';

export interface ReplyToInfo {
  commentID: number;
  tfhopID: number;
  providerName: string;
  directedTo?: string;
}
interface IProps {
  tfhopID: number;
  replyTo?: ReplyToInfo;
  isCallDestinationPopulated: boolean;
  tfhopProviderName: string;
  upstreamProviderName: string;
  downstreamProviderName: string;
  getTfHopObject: Function;
  user: PersonalInfo;
  uniqueId?: string;
}

const AddTfHopComment: React.FC<IProps> = ({
  tfhopID,
  replyTo,
  isCallDestinationPopulated,
  tfhopProviderName,
  upstreamProviderName,
  downstreamProviderName,
  getTfHopObject,
  user,
  uniqueId
}) => {
  const [relatedComment, setRelatedComment] = useState('');
  const [commentErrors, setCommentErrors] = useState('');
  const [attachments, setAttachments] = useState<File[]>([]);
  const [isModalComment, setIsModalComment] = useState(false);
  const [directedTo, setDirectedTo] = useState('');

  useEffect(() => {
    setCommentErrors(
      relatedComment && relatedComment.length < 6 ? 'Comment must have more than 5 characters.' : ''
    );
  }, [relatedComment]);

  const handleRelatedCommentInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) setRelatedComment(e.currentTarget.value);
  };

  const addFile = (files: File[]) => {
    setAttachments([
      ...attachments,
      ...files.filter((file) => !attachments.find((item) => item.name === file.name))
    ]);
  };

  const removeFile = (file: File) => {
    setAttachments(attachments.filter((item) => item.name !== file.name));
  };

  const submitComments = () => {
    if (!relatedComment || relatedComment.length < 6 || (!replyTo && !directedTo)) return;

    addCommentObject(
      replyTo
        ? {
            commentType: 'tfhop',
            contentText: relatedComment,
            relatedObjectId: replyTo.tfhopID,
            directedTo: replyTo.directedTo,
            replyToCommentID: replyTo.commentID
          }
        : {
            commentType: 'tfhop',
            contentText: relatedComment,
            relatedObjectId: tfhopID,
            directedTo
          },
      attachments
    ).then(() => {
      getTfHopObject(tfhopID);
      setIsModalComment(false);
      setRelatedComment('');
      setAttachments([]);
    });
  };

  const commentToggle = () => {
    setIsModalComment((v) => !v);
  };

  return (
    <Fragment>
      <Row className="mb-3 ps-4 flex-column">
        <Col>
          <Row>
            <Col lg="8">
              <FormGroup>
                <Label className="telecom-label">
                  {replyTo
                    ? `Reply to ${replyTo.providerName ? replyTo.providerName : ''} `
                    : 'New Comment'}
                </Label>
                <InputFormGroup
                  isReadonly={false}
                  isTextarea
                  inputName="relatedComment"
                  inputId="relatedComment"
                  inputClassName="input-hop"
                  inputValue={relatedComment}
                  inputOnChange={handleRelatedCommentInputChange}
                  inputPlaceholder="This section is only intended for submitting ancillary information. Please submit your traceback response using the form above. Thank you."
                  inputAutoComplete="off"
                  errorMessage={commentErrors}
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <Label className="telecom-label">Attachments</Label>
                <DropZone
                  uniqueId={uniqueId}
                  attachments={attachments}
                  removeFile={removeFile}
                  addFile={addFile}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="flex-column">
            {!replyTo && (
              <div className="d-flex justify-content-center mt-1 pb-3">
                {directedToValues(
                  user.roleType,
                  tfhopProviderName,
                  upstreamProviderName,
                  downstreamProviderName
                ).map((v) => {
                  if (v.value !== 'oldDownstream') {
                    return (
                      <div className="form-check form-check-inline" key={'addhop-' + v.value}>
                        <input
                          id={v.value}
                          type="radio"
                          name="commentType"
                          value={v.value}
                          className="form-check-input"
                          onChange={() => setDirectedTo(v.value)}
                        />
                        <label className="form-check-label" htmlFor={v.value}>
                          to {v.label}
                        </label>
                      </div>
                    );
                  }
                })}
              </div>
            )}
            <div className="d-flex justify-content-center">
              <Button
                className="telecom-btn"
                style={{ minWidth: '130px' }}
                onClick={() => (isCallDestinationPopulated ? commentToggle() : submitComments())}
                disabled={!relatedComment || relatedComment.length < 6 || (!replyTo && !directedTo)}
              >
                Submit {replyTo ? 'Reply' : 'Comment'}
              </Button>
            </div>
          </Row>
        </Col>
      </Row>

      <Modal
        centered
        className="submit-confirm-modal"
        isOpen={isModalComment}
        toggle={commentToggle}
      >
        <ModalBody>
          <h5 className="traceback-sub-title">
            This will only submit the comment provided. The Traceback Response will need to be
            submitted separately
          </h5>
        </ModalBody>
        <ModalFooter style={{ margin: 'auto' }}>
          <Row>
            <Button className="btn-default telecom-btn" color="light" onClick={commentToggle}>
              Cancel
            </Button>
            <Button className="telecom-btn red" onClick={() => submitComments()}>
              Proceed
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return { user: sm.user };
};

const mapActionsToProps = {
  getTfHopObject
};

export default connect(mapStateToProps, mapActionsToProps)(AddTfHopComment);
