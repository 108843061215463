import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import { DateFormat } from '../../../enum/DateFormat';
import { HoneybadgerhqResult } from '../../../interfaces/insights';
import { getClientFormattedDate } from '../../../lib/utilities';
import { generateHoneyBadgerhqApiCall } from '../../../redux/insights/apiCalls';
import { getHoneyBadgerHQ } from '../../../redux/insights/thunks';
import { stateMappings } from '../../../redux/stateMappings';

interface IProps {
  providerId: number;
  honeybadger: HoneybadgerhqResult;
  getHoneyBadgerHQ: Function;
}

const HoneybadgerCard: FC<IProps> = ({ providerId, honeybadger, getHoneyBadgerHQ }) => {
  const [moreInfo, setMoreInfo] = useState(false);
  const [visibleJson, setVisibleJson] = useState(false);

  useEffect(() => {
    if (!honeybadger || honeybadger.providerId !== providerId) getHoneyBadgerHQ(providerId);
  }, [providerId]);

  useEffect(() => {
    if (!moreInfo) setVisibleJson(false);
  }, [moreInfo]);

  const regenerate = async () => {
    try {
      await generateHoneyBadgerhqApiCall(providerId);
    } catch (error: any) {}
    getHoneyBadgerHQ(providerId);
  };

  const toggleMoreInfo = () => {
    setMoreInfo((v) => !v);
  };

  const indicators: any[] =
    (honeybadger &&
      honeybadger.response &&
      honeybadger.response.score &&
      honeybadger.response.score.indicators) ||
    [];
  const emails: any[] = (honeybadger && honeybadger.response && honeybadger.response.emails) || [];
  return (
    <Card className="w-25" style={{ minWidth: '320px' }}>
      <CardBody className="d-flex justify-content-center">
        {honeybadger ? (
          <div className="d-flex flex-column">
            {honeybadger.totalpoints && (
              <div className="h4 text-center">
                <span className="font-weight-bold">{honeybadger.totalpoints}</span> out of{' '}
                <span className="font-weight-bold">{honeybadger.possiblepoints}</span>
              </div>
            )}
            <div className="h5 text-center">
              Risk level: <span className="font-weight-bold">{honeybadger.risklevel}</span>
            </div>
            <div className="h5 font-weight-bold text-center">
              {getClientFormattedDate(honeybadger.update_date, DateFormat.ShortDate)}
            </div>
            <button
              type="button"
              className="btn btn-link ms-2"
              onClick={() => {
                toggleMoreInfo();
              }}
            >
              More{'>>'}
            </button>
          </div>
        ) : (
          'No data please generate'
        )}
      </CardBody>
      <CardFooter className="d-flex justify-content-center">
        <div className="d-flex flex-column">
          <div>Score provided by</div>
          <img
            className="w-100"
            src="https://honeybadgerhq.com/wp-content/uploads/2022/06/hb_logo.png"
            alt="Honey Badger"
          />
          <Button
            className="telecom-btn ps-0 pe-0"
            onClick={() => {
              regenerate();
            }}
          >
            Update Honeybadger Score
          </Button>
        </div>
      </CardFooter>

      <Modal
        centered
        className="modal-template"
        isOpen={moreInfo}
        toggle={() => {
          toggleMoreInfo();
        }}
      >
        <ModalHeader>
          <img
            className="w-100"
            src="https://honeybadgerhq.com/wp-content/uploads/2022/06/hb_logo.png"
            alt="Honey Badger"
          />
          <h5>Business Risk Details</h5>
        </ModalHeader>
        <ModalBody>
          <div className="d-flex flex-column">
            <div>
              Lookup:{' '}
              <span className="font-weight-bold">
                {getClientFormattedDate(honeybadger.update_date, DateFormat.ShortDate)}
              </span>
            </div>
            <div>
              Score: <span className="font-weight-bold">{honeybadger.totalpoints}</span> out of{' '}
              <span className="font-weight-bold">{honeybadger.possiblepoints}</span>
            </div>
            <div>
              Risk level: <span className="font-weight-bold">{honeybadger.risklevel}</span>
            </div>
            {indicators.length && (
              <div>
                Indicators:
                <ul>
                  {indicators.map((v: any) => (
                    <li>
                      {v.name}:{v.value ? 'true' : 'false'}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {emails.length && (
              <div>
                Emails:
                <ul>
                  {emails.map((v: any) => (
                    <li>
                      {v.email} is
                      {v.emailvalidresults && v.emailvalidresults.valid ? ' ' : ' not '}
                      valid
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <div className="telecom-label" onClick={() => setVisibleJson((v) => !v)}>
              <i
                className={`${
                  visibleJson ? 'fa-solid fa-angle-down' : 'fa-solid fa-angle-right'
                } pe-2`}
                style={{ color: '#08335d' }}
              ></i>
              Additional details
            </div>
            {visibleJson && (
              <div>
                <pre>
                  {JSON.stringify(
                    honeybadger.response,
                    (key, value) => {
                      if (
                        key === 'input' ||
                        value === '' ||
                        value === null ||
                        value === undefined ||
                        (typeof value === 'object' && !Object.keys(value).length)
                      )
                        return undefined;
                      return value;
                    },
                    2
                  )}
                </pre>
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn-default telecom-btn"
            color="light"
            onClick={() => {
              toggleMoreInfo();
            }}
          >
            Close
          </Button>
          <Button
            className="telecom-btn"
            onClick={() => {
              regenerate();
            }}
          >
            Update Honeybadger Score
          </Button>
        </ModalFooter>
      </Modal>
    </Card>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return { honeybadger: sm.insight.honeybadger };
};
const mapActionsToProps = { getHoneyBadgerHQ };
export default connect(mapStateToProps, mapActionsToProps)(HoneybadgerCard);
