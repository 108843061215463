import React from 'react';
import ColumnTooltip from '../ColumnTooltip';

const NoSignerInCallPath = () => (
  <ColumnTooltip tooltipVisible columnType={'noSignerInCallPath'}>
    <span className="span-cell red">Call Signer Not in Call Path</span>
  </ColumnTooltip>
);

export default NoSignerInCallPath;
