import axios from 'axios';
import { Pagination } from '../../interfaces/pagination';
import { TfHop } from '../../interfaces/tfhop';
import { FilterElement } from '../../lib/FilterElement';
import { logoutOnAuthError } from '../../lib/apiRequest';
import { downloadFile } from '../../lib/utilities';
import { getZeroBounceResponse } from '../zeroBounce/action';
import {
  getTfHop,
  getTfHopComments,
  getTfHopStatuses,
  getTfHops,
  getTfHopsCount,
  setErrorMessage,
  setInvalidTfHopId,
  setLoadingStatus
} from './actions';
import {
  deleteTfHopApiCall,
  getAllTfHopsApiCall,
  getCSVApiCall,
  getTfHopApiCall,
  getTfHopCommentsApiCall,
  getTfHopStatusApiCall,
  getTfHopsCountApiCall,
  resetTfHopApiCall,
  updateTfHopApiCall
} from './apiCalls';

const setStatus = (status: boolean, message: string) => async (dispatch: any) => {
  dispatch(setErrorMessage(message));
  dispatch(setLoadingStatus(status));
};

export const updateTfHopObject = (params: TfHop) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  const postData = {
    ...params,
    added: params.added,
    completed: undefined,
    create_date: undefined,
    notified: undefined,
    update_date: undefined
  };

  try {
    const response = await updateTfHopApiCall(postData);
    if (response && response.data) {
      dispatch(getTfHop(response.data));
      dispatch(getZeroBounceResponse(response.data.zeroBounceResponse));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const resetTfHopObject = (hopId: number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    const response = await resetTfHopApiCall(hopId);
    //TODO: Figure out why the frontend gets a 400 response even though the backend sends a 200
    if (response && response.data) {
      dispatch(getTfHop(response.data));
      window.location.reload();
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    window.location.reload();
    logoutOnAuthError(error);
  }
};

export const getAllTfHopsList =
  (params: Pagination, filterElements: FilterElement) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    try {
      const hops = await getAllTfHopsApiCall(params, filterElements);

      if (hops && hops.data) {
        dispatch(getTfHops(hops.data));
      }
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      if (!axios.isCancel(error)) logoutOnAuthError(error as any);
    }
  };

export const getTfHopObject = (hopId: number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    const hop = await getTfHopApiCall(hopId);
    if (hop && hop.data) {
      dispatch(getTfHop(hop.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    if (error.response && error.response.status === 404) {
      dispatch(setStatus(false, error.response.status.toString()));
      dispatch(setInvalidTfHopId(hopId));
    } else {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  }
};

export const getTfHopStatusList = () => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    const hopStatuses = await getTfHopStatusApiCall();
    if (hopStatuses && hopStatuses.data) {
      dispatch(getTfHopStatuses(hopStatuses.data.data));
      dispatch(setStatus(false, ''));
    }
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const deleteTfHop = (hopId: number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    await deleteTfHopApiCall(hopId);
    dispatch(setStatus(false, ''));
    window.location.href = '/tfhops';
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const getTfHopCommentsList = (hopId: number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    const comments = await getTfHopCommentsApiCall(hopId);
    if (comments && comments.data) {
      dispatch(getTfHopComments(comments.data));
    }
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const getTfHopsCountNumber = (filterElements: FilterElement) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    const hopCount = await getTfHopsCountApiCall(filterElements);
    if (hopCount) {
      dispatch(getTfHopsCount(hopCount));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const getTfHopsCSV =
  (filterElements: FilterElement, params: Pagination) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    try {
      const response = await getCSVApiCall(filterElements, params);
      downloadFile(response.data, response.headers.contentfilename);
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };

// export const getHopDisputeObject = (hopId: number) => async (dispatch: any) => {
//   dispatch(setStatus(true, ''));

//   try {
//     const dispute = await getHopDisputeApiCall(hopId);
//     if (dispute && dispute.data) {
//       dispatch(getDisputeAction(dispute.data));
//     }
// } catch (error: any) {
//     dispatch(getDisputeAction(null));
//   }
//   dispatch(setStatus(false, ''));
// };
