export enum NotProvidedReason {
  empty = '',
  isRecordUn = 'isRecordUn',
  isCallOld = 'isCallOld',
  isRefusing = 'isRefusing',
  isProviderNonRes = 'isProviderNonRes',
  tracebackCancel = 'tracebackCancel',
  imposter = 'imposter'
}

export interface CallSource {
  businessName: string;
  addressLine1: string;
  contactName: string;
  contactPhone: string;
  country: string;
  comment: string;
  city: string;
  region: string;
  contactEmail: string;
  postalCode: string;
  stepsTaken: string;
  explanation: string;
  currentCause: NotProvidedReason;
  ipAddress: string;
  customerType: string;
  averageTraffic: string;
  actionTaken: string;
  reasonRejection: string;
}

export const emptyCallSource = (): CallSource => ({
  businessName: '',
  addressLine1: '',
  contactName: '',
  contactPhone: '',
  country: '',
  comment: '',
  city: '',
  region: '',
  contactEmail: '',
  postalCode: '',
  stepsTaken: '',
  explanation: '',
  currentCause: NotProvidedReason.empty,
  ipAddress: '',
  customerType: '',
  averageTraffic: '',
  actionTaken: '',
  reasonRejection: ''
});
