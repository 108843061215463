import React from 'react';
import { connect } from 'react-redux';
import { userTypes } from '../../enum/userTypes';
import { Hop } from '../../interfaces/hop';
import { PersonalInfo } from '../../interfaces/user';
import { certificateExpiredError, stirshakenErrors } from '../../lib/utilities';
import { stateMappings } from '../../redux/stateMappings';
import CustomToolTip from '../CustomToolTip';
import ProviderPanel from '../ProviderPanel';
import NotSignedByOrigin from './NotSignedByOrigin';

interface IProps {
  hop: Hop;
  includeLinks: boolean;
  user: PersonalInfo;
}

const SignerHopCellContent: React.FC<IProps> = ({ hop, includeLinks, user }) => {
  return (
    <div className="table-cell center">
      {hop.stirShakenFlag === 'poeNotSigned' && (
        <div>
          <span className="span-cell red">
            <CustomToolTip
              tooltipVisible
              message="The ITG did not receive information identifying the U.S.-based Point of entry having signed this call which originated outside the U.S."
            >
              <span className="span-cell red"> POE Signing Not Provided</span>
            </CustomToolTip>
            {hop.stirShaken && hop.stirShaken.errorMessage && ' / '}
          </span>
        </div>
      )}
      {hop.stirShakenFlag == 'orgNotSigned' && (
        <div>
          <NotSignedByOrigin />
          <span className="span-cell red">
            {hop.stirShaken && hop.stirShaken.errorMessage && ' / '}
          </span>
        </div>
      )}
      {hop.stirShaken &&
        hop.stirShaken.errorMessage &&
        stirshakenErrors(hop.stirShaken.errorMessage, user.roleType, false).valid && (
          <div>
            <CustomToolTip tooltipVisible message={hop.stirShaken.errorMessage}>
              <span className="span-cell red">Error With Signing</span>
            </CustomToolTip>
            <span className="span-cell red">
              {hop.stirShaken &&
                (hop.stirShaken.signerProviderName ||
                  (hop.stirShaken.signer &&
                    hop.stirShaken.signer.organization &&
                    hop.stirShaken.signer.organization[0]) ||
                  (hop.status === 2 &&
                    hop.upstreamHopId === 0 &&
                    hop.hopProvider.country === 'United States' &&
                    hop.stirShaken.status === 1 &&
                    hop.stirShaken.isCallSigner)) &&
                ' / '}
            </span>
          </div>
        )}
      {hop.stirShaken &&
        (hop.stirShaken.signerProviderName && hop.stirShaken.objectId !== hop.hopId ? (
          <CustomToolTip tooltipVisible message={'Identified as signer by a downstream provider'}>
            <span className="span-cell">({hop.stirShaken.signerProviderName})</span>
          </CustomToolTip>
        ) : hop.stirShaken.signerProviderName && hop.stirShaken.objectId === hop.hopId ? (
          <div className="justify-content-center d-flex">
            <ProviderPanel
              hyperlink={includeLinks}
              provider={{
                providerId: hop.stirShaken.signerProviderId,
                name: hop.stirShaken.signerProviderName,
                isDomestic: hop.stirShaken.signerIsDomestic,
                isITGMember: hop.stirShaken.signerIsITGMember
              }}
            />
          </div>
        ) : hop.status === 2 &&
          hop.upstreamHopId === 0 &&
          hop.hopProvider.country === 'United States' &&
          hop.stirShaken.status === 1 &&
          hop.stirShaken.isCallSigner ? (
          <div className="justify-content-center d-flex">
            <ProviderPanel hyperlink={includeLinks} provider={hop.hopProvider} />
          </div>
        ) : (
          <span className="table-cell gray justify-content-center d-flex">
            {hop.stirShaken.signer ? hop.stirShaken.signer.organization : null}
          </span>
        ))}

      {(user.roleType !== userTypes.GovUser
        ? hop.stirShaken &&
          hop.stirShaken.issuer &&
          hop.stirShaken.issuer.commonName &&
          !hop.stirShaken.isIssuerValid // is not gov so show only the current hop issuer error
        : hop.govIssuerNotValid) &&
        !hop.stirShaken.certificateErrors?.includes(certificateExpiredError) && ( // is gov check govIssuerNotValid
          <div>
            <CustomToolTip
              tooltipVisible
              message="The issuer identified in the STIR/SHAKEN header is not an approved certification authority"
            >
              <span className="span-cell red">Issuer Not Approved</span>
            </CustomToolTip>
          </div>
        )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return { user: sm.user };
};

export default connect(mapStateToProps)(SignerHopCellContent);
