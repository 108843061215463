import { sub } from 'date-fns';
import { convertToTimeZone } from 'date-fns-timezone';
import React, { Fragment, useState } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { Button, Col, Label, Row } from 'reactstrap';
import {
  ReportFormat,
  ReportPostData,
  ReportType,
  RollupReportCampaignRow,
  RollupReportObject,
  RollupReportTracebackRow
} from '../../../../interfaces/report';
import { getEndOfTheDay, getStartOfTheDay } from '../../../../lib/utilities';
import { getReportObject } from '../../../../redux/report/thunks';
import { stateMappings } from '../../../../redux/stateMappings';
import CampaignHeaderRow from './CampaignHeaderRow';
import TracebackRow from './TracebackDataRow';

interface IProps {
  reportName: string;
  narrativeIntro: string;
  getReportObject: Function;
  report: RollupReportObject;
}

const RollupReport: React.FC<IProps> = ({
  reportName,
  narrativeIntro,
  getReportObject,
  report
}) => {
  const [startDate, setStartDate] = useState(sub(new Date(), { months: 1 }));
  const [endDate, setEndDate] = useState(new Date());
  const [markConfidential, setMarkConfidential] = useState(true);
  const [includeTracebacksRollup, setIncludeTracebacksRollup] = useState(true);
  const [includeProvidersRollup, setIncludeProvidersRollup] = useState(true);
  const [includeCampaignsRollup, setIncludeCampaignsRollup] = useState(true);
  const [includeProviderNames, setIncludeProviderNames] = useState(true);

  const getReport = (format: ReportFormat) => {
    getReportObject(
      {
        startDate: getStartOfTheDay(startDate).toISOString(),
        endDate: getEndOfTheDay(endDate).toISOString(),
        includeTracebacksRollup,
        markConfidential,
        name: reportName,
        narrativeIntro,
        includeProvidersRollup,
        includeProviderNames,
        includeCampaignsRollup
      } as ReportPostData,
      ReportType.RollupReport,
      format
    );
  };

  const handleCheck = (e: any, key: string) => {
    switch (key) {
      case 'startDate':
        setStartDate(e);
        break;
      case 'endDate':
        setEndDate(e);
        break;
      case 'markConfidential':
        setMarkConfidential((v) => !v);
        break;
      case 'includeTracebacksRollup':
        setIncludeTracebacksRollup((v) => !v);
        break;
      case 'includeProvidersRollup':
        setIncludeProvidersRollup((v) => !v);
        break;
      case 'includeCampaignsRollup':
        setIncludeCampaignsRollup((v) => !v);
        break;
      case 'includeProviderNames':
        setIncludeProviderNames((v) => !v);
        break;
      case 'json':
        getReport(ReportFormat.Json);
        break;
      case 'word':
        getReport(ReportFormat.Word);
        break;
    }
  };

  return (
    <Fragment>
      <Label className="traceback-label ps-0 mt-4">Provide The Date Range *</Label>
      <div className="d-flex flex-row flex-wrap mb-4">
        <div style={{ minWidth: '150px', maxWidth: '240px' }}>
          <DatePicker
            selected={startDate ? convertToTimeZone(startDate, { timeZone: 'GMT' }) : undefined}
            onChange={(date) => handleCheck(date, 'startDate')}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            maxDate={endDate ? convertToTimeZone(endDate, { timeZone: 'GMT' }) : new Date()}
          />
        </div>
        <span className="ps-3 pe-3"> - </span>
        <div style={{ minWidth: '150px', maxWidth: '240px' }}>
          <DatePicker
            selected={endDate ? convertToTimeZone(endDate, { timeZone: 'GMT' }) : undefined}
            onChange={(date) => handleCheck(date, 'endDate')}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            maxDate={new Date()}
          />
        </div>
      </div>
      <div className="d-flex flex-row flex-wrap m-4">
        <label className="form-label tile-label" style={{ fontSize: '15px', alignSelf: 'center' }}>
          Display Tracebacks Rollup
          <input
            type="checkbox"
            onChange={(option) => handleCheck(option, 'includeTracebacksRollup')}
            checked={includeTracebacksRollup}
          />
          <span className="checkmark tile-checkmark" />
        </label>
        <label className="form-label tile-label" style={{ fontSize: '15px', alignSelf: 'center' }}>
          Display Providers Rollup
          <input
            type="checkbox"
            onChange={(option) => handleCheck(option, 'includeProvidersRollup')}
            checked={includeProvidersRollup}
          />
          <span className="checkmark tile-checkmark" />
        </label>
        <label className="form-label tile-label" style={{ fontSize: '15px', alignSelf: 'center' }}>
          Display Campaigns Rollup
          <input
            type="checkbox"
            onChange={(option) => handleCheck(option, 'includeCampaignsRollup')}
            checked={includeCampaignsRollup}
          />
          <span className="checkmark tile-checkmark" />
        </label>
        <label className="form-label tile-label" style={{ fontSize: '15px', alignSelf: 'center' }}>
          Display Provider Names
          <input
            type="checkbox"
            onChange={(option) => handleCheck(option, 'includeProviderNames')}
            checked={includeProviderNames}
          />
          <span className="checkmark tile-checkmark" />
        </label>
        <label className="form-label tile-label" style={{ fontSize: '15px', alignSelf: 'center' }}>
          Mark as Confidential
          <input
            type="checkbox"
            onChange={(option) => handleCheck(option, 'markConfidential')}
            checked={markConfidential}
          />
          <span className="checkmark tile-checkmark" />
        </label>
      </div>
      <div className="d-flex justify-content-md-center">
        {report && (report.rollupReportCampaignRow || report.rollupReportStatisticsRow) ? (
          <Button
            className="telecom-btn me-2"
            color="primary"
            style={{
              height: '28px',
              padding: '5px',
              fontSize: '14px',
              width: '150px'
            }}
            onClick={(option) => handleCheck(option, 'json')}
          >
            {`Update Report Display`}
          </Button>
        ) : (
          <Button
            className="telecom-btn me-2"
            color="primary"
            style={{
              height: '28px',
              padding: '5px',
              fontSize: '14px',
              width: '150px'
            }}
            onClick={(option) => handleCheck(option, 'json')}
          >
            {`Display Report`}
          </Button>
        )}
        <Button
          className="telecom-btn me-2"
          color="primary"
          style={{
            height: '28px',
            padding: '5px',
            fontSize: '14px',
            width: '150px'
          }}
          onClick={(option) => handleCheck(option, 'word')}
        >
          {`Create Word Doc`}
        </Button>
      </div>
      {report && report.rollupReportStatisticsRow && (
        <Fragment>
          {report.rollupReportStatisticsRow[0].notFound &&
          report.rollupReportStatisticsRow[0].responded ? (
            <Label className="traceback-label ps-0 mt-2 mb-2">Tracebacks & Providers</Label>
          ) : report.rollupReportStatisticsRow[0].notFound ? (
            <Label className="traceback-label ps-0 mt-2 mb-2">Tracebacks</Label>
          ) : (
            <Label className="traceback-label ps-0 mt-2 mb-2">Providers</Label>
          )}
          <Row style={{ padding: '0 10px' }}>
            <Col md="4" />
            {report.rollupReportStatisticsRow[0].notFound && (
              <Col md="4" className="text-center" style={{ backgroundColor: '#e3e3e3' }}>
                <Label className="m-0">Traceback Outcome</Label>
              </Col>
            )}
            {report.rollupReportStatisticsRow[0].responded && (
              <Col md="4" className="text-center" style={{ backgroundColor: '#e3e3e3' }}>
                <Label className="m-0">Providers</Label>
              </Col>
            )}
          </Row>
          <Row className="me-2" style={{ padding: '0 10px' }}>
            <Col md="4">
              <Row>
                <Col className="p-0">
                  <Label>Month</Label>
                </Col>
                <Col className="p-0 text-center">
                  <Label>New TBs</Label>
                </Col>
                <Col className="p-0 text-center">
                  <Label>Total Hops</Label>
                </Col>
              </Row>
            </Col>
            {report.rollupReportStatisticsRow[0].notFound && (
              <Col md="4">
                <Row>
                  <Col className="p-0 text-center">
                    <Label>Not Found</Label>
                  </Col>
                  <Col className="p-0 text-center">
                    <Label>No Info</Label>
                  </Col>
                  <Col className="p-0 text-center">
                    <Label>Originator</Label>
                  </Col>
                  <Col className="p-0 text-center">
                    <Label>Open</Label>
                  </Col>
                </Row>
              </Col>
            )}
            {report.rollupReportStatisticsRow[0].responded && (
              <Col md="4">
                <Row>
                  <Col className="p-0 text-center">
                    <Label>Responded</Label>
                  </Col>
                  <Col className="p-0 text-center">
                    <Label>Sometimes</Label>
                  </Col>
                  <Col className="p-0 text-center">
                    <Label>No Response</Label>
                  </Col>
                  <Col className="p-0 text-center">
                    <Label>Total</Label>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
          {report.rollupReportStatisticsRow.map(
            (rollupReportStatisticsRow: RollupReportTracebackRow) => (
              <div key={rollupReportStatisticsRow.period}>
                <TracebackRow rollupReportStatisticsRow={rollupReportStatisticsRow} />
              </div>
            )
          )}
        </Fragment>
      )}
      {report && report.rollupReportCampaignRow && (
        <Fragment>
          <Label className="traceback-label ps-0 mt-4 mb-2">Campaigns</Label>
          {report.rollupReportCampaignRow.map(
            (rollupReportCampaignRow: RollupReportCampaignRow) => (
              <div key={rollupReportCampaignRow.campaignName}>
                <CampaignHeaderRow rollupReportCampaignRow={rollupReportCampaignRow} />
              </div>
            )
          )}
          <Row>
            <span>
              * Tracebacks are occasionally archived for various reasons, resulting in a discrepancy
              between the number of total tracebacks and the tracebacks outcome figures.
            </span>
          </Row>
        </Fragment>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return { report: sm.report.rollupReport };
};

const mapActionsToProps = { getReportObject };

export default connect(mapStateToProps, mapActionsToProps)(RollupReport);
