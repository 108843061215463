import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Button, CardBody, Label } from 'reactstrap';
import { stateMappings } from '../../../redux/stateMappings';
import { regenerateIssuerListApiCall } from '../../../redux/stirShaken/apiCalls';
import InputFormGroup from '../../inputFormGroup';

interface IProps {}

const StirShakenSettings: React.FC<IProps> = ({}) => {
  const [jwt, setJWT] = useState('');
  const [error, setError] = useState('');
  const handleSave = async () => {
    try {
      await regenerateIssuerListApiCall(jwt);
      setJWT('');
    } catch (error) {
      setError(error as string);
    }
  };
  return (
    <Fragment>
      <Label className="pt-3 traceback-heading">StirShaken Controls</Label>
      <CardBody>
        <Label className="telecom-label ps-0">Trusted STI-CA List</Label>
        <InputFormGroup
          inputName="jwt"
          inputId="jwt"
          inputValue={jwt}
          inputOnChange={(e) => setJWT(e.currentTarget.value)}
          inputPlaceholder="JWT"
          inputAutoComplete="off"
          isTextarea
          errorMessage={error}
        />
        <div className="d-flex justify-content-center">
          <Button color="primary" disabled={jwt.length == 0} onClick={() => handleSave()}>
            Save
          </Button>
        </div>
      </CardBody>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {};
};

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(StirShakenSettings);
