import React from 'react';
import { TableColumn } from 'react-data-table-component';
import ColumnTooltip from '../../components/ColumnTooltip';
import { DateFormat } from '../../enum/DateFormat';
import { LabelSummary } from '../campaign';
import { getClientFormattedDate, getElapsedTime } from '../utilities';

export const campaignColumns: TableColumn<LabelSummary>[] = [
  {
    name: '',
    width: '20px',
    cell: () => <div />
  },
  {
    name: 'Campaign Label',
    selector: () => 'name',
    sortable: true,
    grow: 1,
    cell: (row: LabelSummary) => {
      return (
        <div className="table-cell blue">
          <a
            href="/tracebacks"
            onClick={() => localStorage.setItem('campaignLabel', row.labelId.toString())}
          >
            {row.name}
          </a>
        </div>
      );
    }
  },
  {
    name: 'Tracebacks',
    selector: () => 'numberOfTB',
    sortable: true,
    grow: 1,
    cell: (row: LabelSummary) => <span className=" table-cell center">{row.numberOfTB || 0}</span>
  },
  {
    name: (
      <ColumnTooltip columnType="NoResp" tooltipVisible>
        No Resp
      </ColumnTooltip>
    ),
    selector: () => 'numOfNoResponseHops',
    sortable: true,
    grow: 1,
    cell: (row: LabelSummary) => (
      <span className="table-cell center">{row.numOfNoResponseHops || 0}</span>
    )
  },
  {
    name: 'US-Orig',
    selector: () => 'numOfUSOriginHops',
    sortable: true,
    grow: 1,
    cell: (row: LabelSummary) => (
      <span className="table-cell center ">{row.numOfUSOriginHops || 0}</span>
    )
  },
  {
    name: 'Non-US-Orig',
    selector: () => 'numOfNonUsOriginHops',
    sortable: true,
    grow: 1,
    cell: (row: LabelSummary) => (
      <span className="table-cell center ">{row.numOfNonUsOriginHops || 0}</span>
    )
  },
  {
    name: 'Avg Completion',
    selector: () => 'avgCompletionTime',
    sortable: true,
    grow: 1,
    cell: (row: LabelSummary) => (
      <span className="table-cell center ">{getElapsedTime(row.avgCompletionTime) || 0}</span>
    )
  },
  {
    name: (
      <ColumnTooltip columnType="LastActive" tooltipVisible>
        Last Activity
      </ColumnTooltip>
    ),
    selector: () => 'lastActivity',
    sortable: true,
    grow: 1,
    cell: (row: LabelSummary) => (
      <span className="table-cell gray center">
        {getClientFormattedDate(row.lastActivity, DateFormat.ShortDate) || '-'}
      </span>
    )
  }
];
