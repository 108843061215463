import axios, { Canceler } from 'axios';
import { logoutOnAuthError } from '../../lib/apiRequest';
import { getRMDsResponse } from './actions';
import { getAllRMDsApiCall } from './apiCalls';

// to cancel previous request
const CancelToken = axios.CancelToken;
let cancel: Canceler;

export const getAllRMDs = (name: string) => async (dispatch: any) => {
  if (cancel) cancel();
  try {
    const rmds = await getAllRMDsApiCall(
      new CancelToken((c) => {
        cancel = c;
      }),
      name
    );

    if (rmds && rmds.data) {
      dispatch(getRMDsResponse(rmds.data));
    }
  } catch (error: any) {
    logoutOnAuthError(error as any);
  }
};
