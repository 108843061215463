export const campaignAttributes = [
  {
    tag: 'fraud',
    name: 'Evidence of fraud in call content',
    toolMessage:
      'Content of calls or description from referral indicates caller is seeking to defraud called party, through impersonation or other means, in possible violation of federal and state criminal statutes, unfair and deceptive laws and regulations, the Telephone Consumer Protection Act and Telemarketing Sales Rule, state telemarketing laws, and trademark laws.'
  },
  {
    tag: 'brandImpersonation',
    name: 'Evidence of brand impersonation',
    toolMessage: 'Company/Agency name mentioned'
  },
  {
    tag: 'numberSpoofing',
    name: 'Evidence of spoofing',
    toolMessage:
      'Calling pattern or other evidence indicates that caller is relying on spoofing to reach consumers, in possible violation of the Truth in Caller ID Act and implementing rules and state equivalents.'
  },
  {
    tag: 'dialing',
    name: 'Evidence of random or sequential dialing',
    toolMessage:
      'Calling pattern indicates caller is relying on random or sequential dialing in possible violation of the Telephone Consumer Protection Act and state equivalents.'
  },
  {
    tag: 'eHighVolume',
    name: 'Evidence of lack of consent for prerecorded message - high volume',
    toolMessage:
      'Telemarketing campaign includes prerecorded messages and is at significantly high volume, which regulators have indicated is a reason to be skeptical of consent, in possible violation of the Telephone Consumer Protection Act and FCC implementing rules, the Telemarketing Sales Rule, and state equivalents.'
  },
  {
    tag: 'eCalledPartyConsent',
    name: 'Evidence of lack of consent for prerecorded message - called party confirmation',
    toolMessage:
      'Telemarketing calls from campaign are reaching called parties that have confirmed they have not provided consent to receive the calls in possible violation of the Telephone Consumer Protection Act and FCC implementing rules, the Telemarketing Sales Rule, and state equivalents.'
  },
  {
    tag: 'eComplaints',
    name: 'Evidence of lack of consent for prerecorded message - consumer complaints',
    toolMessage:
      'Telemarketing campaign has resulted in a high number of consumer complaints, suggesting that there is no consent or that consent is not valid, in possible violation of the Telephone Consumer Protection Act and FCC implementing rules, the Telemarketing Sales Rule, and state equivalents.'
  },
  {
    tag: 'eFlawedConsent',
    name: 'Evidence of lack of consent for prerecorded message - flawed consent claim',
    toolMessage:
      'Investigation of the claimed consent mechanism confirms that telemarketer relied on buried click throughs and broad consent inconsistent with and in potential violation of requirements under the Telephone Consumer Protection Act, FCC implementing rules and the Telemarketing Sales Rule, and state equivalents.'
  },
  {
    tag: 'doNotCall',
    name: 'Calls in campaign reaching numbers on Do Not Call list',
    toolMessage:
      'Campaign is reaching a high number of numbers on the Do Not Call list, indicating caller may be in violation of federal and state Do Not Call requirements.'
  },
  {
    tag: 'noName',
    name: 'Failure to identify the entity responsible for initiating the call',
    toolMessage:
      'Calls in the campaign use artificial or prerecorded voice telephone messages and fail to identify the business, individual, or other entity responsible for initiating the call, in violation of 47 CFR sec. 64.1200(b)(1). If a business, the name under which the entity is registered to conduct business with the State Corporation Commission or comparable authority must be stated.'
  },
  {
    tag: 'noNumber',
    name: 'Failure to clearly state the telephone number of the entity responsible for initiating the call',
    toolMessage:
      'Calls in the campaign use artificial or prerecorded voice telephone messages and fail during or after the message to state clearly the telephone number of the business, individual, or other entity responsible for initiating the call, in violation of 47 CFR sec. 64.1200(b)(2).'
  },
  {
    tag: 'noOptOut',
    name: 'Failure to provide opt-out mechanism',
    toolMessage:
      'Calls in the campaign use artificial or prerecorded voice telephone messages and fail to provide an automated, interactive voice- and/or key press-activated opt-out mechanism, in violation of 47 CFR sec. 64.1200(b)(3)'
  },
  {
    tag: 'annoyanceAbuse',
    name: 'Repeated unwanted and unsolicited calls',
    toolMessage:
      'Repeated unwanted and unsolicited calls, including to wrong numbers and called parties.'
  }
];

export interface LabelSummary {
  labelId: number;
  name: string;
  avgCompletionTime: number;
  numberOfTB: number;
  numOfNoResponseHops: number;
  lastActivity: string;
  numOfUSOriginHops: number;
  numOfNonUsOriginHops: number;
}
