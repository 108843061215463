import { sub } from 'date-fns';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { DateFormat } from '../enum/DateFormat';
import { Pagination, defaultPagination } from '../interfaces/pagination';
import { NewProviderDetail, NrOrNfProviderDetail } from '../interfaces/provider';
import { PersonalInfo } from '../interfaces/user';
import { newProviderColumns } from '../lib/dataTableUtils/newProviderColumns';
import { nrOrNfProviderColumns } from '../lib/dataTableUtils/nrProviderColumns';
import { getEndOfDayAsString, getStartOfDayAsString } from '../lib/utilities';
import { getNewProvidersInfo, getNrOrProvidersInfo } from '../redux/provider/thunks';
import { stateMappings } from '../redux/stateMappings';
import CustomDataTable from './CustomDataTable';
import { ProviderInfoType } from '../lib/chart/data';

interface IProps {
  providerInfoType: ProviderInfoType;
  newProviders: NewProviderDetail[];
  nrProviders: NrOrNfProviderDetail[];
  nfProviders: NrOrNfProviderDetail[];
  getNewProvidersInfo: Function;
  getNrOrProvidersInfo: Function;
  user: PersonalInfo;
  homeTable: boolean;
  countNewProviders: number;
  countNrProviders: number;
  countNfProviders: number;
  pageSize: number;
  startDate?: string | Date;
  endDate?: string | Date;
  providerSelected?: number;
}

const HomePageProviderTable: FC<IProps> = ({
  newProviders,
  nrProviders,
  getNewProvidersInfo,
  getNrOrProvidersInfo,
  providerInfoType,
  user,
  homeTable,
  countNewProviders,
  countNrProviders,
  pageSize,
  startDate,
  endDate,
  providerSelected,
  nfProviders,
  countNfProviders
}) => {
  const defaultStartDate = useMemo(
    () => startDate || getStartOfDayAsString(sub(new Date(), { days: 90 }), DateFormat.DBDate),
    [startDate]
  );
  const defaultEndDate = useMemo(
    () => endDate || getEndOfDayAsString(new Date(), DateFormat.DBDate),
    [endDate]
  );

  const [paginationParams, setPaginationParams] = useState({
    ...defaultPagination(),
    sort: providerInfoType === ProviderInfoType.NewProvider ? 'dateAdded' : 'nrTracebacks',
    order: 'desc',
    pageSize: pageSize
  });

  useEffect(() => {
    if (providerInfoType === ProviderInfoType.NewProvider) {
      getNewProvidersInfo(
        {
          startDate: defaultStartDate,
          endDate: defaultEndDate,
          searchedProvider: providerSelected
        },
        paginationParams
      );
    } else {
      getNrOrProvidersInfo(
        {
          startDate: defaultStartDate,
          endDate: defaultEndDate,
          searchedProvider: providerSelected,
          isNr: providerInfoType === ProviderInfoType.NrProvider
        },
        paginationParams
      );
    }
  }, [defaultStartDate, defaultEndDate, providerSelected, paginationParams, providerInfoType]);

  const updatePagination = (params: Pagination) => {
    setPaginationParams({ ...paginationParams, ...params });
  };
  return (
    <div className={`${!homeTable ? 'mb-5' : ''}`}>
      <CustomDataTable
        tableData={
          providerInfoType === ProviderInfoType.NewProvider
            ? newProviders
            : providerInfoType === ProviderInfoType.NrProvider
              ? nrProviders
              : nfProviders
        }
        columns={
          providerInfoType === ProviderInfoType.NewProvider
            ? newProviderColumns(user)
            : nrOrNfProviderColumns(user, providerInfoType === ProviderInfoType.NrProvider)
        }
        noDataText={
          providerInfoType === ProviderInfoType.NewProvider
            ? 'No provider Created in Last 90 days'
            : providerInfoType === ProviderInfoType.NrProvider
              ? 'No NR provider in Last 90 days'
              : 'No NF provider in Last 90 days'
        }
        defaultSortFieldId={paginationParams.sort}
        defaultPage={paginationParams.page}
        defaultPageSize={paginationParams.pageSize}
        paginationTotalRows={
          providerInfoType === ProviderInfoType.NewProvider
            ? countNewProviders
            : providerInfoType === ProviderInfoType.NrProvider
              ? countNrProviders
              : countNfProviders
        }
        updatePaginationParams={updatePagination}
        pagination={!homeTable}
        maxPageSize={10}
      />
      {homeTable && (
        <div className="mt-3 ms-5">
          <a href={providerInfoType}>{`See More >>`}</a>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    newProviders: sm.provider.newProvidersList,
    countNewProviders: sm.provider.countNewProviders,
    nrProviders: sm.provider.nrProvidersList,
    nfProviders: sm.provider.nfProvidersList,
    countNrProviders: sm.provider.countNrProviders,
    countNfProviders: sm.provider.countNfProviders,
    user: sm.user
  };
};

const mapActionsToProps = {
  getNewProvidersInfo,
  getNrOrProvidersInfo
};
export default connect(mapStateToProps, mapActionsToProps)(HomePageProviderTable);
