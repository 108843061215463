import { Comments } from './actionEnum';

const meta = {
  TotalCount: 0,
  PageSize: 0,
  CurrentPage: 0,
  TotalPages: 1
};

const defaultState: any = {
  comments: [],
  attachments: [],
  newCommentsNotificationList: [],
  newHopsCommentsNotificationList: [],
  comment: {
    commentId: 0,
    campaignId: 0,
    campaignName: '',
    callingTN: '',
    calledTN: '',
    commentTime: '',
    termCarrierId: '',
    termCarrierOCN: '',
    termLineType: '',
    termState: '',
    callerName: '',
    create_date: '',
    createdBy: '',
    creatorProvider: '',
    elapsed: 0,
    strikeExempt: true,
    dnc: '',
    description: '',
    audioName: 0,
    audioUrl: 0,
    audioUrlPath: 0,
    updateFlag: 0
  },
  meta,
  loading: 0,
  error: ''
};

const reducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case Comments.GET_COMMENTS:
      return {
        ...state,
        comments: action.payload.data,
        meta: action.payload.paginationMeta || meta
      };

    case Comments.GET_COMMENT:
      return { ...state, comment: action.payload.data };

    case Comments.ADD_COMMENT:
      return { ...state };

    case Comments.EDIT_COMMENT:
      return { ...state, comment: action.payload.data };

    case Comments.ADD_ATTACHMENTS:
      return { ...state };

    case Comments.SET_COMMENT_LOADING_STATUS:
      if (action.payload) return { ...state, loading: state.loading + 1 };
      else return { ...state, loading: state.loading === 0 ? state.loading : state.loading - 1 };

    case Comments.SET_COMMENT_ERROR_MESSAGE:
      return { ...state, error: action.payload };

    case Comments.CLEAR_COMMENTS:
      return { ...state, comments: [] };

    case Comments.GET_NEW_COMMENTS_LIST:
      return { ...state, newCommentsNotificationList: action.payload };

    case Comments.GET_HOPS_NOTIFICATIONS:
      return { ...state, newHopsCommentsNotificationList: action.payload };

    default:
      return state;
  }
};

export default reducer;
