import { Pagination } from 'reactstrap';
import { ChartFilterElements } from '../../interfaces/charts';
import {
  StirShakenProviderNotInCallPathRequest,
  StirShakenSearchRequest
} from '../../interfaces/stirShaken';
import { logoutOnAuthError } from '../../lib/apiRequest';
import {
  getAttestationStatsAction,
  getOriginStatsAction,
  getStirShakens,
  getStirShakensIssues,
  setErrorMessage,
  setLoadingStatus
} from './actions';
import {
  getAttestationStatsApiCall,
  getOriginStatsApiCall,
  getStirShakensApiCall,
  getStirShakensIssuesApiCall
} from './apiCalls';

export const getStirShakensSigners =
  (params: Pagination, filterElements: StirShakenSearchRequest) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));
    try {
      const response = await getStirShakensApiCall(params, filterElements);
      if (response && response.data) {
        dispatch(getStirShakens(response.data));
      }
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
    dispatch(setLoadingStatus(false));
  };

export const getAttestationStats =
  (filterElements: ChartFilterElements) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));
    try {
      const response = await getAttestationStatsApiCall(filterElements);
      if (response && response.data) {
        dispatch(getAttestationStatsAction(response.data));
      }
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      logoutOnAuthError(error);
      dispatch(setStatus(false, error.message));
    }
  };

export const getOriginStats = (filterElements: ChartFilterElements) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  try {
    const response = await getOriginStatsApiCall(filterElements);
    if (response && response.data) {
      dispatch(getOriginStatsAction(response.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    logoutOnAuthError(error);
    dispatch(setStatus(false, error.message));
  }
};

const setStatus = (status: boolean, message: string) => async (dispatch: any) => {
  dispatch(setErrorMessage(message));
  dispatch(setLoadingStatus(status));
};

export const getProviderNotInCallPathStatsQuery =
  (params: Pagination, filterElements: StirShakenProviderNotInCallPathRequest) =>
  async (dispatch: any) => {
    dispatch(setStatus(true, ''));
    try {
      const response = await getStirShakensIssuesApiCall(params, filterElements);
      if (response && response.data) {
        dispatch(getStirShakensIssues(response.data));
      }
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
    dispatch(setLoadingStatus(false));
  };
