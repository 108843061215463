import * as React from 'react';
import { Fragment } from 'react';
import { Col, Row } from 'reactstrap';

const Footer = () => (
  <footer className="footer">
    <div className="container-fluid">
      <Row>
        <Col md="4" className="text-center mt-1" />
        <Col md="4" className="text-center mt-1">
          {process.env.NODE_ENV === 'development' && (
            <Fragment>
              <p className="text-ust-green m-0">Mode: {process.env.NODE_ENV}</p>
            </Fragment>
          )}
          <p className="text-muted m-0" id="links">
            <a
              className="footer-link"
              href="https://industrytracebackgroup.zendesk.com/hc/en-us/sections/1500000840322-FAQ"
              target="_blank"
            >
              Help
            </a>{' '}
            |{' '}
            <a className="footer-link" href="/about">
              About Us
            </a>{' '}
            |{' '}
            <a className="footer-link" href="/support">
              Support
            </a>{' '}
            |{' '}
            <a className="footer-link" href="/privacy">
              Privacy Policy
            </a>{' '}
            |{' '}
            <a className="footer-link" href="/termsofuse">
              Terms of Use
            </a>
          </p>
        </Col>
        <Col md="4" className="text-end mt-1 mb-2 pe-4">
          <p className="footer-info">©{new Date().getFullYear()} Industry Traceback Group, LLC.</p>
        </Col>
      </Row>
    </div>
  </footer>
);

export default Footer;
