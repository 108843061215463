import React from 'react';
import { connect } from 'react-redux';
import { ViewMode } from '../enum/ViewMode';
import { userTypes } from '../enum/userTypes';
import { RMDEntry } from '../interfaces/rmd';
import { PersonalInfo } from '../interfaces/user';
import { stateMappings } from '../redux/stateMappings';

interface IProps {
  user: PersonalInfo;
  rmdEntry: RMDEntry;
  viewMode?: ViewMode;
  deleteRMD?: Function;
}

const RmdInfoElement: React.FC<IProps> = ({ rmdEntry, viewMode, deleteRMD, user }) => {
  return (
    <div key={rmdEntry.number} className="flex-row">
      <div className="d-flex align-items-center">
        {rmdEntry.sysId ? (
          <a
            href={`https://fccprod.servicenowservices.com/rmd?id=rmd_form&table=x_g_fmc_rmd_robocall_mitigation_database&sys_id=${rmdEntry.sysId}&view=sp`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {rmdEntry.number}
          </a>
        ) : (
          <span>
            {rmdEntry.number}
            <span>,</span>{' '}
          </span>
        )}
        {user.roleType === userTypes.Admin &&
          (viewMode === ViewMode.EDIT ||
            viewMode === ViewMode.APPROVAL ||
            viewMode === undefined) && (
            <i
              className="fa fa-close ps-3"
              style={{ color: 'red', cursor: 'pointer' }}
              onClick={() => deleteRMD?.(rmdEntry)}
            />
          )}
      </div>
      <div className="d-flex flex-column me-2">
        {rmdEntry.declarationDate && <div>Declaration Date: {rmdEntry.declarationDate}</div>}
        {rmdEntry.updateDate && <div>Update Date: {rmdEntry.updateDate}</div>}
        {!rmdEntry.voiceServiceProviderChoice && rmdEntry.intermediateProvider && (
          <div>Intermediate Provider</div>
        )}
        {rmdEntry.implementation && <div>{rmdEntry.implementation.split('-')[0]}</div>}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    user: sm.user
  };
};

export default connect(mapStateToProps)(RmdInfoElement);
