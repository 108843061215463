import axios from 'axios';

import { Pagination } from '../../interfaces/pagination';
import { SpreadsheetTraceback, Traceback } from '../../interfaces/traceback';
import { FilterElement } from '../../lib/FilterElement';
import { logoutOnAuthError } from '../../lib/apiRequest';
import { downloadFile } from '../../lib/utilities';
import { addCommentObject } from '../comment/thunks';
import {
  addAttachments,
  addRequestor,
  addTraceback,
  clearTracebacks,
  editTraceback,
  getAttachments,
  getRequestors,
  getSpreadSheetTracebacksAction,
  getTraceback,
  getTracebackStatsObject,
  getTracebackStatuses,
  getTracebacks,
  setErrorMessage,
  setLoadingStatus
} from './actions';
import {
  addAttachmentsApiCall,
  addAudioApiCall,
  addRequestorApiCall,
  addTracebackApiCall,
  createTracebacksFromSpreadsheetApiCall,
  deleteTracebackApiCall,
  editTracebackApiCall,
  getAudioApiCall,
  getCSVApiCall,
  getTracebackApiCall,
  getTracebackFromSpreadSheetApiCall,
  getTracebackRequestorsApiCall,
  getTracebackStatsApiCall,
  getTracebackStatusApiCall,
  getTracebacksApiCall,
  getTracebacksByObjectTypeApiCall
} from './apiCalls';

export const getTracebackList =
  (params: Pagination, filterElements: FilterElement) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    try {
      const tracebacks = await getTracebacksApiCall(params, filterElements);
      if (tracebacks && tracebacks.data) {
        dispatch(getTracebacks(tracebacks.data));
      }
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      if (!axios.isCancel(error)) logoutOnAuthError(error as any);
    }
  };

export const getTracebackStats = () => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    const tracebackStats = await getTracebackStatsApiCall();
    if (tracebackStats && tracebackStats.data) {
      dispatch(getTracebackStatsObject(tracebackStats.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const clearTracebacksList = () => async (dispatch: any) => {
  dispatch(clearTracebacks());
};

export const getTracebackObject = (tracebackId: number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    const traceback = await getTracebackApiCall(tracebackId);

    if (traceback && traceback.data) {
      dispatch(getTraceback(traceback.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const getTracebackStatusList = () => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    const tracebackStatuses = await getTracebackStatusApiCall();
    if (tracebackStatuses && tracebackStatuses.data) {
      dispatch(getTracebackStatuses(tracebackStatuses.data.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const addTracebackObject =
  (
    traceback: Traceback,
    comment: string,
    attachments: any[],
    audioAttachments: any[],
    isReload: boolean = true
  ) =>
  async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    const postableCampaign = {
      campaignId: traceback.campaignId,
      campaignName: traceback.campaignName,
      callingTN: traceback.callingTN,
      calledTN: traceback.calledTN,
      tracebackTime: traceback.tracebackTime,
      create_date: traceback.create_date,
      createdBy: traceback.createdBy,
      audioName: traceback.audioName,
      audioUrl: traceback.audioUrl,
      audioUrlPath: traceback.audioUrl,
      providerId: 0,
      termCarrierOCN: traceback.termCarrierOCN,
      requestors: traceback.requestors,
      associateProvider: traceback.associateProvider
    };

    if (traceback.providerId != 0) {
      postableCampaign.providerId = traceback.providerId;
    }

    try {
      let response = await addTracebackApiCall(postableCampaign);

      if (response && response.data) {
        dispatch(addTraceback(response.data));

        let newTracebackId = response.data.data.tracebackId;

        if (audioAttachments && audioAttachments.length > 0) {
          let formData = new FormData();
          audioAttachments.forEach((attachment) => {
            formData.append('file', attachment);
          });

          addAudioApiCall(newTracebackId, formData)
            .then((result) => {
              handleTracebackComment(dispatch, newTracebackId, comment, attachments, isReload);
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          handleTracebackComment(dispatch, newTracebackId, comment, attachments, isReload);
        }
      }
    } catch (error: any) {
      dispatch(setStatus(false, error.response.data.error));
      logoutOnAuthError(error);
    }
  };

const handleTracebackComment = (
  dispatch: any,
  tracebackId: number,
  comment: string,
  attachments: any[],
  isReload: boolean
) => {
  if (comment || attachments.length > 0) {
    addCommentObject(
      {
        commentType: 'traceback',
        contentText: comment,
        relatedObjectId: tracebackId
      },
      attachments
    ).then(() => {
      successfulApiCall(dispatch, isReload);
    });
  } else {
    successfulApiCall(dispatch, isReload);
  }
};

const successfulApiCall = (dispatch: any, isReload: boolean) => {
  dispatch(setStatus(false, ''));

  if (isReload) {
    window.location.href = '/tracebacks';
  }
};

export const editTracebackObject =
  (traceback: Traceback, comment: string, attachments: any[], isReload: boolean = true) =>
  async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    try {
      const response = await editTracebackApiCall(traceback);

      if (response && response.data) {
        dispatch(editTraceback(response.data));

        if (comment || attachments.length > 0) {
          addCommentObject(
            {
              commentType: 'traceback',
              contentText: comment,
              relatedObjectId: traceback.tracebackId
            },
            attachments
          ).then(() => {
            dispatch(setStatus(false, ''));

            if (isReload) {
              window.location.reload();
            }
          });
        } else {
          if (isReload) {
            window.location.reload();
          }
        }
      }
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };

//TODO: Need to clean up references to addAttachmentList
export const addAttachmentList =
  (tracebackId: number, formData: FormData) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    try {
      const response = await addAttachmentsApiCall(tracebackId, formData);
      if (response && response.data) {
        dispatch(addAttachments(response.data));
      }
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };

export const deleteTraceback = (tracebackId: number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    await deleteTracebackApiCall(tracebackId);
    dispatch(setStatus(false, ''));
    window.location.href = '/Tracebacks';
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const addAudioFile = (tracebackId: number, formData: FormData) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    const response = await addAudioApiCall(tracebackId, formData);
    if (response && response.data) {
      dispatch(addAttachments(response.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const getAudioFile = (tracebackId: number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    const response = await getAudioApiCall(tracebackId);
    if (response && response.data) {
      dispatch(getAttachments(response.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

const setStatus = (status: boolean, message: string) => async (dispatch: any) => {
  dispatch(setErrorMessage(message));
  dispatch(setLoadingStatus(status));
};

export const getTracebackByObjectTypeList =
  (objectId: number, objectType: string, filterElements: FilterElement) =>
  async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    try {
      const tracebacks = await getTracebacksByObjectTypeApiCall(
        objectId,
        objectType,
        filterElements
      );
      if (tracebacks && tracebacks.data) {
        dispatch(getTracebacks(tracebacks.data));
      }
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };

export const getRequestorList = () => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    const response = await getTracebackRequestorsApiCall();
    if (response && response.data) {
      dispatch(getRequestors(response.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const addRequestorObject =
  (requestorName: string, sourceId: number) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    try {
      let response = await addRequestorApiCall(requestorName, sourceId);
      if (response && response.data) {
        dispatch(addRequestor(response.data));
      }

      response = await getTracebackRequestorsApiCall();
      if (response && response.data) {
        dispatch(getRequestors(response.data));
      }

      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };

export const getTracebacksCSV =
  (filterElements: FilterElement, params: Pagination) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    try {
      const response = await getCSVApiCall(filterElements, params);
      downloadFile(response.data, response.headers.contentfilename);
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };

export const getSpreadSheetTracebacks = (params: Pagination) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  try {
    const response = await getTracebackFromSpreadSheetApiCall(params);
    if (response && response.data) {
      dispatch(getSpreadSheetTracebacksAction(response.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const createTracebacksFromSpreadsheet =
  (payload: SpreadsheetTraceback[]) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));
    try {
      await createTracebacksFromSpreadsheetApiCall(payload);
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };
