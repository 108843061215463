import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import Breadcrumb from '../components/Breadcrumbs';
import CommentEditor from '../components/GovCommentEditor';
import { clearCommentsList } from '../redux/comment/thunks';

interface IProps {
  clearCommentsList: Function;
}

const GovCommentAdd: React.FC<IProps> = ({ clearCommentsList }) => {
  return (
    <Fragment>
      <Breadcrumb title="comments" destination={'/gov-comments'} detail="Add Comment" />
      <Card className="telecom-card">
        <CardHeader className="telecom-card-header">Add Comment</CardHeader>
        <CardBody>
          <div className="d-flex justify-content-center">
            <Col xs="12" sm="11" lg="10">
              <CommentEditor clearCommentsList={clearCommentsList} />
            </Col>
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

const mapActionsToProps = {
  clearCommentsList
};

export default connect(null, mapActionsToProps)(GovCommentAdd);
