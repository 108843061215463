export interface Notification {
  id: number;
  userId: number;
  notificationType: NotificationType;
  active: boolean;
  objectId: number;
  relatedUserHopId: number;
  signerCommonName: string;
  commentContent?: string;
  closeComment?: boolean;
}

export enum NotificationType {
  HopComment = 1,
  NewHop = 2,
  NewTraceback = 3,
  ProviderComment = 4,
  NewDispute = 5,
  NewStirShakenSigner = 6,
  TfHopComment = 7,
  WaitingForUpstreamHop = 8,
  UpstreamNoResponseHop = 9
}
