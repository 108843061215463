import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { DateFormat } from '../../enum/DateFormat';
import { Traceforward } from '../../interfaces/traceforward';
import { getClientFormattedDate } from '../../lib/utilities';

const TraceforwardsExpandedComponent = ({ data }: { data: Traceforward }) => (
  <Card className="expandedRow">
    <CardBody style={{ color: '#797979', padding: '0.5rem 1rem' }}>
      <Row>
        <Col md="auto me-3">
          <Row>
            <span className="special-title">Calling Number:</span>
          </Row>
          <Row>
            <span className="special-title">Called Number:</span>
          </Row>
          <Row>
            <span className="special-title">Traceforward Created:</span>
          </Row>
          <Row>
            <span className="special-title">Strike Exemption:</span>
          </Row>
        </Col>
        <Col md="auto me-3">
          <Row>
            <span>{data.callingTN ? data.callingTN : 'None'}</span>
          </Row>
          <Row>
            <span>{data.calledTN ? data.calledTN : 'None'}</span>
          </Row>
          <Row>
            <span>
              {data.create_date
                ? getClientFormattedDate(data.create_date, DateFormat.MediumBoth)
                : 'None'}
            </span>
          </Row>
          <Row>
            <span>{data.strikeExempt ? 'Yes' : 'No'}</span>
          </Row>
        </Col>
        <Col md="auto me-3">
          <Row>
            <span className="special-title">Call Date & Time:</span>
          </Row>
          <Row>
            <span className="special-title">Created By:</span>
          </Row>
        </Col>
        <Col md="auto me-3">
          <Row>
            <span>
              {data.traceforwardTime
                ? getClientFormattedDate(data.traceforwardTime, DateFormat.ShortBoth)
                : 'None'}
            </span>
          </Row>
          <Row>
            <span>{data.createdBy ? data.createdBy : 'None'}</span>
          </Row>
        </Col>
        <Col md="auto me-3">
          <Row>
            <span className="special-title">Creator's Provider:</span>
          </Row>
        </Col>
        <Col md="auto">
          <Row>
            <span>{data.creatorProvider ? data.creatorProvider : 'None'}</span>
          </Row>
        </Col>
      </Row>
    </CardBody>
  </Card>
);

export default TraceforwardsExpandedComponent;
