const knownObjects: {
  [key: string]: {
    [key: string]: boolean;
  };
} = {
  campaigns: {
    filterElements: true,
    paginationParams: true
  },
  commentsAdmin: {
    filterElements: true,
    paginationParams: true
  },
  commentsHops: {
    filterElements: true,
    paginationParams: true
  },
  commentsNotifications: {
    filterElements: true,
    paginationParams: true
  },
  commentsProviderCallRecords: {
    editableComment: true,
    filterElements: true,
    paginationParams: true
  },
  commentsGov: {
    editableComment: true,
    filterElements: true,
    paginationParams: true
  },
  commentsTracebacks: {
    filterElements: true,
    paginationParams: true
  },
  commentsProviders: {
    filterElements: true,
    paginationParams: true
  },

  hops: {
    filterElements: true,
    paginationParams: true
  },
  tfhops: {
    filterElements: true,
    paginationParams: true
  },
  providers: {
    filterElements: true,
    paginationParams: true,
    period: true
  },
  providerSummary: {
    period: true
  },
  tracebacks: {
    filterElements: true,
    paginationParams: true
  },
  traceforwards: {
    filterElements: true,
    paginationParams: true
  },
  upstreamProviderSummaryPanel: {
    filterElements: true,
    paginationParams: true,
    isNotDomestic: true
  },
  ipHistory: {
    filterElements: true,
    paginationParams: true
  },
  stirShaken: {
    filterElements: true,
    paginationParams: true
  },
  stirshakenIssues: {
    paginationParams: true
  },
  partners: {
    filterElements: true,
    paginationParams: true,
    period: true
  },
  usersHistory: {
    filterElements: true
  },
  incidentSummaries: {
    paginationParams: true,
    filterElements: true
  },
  insights: {
    filterElements: true,
    paginationParams: true
  },
  requests: {
    filterElements: true,
    paginationParams: true
  },
  results: {
    filterElements: true,
    paginationParams: true
  },
  emailEvents: {
    filterElements: true,
    paginationParams: true
  }
};

export const decodeQueryParams = (pageName: any, params: any) => {
  const result: { [key: string]: any } = {};
  const queryParams = new URLSearchParams(params);
  const pageFields = knownObjects[pageName];

  queryParams.forEach((val, key) => {
    let value = val;
    if (pageFields[key]) {
      try {
        value = JSON.parse(decodeURIComponent(atob(value)));
      } catch (error: any) {
        console.log('Error: ', error);
        localStorage.removeItem(pageName);
      }
    }
    result[key] = value;
  });
  return result;
};

export const encodeQueryParams = (
  pageName: any,
  filterableParams: any,
  componentParams: { [key: string]: any }
) => {
  let result = '?';
  let keys = Object.keys(componentParams);
  keys.forEach((key) => {
    let value = componentParams[key];
    if (value > 0) {
      result += `${key}=${value}&`;
    }
  });

  keys = Object.keys(filterableParams);
  const pageFields = knownObjects[pageName];

  keys.forEach((key) => {
    let value = filterableParams[key];

    if (pageFields[key]) {
      const stringified = JSON.stringify(value);
      value = encodeURIComponent(btoa(stringified)) || '';
    }
    result += `${key}=${value}&`;
  });

  return result.slice(0, -1);
};

export const getFromLocalStorage = (pageName: string) => {
  return localStorage.getItem(`${pageName}SearchParams`);
};

export const saveToLocalStorage = (pageName: string, search: string) => {
  localStorage.setItem(`${pageName}SearchParams`, search);
};

export const hasHistory = (params: any) => {
  return Object.keys(params).length > 0 && params.constructor === Object;
};
