import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { resendContactEmailApiCall } from '../../redux/provider/apiCalls';
import InputError from '../inputError';

export const useResendEmailModal = (): [(email: string, name: string) => void, React.ReactNode] => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  const handleAction = async () => {
    setLoading(true);
    try {
      await resendContactEmailApiCall(email);
      setOpen(false);
    } catch (error: any) {
      const err = error as AxiosError<{ error?: string }>;
      if (err) {
        setError(err.response?.data?.error || err.message);
      } else setError(error.message);
    }
    setLoading(false);
  };
  const showModal = (email: string, name: string) => {
    setName(name);
    setEmail(email);
    setOpen(true);
  };
  return [
    showModal,
    <Modal centered className="modal-template" isOpen={open}>
      <ModalHeader>Resend confirmation email</ModalHeader>
      <ModalBody>
        Are you sure that you want to resend the confirmation email to the following contact
        <br />
        <div style={{ paddingTop: '15px' }}>
          <b>Name:</b>
          {' ' + name}
          <br />
          <b>Email:</b>
          {' ' + email}
        </div>
        {error && (
          <InputError>
            {error === 'UserDoesNotExistError: sql: no rows in result set'
              ? "User doesn't exists or is incactive"
              : error}
          </InputError>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" disabled={loading} onClick={() => handleAction()}>
          {loading ? 'Loading...' : 'Send'}
        </Button>{' '}
        <Button color="danger" disabled={loading} onClick={() => setOpen(false)}>
          Cancel
        </Button>{' '}
      </ModalFooter>
    </Modal>
  ];
};
