export const DnoToolTip = [
  {
    type: '0',
    description:
      'DNO was set automatically by the system based on its status (Invalid, unallocated, and unassigned local and toll free numbers as well as disconnected and transitional toll free numbers)'
  },
  {
    type: '1',
    description: 'DNO has been manually set by a user in the RealNumber system.'
  },
  {
    type: '2',
    description: 'Number is on the US Telecom DNO list.'
  }
];
