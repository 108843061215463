import { ApiRequest } from '../../lib/apiRequest';
import { ConfigurationSettings } from '../../interfaces/configuration';

export const getConfigurationApiCall = async () =>
  ApiRequest.request({
    method: 'GET',
    url: `/configuration`
  });

export const updateConfigurationApiCall = async (configuration: ConfigurationSettings) =>
  ApiRequest.request({
    method: 'PUT',
    url: `/configuration`,
    data: { ...configuration }
  });
