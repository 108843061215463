import React, { FC, Fragment, useState } from 'react';
import { useLocation } from 'react-router';
import { Card, CardBody, Nav, NavItem, TabContent, TabPane } from 'reactstrap';
import Breadcrumb from '../components/Breadcrumbs';
import Requests from '../components/requests/Requests';
import Results from '../components/requests/Results';
import { userTypes } from '../enum/userTypes';
import { selectUser } from '../redux/stateMappings';
import { useAppSelector } from '../redux/store';

const NavRequest: FC = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.hash || '#request');
  const user = useAppSelector(selectUser);
  return (
    <Fragment>
      <Breadcrumb
        title="Requests"
        className="table-breadcrumbs"
        btnText={user.roleType !== userTypes.Admin && 'Request Traceback'}
      />
      <Card className="table-card">
        <CardBody className="p-0">
          <Nav>
            <NavItem className="w-50">
              <a
                className={`request-nav-item ${activeTab === '#request' ? 'active' : ''}`}
                href="requests#request"
                onClick={() => setActiveTab('#request')}
              >
                Requests
              </a>
            </NavItem>
            <NavItem className="w-50">
              <a
                className={`request-nav-item ${activeTab === '#result' ? 'active' : ''}`}
                href="requests#result"
                onClick={() => setActiveTab('#result')}
              >
                Results
              </a>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="#request">{activeTab === '#request' && <Requests />}</TabPane>
            <TabPane tabId="#result">{activeTab === '#result' && <Results />}</TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default NavRequest;
