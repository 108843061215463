import React, { Fragment, useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { renotifyProvider } from '../redux/provider/thunks';
import { stateMappings } from '../redux/stateMappings';
import { getTraceforwardObject } from '../redux/traceforward/thunks';

import { useNavigate, useParams } from 'react-router';
import { Card, CardBody, Row } from 'reactstrap';

import CallDestinationSection from '../components/TfHopDetail/CallDestinationSection';
import CallDetailsRow from '../components/TfHopDetail/CallDetailsRow';
import TfHopDetailsRow from '../components/TfHopDetail/TfHopDetailsRow';
import Legend from '../components/shared/Legend';
import ZendeskWebWidget from '../components/shared/ZendeskWebWidget';

import { EqaulTfHops, TfHop } from '../interfaces/tfhop';
import { Traceforward } from '../interfaces/traceforward';
import { PersonalInfo } from '../interfaces/user';

import TfHopCommentsSection from '../components/TfHopDetail/Comments/TfHopCommentsSection';
import TfHopBreadcrumb from '../components/TfHopDetail/TfHopBreadcrumb';
import TfHopCardHeader from '../components/TfHopDetail/TfHopCardHeader';
import TfHopNavigationRow from '../components/TfHopDetail/TfHopNavigationRow';
import TraceforwardDetailsShortRow from '../components/TfHopDetail/TraceforwardDetailsShortRow';
import { usePrevious } from '../lib/hooks';
import {
  deleteTfHop,
  getTfHopObject,
  resetTfHopObject,
  updateTfHopObject
} from '../redux/tfhop/thunks';

interface IProps {
  getTfHopObject: Function;
  getTraceforwardObject: Function;
  updateTfHopObject: Function;
  deleteTfHop: Function;
  resetTfHopObject: Function;
  renotifyProvider: Function;
  tfhop: TfHop;
  statuses: { [key: number]: string };
  traceforward: Traceforward;
  error: string;
  user: PersonalInfo;
}
interface urlParams {
  id: string;
}

const HopDetail: React.FC<IProps> = ({
  tfhop,
  traceforward,
  user,
  deleteTfHop,
  resetTfHopObject,
  renotifyProvider,
  updateTfHopObject,
  getTfHopObject,
  getTraceforwardObject,
  statuses,
  error
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isCallSourcePopulated, setIsCallSourcePopulated] = useState(false);

  useEffect(() => {
    getTfHopObject(id);
  }, []);

  useEffect(() => {
    if (error === '404') {
      navigate('/tfhops');
    } else if (error && error !== '404') {
      navigate('/404');
    }
  }, [error]);

  const prevHop = usePrevious(tfhop);

  useEffect(() => {
    if (prevHop && tfhop.tfhopId === prevHop.tfhopId && !EqaulTfHops(prevHop, tfhop))
      navigate('/tfhops');
  }, [tfhop]);

  useEffect(() => {
    if (tfhop.traceforwardId) getTraceforwardObject(tfhop.traceforwardId);
  }, [tfhop.traceforwardId, tfhop.upstreamTfHopId]);

  useEffect(() => {
    if (tfhop && tfhop.forwardedCall && tfhop.forwardedCall.trim() !== '') {
      traceforward.calledTN = tfhop.forwardedCall;
    }
  }, [tfhop.forwardedCall]);

  return typeof tfhop === undefined || Object.keys(tfhop).length < 1 ? null : (
    <Fragment>
      <TfHopBreadcrumb
        tfhop={tfhop}
        traceforward={traceforward}
        user={user}
        resetTfHopObject={resetTfHopObject}
        deleteTfHop={deleteTfHop}
      />
      <Card className="telecom-card">
        <TfHopCardHeader tfhop={tfhop} user={user} renotifyProvider={renotifyProvider} />
        <CardBody className="hop-detail-body">
          <TfHopNavigationRow tfhop={tfhop} traceforward={traceforward} user={user} />
          <TfHopDetailsRow
            tfhop={tfhop}
            traceforward={traceforward}
            user={user}
            statuses={statuses}
            updateTfHopObject={updateTfHopObject}
          />
          <TraceforwardDetailsShortRow tfhop={tfhop} traceforward={traceforward} user={user} />
          <CallDetailsRow tfhop={tfhop} traceforward={traceforward} />

          {(tfhop.status === 1 || tfhop.customer || tfhop.nfNr) && (
            <CallDestinationSection
              tfhop={tfhop}
              traceforward={traceforward}
              user={user}
              updateTfHopObject={updateTfHopObject}
            />
          )}

          <TfHopCommentsSection
            tfhop={tfhop}
            isCallDestinationPopulated={isCallSourcePopulated}
            user={user}
          />
        </CardBody>
      </Card>
      <div className="d-flex justify-content-center pt-4 pb-4">
        <Legend />
      </div>

      <ZendeskWebWidget />
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return {
    tfhop: sm.tfhop.tfhop,
    statuses: sm.tfhop.statuses,
    error: sm.tfhop.error,
    traceforward: sm.traceforward.traceforward,
    user: sm.user
  };
};

const mapActionsToProps = {
  getTfHopObject,
  getTraceforwardObject,
  updateTfHopObject,
  deleteTfHop,
  resetTfHopObject,
  renotifyProvider
};

export default connect(mapStateToProps, mapActionsToProps)(HopDetail);
