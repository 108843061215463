import React from 'react';
import { Col } from 'reactstrap';
import { Comment } from '../../../interfaces/comment';
import { TfHop } from '../../../interfaces/tfhop';
import { DirectedValues } from '../../comments/AddHopComment';
import BasicHopComment from '../../comments/BasicHopComment';
import AddTfHopComment from './AddTfHopComment';

interface IProps {
  tfhop: TfHop;
  hash?: string;
  isCallDestinationPopulated: boolean;
  comment: Comment;
  replies: Comment[];
  directedTo: DirectedValues;
  uniqueId?: string;
}

const TfHopComment: React.FC<IProps> = ({
  tfhop,
  hash,
  isCallDestinationPopulated,
  comment,
  replies,
  directedTo,
  uniqueId
}) => {
  return (
    <div className="d-flex justify-content-center mb-5" key={comment.commentId}>
      <Col className="col-10 hop-detail-form pb-3">
        <BasicHopComment
          id={`#${comment.commentId}`}
          hash={hash}
          comment={comment}
          className="mb-1 pb-4"
        />
        <div className="ps-3 pb-3">
          {replies.map((reply) => (
            <BasicHopComment
              id={`#${reply.commentId}`}
              hash={hash}
              comment={reply}
              className="mb-1 pb-1"
              key={'basicHopReply-' + reply.commentId}
            />
          ))}
        </div>
        {directedTo.value !== 'oldDownstream' && (
          <AddTfHopComment
            tfhopID={tfhop.tfhopId}
            uniqueId={uniqueId}
            isCallDestinationPopulated={isCallDestinationPopulated}
            replyTo={{
              commentID: comment.commentId,
              tfhopID: comment.relatedTfHopId,
              providerName: directedTo.label,
              directedTo: comment.directedTo
            }}
            tfhopProviderName={tfhop.tfhopProvider.name}
            upstreamProviderName={tfhop.upstreamProvider ? tfhop.upstreamProvider.name : ''}
            downstreamProviderName={tfhop.downstreamProvider ? tfhop.downstreamProvider.name : ''}
          />
        )}
      </Col>
    </div>
  );
};

export default TfHopComment;
