import React, { FC, useState } from 'react';
import { OnChangeValue } from 'react-select';
import { useDidUpdateEffect } from '../../lib/hooks';
import { getLabelsApiCall, getLabelsForProviderApiCall } from '../../redux/campaign/apiCalls';
import CustomPicky from '../CustomPicky';
import CustomSelect, { ApiLookup, SelectOption } from '../CustomSelect';

interface IProps {
  value: number | number[] | SelectOption | SelectOption[] | undefined | null;
  onChange: (value: OnChangeValue<SelectOption, boolean>) => void;
  addAllItem?: boolean;
  isMulti?: boolean;
  selectClass?: string;
  isClearable?: boolean;
  inputId?: string;
  placeholder?: string;
  isSearchable?: boolean;
  providerId?: number;
  startDate?: string;
  endDate?: string;
  numberDisplayed?: number;
  maxMenuHeight?: number;
  typeLabel?: string;
}

const getApi = (providerId?: number, startDate?: string, endDate?: string): ApiLookup => ({
  apiCall: providerId
    ? () => getLabelsForProviderApiCall(providerId, startDate, endDate)
    : getLabelsApiCall,
  nameLookupFunction: (item: any) => item.name,
  valueLookupFunction: (item: any) => item.labelID
});

const LabelSelect: FC<IProps> = ({
  value,
  onChange,
  addAllItem,
  selectClass,
  isClearable,
  inputId,
  placeholder,
  isSearchable,
  providerId,
  startDate,
  endDate,
  isMulti,
  numberDisplayed,
  maxMenuHeight,
  typeLabel
}) => {
  const [api, setApi] = useState(getApi(providerId, startDate, endDate));
  useDidUpdateEffect(() => {
    setApi(getApi(providerId, startDate, endDate));
  }, [providerId, startDate, endDate]);
  return isMulti ? (
    <CustomPicky
      selectedOption={value}
      setSelectedOption={onChange}
      getOptions={api}
      className={selectClass ? selectClass : 'customselect-small'}
      isSearchable={isSearchable}
      isClearable={isClearable}
      addAllItem={addAllItem}
      typeLabel={typeLabel ? typeLabel : 'All Labels'}
      inputId={inputId}
      placeholder={placeholder}
      numberDisplayed={numberDisplayed ? numberDisplayed : 3}
      maxMenuHeight={maxMenuHeight}
    />
  ) : (
    <CustomSelect
      selectedOption={value}
      setSelectedOption={onChange}
      getOptions={api}
      className={selectClass ? selectClass : 'customselect-small'}
      isSearchable={isSearchable}
      isClearable={isClearable}
      addAllItem={addAllItem}
      typeLabel={typeLabel ? typeLabel : 'All Labels'}
      inputId={inputId}
      placeholder={placeholder}
    />
  );
};

export default LabelSelect;
