import React, { Fragment } from 'react';
import { Col, Label, Row } from 'reactstrap';
import { userTypes } from '../../../enum/userTypes';
import { PersonalInfo } from '../../../interfaces/user';
import { ValidationRules, minLengthRule, regexRule, requiredRule } from '../../../lib/hooks';
import { validEmailRegex } from '../../../lib/regex';
import { SelectOption } from '../../CustomSelect';
import ProvidersSelect, { ProviderItem } from '../../ProvidersSelect';
import InputError from '../../inputError';
import InputFormGroup from '../../inputFormGroup';

interface IProps {
  user: PersonalInfo;
  currentHopProviderId: number;
  selectedProvider: number;
  providerData?: ProviderItem;
  setSelectedProvider: (providerID: number) => void;
  extraContactEmail: string;
  zerobounceExtraContactEmailError: string;
  extraCommentRmd: string;
  errors: { [key: string]: string };
  handleCallDestinationInputChange: React.FormEventHandler<HTMLInputElement>;
  newProvider: () => void;
}

export const downstreamValidator: ValidationRules = {
  selectedProvider: [
    {
      rule: requiredRule,
      message: 'The field is required.'
    }
  ],
  extraContactEmail: [
    {
      rule: requiredRule,
      message: `Please submit an alternative email for the selected voice service provider.`
    },
    {
      rule: regexRule(validEmailRegex),
      message: 'Please provide a valid email address'
    }
  ],
  extraCommentRmd: [
    {
      rule: requiredRule,
      message: `Please note that the selected provider is not registered in the FCC's Robocall Mitigation Database. If you feel this is an error please add the RMD number or name of entity here.`
    },
    {
      rule: minLengthRule(5),
      message: 'Comment must have at least 5 characters'
    }
  ]
};

const DownstreamProviderStep: React.FC<IProps> = ({
  user,
  currentHopProviderId,
  selectedProvider,
  setSelectedProvider,
  providerData,
  extraContactEmail,
  zerobounceExtraContactEmailError,
  extraCommentRmd,
  errors,
  handleCallDestinationInputChange,
  newProvider
}) => (
  <Fragment>
    <Row className="m-0 mb-3 mt-3">
      <Col lg="3">Select Downstream Voice Service Provider </Col>
      <Col lg="3">
        <ProvidersSelect
          onChange={(e) => {
            if (e) {
              const { value } = e as SelectOption;
              setSelectedProvider(value || 0);
            } else setSelectedProvider(0);
          }}
          isSearchable
          isClearable
          className={'customselect-large'}
          option={selectedProvider}
          removeId={currentHopProviderId}
          placeholder="All Providers"
        />
        {errors.selectedProvider && (
          <InputError className="telecom-input-error">{errors.selectedProvider}</InputError>
        )}
      </Col>
      {user.roleType !== userTypes.Admin && (
        <Col lg="6">
          <p className="mb-0">Is your downstream provider not listed in the dropdown?</p>
          <span
            className="span-cell blue"
            id="Popover1"
            style={{ cursor: 'pointer' }}
            onClick={newProvider}
          >
            + Add New Provider
          </span>
        </Col>
      )}
    </Row>
    {providerData?.badEmails && (
      <Fragment>
        <Row className="m-0 mb-3">
          <Col lg="2" className="ps-0">
            <Label className="telecom-label">Alternative Contact Email</Label>
          </Col>
          <Col lg="4">
            <InputFormGroup
              isReadonly={false}
              inputName="extraContactEmail"
              inputId="extraContactEmail"
              inputClassName="input-provider"
              inputValue={extraContactEmail}
              inputOnChange={handleCallDestinationInputChange}
              inputPlaceholder=""
              inputAutoComplete="off"
              errorMessage={errors.extraContactEmail || zerobounceExtraContactEmailError}
            />
          </Col>
        </Row>
      </Fragment>
    )}
    {providerData?.noRmd && user.providerCountry === 'United States' && (
      <Fragment>
        <Row className="m-0 mb-5">
          <Col lg="2" className="ps-0">
            <Label className="telecom-label">Comment for No RMDs Provider</Label>
          </Col>
          <Col lg="5">
            <InputFormGroup
              isReadonly={false}
              inputName="extraCommentRmd"
              inputId="extraCommentRmd"
              inputClassName="input-provider"
              inputValue={extraCommentRmd}
              inputOnChange={handleCallDestinationInputChange}
              inputPlaceholder=""
              inputAutoComplete="off"
              errorMessage={errors.extraCommentRmd}
            />
          </Col>
        </Row>
      </Fragment>
    )}
  </Fragment>
);

export default DownstreamProviderStep;
