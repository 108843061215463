import { createSlice } from '@reduxjs/toolkit';
import { Meta, defaultMeta } from '../../interfaces/meta';
import {
  PartnerStatsWithTracebackInfo,
  RequestIncident,
  Requestor
} from '../../interfaces/request';
import { logoutOnAuthError } from '../../lib/apiRequest';
import {
  createRequest,
  createTracebackFromRequests,
  downloadPartnerReport,
  getIncidentRequestsCount,
  getRequestors,
  rejectRequests,
  searchRequests,
  searchRequestsResults
} from './apiCalls';

interface State {
  incidents: RequestIncident[];
  meta: Meta;
  loading: number;
  error: any;
  requestors: Array<Requestor>;
  results: Array<PartnerStatsWithTracebackInfo>;
  resultsMeta: Meta;
  pendingCount: number;
}

const initialState: State = {
  incidents: [],
  meta: defaultMeta,
  loading: 0,
  error: '',
  requestors: [],
  results: [],
  resultsMeta: defaultMeta,
  pendingCount: 0
};

export const requestsSlice = createSlice({
  name: 'requests',
  initialState,
  reducers: {
    // any additional "normal" case reducers here.
    // these will generate new action creators
  },
  extraReducers: (builder) => {
    // Use `extraReducers` to handle actions that were generated
    // _outside_ of the slice, such as thunks or in other slices
    builder
      .addCase(searchRequests.pending, (state, action) => {
        state.loading += 1;
      })
      // Pass the generated action creators to `.addCase()`
      .addCase(searchRequests.fulfilled, (state, action) => {
        // Same "mutating" update syntax thanks to Immer
        state.loading = state.loading === 0 ? state.loading : state.loading - 1;
        state.incidents = action.payload.data;
        state.meta = action.payload.paginationMeta;
      })
      .addCase(searchRequests.rejected, (state, action) => {
        state.loading = state.loading === 0 ? state.loading : state.loading - 1;
        state.incidents = [];
        state.meta = defaultMeta;
        state.error = action.error;
        logoutOnAuthError(action.error as any);
      })
      .addCase(getRequestors.pending, (state, action) => {
        state.loading += 1;
      })
      // Pass the generated action creators to `.addCase()`
      .addCase(getRequestors.fulfilled, (state, action) => {
        // Same "mutating" update syntax thanks to Immer
        state.loading = state.loading === 0 ? state.loading : state.loading - 1;
        state.requestors = action.payload;
      })
      .addCase(getRequestors.rejected, (state, action) => {
        state.loading = state.loading === 0 ? state.loading : state.loading - 1;
        state.requestors = [];
        state.error = action.error;
        logoutOnAuthError(action.error as any);
      }) // get results
      .addCase(searchRequestsResults.pending, (state, action) => {
        state.loading += 1;
      })
      // Pass the generated action creators to `.addCase()`
      .addCase(searchRequestsResults.fulfilled, (state, action) => {
        // Same "mutating" update syntax thanks to Immer
        state.loading = state.loading === 0 ? state.loading : state.loading - 1;
        state.results = action.payload.data;
        state.resultsMeta = action.payload.paginationMeta;
      })
      .addCase(searchRequestsResults.rejected, (state, action) => {
        state.loading = state.loading === 0 ? state.loading : state.loading - 1;
        state.results = [];
        state.resultsMeta = defaultMeta;
        state.error = action.error;
        logoutOnAuthError(action.error as any);
      })
      .addCase(createTracebackFromRequests.pending, (state, action) => {
        state.loading += 1;
      })
      .addCase(createTracebackFromRequests.fulfilled, (state, action) => {
        state.loading = state.loading === 0 ? state.loading : state.loading - 1;
      })
      .addCase(createTracebackFromRequests.rejected, (state, action) => {
        state.loading = state.loading === 0 ? state.loading : state.loading - 1;
        state.error = action.error;
        logoutOnAuthError(action.error as any);
      })
      .addCase(rejectRequests.pending, (state, action) => {
        state.loading += 1;
      })
      .addCase(rejectRequests.fulfilled, (state, action) => {
        state.loading = state.loading === 0 ? state.loading : state.loading - 1;
      })
      .addCase(rejectRequests.rejected, (state, action) => {
        state.loading = state.loading === 0 ? state.loading : state.loading - 1;
        state.error = action.error;
        logoutOnAuthError(action.error as any);
      })
      .addCase(createRequest.pending, (state, action) => {
        state.loading += 1;
      })
      .addCase(createRequest.fulfilled, (state, action) => {
        state.loading = state.loading === 0 ? state.loading : state.loading - 1;
      })
      .addCase(createRequest.rejected, (state, action) => {
        state.loading = state.loading === 0 ? state.loading : state.loading - 1;
        state.error = action.error;
        logoutOnAuthError(action.error as any);
      })
      .addCase(downloadPartnerReport.pending, (state, action) => {
        state.loading += 1;
      })
      .addCase(downloadPartnerReport.fulfilled, (state, action) => {
        state.loading = state.loading === 0 ? state.loading : state.loading - 1;
      })
      .addCase(downloadPartnerReport.rejected, (state, action) => {
        state.loading = state.loading === 0 ? state.loading : state.loading - 1;
        state.error = action.error;
        logoutOnAuthError(action.error as any);
      })
      .addCase(getIncidentRequestsCount.pending, (state, action) => {
        state.loading += 1;
      })
      .addCase(getIncidentRequestsCount.fulfilled, (state, action) => {
        state.loading = state.loading === 0 ? state.loading : state.loading - 1;
        state.pendingCount = action.payload;
      })
      .addCase(getIncidentRequestsCount.rejected, (state, action) => {
        state.loading = state.loading === 0 ? state.loading : state.loading - 1;
        state.error = action.error;
        logoutOnAuthError(action.error as any);
      });
  }
});
export default requestsSlice.reducer;
