import React from 'react';

import { EmailValidation } from '../interfaces/user';

interface IProps {
  emailValidation: EmailValidation | undefined;
  classCustom?: string;
}

const EmailValidationStatus: React.FC<IProps> = ({ emailValidation, classCustom }) => {
  return (
    <div
      className={
        classCustom
          ? classCustom
          : `${emailValidation?.aws_reason ? 'pt-3 pb-3' : ''} d-flex flex-column`
      }
    >
      {emailValidation && emailValidation.aws_reason && <span>SES Blocked</span>}

      <span>
        {emailValidation && emailValidation.status === 'invalid'
          ? 'Invalid Mailbox'
          : 'Valid Mailbox'}
      </span>
    </div>
  );
};

export default EmailValidationStatus;
