import React, { FC, Fragment, useEffect, useMemo } from 'react';
import { ChartFilterElementsForProviderSummary } from '../../interfaces/charts';
import { connect } from 'react-redux';
import { stateMappings } from '../../redux/stateMappings';
import { getProviderStirShakenStatsObject } from '../../redux/provider/thunks';
import { ProviderStirShakenStats } from '../../interfaces/provider';
import { CardTitle } from 'reactstrap';

interface IProps {
  providerStirShakenStats: ProviderStirShakenStats;
  getProviderStirShakenStatsObject: Function;
  providerId: number;
  chartFilters: ChartFilterElementsForProviderSummary;
}

const ProviderStirShakenStatsChart: FC<IProps> = ({
  providerId,
  chartFilters,
  providerStirShakenStats,
  getProviderStirShakenStatsObject
}) => {
  const stirShakenSignerPercentage = useMemo(
    () =>
      providerStirShakenStats.totalTracebacks
        ? (
            (providerStirShakenStats.stirShakenSigner / providerStirShakenStats.totalTracebacks) *
            100
          ).toFixed(1) + '%'
        : '0.0%',
    [providerStirShakenStats.totalTracebacks, providerStirShakenStats.stirShakenSigner]
  );
  const intermediateStirShakenSignerPercentage = useMemo(
    () =>
      providerStirShakenStats.totalTracebacks
        ? (
            (providerStirShakenStats.intermediateStirshakenSigner /
              providerStirShakenStats.totalTracebacks) *
            100
          ).toFixed(1) + '%'
        : '0.0%',
    [providerStirShakenStats.totalTracebacks, providerStirShakenStats.intermediateStirshakenSigner]
  );
  useEffect(() => {
    getProviderStirShakenStatsObject(chartFilters, providerId);
  }, [
    providerId,
    chartFilters.labelId,
    chartFilters.note,
    chartFilters.termState,
    chartFilters.startDate,
    chartFilters.endDate
  ]);
  return (
    <Fragment>
      {providerStirShakenStats.totalTracebacks != 0 && (
        <div className="row p-5">
          <div className="col-4">
            <CardTitle tag="h5">STIR/SHAKEN Tracebacks</CardTitle>
            <CardTitle tag="h5">Identified as Origin & Call Signer</CardTitle>
            <CardTitle className="text-secondary" tag="h3">
              {stirShakenSignerPercentage}
            </CardTitle>
          </div>
          <div className="col-4">
            <CardTitle tag="h5">STIR/SHAKEN Tracebacks</CardTitle>
            <CardTitle tag="h5">Identified as Intermediate Signer</CardTitle>
            <CardTitle className="text-secondary" tag="h3">
              {intermediateStirShakenSignerPercentage}
            </CardTitle>
          </div>
          <div className="col-4">
            <CardTitle tag="h5">STIR/SHAKEN Errors</CardTitle>
            <div>{`Crypto EDCA Signing Error (${providerStirShakenStats.errorSigningCount})`}</div>
            <div>{`Issuer not approved (${providerStirShakenStats.issuerErrorCount})`}</div>
            <div>{`Identified as signer but not in the call path (${providerStirShakenStats.notInCallPathCount})`}</div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    providerStirShakenStats: sm.provider.providerStirShakenStats
  };
};
const mapActionsToProps = {
  getProviderStirShakenStatsObject
};

export default connect(mapStateToProps, mapActionsToProps)(ProviderStirShakenStatsChart);
