import { StirShakenReponse } from '../interfaces/stirShaken';
import { CampaignAttributes } from './campaign';
import { Comment } from './comment';
import { Provider } from './provider';
import { RMDEntry } from './rmd';

export enum NoteType {
  OpenHop = 'OPEN',
  OriginHop = 'ORG',
  DownstreamOrigin = 'DownORG',
  InternationOriginHop = 'IOR',
  NoResponseHop = 'NR',
  DownNoResponseHop = 'DownNR',
  NotFoundHop = 'NF',
  DownNotFoundHop = 'DownNF',
  USPointOfEntryHop = 'POE',
  ForeignPointDepartureHop = 'FPD',
  StrikeExemptProvider = 'SE',
  DeletedHop = 'DEL',
  TermHop = 'TERM',
  RejectDispute = 'XD',
  InternationalTraceback = 'INT'
}

export interface Hop {
  active: boolean;
  added: any;
  address: string;
  campaignId: number;
  campaignName: string;
  city: string;
  comments: Comment[];
  relatedComments: Comment[];
  completed: any;
  contactEmail: string;
  contactEmailValidation: any;
  contactName: string;
  contactPhone: string;
  country: string;
  create_date: any;
  customer: string;
  customerDetail: string;
  delegatedTo: string;
  description: string;
  downstreamHopId: number;
  downstreamProvider: Provider;
  elapsed: number;
  calcElapsed: number;
  explanation: any;
  hopId: number;
  hopProvider: Provider;
  hopSequence: number;
  isCallOrig: boolean;
  isDomestic: boolean;
  isProviderITGMember: boolean;
  isUSPointEntry: boolean;
  locked: boolean;
  newProvider: {
    newProviderAddress: string;
    newProviderCity: string;
    newProviderContactEmail: string;
    newProviderExtraContactEmail: string;
    newProviderContactName: string;
    newProviderContactPhone: string;
    newProviderCountry: string;
    newProviderStateRegion: string;
    newProviderZip: string;
    newProviderName: string;
    newProviderReason: string;
    newProviderTimeZone: string;
  };
  nfNr: any;
  nfnrReason: any;
  noFoundExplanation: any;
  noInfoExplanation: any;
  notes: NoteType[];
  upstreamNotes: NoteType[];
  notified: any;
  providerId: number;
  state: string;
  status: number;
  tag: any;
  tracebackId: number;
  update_date: any;
  updateFlag: any;
  upstreamHopId: number;
  upstreamProvider?: Provider;
  zipCode: string;
  forwardedCall: string;
  ipAddress: string;
  customerType: string;
  averageTraffic: string;
  actionTaken: string;
  refuseReason: string;
  attachmentUrl: string;
  stirShaken: StirShakenReponse;
  tracebackTermState?: string;
  labelId?: number;
  rmdFlag: string;
  downstreamRMDFlag: string;
  upstreamRMDFlag: string;
  elapsedUs: boolean;
  govIssuerNotValid?: boolean;
  stirShakenFlag?: string;
  isCampaignLive: boolean;
  isAllowedToDispute: boolean;
  resetDate: string;
  isAllowedToRespondNR: boolean;
  representativeAudio?: boolean;
}

export const EqaulHops = (h1: Hop, h2: Hop) => {
  if (JSON.stringify(h1) === JSON.stringify(h2)) {
    return true;
  }
  h1.elapsed = h2.elapsed;
  h1.calcElapsed = h2.calcElapsed;
  h1.comments = h2.comments;
  h1.relatedComments = h2.relatedComments;
  h1.stirShaken = h2.stirShaken;
  return JSON.stringify(h1) === JSON.stringify(h2);
};

export interface HopDispute {
  hopId: number;
  commentId: number;
  status: DisputeStatus;
  attributes: CampaignAttributes;
  consentGiven: boolean;
}

export enum DisputeStatus {
  NoDispute = 0,
  OpenDispute = 1,
  AcceptedDispute = 2,
  RejectedDispute = 3
}

export interface NewHopProvider {
  newProviderAddress: string;
  newProviderCity: string;
  newProviderContactEmail: string;
  newProviderExtraContactEmail: string;
  newProviderContactName: string;
  newProviderContactPhone: string;
  newProviderCountry: string;
  newProviderStateRegion: string;
  newProviderZip: string;
  newProviderName: string;
  newProviderReason: string;
  newRmd?: RMDEntry;
  acknowledge: boolean; // intern only field
}

export const emptyNewHopProvider = (): NewHopProvider => ({
  newProviderAddress: '',
  newProviderCity: '',
  newProviderContactEmail: '',
  newProviderExtraContactEmail: '',
  newProviderContactName: '',
  newProviderContactPhone: '',
  newProviderCountry: '',
  newProviderStateRegion: '',
  newProviderZip: '',
  newProviderName: '',
  newProviderReason: '',
  acknowledge: false // intern only field
});
