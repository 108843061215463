import React, { FC, Fragment, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';
import { Button, Popover, PopoverBody } from 'reactstrap';
import { Notification, NotificationType } from '../interfaces/notification';
import { Pagination, defaultPaginationNotification } from '../interfaces/pagination';
import { deleteNotificationApiCall } from '../redux/notification/apiCalls';
import { deleteUserNotifications, getAllNotifications } from '../redux/notification/thunks';
import { stateMappings } from '../redux/stateMappings';
import IconCounterDisplay from './IconCounterDisplay';

interface IProps {
  notificationsCount: number;
  notifications: Notification[];
  getAllNotifications: Function;
  deleteUserNotifications: Function;
}

const notificationComponets = [
  {
    type: NotificationType.HopComment,
    component: (notification: Notification) => (
      <li key={notification.id} className="d-flex flex-wrap">
        <p className="m-0 telecom-label pe-2 notificationText">{`${notification.closeComment ? 'Comment on closed TB' : 'New Hop Comment'}`}</p>
        <a
          target="_blank"
          href={`/hops/hop/${notification.relatedUserHopId}/#${notification.objectId}`}
        >
          {`Hop ${notification.relatedUserHopId}`}
        </a>
        {notification.commentContent && notification.commentContent.length > 25 ? (
          <p>{` - ${notification.commentContent?.substring(0, 25)}...`}</p>
        ) : (
          <p>{` - ${notification.commentContent}`}</p>
        )}
      </li>
    )
  },
  {
    type: NotificationType.TfHopComment,
    component: (notification: Notification) => (
      <li key={notification.id} className="d-flex flex-wrap">
        <p className="m-0 telecom-label pe-2 notificationText">New TfHop Comment</p>
        <a
          target="_blank"
          href={`/tfhops/tfhop/${notification.relatedUserHopId}/#${notification.objectId}`}
        >
          {`TfHop ${notification.relatedUserHopId}`}
        </a>
        {notification.commentContent && notification.commentContent.length > 10 ? (
          <p>{` - ${notification.commentContent?.substring(0, 10)}...`}</p>
        ) : (
          <p>{` - ${notification.commentContent}`}</p>
        )}
      </li>
    )
  },
  {
    type: NotificationType.NewDispute,
    component: (notification: Notification) => (
      <li key={notification.id} className="d-flex align-items-center pb-2">
        <p className="m-0 telecom-label pe-2 notificationText">Disputed Traceback</p>
        <a target="_blank" href={`/hops/hop/${notification.relatedUserHopId}/#dispute`}>
          {`Hop ${notification.relatedUserHopId}`}
        </a>
      </li>
    )
  },
  {
    type: NotificationType.NewStirShakenSigner,
    component: (notification: Notification) => (
      <li key={notification.id} className="d-flex align-items-center pb-2">
        <p className="m-0 telecom-label pe-2 notificationText">Stir Shaken Signer missing</p>
        <a
          target="_blank"
          href="/admin-dashboard#stir_shaken_signers"
          onClick={() => {
            localStorage.setItem('signerCommonName', notification.signerCommonName);
          }}
        >
          {`Hop ${notification.relatedUserHopId}`}
        </a>
      </li>
    )
  },
  {
    type: NotificationType.WaitingForUpstreamHop,
    component: (notification: Notification) => (
      <li key={notification.id} className="d-flex flex-wrap">
        <p className="m-0 telecom-label pe-2 notificationText">Waiting for Upstream</p>
        <a
          target="_blank"
          href={`/hops/hop/${notification.relatedUserHopId}/`}
          onClick={() => {
            try {
              notification.id && deleteNotificationApiCall(notification.id);
            } catch (err) {
              console.log(err);
            }
          }}
        >
          {`Hop ${notification.relatedUserHopId}`}
        </a>
        Encourage upstream to respond
      </li>
    )
  },
  {
    type: NotificationType.UpstreamNoResponseHop,
    component: (notification: Notification) => (
      <li key={notification.id} className="d-flex flex-wrap">
        <p className="m-0 telecom-label pe-2 notificationText">Upstream: No Response</p>
        <a
          target="_blank"
          href={`/hops/hop/${notification.relatedUserHopId}/`}
          onClick={() => {
            try {
              notification.id && deleteNotificationApiCall(notification.id);
            } catch (err) {
              console.log(err);
            }
          }}
        >
          {`Hop ${notification.relatedUserHopId}`}
        </a>
        Report Action Taken
      </li>
    )
  }
];

const newNotificationsList = (notifications: Notification[]) => {
  return (
    <Fragment>
      {notifications.length > 0 ? (
        <ul className="ps-0">
          {notifications.map((notification) => {
            const cmp = notificationComponets.find((v) => notification.notificationType === v.type);
            return cmp ? cmp.component(notification) : null;
          })}
        </ul>
      ) : (
        <p className="m-0">There is no data available</p>
      )}
    </Fragment>
  );
};

const NotificationPopover: FC<IProps> = ({
  notifications,
  notificationsCount,
  getAllNotifications,
  deleteUserNotifications
}) => {
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [paginationParams, setPaginationPaginationParams] = useState<Pagination>(
    defaultPaginationNotification()
  );
  const [confirmationMessage, setConfirmationMessage] = useState(false);

  useEffect(() => {
    getAllNotifications(paginationParams);
  }, [isNotificationOpen]);

  const fetchMoreNotifications = () => {
    if (paginationParams.pageSize < notificationsCount) {
      const updatePaginationParams = paginationParams;
      updatePaginationParams.pageSize += 10;
      setPaginationPaginationParams(updatePaginationParams);
      getAllNotifications(paginationParams);
    }
  };

  const deleteNotifications = async () => {
    await deleteUserNotifications();
    await getAllNotifications(paginationParams);
  };

  const setNotification = () => {
    setIsNotificationOpen(!isNotificationOpen);
    if (!isNotificationOpen) setConfirmationMessage(false);
  };
  return (
    <Fragment>
      <div
        id="notificationAllert"
        className="position-relative pe-2"
        onClick={setNotification}
        role="button"
      >
        <i className="fa-solid fa-bell notificationBell "></i>
        {notificationsCount !== 0 && !isNotificationOpen && (
          <div className="position-absolute" style={{ top: '15px', left: '17px' }}>
            <IconCounterDisplay count={notificationsCount} />
          </div>
        )}
      </div>
      <Popover placement="bottom" isOpen={isNotificationOpen} target="notificationAllert">
        <PopoverBody>
          {confirmationMessage ? (
            <div>
              <h6>Mark all notifications as read?</h6>

              <Button
                color="primary"
                size="sm"
                style={{ minWidth: '80px' }}
                onClick={() => setConfirmationMessage(false)}
              >
                Cancel
              </Button>
              <Button
                className="ms-5"
                color="danger"
                size="sm"
                style={{ minWidth: '70px' }}
                onClick={() => {
                  notifications.length && deleteNotifications();
                  setConfirmationMessage(false);
                }}
              >
                Proceed
              </Button>
            </div>
          ) : (
            <div
              id="scrollableBody"
              style={{
                height: `${notificationsCount > 4 ? '150px' : 'auto'}`,
                width: 230,
                overflow: `${notificationsCount > 4 ? 'auto' : 'hidden'}`,
                display: 'flex',
                justifyContent: `${notificationsCount > 0 ? 'start' : 'center'}`
              }}
            >
              <InfiniteScroll
                next={fetchMoreNotifications}
                hasMore={paginationParams.pageSize < notificationsCount}
                dataLength={notifications.length}
                loader={<p>Bring more notifications...</p>}
                scrollableTarget="scrollableBody"
              >
                {newNotificationsList(notifications)}
              </InfiniteScroll>
            </div>
          )}
          {notifications.length > 0 && !confirmationMessage && (
            <Button
              color="primary"
              className="mt-3"
              size="sm"
              onClick={async () => {
                setConfirmationMessage(true);
              }}
            >
              Clear All
            </Button>
          )}
        </PopoverBody>
      </Popover>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    notificationsCount: sm.notification.notificationsCount,
    notifications: sm.notification.notifications
  };
};

const mapActionsToProps = {
  getAllNotifications,
  deleteUserNotifications
};

export default connect(mapStateToProps, mapActionsToProps)(NotificationPopover);
