import { allCountries, CountryName, countryTuples } from 'country-region-data';
import React from 'react';
import Select from 'react-select';
import { SelectOption } from '../CustomSelect';

interface IProps {
  className: string;
  name?: string;
  classNamePrefix?: string;
  value: string;
  setCountry: Function;
  extraOption?: boolean;
  placeholder?: string;
  allSelection?: boolean;
}

const extraOptions: SelectOption[] = [{ label: 'Unknown', value: 'UW' }];
const defaultOption: SelectOption[] = [{ label: 'All Countries', value: '' }];
const priorityOptions: SelectOption[] = [
  { label: 'United States', value: 'US' },
  { label: 'Canada', value: 'CA' }
];
const removeOption: string = 'Puerto Rico';
const getOptions = (extraOption?: boolean, allSelection?: boolean): SelectOption[] => {
  const countries = allCountries
    .map((item): SelectOption => ({ label: item[0], value: item[1] }))
    .concat(extraOption ? extraOptions : [])
    .filter(
      (item) => !priorityOptions.some((v) => item.label === v.label) && item.label !== removeOption
    );
  return allSelection
    ? defaultOption.concat(priorityOptions).concat(countries)
    : priorityOptions.concat(countries);
};
export const getCountryCodeByName = (countryName: CountryName): string => {
  const countryTuple = countryTuples.find(([name]) => name === countryName);
  return countryTuple ? countryTuple[1] : '';
};

const CountryDropdown: React.FC<IProps> = ({
  className,
  name,
  value,
  setCountry,
  extraOption,
  placeholder,
  classNamePrefix,
  allSelection
}) => {
  const options = getOptions(extraOption, allSelection);
  return (
    <Select
      className={className}
      classNamePrefix={classNamePrefix}
      name={name}
      options={options}
      value={options.find((v) => v.label === value)}
      onChange={(e: any) => setCountry(e.label)}
      placeholder={placeholder}
      styles={{
        placeholder: (base, props) => ({
          ...base,
          marginLeft: '5px'
        }),
        singleValue: (base, props) => ({
          ...base,
          marginLeft: '5px'
        })
      }}
    />
  );
};
export default CountryDropdown;
