import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { OnChangeValue } from 'react-select';
import { getProviderNameList } from '../redux/provider/thunks';
import { stateMappings } from '../redux/stateMappings';
import CustomPicky from './CustomPicky';
import CustomSelect, { SelectOption } from './CustomSelect';

export interface ProviderItem {
  id: any;
  name: string;
  isITGMember: boolean;
  badEmails?: boolean;
  noRmd?: boolean;
  active?: boolean;
  country: string;
}

interface IProps {
  option?: number | number[];
  onChange: (value: OnChangeValue<SelectOption, boolean>) => void;
  addAllItem?: boolean;
  isSearchable?: boolean;
  className?: string;
  isClearable?: boolean;
  removeId?: number;
  placeholder?: string;
  noneSelectedByDefault?: boolean;
  addGovProvider?: boolean;
  isMulti?: boolean;
  numberDisplayed?: number;
  providerNameList: ProviderItem[];
  getProviderNameList: Function;
  maxMenuHeight?: number;
  includeInactive?: boolean;
}

const ProvidersSelect: FC<IProps> = ({
  option,
  onChange,
  addAllItem,
  isSearchable,
  className,
  isClearable,
  removeId,
  placeholder,
  noneSelectedByDefault,
  addGovProvider,
  isMulti,
  numberDisplayed,
  providerNameList,
  getProviderNameList,
  maxMenuHeight,
  includeInactive
}) => {
  const [api, setApi] = useState<SelectOption[]>([]);
  const getApi = (addGovProvider?: boolean): SelectOption[] => {
    const data = (
      includeInactive ? providerNameList : providerNameList.filter((v) => v.active)
    ).map(
      (element: ProviderItem): SelectOption => ({
        label: element.name + ' (' + element.country + ')',
        value: element.id
      })
    );
    if (!addGovProvider) {
      return data;
    }
    data.push({ label: 'Gov', value: -1 });
    data.push({ label: 'Admin', value: 6 });
    data.push({ label: 'DnoAcces', value: -2 });
    data.push({ label: 'Partner', value: -3 });
    return data;
  };
  const sortAndFilterApi = (addGovProvider?: boolean, removeId?: number) => {
    setApi(
      getApi(addGovProvider)
        .sort((a: SelectOption, b: SelectOption): number => (a.label > b.label ? 1 : -1))
        .filter((item: SelectOption): boolean => Number(item.value) !== removeId)
    );
  };
  useEffect(() => {
    if (!providerNameList || !providerNameList.length) getProviderNameList();
  }, []);
  useEffect(() => {
    sortAndFilterApi(addGovProvider, removeId);
  }, [providerNameList, addGovProvider]);
  return isMulti ? (
    <CustomPicky
      selectedOption={option}
      setSelectedOption={onChange}
      getOptions={api}
      className={className ? className : 'customselect-small'}
      isSearchable={isSearchable}
      isClearable={isClearable}
      addAllItem={addAllItem}
      typeLabel={'All Providers'}
      placeholder={placeholder}
      numberDisplayed={numberDisplayed ? numberDisplayed : 3}
    />
  ) : (
    <CustomSelect
      noneSelectedByDefault={noneSelectedByDefault}
      addAllItem={addAllItem}
      getOptions={api}
      isClearable={isClearable ? isClearable : false}
      isSearchable={isSearchable}
      setSelectedOption={onChange}
      placeholder={placeholder}
      className={className ? className : 'customselect-small'}
      typeLabel={'All Providers'}
      selectedOption={option}
      maxMenuHeight={maxMenuHeight}
    />
  );
};
const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return {
    providerNameList: sm.provider.providerNames
  };
};
const mapActionsToProps = {
  getProviderNameList
};
export default connect(mapStateToProps, mapActionsToProps)(ProvidersSelect);
