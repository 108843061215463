import { Notification } from '../../interfaces/notification';
import { Notifications } from './actionEnum';

export const getNotificationsCount = (response: number) => ({
  type: Notifications.GET_NOTIFICATIONS_COUNT,
  payload: response
});
export const getNotifications = (response: Notification[]) => ({
  type: Notifications.GET_NOTIFICATIONS,
  payload: response
});
export const setLoadingStatus = (candidate: boolean) => ({
  type: Notifications.SET_NOTIFICATION_LOADING_STATUS,
  payload: candidate
});
export const setErrorMessage = (message: string) => ({
  type: Notifications.SET_NOTIFICATION_ERROR_MESSAGE,
  payload: message
});
