import { format } from 'date-fns';
import { DateFormat } from '../../enum/DateFormat';
import { Pagination } from '../../interfaces/pagination';
import {
  ReportArchiveSearchRequest,
  ReportFormat,
  ReportPostData,
  ReportType
} from '../../interfaces/report';
import { FilterElement } from '../../lib/FilterElement';
import { logoutOnAuthError } from '../../lib/apiRequest';
import { downloadFile } from '../../lib/utilities';
import {
  getArchivedReports,
  getArchivedReportsListCall,
  getReport,
  getRollupReport,
  setErrorMessage,
  setLoadingStatus
} from './actions';
import {
  deleteArchivedReportApiCall,
  downloadReportApiCall,
  downloadReportArchiveApiCall,
  getArchivedReportsApiCall,
  getReportApiCall,
  getReportArchiveApiCall
} from './apiCalls';

const setStatus = (status: boolean, message: string) => async (dispatch: any) => {
  dispatch(setErrorMessage(message));
  dispatch(setLoadingStatus(status));
};

export const getReportObject =
  (content: ReportPostData, type: ReportType, formatType: ReportFormat) =>
  async (dispatch: any) => {
    dispatch(setStatus(true, ''));
    try {
      const response = await getReportApiCall(content, type, formatType);
      const pendingReport =
        type === ReportType.QueryReport ||
        type === ReportType.SubpoenaReport ||
        type === ReportType.TfSubpoenaReport ||
        type === ReportType.IPReport ||
        type === ReportType.PartnerReport ||
        type === ReportType.PartnerSourceReport ||
        type === ReportType.QuarterlyReport;
      if (formatType == ReportFormat.Json || pendingReport) {
        if (type == ReportType.RollupReport) {
          dispatch(getRollupReport(response.data));
        } else {
          dispatch(getReport(response.data));
        }
        if (pendingReport) {
          window.location.href = '/admin-dashboard#reportarchive';
          window.location.reload();
        }
      } else {
        downloadFile(
          response.data,
          content.name
            ? content.name + '.' + formatType.toString()
            : response.headers.contentfilename
              ? response.headers.contentfilename
              : type.toString() +
                '-' +
                format(new Date(), DateFormat.Report) +
                '.' +
                formatType.toString()
        );
      }
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };

export const getArchivedReportsList =
  (params: Pagination, filterElements: FilterElement) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));
    try {
      getArchivedReportsApiCall(params, filterElements).then((response) => {
        dispatch(getArchivedReports(response.data));
        dispatch(setStatus(false, ''));
      });
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };

export const deleteArchivedReport = (id: number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    await deleteArchivedReportApiCall(id);
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const downloadReportApi =
  (type: ReportType, format: ReportFormat) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    try {
      const response = await downloadReportApiCall(type, format);
      downloadFile(response.data, response.headers.contentfilename);
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };

export const getReportArchiveList =
  (params: Pagination, filterElements: ReportArchiveSearchRequest) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));
    try {
      getReportArchiveApiCall(params, filterElements).then((response) => {
        dispatch(getArchivedReportsListCall(response.data));
        dispatch(setStatus(false, ''));
      });
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };

export const downloadReportArchiveApi = (id: number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  try {
    const response = await downloadReportArchiveApiCall(id);
    downloadFile(response.data, response.headers.contentfilename);
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};
