//This file holds the API calls that hit the /user route for DRY purposes
import { PersonalInfo, RegisterUser, SignInUser } from '../../interfaces/user';
import { ApiRequest, ApiRequestWithoutToken } from '../../lib/apiRequest';

export const register = async (content: RegisterUser) =>
  ApiRequest.request({
    method: 'POST',
    url: `/account/register`,
    data: {
      ...content
    }
  });

export const authenticate = async (content: SignInUser) =>
  ApiRequestWithoutToken.request({
    method: 'POST',
    url: `/account/authenticate`,
    data: {
      ...content
    }
  });

export const logout = async () =>
  ApiRequest.request({
    method: 'POST',
    url: `/Token/revoke`
  });

export const getUserInfo = async (userId: Number) =>
  ApiRequest.request({
    method: 'GET',
    url: `/users/${userId}`
  });

export const changePassword = async (
  content: PersonalInfo,
  oldPassword: string,
  newPassword: string
) =>
  ApiRequest.request({
    method: 'PUT',
    url: `/users/${content.id}/change-password`,
    data: {
      newPassword,
      oldPassword
    }
  });

export const editUserInfo = async (content: PersonalInfo) =>
  ApiRequest.request({
    method: 'PUT',
    url: `/users/${content.id}`,
    data: {
      ...content
    }
  });

export const deactivateAccount = async (userId: Number) =>
  ApiRequest.request({
    method: 'DELETE',
    url: `/users/${userId}`
  });

export const resetPasswordEmailGenerate = async (emailAddress: string) =>
  ApiRequest.request({
    method: 'POST',
    url: `/account/GenerateResetPasswordEmail`,
    data: {
      email: emailAddress
    }
  });

export const getZendeskJWT = async () =>
  ApiRequest.request({
    method: 'GET',
    url: `/Token/jwtzendesk`
  });

export const getZendeskURL = async () =>
  ApiRequest.request({
    method: 'GET',
    url: `/Token/zendesk`
  });
