import { Attachments } from './actionEnum';

export const getPublicAttachmentsOldLinks = (response: any) => ({
  type: Attachments.GET_PUBLIC_ATTACHMENTS_OLD_LINKS,
  payload: response
});

export const setLoadingStatus = (candidate: boolean) => ({
  type: Attachments.SET_ATTACHMENTS_LOADING_STATUS,
  payload: candidate
});

export const setErrorMessage = (message: string) => ({
  type: Attachments.SET_ATTACHMENTS_ERROR_MESSAGE,
  payload: message
});
