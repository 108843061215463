import React from 'react';
import { TableColumn } from 'react-data-table-component';
import AudioModal from '../../components/AudioModal';
import ProviderPanel from '../../components/ProviderPanel';
import { Notes } from '../../components/shared/Notes';
import AttestationTracebackStirShaken from '../../components/stirShaken/AttestationTracebackStirShaken';
import SignerTracebackCellContent from '../../components/stirShaken/SignerTracebackCellContent';
import { DateFormat } from '../../enum/DateFormat';
import { Traceback } from '../../interfaces/traceback';
import { getClientFormattedDate } from '../utilities';

export const tracebacksGovPageColumns: TableColumn<Traceback>[] = [
  {
    name: '',
    width: '20px',
    cell: () => <div />
  },
  {
    name: 'TB #',
    selector: () => 'id',
    id: 'column-id',
    sortable: true,
    grow: 1,
    cell: (traceback: Traceback) => <div className="table-cell">{traceback.tracebackId}</div>
  },
  {
    name: 'Created',
    selector: () => 'create_date',
    id: 'column-create_date',
    sortable: true,
    grow: 1,
    cell: (traceback: Traceback) => (
      <span className="table-cell">
        {traceback.create_date
          ? getClientFormattedDate(traceback.create_date, DateFormat.ShortDate)
          : 'None'}
      </span>
    )
  },
  {
    name: 'Audio',
    id: 'column-audio',
    sortable: false,
    grow: 0.5,
    compact: true,
    cell: (traceback: Traceback) => (
      <div className="d-flex flex-fill">
        <AudioModal
          id={traceback.tracebackId.toString()}
          audioUrl={traceback.audioUrl}
          representativeAudio={traceback.representativeAudio}
        />
      </div>
    )
  },
  {
    name: 'Notes',
    selector: () => 'notes',
    id: 'column-notes',
    sortable: false,
    width: '150px',
    compact: true,
    cell: (traceback: Traceback) => (
      <div className="table-cell center">
        <Notes types={traceback.notes} />
      </div>
    )
  },
  {
    name: 'Campaign',
    selector: () => 'campaignName',
    id: 'column-campaignName',
    sortable: true,
    grow: 3,
    cell: (traceback: Traceback) => (
      <div className="table-cell">{traceback.campaignName || 'None'}</div>
    )
  },
  {
    name: 'Last Provider',
    selector: () => 'lastProviderName',
    id: 'column-lastProviderName',
    sortable: true,
    grow: 3,
    cell: (traceback: Traceback) => (
      <ProviderPanel
        includeReputation={true}
        provider={traceback.lastProvider}
        hyperlink
        rmdFlag={traceback.lastHopRMDFlag}
      />
    )
  },
  {
    name: 'Immediate Downstream',
    selector: () => 'downstreamProvider',
    id: 'column-downstreamProvider',
    sortable: false,
    grow: 2,
    cell: (traceback: Traceback) => (
      <ProviderPanel
        includeReputation={true}
        provider={traceback.downstreamProvider && traceback.downstreamProvider}
        hyperlink
        rmdFlag={traceback.downstramHopRMDFlag && traceback.downstramHopRMDFlag}
      />
    )
  },
  {
    name: 'Signer',
    selector: () => 'signerProviderName',
    id: 'column-signerProviderName',
    sortable: false,
    grow: 3,
    cell: (traceback: Traceback) => <SignerTracebackCellContent traceback={traceback} />
  },
  {
    name: 'Attestation',
    selector: () => 'attest',
    id: 'column-attest',
    sortable: false,
    grow: 1,
    cell: (traceback: Traceback) => <AttestationTracebackStirShaken traceback={traceback} />
  }
];
