import React from 'react';
import BaseCard from '../components/BaseCard';

const TermsOfUse = () => {
  return (
    <div className="container-fluid px-md-5">
      <BaseCard containerClassnames="card-join" headerTitle="Terms of Use">
        <div className="row p-4 justify-content">
          <div className="col-lg-8 p-3">
            <h6 className="font-weight-bold">Last Updated/Effective: January 18, 2023 .</h6>
            <p>
              The Industry Traceback Group, LLC (ITG), a wholly-owned subsidiary of USTelecom – The
              Broadband Association(USTelecom), operates the Secure Traceback Portal (STP) located
              at{' '}
              <a
                className="base-link"
                href="https://portal.tracebacks.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://portal.tracebacks.org/
              </a>{' '}
              as part of the industry effort to protect voice networks and consumers from
              fraudulent, abusive, and unlawful calls, including illegal robocalls. The STP is
              intended to be exclusively used to identify the source of and ultimately stop such
              calls through industry traceback efforts.
            </p>
            <p>
              By using or accessing the STP in any way, you accept and agree to be bound by these
              Terms of Use as well as the STP{' '}
              <a
                className="base-link"
                href="https://portal.tracebacks.org/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              .
            </p>
            <p>
              <strong className="text-uppercase">PLEASE READ THE TERMS OF USE CAREFULLY</strong>{' '}
              before you access or use the STP. If you do not agree to these Terms of Use, you must
              not access or use the STP.
            </p>
            <h6 className="text-ust">1. Permitted User Access and Use of the STP</h6>
            <p className="text-underline">
              <strong>
                The STP may only be lawfully accessed and used by (1) representatives of voice
                service providers responding to traceback requests and reviewing their traceback
                history; (2) U.S. federal and state law enforcement personnel or other government or
                law enforcement personnel authorized by the ITG; and (3) the ITG's administrative
                team and supporting personnel. No other individuals or entities are authorized to
                access the STP.
              </strong>
            </p>
            <p>
              The ITG reserves the right to withdraw or amend the STP, and any service or material
              the ITG provides through the STP, in the ITG's sole discretion without notice. Neither
              the ITG nor USTelecom will be liable if for any reason all or any part of the STP is
              unavailable at any time or for any period. From time to time, the ITG may restrict
              access to some parts of the STP, or the entire STP, to users, including registered
              users.
            </p>
            <h6 className="text-ust">2. User Representation and Warranties</h6>
            <p>
              If you are a representative of a voice service provider, by using this STP, you
              represent and warrant that:
            </p>
            <ul>
              <li>
                You understand and, to the extent applicable, comply with the laws governing illegal
                robocalls, including but not limited to the Telecommunications Consumer Protection
                Act (TPCA) and the Truth in Caller ID Act of 2009, see 47 U.S.C. § 227 and the FCC's
                implementing rules, the Telemarketing Sales Rule, see 16 CFR § 310 , the Wire Fraud
                Statute, see 18 U.S.C. § 1343, the FCC's caller ID authentication and robocall
                mitigation rules, see 47 CFR §§ 64.6301 - 64.6306;
              </li>
              <li>
                You understand and, to the extent applicable, comply with the Customer Proprietary
                Network Information (CPNI) requirements and obligations as set forth in 47 U.S.C. §
                222 and the FCC's implementing rules, and your use of the STP will be in full
                compliance with the CPNI rules and any other privacy laws, to the extent applicable;
              </li>
              <li>
                Your use of the STP will be in conformance with, and furtherance of, the
                Anti-Robocall Principles for Voice Service Providers adopted by several members of
                the ITG and available{' '}
                <a
                  className="base-link"
                  href="https://www.ustelecom.org/wp-content/uploads/2019/08/State-AGs-Providers-AntiRobocall-Principles-With-Signatories.pdf"
                >
                  here
                </a>
                ;
              </li>
              <li>
                Your use of the STP will be in full compliance with Industry Traceback Group
                Policies and Procedures (Policies and Procedures), as those Policies and Procedures
                are available{' '}
                <a className="base-link" href="https://tracebacks.org/itg-policies-and-procedures/">
                  here
                </a>{' '}
                and may be updated from time to time;
              </li>
              <li>
                You will use the information and data available in the STP for the exclusive purpose
                of investigating and ultimately stopping illegal robocalls, and will not use any
                information acquired from the STP to gain an unfair competitive advantage or for any
                other anticompetitive purpose;
              </li>
              <li>You are of legal age to form a binding contract with the ITG.</li>
            </ul>
            <p>
              If you are U.S. law criminal and civil enforcement official, or other government or
              enforcement personnel authorized to access the STP by ITG, you represent and warrant
              that:
            </p>
            <ul>
              <li>
                You are a law enforcement official authorized to investigate and conduct enforcement
                to stop illegal calls or otherwise authorized by the ITG to access the STP as part
                of efforts to stop illegal calls;
              </li>
              <li>
                Your use of the STP will be in furtherance of efforts to investigate and ultimately
                stop illegal calls;
              </li>
              <li>
                You understand that the data and information available in the STP may include
                sensitive information and therefore may not be shared publicly or with individuals
                who are not law enforcement personnel without the prior express approval of the ITG;
              </li>
              <li> You are of legal age to form a binding contract with the ITG.</li>
            </ul>
            <p>
              If you cannot make these representations and warrants applicable to you, you must not
              access or use the STP.
            </p>
            <h6 className="text-ust">3. Changes to the Terms of Use</h6>
            <p>
              The ITG may revise and update these Terms of Use from time to time in our sole
              discretion. The amended Terms of Use will be effective immediately after posted. Your
              continued use of the STP after revised Terms of Use are posted constitutes your
              consent to such changes.
            </p>
            <h6 className="text-ust">4. STP Registration and User Credentials</h6>
            <p>
              To access the STP or some of the resources it offers, you may be asked to provide
              certain registration details or other information. It is a condition of your use of
              the STP that all the information you provide to the STP is correct, current, and
              complete. You agree that all information you provide to register with this STP or
              otherwise, including, but not limited to, through the use of any interactive features
              on the STP, is governed by the ITG's Privacy Policy, and you consent to all actions
              the ITG takes with respect to your information consistent with the Privacy Policy.
            </p>
            <p>
              If you choose, or are provided with, a user name, password, or any other piece of
              information as part of the ITG's security procedures, you must treat such information
              as confidential, and you must not disclose it to any unauthorized person or entity.
              You also acknowledge that your account is specific to your organization and agree not
              to provide any person outside your organization with access to the STP, including by
              using your user name, password, or other security information. You agree to notify us
              immediately of any unauthorized access to or use of your user name or password or any
              other breach of security. You also agree to ensure that you exit from your account at
              the end of each session.
            </p>
            <p>
              The ITG has the right to disable any user name, password, or other identifier, whether
              chosen by you or provided by the ITG, at any time in the ITG's sole discretion for any
              or no reason, including if, in the exclusive opinion of the ITG, you have violated any
              provision of these Terms of Use.
            </p>
            <h6 className="text-ust">5. Intellectual Property Rights</h6>
            <p>
              The STP and its entire contents, features, and functionality (including but not
              limited to all information, software, text, displays, images, video, and audio, and
              the design, selection, and arrangement thereof) are owned by the ITG, its licensors,
              or other providers of such material and are protected by United States and
              international copyright, trademark, patent, trade secret, and other intellectual
              property or proprietary rights laws.
            </p>
            <p>
              These Terms of Use permit you to use the STP for the exclusive purposes described
              above. You must not reproduce, distribute, modify, create derivative works of,
              publicly display, republish, download, store, or transmit any of the material on the
              STP, or derived from the STP, except as explicitly permitted in these Terms of Use and
              as follows:
            </p>
            <ul>
              <li>
                Your computer may temporarily store copies of such materials in RAM incidental to
                your accessing and viewing those materials.
              </li>
              <li>
                You may store files that are automatically cached by your Web browser for display
                enhancement purposes.
              </li>
              <li>
                You may print or download one copy of a reasonable number of pages of the STP for
                your authorized use and not for further reproduction, publication, or distribution.
              </li>
            </ul>
            <p>You must not:</p>
            <ul>
              <li>Modify copies of any materials from this site.</li>
              <li>
                Delete or alter any copyright, trademark, or other proprietary rights notices from
                copies of materials from this site.
              </li>
            </ul>
            <p>
              You must not access or use for any unauthorized purposes any part of the STP or any
              services or materials available through the STP.
            </p>
            <p>
              You, or any consultants, contractors, or third party advisors you employ, shall not:
              (i) copy, modify, or create any derivative work of or relating to the STP, or
              information contained therein; or (ii) reverse engineer, decompile, disassemble, or
              translate any of the intellectual property or information relating to the STP, or
              information contained therein.
            </p>
            <p>
              If you print, copy, modify, download, or otherwise use or provide any other person
              with access to any part of the STP in breach of the Terms of Use, your right to use
              the STP will stop immediately and you must, at the ITG's option, return or destroy any
              copies of the materials you have made. No right, title, or interest in or to the STP
              or any content on the STP is transferred to you, and all rights not expressly granted
              are reserved by the ITG. Any use of the STP not expressly permitted by these Terms of
              Use is a breach of these Terms of Use and may violate copyright, trademark, and other
              laws.
            </p>
            <h6 className="text-ust">6. Trademarks</h6>
            <p>
              The ITG name, the USTelecom name, the terms “Industry Traceback Group” and “USTelecom”
              along with the ITG and USTelecom logos, and all related names, logos, product and
              service names, designs, and slogans are trademarks respectively of the ITG and
              USTelecom or affiliates or licensors. You must not use such marks without the prior
              written permission of the ITG or USTelecom as applicable. All other names, logos,
              product and service names, designs, and slogans on this STP are the trademarks of
              their respective owners.
            </p>
            <h6 className="text-ust">7. Acceptable Use</h6>
            <p>
              You may use the STP only for lawful purposes and in accordance with these Terms of Use
              and the Policies and Procedures. You agree not to use the STP:
            </p>
            <ul>
              <li>
                In any way that violates any applicable federal, state, local, or international law
                or regulation (including, without limitation, any laws regarding the export of data
                or software to and from the US or other countries);
              </li>
              <li>
                To send, knowingly receive, upload, download, use, or re-use any material that does
                not comply with these Terms of Use;
              </li>
              <li>
                To transmit, or procure the sending of, any advertising or promotional material,
                including any "junk mail," "chain letter," "spam," or any other similar
                solicitation;
              </li>
              <li>
                To impersonate or attempt to impersonate the ITG, USTelecom, an ITG or USTelecom
                employee, another user, or any other person or entity;
              </li>
              <li>
                To publish or share information about voice service providers and their traceback
                results except, in the case of voice service provider users, as necessary to stop
                illegal robocalls on your network or that are impacting your customers, or, in the
                case of law enforcement personnel, outside of an official law enforcement
                investigation and enforcement action; and/or
              </li>
              <li>
                To engage in any other conduct that restricts or inhibits anyone's use or enjoyment
                of the STP, or which, as determined by the ITG, may harm the ITG, USTelecom or users
                of the STP, or expose them to liability;
              </li>
            </ul>
            <p>Additionally, you agree not to:</p>
            <ul>
              <li>
                Use the STP in any manner that could disable, overburden, damage, or impair the site
                or interfere with any other party's use of the STP, including their ability to
                engage in real time activities through the STP;
              </li>
              <li>
                Use any robot, spider, or other automatic device, process, or means to access the
                STP for any purpose, including monitoring or copying any of the material on the STP;
              </li>
              <li>
                Use any manual process to monitor or copy any of the material on the STP, or for any
                other purpose not expressly authorized in these Terms of Use, without the ITG's
                prior written consent;
              </li>
              <li>
                Use any device, software, or routine that interferes with the proper working of the
                STP;
              </li>
              <li>
                Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is
                malicious or technologically harmful;
              </li>
              <li>
                Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts
                of the STP, the server on which the STP is stored, or any server, computer, or
                database connected to the STP;
              </li>
              <li>
                Attack the STP via a denial-of-service attack or a distributed denial-of-service
                attack; and/or
              </li>
              <li>Otherwise attempt to interfere with the proper working of the STP.</li>
            </ul>
            <h6 className="text-ust">8. Reliance on Information Posted</h6>
            <p>
              The information presented on or through the STP is made available solely for purpose
              of tracing and identifying the source of suspected illegal calls, including illegal
              robocalls. The ITG does not, and cannot warrant the accuracy, completeness, or
              usefulness of the information contained in the STP. Any reliance you place on such
              information is strictly at your own risk. The ITG disclaims all liability and
              responsibility arising from any reliance placed on such materials by you to the STP,
              or by anyone who may be informed of any of its contents.
            </p>
            <p>
              This STP includes content provided by third parties. All information provided by third
              parties must be provided in compliance with the CPNI rules and other applicable
              privacy laws are solely the responsibility of the entity providing those materials.
              These materials are not necessarily under the control of the ITG. The ITG is not
              responsible, or liable to you or any third party, for the content or accuracy of any
              materials provided by any third parties.
            </p>
            <p>
              The ITG may update the content on this STP from time to time, but its content is not
              necessarily complete or up-to-date. Any of the material on the STP may be out of date
              at any given time, and the ITG is under no obligation to update such material.
            </p>
            <h6 className="text-ust">9. Security</h6>
            <p>
              Maintaining the security of the STP is critical to the ITG's mission. Violations of
              the security of the STP and its content are prohibited and may result in criminal or
              civil liability. The ITG may investigate any incident that compromises the security of
              the STP and will cooperate with law enforcement if a criminal violation is suspected.
            </p>
            <h6 className="text-ust">10. Disclaimer of Warranties</h6>
            <p>
              You understand that the ITG cannot and does not guarantee or warrant that files
              available for downloading from the STP will be free of viruses or other destructive
              code. You are responsible for implementing sufficient procedures and checkpoints to
              satisfy your particular requirements for anti-virus protection and accuracy of data
              input and output, and for maintaining a means external to the STP for any
              reconstruction of any lost data. TO THE FULLEST EXTENT PROVIDED BY LAW, NEITHER THE
              ITG NOR USTELECOM WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED
              DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY
              INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL
              DUE TO YOUR USE OF THE STP OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE STP OR TO
              YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY STP LINKED TO IT.
            </p>
            <p>
              YOUR USE OF THE STP, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE STP
              IS AT YOUR OWN RISK. THE STP, ITS CONTENT, AND ANY SERVICES OBTAINED THROUGH THE STP
              ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY
              KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE ITG, USTELECOM, NOR ANY PERSON ASSOCIATED
              WITH THE ITG OR USTELECOM MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
              COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE STP.
              WITHOUT LIMITING THE FOREGOING, NEITHER THE ITG, USTELECOM, NOR ANYONE ASSOCIATED WITH
              THE ITG OR USTELECOM REPRESENTS OR WARRANTS THAT THE STP, ITS CONTENT, OR ANY SERVICES
              OBTAINED THROUGH THE STP WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED,
              THAT DEFECTS WILL BE CORRECTED, THAT THE ITG'S SITE OR THE SERVER THAT MAKES IT
              AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE STP OR ANY
              SERVICES OR ITEMS OBTAINED THROUGH THE STP WILL OTHERWISE MEET YOUR NEEDS OR
              EXPECTATIONS.
            </p>
            <p>
              TO THE FULLEST EXTENT PROVIDED BY LAW, THE ITG HEREBY DISCLAIMS ALL WARRANTIES OF ANY
              KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED
              TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR
              PURPOSE.
            </p>
            <p>
              THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER
              APPLICABLE LAW.
            </p>
            <h6 className="text-ust">11. Limitation on Liability</h6>
            <p>
              TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE ITG, USTELECOM, THEIR
              AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR
              DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR
              IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE STP, ANY STPS LINKED TO IT, ANY
              CONTENT ON THE STP OR SUCH OTHER STPS, INCLUDING ANY DIRECT, INDIRECT, SPECIAL,
              INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL
              INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS
              OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND
              WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN
              IF FORESEEABLE.
            </p>
            <p>
              THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER
              APPLICABLE LAW.
            </p>
            <h6 className="text-ust">12. Indemnification</h6>
            <p>
              To the extent permitted under applicable law, you agree to defend, indemnify, and hold
              harmless the ITG and USTELECOM, their affiliates, licensors, and service providers,
              and their respective officers, directors, employees, contractors, agents, licensors,
              suppliers, successors, and assigns from and against any claims, liabilities, damages,
              judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys'
              fees) arising out of or relating to your violation of these Terms of Use or your use
              of the STP, including, but not limited to, your User Contributions, any use of the
              STP's content, services, and products other than as expressly authorized in these
              Terms of Use, or your use of any information obtained from the STP.
            </p>
            <h6 className="text-ust">13. Governing Law and Jurisdiction</h6>
            <p>
              All matters relating to the STP and these Terms of Use, and any dispute or claim
              arising therefrom or related thereto (in each case, including non-contractual disputes
              or claims), shall be governed by and construed in accordance with the internal laws of
              the District of Columbia without giving effect to any choice or conflict of law
              provision or rule (whether of the District of Columbia or any other jurisdiction).
            </p>
            <p>
              Any legal suit, action, or proceeding arising out of, or related to, these Terms of
              Use or the STP shall be instituted exclusively in the federal courts of the United
              States or the courts of the District of Columbia. You waive any and all objections to
              the exercise of jurisdiction over you by such courts and to venue in such courts.
            </p>
            <h6 className="text-ust">14. Arbitration</h6>
            <p>
              In its sole discretion, the ITG may require you to submit any disputes arising from
              these Terms of Use or use of the STP, including disputes arising from or concerning
              their interpretation, violation, invalidity, non-performance, or termination, to final
              and binding arbitration under the Rules of Arbitration of the American Arbitration
              Association applying District of Columbia law..
            </p>
            <h6 className="text-ust">15. Limitation on Time to File Claims</h6>
            <p>
              ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF
              USE OR THE STP MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION
              ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
            </p>
            <h6 className="text-ust">16. Waiver and Severability</h6>
            <p>
              No waiver by the ITG of any term or condition set out in these Terms of Use shall be
              deemed a further or continuing waiver of such term or condition or a waiver of any
              other term or condition, and any failure of the ITG to assert a right or provision
              under these Terms of Use shall not constitute a waiver of such right or provision.
            </p>
            <p>
              If any provision of these Terms of Use is held by a court or other tribunal of
              competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such
              provision shall be eliminated or limited to the minimum extent such that the remaining
              provisions of the Terms of Use will continue in full force and effect.
            </p>
            <h6 className="text-ust">17. Entire Agreement</h6>
            <p>
              The Terms of Use and Privacy Policy constitute the sole and entire agreement between
              you and the ITG regarding the STP and supersede all prior and contemporaneous
              understandings, agreements, representations, and warranties, both written and oral,
              regarding the STP.
            </p>
            <h6 className="text-ust">18. Your Comments and Concerns</h6>
            <p>If you have any questions about this privacy statement or website, please contact</p>
            <p>
              Industry Traceback Group
              <br />
              c/o USTelecom – The Broadband Association
              <br />
              601 New Jersey Avenue, NW
              <br />
              Suite 600
              <br />
              Washington, DC 20001
              <br />
              <a
                className="base-link"
                href="mailto:legal@tracebacks.org?subject=Traceback%20Platform%20Inquiry"
              >
                legal@tracebacks.org
              </a>
              <br />
            </p>
          </div>
        </div>
      </BaseCard>
    </div>
  );
};

export default TermsOfUse;
