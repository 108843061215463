//This file holds the API calls that hit the /user route for DRY purposes
import { Pagination } from '../../interfaces/pagination';
import {
  CreateAdminPostData,
  CreatePasswordPostData,
  EmailSubscription
} from '../../interfaces/user';
import { FilterElement } from '../../lib/FilterElement';
import { ApiRequest, buildParams } from '../../lib/apiRequest';

export const getUserApiCall = async (userId: number, includeInactive?: boolean) =>
  ApiRequest.request({
    method: 'GET',
    url: `/users/${userId}${includeInactive ? '?includeInactive=true' : ''}`
  });

export const getAllUsersApiCall = async (
  params: Pagination,
  filterElements: FilterElement | null
) =>
  ApiRequest.request({
    method: 'POST',
    url:
      `/users/search?` +
      buildParams({
        ...params
      }),
    data: filterElements
  });

export const resetPasswordApiCall = async (userId: Number, content: CreatePasswordPostData) =>
  ApiRequest.request({
    method: 'PUT',
    url: `/users/${userId}/reset-password`,
    data: {
      ...content
    }
  });

export const addAdminApiCall = async (content: CreateAdminPostData) =>
  ApiRequest.request({
    method: 'POST',
    url: `/users`,
    data: {
      ...content
    }
  });

export const addUserApiCall = async (user: any) =>
  ApiRequest.request({
    method: 'POST',
    url: '/users/add',
    data: { ...user }
  });

export const editUserApiCall = async (userId: Number, content: CreateAdminPostData) =>
  ApiRequest.request({
    method: 'PUT',
    url: `/users/${userId}`,
    data: {
      ...content
    }
  });

export const deleteUserApiCall = async (userId: Number) =>
  ApiRequest.request({
    method: 'DELETE',
    url: `/users/${userId}`
  });

export const checkUserEmailApiCall = async (userEmail: String) =>
  ApiRequest.request({
    method: 'GET',
    url: `/user/CheckEmail/${userEmail}`
  });

export const getRedirectLocationApiCall = async (token: String, userId: Number, location: String) =>
  ApiRequest.request({
    method: 'POST',
    url: `/Email/redirect`,
    data: {
      token: token,
      userId: Number(userId),
      endpoint: location
    }
  });

export const addEmailSubscriptionApiCall = async (subscription: EmailSubscription) =>
  ApiRequest.request({
    method: 'POST',
    url: `/Email/subscribe`,
    data: {
      ...subscription
    }
  });

export const getEmailSubscriptionApiCall = async (emailType: string) => {
  return ApiRequest.request({
    method: 'GET',
    url: `/Email/subscribe/${emailType}`
  });
};

export const setUserInsightAgreedApiCall = async (userId: number) => {
  return ApiRequest.request({
    method: 'PUT',
    url: `/users/${userId}/insightAgreed`
  });
};
