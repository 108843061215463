import { Pagination } from 'reactstrap';
import { Messaging } from './actionEnum';

const meta = {
  TotalCount: 0,
  PageSize: 0,
  CurrentPage: 0,
  TotalPages: 1
};

const defaultState: any = {
  messages: [],
  meta,
  loading: 0,
  error: '',
  shouldReload: false,
  message: null,
  events: [],
  eventMeta: {
    TotalCount: 0,
    PageSize: 0,
    CurrentPage: 0,
    TotalPages: 1
  }
};

const reducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case Messaging.GET_MESSAGES:
      return {
        ...state,
        messages: action.payload.data,
        meta: action.payload.paginationMeta || meta
      };
    case Messaging.ADD_MESSAGE:
      return { ...state };
    case Messaging.CANCEL_MESSAGE:
      return { ...state };
    case Messaging.EDIT_MESSAGE:
      return { ...state };
    case Messaging.SET_MESSAGE:
      return { ...state, message: action.payload };
    case Messaging.RELOAD_DATA:
      return { ...state, shouldReload: action.payload };
    case Messaging.SET_LOADING:
      if (action.payload) return { ...state, loading: state.loading + 1 };
      else return { ...state, loading: state.loading === 0 ? state.loading : state.loading - 1 };
    case Messaging.GET_EMAIL_EVENTS:
      return {
        ...state,
        events: action.payload.data,
        eventMeta: action.payload.paginationMeta || state.eventMeta
      };
    default:
      return state;
  }
};

export default reducer;
