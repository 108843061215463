import { convertToTimeZone } from 'date-fns-timezone';
import React, { Fragment, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { Pagination, defaultPagination } from '../../../../interfaces/pagination';
import { ReportArchiveObj, ReportType } from '../../../../interfaces/report';
import { getApiFormattedDate, getEndOfTheDay, getStartOfTheDay } from '../../../../lib/utilities';
import { getProviderNameList } from '../../../../redux/provider/thunks';
import { getInProgresReportArchivesIDs } from '../../../../redux/report/apiCalls';
import { downloadReportArchiveApi, getReportArchiveList } from '../../../../redux/report/thunks';
import { stateMappings } from '../../../../redux/stateMappings';
import { getCampaignNamesApiCall } from '../../../../redux/traceback/apiCalls';
import CustomDataTable from '../../../CustomDataTable';
import CustomSelect, { SelectOption } from '../../../CustomSelect';
import { ProviderItem } from '../../../ProvidersSelect';
import ExpandableReport from './ExpandableRow';
import columns from './columns';
import { getDefaultEndDate, getDefaultStartDate, reportTypes, statusTypes } from './data';

interface IProps {
  reports: ReportArchiveObj[];
  paginationTotalRows: number;
  getReportArchiveList: Function;
  downloadReportArchiveApi: Function;
  providerNameList: ProviderItem[];
  getProviderNameList: Function;
}

const ReportsArchive: React.FC<IProps> = ({
  reports,
  paginationTotalRows,
  getReportArchiveList,
  downloadReportArchiveApi,
  providerNameList,
  getProviderNameList
}) => {
  const [startDate, setStartDate] = useState(getDefaultStartDate());
  const [endDate, setEndDate] = useState(getDefaultEndDate());
  const [status, setStatus] = useState('');
  const [type, setType] = useState<ReportType | undefined>();
  const [onlyGeneratedByMe, setOnlyGeneratedByMe] = useState(true);

  const [paginationParams, setPaginationParams] = useState({
    ...defaultPagination(),
    sort: 'create_date',
    order: 'desc'
  });

  const [campaignsNameList, setCampaignsNameList] = useState<SelectOption[]>([]);

  const [, setinProgressTimeOut] = useState<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    if (!providerNameList || providerNameList.length == 0) getProviderNameList();
  }, [providerNameList]);

  useEffect(() => {
    getCampaignNamesApiCall()
      .then((result: any) => {
        setCampaignsNameList(
          result.data.data.map((v: any) => ({ label: v.name, value: v.campaignId }))
        );
      })
      .catch((error: any) => {
        console.log('Error:', error);
      });
    return () => {
      setinProgressTimeOut((v) => {
        if (v !== null) clearInterval(v);
        return null;
      });
    };
  }, []);

  const getData = () =>
    getReportArchiveList(paginationParams, {
      startDate: getApiFormattedDate(startDate),
      endDate: getApiFormattedDate(endDate),
      status,
      onlyGeneratedByMe,
      type
    });

  useEffect(() => {
    if (reports) {
      setinProgressTimeOut((v) => {
        if (v !== null) clearTimeout(v);
        const reportsInProgress = reports.filter((v) => v.status === 'inProgress');
        if (reportsInProgress.length > 0)
          return setInterval(
            async () => {
              try {
                const inProgressData = await getInProgresReportArchivesIDs();
                const inProgress = inProgressData.data || [];
                const currentInProgress = reportsInProgress.filter((v) =>
                  inProgress.some((i: number) => v.id === i)
                );
                if (currentInProgress.length != reportsInProgress.length) getData();
              } catch (error: any) {
                console.log(error);
              }
            },

            10000
          );
        return null;
      });
    }
  }, [reports]);
  useEffect(() => {
    getData();
  }, [startDate, endDate, status, type, paginationParams, onlyGeneratedByMe]);

  const handleChange = (e: any, key: string) => {
    let date = new Date(Date.parse(e));
    switch (key) {
      case 'startDate':
        if (e) {
          setStartDate(getStartOfTheDay(new Date(e)).toISOString());
        } else setStartDate(getDefaultStartDate());
        break;
      case 'endDate':
        if (e) {
          setEndDate(getEndOfTheDay(new Date(e)).toISOString());
        } else setEndDate(getDefaultEndDate());
        break;
    }
  };
  const updatePagination = (params: Pagination) => {
    setPaginationParams({ ...paginationParams, ...params });
  };

  return (
    <Fragment>
      <div className="d-flex flex-row">
        <div className="d-flex flex-column col-2 mt-4 ps-0 pe-2">
          <div className="telecom-label ps-0">Start Date </div>
          <DatePicker
            maxDate={endDate ? convertToTimeZone(endDate, { timeZone: 'GMT' }) : new Date()}
            selected={startDate ? convertToTimeZone(startDate, { timeZone: 'GMT' }) : undefined}
            onChange={(option) => handleChange(option, 'startDate')}
            showYearDropdown
            yearDropdownItemNumber={15}
            scrollableYearDropdown
            placeholderText="&#xf133; startDate"
            clearButtonTitle={'Clear'}
            isClearable
          />
        </div>
        <div className="d-flex flex-column col-2 mt-4 ps-0 pe-2">
          <div className="telecom-label ps-0">End Date </div>
          <DatePicker
            maxDate={new Date()}
            selected={endDate ? convertToTimeZone(endDate, { timeZone: 'GMT' }) : undefined}
            minDate={convertToTimeZone(startDate, { timeZone: 'GMT' })}
            onChange={(option) => handleChange(option, 'endDate')}
            showYearDropdown
            yearDropdownItemNumber={15}
            scrollableYearDropdown
            placeholderText="&#xf133; endDate"
            clearButtonTitle={'Clear'}
            isClearable
          />
        </div>
        <div className="d-flex flex-column col-2 mt-4 ps-0 pe-2">
          <div className="telecom-label ps-0">Report Type </div>
          <CustomSelect
            selectedOption={type}
            setSelectedOption={(e: any) => setType(e ? e.value : null)}
            className={'customselect-small'}
            getOptions={reportTypes}
            placeholder={'Select Report Type'}
            typeLabel={''}
            isClearable
          />
        </div>
        <div className="d-flex flex-column col-2 mt-4 ps-0 pe-2">
          <div className="telecom-label ps-0">Report Status </div>
          <CustomSelect
            selectedOption={status}
            setSelectedOption={(e: any) => setStatus(e ? e.value : null)}
            className={'customselect-small'}
            getOptions={statusTypes}
            placeholder={'Select Report status'}
            typeLabel={''}
            isClearable
          />
        </div>
        <div className="d-flex flex-column col-2 mt-4 ps-0 pe-2">
          <div className="d-flex justify-content-center">
            <input
              type="checkbox"
              id="low_volume"
              name="low_volume"
              className="p-2 my-3"
              checked={onlyGeneratedByMe}
              onChange={() => setOnlyGeneratedByMe((v) => !v)}
            />
            <label htmlFor="low_volume" className="label-bold p-2 my-4  ">
              Generated By Me
            </label>
          </div>
        </div>
      </div>
      <CustomDataTable
        tableData={reports}
        columns={columns(downloadReportArchiveApi, getData)}
        defaultSortFieldId={paginationParams.sort}
        defaultSortAsc={false}
        defaultPage={paginationParams.page}
        defaultPageSize={paginationParams.pageSize}
        pagination={true}
        paginationTotalRows={paginationTotalRows}
        useExpandableRows={true}
        useExpandableRowsComponent={({ data }) => (
          <ExpandableReport
            data={data}
            providerNameList={providerNameList}
            campaignsNameList={campaignsNameList}
          />
        )}
        updatePaginationParams={updatePagination}
      />
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    paginationTotalRows: sm.report.archivedReportsMeta.TotalCount,
    reports: sm.report.archivedReportsList,
    providerNameList: sm.provider.providerNames
  };
};

const mapActionsToProps = {
  downloadReportArchiveApi,
  getReportArchiveList,
  getProviderNameList
};

export default connect(mapStateToProps, mapActionsToProps)(ReportsArchive);
