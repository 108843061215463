import { Campaign } from './actionEnum';

const meta = {
  TotalCount: 0,
  PageSize: 0,
  CurrentPage: 0,
  TotalPages: 1
};

const defaultState: any = {
  campaigns: [],
  campaignNames: [],
  attachments: [],
  sources: [],
  campaignStats: [],
  languages: [],
  campaign: {
    campaignId: 0,
    name: '',
    source: '',
    reference: '',
    language: '',
    workflow: '',
    description: '',
    comment: '',
    create_date: '',
    createdBy: '',
    update_date: '',
    updateFlag: 0,
    sources: [],
    labels: []
  },
  meta,
  loading: 0,
  error: ''
};

const reducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case Campaign.GET_CAMPAIGNS:
      return {
        ...state,
        campaigns: action.payload.data,
        meta: action.payload.paginationMeta || meta
      };

    case Campaign.GET_CAMPAIGN:
      return { ...state, campaign: action.payload.data };

    case Campaign.GET_CAMPAIGN_LABELS_STATS:
      return { ...state, campaignLabelsStats: action.payload.data };

    case Campaign.ADD_CAMPAIGN:
      return { ...state };

    case Campaign.SET_CAMPAIGN:
      return { ...state, campaign: action.payload };

    case Campaign.EDIT_CAMPAIGN:
      return { ...state, campaign: action.payload.data };

    case Campaign.ADD_ATTACHMENTS:
      return { ...state };

    case Campaign.GET_ATTACHMENTS:
      return { ...state, attachments: action.payload.data };

    case Campaign.GET_SOURCES:
      return { ...state, sources: action.payload.data };

    case Campaign.GET_LABELS:
      return { ...state, labels: action.payload.data };

    case Campaign.ADD_SOURCE:
      return { ...state, sources: [...state.sources] };

    case Campaign.ADD_LABEL:
      return { ...state, labels: [...state.labels] };

    case Campaign.SET_CAMPAIGN_LOADING_STATUS:
      if (action.payload) return { ...state, loading: state.loading + 1 };
      else return { ...state, loading: state.loading === 0 ? state.loading : state.loading - 1 };

    case Campaign.SET_CAMPAIGN_ERROR_MESSAGE:
      return { ...state, error: action.payload };

    case Campaign.GET_CAMPAIGN_LANGUAGES:
      return { ...state, languages: action.payload.data };

    default:
      return state;
  }
};

export default reducer;
