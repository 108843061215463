import React, { FC } from 'react';
import { Nav, NavItem } from 'reactstrap';
import { IncidentCampaignSource } from '../../../interfaces/incident';
import { visibleIncidentSources } from './utilities';

interface IProps {
  sources: IncidentCampaignSource[];
  activeSource: number;
  handleTabChange: (source: IncidentCampaignSource) => void;
}
const SourcesNav: FC<IProps> = ({ sources, activeSource, handleTabChange }) => (
  <Nav>
    {sources &&
      sources.length > 0 &&
      sources.map((source: IncidentCampaignSource) => {
        if (!!visibleIncidentSources.find((item) => item.name === source.name))
          return (
            <NavItem key={source.name + 'navItem'} style={{ width: '20%' }}>
              <a
                href={`admin-dashboard#incidents-${source.name}`}
                className={
                  'nav-link incident-nav-item' + (source.sourceId === activeSource ? ' active' : '')
                }
                onClick={() => handleTabChange(source)}
              >
                <div className="flex-column">
                  <div className="d-flex flex-row justify-content-end pb-3">
                    <span className="badge incident-campaign-badge">{source.count}</span>
                  </div>
                  <div className="d-flex flex-row justify-content-center pb-3">
                    <span className="font-calibri-bold">{source.name}</span>
                  </div>
                </div>
              </a>
            </NavItem>
          );
      })}
  </Nav>
);

export default SourcesNav;
