import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Input, Label } from 'reactstrap';
import { getEmailSubscriptionApiCall } from '../redux/user/apiCalls';
import { addEmailSubscription } from '../redux/user/thunks';

interface IProps {
  addEmailSubscription: Function;
  emailType: string;
}

const UpdateNewActivityButton: React.FC<IProps> = ({ addEmailSubscription, emailType }) => {
  const [newActivityUpdates, setNewActivityUpdates] = useState(false);

  const checkSubscription = async () => {
    try {
      const response = await getEmailSubscriptionApiCall(emailType);
      setNewActivityUpdates(response.data);
    } catch (error: any) {}
  };
  useEffect(() => {
    checkSubscription();
  }, []);

  const handleInputChange = () => {
    setNewActivityUpdates((prevState) => {
      addEmailSubscription({ emailType, subscribe: !prevState });
      return !prevState;
    });
  };

  return (
    <FormGroup check className="d-flex align-items-center" onClick={handleInputChange}>
      <input
        id="checkbox1"
        className="mt-0 me-2"
        type="checkbox"
        checked={newActivityUpdates}
        readOnly
      />
      <Label check style={{ color: `${newActivityUpdates ? '#28a745' : '#494949'}` }}>
        Email updates of new activity
      </Label>
    </FormGroup>
  );
};

const mapActionsToProps = { addEmailSubscription };

export default connect(null, mapActionsToProps)(UpdateNewActivityButton);
