import { Campaign } from './campaign';
import { Hop } from './hop';
import { Provider } from './provider';
import { Traceback } from './traceback';

export enum CommentFormat {
  RolledUp = 'rolledUp',
  Unrolled = 'unrolled'
}

export type DirectedToValue =
  | 'admin'
  | 'downstream'
  | 'upstream'
  | 'adminOnly'
  | 'oldUpstream'
  | 'oldDownstream';

export interface Comment {
  active: boolean;
  attachments: Attachment[];
  callDate: string;
  campaignId: number;
  campaignName: string;
  callRecordCampaigns: string;
  commentId: number;
  commentType: string;
  contact: string;
  contentText: string;
  create_date: string;
  emailType: string;
  isSystemComment: boolean;
  notificationDate: string;
  providerContact: string;
  providerId: number;
  relatedProvider: Provider | undefined;
  hopProvider: Provider | undefined;
  userProvider: Provider | undefined;
  recipientProvider: Provider | undefined;
  recipientEmail: string;
  reputation: number;
  spokeWith: string;
  systemId: number;
  callRecordTracebacks: string;
  update_date: string;
  userName: string;
  userId: number;
  relatedHopId: number;
  relatedHopActive: boolean;
  relatedTfHopActive: boolean;
  relatedObjects: CommentRelatedObject[];
  relatedTracebackId: number;
  relatedProviderId: number;
  relatedTfHopId: number;
  replyToCommentID?: number;
  directedTo?: DirectedToValue;
  ccName?: string;
  hopTracebackId?: number;
  tfhopTraceforwardId?: number;
  replyCase?: boolean;
}

export interface CommentRelatedObject {
  id: number;
  commentId: number;
  create_date: Date;
  active: boolean;
  relatedHop: Hop;
  relatedTraceback: Traceback;
  relatedCampaign: Campaign;
  relatedProvider: Provider;
}

export function emptyComment(): Comment {
  return {
    active: true,
    attachments: [],
    callDate: '',
    campaignId: 0,
    campaignName: '',
    callRecordCampaigns: '',
    commentId: 0,
    commentType: '',
    contact: '',
    contentText: '',
    create_date: '',
    emailType: '',
    relatedHopId: 0,
    isSystemComment: false,
    notificationDate: '',
    providerContact: '',
    providerId: 0,
    relatedProvider: undefined,
    hopProvider: undefined,
    userProvider: undefined,
    recipientProvider: undefined,
    recipientEmail: '',
    reputation: 0,
    spokeWith: '',
    systemId: 0,
    callRecordTracebacks: '',
    update_date: '',
    userName: '',
    userId: 0,
    relatedHopActive: false,
    relatedTfHopActive: false,
    relatedObjects: [],
    relatedTracebackId: 0,
    relatedProviderId: 0,
    relatedTfHopId: 0
  };
}

export interface CommentPostData {
  commentId?: number;

  commentType?: string;
  relatedObjectId: number;

  contentText?: string;

  callDate?: any;
  callRecordCampaigns?: string;
  callRecordTracebacks?: string;
  relatedProviderId?: number;
  spokeWith?: string;

  replyToCommentID?: number;
  directedTo?: string;
}

export interface Attachment {
  attachmentId: number;
  campaignId: number;
  commentId: number;
  create_date: string;
  fileName: string;
  hopId: number;
  parentId: number;
  parentType: number;
  providerId: number;
  tracebackId: number;
  update_date: string;
  userId: number;
}
export interface NotificationNewComment {
  id: number;
  objectId: number;
  closeComment: boolean;
}
