import { Comment, CommentFormat, CommentPostData } from '../../interfaces/comment';
import { Pagination } from '../../interfaces/pagination';
import { FilterElement } from '../../lib/FilterElement';
import { logoutOnAuthError } from '../../lib/apiRequest';
import { downloadFile } from '../../lib/utilities';
import {
  addAttachments,
  clearComments,
  editComment,
  getComment,
  getComments,
  getHopsNotificationsAction,
  getNotificationsNewCommentsListAction,
  setErrorMessage,
  setLoadingStatus
} from './actions';
import {
  addAttachmentsApiCall,
  addCommentApiCall,
  addOrRemoveAttachmentApiCall,
  downloadCommentsApiCall,
  editCommentApiCall,
  getCommentApiCall,
  getCommentsApiCall,
  getHopsNotificationsApiCall,
  getNotificationsNewCommentsListApiCall
} from './apiCalls';

export const getCommentList =
  (params: Pagination, filterElements: FilterElement, commentFormat: CommentFormat) =>
  async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    try {
      const comments = await getCommentsApiCall(params, filterElements, commentFormat);
      if (comments && comments.data) {
        dispatch(getComments(comments.data));
      }
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };

export const downloadCommentList =
  (params: Pagination, filterElements: FilterElement) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    try {
      const response = await downloadCommentsApiCall(params, filterElements);
      downloadFile(response.data, 'attachments.zip');
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };

export const getCommentObject = (commentId: number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    const comment = await getCommentApiCall(commentId);

    if (comment && comment.data) {
      dispatch(getComment(comment.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const addCommentObject = async (comment: CommentPostData, attachments: File[]) => {
  try {
    const response = await addCommentApiCall(comment);

    if (response && response.data.data.commentId && attachments.length > 0) {
      let formData = new FormData();

      attachments.forEach((attachment) => {
        formData.append('file', attachment);
      });

      await addAttachmentsApiCall(response.data.data.commentId, formData);
    }
    return response ? response.data.data.commentId : 0;
  } catch (error: any) {
    console.error('Failed addCommentApiCall');
  }
};

export const editCommentObject =
  (comment: CommentPostData, attachments: any[]) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    let formData = new FormData();

    attachments.forEach((attachment) => {
      formData.append('file', attachment);
    });

    try {
      if (attachments.length > 0) {
        const response = await Promise.all([
          editCommentApiCall(comment),
          addAttachmentsApiCall(comment.commentId || 0, formData)
        ]);
        if (response) {
          dispatch(editComment(response[0].data));
          dispatch(addAttachments(response[1].data));
        }
      } else {
        const response = await editCommentApiCall(comment);
        if (response && response.data) {
          dispatch(editComment(response.data));
        }
      }

      dispatch(setStatus(false, ''));
      window.location.reload();
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };

export const editCommentWithAttachments = async (comment: CommentPostData, attachments: any[]) => {
  try {
    let formData = new FormData();
    attachments.forEach((attachment) => {
      formData.append('file', attachment);
    });

    await Promise.all([
      editCommentApiCall(comment),
      addOrRemoveAttachmentApiCall(comment.commentId || 0, formData)
    ]);
  } catch (error: any) {
    logoutOnAuthError(error);
  }
};

export const addAttachmentList =
  (commentId: number, formData: FormData) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    try {
      const response = await addAttachmentsApiCall(commentId, formData);
      if (response && response.data) {
        dispatch(addAttachments(response.data));
      }
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };

const setStatus = (status: boolean, message: string) => async (dispatch: any) => {
  dispatch(setErrorMessage(message));
  dispatch(setLoadingStatus(status));
};

export const clearCommentsList = () => async (dispatch: any) => {
  dispatch(clearComments());
};

export const setCommentObject = (comment: Comment) => async (dispatch: any) => {
  dispatch(getComment({ data: comment }));
};

export const getNotificationsNewCommentsList =
  (type: string, hopId: string) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));
    try {
      const response = await getNotificationsNewCommentsListApiCall(type, hopId);
      if (response && response.data === null) {
        dispatch(getNotificationsNewCommentsListAction([]));
      } else if (response && response.data) {
        dispatch(getNotificationsNewCommentsListAction(response.data));
      }
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };
export const getHopsNotifications = () => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  try {
    const response = await getHopsNotificationsApiCall();
    if (response && response.data === null) {
      dispatch(getHopsNotificationsAction([]));
    } else if (response && response.data) {
      dispatch(getHopsNotificationsAction(response.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};
