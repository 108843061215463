import React, { Fragment, useEffect, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { Button, CardHeader, Col, Row } from 'reactstrap';
import { DateFormat } from '../../enum/DateFormat';
import { Attachment, Comment } from '../../interfaces/comment';
import { defaultPagination } from '../../interfaces/pagination';
import { simplifyFilterElement } from '../../lib/FilterElement';
import { decodeQueryParams, getFromLocalStorage } from '../../lib/history-utils';
import {
  getClientFormattedDate,
  getEndOfDayAsString,
  getStartOfDayAsString
} from '../../lib/utilities';
import { clearCommentsList } from '../../redux/comment/thunks';
import CommentsTable, {
  DefaultExpandedComponent,
  getCommentsBasicFilter,
  saveCommentParams,
  setCommentsFiltersFromHistory
} from './commentsTable';

const PAGENAME = 'commentsTracebacks';

const columns: TableColumn<Comment>[] = [
  {
    name: 'Create Date',
    selector: () => 'create_date',
    id: 'column-create_date',
    sortable: true,
    grow: 1,
    cell: (comment: Comment) => (
      <div className="table-cell gray">
        <span>
          {comment.create_date
            ? getClientFormattedDate(comment.create_date, DateFormat.ShortDate)
            : 'TBD'}
        </span>
      </div>
    )
  },
  {
    name: 'Traceback',
    selector: () => 'relatedTracebackId',
    id: 'column-relatedTracebackId',
    sortable: true,
    grow: 2,
    cell: (comment: Comment) =>
      comment.relatedTracebackId && (
        <div className="table-cell blue center">
          <a className="pe-2" href={`/tracebacks/traceback/${comment.relatedTracebackId}`}>
            {comment.relatedTracebackId}
          </a>
        </div>
      )
  },
  {
    name: 'Created By',
    selector: () => 'userName',
    id: 'column-userName',
    sortable: true,
    grow: 1,
    cell: (comment: Comment) => <span className="table-cell gray">{comment.userName}</span>
  },
  {
    name: 'Attachment',
    selector: () => 'isAttchmt',
    id: 'column-isAttchmt',
    sortable: false,
    maxWidth: '1px',
    cell: (comment: Comment) => (
      <div className={'table-cell center'}>
        {comment.attachments &&
          comment.attachments.length > 0 &&
          comment.attachments.map((attachment: Attachment, index: number) => (
            <Fragment key={`${comment.commentId}-attachment-${index}`}>
              <a
                style={{ fontWeight: 'bold' }}
                target="_blank"
                rel="noopener noreferrer"
                href={`/api/attachments/${attachment.attachmentId}`}
              >
                <i className={'fa fa-paperclip'} />
              </a>
            </Fragment>
          ))}
      </div>
    )
  }
];

interface IProps {
  clearCommentsList: Function;
}

const TracebacksComments: React.FC<IProps> = ({ clearCommentsList }) => {
  const { search } = useLocation();
  const searchParams = getFromLocalStorage(PAGENAME) || search || '';
  const savedSearchDetails = decodeQueryParams(PAGENAME, searchParams);
  const filtersFromHistory = setCommentsFiltersFromHistory(savedSearchDetails);
  const [startDate, setStartDate] = useState<string | undefined>(filtersFromHistory.startDate);
  const [endDate, setEndDate] = useState<string | undefined>(filtersFromHistory.endDate);
  const [filterElements, setFilterElements] = useState(
    simplifyFilterElement(getCommentsBasicFilter({ startDate, endDate }, 'traceback'))
  );
  const [pagination, setPagination] = useState(
    savedSearchDetails.paginationParams || {
      ...defaultPagination(),
      sort: 'callDate',
      order: 'desc'
    }
  );

  const handleCheck = (e: any, key: string) => {
    switch (key) {
      case 'startDate':
        setStartDate(getStartOfDayAsString(e, DateFormat.DBDate));
        break;
      case 'endDate':
        setEndDate(getEndOfDayAsString(e, DateFormat.DBDate));
        break;
    }
  };

  const updateFilter = () => {
    setFilterElements(
      simplifyFilterElement(getCommentsBasicFilter({ startDate, endDate }, 'traceback'))
    );
    setPagination({
      ...pagination,
      page: 1
    });
  };

  const clearFilters = () => {
    setStartDate(undefined);
    setEndDate(undefined);
    clearCommentsList();
    saveCommentParams(
      PAGENAME,
      5,
      simplifyFilterElement(getCommentsBasicFilter({}, 'traceback')),
      pagination
    );
  };

  useEffect(() => {
    saveCommentParams(PAGENAME, 5, filterElements, pagination);
  }, [filterElements, pagination]);

  return (
    <Fragment>
      <CardHeader className="card-header-hops">
        <div className="d-flex align-items-center flex-wrap p-0">
          <Col md="2" sm="3" className="pb-2 pb-md-0 me-2">
            <DatePicker
              maxDate={endDate ? new Date(endDate) : new Date()}
              selected={startDate ? new Date(startDate) : undefined}
              onChange={(option) => handleCheck(option, 'startDate')}
              placeholderText="&#xf133; startDate"
              isClearable
            />
          </Col>
          <Col md="2" sm="3" className="pb-2 pb-md-0 me-2">
            <DatePicker
              maxDate={new Date()}
              selected={endDate ? new Date(endDate) : undefined}
              minDate={startDate ? new Date(startDate) : undefined}
              onChange={(option) => handleCheck(option, 'endDate')}
              placeholderText="&#xf133; endDate"
              isClearable
            />
          </Col>
          <Col md="2" className="d-flex justify-content-start pb-2 pb-md-0 me-2">
            <Button color="primary" size="sm" onClick={() => updateFilter()}>
              <i className="fa fa-search pe-2" />
              Search
            </Button>
          </Col>
          <Col md="2" className="ps-1 me-2">
            <button type="button" className="btn btn-link" onClick={clearFilters}>
              Clear Filters
            </button>
          </Col>
        </div>
      </CardHeader>
      <Row>
        <Col>
          <CommentsTable
            columns={columns}
            filterElements={filterElements}
            pagination={pagination}
            setPagination={setPagination}
            expandedComponent={DefaultExpandedComponent}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

const mapActionsToProps = { clearCommentsList };

export default connect(null, mapActionsToProps)(TracebacksComments);
