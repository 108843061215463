import React, { FC, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { CardBody, Col, Form, Label } from 'reactstrap';
import { PersonalInfo } from '../interfaces/user';
import { resetPasswordEmailGenerate } from '../redux/auth/apiCalls';
import { stateMappings } from '../redux/stateMappings';

interface IProps {
  user: PersonalInfo;
}

const SetupForm: FC<IProps> = ({ user }) => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const sendSetupEmail = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await resetPasswordEmailGenerate(user.email);
      if (response.status === 200) {
        navigate('/sent-email');
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error, 'Failed to Setup Mail Account');
    }
  };

  return (
    <CardBody>
      <Form className="form-traceback traceback-add setup-form">
        <div className="text-center">
          <Label className="mb-5 me-3 text-center h1 text-info">Setup your account</Label>
          <div className="d-flex justify-content-center  ">
            <Col xs="6" sm="4" lg="5">
              <div className="form-container">
                {' '}
                You don't have a password set for your account yet !
                <br />
                <br />
                Please follow the instructions we sent you from the Account Setup Email.
                <br />
                <br />* If your passcode expired, click{' '}
                <span
                  style={{ cursor: `${loading ? 'wait' : 'pointer'}`, color: 'blue' }}
                  onClick={sendSetupEmail}
                  className="alert-link"
                >
                  here{' '}
                </span>
                to receive a new one !
              </div>
            </Col>
          </div>
        </div>
      </Form>
    </CardBody>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return {
    user: sm.user
  };
};

export default connect(mapStateToProps)(SetupForm);
