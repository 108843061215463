import { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { userTypes } from '../../enum/userTypes';
import { getZendeskJWT } from '../../redux/auth/apiCalls';
import { stateMappings } from '../../redux/stateMappings';

type IProps = {
  name?: string;
  email?: string;
  role: userTypes;
};

const ZendeskWebWidget: FC<IProps> = ({ name, email, role }) => {
  //   if (email?.includes('-modified')) {
  //     email = email?.replace('-modified', '');
  //   }

  const toggleWidget = (toggle: string) => {
    const windowObject: any = window;
    const zE = windowObject.zE;
    if (zE) {
      zE('webWidget', toggle);
      zE('webWidget', 'prefill', {
        name: {
          value: name
        },
        email: {
          value: email
        }
      });

      zE('webWidget', 'updateSettings', {
        webWidget: {
          color: {
            launcher: '#07325e',
            launcherText: '#FFFFFF',
            button: '#07325e',
            header: '#07325e'
          },
          contactForm: {
            fields: [{ id: 'description', prefill: { '*': window.location.href } }]
          },
          authenticate: {
            jwtFn: (callback: Function) => {
              getZendeskJWT().then((res) => callback(res.data));
            }
          }
        }
      });
    }
  };

  useEffect(() => {
    if (role !== userTypes.Admin) {
      toggleWidget('show');
      return () => {
        toggleWidget('hide');
      };
    }
  }, [role]);

  return null;
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return {
    name: sm.user.name,
    email: sm.user.email,
    role: sm.user.roleType
  };
};

export default connect(mapStateToProps)(ZendeskWebWidget);
