import { NotificationNewComment } from '../../interfaces/comment';
import { Comments } from './actionEnum';

export const getComments = (response: any) => ({
  type: Comments.GET_COMMENTS,
  payload: response
});

export const getComment = (response: any) => ({
  type: Comments.GET_COMMENT,
  payload: response
});

export const addComment = (response: any) => ({
  type: Comments.ADD_COMMENT,
  payload: response
});

export const editComment = (response: any) => ({
  type: Comments.EDIT_COMMENT,
  payload: response
});

export const addAttachments = (response: any) => ({
  type: Comments.ADD_ATTACHMENTS,
  payload: response
});

export const setLoadingStatus = (candidate: boolean) => ({
  type: Comments.SET_COMMENT_LOADING_STATUS,
  payload: candidate
});

export const setErrorMessage = (message: string) => ({
  type: Comments.SET_COMMENT_ERROR_MESSAGE,
  payload: message
});

export const clearComments = () => ({
  type: Comments.CLEAR_COMMENTS
});

export const getNotificationsNewCommentsListAction = (
  notificationsList: NotificationNewComment[]
) => ({
  type: Comments.GET_NEW_COMMENTS_LIST,
  payload: notificationsList
});

export const getHopsNotificationsAction = (notificationsList: NotificationNewComment[]) => ({
  type: Comments.GET_HOPS_NOTIFICATIONS,
  payload: notificationsList
});
