import React, { Fragment, useEffect } from 'react';
import Select from 'react-select';
import { FormGroup, Input, Label } from 'reactstrap';
import { defaultStirShakenErrors } from '../components/modals/StirShakenModal';
import { StirShaken } from '../enum/StirShaken';
import { StirShakenInfo, StirShakenModalErrors } from '../interfaces/stirShaken';
import {
  clearStirShakenValues,
  onlyNumbersAndDash,
  signedStirShakenOptions
} from '../lib/utilities';
import InputError from './inputError';
import InputFormGroup from './inputFormGroup';

interface IProps {
  setStirShakenInfo: Function;
  setStirShakenModalErrors: Function;
  stirShakenInfo: StirShakenInfo;
  stirShakenModalErrors: StirShakenModalErrors;
  forceStirShakenHeader?: boolean;
}

const StirShakenBody: React.FC<IProps> = ({
  setStirShakenInfo,
  stirShakenInfo,
  setStirShakenModalErrors,
  stirShakenModalErrors,
  forceStirShakenHeader
}) => {
  const {
    signedStirShaken,
    stirShakenToken,
    attestationRadio,
    destinationNumber,
    callingNumber,
    timeStamp,
    originationIdentifier,
    isCallSigner
  } = stirShakenInfo;

  useEffect(() => {
    if (forceStirShakenHeader)
      setStirShakenInfo((v: StirShakenInfo) => ({
        ...v,
        signedStirShaken: { label: '', value: 'Y' }
      }));
  }, [forceStirShakenHeader]);

  const handleOnChange = (event: any, key: string) => {
    let tempObj = { ...stirShakenInfo };
    let tempErrorsObj = { ...stirShakenModalErrors };
    switch (key) {
      case StirShaken.stirShakenToken:
        tempObj = { ...tempObj, stirShakenToken: event.target.value.replace(/\s/g, '') };
        tempErrorsObj = { ...tempErrorsObj, stirShakenToken: false };
        if (event.target.value === '') {
          tempErrorsObj = { ...tempErrorsObj, stirShakenToken: true };
        }
        break;
      case StirShaken.signedStirShaken:
        tempObj = {
          ...tempObj,
          signedStirShaken: event,
          ...clearStirShakenValues([
            StirShaken.stirShakenToken,
            StirShaken.attestationRadio,
            StirShaken.destinationNumber,
            StirShaken.callingNumber,
            StirShaken.timeStamp,
            StirShaken.originationIdentifier,
            StirShaken.isCallSigner
          ])
        };
        if (!event) {
          tempErrorsObj = { ...defaultStirShakenErrors, signedStirShaken: true };
        } else {
          tempErrorsObj = { ...defaultStirShakenErrors };
        }
        break;
      case StirShaken.attestationRadio:
        tempObj = { ...tempObj, attestationRadio: event.target.value };
        tempErrorsObj = { ...tempErrorsObj, attestation: false };
        break;
      case StirShaken.destinationNumber:
        if (!Number.parseInt(event.target.value)) {
          tempObj = {
            ...tempObj,
            destinationNumber: ''
          };
          tempErrorsObj = { ...tempErrorsObj, destinationNumber: true };
        } else {
          tempObj = {
            ...tempObj,
            destinationNumber: onlyNumbersAndDash(event.target.value)
          };
          tempErrorsObj = { ...tempErrorsObj, destinationNumber: false };
        }
        break;
      case StirShaken.callingNumber:
        if (!Number.parseInt(event.target.value)) {
          tempObj = {
            ...tempObj,
            callingNumber: ''
          };
          tempErrorsObj = { ...tempErrorsObj, callingNumber: true };
        } else {
          tempObj = {
            ...tempObj,
            callingNumber: onlyNumbersAndDash(event.target.value)
          };
          tempErrorsObj = { ...tempErrorsObj, callingNumber: false };
        }
        break;
      case StirShaken.timeStamp:
        tempObj = {
          ...tempObj,
          timeStamp: onlyNumbersAndDash(event.target.value)
        };
        tempErrorsObj = { ...tempErrorsObj, timeStamp: false };
        break;
      case StirShaken.originationIdentifier:
        tempObj = { ...tempObj, originationIdentifier: event.target.value };
        break;
      case StirShaken.isCallSigner:
        tempObj = { ...tempObj, isCallSigner: !isCallSigner };
        break;
    }
    setStirShakenInfo(tempObj);
    setStirShakenModalErrors(tempErrorsObj);
  };

  return (
    <Fragment>
      {!forceStirShakenHeader && (
        <Fragment>
          <Label className="telecom-label">Was the call signed with Stir Shaken?</Label>
          <Select
            className="pb-3 ps-2 inputStyle"
            value={signedStirShaken}
            onChange={(e) => handleOnChange(e, StirShaken.signedStirShaken)}
            options={signedStirShakenOptions}
            placeholder="Select signed option"
            isClearable
            isSearchable={false}
          />
          {stirShakenModalErrors.signedStirShaken && (
            <InputError className="telecom-input-error">Please select an option !</InputError>
          )}
        </Fragment>
      )}
      {signedStirShaken && (signedStirShaken.value === 'N' || signedStirShaken.value === 'U') && (
        <span>Originating US Voice Service Providers are required to sign all calls</span>
      )}
      {signedStirShaken && signedStirShaken.value === 'Y' && (
        <div style={{ overflowY: 'scroll', maxHeight: '300px' }}>
          <Label className="telecom-label">Encrypted Header</Label>
          <InputFormGroup
            isReadonly={false}
            isTextarea
            inputName="stirShakenToken"
            inputId="stirShakenToken"
            inputClassName="input-hop"
            inputValue={stirShakenToken}
            inputOnChange={(e) => handleOnChange(e, StirShaken.stirShakenToken)}
            inputPlaceholder="Paste full encrypted header here"
            inputAutoComplete="off"
            errorMessage={stirShakenModalErrors.stirShakenToken}
          />
          {stirShakenModalErrors.stirShakenToken && (
            <div>
              <InputError className="telecom-input-error">
                {stirShakenToken === '' ? 'Please input a valid token!' : 'Header is not valid'}
              </InputError>
              <div className="text-break">
                Here is an example of what a valid token looks like:{' '}
                <div className="border border-primary bg-secondary text-light">
                  {' '}
                  {
                    'eyJhbGciOiJFUzI1NiIsInBwdCI6InNoYWtlbiIsInR5cCI6InBhc3Nwb3J0IiwieDV1IjoiaHR0cHM6Ly9zMy5hbWF6b25hd3MuY29tL2NlcnRpZmljYXRlcy5wZWVyaW5naHViLmlvL3l0ZWwvNzAzSi5jcnQifQ.eyJhdHRlc3QiOiJDIiwiZGVzdCI6eyJ0b2342rMTMzNjMxMjI2NTEiXX0sImlhdCI6MTY1ODkzMjU3MSwib3JpZyI6eyJ0biI6IisxOTAzMzM5MTI5OSJ9LCJvcmlnaWQiOiJmYzgzNzdjOC1lNmEwLTRkODAtOWIyZi1kNTY3ZTZiMjNkMzcifQ.ZpX3_Wrxs7kvpZsmjvMdusZVnOA1hwOTd2liwSm1KLJkg-gvDHsSO02HQ4XXMt0aumSS4N06AH8U3LL9Fbn4RA;info=<https://s3.amazonaws.com/certificates.peeringhub.io/ytel/703J.crt>;alg=ES256;ppt=shaken'
                  }
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {signedStirShaken && signedStirShaken.value === 'M' && (
        <FormGroup tag="fieldset" className="d-flex flex-column m-0">
          <Label className="telecom-label">Stir Shaken Manual Entry</Label>
          <FormGroup
            className="d-flex flex-wrap ps-2 align-items-center m-0"
            value={attestationRadio}
            onChange={(e) => handleOnChange(e, StirShaken.attestationRadio)}
          >
            <input
              defaultChecked={attestationRadio === 'A'}
              className="mt-0 me-1"
              name="attestation"
              type="radio"
              id="AAttestation"
              value="A"
            />{' '}
            <Label check className="pe-3">
              A Attestation
            </Label>
            <input
              defaultChecked={attestationRadio === 'B'}
              className="mt-0 me-1"
              name="attestation"
              type="radio"
              id="BAttestation"
              value="B"
            />{' '}
            <Label check className="pe-3">
              B Attestation
            </Label>
            <input
              defaultChecked={attestationRadio === 'C'}
              className="mt-0 me-1"
              name="attestation"
              type="radio"
              id="CAttestation"
              value="C"
            />{' '}
            <Label check className="pe-3">
              C Attestation
            </Label>
          </FormGroup>
          {stirShakenModalErrors.attestation && (
            <InputError className="telecom-input-error">Please provide an attestation !</InputError>
          )}
          <div className="ps-2 m-0 d-flex flex-column align-items-start">
            <div className="d-flex m-1 inputStyle flex-column">
              <Input
                onChange={(e) => handleOnChange(e, StirShaken.destinationNumber)}
                className="comments-search-with-border"
                id="destinationNumber"
                name="destinationNumber"
                value={destinationNumber}
                placeholder="Destination Number"
                type="text"
                invalid={stirShakenModalErrors.destinationNumber}
              />
              {stirShakenModalErrors.destinationNumber && (
                <InputError className="telecom-input-error">
                  Please type a destination number !
                </InputError>
              )}
            </div>
            <div className="d-flex m-1 inputStyle flex-column">
              <Input
                onChange={(e) => handleOnChange(e, StirShaken.callingNumber)}
                className="comments-search-with-border"
                id="callingNumber"
                name="callingNumber"
                value={callingNumber}
                placeholder="Calling Number"
                type="text"
                invalid={stirShakenModalErrors.callingNumber}
              />
              {stirShakenModalErrors.callingNumber && (
                <InputError className="telecom-input-error">
                  Please type a calling number !
                </InputError>
              )}
            </div>
            <div className="d-flex m-1 inputStyle flex-column">
              <Input
                onChange={(e) => handleOnChange(e, StirShaken.timeStamp)}
                className="comments-search-with-border"
                id="timeStamp"
                name="timeStamp"
                value={timeStamp}
                placeholder="2020-03-07 19:55"
                type="text"
                invalid={stirShakenModalErrors.timeStamp}
              />
              {stirShakenModalErrors.timeStamp && (
                <InputError className="telecom-input-error">
                  Please provide a valid date and time (YYYY-MM-DD HH:MM).
                </InputError>
              )}
            </div>
            <div className="d-flex m-1 inputStyle">
              <Input
                onChange={(e) => handleOnChange(e, StirShaken.originationIdentifier)}
                className="comments-search-with-border"
                id="originationIdentifier"
                name="originationIdentifier"
                value={originationIdentifier}
                placeholder="Signed Carrier"
                type="text"
              />
            </div>
          </div>
        </FormGroup>
      )}
      {signedStirShaken && (signedStirShaken.value === 'Y' || signedStirShaken.value === 'M') && (
        <div className="d-flex m-1 ps-4 inputStyle flex-row align-items-center">
          <input
            checked={isCallSigner}
            name="isCallSigner"
            type="checkbox"
            id="isCallSigner"
            className="me-1"
            onChange={(e) => handleOnChange(e, StirShaken.isCallSigner)}
          />
          <Label check>I am the call signer</Label>
        </div>
      )}
    </Fragment>
  );
};

export default StirShakenBody;
