import React, { FC, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { getCronJobsStats } from '../../../redux/appinfo/thunks';
import { ingestYouMailIncidents } from '../../../redux/incident/apiCalls';
import { stateMappings } from '../../../redux/stateMappings';
import { HoverCardContent, HoverCardRoot, HoverCardTrigger } from '../../HoverCard';

export interface CronJobsStats {
  name: string;
  scheduledAt: string;
  startedAt: string;
  endedAt: string;
  lastError: string;
  lastSuccesfulRun: string;
}
interface IProps {
  getCronJobsStats: Function;
  stats: CronJobsStats[];
}

const TriggerIngest: FC<IProps> = ({ getCronJobsStats, stats }) => {
  const [ingestStats, setIngestStats] = useState<CronJobsStats | undefined>(undefined);
  useEffect(() => {
    getCronJobsStats();
  }, []);
  useEffect(() => {
    if (stats) setIngestStats(stats.find((it: CronJobsStats) => it.name === 'YouMailIngest'));
  }, [stats]);
  return (
    <Fragment>
      <HoverCardRoot openDelay={600}>
        <HoverCardTrigger asChild={true}>
          <span className="text-white">
            <Button
              className="btn-default telecom-btn"
              onClick={async () => {
                await ingestYouMailIncidents();
                getCronJobsStats();
              }}
            >
              Pull Latest
            </Button>
          </span>
        </HoverCardTrigger>
        <HoverCardContent sideOffset={5} avoidCollisions collisionPadding={50}>
          {ingestStats ? (
            <div>
              <p className="d-flex justify-content-between">
                <span className="pe-3">ScheduledAt :</span>
                <span>{ingestStats.scheduledAt}</span>
              </p>

              {ingestStats.startedAt && (
                <p className="d-flex justify-content-between">
                  <span>StartedAt :</span>
                  <span>{ingestStats.startedAt}</span>
                </p>
              )}
              {ingestStats.endedAt && (
                <p className="d-flex justify-content-between">
                  <span>EndedAt :</span>
                  <span>{ingestStats.endedAt}</span>
                </p>
              )}

              {ingestStats.lastError && (
                <p className="d-flex justify-content-between">
                  <span>LastError :</span>
                  <span>{ingestStats.lastError}</span>
                </p>
              )}
            </div>
          ) : null}
        </HoverCardContent>
      </HoverCardRoot>
    </Fragment>
  );
};
const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    stats: sm.appinfo.cronJobsStats
  };
};

const mapActionsToProps = {
  getCronJobsStats
};

export default connect(mapStateToProps, mapActionsToProps)(TriggerIngest);
