import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { userTypes } from '../../enum/userTypes';
import { Provider, ProviderNRRow, ProviderOrgRow } from '../../interfaces/provider';
import { PersonalInfo } from '../../interfaces/user';
import { getProvidersNRStatsObject, getProvidersORGStatsObject } from '../../redux/provider/thunks';
import { stateMappings } from '../../redux/stateMappings';
import ProviderPanel from '../ProviderPanel';

interface IProps {
  providerNRStats: Array<ProviderNRRow>;
  providerORGStats: Array<ProviderOrgRow>;
  getProvidersNRStatsObject: Function;
  getProvidersORGStatsObject: Function;
  user: PersonalInfo;
}

const NRProvidersStats: React.FC<IProps> = ({
  providerNRStats,
  providerORGStats,
  getProvidersNRStatsObject,
  getProvidersORGStatsObject,
  user
}) => {
  useEffect(() => {
    getProvidersNRStatsObject();
    getProvidersORGStatsObject();
  }, []);
  return (
    <Fragment>
      <h4>Non Responsive Providers in the last 90 days</h4>
      {providerNRStats && providerNRStats.length > 0 ? (
        <ol className="p-0 m-1">
          {providerNRStats.map((v: ProviderNRRow) => (
            <li key={v.id}>
              <div className="d-flex flex-wrap">
                <ProviderPanel
                  hyperlink={
                    user.roleType === userTypes.GovUser || user.roleType === userTypes.Admin
                  }
                  provider={{
                    ...({} as Provider),
                    providerId: v.id,
                    name: v.name,
                    isDomestic: v.isDomestic,
                    isITGMember: v.isITGMember
                  }}
                />
                <span>{`- ${v.country}`}</span>
                {v.RMDs && v.RMDs.length && (
                  <span className="ms-1">
                    <a
                      className="span-cell ellipses blue me-2"
                      href={`https://fccprod.servicenowservices.com/rmd?id=rmd_form&table=x_g_fmc_rmd_robocall_mitigation_database&sys_id=${v.RMDs[0].sysId}&view=sp`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {v.RMDs[0].number}
                    </a>
                  </span>
                )}
              </div>
            </li>
          ))}
        </ol>
      ) : (
        <h5 className="pt-5">No Data Available</h5>
      )}
      <h4>Missing or Incorrect RMD for Origin in the last 60 days</h4>
      {providerORGStats && providerORGStats.length > 0 ? (
        <ol className="p-0 ">
          {providerORGStats.map((v: ProviderOrgRow) => (
            <li key={v.id}>
              <div className="d-flex flex-wrap">
                <ProviderPanel
                  hyperlink={
                    user.roleType === userTypes.GovUser || user.roleType === userTypes.Admin
                  }
                  provider={{
                    ...({} as Provider),
                    providerId: v.id,
                    name: v.name,
                    isDomestic: v.isDomestic,
                    isITGMember: v.isITGMember
                  }}
                />
                <span>{`- ${v.country}`}</span>
                {v.isIntermediate && <span>/Intermediate Only</span>}
                {v.rmd_number && (
                  <span className="ms-1">
                    <a
                      className="span-cell ellipses blue me-2"
                      href={`https://fccprod.servicenowservices.com/rmd?id=rmd_form&table=x_g_fmc_rmd_robocall_mitigation_database&sys_id=${v.sys_id}&view=sp`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {v.rmd_number}
                    </a>
                  </span>
                )}
              </div>
            </li>
          ))}
        </ol>
      ) : (
        <h5 className="pt-5">No Data Available</h5>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    providerNRStats: sm.provider.providerNRStats,
    providerORGStats: sm.provider.providerORGStats,
    user: sm.user
  };
};

const mapActionsToProps = {
  getProvidersNRStatsObject,
  getProvidersORGStatsObject
};

export default connect(mapStateToProps, mapActionsToProps)(NRProvidersStats);
