export enum Comments {
  GET_COMMENTS = 'GET_COMMENTS',
  GET_COMMENT = 'GET_COMMENT',
  ADD_COMMENT = 'ADD_COMMENT',
  EDIT_COMMENT = 'EDIT_COMMENT',
  ADD_ATTACHMENTS = 'ADD_ATTACHMENTS',
  SET_COMMENT_LOADING_STATUS = 'SET_COMMENT_LOADING_STATUS',
  SET_COMMENT_ERROR_MESSAGE = 'SET_COMMENT_ERROR_MESSAGE',
  CLEAR_COMMENTS = 'CLEAR_COMMENT',
  GET_NEW_COMMENTS_LIST = 'GET_NEW_COMMENTS_LIST',
  GET_HOPS_NOTIFICATIONS = ' GET_HOPS_NOTIFICATIONS'
}
