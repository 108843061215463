import React from 'react';

import { Col } from 'reactstrap';

import AddHopComment, { DirectedValues } from './AddHopComment';
import BasicHopComment from './BasicHopComment';

import { Comment } from '../../interfaces/comment';
import { Hop } from '../../interfaces/hop';

interface IProps {
  hop: Hop;
  hash?: string;
  isCallSourcePopulated: boolean;
  comment: Comment;
  replies: Comment[];
  directedTo: DirectedValues;
  uniqueId?: string;
}

const HopComment: React.FC<IProps> = ({
  hop,
  hash,
  isCallSourcePopulated,
  comment,
  replies,
  directedTo,
  uniqueId
}) => {
  return (
    <div className="d-flex justify-content-center mb-5" key={comment.commentId}>
      <div className="col-10 hop-detail-form pb-3">
        <BasicHopComment
          id={`#${comment.commentId}`}
          hash={hash}
          comment={comment}
          className="mb-1 pb-4"
        />
        <div className="ps-3 pb-3">
          {replies.map((reply) => (
            <BasicHopComment
              id={`#${reply.commentId}`}
              hash={hash}
              comment={reply}
              className="mb-1 pb-1"
              key={'basicHopReply-' + reply.commentId}
            />
          ))}
        </div>
        {directedTo.value !== 'oldUpstream' && (
          <AddHopComment
            hopID={hop.hopId}
            uniqueId={uniqueId}
            isCallSourcePopulated={isCallSourcePopulated}
            replyTo={{
              commentID: comment.commentId,
              hopID: comment.relatedHopId,
              providerName: directedTo.label,
              directedTo: comment.directedTo
            }}
            hopProviderName={hop.hopProvider.name}
            upstreamProviderName={hop.upstreamProvider ? hop.upstreamProvider.name : ''}
            downstreamProviderName={hop.downstreamProvider ? hop.downstreamProvider.name : ''}
          />
        )}
      </div>
    </div>
  );
};

export default HopComment;
