import { Pagination } from '../../interfaces/pagination';
import { FilterElement } from '../../lib/FilterElement';
import { logoutOnAuthError } from '../../lib/apiRequest';
import {
  addMessageAction,
  cancelMessageAction,
  editMessageAction,
  getEmailEvents,
  getMessages,
  reloadDataAction,
  setErrorAction,
  setLoadingAction,
  setMessageAction
} from './actions';
import {
  addMessageApiCall,
  cancelMessageApiCall,
  editMessageApiCall,
  getEmailEventsApiCall,
  getMessageApiCall,
  getMessagesApiCall
} from './apiCalls';

export const addMessage = (data: any) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    const response = await addMessageApiCall(data);

    if (response && response.data) {
      dispatch(addMessageAction(response));
    }
    dispatch(reloadData());
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const getMessageList =
  (params: Pagination, filterElements: FilterElement) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));
    try {
      const messages = await getMessagesApiCall(params, filterElements);
      if (messages && messages.data) {
        dispatch(getMessages(messages.data));
      }
      dispatch(resetReloadData());
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };
export const editMessage = (id: number, data: any) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  try {
    const response = await editMessageApiCall(id, data);
    if (response && response.data) {
      dispatch(editMessageAction(response));
    }

    dispatch(reloadData());
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};
export const cancelMessage =
  (id: number, params: Pagination, filterElements: FilterElement) => async (dispatch: any) => {
    try {
      const response = await cancelMessageApiCall(id);

      if (response) {
        dispatch(cancelMessageAction(response));
        dispatch(getMessageList(params, filterElements));
      }
    } catch (error: any) {
      logoutOnAuthError(error);
    }
  };
export const getMessageById = (id: number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  try {
    const message = await getMessageApiCall(id);
    if (message && message.data) {
      dispatch(setMessageAction(message.data.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};
export const setMessage = (message: any) => async (dispatch: any) => {
  dispatch(setMessageAction(message));
};
export const reloadData = () => async (dispatch: any) => {
  dispatch(reloadDataAction(true));
};

export const resetReloadData = () => async (dispatch: any) => {
  dispatch(reloadDataAction(false));
};

const setStatus = (status: boolean, message: string) => async (dispatch: any) => {
  dispatch(setErrorAction(message));
  dispatch(setLoadingAction(status));
};

export const getEventsList =
  (params: Pagination, filterElements: FilterElement) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));
    try {
      const events = await getEmailEventsApiCall(params, filterElements);
      if (events && events.data) {
        dispatch(getEmailEvents(events.data));
      }
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };
