import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { CardBody, TabContent, TabPane } from 'reactstrap';
import { IncidentCampaignSource, IncidentCampaignTableRow } from '../../../interfaces/incident';
import {
  getIncidentCampaignSourceList,
  getIncidentCampaignSummaryObject
} from '../../../redux/incident/thunks';
import { stateMappings } from '../../../redux/stateMappings';
import IncidentCampaignsSearch from '../Incidents/IncidentCampaignsSearch';
import IncidentSummaries from '../Incidents/IncidentSummaries';
import SourcesNav from '../Incidents/SourcesNav';
import { visibleIncidentSources } from '../Incidents/utilities';

interface IProps {
  incidentCampaignSources: IncidentCampaignSource[];
  incidentCampaign: IncidentCampaignTableRow | null;
  getIncidentCampaignSourceList: Function;
  getIncidentCampaignSummaryObject: Function;
}

const NavIncidents: FC<IProps> = ({
  incidentCampaignSources,
  getIncidentCampaignSourceList,
  incidentCampaign,
  getIncidentCampaignSummaryObject
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeSource, setActiveSource] = useState(0);
  const [selectedIncidentCampaign, setSelectedIncidentCampaign] =
    useState<IncidentCampaignTableRow | null>(incidentCampaign);
  useEffect(() => {
    getIncidentCampaignSourceList();
  }, []);

  useEffect(() => {
    if (incidentCampaign) setSelectedIncidentCampaign({ ...incidentCampaign });
  }, [incidentCampaign]);

  useEffect(() => {
    const [_, source, campaign] = location.hash.split('-');
    const selectedSource = incidentCampaignSources.find((item) => source === item.name);
    if (!source || !selectedSource) {
      // defaults to All
      navigate({ ...location, hash: `#incidents-All` }, { replace: true });
      setActiveSource(0);
      return;
    }
    if (selectedSource) setActiveSource(selectedSource.sourceId);
    if (!campaign) {
      setSelectedIncidentCampaign(null);
      return;
    }

    const incidentCampaignId = Number(campaign);
    if (
      incidentCampaignId &&
      (!selectedIncidentCampaign ||
        selectedIncidentCampaign.incidentCampaignId !== incidentCampaignId)
    ) {
      getIncidentCampaignSummaryObject(incidentCampaignId);
    }
  }, [location, incidentCampaignSources]);

  const handleTabChange = (source: IncidentCampaignSource) => {
    setActiveSource(source.sourceId);
  };

  return (
    <CardBody style={{ padding: 0 }}>
      <div>
        <SourcesNav
          activeSource={activeSource}
          handleTabChange={handleTabChange}
          sources={incidentCampaignSources}
        />
      </div>
      <div className="row-md-10 m-4">
        <TabContent>
          <TabPane>
            {selectedIncidentCampaign ? (
              <IncidentCampaignsSearch selectedIncidentCampaign={selectedIncidentCampaign} />
            ) : (
              <IncidentSummaries
                activeSource={activeSource}
                selectIncidentCampaign={setSelectedIncidentCampaign}
              />
            )}
          </TabPane>
        </TabContent>
      </div>
    </CardBody>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    incidentCampaignSources: sm.incident.incidentCampaignSources || visibleIncidentSources,
    incidentCampaign: sm.incident.incidentCampaignSummary
  };
};

const mapActionsToProps = {
  getIncidentCampaignSourceList,
  getIncidentCampaignSummaryObject
};

export default connect(mapStateToProps, mapActionsToProps)(NavIncidents);
