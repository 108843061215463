import React, { useMemo } from 'react';
import { ActionMeta, OnChangeValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { SelectOption } from './CustomSelect';

interface IProps {
  value?: any;
  placeholder?: string;
  className?: string;
  classNamePrefix?: string;
  validateOption?: (value: any) => boolean;
  onChange: (
    newValue: OnChangeValue<SelectOption, boolean>,
    actionMeta?: ActionMeta<SelectOption>
  ) => void;
}

const CustomCreatable: React.FC<IProps> = ({
  value,
  placeholder,
  className,
  classNamePrefix,
  validateOption: validateOption2,
  onChange
}) => {
  const validateOption = useMemo(() => validateOption2 || (() => true), [validateOption2]);

  const handleCreateOption = (inputValue: any) => {
    if (validateOption(inputValue))
      onChange([
        ...value,
        {
          label: inputValue,
          value: inputValue
        }
      ]);
    else console.log('inputValue (invalid)', inputValue);
  };
  const handleChange = (
    newValue: OnChangeValue<SelectOption, boolean>,
    actionMeta: ActionMeta<SelectOption>
  ) => {
    switch (actionMeta.action) {
      case 'remove-value':
        onChange(newValue);
        break;
      case 'clear':
        onChange(newValue);
        break;
      default:
        console.log('not handled', newValue, actionMeta);
        break;
    }
  };
  return (
    <CreatableSelect
      isClearable
      isMulti
      onCreateOption={handleCreateOption}
      onChange={handleChange}
      value={value}
      placeholder={placeholder || 'Enter values'}
      hideSelectedOptions={true}
      className={className || 'customcreatable'}
      classNamePrefix={classNamePrefix || 'customcreatable'}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null
      }}
      formatCreateLabel={(value) => {
        return 'ID ' + value;
      }}
      styles={{
        placeholder: (base) => ({
          ...base,
          marginLeft: '5px'
        }),
        singleValue: (base) => ({
          ...base,
          marginLeft: '5px'
        })
      }}
    />
  );
};

export default CustomCreatable;
