import { createAsyncThunk } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import { DateFormat } from '../../enum/DateFormat';
import { WithMeta } from '../../interfaces/meta';
import { ReportPostData } from '../../interfaces/report';
import {
  IncidentChangePhoneNumbers,
  PartnerStatsWithTracebackInfo,
  PublishRequestIncident,
  RequestIncident,
  Requestor,
  TracebackRequestIncidents,
  TracebackRequestReject,
  UploadIncident
} from '../../interfaces/request';
import { FiltersAndPage } from '../../lib/FilterElement';
import { ApiRequest, buildParams } from '../../lib/apiRequest';
import { downloadFile } from '../../lib/utilities';

export const searchRequests = createAsyncThunk(
  'requests/search',
  async ({ pagination, filterElements }: FiltersAndPage) => {
    const res = await ApiRequest.request<WithMeta<RequestIncident>>({
      method: 'POST',
      url: `/requests/search?` + buildParams({ ...pagination }),
      data: filterElements
    });
    return res.data;
  },
  {
    serializeError: (x: any) => ({
      name: x.name,
      stack: x.stack,
      code: x.code,
      message: x.response?.data.error || x.message
    })
  }
);

export const createTracebackFromRequests = createAsyncThunk(
  'requests/accept',
  async (incidents: TracebackRequestIncidents) => {
    await ApiRequest.request({
      method: 'POST',
      url: '/requests/accept',
      data: incidents
    });
  }
);

export const rejectRequests = createAsyncThunk(
  'requests/reject',
  async (incidents: TracebackRequestReject) => {
    await ApiRequest.request({
      method: 'POST',
      url: '/requests/reject',
      data: incidents
    });
  }
);

export const getRequestors = createAsyncThunk('requests/requestors', async () => {
  const res = await ApiRequest.request<Array<Requestor>>({
    method: 'GET',
    url: `/requests/requestors`
  });
  return res.data;
});

export const createRequest = createAsyncThunk(
  'requests/create',
  async ({ incident, content }: UploadIncident) => {
    const res = await ApiRequest.request<RequestIncident>({
      method: 'POST',
      url: '/requests/create',
      data: incident
    });
    if (res.data && content.has('file')) {
      const audio = await addAudioApiCall(res.data.id, content);
      return audio.data;
    } else {
      return res.data;
    }
  },
  {
    serializeError: (x: any) => ({
      name: x.name,
      stack: x.stack,
      code: x.code,
      message: x.response?.data.error || x.message
    })
  }
);

export const addAudioApiCall = async (requestId: number, content: FormData) => {
  const res = await ApiRequest.request({
    method: 'POST',
    url: `/UploadAudioFile?requestId=${requestId}`,
    data: content,
    headers: {
      'Content-type': 'multipart/form-data'
    }
  });
  return res.data;
};

export const togglePublish = createAsyncThunk(
  'requests/publish',
  async (publishedIncident: PublishRequestIncident[]) => {
    const res = await ApiRequest.request<string>({
      method: 'POST',
      url: `/requests/publish`,
      data: publishedIncident
    });
    return res.data;
  }
);

export const searchRequestsResults = createAsyncThunk(
  'requests/stats',
  async ({ pagination, filterElements }: FiltersAndPage) => {
    const res = await ApiRequest.request<WithMeta<PartnerStatsWithTracebackInfo>>({
      method: 'POST',
      url: `/requests/stats?` + buildParams({ ...pagination }),
      data: filterElements
    });
    return res.data;
  },
  {
    serializeError: (x: any) => ({
      name: x.name,
      stack: x.stack,
      code: x.code,
      message: x.response?.data.error || x.message
    })
  }
);

export const downloadPartnerReport = createAsyncThunk(
  'downloadPartnerReport',
  async (content: ReportPostData) => {
    const res = await ApiRequest.request({
      method: 'POST',
      url: '/downloadPartnerReport',
      responseType: 'blob',
      headers: { accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
      data: content
    });
    downloadFile(
      res.data,
      content.name
        ? content.name + '.xlxs'
        : res.headers.contentfilename
          ? res.headers.contentfilename
          : 'PartnerReport-' + format(new Date(), DateFormat.Report) + '.xlsx'
    );
  }
);

export const getIncidentRequestsCount = createAsyncThunk('requests/count', async () => {
  const res = await ApiRequest.request<number>({
    method: 'GET',
    url: '/requests/count'
  });
  return res.data;
});

export const checkExistingRequestsApiCall = async (payload: IncidentChangePhoneNumbers[]) =>
  ApiRequest.request({
    method: 'POST',
    url: `/requests/existingRequests`,
    data: payload
  });
