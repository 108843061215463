import { Traceback } from './actionEnum';

export const getTracebacks = (response: any) => ({
  type: Traceback.GET_TRACEBACKS,
  payload: response
});

export const clearTracebacks = () => ({
  type: Traceback.CLEAR_TRACEBACKS
  //payload: undefined
});

export const getTracebackStatsObject = (response: any) => ({
  type: Traceback.GET_TRACEBACK_STATS,
  payload: response
});

export const getTraceback = (response: any) => ({
  type: Traceback.GET_TRACEBACK,
  payload: response
});

export const addTraceback = (response: any) => ({
  type: Traceback.ADD_TRACEBACK,
  payload: response
});

export const editTraceback = (response: any) => ({
  type: Traceback.EDIT_TRACEBACK,
  payload: response
});

export const getAttachments = (response: any) => ({
  type: Traceback.GET_ATTACHMENTS,
  payload: response
});

export const addAttachments = (response: any) => ({
  type: Traceback.ADD_ATTACHMENTS,
  payload: response
});

export const setLoadingStatus = (candidate: boolean) => ({
  type: Traceback.SET_TRACEBACK_LOADING_STATUS,
  payload: candidate
});

export const setErrorMessage = (message: string) => ({
  type: Traceback.SET_TRACEBACK_ERROR_MESSAGE,
  payload: message
});

export const getTracebackStatuses = (response: any) => ({
  type: Traceback.GET_TRACEBACK_STATUSES,
  payload: response
});

export const addRequestor = (response: any) => ({
  type: Traceback.ADD_REQUESTOR,
  payload: response
});

export const getRequestors = (response: any) => ({
  type: Traceback.GET_REQUESTORS,
  payload: response
});
export const getSpreadSheetTracebacksAction = (response: any) => ({
  type: Traceback.GET_SPREADSHEET_TRACEBACKS,
  payload: response
});
