import { CountryName } from 'country-region-data';
import React, { FC, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { RegionDropdown } from 'react-country-region-selector';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import Select from 'react-select';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import { ViewMode } from '../enum/ViewMode';
import { userTypes } from '../enum/userTypes';
import { MemberType } from '../interfaces/memberType';
import {
  Provider,
  ProviderContact,
  ProviderType,
  emptyProvider,
  mapProviderContactToProviderContactPost
} from '../interfaces/provider';
import { RMDEntry } from '../interfaces/rmd';
import { PersonalInfo } from '../interfaces/user';
import { logOut } from '../lib/apiRequest';
import { validEmailRegex } from '../lib/regex';
import { isValidUsername } from '../lib/utilities';
import {
  addProviderContactApiCall,
  addProviderOCNApiCall,
  checkProviderContacts,
  deleteProviderContactApiCall,
  deleteProviderOCNApiCall,
  editProviderContactApiCall,
  getAllProviderNames,
  getProviderContactsApiCall,
  updatePendingProviderApiCall
} from '../redux/provider/apiCalls';
import { stateMappings } from '../redux/stateMappings';
import { checkUserEmailApiCall } from '../redux/user/apiCalls';
import CustomSelect, { SelectOption } from './CustomSelect';
import EmailValidationStatus from './EmailValidationStatus';
import CountryDropdown, { getCountryCodeByName } from './HopDetail/CountryDropdown';
import ProviderEditorForAdmin from './ProviderEditorForAdmin';
import ProviderEditorForUserProvider from './ProviderEditorForUserProvider';
import ProvidersSelect from './ProvidersSelect';
import RmdSearcher from './RmdSearcher';
import TimezoneDropdown from './TimezoneDropdown';
import InputError from './inputError';
import InputFormGroup from './inputFormGroup';
import { useResendEmailModal } from './modals/ConfirmResendEmail';

interface IProps {
  user: PersonalInfo;
  attachments?: any[];
  className?: string;
  isAdd?: boolean;
  viewMode?: ViewMode;
  providerProp: Provider;
  providerTypes: ProviderType[];
  providerServer: Function;
  getProviderObject?: Function;
  approvalComplete: Function;
}

interface Push {
  push: Function;
}

export interface Error {
  name: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  address1: string;
  address2: string;
  city: string;
  region: string;
  zipCode: string;
  country: string;
  providerType: string;
  comment: string;
  isITGMember: string;
  status: string;
  tbEmailFormat: string;
  providerTypeDetail: string;
  additionalContactName: string;
  additionalContactEmail: string;
  additionalContactPhone: string;
  additionalContact: ContactError;
  responseError: string;
  rejectionReason: string;
  existingProvider: string;
  providerTaxId: string;
}

const emptyErrors: Error = {
  name: '',
  contactPhone: '',
  address1: '',
  address2: '',
  city: '',
  contactName: '',
  contactEmail: '',
  region: '',
  zipCode: '',
  country: '',
  providerType: '',
  comment: '',
  isITGMember: '',
  status: '',
  tbEmailFormat: '',
  providerTypeDetail: '',
  additionalContactName: '',
  additionalContactEmail: '',
  additionalContactPhone: '',
  additionalContact: {
    contactId: 0,
    isName: false,
    isEmail: false
  },
  responseError: '',
  rejectionReason: '',
  existingProvider: '',
  providerTaxId: ''
};

interface ContactError {
  contactId: number;
  isName: boolean;
  isEmail: boolean;
}

const sOptions = [
  {
    value: true,
    label: 'Yes'
  },
  {
    value: false,
    label: 'No'
  }
];

const providerTypes = [
  {
    value: 1,
    label: 'US Voice Service Provider - FCC Filer'
  },
  {
    value: 2,
    label: 'US Voice Service Provider - Non-FCC Filer'
  },
  {
    value: 3,
    label: 'US Voice Broadcaster'
  },
  {
    value: 4,
    label: 'US Call Center'
  },
  {
    value: 5,
    label: 'US Lead Generator'
  },
  {
    value: 6,
    label: 'US-Based Other'
  },
  {
    value: 7,
    label: 'Non-US Voice Service Provider - FCC Filer'
  },
  {
    value: 8,
    label: 'Non-US Voice Service Provider - Non-FCC Filer'
  },
  {
    value: 9,
    label: 'Non-US Voice Broadcaster'
  },
  {
    value: 10,
    label: 'Non-US Call Center'
  },
  {
    value: 11,
    label: 'Non-US Lead Generator'
  },
  {
    value: 12,
    label: 'Non-US Other'
  }
];

const memberTypes: MemberType[] = [
  {
    value: false,
    label: 'No'
  },
  {
    value: true,
    label: 'Yes'
  }
];

const TBEmailFormats = [
  {
    value: 3,
    label: 'ITG Member'
  },
  {
    value: 2,
    label: 'Cooperating'
  },
  {
    value: 1,
    label: 'Formal'
  },
  {
    value: 4,
    label: 'Non-responsive'
  }
];

enum AprovalStatus {
  APPROVE = 'APPROVE',
  EXISTS = 'EXISTS',
  OTHER = 'OTHER'
}

interface CheckContactsResponse {
  [key: string]: Provider;
}

const ProviderEditor: FC<IProps> = ({
  user,
  isAdd,
  viewMode,
  providerServer,
  getProviderObject,
  providerProp,
  className,
  approvalComplete
}) => {
  const navigate = useNavigate();
  const contactEmailRef = useRef<HTMLDivElement | null>(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [provider, setProvider] = useState(emptyProvider());
  const [providerContacts, setProviderContacts] = useState<ProviderContact[]>([]);
  const [attachments, setAttachments] = useState<any[]>([]);
  const [error, setError] = useState(emptyErrors);
  const [userAddFailure, setUserAddFailure] = useState({ failure: false, error: '' });
  const [isProviderTypeDetail, setIsProviderTypeDetail] = useState(false);
  const [editables, setEditables] = useState<number[]>([]);
  const [modal, setModal] = useState(false);
  const [ocnModal, setOcnModal] = useState(false);
  const [tenxModal, setTenXModal] = useState(false);
  const [rmdModal, setRmdModal] = useState(false);
  const [comment, setComment] = useState('');
  const [ocn, setOcn] = useState('');
  const [tenXNnid, setTenXNnid] = useState('');
  const [ocnFailure, setOcnFailure] = useState({ failure: false, error: '' });
  const [isOcnAdd, setIsOcnAdd] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState('');
  const [alreadyExistingModal, setAlreadyExistingModal] = useState(false);
  const [similarProviders, setSimilarProviders] = useState<Provider[]>([]);
  const contactsOptions = useMemo<SelectOption[]>(
    () =>
      providerContacts
        .filter((contact: ProviderContact) => !!contact.contactId && contact.active)
        .map((contact: ProviderContact) => ({
          value: contact.email,
          label: contact.email
        })),
    [providerContacts]
  );
  const [addContactMessage, setAddContactMessage] = useState('');

  // check emails on submit
  const [oldUsers, setOldUsers] = useState<CheckContactsResponse>({});
  const [oldUsersModal, setOldUsersModal] = useState(false);
  // check emails on addContact
  const [addOldUserModal, setAddOldUserModal] = useState(false);
  const [addOldContacrt, setAddOldContacrt] = useState<{
    providerId: number;
    contact: ProviderContact;
  }>();

  const [showResendEmailModal, resendEmailModal] = useResendEmailModal();

  const getOcns = (): string => {
    let OCNs = '';
    if (provider.OCNs && provider.OCNs.length > 0) {
      provider.OCNs.map((OCN, index) => {
        if (index != 0) {
          OCNs += ', ';
        }
        OCNs += OCN.OCN;
      });
    } else OCNs = 'None';
    return OCNs;
  };

  useEffect(() => {
    if (user.roleType <= userTypes.Provider) logOut();
  }, []);

  useEffect(() => {
    if (providerProp.providerId) {
      setProvider(providerProp);
      setIsProviderTypeDetail(
        providerProp.providerType === 1 ||
          providerProp.providerType === 7 ||
          providerProp.providerType === 12
      );
      populateProviderContacts();
    }
  }, [providerProp]);

  const populateProviderContacts = () => {
    getProviderContactsApiCall(providerProp.providerId).then((result) => {
      const contactList = result.data.data as ProviderContact[];
      setProviderContacts(contactList || []);
    });
  };

  const toggleAddOldUserModal = () => {
    setAddOldUserModal((v) => !v);
  };
  const checkEmailsOnAddContact = async (providerId: number, contact: ProviderContact) => {
    if (user.roleType === userTypes.Admin) {
      try {
        const { data } = await checkProviderContacts(providerId, [contact.email.toLowerCase()]);
        const old = data as CheckContactsResponse;
        if (
          old &&
          Object.keys(old).length !== 0 &&
          Object.keys(old).find((v) => v === contact.email.toLowerCase())
        ) {
          setOldUsers(old);
          setAddOldUserModal(true);
          setAddOldContacrt({ providerId, contact });
          return true;
        }
      } catch (error) {}
    }
    return addProviderContact(providerId, contact);
  };

  const addProviderContact = (providerId: number, contact: ProviderContact) => {
    addProviderContactApiCall(providerId, mapProviderContactToProviderContactPost(contact))
      .then((result) => {
        let providerContactsAdd = [...providerContacts];
        const addedContactIdx = providerContacts.findIndex(
          (contact: ProviderContact) => contact.isAdded
        );
        setEditables(editables.filter((value: any) => value !== contact.contactId));
        providerContactsAdd.splice(addedContactIdx, 1, result.data.data);
        setProviderContacts(providerContactsAdd);
        setUserAddFailure({ failure: false, error: '' });
        setAddContactMessage(
          `New user request submitted for ${contact.email}. Contact will be displayed once invitation is accepted.`
        );
      })
      .catch((error) => {
        setUserAddFailure({ failure: false, error: 'Email address provided is already in use' });
        setAddContactMessage('');
      });
  };

  const editProviderContact = (providerId: number, contactId: number, contact: ProviderContact) => {
    editProviderContactApiCall(
      providerId,
      contactId,
      mapProviderContactToProviderContactPost(contact)
    )
      .then((result) => {
        const newContact = result.data.data;
        let providerContactsEdit = [...providerContacts];

        setEditables(editables.filter((value) => value !== contact.contactId));

        if (contact.Role === 'providerManager') {
          setProvider({ ...provider, contactName: contact.name, contactPhone: contact.phone });
        }
        providerContactsEdit.filter((value) => value.contactId !== contactId).push(newContact);
        setProviderContacts(providerContactsEdit);
        setUserAddFailure({ failure: false, error: '' });
      })
      .catch((error) => {
        setUserAddFailure({ failure: false, error: 'Edit contact failed!' });
      });
  };

  const deleteProviderContact = (providerId: number, contactId: number) => {
    deleteProviderContactApiCall(providerId, contactId).then(() => {
      setEditables(editables.filter((value) => value !== contactId));
      setProviderContacts(providerContacts.filter((value) => value.contactId !== contactId));
    });
  };

  const toggle = () => {
    setModal((v) => !v);
  };

  const OCNToggle = () => {
    setOcnModal((v) => !v);
    setOcn('');
    setOcnFailure({ failure: false, error: '' });
  };

  const tenxToggle = () => {
    setTenXModal((v) => !v);
    setTenXNnid('');
  };

  const submitOCN = () => {
    if (ocn === '') {
      setOcnFailure({ failure: true, error: 'OCN cannot be empty !' });
    } else {
      isOcnAdd
        ? addProviderOCNApiCall(provider.providerId, ocn)
            .then(() => {
              getProviderObject && getProviderObject(provider.providerId);
              OCNToggle();
            })
            .catch((error) => {
              if (error.response.data.error.includes('OCNInvalidFormatError')) {
                setOcnFailure({ failure: true, error: 'OCN has a bad format !' });
              } else if (error.response.data.error.includes('OCNAlreadyExistsError')) {
                setOcnFailure({ failure: true, error: 'OCN already existent !' });
              } else if (error.response.data.error.includes('OCNOwnershipError')) {
                setOcnFailure({
                  failure: true,
                  error: 'OCN already assigned to a different provider !'
                });
              }
            })
        : deleteProviderOCNApiCall(provider.providerId, ocn)
            .then(() => {
              getProviderObject && getProviderObject(provider.providerId);
              OCNToggle();
            })
            .catch((error) => {
              setOcnFailure({ failure: true, error: 'OCN was not deleted' });
            });
    }
  };

  const deleteOCN = (OCN: any) => {
    deleteProviderOCNApiCall(provider.providerId, OCN)
      .then(() => {
        getProviderObject && getProviderObject(provider.providerId);
      })
      .catch(() => console.error('ERROR: Delete provider OCN failed'));
  };

  const toggleExistingModal = () => {
    setAlreadyExistingModal((v) => !v);
  };

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    switch (e.currentTarget.name) {
      case 'companyName': {
        setError({ ...error, name: '' });
        setProvider({ ...provider, name: value });
        break;
      }
      case 'name': {
        setError({ ...error, contactName: '' });
        setProvider({ ...provider, contactName: value });
        break;
      }
      case 'email': {
        setError({ ...error, contactEmail: '' });
        setProvider({ ...provider, contactEmail: value });
        break;
      }
      case 'phone': {
        setError({ ...error, contactPhone: '' });
        setProvider({ ...provider, contactPhone: value });
        break;
      }
      case 'address1': {
        setError({ ...error, address1: '' });
        setProvider({ ...provider, address1: value });
        break;
      }
      case 'address2': {
        setError({ ...error, address2: '' });
        setProvider({ ...provider, address2: value });
        break;
      }
      case 'city': {
        setError({ ...error, city: '' });
        setProvider({ ...provider, city: value });
        break;
      }
      case 'zipCode': {
        setError({ ...error, zipCode: '' });
        setProvider({ ...provider, zipCode: value });
        break;
      }
      case 'providerTypeDetail': {
        setError({ ...error, providerTypeDetail: '' });
        setProvider({ ...provider, providerTypeDetail: value });
        break;
      }
      case 'providerTaxId': {
        setError({ ...error, providerTaxId: '' });
        setProvider({ ...provider, providerTaxId: value });
        break;
      }
      case 'comment': {
        setError({ ...error, comment: '' });
        setComment(value);
        break;
      }
      default:
        break;
    }
  };

  const setManagerEmailSelect = (option: any) => {
    setError({ ...error, contactEmail: '' });
    setProvider({ ...provider, contactEmail: option.value });
  };

  const additionalContactInfoChanges = (e: any, index: number) => {
    const { value } = e.currentTarget;
    let providerContactsChange = [...providerContacts];
    let errorChange = { ...error };
    switch (e.currentTarget.name) {
      case 'additionalContactName': {
        errorChange.additionalContactName = '';
        errorChange.additionalContact.isName = false;
        providerContactsChange[index].name = value;
        break;
      }
      case 'additionalContactEmail': {
        errorChange.additionalContactEmail = '';
        errorChange.additionalContact.isEmail = false;
        providerContactsChange[index].email = value;
        break;
      }
      case 'additionalContactPhone': {
        errorChange.additionalContactPhone = '';
        providerContactsChange[index].phone = value;
        break;
      }
      default:
        break;
    }
    setProviderContacts(providerContactsChange);
    setError(errorChange);
  };

  const removeFile = (file: any) => {
    setAttachments(attachments.filter((item: any) => item.name !== file.name));
  };

  const addFile = (files: any[]) => {
    const filelist = files.filter(
      (file) => !attachments.map((item: any) => item.name).includes(file.name)
    );
    setAttachments([...attachments, ...filelist]);
  };

  const handleCancelClick = (e: any) => {
    e.preventDefault();
    if (isAdd) {
      navigate('/providers');
    } else {
      window.location.reload();
    }
  };

  const handleRegionChange = (option: any) => {
    setProvider({ ...provider, state: option !== null ? option : '' });
    setError({ ...error, region: '' });
  };

  const handleCountryChange = (option: any) => {
    setProvider({ ...provider, country: option !== null ? option : '' });
    setError({ ...error, country: '' });
  };

  const handleTimezoneChange = (option: any) => {
    setProvider({ ...provider, timeZone: option !== null ? option : '' });
  };

  const handleITGMemberChange = (option: any) => {
    setProvider({ ...provider, isITGMember: option.value });
    setError({ ...error, isITGMember: '' });
  };

  const handleDnoAcces = (option: any) => {
    setProvider({ ...provider, dnoAccess: option.value });
  };

  const handleRejectDispute = (option: any) => {
    setProvider({ ...provider, rejectDispute: option.value });
  };

  const handleActive = (option: any) => {
    setProvider({ ...provider, active: option.value });
  };

  const handleTBEmailFormatChange = (option: any) => {
    setProvider({ ...provider, tbEmailFormat: option.value });
    setError({ ...error, tbEmailFormat: '' });
  };

  const handleProviderTypeChange = (option: any) => {
    setProvider({ ...provider, providerType: option.value });
    setError({ ...error, providerType: '' });
    setIsProviderTypeDetail(option.value === 1 || option.value === 7 || option.value === 12);
  };

  const setAdditionalContactInfo = (option: any, index: number, type: string) => {
    let providerContactsChange = [...providerContacts];
    switch (type) {
      case 'active':
        providerContactsChange[index].active = option.value;
        break;
      case 'tbEmail':
        providerContactsChange[index].isTBEmail = option.value;
        break;
      case 'escalation':
        providerContactsChange[index].isEscalationEmail = option.value;
        break;
      default:
        break;
    }
    setProviderContacts(providerContactsChange);
  };

  const setEditable = (contactId: number, isChecked: boolean) => {
    let providerContactsChange = providerContacts;
    let errorChange = { ...error };
    if (editables.includes(contactId)) {
      if (!isChecked) {
        setEditables(editables.filter((item) => item !== contactId));
        if (contactId === 0) {
          providerContactsChange = providerContacts.filter(
            (contact) => contact.contactId !== contactId
          );
        }
      } else {
        const contact = providerContactsChange.find((contact) => contact.contactId === contactId);
        const contactInfo = contact || { name: '', email: '' };

        errorChange.additionalContact = {
          contactId,
          isName: !contactInfo.name || !isValidUsername(contactInfo.name),
          isEmail: !validEmailRegex.test(contactInfo.email)
        };

        if (!errorChange.additionalContact.isName && !errorChange.additionalContact.isEmail) {
          if (contact) {
            if (contact.isAdded) {
              checkEmailsOnAddContact(provider.providerId, contact);
            } else if (contact.isEdited) {
              editProviderContact(provider.providerId, contact.contactId, contact);
              setEditables(editables.filter((item) => item !== contactId));
            }
          } else {
            setEditables(editables.filter((item) => item !== contactId));
            // TODO is this still needed?
            if (contactId === 0) {
              providerContactsChange[providerContactsChange.length - 1] = {
                ...providerContactsChange[providerContactsChange.length - 1],
                contactId: 100 + providerContactsChange.length
              };
            }
          }
        }
      }
    } else {
      const contact = providerContacts.find((contact) => contact.contactId === contactId);
      if (contact) {
        contact.isEdited = true;
      }
      setEditables([...editables, contactId]);
    }

    setError(errorChange);
    setProviderContacts(providerContactsChange);
  };

  const addEditableProviderContact = () => {
    if (providerContacts.find((contact) => contact.contactId === 0)) {
      setEditables([...editables, 0]);
      return;
    }

    setProviderContacts([
      ...providerContacts,
      {
        active: false,
        contactId: 0,
        create_date: '',
        email: '',
        escalate: 0,
        isAdded: true,
        isEdited: false,
        isEscalationEmail: false,
        isTBEmail: false,
        LockoutEnd: '',
        name: '',
        phone: '',
        providerId: 0,
        report: 0,
        Role: '',
        update_date: '',
        updateFlag: 0,
        Username: ''
      }
    ]);
    setAddContactMessage('');
    setError({
      ...error,
      additionalContact: {
        contactId: 0,
        isName: false,
        isEmail: false
      }
    });
    setEditables([...editables, 0]);
  };

  const handleClickSaveApproval = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!formIsValid()) {
      return;
    }

    updatePendingProviderApiCall(providerProp.providerId, provider).then(
      () => {
        if (provider.status === 2) {
          window.location.href = '/Providers';
        } else {
          approvalComplete();
        }
      },
      (err) => {
        setError({ ...error, responseError: err.message });
      }
    );
  };

  const handleApprovalSelection = (item: AprovalStatus) => {
    setApprovalStatus(item);
    switch (item) {
      case AprovalStatus.APPROVE:
        setError((v) => ({ ...v, existingProvider: '' }));
        setProvider({ ...provider, status: 0, rejectionReason: '', existingId: 0, active: true });
        break;
      case AprovalStatus.EXISTS:
        setError((v) => ({ ...v, existingProvider: '' }));
        setProvider({ ...provider, status: 2, rejectionReason: '', active: false });
        break;
      case AprovalStatus.OTHER:
        setError((v) => ({ ...v, existingProvider: '' }));
        setProvider({ ...provider, status: 2, existingId: 0, active: false });
        break;
    }
  };

  const handleRejectionReasonChange = (e: React.FormEvent<HTMLInputElement>) => {
    setProvider({ ...provider, rejectionReason: e.currentTarget.value });
    setError({ ...error, rejectionReason: '' });
  };

  const handleExistingProviderChange = (option: any) => {
    setProvider({
      ...provider,
      existingId: option.value
    });
    setError({ ...error, existingProvider: '' });
  };

  const handleAddOCN = () => {
    setIsOcnAdd(true);
    setOcnModal(true);
  };

  const handleAddRMD = () => {
    setRmdModal(true);
  };
  const handleAddTenXMapping = () => {
    setTenXModal(true);
  };

  const RMDToggle = () => {
    setRmdModal((v) => !v);
  };

  const setRMD = (rmd: RMDEntry) => {
    if (!provider.RMDs || provider.RMDs.length === 0) {
      setProvider({ ...provider, RMDs: [rmd] });
    } else if (!provider.RMDs.some((item: any) => item.number === rmd.number)) {
      setProvider({ ...provider, RMDs: [...provider.RMDs, rmd] });
    }
    setRmdModal(false);
  };

  const deleteRMD = (rmd: RMDEntry) => {
    if (provider.RMDs) {
      setProvider({
        ...provider,
        RMDs: provider.RMDs.filter((item: any) => item.number !== rmd.number)
      });
    }
  };

  const deleteTenXMapping = (nnid: string) => {
    if (provider.tenXInternationalMapping) {
      setProvider({
        ...provider,
        tenXInternationalMapping: provider.tenXInternationalMapping.filter(
          (item) => item.nnid !== nnid
        )
      });
    }
  };

  const addTenXMapping = () => {
    const tenXMapping = {
      nnid: tenXNnid,
      providerId: 0,
      nn_sp_name: '',
      nn_np_name: '',
      country_name: ''
    };
    if (!provider.tenXInternationalMapping || provider.tenXInternationalMapping.length === 0) {
      setProvider({
        ...provider,
        tenXInternationalMapping: [tenXMapping]
      });
    } else if (!provider.tenXInternationalMapping.some((item) => item.nnid === tenXNnid)) {
      setProvider({
        ...provider,
        tenXInternationalMapping: [...provider.tenXInternationalMapping, tenXMapping]
      });
    }
    setTenXModal(false);
    setTenXNnid('');
  };

  const toggleOldUsersModal = () => {
    setOldUsersModal((v) => !v);
  };
  const checkEmailsOnSubmit = async () => {
    const emails =
      viewMode === ViewMode.APPROVAL && provider.status === 0
        ? [
            provider.contactEmail.toLowerCase(),
            ...providerContacts.map((a) => a.email.toLowerCase())
          ]
        : isAdd
          ? [provider.contactEmail.toLowerCase()]
          : [];
    if (emails.length === 0 || user.roleType !== userTypes.Admin) {
      return false;
    }
    try {
      const { data } = await checkProviderContacts(isAdd ? 0 : provider.providerId, emails);
      const old = data as CheckContactsResponse;
      if (old && Object.keys(old).length !== 0) {
        setOldUsers(old);
        setOldUsersModal(true);
        return true;
      }
    } catch (error) {
      return false;
    }
    return false;
  };

  const checkForExistingProvider = async (e: any, ignoreEmails: boolean = false) => {
    if (e && e.target) e.preventDefault();

    if (!ignoreEmails && (await checkEmailsOnSubmit())) {
      return;
    }
    if (isAdd || provider.status === 0) {
      try {
        const { data } = await getAllProviderNames();
        const providersList = data.data;

        const filteredProvidersByName = providersList.filter((providerElement: any) =>
          providerElement.name.toLowerCase().includes(provider.name.toLowerCase())
        );

        const filteredProvidersByContactEmail = providersList.filter(
          (providerElement: any) =>
            providerElement.contactEmail &&
            providerElement.contactEmail.toLowerCase().includes(provider.contactEmail.toLowerCase())
        );

        if (filteredProvidersByContactEmail && filteredProvidersByContactEmail.length > 0) {
          setIsSubmit(false);
          setError({ ...error, contactEmail: 'Email address provided is already in use' });
          return;
        } else if (filteredProvidersByName && filteredProvidersByName.length > 0) {
          setSimilarProviders(filteredProvidersByName);
          toggleExistingModal();
        } else if (isAdd) {
          submitForm(e);
        } else if (provider.status === 0) {
          handleClickSaveApproval(e);
        }
      } catch (e) {
        setError((v) => ({
          ...v,
          responseError: 'Unable to check existing providers. Try again.'
        }));
      }
    } else {
      handleClickSaveApproval(e);
    }
  };

  const submitForm = async (e: any) => {
    if (e && e.target) e.preventDefault(e.target.value);
    if (!formIsValid()) {
      return;
    }
    if (!isAdd) {
      const { data } = await checkUserEmailApiCall(provider.contactEmail);

      if (data.data) {
        contactEmailRef &&
          contactEmailRef.current &&
          contactEmailRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
          });
        setError({
          ...error,
          contactEmail:
            'User needs to be created before the email can be added to this Manager role'
        });
        setIsSubmit(false);

        return;
      }
    }
    await providerServer(provider, comment, attachments, providerContacts);
  };

  const formIsValid = (): boolean => {
    let errorChange = { ...error };
    if (viewMode === ViewMode.APPROVAL) {
      if (approvalStatus === AprovalStatus.OTHER) {
        if (!provider.rejectionReason || provider.rejectionReason.length === 0) {
          errorChange.rejectionReason = 'Reject reason required when "Reject - Other" is selected.';
          errorChange.existingProvider = '';
          setError(errorChange);
        } else {
          setError({ ...emptyErrors });
        }
        return error.rejectionReason.length === 0;
      } else if (approvalStatus === AprovalStatus.EXISTS) {
        if (!provider.existingId) {
          errorChange.rejectionReason = '';
          errorChange.existingProvider = 'Existing Provider must be selected.';
          setError(errorChange);
        } else {
          setError({ ...emptyErrors });
        }
        return errorChange.existingProvider === '';
      } else if (!approvalStatus) {
        errorChange.existingProvider = 'Please decide if you approve this provider.';
        setError(errorChange);
        return false;
      }
    }

    errorChange.name = !provider.name ? 'The field is required.' : '';

    errorChange.contactName = !provider.contactName ? 'The field is required.' : '';

    errorChange.contactEmail = !validEmailRegex.test(provider.contactEmail)
      ? 'The field is required.'
      : '';

    errorChange.country = !provider.country ? 'The field is required.' : '';

    errorChange.providerTaxId =
      !provider.providerTaxId && user.roleType !== userTypes.Admin ? 'The field is required.' : '';
    if (user.roleType === userTypes.Admin) {
      errorChange.providerType = !provider.providerType ? 'The field is maybe required.' : '';

      errorChange.providerTypeDetail =
        isProviderTypeDetail && !provider.providerTypeDetail ? 'The field is required.' : '';

      errorChange.tbEmailFormat = provider.tbEmailFormat === 0 ? 'The field is required.' : '';
    }

    setError(errorChange);

    const {
      name,
      contactName,
      contactEmail,
      providerType,
      tbEmailFormat,
      providerTypeDetail,
      providerTaxId,
      country
    } = errorChange;

    const isNotValid =
      name ||
      contactName ||
      contactEmail ||
      providerType ||
      providerTaxId ||
      tbEmailFormat ||
      country ||
      (isProviderTypeDetail && providerTypeDetail);

    setIsSubmit(!isNotValid);
    if (approvalStatus === AprovalStatus.APPROVE) {
      if (error.providerType !== '') {
        window.scrollTo({
          top: 500,
          behavior: 'smooth'
        });
      }
    }

    return !isNotValid;
  };

  return (
    <Fragment>
      <Form className={className}>
        <div className="row d-flex flex-wrap justify-content-center m-0 pt-4">
          <Col lg="3" className={viewMode !== ViewMode.VIEW ? 'section-provider ' : '' + 'ms-3'}>
            <h5 className="p-0 traceback-sub-title">Company</h5>
            <Label className="telecom-label">
              Company Name
              {viewMode !== ViewMode.VIEW && user.roleType === userTypes.Admin && (
                <i className="fa fa-asterisk asterisk" />
              )}
            </Label>
            <InputFormGroup
              isReadonly={viewMode === ViewMode.VIEW || user.roleType !== userTypes.Admin}
              inputName="companyName"
              inputId="company-name"
              inputClassName="input-provider"
              inputValue={provider.name}
              inputOnChange={handleInputChange}
              inputPlaceholder=""
              inputAutoComplete="off"
              errorMessage={error.name}
            />
            <h5 className="p-0 traceback-sub-title">Contact</h5>
            <Label className="telecom-label">
              Manager Name
              {viewMode !== ViewMode.VIEW && user.roleType === userTypes.Admin && (
                <i className="fa fa-asterisk asterisk" />
              )}
            </Label>
            <InputFormGroup
              isReadonly={viewMode === ViewMode.VIEW}
              inputName="name"
              inputId="name"
              inputClassName="input-provider"
              inputValue={provider.contactName}
              inputOnChange={handleInputChange}
              inputPlaceholder=""
              inputAutoComplete="off"
              errorMessage={error.contactName}
            />
            <div ref={contactEmailRef} />
            <Label className="telecom-label">
              Manager Email
              {viewMode !== ViewMode.VIEW && user.roleType === userTypes.Admin && (
                <i className="fa fa-asterisk asterisk" />
              )}
            </Label>
            {viewMode === ViewMode.EDIT ? (
              <>
                <CustomSelect
                  className={`customselect-large`}
                  classNamePrefix="customselect"
                  selectedOption={provider.contactEmail}
                  getOptions={contactsOptions}
                  setSelectedOption={setManagerEmailSelect}
                  isSearchable
                  typeLabel=""
                />
                <InputError>{error.contactEmail}</InputError>
              </>
            ) : (
              <InputFormGroup
                isReadonly={viewMode === ViewMode.VIEW}
                inputName="email"
                inputId="email"
                inputClassName="input-provider"
                inputValue={provider.contactEmail}
                inputOnChange={handleInputChange}
                inputPlaceholder=""
                inputAutoComplete="off"
                errorMessage={error.contactEmail}
              />
            )}
            <Label className={`telecom-label ${error.contactEmail ? 'mt-4' : ''}`}>
              Manager Phone
            </Label>
            <InputFormGroup
              isReadonly={viewMode === ViewMode.VIEW}
              inputName="phone"
              inputId="phone"
              inputClassName="input-provider"
              inputValue={provider.contactPhone}
              inputOnChange={handleInputChange}
              inputPlaceholder=""
              inputAutoComplete="off"
              errorMessage={error.contactPhone}
            />
            <h5 className="p-0 traceback-sub-title">Address</h5>
            <Label className="telecom-label">
              Country
              {viewMode !== ViewMode.VIEW && user.roleType === userTypes.Admin && (
                <i className="fa fa-asterisk asterisk" />
              )}
            </Label>
            {viewMode !== ViewMode.VIEW && user.roleType === userTypes.Admin && (
              <FormGroup>
                <CountryDropdown
                  className={'country-dropdown'}
                  name={'hopdetail-country-dropdown'}
                  value={provider.country}
                  setCountry={handleCountryChange}
                  extraOption={false}
                />
              </FormGroup>
            )}
            {(viewMode === ViewMode.VIEW || user.roleType !== userTypes.Admin) && (
              <FormGroup>
                <p className="telecom-text mb-0" style={{ padding: '5px 8px' }}>
                  {provider.country || 'None'}
                </p>
              </FormGroup>
            )}
            {error.country && (
              <InputError className="telecom-input-error">{error.country}</InputError>
            )}
            <Label className="telecom-label">Address 1</Label>
            <InputFormGroup
              isReadonly={viewMode === ViewMode.VIEW || user.roleType !== userTypes.Admin}
              inputName="address1"
              inputId="address1"
              inputClassName="input-provider"
              inputValue={provider.address1}
              inputOnChange={handleInputChange}
              inputPlaceholder=""
              inputAutoComplete="off"
              errorMessage={error.address1}
            />
            <Label className="telecom-label">Address 2</Label>
            <InputFormGroup
              isReadonly={viewMode === ViewMode.VIEW || user.roleType !== userTypes.Admin}
              inputName="address2"
              inputId="address2"
              inputClassName="input-provider"
              inputValue={provider.address2}
              inputOnChange={handleInputChange}
              inputPlaceholder=""
              inputAutoComplete="off"
              errorMessage={error.address2}
            />
            <Label className="telecom-label">City</Label>
            <InputFormGroup
              isReadonly={viewMode === ViewMode.VIEW || user.roleType !== userTypes.Admin}
              inputName="city"
              inputId="city"
              inputClassName="input-provider"
              inputValue={provider.city}
              inputOnChange={handleInputChange}
              inputPlaceholder=""
              inputAutoComplete="off"
              errorMessage={error.city}
            />
            <Label className="telecom-label">State / Region</Label>
            {viewMode !== ViewMode.VIEW && user.roleType === userTypes.Admin && (
              <FormGroup>
                <RegionDropdown
                  country={provider.country}
                  value={provider.state}
                  onChange={handleRegionChange}
                  classes={'region-dropdown'}
                  name={'providereditor-region-dropdown'}
                  customOptions={['Unknown Region']}
                />
              </FormGroup>
            )}
            {(viewMode === ViewMode.VIEW || user.roleType !== userTypes.Admin) && (
              <FormGroup>
                <span className="telecom-text" style={{ padding: '5px 8px' }}>
                  {provider.state || 'None'}
                </span>
              </FormGroup>
            )}
            {error.region && (
              <InputError className="telecom-input-error">{error.region}</InputError>
            )}
            <Label className="telecom-label">Zip / Postal Code</Label>
            <InputFormGroup
              isReadonly={viewMode === ViewMode.VIEW || user.roleType !== userTypes.Admin}
              inputName="zipCode"
              inputId="zip-code"
              inputClassName="input-provider"
              inputValue={provider.zipCode}
              inputOnChange={handleInputChange}
              inputPlaceholder=""
              inputAutoComplete="off"
              errorMessage={error.zipCode}
            />
            <Label className="telecom-label">Time Zone</Label>
            {viewMode !== ViewMode.VIEW ? (
              <FormGroup>
                <TimezoneDropdown
                  countryCode={getCountryCodeByName(provider.country as CountryName)}
                  value={provider.timeZone}
                  setTimezone={handleTimezoneChange}
                />
              </FormGroup>
            ) : (
              <FormGroup>
                <p className="telecom-text mb-0" style={{ padding: '5px 8px' }}>
                  {provider.timeZone || 'None'}
                </p>
              </FormGroup>
            )}
          </Col>
          <Col lg="8" className={viewMode !== ViewMode.VIEW ? 'section-provider' : ''}>
            {!(user.roleType === userTypes.Admin) && (
              <ProviderEditorForUserProvider
                user={user}
                provider={provider}
                viewMode={viewMode}
                providerTypes={providerTypes}
                OCNs={getOcns()}
                handleInputChange={(e) => handleInputChange(e)}
                error={error}
              />
            )}
            {user.roleType === userTypes.Admin && (
              <ProviderEditorForAdmin
                provider={provider}
                viewMode={viewMode}
                providerTypes={providerTypes}
                comment={comment}
                handleProviderTypeChange={handleProviderTypeChange}
                handleInputChange={(e) => handleInputChange(e)}
                deleteOCN={deleteOCN}
                handleAddOCN={handleAddOCN}
                handleAddRMD={handleAddRMD}
                deleteRMD={deleteRMD}
                deleteTenXMapping={deleteTenXMapping}
                handleAddTenXMapping={handleAddTenXMapping}
                handleITGMemberChange={handleITGMemberChange}
                handleDnoAcces={handleDnoAcces}
                handleRejectDispute={handleRejectDispute}
                handleActive={handleActive}
                handleTBEmailFormatChange={handleTBEmailFormatChange}
                removeFile={removeFile}
                addFile={addFile}
                isProviderTypeDetail={isProviderTypeDetail}
                TBEmailFormats={TBEmailFormats}
                attachments={attachments}
                memberTypes={memberTypes}
                isAdd={isAdd}
                error={error}
              />
            )}

            {!isAdd && (
              <Row>
                <Col md="12">
                  <h5 className="p-0 traceback-sub-title">Additional Contacts</h5>
                  <div className="table-responsive additional-contact-table-responsive">
                    <table className="additional-contact-table">
                      <thead className="additional-contact-header">
                        <tr>
                          <td
                            style={
                              viewMode === ViewMode.VIEW ? { minWidth: 150 } : { minWidth: 100 }
                            }
                          >
                            Name
                          </td>
                          <td
                            style={
                              viewMode === ViewMode.VIEW ? { minWidth: 230 } : { minWidth: 160 }
                            }
                          >
                            Email
                          </td>
                          <td
                            style={{
                              minWidth: 60
                            }}
                          >
                            Phone
                          </td>
                          {user.roleType === userTypes.Admin && (
                            <td
                              style={{
                                minWidth: 102
                              }}
                            >
                              Active
                            </td>
                          )}
                          <td
                            style={{
                              minWidth: 102
                            }}
                          >
                            Traceback Emails
                          </td>
                          <td
                            style={{
                              minWidth: 116
                            }}
                          >
                            Escalation Emails
                          </td>
                          <td
                            style={{
                              minWidth: 116
                            }}
                          >
                            Email Validation
                          </td>
                          {viewMode !== ViewMode.VIEW && (
                            <Fragment>
                              <td
                                style={{
                                  minWidth: 50
                                }}
                              >
                                Resend Invitation
                              </td>
                              <td
                                style={{
                                  minWidth: 50
                                }}
                              >
                                Edit
                              </td>
                              <td
                                style={{
                                  minWidth: 50
                                }}
                              >
                                Del
                              </td>
                            </Fragment>
                          )}
                        </tr>
                      </thead>
                      <tbody className={`additional-contact-body`}>
                        {providerContacts &&
                          providerContacts.map((contact, index) => (
                            <tr
                              key={`additional-contact${index}`}
                              className={
                                editables.includes(contact.contactId) ? 'active-additional-row' : ''
                              }
                            >
                              {viewMode === ViewMode.VIEW ? (
                                <Fragment>
                                  <td className={contact.previousProviderId ? `text-red` : ``}>
                                    {contact.name || 'None'}
                                  </td>
                                  <td className={contact.previousProviderId ? `text-red` : ``}>
                                    {contact.email || 'None'}
                                  </td>
                                  <td>{contact.phone || 'None'}</td>
                                  {user.roleType === userTypes.Admin && (
                                    <td>{contact.active ? 'Yes' : 'No'}</td>
                                  )}
                                  <td>{contact.isTBEmail ? 'Yes' : 'No'}</td>
                                  <td>{contact.isEscalationEmail ? 'Yes' : 'No'}</td>
                                  <td>
                                    <EmailValidationStatus
                                      emailValidation={contact.emailValidation}
                                    />
                                  </td>
                                </Fragment>
                              ) : (
                                <Fragment>
                                  {!editables.includes(contact.contactId) && (
                                    <Fragment>
                                      <td>{contact.name || 'None'}</td>
                                      <td>{contact.email || 'None'}</td>
                                      <td>{contact.phone || 'None'}</td>
                                      {user.roleType === userTypes.Admin && (
                                        <td>{contact.active ? 'Yes' : 'No'}</td>
                                      )}
                                      <td>{contact.isTBEmail ? 'Yes' : 'No'}</td>
                                      <td>{contact.isEscalationEmail ? 'Yes' : 'No'}</td>
                                      <td>
                                        <EmailValidationStatus
                                          emailValidation={contact.emailValidation}
                                        />
                                      </td>
                                      <td>
                                        {contact.active && (
                                          <span
                                            onClick={() =>
                                              showResendEmailModal(contact.email, contact.name)
                                            }
                                            className="fa fa-paper-plane"
                                            style={{
                                              color: 'black',
                                              cursor: 'pointer'
                                            }}
                                          />
                                        )}
                                      </td>
                                      <td
                                        onClick={() => {
                                          setEditable(contact.contactId, false);
                                        }}
                                      >
                                        <span
                                          className="fa fa-pencil"
                                          style={{
                                            color: 'black',
                                            cursor: 'pointer'
                                          }}
                                        />
                                      </td>
                                      <td
                                        onClick={() => {
                                          deleteProviderContact(
                                            provider.providerId,
                                            contact.contactId
                                          );
                                        }}
                                      >
                                        <span
                                          className="fa fa-trash"
                                          style={{
                                            color: 'black',
                                            cursor: 'pointer'
                                          }}
                                        />
                                      </td>
                                    </Fragment>
                                  )}
                                  {editables.includes(contact.contactId) && (
                                    <Fragment>
                                      <td>
                                        <Input
                                          value={contact.name}
                                          name="additionalContactName"
                                          id="additional-contact-name"
                                          className={
                                            error.additionalContact.contactId ===
                                              contact.contactId && error.additionalContact.isName
                                              ? 'control-error'
                                              : ''
                                          }
                                          autoComplete="off"
                                          onChange={(e: any) => {
                                            additionalContactInfoChanges(e, index);
                                          }}
                                        />
                                        {error.additionalContact.contactId === contact.contactId &&
                                          error.additionalContact.isName && (
                                            <InputError>Invalid name</InputError>
                                          )}
                                      </td>
                                      <td>
                                        {contact.contactId === 0 ? (
                                          <>
                                            <Input
                                              value={contact.email}
                                              name="additionalContactEmail"
                                              className={
                                                error.additionalContact.contactId ===
                                                  contact.contactId &&
                                                error.additionalContact.isEmail
                                                  ? 'control-error'
                                                  : ''
                                              }
                                              id="additional-contact-email"
                                              autoComplete="off"
                                              onChange={(e: any) =>
                                                additionalContactInfoChanges(e, index)
                                              }
                                            />
                                            {error.additionalContact.contactId ===
                                              contact.contactId &&
                                              error.additionalContact.isEmail && (
                                                <InputError>Invalid email</InputError>
                                              )}
                                          </>
                                        ) : (
                                          <div className="ms-2 me-2">{contact.email || 'None'}</div>
                                        )}
                                      </td>
                                      <td>
                                        <Input
                                          value={contact.phone}
                                          name="additionalContactPhone"
                                          id="additional-contact-phone"
                                          autoComplete="off"
                                          onChange={(e: any) =>
                                            additionalContactInfoChanges(e, index)
                                          }
                                        />
                                      </td>
                                      {user.roleType === userTypes.Admin && (
                                        <td>
                                          <Select
                                            menuPosition="fixed"
                                            className={`customselect-large`}
                                            classNamePrefix="customselect"
                                            value={sOptions.find(
                                              (item) => item.value === contact.active
                                            )}
                                            placeholder=""
                                            onChange={(option: any) =>
                                              setAdditionalContactInfo(option, index, 'active')
                                            }
                                            options={sOptions}
                                            isSearchable={false}
                                          />
                                        </td>
                                      )}
                                      <td>
                                        <Select
                                          menuPosition="fixed"
                                          className={`customselect-large`}
                                          classNamePrefix="customselect"
                                          value={sOptions.find(
                                            (item) => item.value === contact.isTBEmail
                                          )}
                                          placeholder=""
                                          onChange={(option: any) =>
                                            setAdditionalContactInfo(option, index, 'tbEmail')
                                          }
                                          options={sOptions}
                                          isSearchable={false}
                                        />
                                      </td>
                                      <td>
                                        <Select
                                          menuPosition="fixed"
                                          className={`customselect-large`}
                                          classNamePrefix="customselect"
                                          value={sOptions.find(
                                            (item) => item.value === contact.isEscalationEmail
                                          )}
                                          placeholder=""
                                          onChange={(option: any) =>
                                            setAdditionalContactInfo(option, index, 'escalation')
                                          }
                                          options={sOptions}
                                          isSearchable={false}
                                        />
                                      </td>
                                      <td
                                        onClick={() => {
                                          setEditable(contact.contactId, true);
                                        }}
                                      >
                                        <span
                                          className="fa fa-check"
                                          style={{
                                            color: 'green',
                                            cursor: 'pointer'
                                          }}
                                        />
                                      </td>
                                      <td
                                        onClick={() => {
                                          setEditable(contact.contactId, false);
                                        }}
                                      >
                                        <span
                                          className="fa fa-close"
                                          style={{
                                            color: 'red',
                                            cursor: 'pointer'
                                          }}
                                        />
                                      </td>
                                    </Fragment>
                                  )}
                                </Fragment>
                              )}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>{' '}
                  {viewMode !== ViewMode.VIEW && !!addContactMessage.length && (
                    <p>{addContactMessage}</p>
                  )}
                  {viewMode !== ViewMode.VIEW && !editables.includes(0) && (
                    <p className="mb-0" onClick={addEditableProviderContact}>
                      <span className="telecom-text add-contact">+ Add Contact</span>
                    </p>
                  )}
                </Col>
              </Row>
            )}
            {userAddFailure.error && (
              <InputError className="telecom-input-error">{userAddFailure.error}</InputError>
            )}
          </Col>
        </div>
        {(viewMode === ViewMode.EDIT || isAdd) && (
          <div className="d-flex justify-content-center mt-4">
            <Button className="btn-default telecom-btn" onClick={handleCancelClick} color="light">
              Cancel
            </Button>
            <Button
              disabled={isSubmit}
              className="telecom-btn ms-4"
              onClick={(e: any) => {
                if (formIsValid()) {
                  if (isAdd) {
                    checkForExistingProvider(e);
                  } else {
                    submitForm(e);
                  }
                }
              }}
            >
              Save
            </Button>
          </div>
        )}
      </Form>
      {viewMode === ViewMode.APPROVAL && (
        <Fragment>
          <div className="d-flex justify-content-center mt-1">
            <div className="form-check form-check-inline">
              <input
                type="radio"
                value="approve"
                name="approval-choice"
                className="form-check-input"
                checked={approvalStatus === AprovalStatus.APPROVE}
                onChange={() => handleApprovalSelection(AprovalStatus.APPROVE)}
              />
              <label className="form-check-label">Approve</label>
            </div>
            <div className="form-check form-check-inline">
              <input
                type="radio"
                name="approval-choice"
                value="reject-exists"
                className="form-check-input"
                checked={approvalStatus === AprovalStatus.EXISTS}
                onChange={() => handleApprovalSelection(AprovalStatus.EXISTS)}
              />
              <label className="form-check-label">Reject - Provider Already Exists</label>
            </div>
            <div className="form-check form-check-inline">
              <input
                type="radio"
                name="approval-choice"
                value="reject-other"
                className="form-check-input"
                checked={approvalStatus === AprovalStatus.OTHER}
                onChange={() => handleApprovalSelection(AprovalStatus.OTHER)}
              />
              <label className="form-check-label">Reject - Other</label>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-2">
            {approvalStatus === AprovalStatus.OTHER && (
              <Fragment>
                <div className="w-50">
                  <div className="form-group">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control w-50"
                        placeholder="Reason for rejecting"
                        onChange={(e) => {
                          handleRejectionReasonChange(e);
                        }}
                      />
                    </div>
                  </div>
                  {error.rejectionReason && (
                    <div className="d-flex justify-content-center">
                      <InputError className="telecom-input-error">
                        {error.rejectionReason}
                      </InputError>
                    </div>
                  )}
                </div>
              </Fragment>
            )}
            {approvalStatus === AprovalStatus.EXISTS && (
              <Fragment>
                <ProvidersSelect
                  noneSelectedByDefault={true}
                  className={`w-25 ${error.existingProvider ? 'control-error' : ''}`}
                  onChange={(option) => {
                    handleExistingProviderChange(option);
                  }}
                  placeholder="Choose Existing Provider..."
                  isSearchable={true}
                  option={provider.existingId}
                />
              </Fragment>
            )}
          </div>
          {providerContacts.length === 0 && (
            <Fragment>
              <div className="d-flex justify-content-center">
                <InputError className="telecom-input-error">
                  The contact list is empty. Please reject this provider as the email mentioned when
                  creating matches with one from an already existing provider.
                </InputError>
              </div>
              <div className="d-flex justify-content-center">
                <InputError className="telecom-input-error">
                  If you wish to approve it make sure you add a new contact for it and also the
                  manager email is in the contact list.
                </InputError>
              </div>
            </Fragment>
          )}
          {error.existingProvider && error.existingProvider.length > 0 && (
            <div className="d-flex justify-content-center">
              <InputError className="telecom-input-error">{error.existingProvider}</InputError>
            </div>
          )}

          <div className="d-flex justify-content-center mt-2">
            <Button
              className="telecom-btn"
              disabled={isSubmit}
              onClick={(e) => formIsValid() && checkForExistingProvider(e)}
            >
              Save
            </Button>
          </div>
        </Fragment>
      )}

      {error.responseError.length > 0 && (
        <Fragment>
          <div className="d-flex justify-content-center mt-2">
            <div className="alert-no-margin alert-danger w-75">
              <p>{error.responseError}</p>
            </div>
          </div>
        </Fragment>
      )}

      <Modal centered isOpen={modal} toggle={toggle} className="submit-confirm-modal">
        <ModalHeader toggle={toggle}>Leave this page?</ModalHeader>
        <ModalBody>If you leave this page, you will lose any edits you have made.</ModalBody>
        <ModalFooter>
          <Button className="btn-default telecom-btn" color="light" onClick={toggle}>
            Cancel
          </Button>
          <Button className="telecom-btn red" onClick={toggle}>
            Proceed
          </Button>
        </ModalFooter>
      </Modal>
      <Modal centered isOpen={ocnModal} className="OCN-modal" toggle={OCNToggle}>
        <ModalHeader toggle={OCNToggle} />
        <ModalBody>
          {isOcnAdd ? (
            <h5 className="traceback-sub-title">Add A New OCN</h5>
          ) : (
            <h5 className="traceback-sub-title">Delete An OCN</h5>
          )}
          <Col>
            <Label className="telecom-label">
              OCN
              <i className="fa fa-asterisk asterisk" />
            </Label>
            <InputFormGroup
              isReadonly={false}
              inputName="OCN"
              inputId="OCN"
              inputClassName="input-hop"
              inputValue={ocn}
              inputPlaceholder=""
              inputOnChange={(e: any) => {
                let OCN = e.currentTarget.value;
                setOcn(OCN);
              }}
              inputAutoComplete="off"
            />
            {ocnFailure.failure && (
              <InputError className="telecom-input-error">{ocnFailure.error}</InputError>
            )}
          </Col>
        </ModalBody>
        <ModalFooter className="m-auto">
          <div className="d-flex flex-row justify-content-center mt-2">
            <Button className="btn-default telecom-btn" color="light" onClick={OCNToggle}>
              Cancel
            </Button>
            <Button className="telecom-btn red ms-4" onClick={submitOCN}>
              Submit
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal centered className="modal-template" size="lg" isOpen={rmdModal} toggle={RMDToggle}>
        <ModalHeader toggle={RMDToggle}>Add a New RMD</ModalHeader>
        <ModalBody>
          <RmdSearcher setRmd={(rmd) => setRMD(rmd)} addRMDToProvider />
        </ModalBody>
      </Modal>

      <Modal
        centered
        isOpen={alreadyExistingModal}
        className="already-existing-modal"
        toggle={toggleExistingModal}
      >
        <ModalHeader toggle={toggleExistingModal}>Similar provider/s already exist.</ModalHeader>
        <ModalBody>
          There is a list of all similar providers (click on any option in order to see the provider
          in a new tab):
          <ul style={{ margin: '24px auto' }}>
            {similarProviders &&
              React.Children.toArray(
                similarProviders.map((provider) => (
                  <li>
                    <a href={`/providers/provider/${provider.providerId}`} target="_blank">
                      {provider.name}
                    </a>
                  </li>
                ))
              )}
          </ul>
        </ModalBody>
        <ModalFooter style={{ margin: 'auto' }}>
          <div className="d-flex flex-row justify-content-center mt-2">
            <Button className="btn-default telecom-btn" color="light" onClick={toggleExistingModal}>
              Cancel
            </Button>
            <Button
              className="telecom-btn ms-4"
              onClick={(e: any) => {
                toggleExistingModal();
                if (isAdd) {
                  submitForm(e);
                } else if (provider.status === 0) {
                  handleClickSaveApproval(e);
                }
              }}
            >
              Submit anyway
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal centered isOpen={tenxModal} className="OCN-modal" toggle={tenxToggle}>
        <ModalHeader toggle={tenxToggle} />
        <ModalBody>
          <h5 className="traceback-sub-title">Add A New TenX Mapping</h5>
          <Col>
            <Label className="telecom-label">
              Nnid
              <i className="fa fa-asterisk asterisk" />
            </Label>
            <InputFormGroup
              isReadonly={false}
              inputName="nnid"
              inputId="nnid"
              inputClassName="input-hop"
              inputValue={tenXNnid}
              inputPlaceholder=""
              inputOnChange={(e: any) => {
                const value = e.currentTarget.value;
                setTenXNnid(value);
              }}
              inputAutoComplete="off"
            />
          </Col>
        </ModalBody>
        <ModalFooter style={{ margin: 'auto' }}>
          <div className="d-flex flex-row justify-content-center mt-2">
            <Button className="btn-default telecom-btn" color="light" onClick={tenxToggle}>
              Cancel
            </Button>
            <Button className="telecom-btn red ms-4" disabled={!tenXNnid} onClick={addTenXMapping}>
              Submit
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal
        size="lg"
        centered
        isOpen={oldUsersModal}
        toggle={toggleOldUsersModal}
        className="already-existing-modal"
      >
        <ModalHeader toggle={toggleOldUsersModal}>Contacts already exist</ModalHeader>
        <ModalBody>
          The following contacts exist in the Database and are associated with a Provider:
          <ul>
            {Object.keys(oldUsers).map((k) => (
              <li>
                {k} is associated with{' '}
                <a href={`/providers/provider/${oldUsers[k].providerId}`} target="_blank">
                  {oldUsers[k].name}
                </a>{' '}
                {oldUsers[k].status === 2 ? '-Rejected' : '-Inactive'}
              </li>
            ))}
          </ul>
        </ModalBody>
        <ModalFooter>
          <Button className="btn-default telecom-btn" color="light" onClick={toggleOldUsersModal}>
            Cancel
          </Button>
          <Button
            className="telecom-btn red"
            onClick={(e) => {
              toggleOldUsersModal();
              checkForExistingProvider(e, true);
            }}
          >
            Proceed
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        size="lg"
        centered
        isOpen={addOldUserModal}
        toggle={toggleAddOldUserModal}
        className="already-existing-modal"
      >
        <ModalHeader toggle={toggleAddOldUserModal}>Contact already exist</ModalHeader>
        <ModalBody>
          {Object.keys(oldUsers).map((k) => (
            <>
              The contact {k} is associated with{' '}
              <a href={`/providers/provider/${oldUsers[k].providerId}`} target="_blank">
                {oldUsers[k].name}
              </a>{' '}
              {oldUsers[k].status === 2 ? '-Rejected' : '-Inactive'}
            </>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button className="btn-default telecom-btn" color="light" onClick={toggleAddOldUserModal}>
            Cancel
          </Button>
          <Button
            className="telecom-btn red"
            onClick={(e) => {
              toggleAddOldUserModal();
              addOldContacrt &&
                addProviderContact(addOldContacrt.providerId, addOldContacrt.contact);
            }}
          >
            Proceed
          </Button>
        </ModalFooter>
      </Modal>
      {resendEmailModal}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return { user: sm.user };
};

export default connect(mapStateToProps)(ProviderEditor);
