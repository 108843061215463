import { Insight } from './actionEnum';

export const getInsightsAction = (response: any) => ({
  type: Insight.GET_INSIGHTS,
  payload: response
});
export const getInsightsForProviderAction = (response: any) => ({
  type: Insight.GET_INSIGHTS_FOR_PROVIDER,
  payload: response
});
export const getInsightFinalScoreForProviderAction = (response: any) => ({
  type: Insight.GET_INSIGHT_FINAL_SCORE_PROVIDER,
  payload: response
});
export const setLoadingStatus = (status: boolean) => ({
  type: Insight.SET_INSIGHT_LOADING_STATUS,
  payload: status
});
export const setErrorMessage = (message: string) => ({
  type: Insight.SET_INSIGHT_ERROR_MESSAGE,
  payload: message
});
export const getInsightWeightsAction = (response: any) => ({
  type: Insight.GET_INSIGHT_WEIGHTS,
  payload: response
});

export const getHoneyBadgerAction = (response: any) => ({
  type: Insight.GET_HONEYBADGERHQ,
  payload: response
});

export const getInsightsChartFinalScoreAction = (response: any) => ({
  type: Insight.GET_INSIGHTS_CHART_FINAL_SCORE,
  payload: response
});
