export enum Incident {
  GET_INCIDENT = 'GET_INCIDENT',
  GET_INCIDENTS = 'GET_INCIDENTS',
  GET_INCIDENT_SUMMARY = 'GET_INCIDENT_SUMMARY',
  GET_INCIDENT_CAMPAIGN_SOURCES = 'GET_INCIDENT_CAMPAIGN_SOURCES',
  PUT_INCIDENT_CAMPAIGN = 'PUT_INCIDENT_CAMPAIGN',
  TRACEBACK_INCIDENTS = 'TRACEBACK_INCIDENTS',
  POST_INCIDENT_CSV = 'POST_INCIDENT_CSV',
  SET_INCIDENT_LOADING_STATUS = 'SET_INCIDENT_LOADING_STATUS',
  SET_INCIDENT_ERROR_MESSAGE = 'SET_INCIDENT_ERROR_MESSAGE',
  GET_INCIDENT_CAMPAIGN_SUMMARY = 'GET_INCIDENT_CAMPAIGN_SUMMARY',
  GET_CAMPAIGN_NAMES = 'GET_CAMPAIGN_NAMES'
}
