import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import { HoverCardArrow, HoverCardContent, HoverCardRoot, HoverCardTrigger } from './HoverCard';
import ReputationDetails from './stateConverter';

interface IProps {
  className?: any;
  isAdmin?: any;
  detailComponent?: any;
  detail?: any;
  title?: any;
  destination?: any;
  reputation?: any;
  centerContent?: any;
  btnText?: any;
  openHop?: any;
  modify?: any;
  modifyFunction?: any;
  isModifyTooltip?: any;
  tooltipContents?: any;
  handleModifyTooltipBtnClick?: any;
  importTracebackButton?: boolean;
}

const Breadcrumbs: React.FC<IProps> = ({
  className,
  isAdmin,
  detailComponent,
  detail,
  title,
  destination,
  reputation,
  centerContent,
  btnText,
  openHop,
  modify,
  modifyFunction,
  isModifyTooltip,
  tooltipContents,
  handleModifyTooltipBtnClick,
  importTracebackButton
}) => {
  const navigate = useNavigate();
  return (
    <div className={'breadcrumbs ' + className || ''}>
      <div aria-label="breadcrumb" style={{ background: 'none', border: 'none' }}>
        <div className="breadcrumb" style={{ background: 'none', border: 'none' }}>
          <div className="breadcrumb-item-big active">
            {isAdmin && detailComponent ? (
              detailComponent
            ) : (
              <Fragment>
                {!detail && <span className={`breadcrumb-main`}>{title}</span>}
                {detail && (
                  <Fragment>
                    <a className={`breadcrum-sub-main`} href={`${destination}`}>
                      {title}
                    </a>
                    <span className="breadcrum-after">{` / ${detail}`}</span>
                  </Fragment>
                )}
                {reputation && (
                  <span style={{ fontSize: '30px', marginLeft: '5px' }}>
                    <ReputationDetails reputation={reputation} />
                  </span>
                )}
              </Fragment>
            )}
          </div>

          {centerContent && <div>{centerContent}</div>}

          {btnText && (
            <div>
              <Button
                color="primary"
                className="bread-add_category"
                onClick={() => navigate(`/${title.toLowerCase()}/add`)}
              >
                {btnText}
              </Button>
              {importTracebackButton && (
                <Button
                  color="primary"
                  className="bread-add_category ms-2"
                  onClick={() => navigate('/tracebacks/import')}
                >
                  Import Tracebacks
                </Button>
              )}
            </div>
          )}

          {openHop ? (
            <p className="bread-add_category mb-0 ms-4">
              {`Open Hop${isNaN(openHop) ? '' : 's'}: `}
              <span style={{ color: '#0f72b1', fontWeight: 600 }}>{openHop ? openHop : ''}</span>
            </p>
          ) : (
            ''
          )}

          {modify && isAdmin && (
            <HoverCardRoot openDelay={150}>
              <HoverCardTrigger asChild>
                <div className="bread-add_category deactivate-item" onClick={modifyFunction}>
                  {modify}
                </div>
              </HoverCardTrigger>
              {isModifyTooltip && (
                <HoverCardContent sideOffset={5} avoidCollisions className="fs-6">
                  {tooltipContents}
                  <div className="d-flex justify-content-end">
                    <Button
                      color="primary"
                      onClick={handleModifyTooltipBtnClick}
                      style={{ marginRight: '16px' }}
                    >
                      Got it
                    </Button>
                  </div>
                  <HoverCardArrow />
                </HoverCardContent>
              )}
            </HoverCardRoot>
          )}
        </div>
      </div>
    </div>
  );
};

export default Breadcrumbs;
