import React from 'react';

const LoadingIndicator = (props: any) => (
  <div className="telecome-loading">
    <div className={'sk-chase ' + props.className} style={props.style}>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
    </div>
  </div>
);

export default LoadingIndicator;
