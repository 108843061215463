import React, { Dispatch, SetStateAction, useState } from 'react';
import { connect } from 'react-redux';
import { stateMappings } from '../../redux/stateMappings';
import { addRequestorObject } from '../../redux/traceback/thunks';
import CustomPopover, { IExtraProps } from '../CustomPopover';
import { SelectOption } from '../CustomSelect';
import InputError from '../inputError';
import SourceSelect from './SourceSelect';

interface IProps {
  id?: string;
  values: Array<SelectOption>;
  setValues: Dispatch<SetStateAction<Array<SelectOption>>>;
  radioType?: boolean;
  popoverHeaderMessage: string;
  requestors: SelectOption[];
  addRequestorObject: (opt: string, sourceId: number) => void;
}

const RequestorPopover: React.FC<IProps> = ({
  id,
  values,
  setValues,
  radioType,
  popoverHeaderMessage,
  requestors,
  addRequestorObject
}) => {
  const [displayError, setDisplayError] = useState(false);
  return (
    <CustomPopover
      id={id ? id : 'add-requestor'}
      buttonText="Select"
      popoverHeaderMessage={popoverHeaderMessage}
      popoverAddButtonMessage="+ Add new requestor"
      options={requestors}
      setOptions={setValues}
      activeOptions={values}
      addOrRemoveOption={(option: SelectOption) => {
        setValues((v: Array<SelectOption>) =>
          v.find((item) => item.value === option.value)
            ? v.filter((item) => item.value !== option.value)
            : [...v, { value: option.value, label: option.label }]
        );
      }}
      optionServer={(opt, extra) => {
        const sourceId = extra?.value;
        setDisplayError(!sourceId);
        if (!sourceId) return false;
        addRequestorObject(opt, extra?.value);
        return true;
      }}
      radioType={radioType}
      inputPlaceholder="Requestor Name"
      extraInputsComponent={({ value, setValue }: IExtraProps<SelectOption>) => (
        <div className="mb-1">
          <SourceSelect
            value={value || null}
            onChange={(v: any) => {
              setValue(v);
            }}
          />
          {!value && displayError && (
            <InputError className="telecom-input-error">Please provide a source</InputError>
          )}
        </div>
      )}
    />
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    requestors: (sm.traceback.requestors || []).map((requestor: any) => ({
      value: requestor.requestorId,
      label: requestor.name
    }))
  };
};

const mapActionsToProps = { addRequestorObject };

export default connect(mapStateToProps, mapActionsToProps)(RequestorPopover);
