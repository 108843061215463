import {
  getCallingPartyNamesApiCall,
  getCampaignNamesApiCall,
  getClientConfigurationApiCall,
  getCronJobsStatsApiCall,
  getDestinationCarrierOptionsApiCall,
  getOriginatorCarrierOptionsApiCall,
  getOriginatorCNamOptionsApiCall
} from './apiCalls';

import {
  getCallingPartyNames,
  getCampaignNames,
  getClientConfiguration,
  getCronJobsStatsData,
  getDestinationCarrierOptions,
  getOriginatorCarrierOptions,
  getOriginatorCNamOptions,
  setErrorMessage,
  setLoadingStatus
} from './actions';

import { logoutOnAuthError } from '../../lib/apiRequest';

const setStatus = (status: boolean, message: string) => async (dispatch: any) => {
  dispatch(setErrorMessage(message));
  dispatch(setLoadingStatus(status));
};

export const getClientConfigurationInfo = () => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    const response = await getClientConfigurationApiCall();
    if (response && response.data) {
      dispatch(getClientConfiguration(response.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    logoutOnAuthError(error);
    dispatch(setStatus(false, error.message));
  }
};

export const getCampaignNamesList = () => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    const response = await getCampaignNamesApiCall();
    if (response && response.data) {
      dispatch(getCampaignNames(response.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const getCallingPartyNamesList = () => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    const response = await getCallingPartyNamesApiCall();
    if (response && response.data) {
      dispatch(getCallingPartyNames(response.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const getOriginatorCNamOptionsList =
  (incidentCampaignId: number) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    try {
      const response = await getOriginatorCNamOptionsApiCall(incidentCampaignId);
      if (response && response.data) {
        dispatch(getOriginatorCNamOptions(response.data));
      }
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };

export const getOriginatorCarrierOptionsList =
  (incidentCampaignId: number) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    try {
      const response = await getOriginatorCarrierOptionsApiCall(incidentCampaignId);
      if (response && response.data) {
        dispatch(getOriginatorCarrierOptions(response.data));
      }
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };

export const getDestinationCarrierOptionsList =
  (incidentCampaignId: number) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    try {
      const response = await getDestinationCarrierOptionsApiCall(incidentCampaignId);
      if (response && response.data) {
        dispatch(getDestinationCarrierOptions(response.data));
      }
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };

export const getCronJobsStats = () => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    const response = await getCronJobsStatsApiCall();
    if (response && response.data) {
      dispatch(getCronJobsStatsData(response.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};
