import { sub } from 'date-fns';
import { convertToTimeZone } from 'date-fns-timezone';
import React, { useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useLocation } from 'react-router';
import { Card, CardBody, CardHeader, CardTitle, Collapse, Label } from 'reactstrap';
import HomePageProviderTable from '../components/HomePageProviderTable';
import ProvidersSelect from '../components/ProvidersSelect';
import { DateFormat } from '../enum/DateFormat';
import { getEndOfDayAsString, getStartOfDayAsString } from '../lib/utilities';
import { ProviderInfoType } from '../lib/chart/data';

const ProvidersInfo = ({}) => {
  const { pathname } = useLocation();
  const [endDate, setEndDate] = useState<string | undefined | Date>(
    getEndOfDayAsString(new Date(), DateFormat.DBDate)
  );
  const [startDate, setStartDate] = useState<string | undefined | Date>(
    getStartOfDayAsString(sub(new Date(), { days: 90 }), DateFormat.DBDate)
  );
  const providerInfoType = useMemo(() => pathname as ProviderInfoType, [pathname]);
  const [collapseFilters, setCollapseFilters] = useState(false);
  const [chosenProvider, setChosenProvider] = useState(0);

  const toggleFilters = () => {
    setCollapseFilters((v) => !v);
  };

  const handleChange = (e: any, key: string) => {
    switch (key) {
      case 'startDate':
        if (e) {
          let date = new Date(Date.parse(e));
          setStartDate(
            new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0))
          );
        } else setStartDate(e);
        break;
      case 'endDate':
        if (e) {
          let date = new Date(Date.parse(e));
          setEndDate(
            new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59))
          );
        } else setEndDate(e);
        break;
      case 'chosenProvider':
        if (e) setChosenProvider(e.value);
        else setChosenProvider(0);
        break;
    }
  };

  return (
    <Card className="table-card mt-5 mb-5">
      <CardHeader className="card-header-provider mb-5">
        <CardTitle className="mb-5" tag="h4">
          {providerInfoType === ProviderInfoType.NewProvider
            ? 'Providers Added to Portal'
            : providerInfoType === ProviderInfoType.NrProvider
              ? 'Non-Responsive Providers'
              : 'Not-Found Providers'}
        </CardTitle>
        <CardHeader
          className="card-header-filters mb-5"
          onClick={() => toggleFilters()}
          data-type="collapseBanner"
        >
          Filters
          <i
            className={`filters fa-solid ${collapseFilters ? 'fa-arrow-up' : 'fa-arrow-down'}`}
          ></i>
        </CardHeader>
        <Collapse isOpen={collapseFilters}>
          <CardBody>
            <div className="row">
              <div className="fixed-column ps-0">
                <Label className="telecom-label">Start Date</Label>
              </div>
              <div className="fixed-column ps-0">
                <Label className="telecom-label">End Date</Label>
              </div>
              <div className="fixed-column ps-0">
                <Label className="telecom-label">
                  {providerInfoType === ProviderInfoType.NewProvider
                    ? 'First Identified By'
                    : providerInfoType === ProviderInfoType.NrProvider
                      ? 'NR Provider or their Immediate Downstream'
                      : 'NF Provider or their Immediate Downstream'}
                </Label>
              </div>
            </div>
            <div className="row">
              <div className="fixed-column">
                <DatePicker
                  maxDate={endDate ? convertToTimeZone(endDate, { timeZone: 'GMT' }) : new Date()}
                  selected={
                    startDate ? convertToTimeZone(startDate, { timeZone: 'GMT' }) : undefined
                  }
                  onChange={(option) => handleChange(option, 'startDate')}
                  placeholderText="&#xf133; mm/dd/yyyy"
                />
              </div>
              <div className="fixed-column">
                <DatePicker
                  maxDate={new Date()}
                  selected={endDate ? convertToTimeZone(endDate, { timeZone: 'GMT' }) : undefined}
                  minDate={
                    startDate ? convertToTimeZone(startDate, { timeZone: 'GMT' }) : undefined
                  }
                  onChange={(option) => handleChange(option, 'endDate')}
                  placeholderText="&#xf133; mm/dd/yyyy"
                />
              </div>
              <div className="fixed-column">
                <ProvidersSelect
                  onChange={(option) => handleChange(option, 'chosenProvider')}
                  isSearchable
                  isClearable
                  className={'customselect-small'}
                  option={chosenProvider}
                  placeholder="All Providers"
                  includeInactive
                />
              </div>
            </div>
          </CardBody>
        </Collapse>
      </CardHeader>
      <CardBody>
        <HomePageProviderTable
          providerInfoType={providerInfoType}
          homeTable={false}
          pageSize={50}
          startDate={startDate}
          endDate={endDate}
          providerSelected={chosenProvider}
        />
      </CardBody>
    </Card>
  );
};

export default ProvidersInfo;
