export enum TfHops {
  GET_TFHOPS = 'GET_TFHOPS',
  GET_TFHOP = 'GET_TFHOP',
  ADD_TFHOP = 'ADD_TFHOP',
  EDIT_TFHOP = 'EDIT_TFHOP',
  GET_TFHOP_STATUSES = 'GET_TFHOP_STATUSES',
  GET_TFHOP_COMMENTS = 'GET_TFHOP_COMMENTS',
  SET_TFHOP_LOADING_STATUS = 'SET_TFHOP_LOADING_STATUS',
  SET_TFHOP_ERROR_MESSAGE = 'SET_TFHOP_ERROR_MESSAGE',
  GET_TFHOPS_COUNT = 'GET_TFHOPS_COUNT',
  SET_INVALID_TFHOP_ID = 'SET_INVALID_TFHOP_ID'
}
