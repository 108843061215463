import { govFollower } from './actionEnum';

const defaultState: any = {
  govFollowers: null,
  govFollowersSummaries: null,
  govCampaignFollowers: null,
  govCampaignFollowersSummaries: null,
  loading: 0,
  error: ''
};

const reducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case govFollower.GET_ALL_FOLOWERS:
      return {
        ...state,
        govFollowers: action.payload
      };
    case govFollower.GET_ALL_CAMPAIGN_FOLLOWERS:
      return {
        ...state,
        govCampaignFollowers: action.payload
      };
    case govFollower.GET_ALL_FOLOWERS_SUMMARIES:
      return {
        ...state,
        govFollowersSummaries: action.payload
      };
    case govFollower.GET_ALL_CAMPAIGN_FOLOWERS_SUMMARIES:
      return {
        ...state,
        govCampaignFollowersSummaries: action.payload
      };
    case govFollower.SET_HOMEPAGE_LOADING_STATUS:
      if (action.payload) return { ...state, loading: state.loading + 1 };
      else return { ...state, loading: state.loading === 0 ? state.loading : state.loading - 1 };

    case govFollower.SET_HOMEPAGE_ERROR_MESSAGE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
};

export default reducer;
