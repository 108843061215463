import React, { Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import { DateFormat } from '../../enum/DateFormat';
import { Traceforward } from '../../interfaces/traceforward';
import { PersonalInfo } from '../../interfaces/user';
import { getClientFormattedDate, getElapsedTime } from '../../lib/utilities';
import BasisForTraceback from '../HopDetail/BasisForTraceback';
import CommentComponent from '../shared/CommentComponent';

interface IProps {
  traceforward: Traceforward;
  className?: string;
  traceforwardStatuses?: { [key: number]: string };
  user: PersonalInfo;
}

const TraceforwardView: React.FC<IProps> = ({
  traceforward,
  className,
  traceforwardStatuses,
  user
}) => {
  const {
    campaignName,
    traceforwardTime,
    status,
    elapsed,
    numberOfTfHops: numberOfTfHops,
    source
  } = traceforward;
  return (
    <Fragment>
      <div
        className={`traceback-view ${className}`}
        style={{
          marginTop: '15px',
          borderColor: 'transparent',
          background: 'transparent'
        }}
      >
        <Row>
          <Col>
            <div className="label-bold">Status</div>
            <p className="telecom-text " style={{ paddingTop: '5px' }}>
              {traceforwardStatuses && traceforwardStatuses[status]
                ? traceforwardStatuses[status]
                : 'None'}
            </p>
          </Col>
          <Col>
            <div className="label-bold">Campaign Name</div>
            <p className="telecom-text blue" style={{ paddingTop: '5px' }}>
              <a
                style={{ color: '#0f72b1' }}
                href={`/campaigns/campaign/${traceforward.campaignId}`}
              >
                {campaignName}
              </a>
            </p>
          </Col>
          <Col>
            <div className="label-bold">Traceforward Source</div>
            <p className="telecom-text blue" style={{ paddingTop: '5px' }}>
              {source !== '' ? source : 'None'}
            </p>
          </Col>
          <Col>
            <div className="label-bold center">Hops</div>
            <p className="telecom-text blue center" style={{ paddingTop: '5px' }}>
              <a style={{ color: '#0f72b1' }} href={`/tfhops?traceforwardId=${traceforward.id}`}>
                {numberOfTfHops}
              </a>
            </p>
          </Col>
          <div className="col">
            <div className="label-bold center">Elapsed Time</div>
            <p className="telecom-text center" style={{ paddingTop: '5px' }}>
              {getElapsedTime(elapsed)}
            </p>
          </div>
        </Row>
        <div>
          <h5 className="col-md-12 p-0 traceback-sub-title">Call Details</h5>
          <Row>
            <Col md="5">
              <div className="label-bold">Called Number</div>
              <p className="telecom-text " style={{ paddingTop: '5px' }}>
                <span className="fa fa-phone" style={{ color: 'RED', marginRight: '5px' }} />
                {traceforward.calledTN}
              </p>
            </Col>
            <Col md="5">
              <div className="label-bold">Calling Number</div>
              <p className="telecom-text " style={{ paddingTop: '5px' }}>
                <span className="fa fa-phone" style={{ color: 'GREEN', marginRight: '5px' }} />
                {traceforward.callingTN}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md="5">
              <div className="label-bold">Date & Time of Call</div>
              <p className="telecom-text " style={{ paddingTop: '5px' }}>
                {getClientFormattedDate(traceforwardTime, DateFormat.LongBoth)}
              </p>
            </Col>
            <Col md="7">
              <div className="label-bold">Audio URL</div>
              {traceforward.audioUrl ? (
                <Fragment>
                  <audio controls>
                    <source src={traceforward.audioUrl} />
                  </audio>
                  <br />
                  <a
                    href={traceforward.audioUrl}
                    target={'audio'}
                    rel={'noopener noreferrer'}
                    className="telecom-text "
                    style={{ paddingTop: '5px', fontSize: '0.9em' }}
                    type={'audio/mp3'}
                  >
                    {traceforward.audioUrl}
                  </a>
                </Fragment>
              ) : (
                <p className="telecom-text " style={{ paddingTop: '5px' }}>
                  {'None'}
                </p>
              )}
            </Col>
          </Row>
        </div>
        <div>
          <h5 className="col-md-12 p-0 traceback-sub-title">System Comments</h5>
          <CommentComponent input={traceforward.comments} includeSystem />
        </div>
        <div>
          <h5 className="col-md-12 p-0 traceback-sub-title">User Comments + Attachments</h5>
          <CommentComponent input={traceforward.comments} includeUser />
        </div>
        <div>
          <h5 className="col-md-12 p-0 traceback-sub-title">Additional Details</h5>
          <Row>
            <Col md="4">
              <div className="label-bold">Strike Exempt</div>
              <p className="telecom-text " style={{ paddingTop: '5px' }}>
                {traceforward.strikeExempt ? 'Yes' : 'No'}
              </p>
            </Col>
            <Col md="4">
              <div className="label-bold">Traceforward Created</div>
              <p className="telecom-text " style={{ paddingTop: '5px' }}>
                {getClientFormattedDate(traceforward.create_date, DateFormat.MediumBoth)}
              </p>
            </Col>
            <Col md="4">
              <div className="label-bold">Traceforward Created By</div>
              <p className="telecom-text " style={{ paddingTop: '5px' }}>
                {traceforward.createdBy}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <div className="label-bold">Traceforward Requestor</div>
              <p className="telecom-text " style={{ paddingTop: '5px' }}>
                {traceforward.requestors &&
                  traceforward.requestors.map((requestor: any) => requestor.name).join(', ')}
              </p>
            </Col>
            <Col md="4">
              <div className="label-bold">Creator's Provider</div>
              <p className="telecom-text " style={{ paddingTop: '5px' }}>
                {traceforward.creatorProvider || 'None'}
              </p>
            </Col>
            <Col md="4">
              <div className="label-bold">Traceforward No.</div>
              <p className="telecom-text " style={{ paddingTop: '5px' }}>
                {traceforward.id}
              </p>
            </Col>
          </Row>
        </div>
      </div>
      <BasisForTraceback source={traceforward.campaignAttributes} user={user} />
    </Fragment>
  );
};

export default TraceforwardView;
