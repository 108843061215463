//This file holds the API calls that hit the /user route for DRY purposes
import { CancelTokenSource } from 'axios';
import {
  ChartFilterElements,
  ChartFilterElementsForProviderSummary
} from '../../interfaces/charts';
import { Pagination } from '../../interfaces/pagination';
import {
  NewOrNrProviderDetailPayload,
  ProviderContactPost,
  ProviderIpInfoRequest,
  ProviderPost
} from '../../interfaces/provider';
import { FilterElement } from '../../lib/FilterElement';
import { ApiRequest, buildParams, withCancelRequestFunctionality } from '../../lib/apiRequest';

const getProvidersWithCancelApiCalls = withCancelRequestFunctionality();

export const getProvidersListApiCall = async () =>
  ApiRequest.request({
    method: 'GET',
    url: `/providers?PageSize=1000`
  });

export const getProvidersApiCall = async (params: Pagination, filterElements: FilterElement) =>
  getProvidersWithCancelApiCalls((cancelToken: CancelTokenSource) =>
    ApiRequest.request({
      method: 'POST',
      url:
        `/providers/search?` +
        buildParams({
          ...params
        }),
      data: filterElements,
      cancelToken: cancelToken.token
    })
  );

export const getProviderApiCall = async (providerId: number) =>
  ApiRequest.request({
    method: 'GET',
    url: `/provider/${providerId}?`
  });

export const addProviderApiCall = async (content: ProviderPost) =>
  ApiRequest.request({
    method: 'POST',
    url: `/provider`,
    data: {
      ...content
    }
  });

export const editProviderApiCall = async (providerId: number, content: ProviderPost) =>
  ApiRequest.request({
    method: 'PUT',
    url: `/provider/${providerId}`,
    data: {
      ...content
    }
  });

export const updatePendingProviderApiCall = async (providerId: number, content: ProviderPost) =>
  ApiRequest.request({
    method: 'PUT',
    url: `/provider/${providerId}/pending`,
    data: {
      ...content
    }
  });

export const addProviderContactApiCall = async (providerId: number, content: ProviderContactPost) =>
  ApiRequest.request({
    method: 'POST',
    url: `/provider/${providerId}/contacts`,
    data: {
      ...content
    }
  });

export const editProviderContactApiCall = async (
  providerId: number,
  contactId: number,
  content: ProviderContactPost
) =>
  ApiRequest.request({
    method: 'PUT',
    url: `/provider/${providerId}/contact/${contactId}`,
    data: {
      ...content
    }
  });

export const deleteProviderContactApiCall = async (providerId: number, contactId: number) =>
  ApiRequest.request({
    method: 'DELETE',
    url: `/provider/${providerId}/contact/${contactId}`
  });

export const getProviderContactsApiCall = async (providerId: number) =>
  ApiRequest.request({
    method: 'GET',
    url: `/provider/${providerId}/contacts`
  });

export const getProviderTypesApiCall = async () =>
  ApiRequest.request({
    method: 'GET',
    url: `/AppInfo/Enums/ProviderTypes`
  });

export const renotifyProviderApiCall = async (providerId: number) =>
  ApiRequest.request({
    method: 'POST',
    url: `/provider/${providerId}/renotify`
  });

export const getProviderNamesApiCall = async () =>
  ApiRequest.request({
    method: 'GET',
    url: `/AppInfo/System/ProviderNames`
  });

export const deleteProviderApiCall = async (providerId: number) =>
  ApiRequest.request({
    method: 'DELETE',
    url: `/provider/${providerId}`
  });

export const getProviderUpstreamSummaryApiCall = async (
  params: Pagination,
  filterElements: FilterElement,
  providerId: number
) =>
  ApiRequest.request({
    method: 'POST',
    url:
      `/provider/${providerId}/upstream-summary?` +
      buildParams({
        ...params
      }),
    data: filterElements
  });

export const getProviderSummaryApiCall = async (
  filterElements: FilterElement,
  providerId: number
) =>
  ApiRequest.request({
    method: 'POST',
    url: `/provider/${providerId}/summary?`,
    data: filterElements
  });

export const getProviderStatsApiCall = async (
  filterElements: ChartFilterElementsForProviderSummary,
  providerId: number
) =>
  ApiRequest.request({
    method: 'POST',
    url: `/provider/${providerId}/stats?`,
    data: filterElements
  });

export const getAllProvidersStatsApiCall = async (filterElements: ChartFilterElements) =>
  ApiRequest.request({
    method: 'POST',
    url: `/providers/stats`,
    data: filterElements
  });

export const getProviderActionsStatsApiCall = async (
  filterElements: ChartFilterElementsForProviderSummary,
  providerId: number
) =>
  ApiRequest.request({
    method: 'POST',
    url: `provider/${providerId}/providerActionsStats`,
    data: filterElements
  });

export const getAverageDailyTrafficStatsApiCall = async (
  filterElements: ChartFilterElementsForProviderSummary,
  providerId: number
) =>
  ApiRequest.request({
    method: 'POST',
    url: `provider/${providerId}/averageDailyTrafficStats`,
    data: filterElements
  });

export const addProviderOCNApiCall = async (providerId: number, ocn: string) =>
  ApiRequest.request({
    method: 'POST',
    url: `/provider/${providerId}/operating-company-numbers/${ocn}`
  });

export const deleteProviderOCNApiCall = async (providerId: number, ocn: string) =>
  ApiRequest.request({
    method: 'DELETE',
    url: `/provider/${providerId}/operating-company-number/${ocn}`,
    data: {
      ocn,
      providerId
    }
  });

export const resendContactEmailApiCall = async (contactEmail: string) =>
  await ApiRequest.request({
    method: 'POST',
    url: `/account/ResendConfirmation`,
    data: { email: contactEmail }
  });

export const getAllProviderNames = async () =>
  ApiRequest.request({
    method: 'GET',
    url: '/providers/all'
  });

export const getCSVApiCall = async (filterElements: FilterElement, params: Pagination) =>
  ApiRequest.request({
    method: 'POST',
    url:
      `/download-provider-csv?` +
      buildParams({
        ...params
      }),
    data: filterElements,
    responseType: 'blob',
    headers: { accept: 'text/csv' }
  });

export const getProvidersNRStatsApiCall = async () =>
  ApiRequest.request({
    method: 'GET',
    url: '/providers/nrstats'
  });

export const getProvidersORGStatsApiCall = async () =>
  ApiRequest.request({
    method: 'GET',
    url: '/providers/orgrmdstats'
  });

export const getProviderIsNonResponsiveApiCall = async (providerId: number) =>
  ApiRequest.request({
    method: 'GET',
    url: `provider/${providerId}/verifyNrProvider`
  });

export const getNewProvidersInfoApiCall = async (
  request: NewOrNrProviderDetailPayload,
  params: Pagination
) =>
  ApiRequest.request({
    method: 'POST',
    url:
      `/providers/newProviders?` +
      buildParams({
        ...params
      }),
    data: request
  });

export const getNrOrNfProvidersInfoApiCall = async (
  request: NewOrNrProviderDetailPayload,
  params: Pagination
) =>
  ApiRequest.request({
    method: 'POST',
    url:
      `/providers/nrOrNfProviders?` +
      buildParams({
        ...params
      }),
    data: request
  });

export const getProvidersIpInfoApiCall = async (request: ProviderIpInfoRequest) =>
  ApiRequest.request({
    method: 'POST',
    url: `/provider/${request.providerId}/ipInfo`,
    data: request
  });

export const getProvidersStatesApiCall = async () =>
  ApiRequest.request({
    method: 'GET',
    url: `/providers/states`
  });

export const getProviderStirShakenStatsApiCall = async (
  filterElements: ChartFilterElementsForProviderSummary,
  providerId: number
) =>
  ApiRequest.request({
    method: 'POST',
    url: `/provider/${providerId}/stirShakenStats`,
    data: filterElements
  });

export const checkProviderContacts = async (providerId: number, data: string[]) =>
  ApiRequest.request({
    method: 'POST',
    url: `/provider/${providerId}/checkContacts`,
    data
  });

export const getProviderCommentsApiCall = async (providerId: number, onlyGov?: boolean) =>
  ApiRequest.request({
    method: 'GET',
    url: `/provider/${providerId}/comments` + (onlyGov ? '?onlyGov=true' : '')
  });
