import React from 'react';
import { TableColumn } from 'react-data-table-component';
import CustomToolTip from '../../components/CustomToolTip';
import { DateFormat } from '../../enum/DateFormat';
import { userTypes } from '../../enum/userTypes';
import { NrOrNfProviderDetail } from '../../interfaces/provider';
import { PersonalInfo } from '../../interfaces/user';
import { getClientFormattedDate } from '../utilities';

export const nrOrNfProviderColumns = (user: PersonalInfo, isNr: boolean): TableColumn<any>[] => [
  {
    name: isNr ? 'NR provider' : 'NF provider',
    selector: () => 'provider',
    sortable: true,
    grow: 3,
    cell: (provider: NrOrNfProviderDetail) => (
      <div className="table-cell center">
        <a
          className="span-cell blue"
          href={
            user.roleType === userTypes.GovUser
              ? `/providers/provider/summaries/${provider.providerId}`
              : `/providers/provider/${provider.providerId}`
          }
        >
          {provider.provider}
        </a>
      </div>
    )
  },
  {
    name: 'Country',
    selector: () => 'country',
    sortable: false,
    grow: 3,
    cell: (provider: NrOrNfProviderDetail) => (
      <div className="table-cell center">
        {provider.state ? (
          <span>{`${provider.state}, ${provider.country}`}</span>
        ) : (
          <span>{provider.country}</span>
        )}
      </div>
    )
  },
  {
    name: 'Identified RMD',
    selector: () => 'providerRmd',
    sortable: false,
    grow: 3,
    cell: (provider: NrOrNfProviderDetail) => (
      <div className="table-cell center">
        <a
          className="span-cell ellipses blue me-2"
          href={`https://fccprod.servicenowservices.com/rmd?id=rmd_form&table=x_g_fmc_rmd_robocall_mitigation_database&sys_id=${provider.rmdSysId}&view=sp`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {provider.providerRmd}
        </a>
      </div>
    )
  },
  {
    name: 'Last Notification',
    selector: () => 'lastNotitification',
    sortable: false,
    grow: 3,
    cell: (provider: NrOrNfProviderDetail) => (
      <div className="table-cell center">
        <span>{getClientFormattedDate(provider.lastNotitification, DateFormat.ShortDate)}</span>
      </div>
    )
  },
  {
    name: isNr ? 'NR TB' : 'NF TB',
    selector: () => 'nrTracebacks',
    sortable: true,
    grow: 2,
    cell: (provider: NrOrNfProviderDetail) => (
      <div className="table-cell center">
        <span>{provider.nrTracebacks}</span>
      </div>
    )
  },
  {
    name: 'Immediate Downstream Provider',
    selector: () => 'immediateDownstream',
    sortable: true,
    grow: 4,
    cell: (provider: NrOrNfProviderDetail) => (
      <div className="table-cell center">
        <CustomToolTip
          tooltipVisible={isNr && !!(provider.actionTaken && provider.explanation)}
          message={`Recent Action Taken: ${provider.actionTaken} - ${provider.explanation}`}
        >
          <a
            className="span-cell blue"
            href={
              user.roleType === userTypes.GovUser
                ? `/providers/provider/summaries/${provider.immediateId}`
                : `/providers/provider/${provider.immediateId}`
            }
          >
            {provider.immediateDownstream}
          </a>
        </CustomToolTip>
      </div>
    )
  },
  {
    name: 'Note',
    omit: isNr,
    selector: () => 'note',
    sortable: true,
    grow: 4,
    cell: (provider: NrOrNfProviderDetail) => (
      <div className="table-cell center">
        <span>{provider.note}</span>
      </div>
    )
  }
];
