import { Dno } from './actionsEnum';
import { defaultPagination } from '../../interfaces/pagination';

const defaultState = {
  dnos: [],
  dno: null,
  loading: 0,
  error: '',
  meta: defaultPagination()
};

const meta = {
  TotalCount: 0,
  PageSize: 0,
  CurrentPage: 0,
  TotalPages: 1
};

const reducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case Dno.GET_ALL_DNO:
      return {
        ...state,
        dnos: action.payload.data,
        meta: action.payload.paginationMeta || meta
      };
    case Dno.GET_DNO:
      return {
        ...state,
        dno: action.payload.data
      };
    case Dno.SET_DNO:
      return {
        ...state,
        dno: action.payload
      };
    case Dno.SET_DNO_ERROR_MESSAGE:
      return {
        ...state,
        error: action.payload
      };
    case Dno.SET_DNO_LOADING_STATUS:
      if (action.payload) return { ...state, loading: state.loading + 1 };
      else return { ...state, loading: state.loading === 0 ? state.loading : state.loading - 1 };
    case Dno.SEARCH_DNO:
      return {
        ...state,
        dnos: action.payload.data,
        meta: action.payload.paginationMeta || meta
      };
    case Dno.ADD_NEW_DNO:
      return {
        ...state
      };
    default:
      return state;
  }
};

export default reducer;
