import {
  addCampaignApiCall,
  addLabelApiCall,
  addSourceApiCall,
  deleteCampaignApiCall,
  editCampaignApiCall,
  getCampaignApiCall,
  getCampaignLabelsStatsApiCall,
  getCampaignsApiCall,
  getLabelsApiCall,
  getLanguagesCampaignApiCall,
  getSourcesApiCall
} from './apiCalls';

import {
  addCampaign,
  addLabel,
  addSource,
  editCampaign,
  getCampaign,
  getCampaignLabelsStats,
  getCampaigns,
  getLabels,
  getLanguagesCampaignAction,
  getSources,
  setCampaignAction,
  setErrorMessage,
  setLoadingStatus
} from './actions';

import { Campaign } from '../../interfaces/campaign';
import { Pagination } from '../../interfaces/pagination';
import { FilterElement } from '../../lib/FilterElement';
import { logoutOnAuthError } from '../../lib/apiRequest';
import { addCommentObject } from '../comment/thunks';

export const getCampaignList =
  (params: Pagination, filterElements: FilterElement) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));
    try {
      const campaigns = await getCampaignsApiCall(params, filterElements);
      if (campaigns && campaigns.data) {
        dispatch(getCampaigns(campaigns.data));
      }
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };

export const getCampaignObject = (campaignId: number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  try {
    const campaign = await getCampaignApiCall(campaignId);
    if (campaign && campaign.data) {
      dispatch(getCampaign(campaign.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const getCampaignsLabelStatsObject = () => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  try {
    const campaignLabelsStats = await getCampaignLabelsStatsApiCall();
    if (campaignLabelsStats && campaignLabelsStats.data) {
      dispatch(getCampaignLabelsStats(campaignLabelsStats.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
  }
};

export const addCampaignObject =
  (campaign: Campaign, comment: string, attachments: any[], isReload: boolean = true) =>
  async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    try {
      let response = await addCampaignApiCall(campaign);

      if (response && response.data) {
        dispatch(addCampaign(response.data));

        let newCampaignId = response.data.data.campaignId;

        if (comment || attachments.length > 0) {
          addCommentObject(
            {
              commentType: 'campaign',
              contentText: comment,
              relatedObjectId: newCampaignId
            },
            attachments
          ).then(() => {
            dispatch(setStatus(false, ''));

            if (isReload) {
              window.location.href = '/campaigns';
            }
          });
        } else {
          if (isReload) {
            window.location.href = '/campaigns';
          }
        }
      }
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
    }
  };

export const setCampaign = (campaign: any) => async (dispatch: any) => {
  dispatch(setCampaignAction(campaign));
};

export const editCampaignObject =
  (campaign: Campaign, comment: string, attachments: any[], isReload: boolean = true) =>
  async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    try {
      const response = await editCampaignApiCall(campaign);

      if (response && response.data) {
        dispatch(editCampaign(response.data));

        if (comment || attachments.length > 0) {
          addCommentObject(
            {
              commentType: 'campaign',
              contentText: comment,
              relatedObjectId: campaign.campaignId
            },
            attachments
          ).then(() => {
            dispatch(setStatus(false, ''));

            if (isReload) {
              window.location.reload();
            }
          });
        } else {
          if (isReload) {
            window.location.reload();
          }
        }
      } else {
        throw new Error('editCampaignApiCall failed');
      }
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };

export const getSourceList = () => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    const response = await getSourcesApiCall();
    if (response && response.data) {
      dispatch(getSources(response.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const getLabelList = () => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  try {
    const response = await getLabelsApiCall();
    if (response && response.data) {
      dispatch(getLabels(response.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const addSourceObject = (sourceName: string) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    let response = await addSourceApiCall(sourceName);
    if (response && response.data) {
      dispatch(addSource(response.data));
    }
    response = await getSourcesApiCall();
    if (response && response.data) {
      dispatch(getSources(response.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const addLabelObject = (labelName: string, fraudScore: number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    let response = await addLabelApiCall(labelName, fraudScore);
    if (response && response.data) {
      dispatch(addLabel(response.data));
    }
    response = await getLabelsApiCall();
    if (response && response.data) {
      dispatch(getLabels(response.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

const setStatus = (status: boolean, message: string) => async (dispatch: any) => {
  dispatch(setErrorMessage(message));
  dispatch(setLoadingStatus(status));
};

export const deleteCampaign = (campaignId: number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    await deleteCampaignApiCall(campaignId);
    dispatch(setStatus(false, ''));
    window.location.href = '/Campaigns';
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const getCampaignLanguages = () => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  try {
    const response = await getLanguagesCampaignApiCall();
    if (response && response.data) dispatch(getLanguagesCampaignAction(response.data));
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};
