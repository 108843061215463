import React from 'react';
import { TableColumn } from 'react-data-table-component';
import { InsightFinalScoreResult } from '../../../interfaces/insights';

const roundTo = (n: number | undefined, digits: number) => {
  if (!n) return;
  if (!digits) digits = 0;
  const negative = n < 0;
  if (negative) n = n * -1;
  var multiplicator = Math.pow(10, digits);
  n = parseFloat((n * multiplicator).toFixed(11));
  n = Number((Math.round(n) / multiplicator).toFixed(digits));
  if (negative) n = Number((n * -1).toFixed(digits));
  return n;
};

export const quarterValueMapper = (quarterValue: number) => {
  if (quarterValue <= 1 && quarterValue >= 0.8) return 1;
  else if (quarterValue < 0.8 && quarterValue >= 0.6) return 2;
  else if (quarterValue < 0.6 && quarterValue >= 0.4) return 3;
  else if (quarterValue < 0.4 && quarterValue >= 0.2) return 4;
  else if (quarterValue < 0.2) return 5;
  return 0;
};

export const columns = (columnNames: string[]): TableColumn<InsightFinalScoreResult>[] => {
  console.log(columnNames);

  return [
    {
      name: '',
      width: '20px',
      cell: () => <div />
    },
    {
      name: 'Provider',
      selector: () => 'd.id',
      sortable: true,
      grow: 3,
      cell: (row: InsightFinalScoreResult) => (
        <div className="table-cell overflow-hidden">
          <a
            href={`/providers/provider/insights/${row.providerID}`}
            className="span-cell ellipses blue ms-2"
            title={row.providerName}
          >
            {row.providerName}
          </a>
        </div>
      )
    },
    ...columnNames
      .sort((a: string, b: string) => {
        return (
          a.split('_')[1] === b.split('_')[1]
            ? a.split('_')[0] > b.split('_')[0]
            : a.split('_')[1] > b.split('_')[1]
        )
          ? -1
          : 1;
      })
      .map((v) => ({
        name: v.replace('_', ' '),
        selector: () => 'd.' + v,
        sortable: true,
        cell: (row: InsightFinalScoreResult) => {
          const value = row.quarters.find((r) => r.name === v)?.value;
          if (!value) return;
          return (
            <div className="table-cell text-center">
              <span>{roundTo(value, 5)}</span>
              <br />
              <span className="font-calibri-bold">{quarterValueMapper(value)}</span>
            </div>
          );
        }
      }))
  ];
};
