import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, CardBody, Col, Input, Label, Row } from 'reactstrap';
import { ConfigurationSettings } from '../../../interfaces/configuration';
import { getConfiguration, updateConfiguration } from '../../../redux/configuration/thunks';
import { addInsightWeightApiCall } from '../../../redux/insights/apiCalls';
import { getInsightWeights } from '../../../redux/insights/thunks';
import { stateMappings } from '../../../redux/stateMappings';
import InputFormGroup from '../../inputFormGroup';

interface IProps {
  insightWeights: any;
  getInsightWeights: Function;
  configuration: ConfigurationSettings[];
  getConfiguration: Function;
  updateConfiguration: Function;
}

const InsightsSettings: React.FC<IProps> = ({
  insightWeights,
  getInsightWeights,
  getConfiguration,
  updateConfiguration,
  configuration
}) => {
  const [weights, setWeights] = useState(
    Object.entries<number>(insightWeights).map(([key, value]) => ({ key, value: String(value) }))
  );
  const [insightVisible, setInsightVisible] = useState(false);
  const [explanation, setexplanation] = useState('');

  useEffect(() => {
    getInsightWeights();
    getConfiguration();
  }, []);

  useEffect(() => {
    setWeights(
      Object.entries<number>(insightWeights).map(([key, value]) => ({ key, value: String(value) }))
    );
    setexplanation('');
  }, [insightWeights]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>, weight: string) => {
    e.preventDefault();
    const valueStr = e.target.value;
    setWeights((v) => {
      const idx = v.findIndex(({ key }) => key === weight);
      v[idx].value = valueStr;
      return [...v];
    });
  };

  useEffect(() => {
    configuration.forEach((config) => {
      switch (config.key) {
        case 'INSIGHT_VISIBLE':
          setInsightVisible(config.value === 'true');
          break;
      }
    });
  }, [configuration]);

  const handleCheck = () => {
    updateConfiguration({
      key: 'INSIGHT_VISIBLE',
      value: String(!insightVisible)
    } as ConfigurationSettings);
    setInsightVisible((v) => !v);
  };

  const handleSave = () => {
    if (weights.some(({ value }) => !Number.isFinite(Number(value)) || value === '')) return;
    if (explanation.length == 0) return;

    addInsightWeightApiCall({
      explanation,
      ...Object.fromEntries(weights.map(({ key, value }) => [key, Number(value)]))
    })
      .then(() => {
        getInsightWeights();
      })
      .catch((reason) => console.log(reason));
  };

  return (
    <Fragment>
      <Label className="pt-3 traceback-heading">Insights Controls</Label>
      <CardBody>
        <div className="row">
          <div className="col">
            <Label className="telecom-label ps-0">Providers Insights Visible</Label>{' '}
          </div>
          <div className="col">
            <label className="form-label tile-label" style={{ fontSize: '15px' }}>
              Yes
              <input type="checkbox" onChange={() => handleCheck()} checked={insightVisible} />
              <span className="checkmark tile-checkmark" />
            </label>
          </div>
        </div>
        <h5 className="traceback-sub-title p-0 m-0">Weights</h5>
        <div className="ms-3 me-3">
          {weights.map(({ key, value }) => (
            <Row key={key}>
              <Col>
                <Label className="telecom-label ps-0"> {key}</Label>
              </Col>
              <Col>
                <Input
                  onChange={(e) => handleOnChange(e, key)}
                  className="comments-search-with-border"
                  id={key}
                  name={key}
                  value={value}
                  type="number"
                />
              </Col>
            </Row>
          ))}
          <Row>
            <Col>
              <Label className="telecom-label ps-0">Explanation</Label>
            </Col>
            <Col>
              <InputFormGroup
                inputName="explanation"
                inputId="explanation"
                inputValue={explanation}
                inputOnChange={(e) => setexplanation(e.currentTarget.value)}
                inputPlaceholder="Explanation"
                inputAutoComplete="off"
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-center">
            <Button color="primary" disabled={explanation.length == 0} onClick={() => handleSave()}>
              Save
            </Button>
          </div>
        </div>
      </CardBody>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    insightWeights: sm.insight.weights || {},
    configuration: sm.configuration.configuration
  };
};

const mapActionsToProps = {
  getInsightWeights,
  getConfiguration,
  updateConfiguration
};

export default connect(mapStateToProps, mapActionsToProps)(InsightsSettings);
