import { format, subDays } from 'date-fns';
import { convertToTimeZone } from 'date-fns-timezone';
import React, { Fragment, useState } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { Button, Col, Label } from 'reactstrap';
import { ReportFormat, ReportPostData, ReportType } from '../../../../interfaces/report';
import { getReportObject } from '../../../../redux/report/thunks';
import { SelectOption } from '../../../CustomSelect';
import LabelSelect from '../../../shared/LabelSelect';

interface IProps {
  getReportObject: Function;
}
const SixtyDaysReport: React.FC<IProps> = ({ getReportObject }) => {
  const [startDate, setStartDate] = useState(
    new Date(format(subDays(new Date(), 1), 'yyyy-MM-dd'))
  );
  const [chosenLabels, setChosenLabels] = useState<number[]>([]);

  const handleSelectLabels = (value: any) => {
    setChosenLabels(value ? value.map((item: SelectOption) => item.value) : []);
  };

  const handleCheck = (e: any, key: string) => {
    switch (key) {
      case 'startDate':
        if (e) {
          let date = new Date(Date.parse(e));
          setStartDate(
            new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0))
          );
        } else {
          setStartDate(e);
        }
        break;
      case 'excel':
        getReportObject(
          {
            startDate: startDate.toISOString(),
            chosenLabels
          } as ReportPostData,
          ReportType.SixtyDaysReport,
          ReportFormat.Excel
        );
        break;
    }
  };
  return (
    <Fragment>
      <Label className="traceback-label ps-0 mt-4">Provide The Starting Date *</Label>
      <div className="d-flex mb-4">
        <Col style={{ minWidth: '150px', maxWidth: '240px' }}>
          <DatePicker
            selected={startDate ? convertToTimeZone(startDate, { timeZone: 'GMT' }) : undefined}
            onChange={(date) => handleCheck(date, 'startDate')}
            dateFormat="MM/dd/yyyy"
          />
        </Col>
      </div>
      <Label className="traceback-label ps-0">Campaign Labels Filter</Label>
      <div className="d-flex ps-0 me-0 mb-4">
        <Col className="ps-0 col-md-3">
          <LabelSelect
            isMulti
            addAllItem
            value={chosenLabels}
            onChange={handleSelectLabels}
            numberDisplayed={2}
            maxMenuHeight={170}
          />
        </Col>
      </div>
      <Button
        className="telecom-btn me-2"
        color="primary"
        style={{
          height: '28px',
          padding: '5px',
          fontSize: '14px',
          width: '150px'
        }}
        onClick={(option) => handleCheck(option, 'excel')}
      >
        {`Create .xlsx Doc`}
      </Button>
    </Fragment>
  );
};

const mapActionsToProps = {
  getReportObject
};

export default connect(null, mapActionsToProps)(SixtyDaysReport);
