import React, { FC, Fragment } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { Button } from 'reactstrap';
import { Message } from '../../../interfaces/messaging';
import { setMessage } from '../../../redux/messaging/thunks';

interface IProps {
  message: Message;
  setMessage: Function;
}

const EditMessageButon: FC<IProps> = ({ message, setMessage }) => {
  const navigate = useNavigate();
  return (
    <Fragment>
      {message.status === 'PENDING' && (
        <Button
          style={{ marginLeft: '15px', marginRight: '15px' }}
          onClick={() => {
            setMessage(message);
            navigate(`/admin-dashboard#messaging/edit/${message.id}`);
          }}
        >
          <i style={{ color: 'black' }} className="fa fa-pencil" />
        </Button>
      )}
    </Fragment>
  );
};

const mapActionsToProps = { setMessage };
export default connect(null, mapActionsToProps)(EditMessageButon);
