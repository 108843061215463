import React, { Fragment } from 'react';
import { DateFormat } from '../../enum/DateFormat';
import { Hop } from '../../interfaces/hop';
import { Traceback } from '../../interfaces/traceback';
import { getClientFormattedDate } from '../../lib/utilities';

interface IProps {
  hop: Hop;
  traceback: Traceback;
}

const CallDetailsRow: React.FC<IProps> = ({ hop, traceback }) => {
  return (
    <Fragment>
      <h5 className="p-0 traceback-sub-title mb-3">Call Details</h5>
      <div className="hop-details">
        <div className="ml-2">
          <label className="telecom-label">Date & Time of Call</label>
          <span className="table-cell-gray">
            {getClientFormattedDate(traceback.tracebackTime, DateFormat.LongBoth)}
          </span>
        </div>
        <div>
          <label className="telecom-label">Called Number</label>
          {hop.forwardedCall || traceback.calledTN}
        </div>
        <div>
          <label className="telecom-label">Do Not Call Registry</label>
          <span className="table-cell-gray">{traceback.dnc || 'None'}</span>
        </div>
        <div>
          <label className="telecom-label">Calling Number</label>
          {traceback.callingTN}
        </div>
        <div>
          <label className="telecom-label">Caller Name</label>
          <span className="table-cell-gray">{traceback.callerName}</span>
        </div>
        <div>
          <label className="telecom-label">Terminating Line Type</label>
          <span className="table-cell-gray">{traceback.termLineType}</span>
        </div>
        {traceback.isInternational ? (
          <Fragment>
            <div>
              <label className="telecom-label">Calling Country</label>
              <span className="table-cell-gray">{traceback.callingCountry}</span>
            </div>
            <div>
              <label className="telecom-label">Called Country</label>
              <span className="table-cell-gray">{traceback.calledCountry}</span>
            </div>
          </Fragment>
        ) : (
          <div>
            <label className="telecom-label">Terminating State</label>
            <span className="table-cell-gray">{traceback.termState}</span>
          </div>
        )}
      </div>
    </Fragment>
  );
};
export default CallDetailsRow;
