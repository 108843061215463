export interface Configuration {
  reputationThresholds: ReputationThreshold[];
  reputationVisible: boolean;
  insightVisible: boolean;
}

export interface ReputationThreshold {
  reputationThresholdId: number;
  name: string;
  lowerThreshold: number;
  symbolColor: string;
}

export function emptyConfiguration() {
  return {
    reputationThresholds: [],
    reputationAdminVisible: false,
    insightVisible: undefined
  };
}

export interface ConfigurationSettings {
  key: string;
  value: string;
}
