import { TfHops } from './actionEnum';

export const getTfHops = (response: any) => ({
  type: TfHops.GET_TFHOPS,
  payload: response
});

export const getTfHop = (response: any) => ({
  type: TfHops.GET_TFHOP,
  payload: response
});

export const addTfHop = (response: any) => ({
  type: TfHops.ADD_TFHOP,
  payload: response
});

export const editTfHop = (response: any) => ({
  type: TfHops.EDIT_TFHOP,
  payload: response
});

export const setLoadingStatus = (candidate: boolean) => ({
  type: TfHops.SET_TFHOP_LOADING_STATUS,
  payload: candidate
});

export const setErrorMessage = (message: string) => ({
  type: TfHops.SET_TFHOP_ERROR_MESSAGE,
  payload: message
});

export const getTfHopStatuses = (response: any) => ({
  type: TfHops.GET_TFHOP_STATUSES,
  payload: response
});

export const getTfHopComments = (response: any) => ({
  type: TfHops.GET_TFHOP_COMMENTS,
  payload: response
});

export const getTfHopsCount = (response: any) => ({
  type: TfHops.GET_TFHOPS_COUNT,
  payload: response
});

export const setInvalidTfHopId = (id: number | null) => ({
  type: TfHops.SET_INVALID_TFHOP_ID,
  payload: id
});
