import { Auth } from './actionEnum';

import { PersonalInfo } from '../../interfaces/user';

export const registerUser = (response: any) => ({
  type: Auth.REGISTER_USER,
  payload: response
});

export const signInUser = (response: any) => ({
  type: Auth.SIGNIN_USER,
  payload: response
});

export const setLoadingStatus = (candidate: boolean) => ({
  type: Auth.SET_AUTH_LOADING_STATUS,
  payload: candidate
});

export const setErrorMessage = (message: string) => ({
  type: Auth.SET_AUTH_ERROR_MESSAGE,
  payload: message
});

export const signOutUser = () => ({
  type: Auth.SIGNOUT_USER
});

export const getUser = (user: PersonalInfo) => ({
  type: Auth.GET_USER,
  payload: user
});

export const editUser = (user: PersonalInfo) => ({
  type: Auth.EDIT_USER,
  payload: user
});

export const deactivateUser = (response: any) => ({
  type: Auth.DEACTIVATE_USER,
  payload: response
});

export const rememberLastPage = (page: string) => ({
  type: Auth.REMEMBER_LAST_PAGE,
  payload: page
});

export const rememberLastUserId = (userId: number) => ({
  type: Auth.REMEMBER_LAST_USER_ID,
  payload: userId
});

export const setChangePasswordErrorMessage = (message: string) => ({
  type: Auth.SET_CHANGE_PASSWORD_ERROR_MESSAGE,
  payload: message
});
