import React, { Fragment } from 'react';
import { Col, Label, Row } from 'reactstrap';
import { ViewMode } from '../enum/ViewMode';
import { userTypes } from '../enum/userTypes';
import { Option } from '../interfaces/campaign';
import { Provider } from '../interfaces/provider';
import { PersonalInfo } from '../interfaces/user';
import ProviderRmdInfo from './ProviderRmdInfo';
import { Error } from './providerEditor';
import InputFormGroup from './inputFormGroup';

interface IProps {
  provider: Provider;
  user: PersonalInfo;
  OCNs: string;
  handleInputChange: (e: React.FormEvent<HTMLInputElement>) => void;
  providerTypes: Option[];
  viewMode?: ViewMode;
  error: Error;
}

const ProviderEditorForUserProvider: React.FC<IProps> = ({
  user,
  viewMode,
  provider,
  providerTypes,
  handleInputChange,
  OCNs,
  error
}) => {
  return (
    <Row>
      <Col md="12">
        <h5 className="p-0 traceback-sub-title">Provider details</h5>
        <Row className="mb-4">
          <Col md="4">
            <Label className="telecom-label">
              Provider Type
              {viewMode !== ViewMode.VIEW && user.roleType === userTypes.Admin && (
                <i className="fa fa-asterisk asterisk" />
              )}
            </Label>
            <p className="telecom-text mb-0" style={{ padding: '5px 8px' }}>
              {providerTypes.find((type) => type.value === provider.providerType)?.label || 'None'}
            </p>
            <p
              className="telecom-text mb-0"
              style={{
                padding: '5px 8px'
              }}
            >
              {provider.providerTypeDetail}
            </p>

            {viewMode === ViewMode.VIEW && provider.providerTaxId ? (
              <Fragment>
                <Label className="telecom-label">Tax ID</Label>
                <p
                  className="telecom-text mb-0"
                  style={{
                    padding: '5px 8px'
                  }}
                >
                  {provider.providerTaxId}
                </p>
              </Fragment>
            ) : (
              viewMode !== ViewMode.VIEW && (
                <Fragment>
                  <Label className="telecom-label">Tax ID</Label>
                  <InputFormGroup
                    inputName="providerTaxId"
                    inputId="provider-tax-id"
                    inputClassName="input-provider col-md-10 p-0 mt-1"
                    inputValue={provider.providerTaxId}
                    inputOnChange={handleInputChange}
                    inputPlaceholder=""
                    inputAutoComplete="off"
                    errorMessage={error.providerTaxId}
                  />
                </Fragment>
              )
            )}

            <ProviderRmdInfo viewMode={viewMode} rmdEntries={provider.RMDs} />
          </Col>
          <Col md="4">
            <Label className="telecom-label">ITG Member</Label>
            <p
              className="telecom-text mb-0"
              style={{
                padding: '5px 8px'
              }}
            >
              {provider.isITGMember ? 'Yes' : 'No'}
            </p>
          </Col>
          <Col md="4">
            <Label className="telecom-label">OCNs</Label>
            <p
              className="telecom-text mb-0"
              style={{
                padding: '5px 8px'
              }}
            >
              {OCNs}
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ProviderEditorForUserProvider;
