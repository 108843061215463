import { Traceforward } from './actionEnum';

const meta = {
  TotalCount: 0,
  PageSize: 0,
  CurrentPage: 0,
  TotalPages: 1
};

const defaultState: any = {
  traceforwards: [],
  attachments: [],
  traceforward: {
    traceforwardId: 0,
    campaignId: 0,
    campaignName: '',
    callingTN: '',
    calledTN: '',
    traceforwardTime: '',
    create_date: '',
    createdBy: '',
    creatorProvider: '',
    elapsed: 0,
    strikeExempt: true,
    description: '',
    audioName: 0,
    audioUrl: 0,
    audioUrlPath: 0,
    updateFlag: 0,
    update_date: ''
  },
  meta,
  loading: 0,
  error: ''
};

const reducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case Traceforward.GET_TRACEFORWARDS:
      return {
        ...state,
        traceforwards: action.payload.data,
        meta: action.payload.paginationMeta || meta
      };

    case Traceforward.GET_TRACEFORWARD:
      return { ...state, traceforward: action.payload.data };

    case Traceforward.ADD_TRACEFORWARD:
      return { ...state };

    case Traceforward.EDIT_TRACEFORWARD:
      return { ...state, traceforward: action.payload.data };

    case Traceforward.ADD_ATTACHMENTS:
      return { ...state, attachments: [...state.attachments, action.payload.data] };

    case Traceforward.GET_ATTACHMENTS:
      return { ...state, attachments: action.payload.data };

    case Traceforward.SET_TRACEFORWARD_LOADING_STATUS:
      if (action.payload) return { ...state, loading: state.loading + 1 };
      else return { ...state, loading: state.loading === 0 ? state.loading : state.loading - 1 };

    case Traceforward.SET_TRACEFORWARD_ERROR_MESSAGE:
      return { ...state, error: action.payload };

    // case Traceforward.GET_TRACEFORWARD_STATUSES:
    //   return { ...state, statuses: action.payload };

    default:
      return state;
  }
};

export default reducer;
