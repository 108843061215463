import { Attachments } from './actionEnum';

const defaultState: any = {
  oldUrls: [],
  error: '',
  loading: 0
};

const reducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case Attachments.GET_PUBLIC_ATTACHMENTS_OLD_LINKS:
      return { ...state, oldUrls: action.payload };

    case Attachments.SET_ATTACHMENTS_LOADING_STATUS:
      if (action.payload) return { ...state, loading: state.loading + 1 };
      else return { ...state, loading: state.loading === 0 ? state.loading : state.loading - 1 };

    case Attachments.SET_ATTACHMENTS_ERROR_MESSAGE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
};

export default reducer;
