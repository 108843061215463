import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { stateMappings } from '../../redux/stateMappings';

interface IProps {
  zerobounce: any;
}
const ZerobounceAlert: React.FC<IProps> = ({ zerobounce }) => {
  // zerobounce notification
  const [toggleEmailValidation, setToggleEmailValidation] = useState(false);
  const [currentCallSource, setCurrentCallSource] = useState<string | null>(null);

  useEffect(() => {
    const status = localStorage.getItem('currentCallSource');
    if (status) {
      localStorage.removeItem('currentCallSource');
      if (status !== 'isCallRecordNotProvided') {
        setToggleEmailValidation(true);
        setCurrentCallSource(status);
        setTimeout(() => {
          setToggleEmailValidation(false);
        }, 5000);
      }
    }
  }, []);
  return zerobounce &&
    Object.values(zerobounce).length > 0 &&
    currentCallSource !== '' &&
    zerobounce.status !== '' ? (
    <Alert
      color={zerobounce.status !== 'valid' ? 'warning' : 'success'}
      isOpen={toggleEmailValidation}
      toggle={() => {
        setToggleEmailValidation(false);
      }}
      style={{
        position: 'fixed',
        top: 100,
        right: 30,
        width: 400,
        height: `${zerobounce.status !== 'valid' ? 120 : 100}`,
        zIndex: 999
      }}
    >
      {zerobounce.status !== 'valid' && currentCallSource === 'isUpstream'
        ? `Thank you. Please allow 1 business day for the ITG Admin to review the new provider you have submitted. We will reach out to you if there is an issue with the contact information provided.`
        : zerobounce.status !== 'valid' && currentCallSource === 'isCustomer'
          ? `Thank you, please allow 1 business day for the ITG Admin to review the Completed Hop you have submitted. They will contact you if there are any questions.`
          : zerobounce.status === 'valid' && currentCallSource === 'isCustomer'
            ? 'Thank you. Hop Completed Successfully'
            : `Thank you. Please allow 1 business day for the ITG Admin to review the new provider you have submitted.`}
    </Alert>
  ) : null;
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return { zerobounce: sm.zeroBounce };
};

export default connect(mapStateToProps)(ZerobounceAlert);
