//This file holds the API calls that hit the /user route for DRY purposes
import { CancelTokenSource } from 'axios';
import { Pagination } from '../../interfaces/pagination';
import { TfHop } from '../../interfaces/tfhop';
import { FilterElement } from '../../lib/FilterElement';
import { ApiRequest, buildParams, withCancelRequestFunctionality } from '../../lib/apiRequest';

const getTfHopsWithCancelApiCalls = withCancelRequestFunctionality();

export const getAllTfHopsApiCall = async (params: Pagination, filterElements: FilterElement) =>
  getTfHopsWithCancelApiCalls((cancelToken: CancelTokenSource) =>
    ApiRequest.request({
      method: 'POST',
      url:
        `/tfhops/search?` +
        buildParams({
          ...params
        }),
      data: filterElements,
      cancelToken: cancelToken.token
    })
  );

export const getProviderInSequenceStatusApiCall = async (
  tracebackId: number,
  selectedProviderId: number
) =>
  ApiRequest.request({
    method: 'POST',
    url: `/tfhops/checkPreviousHopsForProviderId`,
    data: {
      tracebackId,
      selectedProviderId
    }
  });

export const getTfHopApiCall = async (hopId: number) =>
  ApiRequest.request({
    method: 'GET',
    url: `/tfhops/${hopId}`
  });

export const getTfHopStatusApiCall = async () =>
  ApiRequest.request({
    method: 'GET',
    url: '/AppInfo/Enums/tfHopStatusCodes'
  });

export const updateTfHopApiCall = async (content: TfHop) =>
  await ApiRequest.request({
    method: 'PUT',
    url: `/tfhops/${content.tfhopId}`,
    data: { ...content }
  });

export const deleteTfHopApiCall = async (hopId: number) =>
  ApiRequest.request({
    method: 'DELETE',
    url: `/tfhops/${hopId}`
  });

export const resetTfHopApiCall = async (hopId: number) =>
  ApiRequest.request({
    method: 'PUT',
    url: `/tfhops/${hopId}/unlock`
  });

export const getTfHopCommentsApiCall = async (hopId: number) =>
  ApiRequest.request({
    method: 'GET',
    url: `/tfhops/${hopId}/comments`
  });

export const getTfHopsCountApiCall = async (filterElements: FilterElement) =>
  ApiRequest.request({
    method: 'POST',
    url: `tfhops/count`,
    data: filterElements
  });

export const getCSVApiCall = async (filterElements: FilterElement, params: Pagination) =>
  ApiRequest.request({
    method: 'POST',
    url:
      `/download-provider-traceforward-csv?` +
      buildParams({
        ...params
      }),
    data: filterElements,
    responseType: 'blob',
    headers: { accept: 'text/csv' }
  });
