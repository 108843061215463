import { getPublicAttachmentsOldLinks, setErrorMessage, setLoadingStatus } from './actions';
import { getPublicAttachmentsOldLinksApiCall } from './apiCalls';

export const resetPublicAttachmentsLinks = () => async (dispatch: any) => {
  dispatch(getPublicAttachmentsOldLinks([]));
};

export const getPublicAttachmentsLinks = (data: string[]) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  dispatch(resetPublicAttachmentsLinks());

  try {
    const response = await getPublicAttachmentsOldLinksApiCall(data);

    if (response) {
      dispatch(getPublicAttachmentsOldLinks(response.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    if (error.response) {
      dispatch(setStatus(false, error.response.data.error));
    } else dispatch(setStatus(false, error.message));
  }
};

const setStatus = (status: boolean, message: string) => async (dispatch: any) => {
  dispatch(setErrorMessage(message));
  dispatch(setLoadingStatus(status));
};
