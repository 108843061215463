import React, { FC } from 'react';
interface IProps {
  color: string;
}

const Dot: FC<IProps> = ({ color }) => {
  return (
    <svg height="10" width="10">
      <circle cx="5" cy="5" r="3" stroke={color} strokeWidth="3" fill={color} />
      Sorry, your browser does not support inline SVG.
    </svg>
  );
};

export default Dot;
