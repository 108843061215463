import { Insight } from './actionEnum';
const meta = {
  TotalCount: 0,
  PageSize: 0,
  CurrentPage: 0,
  TotalPages: 1
};
const defaultState: any = {
  insight: [],
  insightFinalScore: {},
  insightsChartFinalScore: [],
  insights: [],
  weights: {},
  loading: 0,
  error: '',
  honeybadger: {},
  meta
};
const reducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case Insight.GET_INSIGHTS:
      return {
        ...state,
        insights: action.payload.data,
        meta: action.payload.paginationMeta || meta
      };
    case Insight.GET_INSIGHTS_FOR_PROVIDER:
      return {
        ...state,
        insight: action.payload
      };
    case Insight.GET_INSIGHT_FINAL_SCORE_PROVIDER:
      return {
        ...state,
        insightFinalScore: action.payload
      };
    case Insight.SET_INSIGHT_LOADING_STATUS:
      if (action.payload) return { ...state, loading: state.loading + 1 };
      else return { ...state, loading: state.loading === 0 ? state.loading : state.loading - 1 };
    case Insight.SET_INSIGHT_ERROR_MESSAGE:
      return {
        ...state,
        error: action.payload
      };
    case Insight.GET_INSIGHT_WEIGHTS:
      return {
        ...state,
        weights: action.payload
      };
    case Insight.GET_HONEYBADGERHQ:
      return {
        ...state,
        honeybadger: action.payload
      };
    case Insight.GET_INSIGHTS_CHART_FINAL_SCORE:
      return {
        ...state,
        insightsChartFinalScore: action.payload
      };
    default:
      return state;
  }
};
export default reducer;
