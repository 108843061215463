import React, { FC, Fragment } from 'react';
import { NoteType } from '../../interfaces/hop';
import CustomToolTip from '../CustomToolTip';

export enum ExtraNoteType {
  UsItg,
  NonUsItg,
  UsNonItg,
  NonUsNonItg
}

interface IProps {
  type: NoteType | ExtraNoteType;
  printInfo?: boolean;
  hideToolTip?: boolean;
  customToolTip?: string;
}

export const Note: FC<IProps> = ({ type, printInfo, hideToolTip, customToolTip }) => {
  let note = null;
  let info = '';
  let tooltipText = '';

  switch (type) {
    case ExtraNoteType.UsItg:
      note = <span className="fa fa-circle ms-2" style={{ color: '#8DD920' }} />;
      info = 'US ITG';
      break;
    case ExtraNoteType.NonUsItg:
      note = <span className="fa fa-circle ms-2" style={{ color: '#349FFA' }} />;
      info = 'Non-US ITG';
      break;
    case ExtraNoteType.UsNonItg:
      note = <span className="fa fa-circle ms-2" style={{ color: '#C6C9D0' }} />;
      info = 'US Non-ITG';
      break;
    case ExtraNoteType.NonUsNonItg:
      note = <span className="fa fa-circle ms-2" style={{ color: '#797E8C' }} />;
      info = 'Non-US Non-ITG';
      break;
    case NoteType.OpenHop: // no symbol for this
      break;
    case NoteType.DeletedHop: // no symbol for this
      break;
    case NoteType.OriginHop:
      note = <span className="hop-org">org</span>;
      info = ': Origin';
      tooltipText = 'Originating Voice Service Provider';
      break;
    case NoteType.DownstreamOrigin:
      note = (
        <Fragment>
          <i className="fa-solid fa-arrow-down hop-dorg-arrow" />
          <span className="hop-dorg">org</span>
        </Fragment>
      );
      tooltipText = 'Immediate Downstream of the Originating Voice Service Provider';
      break;
    case NoteType.InternationOriginHop:
      note = <span className="hop-ior">ior</span>;
      info = ': International Origin';
      tooltipText =
        'Originating Voice Service Provider is from different country than called party';
      break;
    case NoteType.NoResponseHop:
      note = <span className="hop-nr">nr</span>;
      info = ': No Response';
      tooltipText = 'Last Voice Service Provider in call path did not respond to traceback request';
      break;
    case NoteType.DownNoResponseHop:
      note = (
        <Fragment>
          <i className="fa-solid fa-arrow-down hop-dnr-arrow" />
          <span className="hop-dnr">nr</span>
        </Fragment>
      );
      tooltipText = 'Immediate Downstream of Provider that did not respond to traceback request';
      break;
    case NoteType.NotFoundHop:
      note = <span className="hop-nf">nf</span>;
      info = ': Not Found';
      tooltipText = 'Not Found by Voice Service Provider';
      break;
    case NoteType.DownNotFoundHop:
      note = (
        <Fragment>
          <i className="fa-solid fa-arrow-down hop-dnf-arrow" />
          <span className="hop-dnf">nf</span>
        </Fragment>
      );
      tooltipText = 'Not Found by Voice Service Provider Immediately Upstream';
      break;
    case NoteType.USPointOfEntryHop:
      note = <span className="hop-poe">poe </span>;
      info = ': Point of Entry';
      tooltipText = 'Gateway Voice Service Provider to network of called party country';
      break;
    case NoteType.RejectDispute:
      note = <span className="hop-xd">xd</span>;
      info = ': Dispute Rejected';
      tooltipText =
        'After analysis the ITG team continues to have a reasonable basis to suspect that the call subject to the traceback is fraudulent, abusive, and/or unlawful.';
      break;
    case NoteType.InternationalTraceback:
      note = <span className="hop-int">int</span>;
      info = ': International Traceback';
      tooltipText =
        'International Traceback: the called and or calling number may be located outside the US';
      break;
    case NoteType.ForeignPointDepartureHop:
      note = <span className="hop-fpd">fpd</span>;
      info = ': Foreign Point Depart';
      tooltipText = 'Voice Service Provider upstream of the Point of Entry';
      break;
    case NoteType.StrikeExemptProvider:
      note = <span className="hop-se">se</span>;
      info = ': Strike Exempt';
      tooltipText =
        'The ITG team no longer finds the traceback and its results reliable.  Reasons the ITG team may mark a traceback as SE include but are not limited to the ITG no longer has a reasonable basis to suspect the call subject to the traceback is illegal.';
      break;
    case NoteType.TermHop:
      note = <span className="hop-term">term</span>;
      tooltipText = 'Provider for the called party';
      break;
  }
  if (!note) return null;
  if (customToolTip) tooltipText = customToolTip;
  return (
    <Fragment>
      {hideToolTip || !tooltipText ? (
        note
      ) : (
        <CustomToolTip tooltipVisible message={tooltipText}>
          {note}
        </CustomToolTip>
      )}
      {printInfo && info && <span>{info}</span>}
    </Fragment>
  );
};

interface NotesProps {
  types: (NoteType | ExtraNoteType)[];
  printInfo?: boolean;
  customToolTipFunc?: (a: NoteType | ExtraNoteType) => string;
}

export const Notes: FC<NotesProps> = ({ types, printInfo, customToolTipFunc }) => {
  return types ? (
    <Fragment>
      {types.map((type) => (
        <Note
          key={`HopNote-${type}`}
          type={type}
          printInfo={printInfo}
          customToolTip={customToolTipFunc ? customToolTipFunc(type) : undefined}
        />
      ))}
    </Fragment>
  ) : null;
};
