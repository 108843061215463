import React, { FC } from 'react';
import { Card, CardBody } from 'reactstrap';
import { SpreadsheetTraceback } from '../../interfaces/traceback';

interface IProps {
  data?: SpreadsheetTraceback;
}
const SpreadsheetTracebackExpandableRow: FC<IProps> = ({ data }) => {
  return (
    <Card className="expandedRow">
      <CardBody style={{ color: '#797979', fontSize: '1rem', padding: '0.5rem 1rem' }}>
        <div className="d-flex">
          <label className="me-1 font-calibri-bold">Incident name:</label>
          <p>{data?.incidentName}</p>
          <label className="ms-4 me-1 font-calibri-bold">Nature of request:</label>
          <p>{data?.natureOfRequest}</p>
        </div>
        <div className="d-flex">
          <label className="me-1 font-calibri-bold">Suspected Legal Violation(s):</label>
          <p>{data?.legalViolations}</p>
          <label className="ms-4 me-1 font-calibri-bold">Record Type:</label>
          <p>{data?.recordingType}</p>
        </div>
        <div className="d-flex">
          <label className="font-calibri-bold me-1">Content Summary:</label>
          <p className="w-75">{data?.contentTraceback}</p>
        </div>
        <div className="d-flex">
          <label className="font-calibri-bold me-1">Incident Description:</label>
          <p className="w-75">{data?.incidentDescription}</p>
        </div>
        <div className="row">
          <label className="font-calibri-bold me-1">Live or Prerecorded/Artifical:</label>
          <p>{data?.liveOrPrerecorded}</p>
          <label className="font-calibri-bold me-1 ms-4">Called Party Type:</label>
          <p>{data?.calledPartyType}</p>
        </div>
      </CardBody>
    </Card>
  );
};

export default SpreadsheetTracebackExpandableRow;
