import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Input, Label } from 'reactstrap';
import { userTypes } from '../../enum/userTypes';
import { Provider } from '../../interfaces/provider';
import { getLabelsApiCall } from '../../redux/campaign/apiCalls';
import {
  addCampaignFollowerApiCall,
  addFollowerApiCall,
  deleteCampaignFollowerApiCall,
  deleteFollowerApiCall
} from '../../redux/govFollower/apiCalls';
import { getGovCampaignFollowers, getGovFollowers } from '../../redux/govFollower/thunk';
import { stateMappings } from '../../redux/stateMappings';

type IProps = {
  userRoleType: userTypes;
  className: string;
  providerId?: Number;
  getGovFollowers: Function;
  getGovCampaignFollowers: Function;
  children?: React.ReactNode;
  provider: Provider;
  labelId?: Number;
};

const FollowProviderCheckbox: FC<IProps> = ({
  children,
  userRoleType,
  className,
  getGovFollowers,
  providerId,
  provider,
  labelId,
  getGovCampaignFollowers
}) => {
  const [follow, setFollow] = useState(false);
  const [labelName, setLabelName] = useState('');

  const checkFollowState = async () => {
    if (providerId) {
      const response = await getGovFollowers();
      if (response) {
        setFollow(response.some((item: Provider) => item.providerId === providerId));
      }
    } else if (labelId) {
      const response = await getGovCampaignFollowers();
      if (response) {
        setFollow(response.some((item: any) => item.labelID === labelId));
      }
      const labels = await getLabelsApiCall();
      setLabelName(labels.data.data.find((item: any) => item.labelID === labelId).name);
    }
  };
  useEffect(() => {
    if (userRoleType === userTypes.Admin || userRoleType === userTypes.GovUser) {
      checkFollowState();
    }
  }, [providerId, labelId]);

  const handleFollower = async () => {
    try {
      if (follow) {
        if (providerId) await deleteFollowerApiCall(Number(providerId));
        else if (labelId) await deleteCampaignFollowerApiCall(Number(labelId));
      } else {
        if (providerId) await addFollowerApiCall(Number(providerId));
        else if (labelId) await addCampaignFollowerApiCall(Number(labelId));
      }
      setFollow(!follow);
    } catch (error: any) {
      console.log('Error', error);
    }
  };

  return (userRoleType !== userTypes.Admin && userRoleType !== userTypes.GovUser) ||
    (!provider.active && providerId) ||
    (!providerId && !labelId) ? (
    <>{children}</>
  ) : (
    <div className={className}>
      {children}
      <FormGroup check className="follow-provider-input-container" onClick={handleFollower}>
        <input id="checkbox1" className="mt-0 me-2" type="checkbox" checked={follow} readOnly />
        <Label check style={{ color: `${follow ? '#28a745' : '#494949'}` }}>{`${
          providerId ? 'Follow Provider Activity' : `Follow ${labelName} Activity`
        }`}</Label>
      </FormGroup>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return {
    userRoleType: sm.user.roleType,
    provider: sm.provider.provider
  };
};

const mapActionsToProps = {
  getGovFollowers,
  getGovCampaignFollowers
};

export default connect(mapStateToProps, mapActionsToProps)(FollowProviderCheckbox);
