import {
  GET_ACCESSLOGS,
  SET_ACCESSLOG_LOADING_STATUS,
  SET_ACCESSLOG_ERROR_MESSAGE
} from './actionEnum';

const defaultState: any = {
  accessLogs: [],
  meta: {},
  loading: 0,
  error: ''
};

const meta = {
  TotalCount: 0,
  PageSize: 0,
  CurrentPage: 0,
  TotalPages: 1
};

const reducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case GET_ACCESSLOGS:
      return {
        ...state,
        accessLogs: action.payload.data,
        meta: action.payload.paginationMeta || meta
      };

    case SET_ACCESSLOG_LOADING_STATUS:
      if (action.payload) return { ...state, loading: state.loading + 1 };
      else return { ...state, loading: state.loading === 0 ? state.loading : state.loading - 1 };

    case SET_ACCESSLOG_ERROR_MESSAGE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
};

export default reducer;
