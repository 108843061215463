import { getCSVApiCall, getReputationHopsApiCall } from './apiCalls';

import {
  getReputationHopsInternational,
  getReputationHopsLive,
  getReputationHopsPrerecorded,
  setErrorMessage,
  setLoadingStatus
} from './actions';

import { ReputationHopsRequest } from '../../interfaces/reputation';
import { logoutOnAuthError } from '../../lib/apiRequest';

export const getReputationHopsList = (req: ReputationHopsRequest) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  try {
    const hops = await getReputationHopsApiCall(req);
    if (hops && hops.data) {
      if (req.isInternational) dispatch(getReputationHopsInternational(hops.data));
      else if (req.live) dispatch(getReputationHopsLive(hops.data));
      else dispatch(getReputationHopsPrerecorded(hops.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

const setStatus = (status: boolean, message: string) => async (dispatch: any) => {
  dispatch(setErrorMessage(message));
  dispatch(setLoadingStatus(status));
};

export const getHopsCSV =
  (reputationHopsRequest: ReputationHopsRequest) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    try {
      const response = await getCSVApiCall(reputationHopsRequest);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', response.headers.contentfilename);
      document.body.appendChild(link);
      link.click();
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };
