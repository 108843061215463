//This file holds the API calls that hit the /user route for DRY purposes
import { Campaign } from '../../interfaces/campaign';
import { Pagination } from '../../interfaces/pagination';
import { FilterElement } from '../../lib/FilterElement';
import { ApiRequest, buildParams } from '../../lib/apiRequest';

export const getCampaignsApiCall = async (params: Pagination, filterElements: FilterElement) =>
  ApiRequest.request({
    method: 'POST',
    url:
      `/campaigns/search?` +
      buildParams({
        ...params
      }),
    data: filterElements
  });

export const getCampaignApiCall = async (campaignId: number) =>
  ApiRequest.request({
    method: 'GET',
    url: `/campaigns/${campaignId}`
  });

export const getCampaignLabelsStatsApiCall = async () =>
  ApiRequest.request({
    method: 'GET',
    url: `/campaigns/campaignLabelsStats`
  });

export const addCampaignApiCall = async (content: Campaign) => {
  content.comments = [];

  return ApiRequest.request({
    method: 'POST',
    url: `/campaigns`,
    data: {
      ...content
    }
  });
};

export const editCampaignApiCall = async (content: Campaign) => {
  content.comments = [];

  return ApiRequest.request({
    method: 'PUT',
    url: `/campaigns/${content.campaignId}`,
    data: {
      ...content
    }
  });
};

export const getSourcesApiCall = async () =>
  ApiRequest.request({
    method: 'GET',
    url: `/Sources?sort=name&order=asc&pageSize=10000`
  });

export const getLabelsApiCall = async () =>
  ApiRequest.request({
    method: 'GET',
    url: `/Labels`
  });

export const getLabelsForProviderApiCall = async (
  providerId: number,
  startDate?: string,
  endDate?: string
) => {
  return ApiRequest.request({
    method: 'POST',
    url: `/Labels/provider/${providerId}`,
    data: {
      startDate,
      endDate
    }
  });
};

export const addSourceApiCall = async (sourceName: string) =>
  ApiRequest.request({
    method: 'POST',
    url: `/Sources`,
    data: {
      name: sourceName,
      isSystemDefined: true,
      isProvider: true
    }
  });

export const addLabelApiCall = async (labelName: string, fraudScore: number) =>
  ApiRequest.request({
    method: 'POST',
    url: `/Labels`,
    data: {
      name: labelName,
      fraudScore: fraudScore
    }
  });

export const deleteCampaignApiCall = async (campaignId: number) =>
  ApiRequest.request({
    method: 'DELETE',
    url: `/campaigns/${campaignId}`
  });

export const getLanguagesCampaignApiCall = async () =>
  ApiRequest.request({
    method: 'GET',
    url: `/languages/campaign`
  });
