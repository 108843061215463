import React, { FC, useEffect, useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { stateMappings } from '../../redux/stateMappings';
import { getInsightsChartFinalScore } from '../../redux/insights/thunks';
import { connect } from 'react-redux';
import { QuarterFrequency } from '../../interfaces/insights';
import { insightChartData } from '../../lib/chart/data';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: true
    },
    title: {
      display: true,
      text: 'Frequency vs. Rank'
    }
  },
  scales: {
    x: {
      title: {
        display: true,
        text: 'Rank'
      }
    },
    y: {
      title: {
        display: true,
        text: 'Frequency'
      }
    }
  }
};

interface IProps {
  finalScoreChartData: QuarterFrequency[];
  getInsightsChartFinalScore: Function;
}

const InsightsChart: FC<IProps> = ({ finalScoreChartData, getInsightsChartFinalScore }) => {
  const insightsChartData = useMemo(
    () => insightChartData(finalScoreChartData),
    [finalScoreChartData]
  );
  useEffect(() => {
    getInsightsChartFinalScore();
  }, []);
  return (
    <div className="w-75">
      <Line data={insightsChartData} options={options} />
    </div>
  );
};
const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    finalScoreChartData: sm.insight.insightsChartFinalScore
  };
};

const mapActionsToProps = {
  getInsightsChartFinalScore
};
export default connect(mapStateToProps, mapActionsToProps)(InsightsChart);
