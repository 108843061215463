import React, { FC, useEffect, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import { executeCronJob } from '../../redux/appinfo/apiCalls';
import { getCronJobsStats } from '../../redux/appinfo/thunks';
import { stateMappings } from '../../redux/stateMappings';
import CustomDataTable from '../CustomDataTable';
import { CronJobsStats } from './Incidents/TriggerIngest';

interface IProps {
  getCronJobsStats: Function;
  stats: CronJobsStats[];
}

const columns = (tigger: (taskName: string) => void): TableColumn<CronJobsStats>[] => [
  {
    name: '',
    width: '20px',
    cell: () => <div />
  },
  {
    name: 'Name',
    grow: 1,
    cell: (row: CronJobsStats) => (
      <div className="table-cell text-center">
        <span>{row.name}</span>
      </div>
    )
  },
  {
    name: 'ScheduledAt',
    grow: 1,
    omit: true,
    cell: (row: CronJobsStats) => (
      <div className="table-cell text-center">
        <span>{row.scheduledAt}</span>
      </div>
    )
  },
  {
    name: 'StartedAt',
    grow: 1,
    cell: (row: CronJobsStats) => (
      <div className="table-cell text-center">
        <span>{row.startedAt}</span>
      </div>
    )
  },
  {
    name: 'EndedAt',
    grow: 1,
    cell: (row: CronJobsStats) => (
      <div className="table-cell text-center">
        <span>{row.endedAt}</span>
      </div>
    )
  },
  {
    name: 'LastError',
    grow: 1,
    cell: (row: CronJobsStats) => (
      <div className="table-cell text-center text-red">
        <span>{row.lastError}</span>
      </div>
    )
  },
  {
    name: 'Last successful run',
    grow: 1,
    cell: (row: CronJobsStats) => (
      <div className="table-cell text-center">
        <span>{row.lastSuccesfulRun}</span>
      </div>
    )
  },

  {
    name: 'Action',
    grow: 1,
    cell: (row: CronJobsStats) => (
      <div className="table-cell text-center">
        <Button
          className="btn-default telecom-btn"
          onClick={() => {
            tigger(row.name);
          }}
        >
          Manual Trigger
        </Button>
      </div>
    )
  }
];

const CronJobs: FC<IProps> = ({ getCronJobsStats, stats }) => {
  const [taskNameToTrigger, setTaskNameToTrigger] = useState('');
  useEffect(() => {
    getCronJobsStats();
  }, []);
  const triggerCronJob = async () => {
    try {
      await executeCronJob(taskNameToTrigger);
    } catch (e) {}
    getCronJobsStats();
    setTaskNameToTrigger('');
  };
  return (
    <Card>
      <CardHeader>
        <Label className="telecom-label mb-3" style={{ fontSize: '20px' }}>
          CronJobs
        </Label>
      </CardHeader>
      <CardBody className="card-body-traceback">
        <CustomDataTable
          columns={columns(setTaskNameToTrigger)}
          pagination={false}
          tableData={stats}
          defaultSortFieldId={''}
        />
        <Modal
          centered
          className="modal-template"
          isOpen={!!taskNameToTrigger}
          toggle={() => {
            setTaskNameToTrigger('');
          }}
        >
          <ModalHeader
            toggle={() => {
              setTaskNameToTrigger('');
            }}
          />
          <ModalBody className="delete-modal-body">
            Are you sure you want to trigger {taskNameToTrigger}?
          </ModalBody>
          <ModalFooter style={{ margin: 'auto' }}>
            <div className="d-flex flex-row">
              <Button
                className="btn-default telecom-btn"
                color="light"
                onClick={() => {
                  setTaskNameToTrigger('');
                }}
              >
                No
              </Button>
              <Button
                className="telecom-btn ms-2"
                color="danger"
                onClick={() => {
                  triggerCronJob();
                }}
              >
                Yes
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </CardBody>
    </Card>
  );
};
const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return {
    stats: sm.appinfo.cronJobsStats
  };
};
const mapActionsToProps = {
  getCronJobsStats
};

export default connect(mapStateToProps, mapActionsToProps)(CronJobs);
