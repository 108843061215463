import React, { Fragment, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { userTypes } from '../../enum/userTypes';
import { TfHop } from '../../interfaces/tfhop';
import { Traceforward } from '../../interfaces/traceforward';
import { PersonalInfo } from '../../interfaces/user';
import Breadcrumb from '../Breadcrumbs';

interface IProps {
  tfhop: TfHop;
  traceforward: Traceforward;
  user: PersonalInfo;
  resetTfHopObject: Function;
  deleteTfHop: Function;
}

const TfHopBreadcrumb: React.FC<IProps> = ({
  tfhop,
  traceforward,
  user,
  resetTfHopObject,
  deleteTfHop
}) => {
  const [modifyTooltip, setModifyTooltip] = useState(!localStorage.getItem('deleteTfHopTip'));
  const [isDelete, setIsDelete] = useState(false);

  const handleModifyTooltipBtnClick = () => {
    localStorage.setItem('deleteTfHopTip', 'SET');
    setModifyTooltip(false);
  };

  const deleteConfirmModalToggle = () => {
    setIsDelete((v) => !v);
  };

  const modifyFunction = () => {
    // Only Admins & Transit/Originating Hops
    if (user.roleType !== userTypes.Admin) return;
    // Transit Hops can be deleted
    if (
      tfhop.tfhopSequence !== 1 &&
      (tfhop.tfhopId !== traceforward.lastTfHopId || tfhop.status === 1 || tfhop.status === 6)
    ) {
      deleteConfirmModalToggle();
    } // Originating & NFNR Hops can be reset
    else if ((tfhop.status >= 2 && tfhop.status <= 4) || tfhop.status === 7) {
      resetTfHopObject(tfhop.tfhopId);
    }
  };

  return (
    <Fragment>
      <Breadcrumb
        title={'tfhops'}
        isAdmin={user.roleType === userTypes.Admin}
        destination={'/tfhops'}
        detail={`TfHop ${tfhop.tfhopSequence || 'Unknown'} for Traceforward ${
          tfhop.traceforwardId
        }`}
        detailComponent={
          <Fragment>
            <a
              className={`breadcrum-sub-main`}
              href={`/campaigns/campaign/${traceforward.campaignId}`}
            >
              {traceforward.campaignName}
            </a>
            <span className="breadcrum-after">{' / '}</span>
            <a
              className="breadcrum-sub-main"
              style={{ marginLeft: '0px' }}
              href={`/traceforwards/traceforward/${tfhop.traceforwardId}`}
            >
              {tfhop.traceforwardId}
            </a>
            <span className="breadcrum-after">{` / Hop ${tfhop.tfhopSequence || 'Unknown'}`}</span>
          </Fragment>
        }
        modify={
          user.roleType !== userTypes.Admin
            ? null // Only Admins & Transit/Originating Hops
            : tfhop.tfhopSequence !== 1 &&
                (tfhop.tfhopId !== traceforward.lastTfHopId ||
                  tfhop.status === 1 ||
                  tfhop.status === 6)
              ? 'Delete Hop' // Open Hops can be deleted
              : 'Reset Hop' // Originating & NFNR Hops can be reset
        }
        modifyFunction={() => modifyFunction()}
        tooltipContents={
          tfhop.tfhopId !== traceforward.lastTfHopId || tfhop.status === 1 || tfhop.status === 6 ? ( // Transit Hops can be deleted
            <Fragment>
              <p>If you need to delete this TfHop for some reason, this is the spot to do that.</p>
              <p>
                The TfHop will be archived, and the previous TfHop will be re-opened to allow the
                Provider to give a new response.
              </p>
            </Fragment>
          ) : tfhop.status >= 2 && tfhop.status <= 4 ? ( // Originating & NFNR Hops can be reset
            <Fragment>
              <p>If you need to reset this TfHop for some reason, this is the spot to do that.</p>
              <p>The TfHop will be re-opened to allow the Provider to give a new response.</p>
            </Fragment>
          ) : null
        }
        isModifyTooltip={modifyTooltip}
        handleModifyTooltipBtnClick={handleModifyTooltipBtnClick}
      />
      <Modal
        centered
        isOpen={isDelete}
        className="submit-confirm-modal"
        toggle={deleteConfirmModalToggle}
      >
        <ModalHeader toggle={deleteConfirmModalToggle} />
        <ModalBody className="delete-modal-body">
          <h5 className="traceforward-sub-title">Delete this tfhop?</h5>
          <p className="telecom-text mb-0">
            Information will be removed and the upstream tfhop will be re-opened.
          </p>
        </ModalBody>
        <ModalFooter style={{ margin: 'auto' }}>
          <Button
            className="btn-default telecom-btn"
            color="light"
            onClick={deleteConfirmModalToggle}
          >
            Cancel
          </Button>
          <Button
            className="telecom-btn red"
            onClick={() => {
              deleteTfHop(tfhop.tfhopId);
            }}
          >
            Proceed
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};
export default TfHopBreadcrumb;
