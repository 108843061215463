import React from 'react';

export default (props: any) => {
  const tbStats = props.tbStats || {};
  const thisWeek = tbStats.thisWeek || {};
  const lastWeek = tbStats.lastWeek || {};

  return (
    <table id="weekly-statistics" style={{ position: 'inherit' }}>
      <thead>
        <tr>
          <th />
          <th>New</th>
          <th>Open</th>
          <th>Completed</th>
          {/* <th>Avg Hrs</th> */}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>This Wk</td>
          <td>{thisWeek.new}</td>
          <td>{thisWeek.pending}</td>
          <td>{thisWeek.completed}</td>
          {/*<td>{getElapsedTime(thisWeek.avgTime, "hours")}</td>*/}
        </tr>
        <tr>
          <td>Last Wk</td>
          <td>{lastWeek.new}</td>
          <td>{lastWeek.pending}</td>
          <td>{lastWeek.completed}</td>
          {/*<td>{getElapsedTime(lastWeek.avgTime, "hours")}</td>*/}
        </tr>
      </tbody>
    </table>
  );
};
