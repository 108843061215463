import React from 'react';
import { TableColumn } from 'react-data-table-component';
import { Button } from 'reactstrap';
import { ReportArchiveObj } from '../../../../interfaces/report';
import { cancelReportArchiveApiCall } from '../../../../redux/report/apiCalls';
import { HoverCardContent, HoverCardRoot, HoverCardTrigger } from '../../../HoverCard';

const columns = (
  downloadReportArchiveApi: Function,
  search: Function
): TableColumn<ReportArchiveObj>[] => [
  {
    name: 'Zendesk Ticket',
    selector: () => 'zendeskTicketId',
    sortable: true,
    grow: 2,
    cell: (row: ReportArchiveObj) => (
      <div className="table-cell blue center">
        <a href={`https://industrytracebackgroup.zendesk.com/agent/tickets/${row.zendeskTicketId}`}>
          {row.zendeskTicketId}
        </a>
      </div>
    )
  },
  {
    name: 'File Name',
    selector: () => 'file_name',
    sortable: true,
    grow: 3,
    cell: (row: ReportArchiveObj) => <div className="table-cell center">{row.fileName}</div>
  },
  {
    name: 'Report Type',
    selector: () => 'report_type',
    sortable: true,
    grow: 1,
    cell: (row: ReportArchiveObj) => <div className="table-cell center">{row.reportType}</div>
  },
  {
    name: 'User Name',
    selector: () => 'userId',
    sortable: true,
    grow: 1,
    cell: (row: ReportArchiveObj) => <div className="table-cell center">{row.userName}</div>
  },
  {
    name: 'Status',
    selector: () => 'status',
    sortable: true,
    grow: 1,
    cell: (row: ReportArchiveObj) => (
      <div className="table-cell center">
        <HoverCardRoot openDelay={150}>
          <HoverCardTrigger asChild={true}>
            <span>
              {row.status === 'done'
                ? 'Done'
                : row.status === 'inProgress'
                  ? 'In Progress'
                  : row.status === 'queue'
                    ? 'In Queue'
                    : 'Error'}
            </span>
          </HoverCardTrigger>
          <HoverCardContent className="hoverCardGovWidth" sideOffset={2}>
            {row.status === 'done'
              ? 'Done'
              : row.extraInfo ||
                (row.status === 'inProgress'
                  ? 'In Progress'
                  : row.status === 'queue'
                    ? 'In Queue'
                    : 'Error')}
          </HoverCardContent>
        </HoverCardRoot>
      </div>
    )
  },
  {
    name: 'Create Date',
    selector: () => 'create_date',
    sortable: true,
    grow: 2,
    cell: (row: ReportArchiveObj) => <div className="table-cell">{row.createDate}</div>
  },
  {
    name: 'TB updated',
    selector: () => 'tracebacksUpdated',
    sortable: false,
    grow: 1,
    cell: (row: ReportArchiveObj) => (
      <div className="table-cell center">
        {row.tracebacksUpdated && <span className="span-cell red">Yes</span>}
      </div>
    )
  },

  {
    name: 'Action',
    sortable: false,
    grow: 1,
    cell: (row: ReportArchiveObj) => (
      <div className="table-cell center">
        {row.status === 'done' ? (
          <Button
            className="downloadCsvStyle pe-2 ps-2"
            onClick={() => {
              downloadReportArchiveApi(row.id);
            }}
          >
            <i className="fa fa-download pe-1" />
            {`Download`}
          </Button>
        ) : row.status === 'inProgress' ? (
          <Button
            className="pe-2 ps-2 red-button"
            onClick={async () => {
              await cancelReportArchiveApiCall(row.id);
              search();
            }}
          >
            <i className="fa fa-cancel pe-1" />
            {`Cancel`}
          </Button>
        ) : null}
      </div>
    )
  }
];

export default columns;
