import { NotificationType } from '../../interfaces/notification';
import { Pagination } from '../../interfaces/pagination';
import { ApiRequest, buildParams } from '../../lib/apiRequest';

export const getNotificationsCountApiCall = async () =>
  ApiRequest.request({
    method: 'GET',
    url: `/notifications/count`
  });

export const getAllNotificationsApiCall = async (params: Pagination) =>
  ApiRequest.request({
    method: 'POST',
    url:
      `/notifications/search?` +
      buildParams({
        ...params
      })
  });

export const deleteNotificationApiCall = async (notificationId: number) =>
  ApiRequest.request({
    method: 'DELETE',
    url: `/notification/${notificationId}`
  });

export const deleteUserNotificationsApiCall = async () =>
  ApiRequest.request({
    method: 'DELETE',
    url: `/notifications/deactivateAllUserNotifications`
  });

export const deleteNotificationByObjectApiCall = async (
  objectID: number,
  nType: NotificationType
) =>
  ApiRequest.request({
    method: 'DELETE',
    url: `/notifications/deactivate/${nType}/${objectID}`
  });
