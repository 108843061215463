import React, { Fragment, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Row } from 'reactstrap';
import Breadcrumb from '../components/Breadcrumbs';
import CampaignsSelect from '../components/CampaignsSelect';
import CustomDataTable from '../components/CustomDataTable';
import Legend from '../components/shared/Legend';
import TraceforwardsExpandedComponent from '../components/traceforward/TraceforwardExpandedComponent';
import { Pagination, defaultPagination } from '../interfaces/pagination';
import { Traceforward } from '../interfaces/traceforward';
import { FilterElement, getFilter } from '../lib/FilterElement';
import { traceforwardsColumns } from '../lib/dataTableUtils/traceforwardsColumns';
import {
  decodeQueryParams,
  encodeQueryParams,
  getFromLocalStorage,
  saveToLocalStorage
} from '../lib/history-utils';
import { getEndOfTheDay, getStartOfTheDay } from '../lib/utilities';
import { stateMappings } from '../redux/stateMappings';
import { getTraceforwardList, getTraceforwardsCSV } from '../redux/traceforward/thunks';

const PAGENAME = 'traceforwards';

interface IProps {
  traceforwards: Traceforward[];
  getTraceforwardList: Function;
  getTraceforwardsCSV: Function;
  paginationTotalRows: number;
}

const setFiltersFromHistory = (savedSearchDetails: any) => {
  const { filterElements } = savedSearchDetails;
  let campaignId = 0;
  let startDate = null;
  let endDate = null;
  let status: any[] = [];

  if (!filterElements) return { campaignId, endDate, startDate, status };
  const filterableArray = !Array.isArray(filterElements.conditionals)
    ? [filterElements]
    : filterElements.conditionals;

  filterableArray.forEach((condition: FilterElement) => {
    const { conditionals, name, value } = condition;
    switch (name) {
      case 'campaignId':
        campaignId = Number(value);
        break;
      case 'status':
        status.push(Number(value));
        break;
      case 'startDate':
        startDate = new Date(value || '');
        break;
      case 'endDate':
        endDate = new Date(value || '');
        break;

      // This defined case exists because the existing DS does not assign a name value
      // to how the code structures multiple filters of the same "type"
      case undefined:
        if (Array.isArray(conditionals)) {
          conditionals.forEach((condition: FilterElement) => {
            const { name, value } = condition;
            switch (name) {
              case 'campaignId':
                campaignId = Number(value);
                break;
              case 'status':
                status.push(Number(value));
                break;
              case 'startDate':
                startDate = new Date(value || '');
                break;
              case 'endDate':
                endDate = new Date(value || '');
                break;
            }
          });
        }
        break;
      default:
        break;
    }
  });
  return { campaignId, endDate, startDate, status };
};

const Traceforwards: React.FC<IProps> = ({
  traceforwards,
  getTraceforwardList,
  getTraceforwardsCSV,
  paginationTotalRows
}) => {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const searchParams = search || getFromLocalStorage(PAGENAME) || '';
  const [savedSearchDetails, setSavedSearchDetails] = useState(
    decodeQueryParams(PAGENAME, searchParams)
  );
  const filtersFromHistory = setFiltersFromHistory(savedSearchDetails);

  const [paginationParams, setPaginationParams] = useState(
    savedSearchDetails.paginationParams || {
      ...defaultPagination(),
      sort: 'update_date',
      order: 'desc'
    }
  );
  const [startDate, setStartDate] = useState<Date | null>(filtersFromHistory.startDate);
  const [endDate, setEndDate] = useState<Date | null>(filtersFromHistory.endDate);
  const [status, setStatus] = useState(filtersFromHistory.status || []);
  const [campaignId, setCampaignId] = useState(filtersFromHistory.campaignId);
  const [campaignIds, setCampaignIds] = useState<number[]>([]);
  const [filterElements, setFilterElements] = useState<FilterElement>({});
  const updateFilter = () => {
    setFilterElements(
      getFilter({
        status,
        campaignId,
        campaignIds,
        startDate: startDate ? getStartOfTheDay(startDate).toISOString() : '',
        endDate: endDate ? getEndOfTheDay(endDate).toISOString() : ''
      })
    );
  };

  const updatePagination = (params: Pagination) => {
    setPaginationParams(params);
  };

  const getData = () => {
    const searchParams = encodeQueryParams(
      PAGENAME,
      {
        paginationParams,
        filterElements
      },
      { campaignId: campaignId, statusNum: status }
    );

    saveToLocalStorage(PAGENAME, searchParams);
    getTraceforwardList(paginationParams, filterElements);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    updateFilter();
  }, [startDate, endDate, campaignId, campaignIds, status]);

  useEffect(() => {
    getData();
  }, [paginationParams, filterElements]);

  const handleCheck = (e: any, key: string) => {
    switch (key) {
      case 'campaignId':
        if (!e) {
          setCampaignId(0);
        } else if (typeof e === 'object') {
          if ('label' in e) {
            setCampaignId(e.value);
            setCampaignIds([]);
          } else {
            setCampaignIds(Array.from(e));
          }
        }
        break;
      case 'startDate':
        setStartDate(e);
        break;
      case 'endDate':
        setEndDate(e);
        break;
      case 'status':
        const value = Number(e.target.value);
        setStatus((v) =>
          v.includes(value) ? v.filter((item: any) => item !== value) : [...v, value]
        );
        break;
    }
  };

  const clearFilters = () => {
    const searchParams = search || getFromLocalStorage(PAGENAME) || '';
    setStatus([]);
    setCampaignId(0);
    setCampaignIds([]);
    setFilterElements({});
    setStartDate(null);
    setEndDate(null);
    setSavedSearchDetails(decodeQueryParams(PAGENAME, searchParams));
    setPaginationParams({
      ...defaultPagination(),
      sort: 'update_date',
      order: 'desc'
    });

    if (search) {
      navigate(pathname);
    }
  };

  return (
    <Fragment key="test">
      <Breadcrumb title="traceforwards" className="table-breadcrumbs" btnText="Add Traceforward" />
      <Card className="table-card hide-border mb-2">
        <CardHeader className="card-header-traceback">
          <Row>
            <Col xl="1" lg="2" sm="3">
              <DatePicker
                maxDate={endDate || new Date()}
                selected={startDate}
                onChange={(option) => handleCheck(option, 'startDate')}
                placeholderText="&#xf133; startDate"
                clearButtonTitle={'Clear'}
                isClearable
              />
            </Col>
            <Col xl="1" lg="2" sm="3">
              <DatePicker
                maxDate={new Date()}
                selected={endDate}
                minDate={startDate || undefined}
                onChange={(option) => handleCheck(option, 'endDate')}
                placeholderText="&#xf133; endDate"
                clearButtonTitle={'Clear'}
                isClearable
              />
            </Col>
            <Col xl="2" lg="3">
              <CampaignsSelect
                value={campaignId}
                onChange={(option) => handleCheck(option, 'campaignId')}
                isSearchable={true}
                addLink={false}
                placeholder="All Campaigns"
                addAllItem
                isClearable={campaignId > 0 && true}
              />
            </Col>
          </Row>

          <Row>
            <Col lg="6">
              <div className="d-flex flex-wrap media-query-wrap">
                <FormGroup className="m-0">
                  <label className="form-label checkbox-label">
                    Open
                    <input
                      type="checkbox"
                      onChange={(option) => handleCheck(option, 'status')}
                      value={1}
                      checked={status.includes(1)}
                    />
                    <span className="checkmark" />
                  </label>
                </FormGroup>
                <FormGroup className="m-0">
                  <label className="form-label checkbox-label">
                    Completed
                    <input
                      type="checkbox"
                      onChange={(option) => handleCheck(option, 'status')}
                      value={2}
                      checked={status.includes(2)}
                    />
                    <span className="checkmark" />
                  </label>
                </FormGroup>
                <FormGroup className="m-0">
                  <label className="form-label checkbox-label">
                    Not Found
                    <input
                      type="checkbox"
                      onChange={(option) => handleCheck(option, 'status')}
                      value={3}
                      checked={status.includes(3)}
                    />
                    <span className="checkmark" />
                  </label>
                </FormGroup>
                <FormGroup className="m-0">
                  <label className="form-label checkbox-label">
                    No Response
                    <input
                      type="checkbox"
                      onChange={(option) => handleCheck(option, 'status')}
                      value={4}
                      checked={status.includes(4)}
                    />
                    <span className="checkmark" />
                  </label>
                </FormGroup>
                <FormGroup className="m-0">
                  <label className="form-label checkbox-label">
                    Archived
                    <input
                      type="checkbox"
                      onChange={(option) => handleCheck(option, 'status')}
                      value={5}
                      checked={status.includes(5)}
                    />
                    <span className="checkmark" />
                  </label>
                </FormGroup>
              </div>
            </Col>
            <Col lg="6">
              <FormGroup className="d-flex justify-content-lg-end m-0">
                <Button
                  className="downloadCsvStyle"
                  onClick={() => getTraceforwardsCSV(filterElements, paginationParams)}
                >
                  <i className="fa fa-download" />
                  {` Download CSV`}
                </Button>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm="1" className="p-0">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  clearFilters();
                }}
              >
                Clear
              </button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="card-body-traceback">
          <CustomDataTable
            columns={traceforwardsColumns}
            defaultSortFieldId={paginationParams.sort}
            defaultSortAsc={paginationParams.order === 'asc'}
            defaultPage={paginationParams.page}
            defaultPageSize={paginationParams.pageSize}
            pagination={true}
            paginationTotalRows={paginationTotalRows}
            tableData={traceforwards}
            updatePaginationParams={updatePagination}
            useExpandableRows={true}
            useExpandableRowsComponent={TraceforwardsExpandedComponent}
          />
        </CardBody>
      </Card>

      <div className="d-flex justify-content-center pt-4 pb-4">
        <Legend />
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    traceforwards: sm.traceforward.traceforwards,
    paginationTotalRows: sm.traceforward.meta.TotalCount
  };
};

const mapActionsToProps = {
  getTraceforwardList,
  getTraceforwardsCSV
};

export default connect(mapStateToProps, mapActionsToProps)(Traceforwards);
