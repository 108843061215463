import { ChartOptions } from 'chart.js';
import React, { Fragment, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { ChartFilterElements } from '../../interfaces/charts';
import { dataOriginSigned, setChartLegendPosition } from '../../lib/chart/data';
import { stateMappings } from '../../redux/stateMappings';
import { getOriginStats } from '../../redux/stirShaken/thunks';

const options: ChartOptions<'pie'> = {
  responsive: true,
  maintainAspectRatio: false
};

interface IProps {
  originStats: Object;
  getOriginStats: Function;
  chartFilters: ChartFilterElements;
  height?: number;
  width?: number;
  legendPosition: string;
}

const OriginSignedStatsChart: React.FC<IProps> = ({
  originStats,
  getOriginStats,
  height,
  width,
  legendPosition,
  chartFilters
}) => {
  const dataActions = dataOriginSigned(originStats);

  useEffect(() => {
    getOriginStats(chartFilters);
  }, [chartFilters.labelId, chartFilters.startDate, chartFilters.endDate, chartFilters.sourceId]);

  return (
    <Fragment>
      {dataActions && dataActions.datasets[0].data.length > 0 ? (
        <div style={{ width: `${width}px`, height: `${height}px` }}>
          <Pie options={setChartLegendPosition(legendPosition, options)} data={dataActions} />
        </div>
      ) : (
        <h5 className="pt-5">No Data Available</h5>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    originStats: sm.stirShaken.originStats
  };
};

const mapActionsToProps = {
  getOriginStats
};

export default connect(mapStateToProps, mapActionsToProps)(OriginSignedStatsChart);
