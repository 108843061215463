import { GET_ZEROBOUNCE } from './actionEnum';

const defaultState: any = {
  zeroBounceResponse: {}
};

const reducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case GET_ZEROBOUNCE:
      return {
        ...state,
        zeroBounceResponse: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
