import { convertToTimeZone } from 'date-fns-timezone';
import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router';
import Select, { OnChangeValue } from 'react-select';
import {
  Button,
  CardBody,
  CardHeader,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import { userTypes } from '../../enum/userTypes';
import { Pagination, defaultPagination } from '../../interfaces/pagination';
import { FilterElement, getFilter, newConditionalFilterElement } from '../../lib/FilterElement';
import { requestResultsColumns } from '../../lib/dataTableUtils/requestsColumns';
import {
  downloadPartnerReport,
  searchRequestsResults,
  togglePublish
} from '../../redux/request/apiCalls';
import { selectUser, stateMappings } from '../../redux/stateMappings';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import CustomDataTable from '../CustomDataTable';
import { SelectOption } from '../CustomSelect';
import ExpandableResult from './ExpandableResult';
import RequestorSelect from './RequestorSelect';
import CampaignsSelect from '../CampaignsSelect';
import { ReportPostData } from '../../interfaces/report';
import { sub } from 'date-fns';
import { getEndOfTheDay, getStartOfTheDay } from '../../lib/utilities';
import { PartnerStatsWithTracebackInfo, PublishRequestIncident } from '../../interfaces/request';
import {
  decodeQueryParams,
  encodeQueryParams,
  getFromLocalStorage,
  saveToLocalStorage
} from '../../lib/history-utils';
import { setRequestsFiltersFromHistory } from './Requests';
import { SerializedError } from '@reduxjs/toolkit';

const getTracebackStatuses = () => [
  { label: 'Completed', value: 2 },
  { label: 'Not Found', value: 3 },
  { label: 'No Response', value: 4 }
];
const PAGENAME = 'results';

const Results: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser);
  const results = useAppSelector((state) => stateMappings(state).request.results);
  const paginationTotalRows = useAppSelector(
    (state) => stateMappings(state).request.resultsMeta.TotalCount
  );
  const savedSearchDetails = useMemo(
    () => decodeQueryParams(PAGENAME, getFromLocalStorage(PAGENAME) || ''),
    []
  );
  const filters = useMemo(
    () => setRequestsFiltersFromHistory(savedSearchDetails),
    [savedSearchDetails]
  );
  const [collapseFilters, setCollapseFilters] = useState(false);
  const [endDate, setEndDate] = useState<string>(filters.endDate || '');
  const [startDate, setStartDate] = useState<string>(filters.startDate || '');
  const [requestor, setRequestor] = useState<number>(filters.requestor);
  const [campaignId, setCampaignId] = useState<number | undefined>(filters.campaignId);
  const [status, setStatus] = useState<SelectOption[]>(filters.tbStatus);
  const [pagination, setPagination] = useState(
    savedSearchDetails.paginationParams || {
      ...defaultPagination(),
      sort: 'create_date',
      order: 'desc'
    }
  );
  const [filterElements, setFilterElements] = useState<FilterElement>(
    savedSearchDetails.filterElements || newConditionalFilterElement('')
  );
  const [resultsStatus, setResultsStatus] = useState<{ id: number; published: boolean }[]>([]);
  const [resultsToPublish, setResultsToPublish] = useState<PublishRequestIncident[]>([]);
  const [unpublishedModalOpen, setUnpublishedModalOpen] = useState(false);
  const [unpublishedResult, setUnublishedResult] = useState<PublishRequestIncident>();

  useEffect(() => {
    const searchParams = encodeQueryParams(
      PAGENAME,
      {
        filterElements: filterElements,
        paginationParams: pagination
      },
      {}
    );
    saveToLocalStorage(PAGENAME, searchParams);
    dispatch(searchRequestsResults({ pagination, filterElements })).then((data) => {
      const error = (data as any).error as SerializedError;
      if (error && error.message === "user doesn't have requestors") {
        navigate('/');
      }
    });
  }, [pagination, filterElements]);

  useEffect(() => {
    if (results && results.length)
      setResultsStatus(
        results.map((result: PartnerStatsWithTracebackInfo) => ({
          id: result.tracebackId,
          published: result.published
        }))
      );
  }, [results]);
  useEffect(() => {
    setFilterElements(
      getFilter({
        startDate: startDate,
        endDate: endDate,
        requestorId: requestor,
        campaignId: campaignId,
        status: status.map((s) => s.value)
      })
    );
  }, [startDate, endDate, requestor, campaignId, status]);

  const handleChange = (e: any, key: string) => {
    let date = new Date(Date.parse(e));
    switch (key) {
      case 'startDate':
        if (e) {
          const startDate = new Date(
            Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
          );
          setStartDate(startDate.toISOString());
        } else setStartDate(e);
        break;
      case 'endDate':
        if (e) {
          const endDate = new Date(
            Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)
          );
          setEndDate(endDate.toISOString());
        } else setEndDate(e);
        break;
      case 'requestor':
        setRequestor(e);
        break;
    }
  };

  const handleCampaignChange = (selectedOption: OnChangeValue<SelectOption, boolean>) => {
    if (selectedOption) setCampaignId((selectedOption as SelectOption).value);
    else setCampaignId(undefined);
  };

  const toggleFilters = () => {
    setCollapseFilters((v) => !v);
  };

  const updatePagination = (params: Pagination) => {
    setPagination({ ...pagination, ...params });
  };

  const togglePublishResult = (checked: boolean, requestorId: number, tracebackId: number) => {
    setResultsStatus(
      resultsStatus.map((result) =>
        result.id === tracebackId ? { id: result.id, published: checked } : result
      )
    );
    const publishedIncident: PublishRequestIncident = {
      requestorId: requestorId,
      tracebackId: tracebackId,
      published: checked
    };
    checked
      ? setResultsToPublish((prevArray) => [...prevArray, publishedIncident])
      : setResultsToPublish((prevArray) =>
          prevArray.filter((p: PublishRequestIncident) => p.tracebackId !== tracebackId)
        );
  };

  const publishResults = async () => {
    await dispatch(togglePublish(resultsToPublish));
    await dispatch(searchRequestsResults({ pagination, filterElements }));
    setResultsToPublish([]);
  };

  const selectResultToUnpublish = (tracebackId: number, requestorId: number) => {
    setUnpublishedModalOpen(true);
    const unpublishedIncident: PublishRequestIncident = {
      requestorId: requestorId,
      tracebackId: tracebackId,
      published: false
    };
    setUnublishedResult(unpublishedIncident);
  };

  const unpublishResult = async () => {
    await dispatch(togglePublish(unpublishedResult ? [unpublishedResult] : []));
    await dispatch(searchRequestsResults({ pagination, filterElements }));
  };

  const downloadReport = () => {
    const reportFilters: ReportPostData = {
      name: '',
      narrativeIntro: '',
      startDate: startDate || getStartOfTheDay(sub(new Date(), { days: 60 })).toISOString(),
      requestorId: requestor,
      includeStirShaken: true,
      endDate: endDate || getEndOfTheDay(new Date()).toISOString(),
      campaignId: campaignId
    };
    dispatch(downloadPartnerReport(reportFilters));
  };
  return (
    <div>
      <CardHeader className="card-header-requests">
        <CardHeader
          className="card-header-filters"
          onClick={() => toggleFilters()}
          data-type="collapseBanner"
        >
          Filters
          <i
            className={`filters fa-solid ${collapseFilters ? 'fa-arrow-up' : 'fa-arrow-down'}`}
          ></i>
        </CardHeader>
        <Collapse isOpen={collapseFilters}>
          <CardBody>
            <div className="row">
              <div className="fixed-column">
                <label className="telecom-label">Start Date</label>
              </div>
              <div className="fixed-column">
                <label className="telecom-label">End Date</label>
              </div>
              <div className="fixed-column">
                <label className="telecom-label">Partner/Requestor</label>
              </div>
              {user.roleType !== userTypes.Admin && (
                <Fragment>
                  <div className="fixed-column">
                    <label className="telecom-label">Campaigns</label>
                  </div>
                  <div className="fixed-column">
                    <label className="telecom-label">Status</label>
                  </div>
                </Fragment>
              )}
            </div>
            <div className="row m-0">
              <div className="fixed-column">
                <DatePicker
                  maxDate={endDate ? convertToTimeZone(endDate, { timeZone: 'GMT' }) : new Date()}
                  selected={
                    startDate ? convertToTimeZone(startDate, { timeZone: 'GMT' }) : undefined
                  }
                  onChange={(option) => handleChange(option, 'startDate')}
                  placeholderText="&#xf133; mm/dd/yyyy"
                  isClearable
                />
              </div>
              <div className="fixed-column">
                <DatePicker
                  maxDate={new Date()}
                  selected={endDate ? convertToTimeZone(endDate, { timeZone: 'GMT' }) : undefined}
                  minDate={
                    startDate ? convertToTimeZone(startDate, { timeZone: 'GMT' }) : undefined
                  }
                  onChange={(option) => handleChange(option, 'endDate')}
                  placeholderText="&#xf133; mm/dd/yyyy"
                  isClearable
                />
              </div>
              <div className="fixed-column">
                <RequestorSelect
                  isSearchable
                  isClearable
                  className={'customselect-small'}
                  option={requestor}
                  onChange={(option: any) => setRequestor(option ? option.value : 0)}
                />
              </div>
              {user.roleType === userTypes.Admin ? (
                <div className="col d-flex flex-column align-items-end">
                  <div className="d-flex flex-row">
                    <Button color="primary" onClick={() => navigate('/partners/event/add')}>
                      Add Event
                    </Button>
                    <Button
                      color="primary ms-5"
                      disabled={!resultsToPublish.length}
                      onClick={async () => await publishResults()}
                    >
                      Publish
                    </Button>
                  </div>
                </div>
              ) : (
                <Fragment>
                  <div className="fixed-column">
                    <CampaignsSelect
                      value={campaignId}
                      onChange={handleCampaignChange}
                      isClearable
                      selectClass="customselect-small"
                      placeholder="Select Campaign"
                    />
                  </div>
                  <div className="fixed-column">
                    <Select
                      className="customselect-small"
                      classNamePrefix="customselect"
                      options={getTracebackStatuses()}
                      isMulti
                      isSearchable={false}
                      placeholder="All statuses"
                      onChange={(e: any) => setStatus(e)}
                      value={status}
                    />
                  </div>
                  {user.roleType !== userTypes.GovUser && (
                    <div className="col d-flex flex-column align-items-end mt-3">
                      <Button
                        onClick={() => {
                          downloadReport();
                        }}
                        disabled={!requestor}
                        style={{
                          height: '28px',
                          padding: '5px',
                          fontSize: '14px'
                        }}
                      >
                        <i className="fa fa-download" />
                        {` Download Report`}
                      </Button>
                    </div>
                  )}
                </Fragment>
              )}
            </div>
          </CardBody>
        </Collapse>
      </CardHeader>
      <div className="mt-4 p-4">
        <CustomDataTable
          tableData={results}
          columns={requestResultsColumns(user, togglePublishResult, resultsStatus)}
          defaultSortFieldId={pagination.sort}
          defaultSortAsc={pagination.order === 'asc'}
          defaultPage={pagination.page}
          defaultPageSize={pagination.pageSize}
          useExpandableRows={true}
          pagination
          paginationTotalRows={paginationTotalRows}
          updatePaginationParams={updatePagination}
          useExpandableRowsComponent={({ data }) => (
            <ExpandableResult
              data={data}
              user={user}
              selectResultToUnpublish={selectResultToUnpublish}
            />
          )}
        />
      </div>
      <Modal
        centered
        isOpen={unpublishedModalOpen}
        className="submit-confirm-modal"
        toggle={() => setUnpublishedModalOpen((v) => !v)}
      >
        <ModalHeader toggle={() => setUnpublishedModalOpen((v) => !v)} />
        <ModalBody>
          <h5 className="traceback-sub-title">
            Withdrawing a published Traceback based on a partner Request will remove it in the
            portal but the information may already have been downloaded in a report. Are you sure
            you want to continue?
          </h5>
        </ModalBody>
        <ModalFooter style={{ margin: 'auto' }}>
          <Button
            className="btn-default telecom-btn"
            color="light"
            onClick={() => setUnpublishedModalOpen((v) => !v)}
          >
            Cancel
          </Button>
          <Button
            className="telecom-btn red"
            onClick={async () => {
              setUnpublishedModalOpen((v) => !v);
              await unpublishResult();
            }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Results;
