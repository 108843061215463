import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip
} from 'chart.js';
import React, { Fragment, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { CardTitle } from 'reactstrap';
import { ChartFilterElementsForProviderSummary } from '../../interfaces/charts';
import { dataActionsOriginProviders, setChartLegendPosition } from '../../lib/chart/data';
import { getOriginStats } from '../../redux/hop/thunks';
import { getProviderActionStats } from '../../redux/provider/thunks';
import { stateMappings } from '../../redux/stateMappings';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  BarController,
  LineController,
  ArcElement
);

const options: ChartOptions<'pie'> = {
  plugins: {
    title: {
      display: false,
      text: 'Actions Taken by Origin Providers'
    },
    legend: {}
  },
  responsive: true,
  maintainAspectRatio: false
};

interface IProps {
  originStats: Object;
  providerOriginStats: Object;
  getOriginStats: Function;
  getProviderActionStats: Function;
  chartFilters: ChartFilterElementsForProviderSummary;
  providerId?: number;
  height?: number;
  width?: number;
  legendPosition: string;
}

const OriginProvidersActionsChart: React.FC<IProps> = ({
  originStats,
  providerOriginStats,
  getOriginStats,
  getProviderActionStats,
  providerId,
  height,
  width,
  legendPosition,
  chartFilters
}) => {
  const dataActions = dataActionsOriginProviders(providerId ? providerOriginStats : originStats);

  useEffect(() => {
    if (providerId) {
      getProviderActionStats(chartFilters, providerId);
    } else {
      getOriginStats(chartFilters);
    }
  }, [
    chartFilters.labelId,
    chartFilters.note,
    chartFilters.termState,
    chartFilters.startDate,
    chartFilters.endDate,
    chartFilters.sourceId
  ]);

  return (
    <Fragment>
      {dataActions && dataActions.datasets[0].data.length > 0 && (
        <div className="originChartCol col-xl-4 d-flex justify-content-center">
          <div className="text-center">
            <CardTitle tag="h4">Actions Taken by Provider</CardTitle>
            <div style={{ width: `${width}px`, height: `${height}px` }}>
              <Pie options={setChartLegendPosition(legendPosition, options)} data={dataActions} />
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    originStats: sm.hop.originActionsStats,
    providerOriginStats: sm.provider.providerActionsStats
  };
};

const mapActionsToProps = {
  getOriginStats,
  getProviderActionStats
};

export default connect(mapStateToProps, mapActionsToProps)(OriginProvidersActionsChart);
