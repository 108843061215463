import React, { FC, useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import { Pagination, defaultPagination } from '../interfaces/pagination';
import {
  addConditionGroup,
  newConditionalFilterElement,
  simplifyFilterElement
} from '../lib/FilterElement';
import { getPartnerTbStatsApiCall, getPartnerTfStatsApiCall } from '../redux/partners/apiCalls';
import CustomDataTable from './CustomDataTable';
import {
  partnersTbStatsTableColumns,
  partnersTfStatsTableColumns
} from '../lib/dataTableUtils/partnerTables';
import { PartnerTbStats, PartnerTfStats } from '../interfaces/partners';

interface IProps {
  data?: any;
  startDate: string;
  endDate: string;
}

const PartnersExpandableRow: FC<IProps> = ({ data, startDate, endDate }) => {
  const [tbStats, setTbStats] = useState<PartnerTbStats[]>([]);
  const [tfStats, setTfStats] = useState<PartnerTfStats[]>([]);

  const [tbPaginationParams, setTbPaginationParams] = useState({
    ...defaultPagination(),
    sort: 'campaignName',
    order: 'asc',
    pageSize: 10
  });
  const [tfPaginationParams, setTfPaginationParams] = useState({
    ...defaultPagination(),
    sort: 'campaignName',
    order: 'asc',
    pageSize: 10
  });
  const [tbPaginationMeta, setTbPaginationMeta] = useState<any>({});
  const [tfPaginationMeta, setTfPaginationMeta] = useState<any>({});

  const getFilterElement = () => {
    let filterElements = newConditionalFilterElement('AND');

    addConditionGroup('AND', filterElements, [
      { key: startDate ? 'startDate' : '', value: startDate },
      { key: endDate ? 'endDate' : '', value: endDate },
      { key: 'requestorId', value: String(data.requestorId) }
    ]);

    return simplifyFilterElement(filterElements);
  };

  const getTbData = async () => {
    const filterElements = getFilterElement();
    try {
      const tbResponse = await getPartnerTbStatsApiCall(tbPaginationParams, filterElements);
      if (tbResponse.data) {
        setTbStats(tbResponse.data.data);
        setTbPaginationMeta(tbResponse.data.paginationMeta);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getTfData = async () => {
    const filterElements = getFilterElement();
    try {
      const tfResponse = await getPartnerTfStatsApiCall(tfPaginationParams, filterElements);
      if (tfResponse.data) {
        setTfStats(tfResponse.data.data);
        setTfPaginationMeta(tfResponse.data.paginationMeta);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleTbPaginationChange = (params: Pagination) => {
    const newParams = { ...tbPaginationParams, ...params };
    setTbPaginationParams(newParams);
  };

  const handleTfPaginationChange = (params: Pagination) => {
    const newParams = { ...tfPaginationParams, ...params };
    setTfPaginationParams(newParams);
  };

  useEffect(() => {
    getTbData();
  }, [startDate, endDate, tbPaginationParams]);

  useEffect(() => {
    getTfData();
  }, [startDate, endDate, tfPaginationParams]);

  return (
    <Card className="expandedRow">
      <CardBody>
        <CustomDataTable
          tableData={tfStats}
          columns={partnersTfStatsTableColumns}
          pagination={true}
          paginationTotalRows={tfPaginationMeta.TotalCount}
          defaultSortFieldId={'campaignName'}
          defaultSortAsc={tfPaginationParams.order === 'asc'}
          defaultPage={tfPaginationParams.page}
          defaultPageSize={tfPaginationParams.pageSize}
          updatePaginationParams={handleTfPaginationChange}
        />
        <CustomDataTable
          tableData={tbStats}
          columns={partnersTbStatsTableColumns}
          pagination={true}
          paginationTotalRows={tbPaginationMeta.TotalCount}
          defaultSortFieldId={'campaignName'}
          defaultSortAsc={tbPaginationParams.order === 'asc'}
          defaultPage={tbPaginationParams.page}
          defaultPageSize={tbPaginationParams.pageSize}
          updatePaginationParams={handleTbPaginationChange}
        />
      </CardBody>
    </Card>
  );
};

export default PartnersExpandableRow;
