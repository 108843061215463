import { User } from './actionEnum';

export const getAllUsers = (response: any) => ({
  type: User.GET_USERS,
  payload: response
});

export const setUserId = (response: any) => ({
  type: User.SET_USER_ID,
  payload: response
});

export const resetPasswordError = (response: any) => ({
  type: User.RESET_PASSWORD_ERROR,
  payload: response
});

export const addUser = (response: any) => ({
  type: User.ADD_USER,
  payload: response
});

export const setLoadingStatus = (status: boolean) => ({
  type: User.SET_USER_LOADING_STATUS,
  payload: status
});

export const setErrorMessage = (message: string) => ({
  type: User.SET_USER_ERROR_MESSAGE,
  payload: message
});

export const getRedirectLoc = (location: string) => ({
  type: User.GET_REDIRECT_LOCATION,
  payload: location
});
