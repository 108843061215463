import React, { FC } from 'react';
import { HoverCardContent, HoverCardRoot, HoverCardTrigger } from './HoverCard';

interface IProps {
  tooltipVisible: boolean;
  message: string;
  children: React.ReactNode;
}
const CustomToolTip: FC<IProps> = ({ tooltipVisible, message, children }) =>
  tooltipVisible ? (
    <HoverCardRoot openDelay={150}>
      <HoverCardTrigger asChild={true}>
        <span>{children}</span>
      </HoverCardTrigger>
      <HoverCardContent className="hoverCardGovWidth z-3" sideOffset={5}>
        {message}
      </HoverCardContent>
    </HoverCardRoot>
  ) : (
    <span>{children}</span>
  );

export default CustomToolTip;
