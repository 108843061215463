import { Report } from './actionEnum';

const meta = {
  TotalCount: 0,
  PageSize: 0,
  CurrentPage: 0,
  TotalPages: 1
};

const defaultState: any = {
  report: {},
  loading: 0,
  error: '',
  archivedReportsMeta: meta,
  meta
};

const reducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case Report.GET_REPORT:
      return { ...state, report: action.payload.data };
    case Report.GET_ROLLUP_REPORT:
      return { ...state, rollupReport: action.payload.data };
    case Report.GET_ARCHIVED_REPORTS:
      return {
        ...state,
        archivedReports: action.payload.data,
        meta: action.payload.paginationMeta || meta
      };
    case Report.GET_ARCHIVED_REPORTS_LIST:
      return {
        ...state,
        archivedReportsList: action.payload.data,
        archivedReportsMeta: action.payload.paginationMeta || meta
      };

    case Report.SET_REPORT_LOADING_STATUS:
      if (action.payload) return { ...state, loading: state.loading + 1 };
      else return { ...state, loading: state.loading === 0 ? state.loading : state.loading - 1 };
    case Report.SET_REPORT_ERROR_MESSAGE:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default reducer;
