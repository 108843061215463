//This file holds the API calls that hit the /user route for DRY purposes
import { CancelToken } from 'axios';
import { ApiRequest } from '../../lib/apiRequest';

export const getAllRMDsApiCall = async (source: CancelToken, name: string) =>
  ApiRequest.request({
    method: 'POST',
    url: `/rmds/search`,
    data: { name },
    cancelToken: source
  });
