import React, { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { CardBody } from 'reactstrap';
import { DateFormat } from '../../enum/DateFormat';
import {
  StirShakenProviderNotInCallPathRequest,
  StirShakenProviderNotInCallPathStats,
  StirShakenProviderNotInCallPathTraceback
} from '../../interfaces/stirShaken';
import { buildParams } from '../../lib/apiRequest';
import { getClientFormattedDate } from '../../lib/utilities';
import { getStirShakensProviderIssuesApiCall } from '../../redux/stirShaken/apiCalls';

const columns: TableColumn<StirShakenProviderNotInCallPathTraceback>[] = [
  {
    name: 'Campaign Name',
    selector: (r) => r.campaignName,
    id: 'column-campaign',
    sortable: true,
    grow: 2,
    cell: (row) => (
      <span className=" table-cell blue center">
        <a href={`/campaigns/campaign/${row.campaignId}`}>{row.campaignName}</a>
      </span>
    )
  },
  {
    name: 'Campaign Label',
    selector: (r) => r.campaignLabel || '',
    id: 'column-label',
    sortable: true,
    grow: 2,
    cell: (row) => <span className=" table-cell gray center"> {row.campaignLabel}</span>
  },
  {
    name: 'Date',
    selector: (r) => r.tracebackTime.toString(),
    id: 'column-date',
    sortable: true,
    grow: 2,
    cell: (row) => (
      <span className=" table-cell gray center">
        {getClientFormattedDate(row.tracebackTime, DateFormat.MediumBoth)}
      </span>
    )
  },
  {
    name: 'TB',
    selector: (r) => r.tracebackId,
    id: 'column-traceback-id',
    sortable: true,
    grow: 1,
    cell: (row) => (
      <div className="table-cell blue center">
        <a href={'/hops?' + buildParams({ tracebackId: row.tracebackId })}>#{row.tracebackId}</a>
      </div>
    )
  },
  {
    name: 'Attestation',
    selector: (r) => r.attest,
    id: 'column-attest',
    sortable: true,
    grow: 1,
    cell: (row) => <span className=" table-cell gray center"> {row.attest}</span>
  },
  {
    name: 'Signer',
    selector: (r) => r.signerCommonName,
    id: 'column-signer',
    sortable: true,
    grow: 2,
    cell: (row) => <span className=" table-cell gray center"> {row.signerCommonName}</span>
  },
  {
    name: 'Issuer',
    selector: (r) => r.issuerCommonName,
    id: 'column-issuer',
    sortable: true,
    grow: 2,
    cell: (row) => <span className=" table-cell gray center"> {row.issuerCommonName}</span>
  }
];

interface IProps {
  row?: StirShakenProviderNotInCallPathStats;
  filter: StirShakenProviderNotInCallPathRequest;
}

const StirShakenNotInCallPathExpandableRow: React.FC<IProps> = ({ row, filter }) => {
  const [tableData, setTableData] = useState<StirShakenProviderNotInCallPathTraceback[]>([]);

  useEffect(() => {
    if (!row) return;
    row?.providerId &&
      getStirShakensProviderIssuesApiCall({
        ...filter,
        providerId: row?.providerId
      })
        .then((response) => {
          setTableData(response.data || []);
        })
        .catch(() => setTableData([]));
  }, [row]);

  return (
    <div className="data-table">
      <CardBody className="py-2 px-3">
        <DataTable
          data={tableData}
          columns={columns}
          defaultSortFieldId={'tr.campaignName'}
          defaultSortAsc={false}
          noHeader
          className={'providers-expanded'}
        />
      </CardBody>
    </div>
  );
};

export default StirShakenNotInCallPathExpandableRow;
