import React from 'react';
import { connect } from 'react-redux';
import { userTypes } from '../enum/userTypes';
import { Provider } from '../interfaces/provider';
import { PersonalInfo } from '../interfaces/user';
import { stateMappings } from '../redux/stateMappings';
import ColumnTooltip from './ColumnTooltip';
import ReputationDetails from './stateConverter';

interface IProps {
  user: PersonalInfo;
  provider: Provider;
  hyperlink?: boolean;
  linkOverride?: string;
  width?: string;
  includeReputation?: boolean;
  hideITG?: boolean;
  rmdFlag?: string;
  excludeEllipses?: boolean;
}

const ProviderPanel: React.FC<IProps> = ({
  width,
  provider,
  linkOverride,
  hyperlink,
  user,
  hideITG,
  includeReputation,
  rmdFlag,
  excludeEllipses
}) => {
  return (
    <div style={{ width: width ? width : '' }}>
      {provider && provider.name && (
        <div className="container" style={{ display: 'flex', padding: '0px' }}>
          {linkOverride ? (
            <a
              href={linkOverride}
              className={`span-cell blue me-2 ${!excludeEllipses ? 'ellipses' : ''}`}
              title={provider.name}
            >
              {provider.name}
            </a>
          ) : hyperlink && provider.providerId ? (
            <a
              href={
                user.roleType === userTypes.GovUser
                  ? `/providers/provider/summaries/${provider.providerId}`
                  : `/providers/provider/${provider.providerId}`
              }
              className={`span-cell me-2 ${!excludeEllipses ? 'ellipses' : ''}`}
              style={{ color: '#0F72B1' }}
              title={provider.name}
            >
              {provider.name}
            </a>
          ) : (
            <span
              className={`span-cell me-2 ${!excludeEllipses ? 'ellipses' : ''}`}
              title={provider.name}
            >
              {provider.name}
            </span>
          )}

          {!hideITG &&
            (provider.isITGMember && provider.isDomestic ? (
              <span className="fa fa-circle" style={{ color: '#8DD920' }} title={'ITG US'} />
            ) : provider.isITGMember && !provider.isDomestic ? (
              <span className="fa fa-circle" style={{ color: '#349FFA' }} title={'ITG Non-US'} />
            ) : !provider.isITGMember && provider.isDomestic ? (
              <span className="fa fa-circle" style={{ color: '#C6C9D0' }} title={'Non-ITG US'} />
            ) : (
              <span
                className="fa fa-circle"
                style={{ color: '#797E8C' }}
                title={'Non-ITG Non-US'}
              />
            ))}

          {includeReputation && provider.reputation && (
            <span className="me-2 " style={{ fontSize: '10px', alignSelf: 'center' }}>
              <ReputationDetails reputation={provider.reputation} />
            </span>
          )}
          {rmdFlag && <RMDFlag rmdFlag={rmdFlag} />}
        </div>
      )}
    </div>
  );
};

interface IPropsRMDFlag {
  rmdFlag: string;
}
export const RMDFlag: React.FC<IPropsRMDFlag> = ({ rmdFlag }) => (
  <ColumnTooltip columnType={rmdFlag} tooltipVisible>
    {' '}
    <span className="text-red">RMD</span>
  </ColumnTooltip>
);

const mapStateToProps = (state: any, ownProps: any) => {
  const sm = stateMappings(state);
  return { user: sm.user };
};

export default connect(mapStateToProps)(ProviderPanel);
