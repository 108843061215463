import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { StirShakenReponse } from '../../interfaces/stirShaken';
import { Traceback } from '../../interfaces/traceback';
import { PersonalInfo } from '../../interfaces/user';
import { certificateExpiredError, stirshakenErrors } from '../../lib/utilities';
import { stateMappings } from '../../redux/stateMappings';
import CustomToolTip from '../CustomToolTip';
import ProviderPanel from '../ProviderPanel';
import NoSignerInCallPath from './NoSignerInCallPath';
import NotSignedByOrigin from './NotSignedByOrigin';

interface IProps {
  traceback: Traceback;
  user: PersonalInfo;
}

const SignerTracebackCellContent: React.FC<IProps> = ({ traceback, user }) => {
  const allStirShakens = useMemo(
    () =>
      (traceback.hopsStirShaken
        ? [traceback.stirShaken, ...traceback.hopsStirShaken]
        : [traceback.stirShaken]
      ).filter((v) => !!v),
    [traceback.hopsStirShaken, traceback.stirShaken]
  );
  const stirShakensValidErrors = useMemo(
    () =>
      allStirShakens.filter(
        (v) => v.errorMessage && stirshakenErrors(v.errorMessage, user.roleType, false).valid
      ),
    [allStirShakens, user.roleType]
  );
  const stirShakens: StirShakenReponse[] = useMemo(
    () => allStirShakens.filter((v) => v.status === 0),
    [allStirShakens]
  );
  const lastManualStirShaken: StirShakenReponse | undefined = useMemo(
    () =>
      allStirShakens.find(
        (v) => v.status === 1 && v.isCallSigner && v.hopID === traceback.lastHopId
      ),
    [allStirShakens, traceback.lastHopId]
  );

  const mentionedProviders = useMemo(
    () =>
      stirShakens
        .map((v) => ({
          // map it to a simpler object
          providerId: v.signerProviderId,
          providerName: v.signerProviderName,
          hopSequence: v.hopSequence,
          organization:
            v.signer?.organization &&
            v.signer?.organization.length > 0 &&
            v.signer?.organization.join(' ')
        }))
        .filter(
          (value, index, array) =>
            (value.providerId || value.organization) && // filter only the ones with provider or organization
            array.findIndex(
              // remove duplicates
              (v) => v.providerId === value.providerId || v.organization === value.organization
            ) === index
        ),
    [stirShakens]
  );

  const signingMessage = useMemo(
    () =>
      mentionedProviders
        .map((v) => `${v.providerId ? v.providerName : v.organization} (${v.hopSequence || 0})`)
        .join('\r'),
    [mentionedProviders]
  );

  return (
    <div className="table-cell center">
      {traceback.hopsStirShakenFlag &&
        traceback.hopsStirShakenFlag.length != 0 &&
        traceback.hopsStirShakenFlag.some((v) => v === 'poeNotSigned') && (
          <div>
            <CustomToolTip
              tooltipVisible
              message="The ITG did not receive information identifying the U.S.-based Point of entry having signed this call which originated outside the U.S."
            >
              <span className="span-cell red"> POE Signing Not Provided</span>
            </CustomToolTip>
            <span className="span-cell red">
              {stirShakens.some((stirShaken: StirShakenReponse) => stirShaken.errorMessage) &&
                ' / '}
            </span>
          </div>
        )}
      {traceback.hopsStirShakenFlag &&
        traceback.hopsStirShakenFlag.length != 0 &&
        traceback.hopsStirShakenFlag.some((v) => v === 'orgNotSigned') && (
          <div>
            <NotSignedByOrigin />
            <span className="span-cell red">
              {stirShakens.some((stirShaken: StirShakenReponse) => stirShaken.errorMessage) &&
                ' / '}
            </span>
          </div>
        )}
      {traceback.hopsStirShakenFlag &&
        traceback.hopsStirShakenFlag.length != 0 &&
        traceback.hopsStirShakenFlag.some((v) => v === 'noStirShakenSignerInCallPath') && (
          <div>
            <NoSignerInCallPath />
            <span className="span-cell red">
              {stirShakens.some((stirShaken: StirShakenReponse) => stirShaken.errorMessage) &&
                ' / '}
            </span>
          </div>
        )}
      {stirShakensValidErrors.length !== 0 ? (
        <div>
          <CustomToolTip
            tooltipVisible
            message={stirShakensValidErrors
              .map((stirShaken: StirShakenReponse) =>
                stirShaken.errorMessage ? stirShaken.errorMessage : ''
              )
              .toString()}
          >
            <span className="span-cell red">Error With Signing</span>
          </CustomToolTip>
          <span className="span-cell red">{stirShakens.length > 0 && ' / '}</span>
        </div>
      ) : (
        ''
      )}
      {mentionedProviders.length > 1 ? (
        <CustomToolTip tooltipVisible message={signingMessage}>
          <span className="span-cell red">Multiple</span>
        </CustomToolTip>
      ) : (
        (mentionedProviders.length === 1 &&
          (stirShakens[0].signerProviderName ? (
            <div className="justify-content-center d-flex">
              <ProviderPanel
                hyperlink
                provider={{
                  providerId: stirShakens[0].signerProviderId,
                  name: stirShakens[0].signerProviderName,
                  isDomestic: stirShakens[0].signerIsDomestic,
                  isITGMember: stirShakens[0].signerIsITGMember
                }}
              />
            </div>
          ) : (
            <span className="span-cell gray">
              {stirShakens[0].signer ? stirShakens[0].signer.organization : null}
            </span>
          ))) ||
        (!!lastManualStirShaken && (
          <div className="justify-content-center d-flex">
            <ProviderPanel hyperlink provider={traceback.lastProvider} />
          </div>
        ))
      )}
      {stirShakens.length > 0 &&
        stirShakens[0].issuer &&
        !stirShakens[0].isIssuerValid &&
        !stirShakens[0].certificateErrors?.includes(certificateExpiredError) && (
          <div>
            <CustomToolTip
              tooltipVisible
              message="The issuer identified in the STIR/SHAKEN header is not an approved certification authority"
            >
              <span className="span-cell red">Issuer Not Approved</span>
            </CustomToolTip>
          </div>
        )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return { user: sm.user };
};

export default connect(mapStateToProps)(SignerTracebackCellContent);
