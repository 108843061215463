import { Messaging } from './actionEnum';

export const addMessageAction = (response: any) => ({
  type: Messaging.ADD_MESSAGE,
  payload: response
});

export const getMessages = (response: any) => ({
  type: Messaging.GET_MESSAGES,
  payload: response
});
export const setMessageAction = (response: any) => ({
  type: Messaging.SET_MESSAGE,
  payload: response
});
export const editMessageAction = (response: any) => ({
  type: Messaging.EDIT_MESSAGE,
  payload: response
});
export const cancelMessageAction = (response: any) => ({
  type: Messaging.CANCEL_MESSAGE,
  payload: response
});

export const reloadDataAction = (reload: boolean) => ({
  type: Messaging.RELOAD_DATA,
  payload: reload
});

export const setLoadingAction = (loading: boolean) => ({
  type: Messaging.SET_LOADING,
  payload: loading
});

export const setErrorAction = (error: string) => ({
  type: Messaging.SET_ERROR_MESSAGE,
  payload: error
});

export const getEmailEvents = (response: any) => ({
  type: Messaging.GET_EMAIL_EVENTS,
  payload: response
});
