import React, { FC, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { DateFormat } from '../../enum/DateFormat';
import { Comment } from '../../interfaces/comment';
import { getClientFormattedDate } from '../../lib/utilities';
import { getProviderCommentsObject } from '../../redux/provider/thunks';
import { stateMappings } from '../../redux/stateMappings';

interface IProps {
  providerId: number;
  comments?: Comment[];
  getProviderCommentsObject: Function;
}

const AdminsComments: FC<IProps> = ({ providerId, comments, getProviderCommentsObject }) => {
  useEffect(() => {
    providerId && getProviderCommentsObject(providerId, true);
  }, [providerId]);
  return (
    <Fragment>
      {comments &&
        comments
          .filter((v) => v.commentType === 'gov')
          .map((item: Comment, index) => (
            <div className="col mb-2 mt-4" key={index}>
              <div className="row telecom-label">
                <p className="h5" style={{ paddingLeft: '2rem' }}>
                  DATE {getClientFormattedDate(item.create_date, DateFormat.ShortDate)}
                </p>
              </div>
              <div className="row">
                <p className="row" style={{ paddingLeft: '3.5rem' }}>
                  {item.contentText}
                </p>
              </div>
              {(item.callRecordTracebacks || item.callRecordCampaigns) && (
                <div className="row telecom-label">
                  <p className="ps-2">
                    {item.callRecordCampaigns && 'Campaign '}
                    {item.callRecordTracebacks && item.callRecordCampaigns && 'and '}
                    {item.callRecordTracebacks && 'Tracebacks '} indentified
                  </p>
                </div>
              )}
            </div>
          ))}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return { comments: sm.provider.providerComments };
};

const mapActionsToProps = { getProviderCommentsObject };

export default connect(mapStateToProps, mapActionsToProps)(AdminsComments);
