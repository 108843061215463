export interface Pagination {
  order: string;
  page: number;
  pageSize: number;
  sort: string;
}

export function defaultPagination(): Pagination {
  return {
    sort: '',
    order: 'asc',
    page: 1,
    pageSize: 10
  };
}
export function defaultPaginationStirShakenSigners(): Pagination {
  return {
    sort: 'signerOrganization',
    order: 'asc',
    page: 1,
    pageSize: 10
  };
}
export function defaultPaginationNotification(): Pagination {
  return {
    sort: 'create_date',
    order: 'desc',
    page: 1,
    pageSize: 10
  };
}
