import React, { FC, Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Message } from '../../../interfaces/messaging';
import { Pagination } from '../../../interfaces/pagination';
import { FilterElement } from '../../../lib/FilterElement';
import { cancelMessage } from '../../../redux/messaging/thunks';

interface IProps {
  message: Message;
  cancelMessage: Function;
  params: Pagination;
  filters: FilterElement;
}

const CancelMessage: FC<IProps> = ({ message, cancelMessage, params, filters }) => {
  const [open, setOpen] = useState(false);
  return (
    <Fragment>
      {message.status === 'PENDING' && (
        <Fragment>
          <Button
            onClick={() => {
              setOpen(true);
            }}
          >
            <i style={{ color: 'red' }} className="fa-solid fa-xmark" />
          </Button>

          <Modal
            centered
            isOpen={open}
            className="submit-confirm-modal"
            toggle={() => setOpen((prev) => !prev)}
          >
            <ModalHeader toggle={() => setOpen((prev) => !prev)}>
              Confirm Cancel Message !
            </ModalHeader>
            <ModalBody className="delete-modal-body">
              Are you sure you want to cancel the following message #{message.id} :
              <ul style={{ margin: '24px auto 36px -24px' }}>
                <li>Subject: {message.subject}</li>
                <li>Send Date: {message.sendDate}</li>
                <li>Body: {message.body}</li>
              </ul>
            </ModalBody>
            <ModalFooter className="m-auto">
              <div style={{ gap: '12px' }}>
                <Button
                  className="btn-default telecom-btn"
                  color="light"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  className="telecom-btn ms-2"
                  color="danger"
                  onClick={() => {
                    cancelMessage(message.id, params, filters);
                    setOpen(false);
                  }}
                >
                  Proceed
                </Button>
              </div>
            </ModalFooter>
          </Modal>
        </Fragment>
      )}
    </Fragment>
  );
};

const mapActionsToProps = { cancelMessage };
export default connect(null, mapActionsToProps)(CancelMessage);
