import { Notifications } from './actionEnum';

const defaultState: any = {
  notifications: [],
  notification: {},
  notificationsCount: null,
  loading: 0,
  error: ''
};

const reducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case Notifications.GET_NOTIFICATIONS_COUNT:
      return {
        ...state,
        notificationsCount: action.payload
      };
    case Notifications.GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload.data || [],
        notificationsCount: action.payload.totalCount
      };
    case Notifications.SET_NOTIFICATION_LOADING_STATUS:
      if (action.payload) return { ...state, loading: state.loading + 1 };
      else return { ...state, loading: state.loading === 0 ? state.loading : state.loading - 1 };
    case Notifications.SET_NOTIFICATION_ERROR_MESSAGE:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};
export default reducer;
