import React, { FC, Fragment, useState } from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import { userTypes } from '../../enum/userTypes';
import { Comment, CommentPostData } from '../../interfaces/comment';
import { PartnerStatsWithTracebackInfo } from '../../interfaces/request';
import { PersonalInfo } from '../../interfaces/user';
import { DnoToolTip } from '../../lib/dno';
import { getClientFormattedDate } from '../../lib/utilities';
import { addCommentApiCall, deleteCommentApiCall } from '../../redux/comment/apiCalls';
import CustomToolTip from '../CustomToolTip';
import ProviderPanel from '../ProviderPanel';
import InputFormGroup from '../inputFormGroup';

interface IProps {
  data: PartnerStatsWithTracebackInfo;
  user: PersonalInfo;
  selectResultToUnpublish: (tracebackId: number, requestorId: number) => void;
}

const ExpandableResult: FC<IProps> = ({ data, user, selectResultToUnpublish }) => {
  const [openCommentSection, setCommentSection] = useState(false);
  const [commentText, setCommentText] = useState('');
  const [tracebackComment, setTracebackComment] = useState<Comment | null>(data.comment);

  const submitResultComment = async () => {
    const comment: CommentPostData = {
      commentType: 'tracebackResult',
      relatedObjectId: data.tracebackId,
      contentText: commentText
    };
    try {
      const res = await addCommentApiCall(comment);
      if (res && res.data) {
        setTracebackComment(res.data.data);
      }
      setCommentSection(false);
      setCommentText('');
    } catch (error: any) {}
  };

  const deleteResultComment = async (commentId: number) => {
    try {
      const res = await deleteCommentApiCall(commentId);
      if (res && res.data) setTracebackComment(null);
    } catch (error: any) {}
  };
  return (
    <Card className="expandedRow">
      <CardBody style={{ color: '#797979', fontSize: '1rem', padding: '0.5rem 1rem' }}>
        {user.roleType === userTypes.Admin && (
          <div className="d-flex justify-content-end">
            {data.published && (
              <Button
                color="primary"
                onClick={() => selectResultToUnpublish(data.tracebackId, data.requestorId)}
              >
                Unpublish
              </Button>
            )}
            {!tracebackComment && !openCommentSection && (
              <Button className="ms-3" color="primary" onClick={() => setCommentSection((v) => !v)}>
                Add Comment
              </Button>
            )}
          </div>
        )}
        <div className="row">
          <div className="col">
            <div className="d-flex flex-row">Total Hops: {data.numberOfHops}</div>
            {data.tracebackTime === data.callTime || !data.requestId ? (
              <div className="d-flex flex-row">
                Call Time: {getClientFormattedDate(data.tracebackTime)}
              </div>
            ) : (
              <>
                <div className="d-flex flex-row">
                  Call Time: {getClientFormattedDate(data.callTime)}
                </div>
                <div className="d-flex flex-row">
                  Corrected Call Time: {getClientFormattedDate(data.tracebackTime)}
                </div>
              </>
            )}
            <div className="d-flex flex-row">Called Number: {data.calledTN}</div>
            <div className="d-flex flex-row">Calling Number: {data.callingTN}</div>
            <div className="d-flex flex-row">Campaign Label: {data.campaignLabel}</div>
            <div className="d-flex flex-row">Terminating Line Type: {data.termLineType}</div>
            <div className="d-flex flex-row">
              <label className="me-1">Do Not Origin Registry:</label>
              <CustomToolTip
                message={
                  DnoToolTip.find((v) => v.type === data.dnoType)?.description || data.dnoType || ''
                }
                tooltipVisible={!!data.dnoType}
              >
                <span>{data.dno ? 'Industry DNO' : 'Not Listed'}</span>
              </CustomToolTip>
            </div>
            <div className="d-flex flex-row">Do Not Call Registry: {data.dnc || 'None'}</div>
            {data.includeStirShaken && (
              <>
                <div className="d-flex flex-row">
                  STIR/SHAKEN Attestation: {data.stirShakenAttest ? data.stirShakenAttest : 'N/A'}
                </div>
                <div className="d-flex flex-row">
                  STIR/SHAKEN Signer: {data.stirShakenSigner ? data.stirShakenSigner : 'N/A'}
                </div>
              </>
            )}
            {data.includeSignerPath && (
              <div className="d-flex flex-row">
                Intermediate between signer and origin:{' '}
                {data.stirShakenIntermediate ? data.stirShakenIntermediate : 'N/A'}
              </div>
            )}
          </div>
          {!data.strikeExempt ? (
            <div className="col">
              {data.status !== 'Pending' && (
                <div>
                  <div className="d-flex flex-row ">
                    Originating Provider:
                    <div className="ms-2">
                      <ProviderPanel
                        provider={{
                          name: data.originatingProvider.providerName,
                          providerId: data.originatingProvider.providerId
                        }}
                        hyperlink={
                          user.roleType === userTypes.Admin || user.roleType === userTypes.GovUser
                        }
                        hideITG
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-row">
                    Originating Provider Country: {data.originatingProvider.providerCountry}
                  </div>
                  <div className="d-flex flex-row">
                    Originating Provider State: {data.originatingProvider.providerState}
                  </div>
                  <div className="d-flex flex-row">
                    Originating Provider City: {data.originatingProvider.providerCity}
                  </div>
                </div>
              )}
              {data.status === 'Complete' && (
                <>
                  {user.roleType !== userTypes.GovUser && (
                    <>
                      {data.originatingProvider.customer && (
                        <div className="d-flex flex-row">
                          Buissnes Name: {data.originatingProvider.customer}
                        </div>
                      )}
                      {data.originatingProvider.contactName && (
                        <div className="d-flex flex-row">
                          Contact Name: {data.originatingProvider.contactName}
                        </div>
                      )}
                      {data.originatingProvider.contactEmail && (
                        <div className="d-flex flex-row">
                          Contact Email: {data.originatingProvider.contactEmail}
                        </div>
                      )}
                      {data.originatingProvider.contactPhone && (
                        <div className="d-flex flex-row">
                          Contact Phone: {data.originatingProvider.contactPhone}
                        </div>
                      )}
                      {data.originatingProvider.ipAddress && (
                        <div className="d-flex flex-row">
                          IP Address: {data.originatingProvider.ipAddress}
                        </div>
                      )}
                      {data.originatingProvider.customerType && (
                        <div className="d-flex flex-row">
                          Customer Type: {data.originatingProvider.customerType}
                        </div>
                      )}
                      {data.originatingProvider.country && (
                        <div className="d-flex flex-row">
                          Customer Country: {data.originatingProvider.country}
                        </div>
                      )}
                      {data.originatingProvider.city && (
                        <div className="d-flex flex-row">
                          Customer City: {data.originatingProvider.city}
                        </div>
                      )}
                      {data.originatingProvider.state && (
                        <div className="d-flex flex-row">
                          Customer State: {data.originatingProvider.state}
                        </div>
                      )}
                      {data.originatingProvider.address && (
                        <div className="d-flex flex-row">
                          Customer Address: {data.originatingProvider.address}
                        </div>
                      )}
                      {data.originatingProvider.zipCode && (
                        <div className="d-flex flex-row">
                          Customer Zip/Postal Code: {data.originatingProvider.zipCode}
                        </div>
                      )}
                      {data.originatingProvider.averageTraffic && (
                        <div className="d-flex flex-row">
                          Avg. Daily Traffic: {data.originatingProvider.averageTraffic}
                        </div>
                      )}{' '}
                      {data.originatingProvider.refuseReason && (
                        <div className="d-flex flex-row">
                          Refuse Reason: {data.originatingProvider.refuseReason}
                        </div>
                      )}
                    </>
                  )}
                  <div className="d-flex flex-row">Provider Action Taken:</div>
                  <div className="d-flex flex-row ms-2">{data.originatingProvider.actionTaken}</div>
                </>
              )}
              {data.originatingProvider.nfNr && data.originatingProvider.nfNr.length > 0 && (
                <div className="d-flex flex-row ms-2  mt-2">
                  Reason:{' '}
                  {data.originatingProvider.nfNr === 'isRecordUn' ? (
                    <span>Record Unavailable</span>
                  ) : data.originatingProvider.nfNr === 'isCallOld' ? (
                    <span>Call is too old</span>
                  ) : data.originatingProvider.nfNr === 'isRefusing' ? (
                    <span>We refuse to share this information</span>
                  ) : (
                    <span>Provider is non-responsive</span>
                  )}
                </div>
              )}
              <div className="d-flex flex-row ms-2">
                Explanation: {data.originatingProvider.explanation}
              </div>
            </div>
          ) : (
            <div className="col">
              <p>Traceback has been marked as Strike Exempt</p>
              <p>
                The ITG team no longer finds the traceback and its results reliable. Reasons the ITG
                team may mark a traceback as SE include but are not limited to the ITG no longer has
                a reasonable basis to suspect the call subject to the traceback is illegal.
              </p>
            </div>
          )}
          {data.downstreamProvider.providerId !== 0 && (
            <div className="col">
              <div className="d-flex flex-row ">
                Imediate Downstream Provider:
                <div className="ms-2">
                  <ProviderPanel
                    provider={{
                      name: data.downstreamProvider.providerName,
                      providerId: data.downstreamProvider.providerId
                    }}
                    hyperlink={
                      user.roleType === userTypes.Admin || user.roleType === userTypes.GovUser
                    }
                    hideITG
                  />
                </div>
              </div>
              <Fragment>
                <div className="d-flex flex-row">
                  Imediate Downstream Provide Country: {data.downstreamProvider.providerCountry}
                </div>
                <div className="d-flex flex-row">
                  Imediate Downstream Provider State: {data.downstreamProvider.providerState}
                </div>
                <div className="d-flex flex-row">
                  Imediate Downstream Provide City: {data.downstreamProvider.providerCity}
                </div>
              </Fragment>
              {data.status === 'No Response' && data.downstreamProvider.actionTaken && (
                <>
                  <div className="d-flex flex-row">Provider Action Taken:</div>
                  <div className="d-flex flex-row ms-2">{data.downstreamProvider.actionTaken}</div>
                  <div className="d-flex flex-row ms-2 mt-2">
                    {data.downstreamProvider.explanation}
                  </div>
                </>
              )}
            </div>
          )}
          {data.requestId && user.roleType == userTypes.Admin && (
            <div className="col">
              {data.transcriptBody && (
                <div className="d-flex justify-content-start mt-1">
                  <span className="ms-0">
                    <span className="font-calibri-bold">Transcription</span> {data.transcriptBody}
                  </span>
                </div>
              )}
              {data.description && (
                <div className="d-flex justify-content-start mt-1">
                  <span className="ms-0">
                    <span className="font-calibri-bold">Incident Description</span>{' '}
                    {data.description}
                  </span>
                </div>
              )}
              {data.natureOfRequest && (
                <div className="d-flex justify-content-start mt-1">
                  <span className="ms-0">
                    <span className="font-calibri-bold">Nature of Request</span>{' '}
                    {data.natureOfRequest}
                  </span>
                </div>
              )}
              {data.live !== null && (
                <div className="d-flex justify-content-start mt-1">
                  <span className="ms-0">
                    <span className="font-calibri-bold">Live or Prerecorded/Artificial</span>{' '}
                    {data.live === true
                      ? 'Live'
                      : data.live === false && 'Prerecorded or Artificial Voice'}
                  </span>
                </div>
              )}
              {data.suspectedLegalViolation && (
                <div className="d-flex justify-content-start mt-1">
                  <span className="ms-0">
                    <span className="font-calibri-bold">Suspected Legal Violation(s)</span>{' '}
                    {data.suspectedLegalViolation}
                  </span>
                </div>
              )}
              {data.calledPartyType && (
                <div className="d-flex justify-content-start mt-1">
                  <span className="ms-0">
                    <span className="font-calibri-bold">Called Party Type</span>{' '}
                    {data.calledPartyType}
                  </span>
                </div>
              )}
              <div className="d-flex justify-content-start mt-1">
                <span className="ms-0">
                  <span className="font-calibri-bold">More Information</span>{' '}
                  {data.audioUrl ? (
                    <Fragment>
                      <br />
                      <audio controls>
                        <source src={data.audioUrl} />
                      </audio>
                      <br />
                      <a
                        href={data.audioUrl}
                        target={'audio'}
                        rel={'noopener noreferrer'}
                        className="telecom-text "
                        style={{ paddingTop: '5px', fontSize: '0.9em' }}
                        type={'audio/mp3'}
                      >
                        {data.audioUrl}
                      </a>
                    </Fragment>
                  ) : (
                    'None'
                  )}
                </span>
              </div>
            </div>
          )}
          {tracebackComment && (
            <div className="col">
              <div className="d-flex flex-row mt-1">Comment: {tracebackComment.contentText}</div>
              {user.roleType === userTypes.Admin && (
                <div>
                  <Button
                    className="ms-3"
                    color="primary"
                    onClick={async () => await deleteResultComment(tracebackComment.commentId)}
                  >
                    Delete Comment
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
        {openCommentSection && (
          <div className="row mt-2">
            <div className="col-4">
              <InputFormGroup
                isTextarea
                inputName="resultComment"
                inputId="resultComment"
                inputClassName="input-comment"
                inputValue={commentText}
                inputOnChange={(e: any) => setCommentText(e.currentTarget.value)}
                inputPlaceholder="Please type your comment for the requestor here"
                inputAutoComplete="off"
              />
              <div style={{ textAlign: 'center', marginTop: '25px' }}>
                <Button
                  className="btn-default telecom-btn"
                  onClick={() => {
                    setCommentSection((v) => !v);
                    setCommentText('');
                  }}
                  color="light"
                >
                  Cancel
                </Button>
                <Button
                  className="telecom-btn ms-2"
                  disabled={commentText.length < 5}
                  onClick={async () => await submitResultComment()}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default ExpandableResult;
