import React, { Fragment } from 'react';
import { DateFormat } from '../../enum/DateFormat';
import { userTypes } from '../../enum/userTypes';
import { Hop } from '../../interfaces/hop';
import { Traceback } from '../../interfaces/traceback';
import { PersonalInfo } from '../../interfaces/user';
import { getClientFormattedDate, getElapsedTime } from '../../lib/utilities';

interface IProps {
  hop: Hop;
  traceback: Traceback;
  user: PersonalInfo;
}

const TracebackDetailsShortRow: React.FC<IProps> = ({ hop, traceback, user }) => {
  return user.roleType === userTypes.Admin && hop.status !== 1 ? (
    <Fragment>
      <h5 className="p-0 traceback-sub-title mb-3">Traceback Details</h5>
      <div className="d-flex flex-wrap justify-content-around">
        <div>
          <label className="telecom-label">Date & Time Created</label>
          {getClientFormattedDate(traceback.create_date, DateFormat.MediumBoth)}
        </div>
        <div>
          <label className="telecom-label">Total Time Elapsed</label>
          {getElapsedTime(traceback.elapsed)}
        </div>
        {traceback.completed && traceback.completed !== '0001-01-01T00:00:00Z' && (
          <div>
            <label className="telecom-label">Date & Time Completed</label>
            {getClientFormattedDate(traceback.completed, DateFormat.MediumBoth)}
          </div>
        )}
      </div>
    </Fragment>
  ) : null;
};

export default TracebackDetailsShortRow;
