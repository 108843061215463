import { sub } from 'date-fns';
import React, { FC, Fragment, useState } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { Button, Col, Label, Row } from 'reactstrap';
import { HopTypes, ReportFormat, ReportPostData, ReportType } from '../../../interfaces/report';
import { getEndOfTheDay, getStartOfTheDay } from '../../../lib/utilities';
import { getReportObject } from '../../../redux/report/thunks';
import CampaignsSelect from '../../CampaignsSelect';
import CustomCreatable from '../../CustomCreatable';
import CustomPicky from '../../CustomPicky';
import { SelectOption } from '../../CustomSelect';
import ProvidersSelect from '../../ProvidersSelect';
import LabelSelect from '../../shared/LabelSelect';

interface IProps {
  reportName: string;
  narrativeIntro: string;
  getReportObject: Function;
  zendeskTicketId: string;
}
const hopTypeOptions = [
  { label: 'Include Originator Hops', value: 'includeUsOriginator' },
  { label: 'Include PoE Hops', value: 'includeUsPointOfEntry' },
  { label: 'Include IOR Hops', value: 'includeInternationalOrigin' },
  { label: 'Include FPD Hops', value: 'includeForeignPointOfDeparture' },
  { label: 'Include NR Hops', value: 'includeNoResponsive' }
];

const SubpoenaReport: FC<IProps> = ({
  reportName,
  narrativeIntro,
  getReportObject,
  zendeskTicketId
}) => {
  const [startDate, setStartDate] = useState(sub(new Date(), { months: 1 }));
  const [endDate, setEndDate] = useState(new Date());
  const [callingParties, setCallingParties] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [hopTypes, setHopTypes] = useState([]);
  const [chosenProviders, setChosenProviders] = useState([]);
  const [tracebacks, setTracebacks] = useState([]);
  const [includeComments, setIncludeComments] = useState(false);
  const [includeNotifications, setIncludeNotifications] = useState(false);
  const [includeOtherAttachments, setIncludeOtherAttachments] = useState(false);
  const [includeProviderCallRecords, setIncludeProviderCallRecords] = useState(false);
  const [includeAdminData, setIncludeAdminData] = useState(false);
  const [includeAllHops, setIncludeAllHops] = useState(false);
  const [
    includeProviderUpstreamDownstreamSummaries,
    setIncludeProviderUpstreamDownstreamSummaries
  ] = useState(false);
  const [excludeOpenTracebacks, setExludeOpenTracebacks] = useState(false);
  const [excludeTracebacksWithNotFoundStatus, setExcludeTracebacksWithNotFoundStatus] =
    useState(false);
  const [markConfidential, setMarkConfidential] = useState(false);
  const [chosenLabels, setChosenLabels] = useState([]);

  const handleSelectProviders = (value: any) => {
    setChosenProviders(value ? value.map((item: SelectOption) => item.value) : []);
  };
  const getReportObjectFunction = () => {
    let reportPostData = {
      startDate: getStartOfTheDay(startDate).toISOString(),
      endDate: getEndOfTheDay(endDate).toISOString(),
      name: reportName,
      narrativeIntro,
      markConfidential,
      reportFormat: ReportFormat.Excel,
      includeComments,
      includeNotifications,
      includeProviderCallRecords,
      includeOtherAttachments,
      includeAdminData,
      includeAllHops,
      includeProviderUpstreamDownstreamSummaries,
      excludeOpenTracebacks,
      excludeTracebacksWithNotFoundStatus,
      chosenLabels: chosenLabels.map((v: SelectOption) => ({ labelID: v.value, name: v.label }))
    } as ReportPostData;

    if (callingParties.length > 0) {
      let array = callingParties.map((callingParty: any) => callingParty.label);
      reportPostData.callingParties = array;
    }

    if (campaigns.length > 0) {
      let array = campaigns.map((campaign: any) => campaign.value);
      reportPostData.campaigns = array;
    }

    if (hopTypes && hopTypes.length > 0) {
      let types = {} as HopTypes;
      hopTypes.forEach((hopTypes: any) => {
        if (hopTypes.value == 'includeUsOriginator') {
          types.includeUsOriginator = true;
        }
        if (hopTypes.value == 'includeUsPointOfEntry') {
          types.includeUsPointOfEntry = true;
        }
        if (hopTypes.value == 'includeInternationalOrigin') {
          types.includeInternationalOrigin = true;
        }
        if (hopTypes.value == 'includeForeignPointOfDeparture') {
          types.includeForeignPointOfDeparture = true;
        }
        if (hopTypes.value == 'includeNoResponsive') {
          types.includeNoResponsive = true;
        }
      });
      reportPostData.hopTypes = types;
    }

    if (chosenProviders.length > 0) {
      reportPostData.providers = chosenProviders;
    }

    if (tracebacks && tracebacks.length > 0) {
      let array = tracebacks.map((traceback: any) => parseInt(traceback.value));
      reportPostData.tracebacks = array;
    }
    if (zendeskTicketId) {
      reportPostData.zendeskTicketId = parseInt(zendeskTicketId.replace('#', ''));
    }

    getReportObject(reportPostData, ReportType.SubpoenaReport, ReportFormat.Zip);
  };

  const handleCheck = (e: any, key: string) => {
    switch (key) {
      case 'startDate':
        setStartDate(e);
        break;
      case 'endDate':
        setEndDate(e);
        break;
      case 'callingParties':
        setCallingParties(e);
        break;
      case 'campaigns':
        setCampaigns(e);
        break;
      case 'hopTypes':
        setHopTypes(e);
        break;
      case 'tracebacks':
        setTracebacks(e);
        break;
      case 'includeComments':
        setIncludeComments((v) => !v);
        break;
      case 'includeNotifications':
        setIncludeNotifications((v) => !v);
        break;
      case 'includeOtherAttachments':
        setIncludeOtherAttachments((v) => !v);
        break;
      case 'includeProviderCallRecords':
        setIncludeProviderCallRecords((v) => !v);
        break;
      case 'includeAdminData':
        setIncludeAdminData((v) => !v);
        break;
      case 'includeAllHops':
        setIncludeAllHops((v) => !v && chosenProviders.length !== 0);
        break;
      case 'includeProviderUpstreamDownstreamSummaries':
        setIncludeProviderUpstreamDownstreamSummaries((v) => !v && chosenProviders.length !== 0);
        break;
      case 'excludeOpenTracebacks':
        setExludeOpenTracebacks((v) => !v);
        break;
      case 'excludeTracebacksWithNotFoundStatus':
        setExcludeTracebacksWithNotFoundStatus((v) => !v);
        break;
      case 'markConfidential':
        setMarkConfidential((v) => !v);
        break;

      case 'excel':
        getReportObjectFunction();
        break;
    }
  };

  const handleSelectLabels = (value: any) => {
    setChosenLabels(value);
  };

  return (
    <Fragment>
      <Label className="telecom-label ps-3">Date Range *</Label>

      <div className="d-flex flex-row mb-4">
        <DatePicker
          maxDate={endDate || new Date()}
          selected={startDate}
          onChange={(option) => handleCheck(option, 'startDate')}
          placeholderText="&#xf133; mm/dd/yyyy"
          clearButtonTitle={'Clear'}
          isClearable
        />
        <span className="ms-2 me-2">-</span>
        <DatePicker
          maxDate={new Date()}
          selected={endDate}
          minDate={startDate}
          onChange={(option) => handleCheck(option, 'endDate')}
          placeholderText="&#xf133; mm/dd/yyyy"
          clearButtonTitle={'Clear'}
          isClearable
        />
      </div>

      <div className="traceback-label ps-0">
        You may filter by any of the below. Note that all filters are optional and allow for the
        selection of more than one option.
      </div>
      <div className="telecom-label ps-0">Traceback-level Filters</div>
      <div className="d-flex flex-row ps-0 me-0 mb-2">
        <div className="ps-0 col-3">
          <CustomCreatable
            className={'customcreatable me-0 ps-0'}
            onChange={(option: any) => handleCheck(option, 'tracebacks')}
            placeholder="Enter Traceback IDs"
            value={tracebacks}
          />
        </div>
        <div className="ps-2 col-3">
          <CampaignsSelect
            isMulti
            addAllItem
            isSearchable
            numberDisplayed={2}
            maxMenuHeight={170}
            value={campaigns}
            onChange={(option) => handleCheck(option, 'campaigns')}
            placeholder={'Campaign Name'}
          />
        </div>
      </div>
      <div className="me-1 mb-4 d-flex">
        <label className="form-label tile-label" style={{ fontSize: '15px' }}>
          Exclude Tracebacks that Include Not Found Status
          <input
            type="checkbox"
            onChange={(option) => handleCheck(option, 'excludeTracebacksWithNotFoundStatus')}
            checked={excludeTracebacksWithNotFoundStatus}
          />
          <span className="checkmark tile-checkmark" />
        </label>
        <label className="form-label tile-label" style={{ fontSize: '15px' }}>
          Exclude Open Tracebacks
          <input
            type="checkbox"
            onChange={(option) => handleCheck(option, 'excludeOpenTracebacks')}
            checked={excludeOpenTracebacks}
          />
          <span className="checkmark tile-checkmark" />
        </label>
      </div>
      <div className="telecom-label ps-0">Provider-level Filters</div>
      <Row className="ps-0 me-0 mb-4">
        <Col className="ps-0 col-3">
          <ProvidersSelect
            option={chosenProviders}
            className={'customselect-large'}
            onChange={handleSelectProviders}
            placeholder="Provider Names"
            isSearchable={true}
            isMulti
            addAllItem
            numberDisplayed={2}
            includeInactive
          />
        </Col>

        <Col className="ps-0 col-3">
          <CustomPicky
            addAllItem
            isSearchable
            numberDisplayed={2}
            maxMenuHeight={170}
            selectedOption={hopTypes}
            getOptions={hopTypeOptions}
            setSelectedOption={(option) => handleCheck(option, 'hopTypes')}
            placeholder={'Hop Notes'}
            typeLabel={'Select All'}
          />
        </Col>
      </Row>
      <div className="telecom-label ps-0">Campaign Labels Filter</div>
      <Row className="ps-0 me-0 mb-4">
        <Col className="ps-0 col-3">
          <LabelSelect
            value={chosenLabels}
            isMulti
            numberDisplayed={2}
            addAllItem
            onChange={handleSelectLabels}
            maxMenuHeight={600}
          />
        </Col>
      </Row>
      <Row className="me-1 mb-4 d-flex">
        {chosenProviders.length > 0 && (
          <label className="form-label tile-label" style={{ fontSize: '15px' }}>
            Include All Hops in Call Path
            <input
              type="checkbox"
              onChange={(option) => handleCheck(option, 'includeAllHops')}
              checked={includeAllHops}
            />
            <span className="checkmark tile-checkmark" />
          </label>
        )}
        {chosenProviders.length > 0 && (
          <label className="form-label tile-label" style={{ fontSize: '15px' }}>
            Upstream & Downstream Provider Summaries
            <input
              type="checkbox"
              onChange={(option) =>
                handleCheck(option, 'includeProviderUpstreamDownstreamSummaries')
              }
              checked={includeProviderUpstreamDownstreamSummaries}
            />
            <span className="checkmark tile-checkmark" />
          </label>
        )}
      </Row>

      <div className="telecom-label ps-0">Also Include</div>
      <div className="me-1 mb-4 d-flex">
        <label className="form-label tile-label" style={{ fontSize: '15px' }}>
          Notification Emails
          <input
            type="checkbox"
            onChange={(option) => handleCheck(option, 'includeNotifications')}
            checked={includeNotifications}
          />
          <span className="checkmark tile-checkmark" />
        </label>
        <label className="form-label tile-label" style={{ fontSize: '15px' }}>
          Comments
          <input
            type="checkbox"
            onChange={(option) => handleCheck(option, 'includeComments')}
            checked={includeComments}
          />
          <span className="checkmark tile-checkmark" />
        </label>
        <label className="form-label tile-label" style={{ fontSize: '15px' }}>
          Provider Call Records
          <input
            type="checkbox"
            onChange={(option) => handleCheck(option, 'includeProviderCallRecords')}
            checked={includeProviderCallRecords}
          />
          <span className="checkmark tile-checkmark" />
        </label>
        <label className="form-label tile-label" style={{ fontSize: '15px' }}>
          Other Related Attachments
          <input
            type="checkbox"
            onChange={(option) => handleCheck(option, 'includeOtherAttachments')}
            checked={includeOtherAttachments}
          />
          <span className="checkmark tile-checkmark" />
        </label>
      </div>
      <Row className="m-4" style={{ borderBottom: '1px solid #ccc' }} />
      <div className="d-flex justify-content-md-center m-4">
        <label className="form-label tile-label" style={{ fontSize: '15px' }}>
          Mark as Confidential
          <input
            type="checkbox"
            onChange={(option) => handleCheck(option, 'markConfidential')}
            checked={markConfidential}
          />
          <span className="checkmark tile-checkmark" />
        </label>
      </div>
      <div className="d-flex justify-content-md-center">
        <Button
          className="telecom-btn me-2"
          color="primary"
          style={{
            height: '28px',
            padding: '5px',
            fontSize: '14px',
            width: '150px'
          }}
          onClick={(option) => handleCheck(option, 'excel')}
        >
          Create Report
        </Button>
      </div>
    </Fragment>
  );
};

const mapActionsToProps = { getReportObject };

export default connect(null, mapActionsToProps)(SubpoenaReport);
