import React, { Fragment } from 'react';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import Breadcrumb from '../components/Breadcrumbs';
import CommentEditor from '../components/TracebackCommentEditor';

const TracebackCommentAdd = () => {
  return (
    <Fragment>
      <Breadcrumb title="comments" destination={'/traceback-comments'} detail="Add Comment" />
      <Card className="telecom-card">
        <CardHeader className="telecom-card-header">Add Comment</CardHeader>
        <CardBody>
          <div className="d-flex justify-content-center">
            <Col xs="12" sm="11" lg="10">
              <CommentEditor />
            </Col>
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default TracebackCommentAdd;
