import React, { Fragment } from 'react';
import { TableColumn } from 'react-data-table-component';
import { Notes } from '../../components/shared/Notes';
import { DateFormat } from '../../enum/DateFormat';
import { userTypes } from '../../enum/userTypes';
import { NoteType } from '../../interfaces/hop';
import { PartnerStatsWithTracebackInfo, RequestIncident } from '../../interfaces/request';
import { tracebackStatus } from '../../interfaces/traceback';
import { PersonalInfo } from '../../interfaces/user';
import { getClientFormattedDate, processTracebackError } from '../utilities';

export const requestColumns = (
  user: PersonalInfo,
  selected: number[],
  updateSelectedRows: (checked: boolean, index: number) => void
): TableColumn<RequestIncident>[] => [
  {
    name: 'Id',
    selector: () => 'id',
    sortable: true,
    grow: 2,
    cell: (incident: RequestIncident) => <div className="table-cell center me-2">{incident.id}</div>
  },
  {
    name: 'Requested',
    selector: () => 'create_date',
    sortable: true,
    grow: 3,
    cell: (incident: RequestIncident) => (
      <div className="table-cell center">
        <span>{getClientFormattedDate(incident.createDate, DateFormat.ShortDate)}</span>
      </div>
    )
  },
  {
    name: 'Requestor',
    selector: () => 'requestorId',
    sortable: false,
    omit: user.roleType !== userTypes.Admin,
    grow: 3,
    cell: (incident: RequestIncident) => (
      <div className="table-cell center">{incident.requestorName}</div>
    )
  },
  {
    name: 'Email',
    selector: () => 'userEmail',
    sortable: false,
    grow: 3,
    cell: (incident: RequestIncident) => (
      <div className="table-cell center">{incident.userEmail}</div>
    )
  },
  {
    name: 'Contact Phone',
    selector: () => 'userPhoneNumber',
    sortable: false,
    omit: user.roleType !== userTypes.Admin,
    grow: 3,
    cell: (incident: RequestIncident) => (
      <div className="table-cell center">
        {incident.userPhoneNumber ? incident.userPhoneNumber : '-'}
      </div>
    )
  },
  {
    name: 'Incident Campaign',
    selector: () => 'incidentCampaignId',
    sortable: false,
    grow: 3,
    cell: (incident: RequestIncident) => (
      <div className="table-cell center">
        <span>{incident.incidentCampaignName}</span>
      </div>
    )
  },
  {
    name: 'Status',
    selector: () => 'status',
    sortable: false,
    omit: user.roleType === userTypes.Admin,
    grow: 3,
    cell: (incident: RequestIncident) =>
      incident.status === 'ACCEPTED' ? (
        <div className="table-cell center">{`Traceback ${incident.tracebackId} ${incident.tracebackPublished ? tracebackStatus.get(incident?.tracebackStatus || 0) : 'Open'}`}</div>
      ) : (
        <div className="table-cell center">{incident.status}</div>
      )
  },
  {
    name: '',
    sortable: false,
    omit: user.roleType !== userTypes.Admin,
    grow: 3,
    cell: (incident: RequestIncident) => (
      <Fragment>
        {incident.tracebackError && (
          <span className="table-cell center text-danger w-75">
            {processTracebackError(incident.tracebackError)}
          </span>
        )}
        <span className="table-cell gray center me-1">
          <input
            type="checkbox"
            defaultChecked={selected.some((select: number) => select === incident.id)}
            onChange={(e) => updateSelectedRows(e.currentTarget.checked, incident.id)}
          />
        </span>
      </Fragment>
    )
  }
];

export const requestResultsColumns = (
  user: PersonalInfo,
  togglePublish: (checked: boolean, requestorId: number, tracebackId: number) => void,
  resultsStatus: { id: number; published: boolean }[]
): TableColumn<PartnerStatsWithTracebackInfo>[] => [
  {
    name: '',
    width: '20px',
    cell: () => <div />
  },
  {
    name: 'Requestor',
    selector: () => 'requestorId',
    sortable: false,
    omit: user.roleType !== userTypes.Admin,
    grow: 2,
    cell: (stats: PartnerStatsWithTracebackInfo) => (
      <div className="table-cell center">{stats.requestorName}</div>
    )
  },
  {
    name: 'Campaign',
    selector: () => 'campaignName',
    sortable: true,
    grow: 4,
    cell: (stats: PartnerStatsWithTracebackInfo) =>
      user.roleType === userTypes.Admin ? (
        <span className="table-cell center blue">
          <a href={`/campaigns/campaign/${stats.campaignId}`}>{stats.campaignName}</a>
        </span>
      ) : (
        <span className="table-cell center">{stats.campaignName}</span>
      )
  },
  {
    name: 'Traceback',
    selector: () => 'tracebackId',
    sortable: true,
    grow: 2,
    cell: (stats: PartnerStatsWithTracebackInfo) =>
      user.roleType === userTypes.Admin ? (
        <span className="table-cell blue center">
          <a href={`/tracebacks/traceback/${stats.tracebackId}`}>{stats.tracebackId}</a>
        </span>
      ) : (
        <span className="table-cell center">{stats.tracebackId}</span>
      )
  },
  {
    name: 'Event',
    selector: () => 'eventId',
    sortable: true,
    grow: 2,
    cell: (stats: PartnerStatsWithTracebackInfo) =>
      user.roleType === userTypes.Admin ? (
        <span className="table-cell blue center">
          <a href={`/partners/event/${stats.eventId}`}>{stats.eventId}</a>
        </span>
      ) : (
        <span className="table-cell center">{stats.eventId}</span>
      )
  },
  {
    name: 'Create Date',
    selector: () => 'create_date',
    sortable: true,
    grow: 3,
    cell: (stats: PartnerStatsWithTracebackInfo) => (
      <span className="table-cell center gray">
        {getClientFormattedDate(stats.create_date, DateFormat.ShortBoth)}
      </span>
    )
  },
  {
    name: 'Completed Date',
    selector: () => 'completed_date',
    sortable: true,
    grow: 3,
    cell: (stats: PartnerStatsWithTracebackInfo) =>
      stats.status === 'Pending' ? (
        <span className="table-cell gray center">-</span>
      ) : (
        <span className="table-cell gray center">
          {getClientFormattedDate(stats.completed_date, DateFormat.ShortBoth)}
        </span>
      )
  },
  {
    name: 'Status',
    selector: () => 'status',
    sortable: true,
    grow: 2,
    cell: (stats: PartnerStatsWithTracebackInfo) => (
      <div className="table-cell gray center">
        <span>{stats.status}</span>
        {stats.strikeExempt && <Notes types={[NoteType.StrikeExemptProvider]} />}
      </div>
    )
  },
  {
    name: 'Elapsed',
    selector: () => 'elapsed',
    sortable: true,
    omit: user.roleType !== userTypes.Admin,
    grow: 2,
    cell: (stats: PartnerStatsWithTracebackInfo) => (
      <span className="table-cell gray center">{stats.elapsed}</span>
    )
  },
  {
    name: 'Publish',
    selector: () => 'publish',
    grow: 1,
    omit: user.roleType !== userTypes.Admin,
    cell: (stats: PartnerStatsWithTracebackInfo) => (
      <span className="table-cell gray center">
        <input
          type="checkbox"
          className="checkbox"
          checked={
            resultsStatus.find((resultStatus) => resultStatus.id === stats.tracebackId)?.published
          }
          disabled={stats.status === 'Pending' || stats.published}
          onChange={(e) =>
            togglePublish(e.currentTarget.checked, stats.requestorId, stats.tracebackId)
          }
        />
      </span>
    )
  }
];
