import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import {
  getPublicAttachmentsLinks,
  resetPublicAttachmentsLinks
} from '../../redux/attachments/thunks';
import { stateMappings } from '../../redux/stateMappings';
import CustomDataTable from '../CustomDataTable';
import InputError from '../inputError';
import InputFormGroup from '../inputFormGroup';

interface IProps {
  error: string;
  newLinks: any[];
  getPublicAttachmentsLinks: Function;
  resetPublicAttachmentsLinks: Function;
}
const ConvertOldLinks: React.FC<IProps> = ({
  error,
  newLinks,
  getPublicAttachmentsLinks,
  resetPublicAttachmentsLinks
}) => {
  const [links, setLinks] = useState('');

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setLinks(event.currentTarget.value);
  };

  const submit = () => {
    if (links.length === 0) return;
    const olLinks = links.split('\n').filter((v) => !!v);
    getPublicAttachmentsLinks(olLinks);
  };

  useEffect(() => {
    return () => {
      resetPublicAttachmentsLinks();
    };
  }, []);

  return (
    <div className="d-flex justify-content-center flex-wrap">
      <div className="col-6 text-center">
        <p>
          We deprecated some original audio URL's and moved the files to a more secure location.
          Paste one or more 'Audio URL' below to get the updated link(s).
        </p>
      </div>
      <div className="col-12">
        <label className="label-bold">Old Links</label>
        <InputFormGroup
          inputName="links"
          inputId="links"
          inputClassName="input-textarea"
          inputValue={links}
          inputOnChange={handleChange}
          inputPlaceholder=""
          inputAutoComplete="off"
          errorMessage={error && 'No match found for Audio URL, please check the link'}
          isTextarea
        />
        {newLinks && newLinks.length !== 0 && (
          <CustomDataTable
            tableData={newLinks}
            columns={[
              {
                name: '',
                width: '20px',
                cell: () => <div />
              },
              {
                name: 'Old Link',
                grow: 1,
                cell: (row: any) => row.oldUrl
              },
              {
                name: 'New Link',
                grow: 1,
                cell: (row: any) =>
                  row.newUrl && !row.error ? (
                    <a href={row.newUrl}>{row.newUrl}</a>
                  ) : (
                    <InputError className="telecom-input-error">
                      {row.error === 'wrong url' ? 'Not a supported URL' : 'Audio URL not found'}
                    </InputError>
                  )
              }
            ]}
            defaultSortFieldId={''}
          />
        )}
      </div>
      <div className="d-flex flex-row justify-content-center">
        <Button
          color="primary"
          className="align-self-center"
          onClick={submit}
          disabled={!links.length}
        >
          Generate New Links
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    error: sm.error.attachments,
    newLinks: sm.attachments.oldUrls || []
  };
};

const mapActionsToProps = { getPublicAttachmentsLinks, resetPublicAttachmentsLinks };

export default connect(mapStateToProps, mapActionsToProps)(ConvertOldLinks);
