import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import Breadcrumb from '../components/Breadcrumbs';
import CommentEditor from '../components/CommentEditor';
import GovCommentEditor from '../components/GovCommentEditor';
import { Comment } from '../interfaces/comment';
import { clearCommentsList, getCommentObject, setCommentObject } from '../redux/comment/thunks';
import { stateMappings } from '../redux/stateMappings';

interface IProps {
  getCommentObject: Function;
  comment: Comment;
  setCommentObject: Function;
  clearCommentsList: Function;
}

const CommentDetail: React.FC<IProps> = ({
  getCommentObject,
  comment,
  setCommentObject,
  clearCommentsList
}: IProps) => {
  const location = useLocation();
  const { pathname } = location;
  const id = pathname.split('/')[pathname.split('/').length - 1];

  useEffect(() => {
    if (Number(id) !== comment?.commentId) getCommentObject(id);
  }, [id, comment]);
  return (
    <Fragment>
      <Breadcrumb title="comments" destination={'/comments'} detail={`Comment ${id}`} />
      <Card className="telecom-card">
        <CardHeader className="telecom-card-header">Edit Comment</CardHeader>
        <CardBody>
          <div className="d-flex justify-content-center">
            <Col xs="12" sm="11" lg="10">
              {comment?.commentType === 'callRecord' ? (
                <CommentEditor comment={comment} isEdit setCommentObject={setCommentObject} />
              ) : (
                <GovCommentEditor
                  comment={comment}
                  isEdit
                  setCommentObject={setCommentObject}
                  clearCommentsList={clearCommentsList}
                />
              )}
            </Col>
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

const mapActionsToProps = { getCommentObject, setCommentObject, clearCommentsList };
const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    comment: sm.comment.comment
  };
};
export default connect(mapStateToProps, mapActionsToProps)(CommentDetail);
