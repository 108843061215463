import axios from 'axios';

import { Pagination } from '../../interfaces/pagination';
import { Traceforward } from '../../interfaces/traceforward';
import { FilterElement } from '../../lib/FilterElement';
import { logoutOnAuthError } from '../../lib/apiRequest';
import { downloadFile } from '../../lib/utilities';
import { addCommentObject } from '../comment/thunks';
import {
  addAttachments,
  addTraceforward,
  editTraceforward,
  getAttachments,
  getTraceforward,
  getTraceforwards,
  setErrorMessage,
  setLoadingStatus
} from './actions';
import {
  addAttachmentsApiCall,
  addAudioApiCall,
  addTraceforwardApiCall,
  deleteTraceforwardApiCall,
  editTraceforwardApiCall,
  getAudioApiCall,
  getCSVApiCall,
  getTraceforwardApiCall,
  getTraceforwardsApiCall,
  getTraceforwardsByObjectTypeApiCall
} from './apiCalls';

export const getTraceforwardList =
  (params: Pagination, filterElements: FilterElement) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    try {
      const traceforwards = await getTraceforwardsApiCall(params, filterElements);
      if (traceforwards && traceforwards.data) {
        dispatch(getTraceforwards(traceforwards.data));
      }
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      if (!axios.isCancel(error)) logoutOnAuthError(error as any);
    }
  };

export const getTraceforwardObject = (traceforwardId: number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    const traceforward = await getTraceforwardApiCall(traceforwardId);

    if (traceforward && traceforward.data) {
      dispatch(getTraceforward(traceforward.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

// export const getTraceforwardStatusList = () => async (dispatch: any) => {
//   dispatch(setStatus(true, ''));

//   try {
//     const traceforwardStatuses = await getTraceforwardStatusApiCall();
//     if (traceforwardStatuses && traceforwardStatuses.data) {
//       dispatch(getTraceforwardStatuses(traceforwardStatuses.data.data));
//     }
//     dispatch(setStatus(false, ''));
// } catch (error: any) {
//     dispatch(setStatus(false, error.message));
//     logoutOnAuthError(error);
//   }
// };

export const addTraceforwardObject =
  (
    traceforward: Traceforward,
    comment: string,
    attachments: any[],
    audioAttachments: any[],
    isReload: boolean = true
  ) =>
  async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    const postableCampaign = {
      campaignId: traceforward.campaignId,
      campaignName: traceforward.campaignName,
      callingTN: traceforward.callingTN,
      calledTN: traceforward.calledTN,
      traceforwardTime: traceforward.traceforwardTime,
      create_date: traceforward.create_date,
      createdBy: traceforward.createdBy,
      audioName: traceforward.audioName,
      audioUrl: traceforward.audioUrl,
      audioUrlPath: traceforward.audioUrl,
      providerId: 0,
      requestors: traceforward.requestors
    };

    if (traceforward.providerId !== 0) {
      postableCampaign.providerId = traceforward.providerId;
    }

    try {
      let response = await addTraceforwardApiCall(postableCampaign);

      if (response && response.data) {
        dispatch(addTraceforward(response.data));

        let newTraceforwardId = response.data.data.id;

        if (audioAttachments && audioAttachments.length > 0) {
          let formData = new FormData();
          audioAttachments.forEach((attachment) => {
            formData.append('file', attachment);
          });

          addAudioApiCall(newTraceforwardId, formData)
            .then((result) => {
              handleTraceforwardComment(
                dispatch,
                newTraceforwardId,
                comment,
                attachments,
                isReload
              );
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          handleTraceforwardComment(dispatch, newTraceforwardId, comment, attachments, isReload);
        }
      }
    } catch (error: any) {
      dispatch(setStatus(false, error.response.data.error));
      logoutOnAuthError(error);
    }
  };

const handleTraceforwardComment = (
  dispatch: any,
  traceforwardId: number,
  comment: string,
  attachments: any[],
  isReload: boolean
) => {
  if (comment || attachments.length > 0) {
    addCommentObject(
      {
        commentType: 'traceforward',
        contentText: comment,
        relatedObjectId: traceforwardId
      },
      attachments
    ).then(() => {
      successfulApiCall(dispatch, isReload);
    });
  } else {
    successfulApiCall(dispatch, isReload);
  }
};

const successfulApiCall = (dispatch: any, isReload: boolean) => {
  dispatch(setStatus(false, ''));

  if (isReload) {
    window.location.href = '/traceforwards';
  }
};

export const editTraceforwardObject =
  (traceforward: Traceforward, comment: string, attachments: any[], isReload: boolean = true) =>
  async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    try {
      const response = await editTraceforwardApiCall(traceforward);

      if (response && response.data) {
        dispatch(editTraceforward(response.data));

        if (comment || attachments.length > 0) {
          addCommentObject(
            {
              commentType: 'traceforward',
              contentText: comment,
              relatedObjectId: traceforward.id
            },
            attachments
          ).then(() => {
            dispatch(setStatus(false, ''));

            if (isReload) {
              window.location.reload();
            }
          });
        } else {
          if (isReload) {
            window.location.reload();
          }
        }
      }
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };

//TODO: Need to clean up references to addAttachmentList
export const addAttachmentList =
  (traceforwardId: number, formData: FormData) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    try {
      const response = await addAttachmentsApiCall(traceforwardId, formData);
      if (response && response.data) {
        dispatch(addAttachments(response.data));
      }
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };

export const deleteTraceforward = (traceforwardId: number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    await deleteTraceforwardApiCall(traceforwardId);
    dispatch(setStatus(false, ''));
    window.location.href = '/Traceforwards';
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const addAudioFile =
  (traceforwardId: number, formData: FormData) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    try {
      const response = await addAudioApiCall(traceforwardId, formData);
      if (response && response.data) {
        dispatch(addAttachments(response.data));
      }
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };

export const getAudioFile = (traceforwardId: number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    const response = await getAudioApiCall(traceforwardId);
    if (response && response.data) {
      dispatch(getAttachments(response.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

const setStatus = (status: boolean, message: string) => async (dispatch: any) => {
  dispatch(setErrorMessage(message));
  dispatch(setLoadingStatus(status));
};

export const getTraceforwardByObjectTypeList =
  (objectId: number, objectType: string, filterElements: FilterElement) =>
  async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    try {
      const traceforwards = await getTraceforwardsByObjectTypeApiCall(
        objectId,
        objectType,
        filterElements
      );
      if (traceforwards && traceforwards.data) {
        dispatch(getTraceforwards(traceforwards.data));
      }
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };

export const getTraceforwardsCSV =
  (filterElements: FilterElement, params: Pagination) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    try {
      const response = await getCSVApiCall(filterElements, params);
      downloadFile(response.data, response.headers.contentfilename);
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };
