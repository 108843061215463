import { refreshToken } from './apiRequest';

interface StartOptions {
  interval?: number;
  signOut: () => void;
}

interface SessionManager {
  tokenInterval?: NodeJS.Timeout;
  start: (options: StartOptions) => void;
  stop: () => void;
}

const createSessionManager = (): SessionManager => {
  let tokenInterval: NodeJS.Timeout | undefined = undefined;
  const stop = () => {
    if (typeof tokenInterval !== 'undefined') {
      //   console.log('Stopping session refresh interval');
      clearInterval(tokenInterval);
      // } else {
      //   console.error(`sessionManager has not been started`);
    }
  };
  const start = ({ interval = 1 * 60 * 1e3, signOut }: StartOptions) => {
    tokenInterval = setInterval(async () => {
      try {
        const resp = await refreshToken();

        if (!resp) {
          signOut();
          stop();
        }
      } catch (error) {
        signOut();
        stop();
      }
    }, interval);
  };
  return {
    tokenInterval,
    start,
    stop
  };
};

const sessionManager = createSessionManager();

export default sessionManager;
