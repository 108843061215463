export enum ProviderType {
  US_Voice_Service_Provider_FCC_Filer = 'US Voice Service Provider - FCC Filer',
  US_Voice_Service_Provider_Non_FCC_Filer = 'US Voice Service Provider - Non-FCC Filer',
  US_Voice_Broadcaster = 'US Voice Broadcaster',
  US_Call_Center = 'US Call Center',
  US_Lead_Generator = 'US Lead Generator',
  US_Based_Other = 'US-Based Other',
  Non_US_Voice_Service_Provider_FCC_Filer = 'Non-US Voice Service Provider - FCC Filer',
  Non_US_Voice_Service_Provider_Non_FCC_Filer = 'Non-US Voice Service Provider - Non-FCC Filer',
  Non_US_Voice_Broadcaster = 'Non-US Voice Broadcaster',
  Non_US_Call_Center = 'Non-US Call Center',
  Non_US_Lead_Generator = 'Non-US Lead Generator',
  Non_US_Other = 'Non-US Other'
}
