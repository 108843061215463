import React, { Fragment, useEffect, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import Breadcrumb from '../components/Breadcrumbs';
import CustomDataTable from '../components/CustomDataTable';
import { SelectOption } from '../components/CustomSelect';
import InputFormGroup from '../components/inputFormGroup';
import LabelSelect from '../components/shared/LabelSelect';
import LanguageSelect from '../components/shared/LanguageSelect';
import SourceSelect from '../components/shared/SourceSelect';
import { DateFormat } from '../enum/DateFormat';
import { Campaign } from '../interfaces/campaign';
import { Pagination, defaultPagination } from '../interfaces/pagination';
import {
  FilterElement,
  addCondition,
  getFilter,
  newConditionalFilterElement,
  newLeafFilterElement,
  simplifyFilterElement
} from '../lib/FilterElement';
import {
  decodeQueryParams,
  encodeQueryParams,
  getFromLocalStorage,
  saveToLocalStorage
} from '../lib/history-utils';
import { languageListWithNonEnglish } from '../lib/languegeSelect';
import { getClientFormattedDate } from '../lib/utilities';
import { getCampaignList } from '../redux/campaign/thunks';
import { stateMappings } from '../redux/stateMappings';

interface IProps {
  campaigns: Array<Campaign>;
  getCampaignList: Function;
  paginationTotalRows: number;
}

const columns: TableColumn<any>[] = [
  {
    name: 'Campaign Name',
    selector: () => 'name',
    sortable: true,
    grow: 2,
    id: 'column-campaignName',
    cell: (campaign: any) => (
      <div
        className="table-cell blue"
        onClick={() => (window.location.href = `/campaigns/campaign/${campaign.campaignId}`)}
      >
        <a href={`/campaigns/campaign/${campaign.campaignId}`}>{campaign.name}</a>
      </div>
    )
  },
  {
    name: 'Labels',
    selector: () => 'labels',
    sortable: true,
    // center: true,
    grow: 1,
    cell: (campaign: any) => (
      <span className="table-cell gray center">
        {campaign.labels ? campaign.labels.map((label: any) => label.name).join(', ') : '-'}
      </span>
    )
  },
  {
    name: 'Added',
    selector: () => 'create_date',
    sortable: true,
    // center: true,
    grow: 1,
    cell: (campaign: any) => (
      <span
        className="table-cell gray center"
        onClick={() => (window.location.href = `/campaigns/campaign/${campaign.campaignId}`)}
      >
        {campaign.create_date
          ? getClientFormattedDate(campaign.create_date, DateFormat.ShortDate)
          : 'None'}
      </span>
    )
  },
  {
    name: 'Latest Activity',
    selector: () => 'update_date',
    sortable: true,
    grow: 1,
    cell: (campaign: any) => (
      <span
        className="table-cell gray center"
        onClick={() => (window.location.href = `/campaigns/campaign/${campaign.campaignId}`)}
      >
        {campaign.update_date
          ? getClientFormattedDate(campaign.update_date, DateFormat.ShortDate)
          : 'None'}
      </span>
    )
  },
  {
    name: 'Total TBs',
    selector: () => 'numOfTracebacks',
    sortable: true,
    grow: 1,
    cell: (campaign: any) =>
      campaign.numOfTracebacks ? (
        <div
          className="table-cell blue center"
          onClick={() => (window.location.href = `/campaigns/campaign/${campaign.campaignId}`)}
        >
          <a href={`/tracebacks?campaignId=${campaign.campaignId}`}>{campaign.numOfTracebacks}</a>
        </div>
      ) : (
        <div className="table-cell gray center">
          <span>{campaign.numOfTracebacks}</span>
        </div>
      )
  },
  {
    name: 'Completed',
    selector: () => 'numOfCompleteTracebacks',
    sortable: true,
    grow: 1,
    cell: (campaign: any) =>
      campaign.numOfCompleteTracebacks ? (
        <div
          className="table-cell blue center"
          onClick={() => (window.location.href = `/campaigns/campaign/${campaign.campaignId}`)}
        >
          <a href={`/tracebacks?campaignId=${campaign.campaignId}&status=2`}>
            {campaign.numOfCompleteTracebacks}
          </a>
        </div>
      ) : (
        <div className="table-cell gray center">
          <span>{campaign.numOfCompleteTracebacks}</span>
        </div>
      )
  },
  {
    name: 'Open',
    selector: () => 'numOfPendingTracebacks',
    sortable: true,
    grow: 1,
    cell: (campaign: any) =>
      campaign.numOfPendingTracebacks ? (
        <div
          className="table-cell blue center"
          onClick={() => (window.location.href = `/campaigns/campaign/${campaign.campaignId}`)}
        >
          <a href={`/tracebacks?campaignId=${campaign.campaignId}&status=1`}>
            {campaign.numOfPendingTracebacks}
          </a>
        </div>
      ) : (
        <div className="table-cell gray center">
          <span>{campaign.numOfPendingTracebacks}</span>
        </div>
      )
  },
  {
    name: 'Not Found',
    selector: () => 'numOfNotFoundTracebacks',
    sortable: true,
    grow: 1,
    cell: (campaign: any) =>
      campaign.numOfNotFoundTracebacks ? (
        <div
          className="table-cell blue center"
          onClick={() => (window.location.href = `/campaigns/campaign/${campaign.campaignId}`)}
        >
          <a href={`/tracebacks?campaignId=${campaign.campaignId}&status=3`}>
            {campaign.numOfNotFoundTracebacks}
          </a>
        </div>
      ) : (
        <div className="table-cell gray center">
          <span>{campaign.numOfNotFoundTracebacks}</span>
        </div>
      )
  },
  {
    name: 'No Response',
    selector: () => 'numOfNoInfoTracebacks',
    sortable: true,
    grow: 1,
    cell: (campaign: any) =>
      campaign.numOfNoInfoTracebacks ? (
        <div
          className="table-cell blue center"
          onClick={() => (window.location.href = `/campaigns/campaign/${campaign.campaignId}`)}
        >
          <a href={`/tracebacks?campaignId=${campaign.campaignId}&status=4`}>
            {campaign.numOfNoInfoTracebacks}
          </a>
        </div>
      ) : (
        <div className="table-cell gray center">
          <span>{campaign.numOfNoInfoTracebacks}</span>
        </div>
      )
  },
  {
    name: 'Attachment',
    selector: () => 'numOfAttachments',
    sortable: true,
    omit: true,
    grow: 1,
    cell: (campaign: any) =>
      campaign.numOfAttachments ? (
        <div
          className="table-cell blue center"
          onClick={() => (window.location.href = `/campaigns/campaign/${campaign.campaignId}`)}
        >
          <a href={`/campaigns/campaign/${campaign.campaignId}#comments`}>
            {campaign.numOfAttachments}
          </a>
        </div>
      ) : (
        <div className="table-cell gray center">
          <span>{campaign.numOfAttachments}</span>
        </div>
      )
  }
];

const PAGENAME = 'campaigns';

const setFiltersFromHistory: any = (savedSearchDetails: any) => {
  let filterableArray = [];
  const { filterElements } = savedSearchDetails;
  let sourceId = 0;
  let labelId: any[] = [];
  let language: any[] = [];
  let campaignDescription = '';

  if (!filterElements) {
    return {};
  }

  if (!Array.isArray(filterElements.conditionals)) {
    filterableArray.push(filterElements);
  } else {
    filterableArray = filterElements.conditionals;
  }

  filterableArray.forEach((condition: FilterElement) => {
    const { name, value, conditionals } = condition;

    switch (name) {
      case 'sourceId':
        sourceId = Number(value);
        break;
      case 'description':
        campaignDescription = value || '';
        break;
      case 'labelId':
        if (value) {
          const labelsValue = value.split(',');
          labelId = labelsValue.map((label: any) => Number(label));
        }
        break;
      case 'language':
        language = handleLanguage(language, condition);
        break;
      case undefined:
        if (Array.isArray(conditionals)) {
          (conditionals as FilterElement[]).forEach((condition) => {
            if (condition.name === 'language') {
              language = handleLanguage(language, condition);
            }
          });
        }
        break;
      default:
        break;
    }
  });

  return { sourceId, campaignDescription, labelId, language };
};

export const handleLanguage = (language: any[], condition: FilterElement) => {
  switch (condition.comparator) {
    case 'IN':
      if (condition.value) {
        const languageValue = condition.value.split(',');
        language = languageValue.map((label: string) => {
          if (!label) return label;
          label = label.endsWith('"') ? label.slice(0, -1) : label;
          label = label.startsWith('"') ? label.slice(1, label.length) : label;
          return label;
        });
      }
      break;
    case 'NE':
      if (!language) language = [];
      language.push('Non-English');
      break;
  }

  return language;
};

export const getCampaignLanguageFilter = (
  filter: FilterElement,
  languages: string[]
): FilterElement => {
  if (!languages || languages.length === 0) return filter;

  const lang = languages.filter((v) => v != 'Non-English');
  let ne = newConditionalFilterElement('OR');
  if (lang.length)
    addCondition(ne, newLeafFilterElement('language', 'IN', '"' + lang.join('","') + '"'));
  if (languages.some((v) => v === 'Non-English')) {
    addCondition(ne, newLeafFilterElement('language', 'NE', 'English'));
    addCondition(ne, newLeafFilterElement('language', 'ISNULL', ''));
  }
  let wrapper = newConditionalFilterElement('AND');
  addCondition(wrapper, filter);
  addCondition(wrapper, simplifyFilterElement(ne));
  return simplifyFilterElement(wrapper);
};

const Campaigns: React.FC<IProps> = ({ campaigns, getCampaignList, paginationTotalRows }) => {
  const location = useLocation();
  const searchParams = location.search || getFromLocalStorage(PAGENAME) || '';

  const savedSearchDetails = decodeQueryParams(PAGENAME, searchParams);
  const [paginationParams, setPaginationParams] = useState<Pagination>(
    savedSearchDetails.paginationParams || {
      ...defaultPagination(),
      sort: 'update_date',
      order: 'desc'
    }
  );
  const [filterElements, setFilterElements] = useState<FilterElement>(
    savedSearchDetails.filterElements || newConditionalFilterElement('')
  );
  const [chosenLabels, setChosenLabels] = useState(
    setFiltersFromHistory(savedSearchDetails).labelId || []
  );
  const [chosenLanguages, setChosenLanguages] = useState<string[]>(
    setFiltersFromHistory(savedSearchDetails).language || []
  );
  const [source, setSource] = useState<number | undefined>(
    setFiltersFromHistory(savedSearchDetails).sourceId
  );
  const [description, setDescription] = useState(
    setFiltersFromHistory(savedSearchDetails).campaignDescription
  );

  useEffect(() => {
    setFilterElements(
      getCampaignLanguageFilter(
        getFilter({
          labelId: chosenLabels,
          campaignDescription: description,
          sourceId: source
        }),
        chosenLanguages
      )
    );
  }, [chosenLabels, description, source, chosenLanguages]);

  useEffect(() => {
    const searchParams = encodeQueryParams(PAGENAME, { paginationParams, filterElements }, {});
    saveToLocalStorage(PAGENAME, searchParams);
    getCampaignList(paginationParams, filterElements);
    window.scrollTo(0, 0);
  }, [paginationParams]);

  const handleSelectLabels = (value: any) => {
    setChosenLabels(value ? value.map((item: SelectOption) => item.value) : []);
  };
  const handleSelectLanguages = (value: any) => {
    setChosenLanguages(value ? value.map((item: SelectOption) => item.label) : []);
  };

  const updatePagination = (params: Pagination) => {
    setPaginationParams({
      ...paginationParams,
      ...params
    });
  };

  const clearFilters = () => {
    setChosenLabels([]);
    setChosenLanguages([]);
    setSource(undefined);
    setDescription('');
  };

  const searchCampaigns = () => {
    const searchParams = encodeQueryParams(PAGENAME, { paginationParams, filterElements }, {});
    saveToLocalStorage(PAGENAME, searchParams);
    getCampaignList(paginationParams, filterElements);
  };

  return (
    <Fragment>
      <Breadcrumb title="campaigns" className="table-breadcrumbs" btnText="Add Campaign" />
      <Card className="table-card hide-border">
        <CardHeader className="card-header-traceback">
          <div className="row ps-0 ms-0 mb-4">
            <div className="ps-0 col-2">
              <label className="traceback-label ps-0">Labels Filter</label>
              <LabelSelect
                isMulti
                addAllItem
                value={chosenLabels}
                onChange={handleSelectLabels}
                numberDisplayed={2}
              />
            </div>
            <div className="ps-0 col-2">
              <label className="traceback-label ps-0">Source Filter</label>
              <SourceSelect
                value={source}
                onChange={(option: any) => setSource(option?.value)}
                isClearable
              />
            </div>
            <div className="ps-0 col-2">
              <label className="traceback-label ps-0">Language</label>
              <LanguageSelect
                isMulti
                addAllItem
                value={chosenLanguages.map(
                  (v) =>
                    languageListWithNonEnglish.find((i) => i.label === v) || {
                      value: -1,
                      label: 'Non-English'
                    }
                )}
                onChange={handleSelectLanguages}
                numberDisplayed={2}
                isSearchable
                addNonEnglish
                onlySelectedLanguages
              />
            </div>
            <div className="ps-0 col-3 pt-3">
              <label className="traceback-label ps-0">Text Description</label>
              <InputFormGroup
                inputClassName="csd-search"
                inputPlaceholder="Campaign description"
                inputValue={description}
                inputOnChange={(option: any) => setDescription(option.target.value)}
              />
            </div>
            <div className="col mt-3">
              <Button color="primary" onClick={searchCampaigns}>
                <i className="fa fa-search pe-2" />
                Search
              </Button>
              <button
                type="button"
                className="btn btn-link ms-2"
                onClick={() => {
                  clearFilters();
                }}
              >
                Clear
              </button>
            </div>
          </div>
        </CardHeader>
        <CardBody className="card-body-campaign">
          <CustomDataTable
            tableData={campaigns}
            columns={columns}
            defaultSortFieldId={paginationParams.sort}
            defaultSortAsc={paginationParams.order === 'asc'}
            defaultPage={paginationParams.page}
            defaultPageSize={paginationParams.pageSize}
            paginationTotalRows={paginationTotalRows}
            useExpandableRows={true}
            useExpandableRowsComponent={ExpandedComponent}
            pagination={true}
            updatePaginationParams={updatePagination}
          />
        </CardBody>
      </Card>
    </Fragment>
  );
};

const ExpandedComponent = (props: any) => (
  <Card className="expandedRow">
    <CardBody style={{ color: '#797979', padding: '0.5rem 1rem' }} className="d-flex flex-column">
      <div className="row">
        <div className="col-1">
          <span className="special-title">Source:</span>
        </div>
        <div className="col-4">
          <span>{props.data.source ? props.data.source.replace(/[;][\s]$/, ' ') : 'None'}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-1">
          <span className="special-title">Reference:</span>
        </div>
        <div className="col-4">
          <span>{props.data.reference ? props.data.reference : 'None'}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-1">
          <span className="special-title">Created:</span>
        </div>
        <div className="col-4">
          <span>
            {props.data.create_date
              ? getClientFormattedDate(props.data.create_date, DateFormat.MediumBoth)
              : 'None'}
          </span>
        </div>
      </div>
      <div className="row">
        <div className="col-1">
          <span className="special-title">By: </span>
        </div>
        <div className="col-4">
          <span>{props.data.createdBy ? props.data.createdBy : 'None'}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-1">
          <span className="special-title">Language: </span>
        </div>
        <div className="col-4">
          <span>{props.data.Language ? props.data.Language : 'None'}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-1">
          <span className="special-title">Description:</span>
        </div>
        <div className="col-4">
          <span>{props.data.description ? props.data.description : 'None'}</span>
        </div>
      </div>
    </CardBody>
  </Card>
);

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    campaigns: sm.campaign.campaigns,
    paginationTotalRows: sm.campaign.meta.TotalCount
  };
};

const mapActionsToProps = { getCampaignList };

export default connect(mapStateToProps, mapActionsToProps)(Campaigns);
