import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Label, Row } from 'reactstrap';
import { Pagination, defaultPaginationStirShakenSigners } from '../../../interfaces/pagination';
import {
  StirShakenSearchRequest,
  StirShakenSigner,
  StirShakenSignerEqual
} from '../../../interfaces/stirShaken';
import {
  decodeQueryParams,
  encodeQueryParams,
  getFromLocalStorage,
  saveToLocalStorage
} from '../../../lib/history-utils';
import { stateMappings } from '../../../redux/stateMappings';
import { updateStirShakenApiCall } from '../../../redux/stirShaken/apiCalls';
import { getStirShakensSigners } from '../../../redux/stirShaken/thunks';
import CustomDataTable from '../../CustomDataTable';
import InputFormGroup from '../../inputFormGroup';
import { stirShakenSignerColumns } from './columns';

interface IProps {
  stirShakens: StirShakenSigner[];
  paginationTotalRows: number;
  getStirShakensSigners: Function;
}

const PAGENAME = 'stirShaken';

const StirShakenSigners: FC<IProps> = ({
  stirShakens,
  paginationTotalRows,
  getStirShakensSigners
}) => {
  const searchParams = getFromLocalStorage(PAGENAME) || '';
  const savedSearchDetails = decodeQueryParams(PAGENAME, searchParams);

  const [name, setName] = useState(savedSearchDetails.filterElements?.name);
  const [isNotAssociated, setIsNotAssociated] = useState(
    !!savedSearchDetails.filterElements?.isNotAssociated
  );
  const [paginationParams, setPaginationParams] = useState<Pagination>(
    savedSearchDetails.paginationParams || {
      ...defaultPaginationStirShakenSigners()
    }
  );
  const [selectedRows, setSelectedRows] = useState<StirShakenSigner[]>([]);

  const handleSearchChange = (e: any) => setName(e.target.value);
  const handleCheckBox = () => setIsNotAssociated((v) => !v);
  const updateStirShakenSigner = async (payload: StirShakenSigner) => {
    await updateStirShakenApiCall(payload);
    getStirShakensSigners(paginationParams, { name, isNotAssociated });
  };

  const updateRow = (row: StirShakenSigner, providerId?: number) => {
    setSelectedRows((v) =>
      v
        .filter((item) => !StirShakenSignerEqual(item, row))
        .concat({ ...row, providerId: providerId })
    );
  };

  const toggleEdit = (row: StirShakenSigner) => {
    setSelectedRows((v) => {
      return v.find((item) => StirShakenSignerEqual(item, row))
        ? v.filter((item) => !StirShakenSignerEqual(item, row))
        : v.concat({ ...row });
    });
  };

  const finishEdit = (row: StirShakenSigner) => {
    setSelectedRows((v) => {
      const selected = v.find((item) => StirShakenSignerEqual(item, row));
      if (
        selected &&
        stirShakens.find(
          (item) => StirShakenSignerEqual(item, row) && item.providerId != selected.providerId
        )
      ) {
        if (localStorage.getItem('signerCommonName')) {
          localStorage.removeItem('signerCommonName');
          setName(null);
          setIsNotAssociated(false);
          updateStirShakenApiCall(selected);
        } else updateStirShakenSigner(selected);
      }
      return selected
        ? v.filter((item) => !StirShakenSignerEqual(item, row))
        : v.concat({ ...row });
    });
  };

  const updatePagination = (params: Pagination) => {
    if (
      paginationParams.page === params.page &&
      paginationParams.order === params.order &&
      paginationParams.sort === params.sort &&
      paginationParams.pageSize === params.pageSize
    ) {
      return;
    }
    setPaginationParams({ ...paginationParams, ...params });
  };

  useEffect(() => {
    const defaultSearchParams: Pagination = { ...paginationParams, page: 1 };
    const filterElements: StirShakenSearchRequest = { name, isNotAssociated };
    const searchParams = encodeQueryParams(
      PAGENAME,
      { paginationParams: defaultSearchParams, filterElements },
      {}
    );
    saveToLocalStorage(PAGENAME, searchParams);
    getStirShakensSigners(paginationParams, filterElements);
  }, [paginationParams, name, isNotAssociated]);

  useEffect(() => {
    setSelectedRows([]);
  }, [stirShakens]);

  useEffect(() => {
    const commonName = localStorage.getItem('signerCommonName');
    setName(commonName);
    setIsNotAssociated(!!commonName);
  }, []);

  return (
    <Card>
      <CardHeader>
        <Label className="telecom-label mb-3" style={{ fontSize: '20px' }}>
          StirShakenSigners
        </Label>
        <Row>
          <Col className="col-3">
            <InputFormGroup
              inputClassName="csd-search"
              inputPlaceholder="Enter signer information"
              inputValue={name}
              inputOnChange={(option) => handleSearchChange(option)}
            />
          </Col>
          <div className="col-4">
            <div className="d-flex flex-row align-items-center">
              <input type="checkbox" checked={isNotAssociated} onChange={() => handleCheckBox()} />
              <Label className="telecom-label pb-0 pt-1">
                Show only StirShakens without signers
              </Label>
            </div>
          </div>
        </Row>
      </CardHeader>
      <CardBody className="card-body-traceback stirshaken-overflow">
        <CustomDataTable
          columns={stirShakenSignerColumns(selectedRows, updateRow, toggleEdit, finishEdit)}
          defaultSortFieldId={paginationParams.sort}
          defaultSortAsc={paginationParams.order === 'asc'}
          defaultPage={paginationParams.page}
          defaultPageSize={paginationParams.pageSize}
          pagination={true}
          paginationTotalRows={paginationTotalRows}
          tableData={stirShakens}
          updatePaginationParams={updatePagination}
          style={{ overflow: 'visible', overflowX: 'visible', overflowY: 'visible' }}
        />
      </CardBody>
    </Card>
  );
};
const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return {
    stirShakens: sm.stirShaken.stirShakens,
    paginationTotalRows: sm.stirShaken.meta.TotalCount
  };
};
const mapActionsToProps = { getStirShakensSigners };

export default connect(mapStateToProps, mapActionsToProps)(StirShakenSigners);
