import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import Breadcrumb from '../components/Breadcrumbs';
import CampaignEditor from '../components/CampaignEditor';
import { addCampaignObject } from '../redux/campaign/thunks';
import { stateMappings } from '../redux/stateMappings';

interface IProps {
  addCampaignObject: Function;
  createdBy: string;
}

const AddCampaign: React.FC<IProps> = ({ addCampaignObject, createdBy }) => (
  <Fragment>
    <Breadcrumb title="campaigns" destination={'/campaigns'} detail="Add Campaign" />
    <Card className="telecom-card">
      <CardHeader className="telecom-card-header">Add Campaign</CardHeader>
      <CardBody>
        <div className="d-flex justify-content-center">
          <Col xs="12" sm="8" lg="5">
            <CampaignEditor
              isAdd
              createdBy={createdBy}
              campaignServer={addCampaignObject}
            ></CampaignEditor>
          </Col>
        </div>
      </CardBody>
    </Card>
  </Fragment>
);

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    createdBy: sm.user.name
  };
};

const mapActionsToProps = {
  addCampaignObject
};

export default connect(mapStateToProps, mapActionsToProps)(AddCampaign);
