import { DNO } from '../../interfaces/dno';
import { Pagination } from '../../interfaces/pagination';
import { downloadFile } from '../../lib/utilities';
import {
  getAllDnoAction,
  getDnoAction,
  setDnoAction,
  setDnoErrorMessage,
  setDnoLoadingStatus
} from './actions';
import {
  associateLoaApi,
  downloadDnosCsvApi,
  getAllDnoApi,
  getDnoApi,
  getLoaAttachmentApi,
  newDnoApi,
  updateDnoApi
} from './apiCalls';

export const getAllDno = (params: Pagination, phoneNumber?: string) => async (dispatch: any) => {
  dispatch(setDnoLoadingStatus(true));
  try {
    const dnos = await getAllDnoApi(params, phoneNumber);
    if (dnos) {
      dispatch(getAllDnoAction(dnos));
    }
    dispatch(setDnoLoadingStatus(false));
  } catch (error: any) {
    dispatch(setDnoErrorMessage(error.message));
  }
};

export const getDno = (id: number) => async (dispatch: any) => {
  dispatch(setDnoLoadingStatus(true));
  try {
    const dno = await getDnoApi(id);
    if (dno) {
      dispatch(getDnoAction(dno.data));
    }
    dispatch(setDnoLoadingStatus(false));
  } catch (error: any) {
    dispatch(setDnoErrorMessage(error.message));
  }
};

export const addNewDno = (dno: DNO) => async (dispatch: any) => {
  try {
    const response = await newDnoApi(dno);
    if (response && response.data) {
      return response.data.data;
    }
  } catch (error: any) {
    if (error.response.data.error) dispatch(setDnoErrorMessage(error.response.data.error));
    else dispatch(setDnoErrorMessage(error.message));
  }
};

export const updateDno = (id: number, dno: DNO) => async (dispatch: any) => {
  try {
    const response = await updateDnoApi(id, dno);
    if (response && response.data) {
      return response.data.data;
    }
  } catch (error: any) {
    dispatch(setDnoErrorMessage(error.message));
  }
};

export const setDno = (dno: any) => async (dispatch: any) => {
  dispatch(setDnoAction(dno));
};

export const downloadDnosCsv = () => async (dispatch: any) => {
  dispatch(setDnoLoadingStatus(true));
  try {
    const response = await downloadDnosCsvApi();
    downloadFile(response.data, response.headers.contentfilename);
    dispatch(setDnoLoadingStatus(false));
  } catch (error: any) {
    dispatch(setDnoErrorMessage(error.message));
  }
};

export const getLoaAttachment = (id: string) => async (dispatch: any) => {
  try {
    const response = await getLoaAttachmentApi(id);
    return response.data.data;
  } catch (error: any) {
    dispatch(setDnoErrorMessage(error.message));
  }
};

export const associateLoa = (id: string, attachments: any[]) => async (dispatch: any) => {
  try {
    let formData = new FormData();
    attachments.forEach((attachment) => {
      formData.append('file', attachment);
    });
    const response = await associateLoaApi(id, formData);
    return response.data.data;
  } catch (error: any) {
    dispatch(setDnoErrorMessage(error.message));
  }
};
