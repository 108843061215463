import React, { Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import { RollupReportCampaignDataRow } from '../../../../interfaces/report';

interface IProps {
  campaignDataRow: RollupReportCampaignDataRow;
}
const CampaignDataRow: React.FC<IProps> = ({ campaignDataRow }: IProps) => {
  return (
    <Fragment>
      <Row style={{ padding: '0 10px' }}>
        <Col>
          <span>
            {campaignDataRow.usPointOfEntry.id
              ? campaignDataRow.usPointOfEntry.name
              : campaignDataRow.usOriginator.id
                ? campaignDataRow.usOriginator.name
                : '-'}
          </span>
        </Col>
        <Col>
          <span>{campaignDataRow.foreignPointOfDeparture.name || '-'}</span>
        </Col>
        <Col>
          <span>{campaignDataRow.internationalOriginator.name || '-'}</span>
        </Col>
        <Col>
          <span>{campaignDataRow.callingEntity || '-'}</span>
        </Col>
        <Col className="md-1 text-end">
          <span>{campaignDataRow.count || '-'}</span>
        </Col>
      </Row>
    </Fragment>
  );
};

export default CampaignDataRow;
