import { ReputationHopsRequest } from '../../interfaces/reputation';
import { ApiRequest } from '../../lib/apiRequest';

export const getReputationHopsApiCall = async (reputationHopsRequest: ReputationHopsRequest) =>
  ApiRequest.request({
    method: 'POST',
    url: `reputations/hops`,
    data: reputationHopsRequest
  });

export const getCSVApiCall = async (reputationHopsRequest: ReputationHopsRequest) =>
  ApiRequest.request({
    method: 'POST',
    url: `reputations/download/hops`,
    data: reputationHopsRequest,
    responseType: 'blob',
    headers: { accept: 'text/csv' }
  });
