import { Configuration } from './actionEnum';

const defaultState: any = {
  configuration: [],
  loading: 0,
  error: ''
};

const reducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case Configuration.GET_CONFIGURATION:
    case Configuration.UPDATE_CONFIGURATION:
      return { ...state, configuration: action.payload.data };

    case Configuration.SET_CONFIGURATION_LOADING_STATUS:
      if (action.payload) return { ...state, loading: state.loading + 1 };
      else return { ...state, loading: state.loading === 0 ? state.loading : state.loading - 1 };

    case Configuration.SET_CONFIGURATION_ERROR_MESSAGE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
};

export default reducer;
