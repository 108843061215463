import { Pagination } from '../../interfaces/pagination';
import { FilterElement } from '../../lib/FilterElement';
import { ApiRequest, buildParams } from '../../lib/apiRequest';

export const addMessageApiCall = async (data: any) =>
  await ApiRequest.request({
    method: 'POST',
    url: `/EmailRequest`,
    data
  });

export const getMessagesApiCall = async (params: Pagination, filterElements: FilterElement) =>
  ApiRequest.request({
    method: 'POST',
    url:
      `/EmailRequest/search?` +
      buildParams({
        ...params
      }),
    data: filterElements
  });

export const getMessageApiCall = async (id: number) =>
  ApiRequest.request({ method: 'GET', url: `/EmailRequest/${id}` });

export const editMessageApiCall = async (id: number, data: any) =>
  ApiRequest.request({
    method: 'PUT',
    url: `/EmailRequest/` + id,
    data
  });

export const cancelMessageApiCall = async (id: number) =>
  ApiRequest.request({
    method: 'DELETE',
    url: `/EmailRequest/` + id
  });

export const getValidateEmailApi = async (email: string) =>
  ApiRequest.request({
    method: 'GET',
    url: '/email/check?email=' + email
  });

export const removeEmailFromSESListApi = async (email: string) =>
  ApiRequest.request({
    method: 'GET',
    url: '/email/remove_ses?email=' + email
  });

export const getEmailEventsApiCall = async (params: Pagination, filterElements: FilterElement) =>
  ApiRequest.request({
    method: 'POST',
    url:
      `/email/searchEvents?` +
      buildParams({
        ...params
      }),
    data: filterElements
  });
