import React, { useEffect, useRef, useState } from 'react';
import { getZendeskURL } from '../redux/auth/apiCalls';

const ZendeskLogin: React.FC = () => {
  const formRef = useRef<HTMLFormElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [jwt, setJwt] = useState('');
  useEffect(() => {
    getZendeskURL()
      .then((res) => {
        setJwt(res.data);
      })
      .catch((error: any) => {
        console.log('ZendeskError:', error);
      });
  }, []);

  useEffect(() => {
    if (formRef && formRef.current && inputRef && inputRef.current && jwt) {
      // add the JWT to a hidden form on your login page
      inputRef.current.value = jwt;

      formRef.current.submit();
    }
  }, [formRef, inputRef, jwt]);
  return (
    <form
      ref={formRef}
      action={`https://industrytracebackgroup.zendesk.com/access/jwt`}
      method="post"
    >
      <input ref={inputRef} type="hidden" name="jwt"></input>
    </form>
  );
};

export default ZendeskLogin;
