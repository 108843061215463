import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';
import { logOut } from '../lib/apiRequest';
import { stateMappings } from '../redux/stateMappings';
import { getRedirectLocation } from '../redux/user/thunks';

interface IProps {
  getRedirectLocation: Function;
  redirectLocation: string;
  isAuthenticated: boolean;
}

const EmailRedirect: React.FC<IProps> = ({
  getRedirectLocation,
  redirectLocation,
  isAuthenticated
}) => {
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();

  useEffect(() => {
    const userID = Number(searchParams.get('userId'));
    if (isAuthenticated) {
      logOut();
    }
    getRedirectLocation(searchParams.get('token'), userID, searchParams.get('location'));
  }, []);

  useEffect(() => {
    if (redirectLocation !== '') {
      navigate(redirectLocation);
    }
  }, [redirectLocation]);

  return (
    <Card className="card-sign">
      <CardBody>
        <div className="container-fluid">
          <div className="d-flex justify-content-center">
            <Col xs="12" sm="8" lg="5">
              <h1>Waiting for redirect</h1>
            </Col>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return {
    redirectLocation: sm.users.redirectLocation,
    isAuthenticated: sm.isAuthenticated
  };
};

const mapActionsToProps = { getRedirectLocation };

export default connect(mapStateToProps, mapActionsToProps)(EmailRedirect);
