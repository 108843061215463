import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';

const STORAGE_KEY = 'APP_VERSION';

const usePersistedState = (
  name: string,
  defaultValue: string
): [string, Dispatch<SetStateAction<string>>] => {
  const [value, setValue] = useState(defaultValue);
  const nameRef = useRef(name);

  useEffect(() => {
    try {
      const storedValue = localStorage.getItem(name);
      if (storedValue !== null) setValue(storedValue);
      else localStorage.setItem(name, defaultValue);
    } catch {
      setValue(defaultValue);
    }
  }, []);

  useEffect(() => {
    try {
      localStorage.setItem(nameRef.current, value);
    } catch {}
  }, [value]);

  useEffect(() => {
    const lastName = nameRef.current;
    if (name !== lastName) {
      try {
        localStorage.setItem(name, value);
        nameRef.current = name;
        localStorage.removeItem(lastName);
      } catch {}
    }
  }, [name]);

  return [value, setValue];
};

type OwnProps = {
  duration?: number;
  filename?: string;
};

const defaultProps: OwnProps = { duration: 60 * 1000, filename: 'meta.json' };

let fetchCacheTimeout: any;

export const installCheckVersionHook = (props?: OwnProps) => {
  const { duration, filename } = { ...defaultProps, ...props };
  const [appVersion, setAppVersion] = usePersistedState(STORAGE_KEY, '');
  const navigate = useNavigate();

  const emptyCacheStorage = async (version?: string) => {
    if ('caches' in window) {
      // Service worker cache should be cleared with caches.delete()
      const cacheKeys = await window.caches.keys();
      await Promise.all(
        cacheKeys.map((key) => {
          window.caches.delete(key);
        })
      );
    }

    // clear browser cache and reload page
    navigate(0);
  };

  const fetchMeta = () => {
    try {
      fetch('/' + filename, { cache: 'no-store' })
        .then((response) => response.json())
        .then((meta) => {
          const newVersion = meta.version;
          const currentVersion = appVersion;
          setAppVersion(newVersion);
          if (currentVersion && newVersion !== currentVersion) {
            emptyCacheStorage(newVersion);
          }
        });
    } catch (err) {
      console.error(err);
    }
  };

  const startVersionCheck = useCallback(() => {
    if (window.navigator.onLine) {
      fetchCacheTimeout = setInterval(() => fetchMeta(), duration);
    }
  }, []);
  const stopVersionCheck = useCallback(() => {
    clearInterval(fetchCacheTimeout);
  }, []);

  useEffect(() => {
    window.addEventListener('focus', startVersionCheck);
    window.addEventListener('blur', stopVersionCheck);
    () => {
      window.removeEventListener('focus', startVersionCheck);
      window.removeEventListener('blur', stopVersionCheck);
    };
  }, []);

  useEffect(() => {
    fetchMeta();
  }, []);
};
