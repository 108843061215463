import React, { Fragment } from 'react';
import { DateFormat } from '../../enum/DateFormat';
import { TfHop } from '../../interfaces/tfhop';
import { Traceforward } from '../../interfaces/traceforward';
import { getClientFormattedDate } from '../../lib/utilities';

interface IProps {
  tfhop: TfHop;
  traceforward: Traceforward;
}

const CallDetailsRow: React.FC<IProps> = ({ tfhop, traceforward }) => {
  return (
    <Fragment>
      <h5 className="p-0 traceforward-sub-title mb-3">Call Details</h5>
      <div className="d-flex flex-row justify-content-around mb-3">
        <div style={{ marginLeft: '25px' }}>
          <label className="telecom-label">Date & Time of Call</label>
          <span className="table-cell-gray">
            {getClientFormattedDate(traceforward.traceforwardTime, DateFormat.LongBoth)}
          </span>
        </div>
        <div>
          <label className="telecom-label">Called Number</label>
          {tfhop.forwardedCall || traceforward.calledTN}
        </div>
        <div>
          <label className="telecom-label">Calling Number</label>
          {traceforward.callingTN}
        </div>
        <div>
          <label className="telecom-label">Caller Name</label>
          <span className="table-cell-gray">{traceforward.callerName}</span>
        </div>
        <div className="col-3">
          <label className="telecom-label">Audio URL</label>
          {traceforward.audioUrl ? (
            <Fragment>
              <audio controls>
                <source src={traceforward.audioUrl} />
              </audio>
              <a href={traceforward.audioUrl} className="span-cell blue">
                {traceforward.audioUrl}
              </a>
            </Fragment>
          ) : (
            <span className="span-cell gray">{'None'}</span>
          )}
        </div>
      </div>
    </Fragment>
  );
};
export default CallDetailsRow;
