import React from 'react';
import NavMenu from './NavMenu';

interface IProps {
  children?: React.ReactNode;
}
const Layout: React.FC<IProps> = ({ children }: IProps) => (
  <React.Fragment>
    <NavMenu />
    {children}
  </React.Fragment>
);

export default Layout;
