import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { Button, Card, CardBody } from 'reactstrap';
import uploadLogo from '../../assets/images/upload-drop.svg';
import { MAX_FILE_SIZE } from '../../lib/dropZoneUtils';
import { uploadSpreadSheet } from '../../redux/traceback/apiCalls';

interface IProps {
  setActiveTab: Function;
}

const UploadTracebacks: React.FC<IProps> = ({ setActiveTab }) => {
  const [excelFile, setExcelFile] = useState<File>();
  const [uploadError, setUploadError] = useState('');
  const setError = (file: File) => {
    if (file.size > MAX_FILE_SIZE && !file.name.endsWith('.xlsx'))
      setUploadError(
        'File size to large for upload, please limit file to 5MB or less. File type need to be .xlsx'
      );
    else if (file.size <= MAX_FILE_SIZE && file.name.endsWith('.xlsx'))
      setUploadError('File size to large for upload, please limit files to 5MB or less.');
    else if (file.size <= MAX_FILE_SIZE && !file.name.endsWith('.xlsx'))
      setUploadError('File type need to be .xlsx');
  };
  const importTracebacks = async () => {
    if (excelFile) {
      let formData = new FormData();
      formData.append('excelTraceback', excelFile);
      try {
        await uploadSpreadSheet(formData);
        setExcelFile(undefined);
        setActiveTab('new-tracebacks');
      } catch (error: any) {
        const errorA = error as any;
        if (errorA.response) {
          setUploadError('Unable to parse excel ' + errorA.response.data.error);
        } else {
          setUploadError('Unable to parse excel');
        }
      }
    }
  };
  return (
    <Card className="upload-card">
      <CardBody className="mt-3">
        {excelFile && (
          <div className="d-flex justify-content-center telecom-text mb-2">
            {`${excelFile.name} `}
            <i
              className="fa fa-close me-2"
              style={{ color: 'red', cursor: 'pointer', paddingTop: '2.5px' }}
              onClick={(e) => {
                e.preventDefault();
                setExcelFile(undefined);
              }}
            ></i>
          </div>
        )}
        <div className="d-flex justify-content-center">
          <Dropzone
            multiple={false}
            accept={{ 'application/*': ['.xlsx'] }}
            maxSize={MAX_FILE_SIZE}
            onDrop={(file) => setExcelFile(file[0])}
            onDropRejected={(file) => setError(file[0].file)}
            onDropAccepted={() => setUploadError('')}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <p className="text-center">
                  <img width="30" height="30" src={uploadLogo} alt="upload-drop" />
                </p>
                <p className="text-center">Drag and Drop file or click to browse</p>
              </div>
            )}
          </Dropzone>
        </div>
        <div className="d-flex justify-content-center">
          {uploadError && <small className="text-danger mb-2">{uploadError}</small>}
        </div>

        <div className="d-flex justify-content-center">
          <Button
            className="telecom-btn"
            style={{ minWidth: '100px', marginRight: '0px' }}
            disabled={!excelFile || !!uploadError}
            onClick={async () => await importTracebacks()}
          >
            Import Tracebacks
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};
export default UploadTracebacks;
