export interface Meta {
  TotalCount: number;
  PageSize: number;
  CurrentPage: number;
  TotalPages: number;
}
export const defaultMeta: Meta = {
  TotalCount: 0,
  PageSize: 0,
  CurrentPage: 0,
  TotalPages: 1
};

export interface WithMeta<T = any> {
  data: Array<T>;
  paginationMeta: Meta;
}
