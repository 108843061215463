import { Traceback } from '../../interfaces/traceback';
import React, { FC, useMemo } from 'react';

interface IProps {
  traceback: Traceback;
}
const AttestationTracebackStirShaken: FC<IProps> = ({ traceback }) => {
  const stirShakens = useMemo(
    () =>
      (traceback.hopsStirShaken
        ? [traceback.stirShaken, ...traceback.hopsStirShaken]
        : [traceback.stirShaken]
      ).filter((v) => !!v && v.status === 0),
    [traceback.hopsStirShaken, traceback.stirShaken]
  );

  const attestations = useMemo(
    () =>
      stirShakens
        .map((v) => ({
          // map it to a simpler object
          providerId: v.signerProviderId,
          attestation: v.attest,
          organization:
            v.signer?.organization &&
            v.signer?.organization.length > 0 &&
            v.signer?.organization.join(' ')
        }))
        .filter(
          (value, index, array) =>
            (value.providerId || value.organization) && // filter only the ones with provider or organization
            array.findIndex(
              // remove duplicates
              (v) => v.providerId === value.providerId || v.organization === value.organization
            ) === index
        )
        .map((v) => v.attestation),
    [stirShakens]
  );

  return (
    <div className="table-cell center ">
      <span className={`${attestations.length >= 1 ? 'span-cell red' : 'span-cell gray'}`}>
        {attestations.join('/')}
      </span>
    </div>
  );
};

export default AttestationTracebackStirShaken;
