import React, { FC, useState } from 'react';
import { Card, CardBody, Nav, NavItem, TabContent, TabPane } from 'reactstrap';
import SpreadsheetTracebacks from '../components/spreadsheetTraceback/spreadsheetTracebacks';
import UploadTracebacks from '../components/spreadsheetTraceback/uploadTracebacks';

const ImportTracebacks: FC = () => {
  const [activeTab, setActiveTab] = useState('new-tracebacks');
  return (
    <Card className="import-card">
      <CardBody>
        <Nav tabs className="tab-list">
          <NavItem className="nav-item-horizontal">
            <a
              className={'tab-list-item ' + (activeTab === 'new-tracebacks' ? ' active' : '')}
              href="tracebacks/import#new-tracebacks"
              onClick={() => setActiveTab('new-tracebacks')}
            >
              New Tracebacks
            </a>
            <a
              className={'tab-list-item ' + (activeTab === 'import-tracebacks' ? ' active' : '')}
              href="tracebacks/import#import-tracebacks"
              onClick={() => setActiveTab('import-tracebacks')}
            >
              Import Tracebacks
            </a>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="new-tracebacks">
            <SpreadsheetTracebacks activeTab={activeTab} />
          </TabPane>
          <TabPane tabId="import-tracebacks">
            <UploadTracebacks setActiveTab={setActiveTab} />
          </TabPane>
        </TabContent>
      </CardBody>
    </Card>
  );
};

export default ImportTracebacks;
