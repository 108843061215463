import React, { useEffect, useRef, useState } from 'react';
import Select, {
  MenuListProps,
  MenuProps,
  OnChangeValue,
  OptionProps,
  ValueContainerProps,
  components
} from 'react-select';
import { ApiLookup, SelectOption, getApiItems } from './CustomSelect';

interface IProps {
  className?: string;
  classNamePrefix?: string | null;
  isSearchable?: boolean;
  isClearable?: boolean;
  placeholder?: string;
  inputId?: string;

  typeLabel: string;

  getOptions: SelectOption[] | ApiLookup;
  addAllItem?: boolean;
  noneSelectedByDefault?: boolean;
  removeId?: number;
  selectedOption: any;
  setSelectedOption: (item: OnChangeValue<SelectOption, boolean>) => void;
  numberDisplayed: number;
  maxMenuHeight?: number;
}

const CustomPicky: React.FC<IProps> = ({
  className,
  classNamePrefix,
  isSearchable,
  isClearable,
  placeholder,
  inputId,
  typeLabel,
  getOptions,
  addAllItem,
  noneSelectedByDefault,
  removeId,
  selectedOption,
  setSelectedOption,
  numberDisplayed,
  maxMenuHeight
}) => {
  const [loading, setLoading] = useState(true);
  const [toggleAll, setToggleAll] = useState<boolean | null>(null);
  const [options, setOptions] = useState<SelectOption[]>([]);
  useEffect(() => {
    if (toggleAll) {
      setSelectedOption(getOptionValue()?.filter((v) => v.value) || []);
    } else if (toggleAll !== null) {
      setSelectedOption([]);
    }
  }, [toggleAll]);

  useEffect(() => {
    if (toggleAll && selectedOption && !selectedOption.length) setToggleAll(false);
  }, [selectedOption]);

  const addFirstItem = (items: SelectOption[]): SelectOption[] => {
    const firstItem: SelectOption | null = addAllItem
      ? { label: typeLabel, value: 0 }
      : noneSelectedByDefault
        ? {
            label: placeholder ? placeholder : 'Select An Option...',
            value: 0
          }
        : null;
    if (firstItem) {
      items = [firstItem, ...items];
    }
    return items;
  };

  const populateOptions = async (api: ApiLookup) => {
    try {
      setOptions(
        addFirstItem(
          (await getApiItems(api)).filter(
            (item: SelectOption): boolean => Number(item.value) !== removeId
          )
        )
      );
    } catch (error: any) {
      console.log('Error:', error);
    }
  };
  useEffect(() => {
    if (!Array.isArray(getOptions)) {
      populateOptions(getOptions);
    } else {
      setOptions(addFirstItem(getOptions));
    }
    setLoading(false);
  }, [getOptions]);

  const equal = (item: SelectOption, element: any): boolean =>
    item === element ||
    item.value === element ||
    item.value === element?.value ||
    (!element && item.value === 0);
  const getOptionValue = (): SelectOption[] | undefined | null => {
    let opts = Array.isArray(selectedOption)
      ? options.filter((item: SelectOption) =>
          selectedOption.some((element: any) => equal(item, element))
        )
      : options.filter((item: SelectOption): boolean => equal(item, selectedOption));
    if (opts.length && toggleAll) opts = options;
    else opts = opts.filter((item) => !!item.value);
    return opts && opts.length && !selectedOption ? null : opts;
  };

  const valueRef = useRef<HTMLDivElement | null>(null);
  const ValueContainer = ({ children, ...props }: ValueContainerProps<any, boolean>) => {
    const selectedOptions = getOptionValue()?.filter((item) => !!item.value);
    return !selectedOptions || selectedOptions.length < numberDisplayed ? (
      <div ref={valueRef} className="ps-2">
        <components.ValueContainer {...props}>{children}</components.ValueContainer>
      </div>
    ) : (
      <div className="ps-2">{selectedOptions.length} selected</div>
    );
  };

  const onOptionClick = (item: SelectOption) => {
    if (!item.value) setToggleAll((prev) => !prev);
    else setToggleAll(null);
  };
  const Option = (props: OptionProps<any, boolean>) => (
    <components.Option {...props}>
      <div onClick={() => onOptionClick(props.data)}>
        <input type="checkbox" checked={props.isSelected} readOnly /> <label>{props.label}</label>
      </div>
    </components.Option>
  );

  const inputRef = useRef<HTMLInputElement | null>(null);
  const MenuList = (props: MenuListProps<any, true>) => {
    const { onInputChange, inputValue } = props.selectProps;
    return (
      <components.MenuList {...props}>
        {isSearchable && (
          <input
            ref={inputRef}
            style={{
              width: '100%',
              boxSizing: 'border-box',
              padding: 10,
              border: 'none',
              borderBottom: '1px solid lightgrey'
            }}
            autoCorrect="off"
            autoComplete="off"
            spellCheck="false"
            type="text"
            value={inputValue}
            onChange={(e) => {
              onInputChange &&
                onInputChange(e.currentTarget.value, {
                  action: 'input-change',
                  prevInputValue: ''
                });
            }}
            placeholder="Search..."
            aria-autocomplete="list"
            aria-label={props.selectProps['aria-label']}
            aria-labelledby={props.selectProps['aria-labelledby']}
            onMouseDown={(e) => {
              e.stopPropagation();
              const target = e.target as HTMLElement;
              target.focus();
            }}
            onTouchEnd={(e) => {
              e.stopPropagation();
              const target = e.target as HTMLElement;
              target.focus();
            }}
          />
        )}
        {props.children}
      </components.MenuList>
    );
  };

  const containerRef = useRef<HTMLDivElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const onDomClick = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    setIsOpen((v) =>
      containerRef?.current?.contains(target) &&
      !(
        valueRef?.current?.contains(target) &&
        (e.target instanceof SVGElement || target.className.includes('remove'))
      )
        ? menuRef?.current?.contains(target) || !v
        : false
    );
  };

  useEffect(() => {
    if (!isOpen) setInputValue('');
    else inputRef.current?.focus();
  }, [isOpen]);

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputValue]);

  useEffect(() => {
    document.addEventListener('mousedown', onDomClick);
    return () => {
      document.removeEventListener('mousedown', onDomClick);
    };
  }, []);

  const Menu = (props: MenuProps<any, true>) => {
    return (
      <div ref={menuRef}>
        <components.Menu {...props}>{props.children}</components.Menu>
      </div>
    );
  };

  return !loading ? (
    <div ref={containerRef}>
      <Select
        isMulti
        hideSelectedOptions={false}
        components={{
          Menu,
          MenuList,
          Option,
          ValueContainer
        }}
        // allowSelectAll={true}
        className={className}
        classNamePrefix={classNamePrefix || 'customselect'}
        isSearchable={false}
        isClearable={isClearable}
        placeholder={placeholder}
        inputId={inputId}
        onChange={(item: OnChangeValue<SelectOption, boolean>) => {
          setSelectedOption(item);
          setIsOpen(false);
        }}
        options={options}
        value={getOptionValue()}
        maxMenuHeight={maxMenuHeight}
        inputValue={inputValue}
        onInputChange={(val: any) => setInputValue(val)}
        menuIsOpen={isOpen}
        blurInputOnSelect
      />
    </div>
  ) : null;
};

export default CustomPicky;
