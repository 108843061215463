import React from 'react';
import supportImg from '../assets/images/support-desktop.jpg';
import ConvertOldLinks from '../components/Attachments/ConvertOldLinks';

const Support = () => (
  <div className="container-fluid px-md-5 static-page-content">
    <div className="row py-3 pb-4">
      <div className="col-6"></div>
      <div className="col-6 text-end"></div>
    </div>
    <div className="row py-3 pb-4">
      <div className="col-sm-12">
        <div className="card shadow-lg support-card" id="main-card-long s">
          <div className="card-header" style={{ backgroundImage: `url(${supportImg})` }}>
            <div className="row py-2">
              <div className="col-12 text-center py-5">
                <h1 className="card-title text-white my-2 my-lg-5 py-5 pt-2 ps-4">
                  <b>How can we help?</b>
                </h1>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="d-flex p-4 justify-content-center flex-wrap">
              <div className="col-5 mb-4">
                <div className="card card-body rounded-0 p-5 detail-box">
                  <h5 className="text-center font-weight-bold">
                    QUESTIONS ABOUT <br />
                    THE INDUSTRY TRACEBACK GROUP
                  </h5>
                  <div className="mx-2">
                    <div className="ms-3 mb-4 pull-left w-60">
                      <b>Email</b>
                    </div>
                    <a style={{ color: '#0f72b1' }} href="mailto:jessica@tracebacks.org">
                      jessica@tracebacks.org
                    </a>
                  </div>
                </div>
              </div>
              <div className="d-none d-xl-block p-4 m-0"></div>
              <div className="col-5 mb-4">
                <div className="card card-body rounded-0 p-5 detail-box">
                  <h5 className="text-center font-weight-bold ">TECHNICAL SUPPORT</h5>
                  <div className="mx-2">
                    <div className="ms-3 mb-4 pull-left w-60">
                      <b>Email</b>
                    </div>
                    <a style={{ color: '#0f72b1' }} href="mailto:support@tracebacks.org">
                      support@tracebacks.org
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-6 text-center">
                <p>
                  Received a Traceback Notice? You can learn more about the Industry Traceback Group
                  and its role with enforcement{' '}
                  <a style={{ color: '#0f72b1' }} href="https://www.tracebacks.org">
                    here
                  </a>
                  .
                </p>
              </div>
            </div>

            <div className="row p-4">
              <ConvertOldLinks />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Support;
