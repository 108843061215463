import React, { FC } from 'react';

interface IProps {
  count: number;
  smallIcon?: boolean;
}

const IconCounterDisplay: FC<IProps> = ({ count, smallIcon }) => {
  return (
    <div className="d-flex justify-content-center align-item-center bg-danger rounded-circle iconCounter">
      <span className={`text-light ${smallIcon && 'small'}`}>{count}</span>
    </div>
  );
};

export default IconCounterDisplay;
