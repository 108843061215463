import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { OnChangeValue } from 'react-select';
import { CampaignSource } from '../../interfaces/campaign';
import { getSourceList } from '../../redux/campaign/thunks';
import { stateMappings } from '../../redux/stateMappings';
import CustomSelect, { SelectOption } from '../CustomSelect';

interface IProps {
  sources: SelectOption[];
  getSourceList: Function;

  value: number | number[] | SelectOption | SelectOption[] | undefined | null;
  onChange: (value: OnChangeValue<SelectOption, boolean>) => void;
  selectClass?: string;
  isClearable?: boolean;
  placeholder?: string;
  isSearchable?: boolean;
  maxMenuHeight?: number;
  typeLabel?: string;
}

const SourceSelect: React.FC<IProps> = ({
  sources,
  getSourceList,
  value,
  onChange,
  selectClass,
  isClearable,
  placeholder,
  isSearchable,
  maxMenuHeight,
  typeLabel
}) => {
  useEffect(() => {
    if (!sources || !sources.length) getSourceList();
  }, []);
  return (
    <CustomSelect
      className={selectClass ? selectClass : 'customselect-small'}
      typeLabel={typeLabel || ''}
      getOptions={sources}
      selectedOption={value}
      setSelectedOption={onChange}
      isClearable={isClearable}
      placeholder={placeholder}
      isSearchable={isSearchable}
      maxMenuHeight={maxMenuHeight}
    />
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  const sources = sm.campaign.sources.map((source: CampaignSource) => ({
    value: source.sourceId,
    label: source.name
  }));
  return { sources };
};

const mapActionsToProps = { getSourceList };

export default connect(mapStateToProps, mapActionsToProps)(SourceSelect);
