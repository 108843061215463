import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader } from 'reactstrap';
import Breadcrumb from '../components/Breadcrumbs';
import ProviderEditor from '../components/providerEditor';
import { ProviderType, emptyProvider } from '../interfaces/provider';
import { addProviderObject } from '../redux/provider/thunks';
import { stateMappings } from '../redux/stateMappings';

interface IProps {
  addProviderObject: Function;
  providerTypes: ProviderType[];
}

const AddProvider: React.FC<IProps> = ({ addProviderObject, providerTypes }) => {
  return (
    <Fragment>
      <Breadcrumb title="providers" destination={'/providers'} detail="Add Provider" />
      <Card className="telecom-card">
        <CardHeader className="telecom-card-header">Add Provider</CardHeader>
        <CardBody className="card-add-provider">
          <ProviderEditor
            isAdd
            providerProp={emptyProvider()}
            providerServer={addProviderObject}
            providerTypes={providerTypes}
            approvalComplete={() => {}}
          />
        </CardBody>
      </Card>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return { providerTypes: sm.provider.providerTypes };
};

const mapActionsToProps = { addProviderObject };

export default connect(mapStateToProps, mapActionsToProps)(AddProvider);
