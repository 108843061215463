import React, { FC, useEffect } from 'react';
import { OnChangeValue } from 'react-select';
import { getRequestors } from '../../redux/request/apiCalls';
import { stateMappings } from '../../redux/stateMappings';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import CustomSelect, { SelectOption } from '../CustomSelect';

interface IProps {
  option?: SelectOption | number | null;
  onChange: (value: OnChangeValue<SelectOption, boolean>) => void;
  isSearchable?: boolean;
  className?: string;
  isClearable?: boolean;
  placeholder?: string;
  maxMenuHeight?: number;
}

const RequestorSelect: FC<IProps> = ({
  option,
  onChange,
  isSearchable,
  className,
  isClearable,
  placeholder,
  maxMenuHeight
}) => {
  const dispatch = useAppDispatch();

  const requestors = useAppSelector((state) => stateMappings(state).request.requestors);
  useEffect(() => {
    dispatch(getRequestors());
  }, []);
  return (
    <CustomSelect
      getOptions={(requestors || []).map((v) => ({ label: v.requestorName, value: v.requestorId }))}
      isClearable={isClearable ? isClearable : false}
      isSearchable={isSearchable}
      setSelectedOption={onChange}
      placeholder={placeholder}
      className={className}
      typeLabel={'All Requestors'}
      selectedOption={option}
      maxMenuHeight={maxMenuHeight}
    />
  );
};
export default RequestorSelect;
