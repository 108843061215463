import { convertToTimeZone } from 'date-fns-timezone';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { DebounceInput } from 'react-debounce-input';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import Select, { OnChangeValue } from 'react-select';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  FormGroup,
  Input,
  Row
} from 'reactstrap';
import Breadcrumb from '../components/Breadcrumbs';
import CampaignsSelect from '../components/CampaignsSelect';
import CustomDataTable from '../components/CustomDataTable';
import { SelectOption } from '../components/CustomSelect';
import HopInvalidAlert from '../components/Hops/HopInvalidAlert';
import ZerobounceAlert from '../components/Hops/ZerobounceAlert';
import ProvidersSelect from '../components/ProvidersSelect';
import Legend from '../components/shared/Legend';
import ZendeskWebWidget from '../components/shared/ZendeskWebWidget';
import { DateFormat } from '../enum/DateFormat';
import { userTypes } from '../enum/userTypes';
import { Hop } from '../interfaces/hop';
import { Pagination, defaultPagination } from '../interfaces/pagination';
import { Provider } from '../interfaces/provider';
import { Traceback } from '../interfaces/traceback';
import { PersonalInfo } from '../interfaces/user';
import {
  addCondition,
  addConditionGroup,
  newConditionalFilterElement,
  newLeafFilterElement,
  simplifyFilterElement
} from '../lib/FilterElement';
import { hopsColumns } from '../lib/dataTableUtils/hopsColumns';
import { decodeQueryParams, getFromLocalStorage, saveToLocalStorage } from '../lib/history-utils';
import {
  buildCsdFilterElement,
  getClientFormattedDate,
  getElapsedTime,
  parseDateToISOString
} from '../lib/utilities';
import { getAllHopsList, getHopsCSV, getHopsCountNumber } from '../redux/hop/thunks';
import { getProviderObject } from '../redux/provider/thunks';
import { stateMappings } from '../redux/stateMappings';
import { getTracebackObject, getTracebackStats } from '../redux/traceback/thunks';

const hopOrigins: SelectOption[] = [
  { label: 'POE', value: '1' },
  { label: 'ORG', value: '2' },
  { label: 'IOR', value: '3' },
  { label: 'Foreign PoD', value: '4' }
];

const PAGENAME = 'hops';

const getHopStatuses = (role: userTypes): SelectOption[] => {
  const statuses = [
    { label: 'Open', value: '1' },
    { label: 'Completed', value: '2' },
    { label: 'Not Found', value: '3' },
    { label: 'No Response', value: '4' }
  ];
  return role === userTypes.Admin
    ? statuses.concat([
        { label: 'Pending Approval', value: '6' },
        { label: 'Pending Dispute', value: '7' }
      ])
    : statuses;
};

const getFilter = (
  status: SelectOption[],
  origin: SelectOption[],
  isStrikeExempt: boolean,
  isDisputeRejected: boolean,
  isItgMember: boolean,
  providerId: number,
  tracebackId: number,
  campaignId: number,
  startDate: any,
  endDate: any,
  callSourceDetails: string,
  tracebackNumber: string,
  chosenProvider: number
) => {
  let filterElements = newConditionalFilterElement('AND');

  if (status && status.length > 0) {
    let statusElements = newConditionalFilterElement('OR');
    status.forEach((item) => {
      addCondition(statusElements, newLeafFilterElement('status', 'EQ', item.value));
    });
    addCondition(filterElements, simplifyFilterElement(statusElements));
  }

  if (!!campaignId)
    addCondition(filterElements, newLeafFilterElement('campaignId', 'EQ', campaignId.toString()));

  if (!!callSourceDetails) buildCsdFilterElement(filterElements, callSourceDetails);

  if (origin && origin.length > 0) {
    let originElements = newConditionalFilterElement('OR');
    origin.forEach((item: any) => {
      switch (item.label) {
        case 'IOR':
          addCondition(originElements, newLeafFilterElement('isIor', 'EQ', '1'));
          break;
        case 'ORG':
          addCondition(originElements, newLeafFilterElement('isOrig', 'EQ', '1'));
          break;
        case 'POE':
          addCondition(originElements, newLeafFilterElement('isPoe', 'EQ', '1'));
          break;
        case 'Foreign PoD':
          addCondition(originElements, newLeafFilterElement('isFpd', 'EQ', '1'));
          break;
        default:
          break;
      }
    });
    addCondition(filterElements, simplifyFilterElement(originElements));
  }

  if (startDate || endDate) {
    addConditionGroup('AND', filterElements, [
      { key: startDate ? 'startDate' : '', value: startDate },
      { key: endDate ? 'endDate' : '', value: endDate }
    ]);
  }

  if (isStrikeExempt)
    addCondition(filterElements, newLeafFilterElement('isStrikeExempt', 'EQ', '1'));
  if (isDisputeRejected)
    addCondition(filterElements, newLeafFilterElement('isDisputeRejected', 'EQ', '1'));
  if (isItgMember) addCondition(filterElements, newLeafFilterElement('isItgMember', 'EQ', '1'));
  if (providerId)
    addCondition(filterElements, newLeafFilterElement('providerId', 'EQ', providerId.toString()));
  if (tracebackId)
    addCondition(filterElements, newLeafFilterElement('tracebackId', 'EQ', tracebackId.toString()));
  if (!!tracebackNumber)
    addCondition(filterElements, newLeafFilterElement('tracebackId', 'RLIKE', tracebackNumber));
  if (!!chosenProvider) {
    let filterProvider = newConditionalFilterElement('OR');
    addCondition(
      filterProvider,
      newLeafFilterElement('callReceivedFrom', 'EQ', chosenProvider.toString())
    );
    addCondition(
      filterProvider,
      newLeafFilterElement('callSentTo', 'EQ', chosenProvider.toString())
    );
    addCondition(filterElements, simplifyFilterElement(filterProvider));
  }
  return simplifyFilterElement(filterElements);
};

interface IProps {
  isAuthenticated: boolean;
  user: PersonalInfo;
  provider: Provider;
  hops: Array<Hop>;
  paginationTotalRows: number;
  numOpenHops: number;
  statuses: { [key: number]: string };
  traceback: Traceback;
  hopInvalidId: number;
  getTracebackObject: Function;
  getProviderObject: Function;
  getTracebackStats: Function;
  getHopsCountNumber: Function;
  getAllHopsList: Function;
  getHopsCSV: Function;
}

const Hops: React.FC<IProps> = ({
  isAuthenticated,
  user,
  provider,
  hops,
  paginationTotalRows,
  numOpenHops,
  statuses,
  traceback,
  hopInvalidId,
  getTracebackObject,
  getProviderObject,
  getTracebackStats,
  getHopsCountNumber,
  getAllHopsList,
  getHopsCSV
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const savedSearchDetails = useMemo(() => {
    let search = searchParams.toString();
    if (!search) {
      search = getFromLocalStorage(PAGENAME) || '';
      setSearchParams(search);
    }
    return decodeQueryParams(PAGENAME, search);
  }, [searchParams]);

  const [isItgMember, setIsItgMember] = useState(!!savedSearchDetails.isItgMember);
  const [isStrikeExempt, setIsStrikeExempt] = useState(!!savedSearchDetails.isStrikeExempt);
  const [isDisputeRejected, setIsDisputeRejected] = useState(
    !!savedSearchDetails.isDisputeRejected
  );
  const hopStatuses = useMemo(() => getHopStatuses(user.roleType), [user.roleType]);
  const [status, setStatus] = useState(() => {
    const statuses = (savedSearchDetails.status ? (savedSearchDetails.status as string) : '').split(
      '-'
    );
    return hopStatuses.filter((status) => !!statuses.find((v) => v === status.value));
  });
  const [origin, setOrigin] = useState(() => {
    const origins = (savedSearchDetails.origin ? (savedSearchDetails.origin as string) : '').split(
      '-'
    );
    return hopOrigins.filter((status) => !!origins.find((v) => v === status.value));
  });
  const [startDate, setStartDate] = useState<string | undefined>(
    parseDateToISOString(savedSearchDetails.startDate, 1)
  );
  const [endDate, setEndDate] = useState<string | undefined>(
    parseDateToISOString(savedSearchDetails.endDate, 2)
  );
  const [callSourceDetails, setCallSourceDetails] = useState(savedSearchDetails.callSourceDetails);
  const [tracebackNumber, setTracebackNumber] = useState(savedSearchDetails.tracebackNumber);
  const [chosenProvider, setChosenProvider] = useState(savedSearchDetails.chosenProvider);

  const [campaignId, setCampaignId] = useState(
    Number(savedSearchDetails.campaignId) || savedSearchDetails.campaignId
  );
  const tracebackId = useMemo(() => Number(savedSearchDetails.tracebackId), [savedSearchDetails]);
  const hopIds = useMemo(() => savedSearchDetails.ids, [savedSearchDetails]);
  const providerId = useMemo(
    () =>
      user.roleType !== userTypes.Admin ? user.providerId : Number(savedSearchDetails.providerId),
    [user.roleType, user.providerId, savedSearchDetails]
  ); // Override the provided providerId with the users providerId

  const [clickDisabled, setClickDisabled] = useState(false);
  const [collapseFilters, setCollapseFilters] = useState(false);

  const [paginationParams, setPaginationParams] = useState<Pagination>(
    savedSearchDetails.paginationParams || {
      ...defaultPagination(),
      sort: 'completed',
      order: 'desc'
    }
  );

  // this should make the page more responsive
  const hopColumns = useMemo(
    () => hopsColumns(user.roleType === userTypes.Admin, !!tracebackId, !!providerId, statuses),
    [user.roleType, tracebackId, providerId, statuses]
  );

  useEffect(() => {
    if (tracebackId) getTracebackObject(tracebackId);
    if (providerId) getProviderObject(providerId);
    getTracebackStats();
  }, [tracebackId, providerId]);

  useEffect(() => {
    const urlQueryParams = new URLSearchParams({});
    if (startDate) {
      urlQueryParams.set('startDate', startDate.slice(0, 10));
    }
    if (endDate) {
      urlQueryParams.set('endDate', endDate.slice(0, 10));
    }
    if (isItgMember && user.roleType === userTypes.Admin) {
      urlQueryParams.set('isItgMember', isItgMember.toString());
    }
    if (isStrikeExempt && user.roleType === userTypes.Admin) {
      urlQueryParams.set('isStrikeExempt', isStrikeExempt.toString());
    }
    if (isDisputeRejected && user.roleType === userTypes.Admin) {
      urlQueryParams.set('isDisputeRejected', isDisputeRejected.toString());
    }
    if (callSourceDetails) {
      urlQueryParams.set('callSourceDetails', callSourceDetails);
    }
    if (tracebackNumber) {
      urlQueryParams.set('tracebackNumber', tracebackNumber);
    }
    if (chosenProvider) {
      urlQueryParams.set('chosenProvider', chosenProvider);
    }
    if (campaignId) {
      urlQueryParams.set('campaignId', campaignId);
    }
    if (tracebackId) {
      urlQueryParams.set('tracebackId', tracebackId.toString());
    }
    if (providerId && user.roleType === userTypes.Admin) {
      urlQueryParams.set('providerId', providerId.toString());
    }
    if (hopIds && user.roleType === userTypes.Admin) {
      urlQueryParams.set('ids', hopIds);
    }

    const statusToString = status && status.map((v) => v.value).join('-');
    if (statusToString) {
      urlQueryParams.set('status', statusToString);
    }
    const originToString = origin && origin.map((v) => v.value).join('-');
    if (originToString) {
      urlQueryParams.set('origin', originToString);
    }
    setSearchParams(urlQueryParams.toString());
    urlQueryParams.delete('tracebackId');
    urlQueryParams.delete('providerId');
    urlQueryParams.delete('ids');
    saveToLocalStorage(PAGENAME, urlQueryParams.toString());
  }, [
    isItgMember,
    isStrikeExempt,
    isDisputeRejected,
    status,
    origin,
    startDate,
    endDate,
    callSourceDetails,
    tracebackNumber,
    chosenProvider,
    campaignId,
    tracebackId,
    hopIds,
    providerId
  ]);

  useEffect(() => {
    getData();
  }, [
    status,
    origin,
    isStrikeExempt,
    isDisputeRejected,
    isItgMember,
    providerId,
    tracebackId,
    campaignId,
    startDate,
    endDate,
    callSourceDetails,
    tracebackNumber,
    chosenProvider,
    paginationParams
  ]);

  const getData = () => {
    const filterElements = getFilter(
      status,
      origin,
      isStrikeExempt,
      isDisputeRejected,
      isItgMember,
      providerId,
      tracebackId,
      campaignId,
      startDate,
      endDate,
      callSourceDetails,
      tracebackNumber,
      chosenProvider
    );

    getAllHopsList(
      paginationParams,
      hopIds
        ? newLeafFilterElement('hopId', 'IN', hopIds)
        : !tracebackId
          ? filterElements
          : newLeafFilterElement('tracebackId', 'EQ', tracebackId.toString())
    ).then(() => {
      setClickDisabled(false);
    });

    if (user.roleType === userTypes.Admin) {
      let hcFilterElements = newConditionalFilterElement('AND');
      addCondition(hcFilterElements, newLeafFilterElement('status', 'EQ', '1'));

      if (providerId)
        addCondition(
          hcFilterElements,
          newLeafFilterElement('providerId', 'EQ', providerId.toString())
        );

      if (tracebackId)
        addCondition(
          hcFilterElements,
          newLeafFilterElement('tracebackId', 'EQ', tracebackId.toString())
        );

      getHopsCountNumber(simplifyFilterElement(hcFilterElements));
    }
    window.scrollTo(0, 0);
  };

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchParams('');
    switch (e.target.value) {
      case 'isItgMember':
        setIsItgMember((v) => !v);
        break;
      case 'isStrikeExempt':
        setIsStrikeExempt((v) => !v);
        break;
      case 'isDisputeRejected':
        setIsDisputeRejected((v) => !v);
        break;
      default:
        break;
    }
    setClickDisabled(true);
  };

  const handleChange = (e: any, key: string) => {
    setSearchParams('');
    switch (key) {
      case 'startDate':
        if (e) {
          let date = new Date(Date.parse(e));
          setStartDate(
            new Date(
              Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
            ).toISOString()
          );
        } else setStartDate(undefined);
        break;
      case 'endDate':
        if (e) {
          let date = new Date(Date.parse(e));
          setEndDate(
            new Date(
              Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)
            ).toISOString()
          );
        } else setEndDate(undefined);
        break;
      case 'campaignId':
        if (typeof e === 'object')
          if ('label' in e) setCampaignId(e.value);
          else setCampaignId(Array.from(e).length >= 1 ? Number(Array.from(e)[0]) : 0);
        break;
      case 'callSourceDetails':
        if (e) setCallSourceDetails(e.target.value);
        break;
      case 'tracebackNumber':
        if (e) setTracebackNumber(e.target.value);
        break;
      case 'chosenProvider':
        if (e) setChosenProvider(e.value);
        break;
    }
  };

  const updatePagination = (params: Pagination) => {
    if (
      paginationParams.page === params.page &&
      paginationParams.order === params.order &&
      paginationParams.sort === params.sort &&
      paginationParams.pageSize === params.pageSize
    )
      return;
    setPaginationParams((v) => ({ ...v, ...params }));
  };

  const selectStatusOptions = (value: any) => {
    setSearchParams('');
    setStatus(value);
  };
  const selectOriginOptions = (value: OnChangeValue<SelectOption, boolean>) => {
    setOrigin(value as SelectOption[]);
  };
  const toggleFilters = () => {
    setCollapseFilters((v) => !v);
  };

  const clearFilters = () => {
    setPaginationParams({
      ...defaultPagination(),
      sort: 'completed',
      order: 'desc'
    });
    setStatus([]);
    setOrigin([]);
    setIsItgMember(false);
    setIsStrikeExempt(false);
    setIsDisputeRejected(false);
    setClickDisabled(false);
    setStartDate(undefined);
    setEndDate(undefined);
    setCampaignId(0);
    setTracebackNumber('');
    setCallSourceDetails('');
    setChosenProvider(0);
    setSearchParams('');
  };

  const getHopsCSVBtn = () => {
    const filterElements = getFilter(
      status,
      origin,
      isStrikeExempt,
      isDisputeRejected,
      isItgMember,
      providerId,
      tracebackId,
      campaignId,
      startDate,
      endDate,
      callSourceDetails,
      tracebackNumber,
      chosenProvider
    );
    getHopsCSV(filterElements, paginationParams);
  };

  return isAuthenticated && (user.roleType === userTypes.Admin || providerId) ? (
    <Fragment>
      <Breadcrumb
        title={
          !!tracebackId
            ? `All Hops for Traceback ${tracebackId}`
            : providerId
              ? `All Hops - ${providerId ? (provider ? provider.name : '-') : ''} `
              : 'hops'
        }
        className="table-breadcrumbs"
        openHop={user.roleType === userTypes.Admin ? numOpenHops : null}
        reputation={providerId ? provider.reputation : undefined}
      />
      <Card className="table-card hide-border">
        {!!tracebackId && (
          <CardHeader className="card-header-hops">
            <div className="d-flex flex-row justify-content-around">
              <div className="mb-0">
                <label className="telecom-label ps-0">Called Number</label>
                <i className="fa fa-phone text-red" />
                <span>{traceback.calledTN}</span>
              </div>
              <div className="mb-0">
                <label className="telecom-label ps-0">Calling Number</label>
                <i className="fa fa-phone text-green" />
                <span>{traceback.callingTN}</span>
              </div>
              <div className="mb-0">
                <label className="telecom-label ps-0">Date & Time of Call</label>
                <span>{getClientFormattedDate(traceback.tracebackTime, DateFormat.ShortBoth)}</span>
              </div>
              <div className="mb-0">
                <label className="telecom-label ps-0">Campaign Name</label>
                <span className="span-cell blue">
                  <a href={`/campaigns/campaign/${traceback.campaignId}`}>
                    {traceback.campaignName}
                  </a>
                </span>
              </div>
              <div className="mb-0">
                <label className="telecom-label ps-0">Traceback</label>
                <span className="span-cell blue">
                  <a href={`/tracebacks/traceback/${tracebackId}`}>{tracebackId}</a>
                </span>
              </div>
              <div className="mb-0">
                <label className="telecom-label ps-0">Total Elapsed</label>
                <span className="table-cell-gray">
                  {traceback.elapsed ? getElapsedTime(traceback.elapsed) : 'None'}
                </span>
              </div>
            </div>
          </CardHeader>
        )}
        {!tracebackId && (
          <CardHeader className="card-header-hops">
            <CardHeader
              className="card-header-filters"
              onClick={() => toggleFilters()}
              data-type="collapseBanner"
            >
              Filters
              <i
                className={`filters fa-solid ${collapseFilters ? 'fa-arrow-up' : 'fa-arrow-down'}`}
              ></i>
            </CardHeader>
            <Collapse isOpen={collapseFilters}>
              <CardBody>
                <div className="row">
                  <Col className="fixed-column">
                    <label className="telecom-label">Start Date</label>
                  </Col>
                  <Col className="fixed-column">
                    <label className="telecom-label">End Date</label>
                  </Col>
                  <Col className="fixed-column">
                    <label className="telecom-label">Status</label>
                  </Col>
                  <Col className="fixed-column">
                    <label className="telecom-label">Campaigns</label>
                  </Col>
                  <Col className="fixed-column">
                    <label className="telecom-label">Origin</label>
                  </Col>
                  <div className="col">
                    <label className="telecom-label">Traceback by Call Source Details</label>
                  </div>
                </div>
                <Row>
                  <Col className="fixed-column">
                    <DatePicker
                      maxDate={
                        endDate ? convertToTimeZone(endDate, { timeZone: 'GMT' }) : new Date()
                      }
                      selected={
                        startDate ? convertToTimeZone(startDate, { timeZone: 'GMT' }) : undefined
                      }
                      onChange={(option) => handleChange(option, 'startDate')}
                      placeholderText="&#xf133; mm/dd/yyyy"
                      clearButtonTitle={'Clear'}
                      isClearable
                    />
                  </Col>
                  <Col className="fixed-column">
                    <DatePicker
                      maxDate={new Date()}
                      selected={
                        endDate ? convertToTimeZone(endDate, { timeZone: 'GMT' }) : undefined
                      }
                      minDate={
                        startDate ? convertToTimeZone(startDate, { timeZone: 'GMT' }) : undefined
                      }
                      onChange={(option) => handleChange(option, 'endDate')}
                      placeholderText="&#xf133; mm/dd/yyyy"
                      clearButtonTitle={'Clear'}
                      isClearable
                    />
                  </Col>
                  <Col className="fixed-column">
                    <Select
                      className="customselect-small"
                      classNamePrefix="customselect"
                      options={hopStatuses}
                      isMulti
                      isSearchable={false}
                      placeholder="All statuses"
                      onChange={selectStatusOptions}
                      value={status}
                    />
                  </Col>
                  <Col className="fixed-column">
                    <CampaignsSelect
                      value={campaignId}
                      isSearchable
                      addAllItem
                      onChange={(option) => handleChange(option, 'campaignId')}
                    />
                  </Col>
                  <Col className="fixed-column">
                    <Select
                      className="customselect-small"
                      classNamePrefix="customselect"
                      options={hopOrigins}
                      isMulti
                      isSearchable={false}
                      placeholder="All origins"
                      onChange={selectOriginOptions}
                      value={origin}
                    />
                  </Col>
                  <Col>
                    <DebounceInput
                      type={'text'}
                      className={'me-auto m-1 pl-2 campaign-search'}
                      placeholder={'Search Campaign by Partial Text'}
                      minLength={1}
                      debounceTimeout={1000}
                      onChange={(option) => handleChange(option, 'callSourceDetails')}
                      value={callSourceDetails}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col className="fixed-column p-0">
                    <label className="telecom-label">Search for Traceback</label>
                  </Col>
                  {(user.roleType === userTypes.Provider ||
                    user.roleType === userTypes.ProviderManager) && (
                    <Col className="p-0">
                      <label className="telecom-label">Search for Upstream&Downstream</label>
                    </Col>
                  )}
                </Row>

                <div className="d-flex flex-row justify-content-between mt-2">
                  <div className="div-left me-1">
                    <div className="row p-0">
                      <Col>
                        <Input
                          value={tracebackNumber}
                          onChange={(option) => handleChange(option, 'tracebackNumber')}
                          className="csd-search width-small"
                          placeholder="Go to Traceback #"
                        />
                      </Col>
                      {(user.roleType === userTypes.Provider ||
                        user.roleType === userTypes.ProviderManager) && (
                        <Col className="me-1">
                          <ProvidersSelect
                            onChange={(option) => handleChange(option, 'chosenProvider')}
                            isSearchable
                            className={'customselect-small'}
                            option={chosenProvider}
                            placeholder="All Providers"
                            removeId={user.providerId}
                            includeInactive
                          />
                        </Col>
                      )}
                    </div>

                    {user.roleType === userTypes.Admin && (
                      <Fragment>
                        <FormGroup className="me-3 ms-2">
                          <label className="form-label checkbox-label">
                            ITG
                            <input
                              type="checkbox"
                              onChange={handleCheck}
                              value="isItgMember"
                              checked={isItgMember}
                              disabled={clickDisabled}
                            />
                            <span className="checkmark" />
                          </label>
                        </FormGroup>
                        <FormGroup>
                          <label className="form-label checkbox-label">
                            Strike Exempt
                            <input
                              type="checkbox"
                              onChange={handleCheck}
                              value="isStrikeExempt"
                              checked={isStrikeExempt}
                              disabled={clickDisabled}
                            />
                            <span className="checkmark" />
                          </label>
                        </FormGroup>
                        <FormGroup>
                          <label className="form-label checkbox-label">
                            Dispute Rejected
                            <input
                              type="checkbox"
                              onChange={handleCheck}
                              value="isDisputeRejected"
                              checked={isDisputeRejected}
                              disabled={clickDisabled}
                            />
                            <span className="checkmark" />
                          </label>
                        </FormGroup>
                      </Fragment>
                    )}
                  </div>
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={() => {
                      clearFilters();
                    }}
                  >
                    Clear
                  </button>
                </div>

                {user.roleType !== userTypes.Admin && (
                  <div className="d-flex flex-row justify-content-end mt-2">
                    <Button
                      onClick={() => {
                        getHopsCSVBtn();
                      }}
                      className="downloadCsvStyle float-end"
                    >
                      <i className="fa fa-download" />
                      {` Download CSV`}
                    </Button>
                  </div>
                )}
              </CardBody>
            </Collapse>
          </CardHeader>
        )}
        <CardBody className={'card-body-hop'}>
          <CustomDataTable
            tableData={hops}
            columns={hopColumns}
            defaultSortFieldId={paginationParams.sort}
            defaultSortAsc={paginationParams.order === 'asc'}
            defaultPage={paginationParams.page}
            defaultPageSize={paginationParams.pageSize}
            paginationTotalRows={paginationTotalRows}
            pagination
            updatePaginationParams={updatePagination}
          />
        </CardBody>
      </Card>
      <div className="d-flex justify-content-center pt-4 pb-4">
        <Legend />
      </div>
      <ZerobounceAlert />
      <ZendeskWebWidget />
      <HopInvalidAlert hopId={hopInvalidId} />
    </Fragment>
  ) : null;
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return {
    user: sm.user,
    hops: sm.hop.hops,
    traceback: sm.traceback.traceback,
    provider: sm.provider.provider,
    tbStats: sm.traceback.tbStats,
    numOpenHops: sm.hop.hopCount,
    statuses: sm.hop.statuses,
    paginationTotalRows: sm.hop.meta.TotalCount,
    isAuthenticated: sm.isAuthenticated,
    hopInvalidId: sm.hop.hopInvalidId
  };
};

//TODO: Replace AllProvidersListAPI call with /api/AppInfo/System/ProviderNames
const mapActionsToProps = {
  getAllHopsList,
  getHopsCountNumber,
  getTracebackObject,
  getTracebackStats,
  getProviderObject,
  getHopsCSV
};

export default connect(mapStateToProps, mapActionsToProps)(Hops);
