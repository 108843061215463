import React, { Fragment } from 'react';
import { TableColumn } from 'react-data-table-component';
import { Row } from 'reactstrap';
import {
  IncidentCampaignSource,
  IncidentCampaignTableRow,
  IncidentPhoneNumbers,
  IncidentTableRow
} from '../../../interfaces/incident';
import {
  FilterElement,
  addCondition,
  newConditionalFilterElement,
  newLeafFilterElement,
  simplifyFilterElement
} from '../../../lib/FilterElement';
import { processTracebackError } from '../../../lib/utilities';

export const visibleIncidentSources: IncidentCampaignSource[] = [
  { name: 'All', sourceId: 0, count: 0, providerId: -1 },
  { name: 'YouMail', sourceId: 10057, count: 0, providerId: -1 },
  { name: 'Verizon', sourceId: 10077, count: 0, providerId: 23 },
  { name: 'ZipDX', sourceId: 10136, count: 0, providerId: 1535 },
  { name: 'Robokiller', sourceId: 10163, count: 0, providerId: 1734 }
];

const determineTextColor = (a: number, b: number): string =>
  a === b ? '' : a < b ? 'text-red' : 'text-green';

const getMonthAndDay = (date: Date) =>
  `${date.toLocaleString('default', { month: 'short' })} ${date
    .getDate()
    .toString()
    .padStart(2, '0')}`;

const getDateColumnHeader = (week: number, daysBehind: number) => {
  const today = new Date();
  const todayOffset = today;
  todayOffset.setDate(today.getDate() - Math.abs(daysBehind - week * 7));

  return getMonthAndDay(todayOffset);
};

/**
 * Formats the data as ShortMonth DayOfMonth, Year - i.e. Jan 8, 2021
 * @param date The date string to format
 */

const getFormattedCellDate = (date: string): string => getFormattedDate(new Date(date));

const getFormattedDate = (date: Date): string => {
  let year = date.getFullYear();
  const month = date.toLocaleString('default', { month: 'short' });
  let day = date.getDate().toString().padStart(2, '0');

  return `${month} ${day}, ${year}`;
};

export const addDays = (date: Date, days: number): Date => {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const getWeekFilterText = (currentDate: Date): string =>
  `${getFormattedDate(currentDate)} to ${getFormattedDate(addDays(currentDate, -7))}`;

export const getIncidentSummariesColumns = (
  week: number,
  baseHash: string,
  selectIncidentCampaign: (row: IncidentCampaignTableRow) => void
): TableColumn<IncidentCampaignTableRow>[] => [
  {
    name: '',
    width: '20px',
    grow: 1,
    cell: () => <div />
  },
  {
    name: 'Incident Campaigns',
    sortable: true,
    selector: () => 'campaignLabel',
    id: 'column-campaignLabel',
    grow: 5,
    cell: (row: IncidentCampaignTableRow) => (
      <Row className="flex-column">
        <div className="table-cell left blue">
          <a
            title={row.campaignLabel}
            href={`/admin-dashboard${baseHash}-${row.incidentCampaignId}`}
            onClick={() => {
              selectIncidentCampaign(row);
            }}
          >
            {row.campaignLabel}
          </a>
        </div>
        {row.callType && row.callType.length > 0 && <small>{row.callType}</small>}
      </Row>
    )
  },
  {
    name: getDateColumnHeader(week, 0),
    sortable: true,
    selector: () => 'today',
    grow: 1,
    cell: (incidentRow: IncidentCampaignTableRow) => (
      <div className="table-cell center">
        <span className={determineTextColor(incidentRow.today, incidentRow.todayMinusOne)}>
          {incidentRow.today || 0}
        </span>
      </div>
    )
  },
  {
    name: getDateColumnHeader(week, 1),
    sortable: true,
    selector: () => 'todayMinusOne',
    grow: 1,
    cell: (incidentRow: IncidentCampaignTableRow) => (
      <div className="table-cell center">
        <span className={determineTextColor(incidentRow.todayMinusOne, incidentRow.todayMinusTwo)}>
          {incidentRow.todayMinusOne || 0}
        </span>
      </div>
    )
  },
  {
    name: getDateColumnHeader(week, 2),
    sortable: true,
    selector: () => 'todayMinusTwo',
    grow: 1,
    cell: (incidentRow: IncidentCampaignTableRow) => (
      <div className="table-cell center">
        <span
          className={determineTextColor(incidentRow.todayMinusTwo, incidentRow.todayMinusThree)}
        >
          {incidentRow.todayMinusTwo || 0}
        </span>
      </div>
    )
  },
  {
    name: getDateColumnHeader(week, 3),
    sortable: true,
    selector: () => 'todayMinusThree',
    grow: 1,
    cell: (incidentRow: IncidentCampaignTableRow) => (
      <div className="table-cell center">
        <span
          className={determineTextColor(incidentRow.todayMinusThree, incidentRow.todayMinusFour)}
        >
          {incidentRow.todayMinusThree || 0}
        </span>
      </div>
    )
  },
  {
    name: getDateColumnHeader(week, 4),
    sortable: true,
    selector: () => 'todayMinusFour',
    grow: 1,
    cell: (incidentRow: IncidentCampaignTableRow) => (
      <div className="table-cell center">
        <span
          className={determineTextColor(incidentRow.todayMinusFour, incidentRow.todayMinusFive)}
        >
          {incidentRow.todayMinusFour || 0}
        </span>
      </div>
    )
  },
  {
    name: getDateColumnHeader(week, 5),
    sortable: true,
    selector: () => 'todayMinusFive',
    grow: 1,
    cell: (incidentRow: IncidentCampaignTableRow) => (
      <div className="table-cell center">
        <span className={determineTextColor(incidentRow.todayMinusFive, incidentRow.todayMinusSix)}>
          {incidentRow.todayMinusFive || 0}
        </span>
      </div>
    )
  },
  {
    name: getDateColumnHeader(week, 6),
    sortable: true,
    selector: () => 'todayMinusSix',
    grow: 1,
    cell: (incidentRow: IncidentCampaignTableRow) => (
      <div className="table-cell center">
        <span
          className={determineTextColor(incidentRow.todayMinusSix, incidentRow.todayMinusSeven)}
        >
          {incidentRow.todayMinusSix || 0}
        </span>
      </div>
    )
  },
  {
    name: getDateColumnHeader(week, 7),
    sortable: true,
    selector: () => 'todayMinusSeven',
    grow: 1,
    style: { borderRight: '2px solid grey' },
    cell: (incidentRow: IncidentCampaignTableRow) => (
      <div className="table-cell center">
        <span
          className={determineTextColor(incidentRow.todayMinusSeven, incidentRow.todayMinusEight)}
        >
          {incidentRow.todayMinusSeven || 0}
        </span>
      </div>
    )
  },
  {
    name: 'Total',
    sortable: true,
    selector: () => 'total',
    grow: 1,
    cell: (incidentRow: IncidentCampaignTableRow) => (
      <div className="table-cell center">{incidentRow.total || 0}</div>
    )
  },
  {
    name: 'First',
    sortable: true,
    selector: () => 'firstTracebackDate',
    grow: 1.5,
    cell: (incidentRow: IncidentCampaignTableRow) => (
      <div className="table-cell center">
        {incidentRow.firstTracebackDate
          ? getFormattedCellDate(incidentRow.firstTracebackDate)
          : 'N/A'}
      </div>
    )
  },
  {
    name: 'Latest',
    sortable: true,
    grow: 1.5,
    selector: () => 'mostRecentTracebackDate',
    cell: (incidentRow: IncidentCampaignTableRow) => (
      <div className="table-cell center">
        {incidentRow.mostRecentTracebackDate
          ? getFormattedCellDate(incidentRow.mostRecentTracebackDate)
          : 'N/A'}
      </div>
    )
  }
];

export const getIncidentCampaignSearchColumns = (
  campaignId: number,
  selected: IncidentPhoneNumbers[],
  toggleRow: (
    id: number,
    incidentCampaignId: number,
    originatorPhoneNumber: string,
    destinationPhoneNumber: string,
    callTime: string
  ) => void,
  forAllSources: boolean
): TableColumn<IncidentTableRow>[] => {
  return [
    {
      name: '',
      width: '20px',
      cell: () => <div />
    },
    {
      name: '',
      width: '20px',
      cell: (row: IncidentTableRow) =>
        campaignId && campaignId > 0 && !row.tracedBack && !forAllSources ? (
          <input
            type="checkbox"
            className="input-comment form-check cell-check"
            checked={!!selected.find((value) => value.id === row.id)}
            onChange={() =>
              toggleRow(
                row.id,
                row.incidentCampaignId,
                row.originatorPhoneNumber,
                row.destinationPhoneNumber,
                row.callTime
              )
            }
          />
        ) : null
    },
    {
      name: 'Email No.',
      sortable: true,
      selector: () => 'id',
      grow: 1,
      cell: (row: IncidentTableRow) => <div className="table-cell center">{row.id}</div>
    },
    {
      name: 'Date Created',
      selector: () => 'create_date',
      sortable: true,
      grow: 2,
      cell: (row: IncidentTableRow) => (
        <div className="table-cell center">{getFormattedCellDate(row.createDate)}</div>
      )
    },
    {
      name: 'Traceback Created',
      sortable: true,
      selector: () => 'tracedBack',
      grow: 4,
      cell: (row: IncidentTableRow) => (
        <div className="table-cell center">
          {row.tracebackError && row.tracebackError.length > 0 ? (
            <span className="text-red">{processTracebackError(row.tracebackError)}</span>
          ) : row.tracedBack ? (
            <div className="d-flex flex-row justify-content-between">
              Yes
              {row.tracebackId && (
                <Fragment>
                  Traceback{' '}
                  <span className="font-weight-bold">
                    <a href={`tracebacks/traceback/${row.tracebackId}`}>#{row.tracebackId}</a>
                  </span>
                </Fragment>
              )}
              {row.tracebackCampaignName && (
                <Fragment>
                  Campaign:<span className="font-weight-bold">{row.tracebackCampaignName}</span>
                </Fragment>
              )}
            </div>
          ) : (
            <span>No</span>
          )}
        </div>
      )
    }
  ];
};

export const getIncidentCampaignSearchFilter = (
  incidentCampaignId: number,
  selectedCallerId: string,
  selectedCallerCarrier: string,
  selectedCalledCarrier: string,
  callerTnValue: string,
  calledTnValue: string,
  voiceMailValue: boolean,
  tracedBackValue: boolean,
  transcriptFilter: string,
  callDateValue: Date | null,
  callTimeValue: string,
  dnc: boolean,
  dno: boolean
): FilterElement => {
  let filterElements = newConditionalFilterElement('AND');

  if (incidentCampaignId) {
    addCondition(filterElements, newLeafFilterElement('id', 'EQ', String(incidentCampaignId)));
  }

  if (selectedCallerId && selectedCallerId.length > 0) {
    addCondition(filterElements, newLeafFilterElement('originatorCnam', 'EQ', selectedCallerId));
  }

  if (selectedCallerCarrier && selectedCallerCarrier.length > 0) {
    addCondition(
      filterElements,
      newLeafFilterElement('originatorCarrier', 'EQ', selectedCallerCarrier)
    );
  }

  if (selectedCalledCarrier && selectedCalledCarrier.length > 0) {
    addCondition(
      filterElements,
      newLeafFilterElement('destinationCarrier', 'EQ', selectedCalledCarrier)
    );
  }

  if (callDateValue) {
    // Convert to String and remove quotes
    let dateAsString: string = JSON.stringify(callDateValue).replace('"', '');

    // Last occurrence of : should be before the seconds
    let index: number = dateAsString.lastIndexOf('T');
    if (index != -1) {
      // Remove everything but date
      dateAsString = dateAsString.substr(0, index);
      // Remove T
      dateAsString = dateAsString.replace('T', ' ');

      // Should now be formatted like 2020-12-08
    }

    if (callTimeValue) {
      dateAsString = dateAsString + ' ' + callTimeValue;
      // Should now be formatted like 2020-12-08 16:42
    }

    // Will be converted to LIKE on Backend
    addCondition(filterElements, newLeafFilterElement('callTime', 'EQ', dateAsString));
  }

  if (callerTnValue && callerTnValue.length > 0) {
    addCondition(
      filterElements,
      newLeafFilterElement('originatorPhoneNumber', 'EQ', callerTnValue)
    );
  }

  if (calledTnValue && calledTnValue.length > 0) {
    addCondition(
      filterElements,
      newLeafFilterElement('destinationPhoneNumber', 'EQ', calledTnValue)
    );
  }

  if (voiceMailValue) {
    addCondition(filterElements, newLeafFilterElement('callResult', 'EQ', 'Voicemail'));
  }

  if (tracedBackValue) {
    addCondition(filterElements, newLeafFilterElement('tracedBack', 'EQ', '1'));
  }

  if (transcriptFilter && transcriptFilter.length > 0) {
    addCondition(filterElements, newLeafFilterElement('transcriptBody', 'EQ', transcriptFilter));
  }

  if (dnc) {
    addCondition(filterElements, newLeafFilterElement('dnc', 'EQ', '1'));
  }
  if (dno) {
    addCondition(filterElements, newLeafFilterElement('dno', 'EQ', '1'));
  }
  return simplifyFilterElement(filterElements);
};

export const getIncidentCampaignAllSearchFilter = (
  incidentCampaignId: number,
  transcriptFilter: string
): FilterElement => {
  let filterElements = newConditionalFilterElement('AND');

  if (incidentCampaignId) {
    addCondition(filterElements, newLeafFilterElement('id', 'EQ', String(incidentCampaignId)));
  }

  if (transcriptFilter && transcriptFilter.length > 0) {
    addCondition(filterElements, newLeafFilterElement('transcriptBody', 'EQ', transcriptFilter));
  }

  return simplifyFilterElement(filterElements);
};

export const getAllIncidentSummariesColumns: TableColumn<IncidentCampaignTableRow>[] = [
  {
    name: 'Source',
    sortable: true,
    selector: () => 'sourceName',
    grow: 1,
    cell: (row: IncidentCampaignTableRow) => <div className="table-cell left">{row.sourceName}</div>
  },
  {
    name: 'Incident Campaign',
    sortable: true,
    selector: () => 'campaignLabel',
    grow: 4,
    cell: (row: IncidentCampaignTableRow) => (
      <div className="table-cell left">
        <a href={'/admin-dashboard#incidents-' + row?.sourceName + '-' + row?.incidentCampaignId}>
          {row.campaignLabel}
        </a>
      </div>
    )
  },
  {
    name: 'Label',
    sortable: true,
    selector: () => 'incidentLabel',
    grow: 2.5,
    cell: (row: IncidentCampaignTableRow) => (
      <div className="table-cell center">{row.incidentLabel}</div>
    )
  },
  {
    name: 'Campaign',
    sortable: true,
    selector: () => 'tracebackCampaignName',
    grow: 2.5,
    cell: (row: IncidentCampaignTableRow) => (
      <div className="table-cell center">
        <a href={'campaigns/campaign/' + row?.campaignId}>{row.tracebackCampaignName}</a>
      </div>
    )
  },
  {
    name: 'Incidents this week',
    sortable: true,
    selector: () => 'totalIncidentsForWeek',
    grow: 1.5,
    cell: (row: IncidentCampaignTableRow) => (
      <div
        className={`table-cell center ${
          row.newCampaignIncidentWeek >= 0 ? 'text-green' : 'text-red'
        }`}
      >
        {weekTotalIncidents(row)}
      </div>
    )
  },
  {
    name: 'Tracebacks this week',
    sortable: true,
    selector: () => 'totalTracedBack',
    grow: 1.5,
    cell: (row: IncidentCampaignTableRow) => (
      <div className="table-cell center">{row.totalTracedBack}</div>
    )
  },
  {
    name: 'DNC',
    sortable: false,
    selector: () => 'dncNumber',
    grow: 0.75,
    cell: (row: IncidentCampaignTableRow) => (
      <div className="table-cell center">
        {row.dncNumber ? Math.round((row.dncNumber * 100) / weekTotalIncidents(row)) : 0}%
      </div>
    )
  },
  {
    name: 'DNO',
    sortable: true,
    selector: () => 'dnoNumber',
    grow: 0.75,
    cell: (row: IncidentCampaignTableRow) => (
      <div className="table-cell center">{row.dnoNumber}</div>
    )
  },
  {
    name: 'Number Rotation',
    sortable: false,
    grow: 1.5,
    selector: () => 'numberOriginatorPhone',
    cell: (row: IncidentCampaignTableRow) => (
      <div className="table-cell center">
        {row.numberOriginatorPhone
          ? Math.round((row.numberOriginatorPhone * 100) / weekTotalIncidents(row))
          : 0}
        %
      </div>
    )
  }
];

const weekTotalIncidents = (incident: IncidentCampaignTableRow) =>
  incident.today +
  incident.todayMinusOne +
  incident.todayMinusTwo +
  incident.todayMinusThree +
  incident.todayMinusFour +
  incident.todayMinusFive +
  incident.todayMinusSix +
  incident.todayMinusSeven;
