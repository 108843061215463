import { format } from 'date-fns';
import React, { Fragment } from 'react';
import { userTypes } from '../../enum/userTypes';
import { Hop } from '../../interfaces/hop';
import { Traceback } from '../../interfaces/traceback';
import { PersonalInfo } from '../../interfaces/user';
import { DnoToolTip } from '../../lib/dno';
import CustomToolTip from '../CustomToolTip';

interface IProps {
  hop: Hop;
  traceback: Traceback;
  user: PersonalInfo;
}

const TracebackDetailsRow: React.FC<IProps> = ({ hop, traceback, user }) => (
  <Fragment>
    <h5 className="p-0 traceback-sub-title mb-3">Traceback Details</h5>
    <div className="row mb-3 px-2">
      <div className="col-12 col-md-3 mb-2">
        <label className="telecom-label">Campaign Name</label>
        <span className="table-cell-gray">{traceback.campaignName}</span>
      </div>
      {user.roleType === userTypes.Admin && (
        <div className="col-12 col-md-3 mb-2">
          <label className="telecom-label">Traceback Source</label>
          <span className="table-cell-gray">{traceback.source}</span>
        </div>
      )}
      {user.roleType === userTypes.Admin && (
        <div className="col-12 col-md-3 mb-2">
          <label className="telecom-label">Traceback Created</label>
          <span className="table-cell-gray">
            {traceback.create_date &&
              format(new Date(traceback.create_date.slice(0, -1)), 'MMM dd, yyyy HH:mm')}{' '}
            UTC
          </span>
        </div>
      )}
      <div className="col-12 col-md-4">
        <label className="telecom-label">Audio URL</label>
        {traceback.audioUrl ? (
          <Fragment>
            <audio controls className="d-block mb-2 w-100">
              <source src={traceback.audioUrl} />
            </audio>
            <a href={traceback.audioUrl} className="span-cell blue d-block">
              {traceback.audioUrl}
            </a>
            {traceback.representativeAudio === false && (
              <p>Voicemail May Be a Representative Sample of Campaign</p>
            )}
          </Fragment>
        ) : (
          <span className="span-cell gray">{'None'}</span>
        )}
      </div>
    </div>
    <div className="row mb-3 px-2">
      {user.roleType === userTypes.Admin && (
        <div className="col-12 col-md-3 mb-2">
          <label className="telecom-label">Traceback Created By</label>
          <span className="table-cell-gray">{traceback.createdBy}</span>
        </div>
      )}
      <div className="col-12 col-md-3 mb-2">
        <label className="telecom-label">Do Not Call Registry</label>
        <span className="table-cell-gray">{traceback.dnc || 'None'}</span>
      </div>
      <div className="col-12 col-md-3">
        <label className="telecom-label">Do Not Origin Registry</label>
        <CustomToolTip
          message={
            DnoToolTip.find((v) => v.type === traceback.dnoType)?.description ||
            traceback.dnoType ||
            ''
          }
          tooltipVisible={!!traceback.dnoType}
        >
          <span>{traceback.dno ? 'Industry DNO' : 'Not Listed'}</span>
        </CustomToolTip>
      </div>
    </div>
    {user.roleType === userTypes.Admin && (
      <div className="row mb-3 px-2">
        <div className="col-12 col-md-3 mb-2">
          <label className="telecom-label">Attachments</label>
          <span className="table-cell-gray">None</span>
        </div>
        <div className="col-12 col-md-3 mb-2">
          <label className="telecom-label">Creator's Provider</label>
          <span className="table-cell-gray">{traceback.creatorProvider || 'None'}</span>
        </div>
        <div className="col-12 col-md-3 mb-2">
          <label className="telecom-label">Strike Exempt</label>
          <span className="table-cell-gray">{traceback.strikeExempt ? 'Yes' : 'No'}</span>
        </div>
        <div className="col-12 col-md-3">
          <label className="telecom-label">System Hop No.</label>
          <span className="table-cell-gray">{hop.hopId}</span>
        </div>
      </div>
    )}
    {traceback.campaignAttachmentUrl && (
      <div className="row mb-3 px-2">
        <div className="col-12 col-md-4">
          <label className="telecom-label">Campaign Attachment</label>
          <span className="table-cell-gray">
            {traceback.campaignAttachmentDocumentation || 'None'}
          </span>
          <a href={traceback.campaignAttachmentUrl} className="span-cell blue d-block">
            {traceback.campaignAttachmentUrl}
          </a>
        </div>
      </div>
    )}
    {hop.isCampaignLive && (
      <div className="row px-2">
        <div className="col-12">Evidence shows that this call is live or can not be determined</div>
      </div>
    )}
  </Fragment>
);

export default TracebackDetailsRow;
