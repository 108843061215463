//This file holds the API calls that hit the /user route for DRY purposes
import { CancelTokenSource } from 'axios';

import { Pagination } from '../../interfaces/pagination';
import { SpreadsheetTraceback, Traceback, TracebackPostData } from '../../interfaces/traceback';
import { FilterElement } from '../../lib/FilterElement';
import { ApiRequest, buildParams, withCancelRequestFunctionality } from '../../lib/apiRequest';

const getTracebacksWithCancelApiCalls = withCancelRequestFunctionality();

export const getTracebacksApiCall = async (params: Pagination, filterElements: FilterElement) =>
  getTracebacksWithCancelApiCalls((cancelToken: CancelTokenSource) =>
    ApiRequest.request({
      method: 'POST',
      url:
        `/tracebacks/search?` +
        buildParams({
          ...params
        }),
      data: filterElements,
      cancelToken: cancelToken.token
    })
  );

export const getTracebackApiCall = async (tracebackId: number) =>
  ApiRequest.request({
    method: 'GET',
    url: `/tracebacks/${tracebackId}`
  });

export const getTracebackStatsApiCall = async () =>
  ApiRequest.request({
    method: 'GET',
    url: `/tracebacks/stats`
  });

export const getTracebackStatusApiCall = async () =>
  ApiRequest.request({
    method: 'GET',
    url: '/AppInfo/Enums/TracebackStatusCodes'
  });

export const addTracebackApiCall = async (content: TracebackPostData) =>
  ApiRequest.request({
    method: 'POST',
    url: `/tracebacks`,
    data: {
      ...content
    }
  });

export const editTracebackApiCall = async (content: Traceback) =>
  ApiRequest.request({
    method: 'PUT',
    url: `/tracebacks/${content.tracebackId}`,
    data: {
      ...content
    }
  });

export const addAttachmentsApiCall = async (tracebackId: number, content: FormData) =>
  ApiRequest.request({
    method: 'POST',
    url: `/attachments?tracebackId=${tracebackId}`,
    data: content,
    headers: {
      'Content-type': 'multipart/form-data'
    }
  });

export const deleteTracebackApiCall = async (tracebackId: number) =>
  ApiRequest.request({
    method: 'DELETE',
    url: `/tracebacks/${tracebackId}`
  });

export const addAudioApiCall = async (tracebackId: number, content: FormData) =>
  ApiRequest.request({
    method: 'POST',
    url: `/UploadAudioFile?tracebackId=${tracebackId}`,
    data: content,
    headers: {
      'Content-type': 'multipart/form-data'
    }
  });

export const getAudioApiCall = async (tracebackId: number) =>
  ApiRequest.request({
    method: 'GET',
    url: `/tracebacks/${tracebackId}/UploadAudioFile`
  });

export const getCurrentTraceback = async (calledTN: any, callingTN: any, tracebackTime: any) => {
  return ApiRequest.request({
    method: 'POST',
    url: `/existing-traceback`,
    data: {
      callingTN: callingTN,
      calledTN: calledTN,
      tracebackTime: tracebackTime
    }
  });
};

export const getTracebacksByObjectTypeApiCall = async (
  objectId: number,
  objectType: string,
  filterElements: FilterElement
) =>
  ApiRequest.request({
    method: 'POST',
    url: `/tracebacks/${objectId}/${objectType}`,
    data: filterElements
  });

export const getCampaignNamesApiCall = async () =>
  ApiRequest.request({
    method: 'GET',
    url: `/AppInfo/System/CampaignNames`
  });

export const addRequestorApiCall = async (requestorName: string, sourceId: number) =>
  await ApiRequest.request({
    method: 'POST',
    url: `/tracebacks/requestors`,
    data: {
      name: requestorName,
      sourceId
    }
  });

export const getTracebackRequestorsApiCall = async () =>
  await ApiRequest.request({
    method: 'GET',
    url: `/tracebacks/requestors`
  });

export const getCSVApiCall = async (filterElements: FilterElement, params: Pagination) =>
  ApiRequest.request({
    method: 'POST',
    url:
      `/DownloadTracebacksCsv?` +
      buildParams({
        ...params
      }),
    data: filterElements,
    responseType: 'blob',
    headers: { accept: 'text/csv' }
  });

export const uploadSpreadSheet = async (content: FormData) =>
  ApiRequest.request({
    method: 'POST',
    url: '/traceback-spreadsheet/',
    data: content,
    headers: {
      'Content-type': 'multipart/form-data'
    }
  });

export const getTracebackFromSpreadSheetApiCall = async (params: Pagination) =>
  ApiRequest.request({
    method: 'GET',
    url:
      '/traceback-spreadsheet/get?' +
      buildParams({
        ...params
      })
  });

export const createTracebacksFromSpreadsheetApiCall = async (payload: SpreadsheetTraceback[]) =>
  ApiRequest.request({
    method: 'POST',
    url: '/traceback-spreadsheet/createTracebacks',
    data: payload
  });
