import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import Breadcrumb from '../components/Breadcrumbs';
import CampaignEditor from '../components/CampaignEditor';
import { DateFormat } from '../enum/DateFormat';
import { Campaign } from '../interfaces/campaign';
import { getClientFormattedDate } from '../lib/utilities';
import { deleteCampaign, editCampaignObject, getCampaignObject } from '../redux/campaign/thunks';
import { stateMappings } from '../redux/stateMappings';

interface IProps {
  campaign: Campaign;
  error: string;

  getCampaignObject: Function;
  editCampaignObject: Function;
  deleteCampaign: Function;

  attachments: any[];
}

const CampaignDetail: React.FC<IProps> = ({
  campaign,
  error,
  getCampaignObject,
  editCampaignObject,
  deleteCampaign,
  attachments
}) => {
  const navigate = useNavigate();
  const campaignId = Number(useParams().id) || 0;
  const systemComments = campaign.comments && campaign.comments.filter((v) => v.isSystemComment);
  const [isViewMode, setIsViewMode] = useState(true);
  const [modifyTooltip, setModifyTooltip] = useState(!localStorage.getItem('ModifyHopTip'));

  useEffect(() => {
    getCampaignObject(campaignId);
  }, [campaignId]);

  useEffect(() => {
    if (error) navigate('/404');
  }, [error]);

  const handleClickEdit = () => {
    setIsViewMode((v) => !v);
  };

  const handleModifyTooltipBtnClick = () => {
    localStorage.setItem('deleteCampaignTip', 'SET');
    setModifyTooltip(false);
  };

  return (
    <Fragment>
      <Breadcrumb
        title="campaigns"
        destination={'/campaigns'}
        detail={campaign.name || 'Unknown campaign'}
        modify={!isViewMode ? 'Delete Campaign' : ''}
        modifyFunction={() => deleteCampaign(campaign.campaignId)}
        tooltipContents={
          <Fragment>
            <p>
              If a mistake has been made or you need to delete this Campaign for some other reason,
              this is the spot to do that.
            </p>
            <p>After being deleted, the Campaign will no longer be visible or active.</p>
          </Fragment>
        }
        isModifyTooltip={modifyTooltip}
        handleModifyTooltipBtnClick={handleModifyTooltipBtnClick}
      />
      <Card className="telecom-card">
        <CardHeader className="telecom-card-header">
          {campaign.name || 'Unknown campaign'}
          <Button
            href={encodeURI(
              process.env.REACT_APP_API_URL + '/DownloadCampaignCsv?campaignId=' + campaignId
            )}
            target="_blank"
            rel="noopener noreferrer"
            className="downloadCsvStyle float-end"
          >
            <i className="fa fa-download" />
            {` Download CSV`}
          </Button>
        </CardHeader>
        <CardBody>
          <Row className="justify-content-around">
            <Col xs="12" sm="7" lg="5">
              <CampaignEditor
                className={isViewMode ? 'traceback-editor' : ''}
                isView={isViewMode}
                createdBy={campaign.createdBy}
                attachments={attachments}
                campaignServer={editCampaignObject}
              ></CampaignEditor>
            </Col>
            <Col className="mt-2" xs="12" sm="5" lg="5">
              <div className="row mt-4 mb-2">
                <label className="label-bold">Created</label>
                <span className="telecom-text padding">
                  {campaign.create_date
                    ? getClientFormattedDate(campaign.create_date, DateFormat.MediumBoth)
                    : 'None'}
                </span>
              </div>
              <div className="row mb-1">
                <label className="label-bold pb-1">Created by</label>
                <span>{campaign.createdBy ? campaign.createdBy : 'None'}</span>
              </div>
              <div className="row mb-1">
                <label className="label-bold mt-1 pb-1">Campaign ID. No.</label>
                <span>{campaign.campaignId ? campaign.campaignId : 'None'}</span>
              </div>
              <div className="row">
                <label className="label-bold mt-1 pb-1">System Comments</label>
                {systemComments && systemComments.length > 0 ? (
                  systemComments.map((item, key) => (
                    <div className="w-100" key={item.commentId}>
                      <div className="row">
                        <div className="col">
                          <span className="fw-bold">{`Date/Time: `}</span>
                          {getClientFormattedDate(item.create_date, DateFormat.MediumBoth)}
                        </div>
                        <div className="col">
                          <span className="fw-bold">{`From: `}</span>
                          {item.userName}
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-md-12">{item.contentText}</div>
                      </div>
                    </div>
                  ))
                ) : (
                  <span className="telecom-text mb-0 pt-1 pe-2">None</span>
                )}
              </div>
            </Col>
          </Row>
          {isViewMode && (
            <div className="d-flex justify-content-center">
              <Button className="telecom-btn" onClick={handleClickEdit}>
                Edit
              </Button>
            </div>
          )}
        </CardBody>
      </Card>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    campaign: sm.campaign.campaign,
    attachments: sm.campaign.attachments,
    error: sm.campaign.error
  };
};

const mapActionsToProps = {
  getCampaignObject,
  editCampaignObject,
  deleteCampaign
};

export default connect(mapStateToProps, mapActionsToProps)(CampaignDetail);
