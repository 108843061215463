import React from 'react';
import { ExpandableRowsComponent } from 'react-data-table-component/dist/DataTable/types';
import { connect } from 'react-redux';
import { Card, CardBody, Row } from 'reactstrap';
import { Comment, CommentFormat } from '../../interfaces/comment';
import { Pagination } from '../../interfaces/pagination';
import {
  FilterElement,
  addCondition,
  addConditionGroup,
  newConditionalFilterElement,
  newLeafFilterElement,
  simplifyFilterElement
} from '../../lib/FilterElement';
import { encodeQueryParams, saveToLocalStorage } from '../../lib/history-utils';
import { useDidUpdateEffect } from '../../lib/hooks';
import { getCommentList } from '../../redux/comment/thunks';
import { stateMappings } from '../../redux/stateMappings';
import CustomDataTable from '../CustomDataTable';

interface IProps {
  columns: any[];
  pagination: Pagination;
  setPagination: (p: Pagination) => void;
  filterElements: FilterElement;
  expandedComponent?: ExpandableRowsComponent<any>;
  comments: Comment[];
  getCommentList: Function;
  paginationTotalRows: number;
  conditionalRowStyles?: any;
}

const CommentsTable: React.FC<IProps> = ({
  columns,
  pagination,
  setPagination,
  filterElements,
  expandedComponent,
  paginationTotalRows,
  comments,
  getCommentList,
  conditionalRowStyles
}) => {
  const updatePagination = (params: Pagination) => {
    setPagination({
      ...pagination,
      ...params
    });
  };
  useDidUpdateEffect(() => {
    getCommentList(pagination, filterElements, CommentFormat.Unrolled);
  }, [filterElements, pagination]);

  return (
    <CustomDataTable
      columns={columns}
      defaultSortFieldId={pagination.sort}
      defaultSortAsc={pagination.order === 'asc'}
      defaultPage={pagination.page}
      defaultPageSize={pagination.pageSize}
      pagination={true}
      paginationTotalRows={paginationTotalRows}
      tableData={comments}
      updatePaginationParams={updatePagination}
      useExpandableRows={!!expandedComponent}
      useExpandableRowsComponent={expandedComponent}
      conditionalRowStyles={conditionalRowStyles}
    />
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    comments: sm.comment.comments,
    paginationTotalRows: sm.comment.meta.TotalCount
  };
};

const mapActionsToProps = {
  getCommentList
};

export default connect(mapStateToProps, mapActionsToProps)(CommentsTable);

export const saveCommentParams = (
  pageName: string,
  commentType: number,
  filterElements: FilterElement,
  pagination: Pagination
) => {
  saveToLocalStorage(
    pageName,
    encodeQueryParams(
      pageName,
      {
        commentType,
        filterElements,
        paginationParams: pagination
      },
      {}
    )
  );
  saveToLocalStorage('commentType', commentType.toString());
};

export const DefaultExpandedComponent = (props: any) => (
  <Card className="expandedRow">
    <CardBody style={{ color: '#797979', padding: '0.5rem 1rem' }}>
      <Row>
        <span className="special-title">Comment:&emsp;</span>
        <span>{props.data.contentText ? props.data.contentText : 'None'}</span>
      </Row>
    </CardBody>
  </Card>
);

export const setCommentsFiltersFromHistory = (savedSearchDetails: any) => {
  const { filterElements } = savedSearchDetails;

  let endDate,
    startDate,
    relatedProviderId,
    searchKey,
    hopProviderId,
    recipientProviderId,
    campaignId,
    tracebackId,
    onlyTraceforward;

  let emailType: string[] = [];

  if (!filterElements) {
    return {};
  }

  let filterableArray = [filterElements];
  if (Array.isArray(filterElements.conditionals)) {
    filterableArray = filterElements.conditionals;
  }
  filterableArray.forEach(({ conditionals, name, value }: FilterElement) => {
    switch (name) {
      case 'startDate':
        startDate = value;
        break;
      case 'endDate':
        endDate = value;
        break;
      case 'relatedProviderId':
        relatedProviderId = Number(value);
        break;
      case 'searchKey':
        searchKey = value;
        break;
      case 'tfhopProviderId':
        hopProviderId = Number(value);
        break;
      case 'recipientProviderId':
        recipientProviderId = Number(value);
        break;
      case 'campaignId':
        campaignId = Number(value);
        break;
      case 'tracebackId':
        tracebackId = value;
        break;
      case 'onlyTraceforward':
        onlyTraceforward = Boolean(value);
        break;
      case 'emailType':
        switch (value) {
          case 'ITG Member':
            emailType.push('includeITGMember');
            break;
          case 'Reminder':
            emailType.push('includeReminder');
            break;
          case 'Formal':
            emailType.push('includeFormal');
            break;
          case 'Cooperating':
            emailType.push('includeCooperating');
            break;
          case 'Non-Responsive':
            emailType.push('includeNonResponsive');
            break;
          default:
            break;
        }
      // This defined case exists because the existing DS does not assign a name value
      // to how the code structures multiple filters of the same "type"
      case undefined:
        if (Array.isArray(conditionals)) {
          conditionals.forEach(({ name, value }: FilterElement) => {
            switch (name) {
              case 'startDate':
                startDate = value;
                break;
              case 'endDate':
                endDate = value;
                break;
              case 'relatedProviderId':
                relatedProviderId = value;
                break;
              case 'searchKey':
                searchKey = value;
                break;
              case 'tfhopProviderId':
                hopProviderId = Number(value);
                break;
              case 'recipientProviderId':
                recipientProviderId = value;
                break;
              case 'campaignId':
                campaignId = value;
                break;
              case 'tracebackId':
                tracebackId = value;
                break;
              case 'emailType':
                switch (value) {
                  case 'ITG Member':
                    emailType.push('includeITGMember');
                    break;
                  case 'Reminder':
                    emailType.push('includeReminder');
                    break;
                  case 'Formal':
                    emailType.push('includeFormal');
                    break;
                  case 'Cooperating':
                    emailType.push('includeCooperating');
                    break;
                  case 'Non-Responsive':
                    emailType.push('includeNonResponsive');
                    break;
                  default:
                    break;
                }
              case 'onlyTraceforward':
                onlyTraceforward = Boolean(value);
                break;
              default:
                break;
            }
          });
        }
        break;
      default:
        break;
    }
  });

  return {
    endDate,
    startDate,
    relatedProviderId,
    searchKey,
    hopProviderId,
    recipientProviderId,
    emailType,
    campaignId,
    tracebackId,
    onlyTraceforward
  };
};

export const getCommentsBasicFilter = (
  { startDate, endDate }: any,
  commentType?: string
): FilterElement => {
  let filterElements = newConditionalFilterElement('AND');

  if (commentType) {
    addCondition(filterElements, newLeafFilterElement('commentType', 'EQ', commentType));
  } else {
    let commentElements = newConditionalFilterElement('OR');

    addCondition(commentElements, newLeafFilterElement('commentType', 'EQ', 'hop'));

    addCondition(commentElements, newLeafFilterElement('commentType', 'EQ', 'tfhop'));
    addCondition(filterElements, simplifyFilterElement(commentElements));
  }

  if (startDate || endDate) {
    addConditionGroup('AND', filterElements, [
      { key: startDate ? 'startDate' : '', value: startDate },
      { key: endDate ? 'endDate' : '', value: endDate }
    ]);
  }

  return filterElements;
};
