import React, { Fragment, useState } from 'react';
import { useNavigate } from 'react-router';
import Select from 'react-select';
import { Button } from 'reactstrap';
import { Hop, NoteType } from '../../interfaces/hop';
import { Traceback } from '../../interfaces/traceback';
import { explainNRUpstreamHop } from '../../redux/hop/apiCalls';
import { SelectOption } from '../CustomSelect';
import InputFormGroup from '../inputFormGroup';

interface IProps {
  hop: Hop;
  traceback: Traceback;
}

const actionsTaken: SelectOption[] = [
  {
    value: 1,
    label: 'No Action Taken'
  },
  {
    value: 2,
    label: 'Investigation'
  },
  {
    value: 3,
    label: 'Asked Upstream Voice Service Provider for information'
  },
  {
    value: 4,
    label: 'Warned Upstream Voice Service Provider'
  },
  {
    value: 5,
    label: 'Terminated Upstream Voice Service Provider'
  }
];

const NRActionTaken: React.FC<IProps> = ({ hop }) => {
  const [actionTaken, setActionTaken] = useState<SelectOption | null>(null);
  const [stepsTaken, setStepsTaken] = useState('');
  const navigate = useNavigate();

  const handleStepsTakenChange = (e: any) => {
    if (e !== null && e.currentTarget) {
      setStepsTaken(e.currentTarget.value);
    }
  };

  const submit = async () => {
    if (!actionTaken) return;
    try {
      await explainNRUpstreamHop(hop.hopId, actionTaken?.label, stepsTaken);
      navigate(0);
    } catch (error) {}
  };
  return !hop.actionTaken && hop.isAllowedToRespondNR ? (
    <Fragment>
      <h5 className="mt-3">Action Requested</h5>
      <p className="ps-2">
        The upstream provider you identified as having received this call traffic from has not
        responded to this traceback request despite multiple notifications. All US Voice Service
        Providers are by law to respond to traceback requests in a timely manner. Providers that
        have filed with the FCC's RMD have indicated that they will comply with all traceback
        requests. Tracebacks are initiated to identify sources of illegal and fraudulent calls, not
        responding to requests undermines our industry's efforts. Please let us know if you have or
        plan to take action with your upstream provider <b>{hop.upstreamProvider?.name}</b>.
      </p>
      <div className="row" style={{ marginLeft: '5%', marginRight: '5%' }}>
        <div className="col-12 col-md-3">
          <label className="telecom-label">
            Action Taken
            <i className="fa fa-asterisk asterisk" />
          </label>
          <Select
            className="customselect-large"
            classNamePrefix="customselect"
            options={actionsTaken}
            value={actionTaken}
            onChange={(e: any) => {
              setActionTaken(e);
            }}
          />
        </div>
        <div className="col-12 col-md-8">
          <label className="telecom-label">
            Steps Taken
            <i className="fa fa-asterisk asterisk" />
          </label>
          <InputFormGroup
            isReadonly={false}
            isTextarea
            inputName="stepsTaken"
            inputId="stepsTaken"
            inputClassName="input-hop"
            inputValue={stepsTaken}
            inputOnChange={handleStepsTakenChange}
            inputPlaceholder="Please describe the steps you have taken to mitigate unlawful traffic."
            inputAutoComplete="off"
          />
        </div>
      </div>
      <div className="d-flex justify-content-center mb-3">
        <Button
          className="telecom-btn"
          style={{ minWidth: '130px' }}
          onClick={submit}
          disabled={!actionTaken || !stepsTaken}
        >
          Submit
        </Button>
      </div>
    </Fragment>
  ) : hop.actionTaken && !!(hop.notes || []).find((v) => v === NoteType.DownNoResponseHop) ? (
    <div className="m-4">
      <h6>{`Action Taken: ${hop.actionTaken}`}</h6>
      <p>{hop.explanation}</p>
    </div>
  ) : null;
};

export default NRActionTaken;
