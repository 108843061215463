import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import Breadcrumb from '../components/Breadcrumbs';
import TracebackEditor from '../components/traceback/tracebackEditor';
import { emptyTraceback } from '../interfaces/traceback';
import { stateMappings } from '../redux/stateMappings';
import { addTracebackObject } from '../redux/traceback/thunks';

type IProps = {
  createdBy: string;
  addTracebackObject: Function;
};

const traceback = emptyTraceback();

const AddTraceback: React.FC<IProps> = ({ addTracebackObject, createdBy }) => {
  return (
    <Fragment>
      <Breadcrumb title="tracebacks" destination={'/tracebacks'} detail="Add Traceback" />
      <Card className="telecom-card">
        <CardHeader className="telecom-card-header">Add Traceback</CardHeader>
        <CardBody>
          <div className="d-flex justify-content-center">
            <Col xs="12" sm="11" lg="10">
              <TracebackEditor
                isAdd
                traceback={traceback}
                createdBy={createdBy}
                tracebackServer={addTracebackObject}
              />
            </Col>
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    createdBy: sm.user.name
  };
};

const mapActionsToProps = { addTracebackObject };

export default connect(mapStateToProps, mapActionsToProps)(AddTraceback);
