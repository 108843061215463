import { configureStore } from '@reduxjs/toolkit';
import 'font-awesome/css/font-awesome.min.css';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import rootReducer from './combineReducers';

export const store = configureStore({
  reducer: {
    _newReduxTree: rootReducer
  }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
