import React, { Fragment } from 'react';
import { TableColumn } from 'react-data-table-component';
import ProviderPanel from '../../components/ProviderPanel';
import { tbStatus } from '../../components/stateConverter';
import SignerTracebackCellContent from '../../components/stirShaken/SignerTracebackCellContent';
import { DateFormat } from '../../enum/DateFormat';
import { Traceback } from '../../interfaces/traceback';
import { getClientFormattedDate, getDuration, getElapsedTime } from '../utilities';
import AttestationTracebackStirShaken from '../../components/stirShaken/AttestationTracebackStirShaken';
import { Note } from '../../components/shared/Notes';
import { NoteType } from '../../interfaces/hop';

const rmdDate = '2022-09-28 23:59:59';

export const tracebacksPageColumns: TableColumn<Traceback>[] = [
  {
    name: 'TB #',
    selector: () => 'id',
    id: 'column-id',
    sortable: true,
    grow: 1,
    cell: (traceback: Traceback) => (
      <div className="table-cell blue">
        <span
          onClick={() => (window.location.href = `/tracebacks/traceback/${traceback.tracebackId}`)}
        >
          <a href={`/tracebacks/traceback/${traceback.tracebackId}`}>{traceback.tracebackId}</a>
        </span>
        {traceback.isInternational && <Note type={NoteType.InternationalTraceback} />}
      </div>
    )
  },
  {
    name: 'Campaign',
    selector: () => 'campaignName',
    id: 'column-campaignName',
    sortable: true,
    grow: 2,
    cell: (traceback: Traceback) => (
      <div
        className="table-cell blue"
        onClick={() => (window.location.href = `/tracebacks/traceback/${traceback.tracebackId}`)}
      >
        <a href={`/campaigns/campaign/${traceback.campaignId}`}>
          {traceback.campaignName || 'None'}
        </a>
      </div>
    )
  },
  {
    name: 'Created',
    selector: () => 'create_date',
    id: 'column-create_date',
    sortable: true,
    grow: 1,
    cell: (traceback: Traceback) => (
      <span
        className="table-cell gray"
        onClick={() => (window.location.href = `/tracebacks/traceback/${traceback.tracebackId}`)}
      >
        {traceback.create_date
          ? getClientFormattedDate(traceback.create_date, DateFormat.ShortDate)
          : 'None'}
      </span>
    )
  },
  {
    name: 'Last Updated',
    selector: () => 'update_date',
    id: 'column-update_date',
    sortable: true,
    grow: 1,
    cell: (traceback: Traceback) => {
      return (
        <span className={`table-cell ${traceback.update_date ? 'blue' : 'gray'}`}>
          <div
            className="table-cell blue"
            onClick={() =>
              (window.location.href = `/tracebacks/traceback/${traceback.tracebackId}`)
            }
          >
            {traceback.update_date ? (
              <a href={`/hops/hop/${traceback.lastHopId}`}>{getDuration(traceback.update_date)}</a>
            ) : (
              <Fragment>{'None'}</Fragment>
            )}
          </div>
        </span>
      );
    }
  },
  {
    name: 'Time Elapsed',
    selector: () => 'elapsed',
    id: 'column-elapsed',
    sortable: true,
    grow: 1,
    cell: (traceback: Traceback) => (
      <div
        className="table-cell gray center"
        onClick={() => (window.location.href = `/tracebacks/traceback/${traceback.tracebackId}`)}
      >
        <span>{traceback.elapsed ? getElapsedTime(traceback.elapsed) : 'None'}</span>
      </div>
    )
  },
  {
    name: 'Status',
    selector: () => 'status',
    id: 'column-status',
    sortable: true,
    grow: 1,
    cell: (traceback: Traceback) => (
      <div
        className="table-cell gray center"
        onClick={() => (window.location.href = `/tracebacks/traceback/${traceback.tracebackId}`)}
      >
        <span
          style={{
            fontWeight: traceback.status > 0 && traceback.status < 6 ? 600 : 'inherit'
          }}
        >
          {tbStatus(traceback.status)}
        </span>
      </div>
    )
  },
  {
    name: 'Last Provider',
    selector: () => 'lastProviderName',
    id: 'column-lastProviderName',
    sortable: true,
    grow: 2,
    cell: (traceback: Traceback) => (
      <ProviderPanel
        includeReputation={true}
        provider={traceback.lastProvider}
        hyperlink
        rmdFlag={traceback.lastHopRMDFlag}
      />
    )
  },
  {
    name: 'Signer',
    selector: () => 'signerProviderName',
    id: 'column-signerProviderName',
    sortable: false,
    grow: 2,
    cell: (traceback: Traceback) => <SignerTracebackCellContent traceback={traceback} />
  },
  {
    name: 'Attestation',
    selector: () => 'attest',
    id: 'column-attest',
    sortable: false,
    grow: 1,
    cell: (traceback: Traceback) => <AttestationTracebackStirShaken traceback={traceback} />
  },
  {
    name: 'Hops',
    selector: () => 'numberOfHops',
    id: 'column-numberOfHops',
    sortable: true,
    grow: 1,
    cell: (traceback: Traceback) => (
      <div
        className={`table-cell ${traceback.numberOfHops ? 'blue center' : 'gray center'}`}
        onClick={() => (window.location.href = `/tracebacks/traceback/${traceback.tracebackId}`)}
      >
        {traceback.numberOfHops ? (
          <a href={`/hops?tracebackId=${traceback.tracebackId}`}>{traceback.numberOfHops}</a>
        ) : (
          <span>{traceback.numberOfHops}</span>
        )}
      </div>
    )
  }
];
