import {
  GET_REPUTATION_HOPS_PRERECORDED,
  GET_REPUTATION_HOPS_INTERNATIONAL,
  GET_REPUTATION_HOPS_LIVE,
  SET_REPUTATION_ERROR_MESSAGE,
  SET_REPUTATION_LOADING_STATUS
} from './actionEnum';

export const getReputationHopsLive = (response: any) => ({
  type: GET_REPUTATION_HOPS_LIVE,
  payload: response
});

export const getReputationHopsInternational = (response: any) => ({
  type: GET_REPUTATION_HOPS_INTERNATIONAL,
  payload: response
});

export const getReputationHopsPrerecorded = (response: any) => ({
  type: GET_REPUTATION_HOPS_PRERECORDED,
  payload: response
});

export const setLoadingStatus = (candidate: boolean) => ({
  type: SET_REPUTATION_LOADING_STATUS,
  payload: candidate
});

export const setErrorMessage = (message: string) => ({
  type: SET_REPUTATION_ERROR_MESSAGE,
  payload: message
});
