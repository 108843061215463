import { Incident } from './actionEnum';

export const getIncident = (response: any) => ({
  type: Incident.GET_INCIDENT,
  payload: response
});

export const getIncidents = (response: any) => ({
  type: Incident.GET_INCIDENTS,
  payload: response
});

export const getIncidentSummary = (response: any) => ({
  type: Incident.GET_INCIDENT_SUMMARY,
  payload: response
});

export const getIncidentCampaignSources = (response: any) => ({
  type: Incident.GET_INCIDENT_CAMPAIGN_SOURCES,
  payload: response
});

export const putIncidentCampaign = (response: any) => ({
  type: Incident.PUT_INCIDENT_CAMPAIGN,
  payload: response
});

export const postTracebackIncidents = (response: any) => ({
  type: Incident.TRACEBACK_INCIDENTS,
  payload: response
});

export const postIncidentCSV = (response: any) => ({
  type: Incident.POST_INCIDENT_CSV,
  payload: response
});

export const setLoadingStatus = (status: boolean) => ({
  type: Incident.SET_INCIDENT_LOADING_STATUS,
  payload: status
});

export const setErrorMessage = (message: string) => ({
  type: Incident.SET_INCIDENT_ERROR_MESSAGE,
  payload: message
});

export const getIncidentCampaignSummary = (response: any) => ({
  type: Incident.GET_INCIDENT_CAMPAIGN_SUMMARY,
  payload: response
});

export const getIncidentCampaignNamesAction = (response: any) => ({
  type: Incident.GET_CAMPAIGN_NAMES,
  payload: response
});
