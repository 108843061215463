import { Hop } from './hop';
import { ProviderSummaryRow } from './provider';
import { Traceback } from './traceback';

export enum ReportType {
  CampaignReport = 'CampaignReport',
  ProviderReport = 'ProviderReport',
  ReputationReport = 'ReputationReport',
  SubpoenaReport = 'SubpoenaReport',
  TfSubpoenaReport = 'TfSubpoenaReport',
  QueryReport = 'QueryReport',
  RollupReport = 'RollupReport',
  SixtyDaysReport = 'SixtyDaysReport',
  PartnerReport = 'PartnerReport',
  PartnerSourceReport = 'PartnerSourceReport',
  IPReport = 'IPReport',
  QuarterlyReport = 'QuarterlyReport'
}

export enum ReportFormat {
  Excel = 'xlsx',
  Word = 'docx',
  Json = 'json',
  Zip = 'zip'
}

export interface ReportPostData {
  // Used by all reports
  startDate?: string;
  endDate?: string;
  name: string;
  narrativeIntro: string;
  markConfidential?: boolean;
  zendeskTicketId?: number;
  // Provider & Campaign Reports
  campaignId?: number;
  providerId?: number;
  includeFullCallPath?: boolean;
  obscurePhoneNumbers?: boolean;
  removeComments?: boolean;
  // Rollup Report
  includeProviderNames?: boolean;
  includeCampaignsRollup?: boolean;
  includeProvidersRollup?: boolean;
  includeTracebacksRollup?: boolean;
  // Reputation Report
  reportDate?: Date;
  periodType?: number;
  // Subpoena Report
  callingParties?: string[];
  campaigns?: number[];
  generators?: string[];
  hopTypes?: HopTypes;
  providers?: number[];
  tracebacks?: number[];
  reputationThresholds?: number[];
  reportFormat?: string;
  includeComments?: boolean;
  includeNotifications?: boolean;
  includeProviderCallRecords?: boolean;
  includeOtherAttachments?: boolean;
  includeAdminData?: boolean;
  includeAllHops?: boolean;
  includeProviderUpstreamDownstreamSummaries?: boolean;
  excludeOpenTracebacks?: boolean;
  excludeTracebacksWithNotFoundStatus?: boolean;
  chosenLabels?: any[];
  // TfSubpoena Report
  tfhopTypes?: HopTypes;
  traceforwards?: number[];
  includeAllTfHops?: boolean;
  excludeOpenTraceforwards?: boolean;
  excludeTraceforwardsWithNotFoundStatus?: boolean;
  // Requestor report
  requestorId?: number;
  includeStirShaken?: boolean;
  keywordSearch?: string;
  // IP Report
  origin?: string;
  // Partner Source Report
  sourceId?: number;
  sourceName?: string;
}

export interface ReportGetData {
  // Used by all reports
  startDate?: string;
  endDate?: string;
  name: string;
  narrativeIntro: string;
  markConfidential?: boolean;
  // Provider & Campaign Reports
  campaignId?: number;
  providerId?: number;
  includeFullCallPath?: boolean;
  obscurePhoneNumbers?: boolean;
  removeComments?: boolean;
  // Rollup Report
  includeProviderNames?: boolean;
  includeCampaignsRollup?: boolean;
  includeProvidersRollup?: boolean;
  includeTracebacksRollup?: boolean;
  // Reputation Report
  reportDate?: Date;
  periodType?: number;
  // Subpoena Report
  callingParties?: string[];
  campaigns?: number[];
  generators?: string[];
  hopTypes?: HopTypes;
  providers?: number[];
  tracebacks?: number[];
  reputationThresholds?: number[];
  includeComments?: boolean;
  includeNotifications?: boolean;
  includeProviderCallRecords?: boolean;
  includeOtherAttachments?: boolean;
  includeAdminData?: boolean;
  includeAllHops?: boolean;
  includeProviderUpstreamDownstreamSummaries?: boolean;
  excludeOpenTracebacks?: boolean;
  excludeTracebacksWithNotFoundStatus?: boolean;
  providerNames?: string[];
  campaignNames?: string[];
  reputationThresholdNames?: string[];
  zendeskTicketId?: number;
}

export interface HopTypes {
  includeUsOriginator?: boolean;
  includeUsPointOfEntry?: boolean;
  includeInternationalOrigin?: boolean;
  includeForeignPointOfDeparture?: boolean;
  includeNoResponsive?: boolean;
}

export interface TracebackReportResponse {
  id: number;
  name: string;
  providerCreateDate: string;
  TracebackList: TracebackList[];
}

export interface TracebackList {
  traceback: Traceback;
  hops: Hop[];
  callingParty: string;
  campaignCreateDate: string;
  TracebackWindow: TracebackWindow;
}

export interface TracebackWindow {
  firstTracebackDateInWindow: string;
  mostRecentTracebackDateInWindow: string;
  firstTracebackDate: string;
  mostRecentTracebackDate: string;
}

export interface RollupReportObject {
  rollupReportStatisticsRow: RollupReportTracebackRow[];
  rollupReportCampaignRow: RollupReportCampaignRow[];
}

export interface ReputationReportObject {
  ProviderSummaries: ProviderSummaryRow[];
}

export interface RollupReportTracebackRow {
  period: string;
  newTracebacks: number;
  totalHops: number;
  notFound: number;
  noInfo: number;
  complete: number;
  open: number;
  responded: number;
  sometimes: number;
  noResponse: number;
  total: number;
}

export interface RollupReportCampaignRow {
  campaignName: string;
  firstTracebackDate: string;
  mostRecentTracebackDate: string;
  count: number;
  rollupReportCampaignDataRow: RollupReportCampaignDataRow[];
}

export interface RollupReportCampaignDataRow {
  foreignPointOfDeparture: ProviderItem;
  usOriginator: ProviderItem;
  usPointOfEntry: ProviderItem;
  internationalOriginator: ProviderItem;
  callingEntity: string;
  count: number;
}

export interface ProviderItem {
  name: string;
  id: number;
}

export interface ReportArchive {
  id: string;
  reportType: string;
  reportFilter: ReportGetData;
  createDate: string;
}

export interface ReportArchiveObj {
  id: number;
  fileName: string;
  archiveUrl: string;
  reportType: ReportType;
  reportFilter?: string;
  userId: number;
  active: boolean;
  createDate: string;
  updateDate: string;
  status: string;
  extraInfo?: string;
  userName: string;
  zendeskTicketId?: number;
  tracebacksUpdated?: number[];
}

export interface ReportArchiveSearchRequest {
  startDate: string;
  endDate: string;
  type?: ReportType;
  status: string;
  onlyGeneratedByMe: boolean;
}
