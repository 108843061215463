import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { setInvalidHopId } from '../../redux/hop/actions';

import { Alert } from 'reactstrap';

interface IProps {
  hopId: number;
}

const HopInvalidAlert: React.FC<IProps> = ({ hopId }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);

  const onClose = () => {
    setOpen(false);
    dispatch(setInvalidHopId(null));
  };

  return hopId ? (
    <div className="d-flex justify-content-center m-0">
      <Alert
        color="warning"
        isOpen={open}
        toggle={onClose}
        style={{
          position: 'fixed',
          top: 100,
          right: 30,
          height: 120,
          zIndex: 999
        }}
        className="d-flex justify-content-center align-items-center"
      >
        <p>{`The link to the hop #${hopId} is no longer valid or you do not have permission to access.`}</p>
      </Alert>
    </div>
  ) : null;
};

export default HopInvalidAlert;
