import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { CardBody, Row } from 'reactstrap';
import { Hop } from '../interfaces/hop';
import { ProviderSummaryRow } from '../interfaces/provider';
import { expandedRowColumns } from '../lib/dataTableUtils/providerSummaryHopColumns';
import { getReputationHopsApiCall } from '../redux/reputation/apiCalls';

interface IProps {
  data?: ProviderSummaryRow;
  includeLinks?: boolean;
  startDate: string | undefined;
  endDate: string | undefined;
  termState?: string;
}

const ProviderExpandableRow: React.FC<IProps> = ({
  includeLinks,
  data,
  startDate,
  endDate,
  termState
}) => {
  const [tableData, setTableData] = useState<Hop[]>([]);

  useEffect(() => {
    if (!data || !startDate || !endDate) return;
    let { provider } = data;
    provider &&
      getReputationHopsApiCall({
        startDate,
        endDate,
        providerId: provider.providerId,
        termState: termState
      })
        .then((response) => {
          setTableData(response.data.data || []);
        })
        .catch(() => setTableData([]));
  }, [data]);
  let negativeTracebacksImpact = tableData.length;
  if (!negativeTracebacksImpact && data) {
    const { summary } = data;
    if (summary && Object.values(summary).length) {
      negativeTracebacksImpact =
        summary.numOfUSPoEHops +
        summary.numOfDownstreamNoResponseHops +
        summary.numOfDownstreamOriginHops +
        summary.numOfOriginHops +
        summary.numOfNoResponseHops +
        summary.numOfNotFoundHops;
    }
  }

  return (
    <div className="data-table">
      <CardBody
        style={{
          color: '#797979',
          fontSize: '1rem',
          padding: '0.5rem 1rem'
        }}
      >
        <Row className="justify-content-between m-0">
          <div className="d-flex">
            <h5 style={{ color: '#07335e', paddingRight: '10px' }}>
              In the selected period,{' '}
              <span
                style={{
                  color: '#47996f',
                  fontWeight: 800
                }}
              >
                {negativeTracebacksImpact}
              </span>{' '}
              {`${negativeTracebacksImpact !== 1 ? 'tracebacks' : 'traceback'}`} may contribute to
              status and score
            </h5>
          </div>
        </Row>

        <DataTable
          data={tableData}
          columns={expandedRowColumns(!!includeLinks)}
          defaultSortFieldId={'campaignName'}
          defaultSortAsc={false}
          noHeader
          className={'providers-expanded'}
        />
      </CardBody>
    </div>
  );
};
export default ProviderExpandableRow;
