import { Configuration } from './actionEnum';

export const getConfigurationObject = (response: any) => ({
  type: Configuration.GET_CONFIGURATION,
  payload: response
});

export const setLoadingStatus = (status: boolean) => ({
  type: Configuration.SET_CONFIGURATION_LOADING_STATUS,
  payload: status
});

export const setErrorMessage = (message: string) => ({
  type: Configuration.SET_CONFIGURATION_ERROR_MESSAGE,
  payload: message
});
