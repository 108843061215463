import React, { FC, useEffect, useMemo } from 'react';
import { OnChangeValue } from 'react-select';
import { regionList } from '../../lib/regionSelectUtils';
import CustomSelect, { SelectOption } from '../CustomSelect';
import { stateMappings } from '../../redux/stateMappings';
import { getProviderStates } from '../../redux/provider/thunks';
import { connect } from 'react-redux';

interface IProps {
  value: any;
  onChange: (value: OnChangeValue<SelectOption, boolean>) => void;
  selectClass?: string;
  isClearable?: boolean;
  inputId?: string;
  placeholder?: string;
  typeLabel: string;
  addExtraRegions?: boolean;
  onlyUsedStates?: boolean;
  getProviderStates: Function;
  states: string[];
}

const RegionSelect: FC<IProps> = ({
  value,
  onChange,
  selectClass,
  isClearable,
  inputId,
  placeholder,
  typeLabel,
  addExtraRegions,
  states,
  onlyUsedStates,
  getProviderStates
}) => {
  const usedStates = useMemo(
    () =>
      states.map((state: string) => ({
        value: regionList.find((l: SelectOption) => l.label === state)?.value,
        label: state
      })),
    [states]
  );
  const regions = useMemo(
    () =>
      usedStates && usedStates.length && onlyUsedStates
        ? usedStates
        : !addExtraRegions
          ? regionList
          : [
              {
                label: 'Non-Us',
                value: 'Non-Us'
              },
              {
                label: 'All-Us',
                value: 'All-Us'
              }
            ].concat(regionList),
    [usedStates, onlyUsedStates, addExtraRegions, regionList]
  );

  useEffect(() => {
    if (onlyUsedStates) getProviderStates();
  }, [onlyUsedStates]);

  return (
    <CustomSelect
      selectedOption={value || 0}
      setSelectedOption={onChange}
      className={selectClass || 'customselect-small'}
      getOptions={regions}
      isSearchable
      isClearable={isClearable}
      addAllItem
      typeLabel={typeLabel}
      inputId={inputId || 'regionSelect'}
      placeholder={placeholder}
    />
  );
};
const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return {
    states: sm.provider.states
  };
};

const mapActionsToProps = { getProviderStates };
export default connect(mapStateToProps, mapActionsToProps)(RegionSelect);
