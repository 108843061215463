export enum Hops {
  GET_HOPS = 'GET_HOPS',
  GET_HOP = 'GET_HOP',
  ADD_HOP = 'ADD_HOP',
  EDIT_HOP = 'EDIT_HOP',
  ADD_ATTACHMENTS = 'ADD_ATTACHMENTS',
  GET_ATTACHMENTS = 'GET_ATTACHMENTS',
  GET_HOP_STATUSES = 'GET_HOP_STATUSES',
  GET_HOP_COMMENTS = 'GET_HOP_COMMENTS',
  SET_HOP_LOADING_STATUS = 'SET_HOP_LOADING_STATUS',
  SET_HOP_ERROR_MESSAGE = 'SET_HOP_ERROR_MESSAGE',
  GET_HOPS_COUNT = 'GET_HOPS_COUNT',
  GET_ORIGIN_ACTION_STATS = 'GET_ORIGIN_ACTION_STATS',
  SET_INVALID_HOP_ID = 'SET_INVALID_HOP_ID',
  GET_HOPS_COUNTRIES = 'GET_HOPS_COUNTRIES',
  GET_DISPUTE = 'GET_DISPUTE'
}
