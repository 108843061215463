import React, { FC, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import DisplayRmds from '../components/DisplayRmds';
import { RMDEntry } from '../interfaces/rmd';
import { getAllRMDs } from '../redux/rmd/thunks';
import { stateMappings } from '../redux/stateMappings';
import InputFormGroup from './inputFormGroup';

interface IProps {
  getAllRMDs: Function;
  rmds: RMDEntry[];
  setRmd: (rmd: RMDEntry) => void;
  setBusinessName?: (name: string) => void;
  addRMDToProvider?: boolean;
}

const RmdSearcher: FC<IProps> = ({
  getAllRMDs,
  rmds,
  setRmd,
  setBusinessName,
  addRMDToProvider
}) => {
  const [rmdBusinessName, setRmdBusinessName] = useState('');

  useEffect(() => {
    if (rmdBusinessName.length < 3) return;
    getAllRMDs(rmdBusinessName);
  }, [rmdBusinessName]);

  useEffect(() => () => setRmdBusinessName(''), []);

  const handleInputChange = (e: any) => {
    e.preventDefault();
    const { value } = e.currentTarget;

    setRmdBusinessName(value);
    if (setBusinessName) {
      setBusinessName(value);
    }
  };

  return (
    <Fragment>
      <Row>
        <Col>
          <InputFormGroup
            inputName="p-rmdBusinessName"
            inputId="p-rmdBusinessName"
            inputClassName="input-hop"
            inputValue={rmdBusinessName}
            inputPlaceholder="Type to start search"
            inputOnChange={handleInputChange}
            inputAutoComplete="off"
          />
        </Col>
      </Row>
      <div className="rmd-results">
        {rmdBusinessName.length >= 3 && rmds.length === 0 ? (
          <h3 className="mt-3 me-3">No results</h3>
        ) : rmdBusinessName.length < 3 ? null : (
          <DisplayRmds rmds={rmds} setRmd={setRmd} addRMDToProvider={addRMDToProvider} />
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    rmds: sm.rmd.rmds || []
  };
};

const mapActionsToProps = { getAllRMDs };
export default connect(mapStateToProps, mapActionsToProps)(RmdSearcher);
