import React, { FC, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { userTypes } from '../enum/userTypes';
import { Configuration } from '../interfaces/configuration';
import { PersonalInfo } from '../interfaces/user';
import { getClientConfigurationInfo } from '../redux/appinfo/thunks';
import { rememberPage } from '../redux/auth/thunks';
import { stateMappings } from '../redux/stateMappings';

interface IProps {
  path: string;
  element: React.ReactElement;

  isAuthenticated: boolean;
  user: PersonalInfo;
  configuration: Configuration;

  rememberPage: Function;
  getClientConfigurationInfo: Function;
}

const basicAllowedPagesWithoutAccountSetup: string[] = [
  '/account',
  '/create-password',
  '/setup-account'
];

const basicAllowedPagesWithAccountSetup: string[] = [
  '/providers/provider/summaries/:id',
  '/zendesk/token',
  '/zendesk/logout',
  '/'
];

const govAllowedPages: string[] = [
  '/providers',
  '/tracebacks',
  '/nrProviderInfo',
  '/newProviderInfo'
];
const providerManagerAllowedPages: string[] = ['/providers/provider/:id'];
const providerAllowedPages: string[] = [
  '/comments',
  '/hops',
  '/hops/hop/:id',
  '/tfhops',
  '/tfhops/tfhop/:id',
  '/providers/provider/insights/:id'
];
const requestorAllowedPages: string[] = ['/requests', '/requests/add'];

const redirectTo = (
  path: string,
  isAuthenticated: boolean,
  user: PersonalInfo,
  configuration: Configuration,
  urlLocation: string
): string | null => {
  const permittedLocation = localStorage.getItem('locationPermitted');
  if (!isAuthenticated) return '/login';
  if (user.roleType === userTypes.Admin) return null;
  if (basicAllowedPagesWithoutAccountSetup.some((val) => val === path)) return null;
  if (!user.setupCompleted && permittedLocation === urlLocation) return null;
  if (!user.setupCompleted) return '/setup-account';
  if (
    basicAllowedPagesWithAccountSetup.some((val) => val === path) &&
    user.roleType !== userTypes.DnoUser &&
    user.roleType !== userTypes.PartnerUser
  )
    return null;

  switch (user.roleType) {
    case userTypes.GovUser:
      if (requestorAllowedPages.some((val) => val === path) && user.isRequestor) break;
      if (!govAllowedPages.some((val) => val === path)) return '/';
      break;
    case userTypes.ProviderManager:
      if (providerManagerAllowedPages.some((val) => val === path)) break;
    case userTypes.Provider:
      if (path === '/providers/provider/insights/:id' && configuration.insightVisible === false)
        return '/';
      if (path === '/dno-providers' && user.dnoAccess) break;
      if (requestorAllowedPages.some((val) => val === path) && user.isRequestor) break;
      if (!providerAllowedPages.some((val) => val === path)) return '/';
      break;
    case userTypes.PartnerUser:
      if (!requestorAllowedPages.some((val) => val === path)) return '/requests';
      break;
    case userTypes.DnoUser:
      if (path !== '/dno-providers') return '/dno-providers';
      break;
  }
  return null;
};

const AuthRoute: FC<IProps> = ({
  isAuthenticated,
  user,
  path,
  element,
  rememberPage,
  configuration,
  getClientConfigurationInfo
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const getConfiguration = async () => await getClientConfigurationInfo();
    if (
      configuration.insightVisible === undefined &&
      (user.roleType === userTypes.Provider || user.roleType === userTypes.ProviderManager)
    )
      getConfiguration();
  }, [configuration.insightVisible, user.roleType]);

  const redirectPage = useMemo(() => {
    return redirectTo(
      path,
      isAuthenticated,
      user,
      configuration,
      location.pathname + location.hash
    );
  }, [path, isAuthenticated, user, configuration]);

  useEffect(() => {
    if (redirectPage === '/login') {
      const newRoute = location.pathname + location.search + location.hash;
      rememberPage(newRoute);
    }
    if (redirectPage !== null) navigate(redirectPage, { replace: true });
  }, [redirectPage]);

  return redirectPage !== null ? null : element;
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return {
    user: sm.user,
    isAuthenticated: sm.isAuthenticated,
    configuration: sm.appinfo.configuration
  };
};

const mapActionToProps = { rememberPage, getClientConfigurationInfo };

export default connect(mapStateToProps, mapActionToProps)(AuthRoute);
