import { Hops } from './actionEnum';

const meta = {
  TotalCount: 0,
  PageSize: 0,
  CurrentPage: 0,
  TotalPages: 1
};

const defaultState: any = {
  hops: [],
  hopsCountries: [],
  hop: {},
  statuses: [],
  meta,
  loading: 0,
  error: '',
  hopComments: [],
  originActionsStats: [],
  hopInvalidID: null,
  hopDispute: null
};

const reducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case Hops.GET_HOPS:
      return {
        ...state,
        hops: action.payload.data || [],
        meta: action.payload.paginationMeta || meta
      };

    case Hops.GET_HOP:
      return { ...state, hop: action.payload.data };

    case Hops.ADD_HOP:
      return { ...state };

    case Hops.EDIT_HOP:
      return { ...state, hop: action.payload.data };

    case Hops.GET_HOP_COMMENTS:
      return { ...state, hopComments: action.payload.data };

    case Hops.ADD_ATTACHMENTS:
      return { ...state };

    case Hops.GET_ATTACHMENTS:
      return { ...state };

    case Hops.SET_HOP_LOADING_STATUS:
      if (action.payload) return { ...state, loading: state.loading + 1 };
      else return { ...state, loading: state.loading === 0 ? state.loading : state.loading - 1 };

    case Hops.SET_HOP_ERROR_MESSAGE:
      return { ...state, error: action.payload };

    case Hops.GET_HOP_STATUSES:
      return { ...state, statuses: action.payload };

    case Hops.GET_HOPS_COUNT:
      return { ...state, hopCount: action.payload.data.hopCount };

    case Hops.GET_ORIGIN_ACTION_STATS:
      return { ...state, originActionsStats: action.payload };

    case Hops.GET_HOPS_COUNTRIES:
      return {
        ...state,
        hopsCountries: action.payload
      };

    case Hops.SET_INVALID_HOP_ID:
      return {
        ...state,
        hopInvalidId: action.payload
      };

    case Hops.GET_DISPUTE:
      return {
        ...state,
        hopDispute: action.payload
      };

    default:
      return state;
  }
};

export default reducer;
