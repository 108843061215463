import React, { FC, useEffect, useRef, useState } from 'react';

import { connect } from 'react-redux';
import { getHopsCountries } from '../../redux/hop/thunks';
import { getProvidersCountries } from '../../redux/provider/thunks';
import { stateMappings } from '../../redux/stateMappings';

import { CategoryScale, Chart as ChartJS, Legend, Title, Tooltip } from 'chart.js';
import {
  ChoroplethChart,
  ChoroplethController,
  ColorScale,
  GeoFeature,
  ProjectionScale,
  topojson
} from 'chartjs-chart-geo';
import { Chart } from 'react-chartjs-2';

import { ChartFilterElements } from '../../interfaces/charts';
import { ProvidersCountries } from '../../interfaces/provider';
import { dataMapChart, topologyUrl } from '../../lib/chart/data';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  ChoroplethController,
  ChoroplethChart,
  ProjectionScale,
  ColorScale,
  GeoFeature
);

interface IProps {
  chosenKey: string;
  providersCountries?: ProvidersCountries[];
  hopsCountries?: ProvidersCountries[];
  hops?: boolean;
  chartFilters: ChartFilterElements;
  getProvidersCountries: Function;
  getHopsCountries: Function;
}

const MapChart: FC<IProps> = ({
  chosenKey,
  providersCountries,
  hopsCountries,
  hops,
  chartFilters,
  getProvidersCountries,
  getHopsCountries
}) => {
  const chartRef = useRef();
  const [topoData, setTopoData] = useState<any>([]);

  const dataChart = dataMapChart(topoData, hops ? hopsCountries : providersCountries);

  const getTopoData = async () => {
    try {
      const response = await fetch(topologyUrl);
      const value = await response.json();
      if (value) {
        setTopoData(
          topojson.feature(
            value,
            value.objects.countries
            //@ts-ignore
          ).features
        );
      }
    } catch (error: any) {
      console.log(`FAILED TO FETCH MAP CHART DATA ${error}`);
    }
  };

  useEffect(() => {
    getTopoData();
  }, [chosenKey]);

  useEffect(() => {
    if (hops) {
      getHopsCountries(chartFilters);
    } else {
      getProvidersCountries(chartFilters);
    }
  }, [chartFilters]);

  const interpolate = (v: any) => {
    if (v < 0.005) {
      return '#eaf5fb';
    } else if (v < 0.01) {
      return '#d5eaf6';
    } else if (v < 0.015) {
      return ' #c0e0f2';
    } else if (v < 0.02) {
      return ' #abd6ed';
    } else if (v < 0.025) {
      return '#9dcfea';
    } else if (v < 0.03) {
      return ' #96cce9';
    } else if (v < 0.035) {
      return '#81c1e4';
    } else if (v < 0.04) {
      return '#6cb7e0';
    } else if (v < 0.045) {
      return '#57addb';
    } else if (v < 0.05) {
      return '#42a3d7';
    } else if (v < 0.055) {
      return '#2d98d2';
    } else if (v < 0.06) {
      return '#2889bd';
    } else if (v < 0.65) {
      return '#247aa8';
    } else if (v < 0.07) {
      return ' #1f6b93';
    } else if (v < 0.075) {
      return '#1b5b7e';
    } else if (v < 0.08) {
      return '#164c69';
    } else if (v < 0.85) {
      return '#123d54';
    } else if (v < 0.09) {
      return '#0d2e3f';
    } else if (v < 0.095) {
      return '#091e2a';
    } else {
      return '#040f15';
    }
  };

  return (
    <Chart
      ref={chartRef}
      className="d-inline-flex"
      type="choropleth"
      data={dataChart}
      options={{
        showOutline: true,
        showGraticule: true,
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          projection: {
            axis: 'x',
            projection: 'equalEarth'
          },
          color: {
            quantize: 500,
            axis: 'x',
            interpolate: (v) => interpolate(v)
          }
        }
      }}
    />
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    providersCountries: sm.provider.providersCountries,
    hopsCountries: sm.hop.hopsCountries
  };
};

const mapActionsToProps = {
  getProvidersCountries,
  getHopsCountries
};

export default connect(mapStateToProps, mapActionsToProps)(MapChart);
