import React from 'react';
import { TableColumn } from 'react-data-table-component';
import { PartnerSummary, PartnerTbStats, PartnerTfStats } from '../../interfaces/partners';

export const partnerSummaryTableColumns: TableColumn<PartnerSummary>[] = [
  {
    name: '',
    width: '20px',
    cell: () => <div />
  },
  {
    name: 'Partners',
    selector: () => 'requestorName',
    sortable: true,
    grow: 3,
    width: '300px',
    cell: (partnerSummary: PartnerSummary) => (
      <span className="table-cell gray center">{partnerSummary.requestorName}</span>
    )
  },
  {
    name: 'Tracebacks',
    selector: () => 'tracebacksCount',
    sortable: true,
    grow: 1,
    cell: (partnerSummary: PartnerSummary) => (
      <span className="table-cell gray center">{partnerSummary.tracebacksCount}</span>
    )
  },
  {
    name: 'Traceforwards',
    selector: () => 'traceforwardsCount',
    sortable: true,
    grow: 1,
    cell: (partnerSummary: PartnerSummary) => (
      <span className="table-cell gray center">{partnerSummary.traceforwardsCount}</span>
    )
  },
  {
    name: 'Orig',
    selector: () => 'origCount',
    sortable: true,
    grow: 1,
    cell: (partnerSummary: PartnerSummary) => (
      <span className="table-cell gray center">{partnerSummary.origCount}</span>
    )
  },
  {
    name: 'No Resp',
    selector: () => 'noRespCount',
    sortable: true,
    grow: 1,
    cell: (partnerSummary: PartnerSummary) => (
      <span className="table-cell gray center">{partnerSummary.noRespCount}</span>
    )
  },
  {
    name: 'Not Found',
    selector: () => 'notFoundCount',
    sortable: true,
    grow: 1,
    cell: (partnerSummary: PartnerSummary) => (
      <span className="table-cell gray center">{partnerSummary.notFoundCount}</span>
    )
  },
  {
    name: 'Average Time to Complete',
    selector: () => 'averageTime',
    sortable: true,
    grow: 3,
    cell: (partnerSummary: PartnerSummary) => (
      <span className="table-cell gray center">{partnerSummary.averageTime}</span>
    )
  },
  {
    name: 'Campaigns',
    selector: () => 'campaignsCount',
    sortable: true,
    grow: 1,
    cell: (partnerSummary: PartnerSummary) => (
      <span className="table-cell gray center">{partnerSummary.campaignsCount}</span>
    )
  }
];

export const partnersTbStatsTableColumns: TableColumn<PartnerTbStats>[] = [
  {
    name: '',
    width: '20px',
    cell: () => <div />
  },
  {
    name: 'Campaign',
    selector: () => 'campaignName',
    sortable: true,
    grow: 3,
    width: '300px',
    cell: (stats: PartnerTbStats) => (
      <span className="table-cell blue">
        <a href={`/campaigns/campaign/${stats.campaignId}`}>{stats.campaignName}</a>
      </span>
    )
  },
  {
    name: 'Traceback',
    selector: () => 'tracebackId',
    sortable: true,
    grow: 1,
    cell: (stats: PartnerTbStats) => (
      <span className="table-cell blue center">
        <a href={`/tracebacks/traceback/${stats.tracebackId}`}>{stats.tracebackId}</a>
      </span>
    )
  },
  {
    name: 'Event',
    selector: () => 'eventId',
    sortable: true,
    grow: 1,
    cell: (stats: PartnerTbStats) => (
      <span className="table-cell blue center">
        <a href={`/partners/event/${stats.eventId}`}>{stats.eventId}</a>
      </span>
    )
  },
  {
    name: 'Create Date',
    selector: () => 'create_date',
    sortable: true,
    grow: 2,
    cell: (stats: PartnerTbStats) => <span className="table-cell gray">{stats.create_date}</span>
  },
  {
    name: 'Completed Date',
    selector: () => 'completed_date',
    sortable: true,
    grow: 2,
    cell: (stats: PartnerTbStats) =>
      stats.status === 'Pending' ? (
        <span className="table-cell gray center">-</span>
      ) : (
        <span className="table-cell gray center">{stats.completed_date}</span>
      )
  },
  {
    name: 'Status',
    selector: () => 'status',
    sortable: true,
    grow: 1,
    cell: (stats: PartnerTbStats) => <span className="table-cell gray center">{stats.status}</span>
  },
  {
    name: 'Elapsed',
    selector: () => 'elapsed',
    sortable: true,
    grow: 2,
    cell: (stats: PartnerTbStats) => <span className="table-cell gray center">{stats.elapsed}</span>
  }
];

export const partnersTfStatsTableColumns: TableColumn<PartnerTfStats>[] = [
  {
    name: '',
    width: '20px',
    cell: () => <div />
  },
  {
    name: 'Campaign',
    selector: () => 'campaignName',
    sortable: true,
    grow: 3,
    width: '300px',
    cell: (stats: PartnerTfStats) => (
      <span className="table-cell blue">
        <a href={`/campaigns/campaign/${stats.campaignId}`}>{stats.campaignName}</a>
      </span>
    )
  },
  {
    name: 'Traceforward',
    selector: () => 'traceforwardId',
    sortable: true,
    grow: 1,
    cell: (stats: PartnerTfStats) => (
      <span className="table-cell blue center">
        <a href={`/traceforwards/traceforward/${stats.traceforwardId}`}>{stats.traceforwardId}</a>
      </span>
    )
  },
  {
    name: 'Event',
    selector: () => 'eventId',
    sortable: true,
    grow: 1,
    cell: (stats: PartnerTfStats) => (
      <span className="table-cell blue center">
        <a href={`/partners/event/${stats.eventId}`}>{stats.eventId}</a>
      </span>
    )
  },
  {
    name: 'Create Date',
    selector: () => 'create_date',
    sortable: true,
    grow: 2,
    cell: (stats: PartnerTfStats) => <span className="table-cell gray">{stats.create_date}</span>
  },
  {
    name: 'Completed Date',
    selector: () => 'completed_date',
    sortable: true,
    grow: 2,
    cell: (stats: PartnerTfStats) =>
      stats.status === 'Pending' ? (
        <span className="table-cell gray center">-</span>
      ) : (
        <span className="table-cell gray center">{stats.completed_date}</span>
      )
  },
  {
    name: 'Status',
    selector: () => 'status',
    sortable: true,
    grow: 1,
    cell: (stats: PartnerTfStats) => <span className="table-cell gray center">{stats.status}</span>
  },
  {
    name: 'Elapsed',
    selector: () => 'elapsed',
    sortable: true,
    grow: 2,
    cell: (stats: PartnerTfStats) => <span className="table-cell gray center">{stats.elapsed}</span>
  }
];
