import {
  CREATE_EVENT,
  DELETE_EVENT,
  GET_ALL_PARTNER_STATS,
  GET_ALL_PARTNER_TFSTATS,
  GET_ALL_PARTNERS,
  GET_PARTNER,
  GET_PARTNERS,
  GET_TFPARTNER,
  RESET_REDIRECT_ERROR,
  SET_PARTNERS_ERROR_MESSAGE,
  SET_PARTNERS_LOADING_STATUS,
  UPDATE_EVENT
} from './actionEnum';

const defaultState = {
  partnersList: [],
  partnersSummary: [],
  partnersPaginationMeta: {},
  partnerStats: [],
  partnerTfStats: [],
  loading: 0,
  error: '',
  redirectTo: ''
};

const reducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case GET_PARTNERS:
      return {
        ...state,
        partnersSummary: action.payload.data,
        partnersPaginationMeta: action.payload.paginationMeta
      };

    case GET_ALL_PARTNERS:
      return {
        ...state,
        partnersList: action.payload.data
      };

    case GET_PARTNER:
      return {
        ...state,
        partnerStats: action.payload.data
      };

    case GET_ALL_PARTNER_STATS:
      return {
        ...state,
        partnerStats: action.payload.data
      };

    case GET_TFPARTNER:
      return {
        ...state,
        partnerTfStats: action.payload.data
      };

    case GET_ALL_PARTNER_TFSTATS:
      return {
        ...state,
        partnerTfStats: action.payload.data
      };

    case CREATE_EVENT:
      return {
        ...state,
        partnerStats: [],
        partnerTfStats: [],
        redirectTo: action.payload.data ? `/partners/event/${action.payload.data}` : '/partners'
      };

    case UPDATE_EVENT:
      return {
        ...state,
        partnerStats: [],
        partnerTfStats: [],
        redirectTo: '/partners'
      };

    case DELETE_EVENT:
      return {
        ...state,
        partnerStats: [],
        partnerTfStats: [],
        redirectTo: '/partners'
      };

    case SET_PARTNERS_LOADING_STATUS:
      if (action.payload) return { ...state, loading: state.loading + 1 };
      else return { ...state, loading: state.loading === 0 ? state.loading : state.loading - 1 };

    case SET_PARTNERS_ERROR_MESSAGE:
      return { ...state, error: action.payload };

    case RESET_REDIRECT_ERROR:
      return {
        ...state,
        redirectTo: '',
        error: ''
      };

    default:
      return state;
  }
};

export default reducer;
