import React from 'react';
import BaseCard from '../components/BaseCard';

const Privacy = () => {
  return (
    <div className="container-fluid px-md-5">
      <BaseCard containerClassnames="card-join" headerTitle="Privacy Policy">
        <div className="row p-4 justify-content">
          <div className="col-lg-6">
            <h6 className="font-weight-bold">Last Updated/Effective: August 30, 2023.</h6>

            <p>
              The Industry Traceback Group, LLC (ITG), a wholly-owned subsidiary of USTelecom – The
              Broadband Association, operates the Secure Traceback Portal (STP) located at{' '}
              <a
                className="base-link"
                href="https://portal.tracebacks.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://portal.tracebacks.org/
              </a>{' '}
              as part of the industry effort to protect voice networks and consumers from
              fraudulent, abusive, and unlawful calls, including illegal robocalls. The STP is
              intended to be exclusively used to identify the source of and ultimately stop such
              calls through industry traceback efforts.
            </p>
            <p>
              This Privacy Policy explains our collection, use, and sharing of information from you
              in connection with the STP.
            </p>
            <h6 className="text-ust-green">
              <i>Information Collected</i>
            </h6>
            <p>
              We collect limited personal information directly from you in order to support the
              function of the STP and to allow you access to the STP. You may be required to provide
              information about yourself or your company in order to provide incident reports or
              otherwise communicate about a traceback request regarding a suspected fraudulent,
              abusive or unlawful robocall. Such information may include your first and last name,
              email address, professional title, phone number, and employer information. You may
              update, correct or delete your personal information when logged into your account.
            </p>
            <p>
              As part of the traceback process, we also may request that you provide information
              related to a suspected illegal call. Any such information you provide will be used in
              compliance with Section 222 of the Communications Act. For more information about the
              traceback process and how we use and share traceback results, please review our{' '}
              <a
                className="base-link"
                href="https://tracebacks.org/itg-policies-and-procedures/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Policies and Procedures
              </a>
              .
            </p>
            <p>
              In addition, we may collect information indirectly about you through your use of the
              STP, such as tracking how often you access the STP and the reports you create when
              using the STP. We also may automatically log your IP addresses, device ID, session
              sources, and other data to understand how the STP is being used and for monitoring and
              improving the STP and authenticating and verifying users of the STP. We also may use
              cookies and similar tracking mechanism for such purposes.
            </p>
            <h6 className="text-ust-green">
              <i>Information Use and Sharing</i>
            </h6>
            <p>
              We use and share the information you provide as needed to operate and improve the STP,
              conduct tracebacks, and otherwise seek to stop illegal calls. For instance, we may
              disclose certain information contained in the STP publicly or share with law
              enforcement, regulatory agencies, and/or other users as we deem appropriate to best
              effectuate the ITG's mission to identify and stop illegal calls, including illegal
              robocalls, as permitted by law. We also share information with third-party consultants
              and service providers that support the functionality, operation, and development of
              the ITG and STP on our behalf.
            </p>
            <p>
              In addition, we may disclose information we collect if required to do so by law or if
              we, in good faith, believe that disclosure is necessary to (i) comply with a legal
              obligation; (ii) protect and defend our rights or property; (iii) act in emergency
              situations to protect personal safety or the safety of the public; or (iv) protect
              against legal liability.
            </p>
            <p>Finally, we also may share aggregated information.</p>
            <h6 className="text-ust-green">
              <i>Information Protection</i>
            </h6>
            <p>
              We take reasonable steps to protect the information we collect against the loss,
              misuse and alternation. We have put in place appropriate physical, electronic, and
              managerial procedures and controls to safeguard and secure such information.
            </p>
            <h6 className="text-ust-green">
              <i>Changes to this Privacy Policy</i>
            </h6>
            <p>
              We may change this Privacy from time to time. The most current version of our Privacy
              Policy will be located at{' '}
              <a
                className="base-link"
                href="https://portal.tracebacks.org/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://portal.tracebacks.org/privacy
              </a>
              .
            </p>
            <h6 className="text-ust-green">
              <i>Contacting the Industry Traceback Group</i>
            </h6>
            <p>If you have any questions about this privacy statement or website, please contact</p>
            <p>
              Industry Traceback Group
              <br />
              c/o
              <br />
              USTelecom – The Broadband Association
              <br />
              601 New Jersey Avenue, NW
              <br />
              Suite 600
              <br />
              Washington, DC 20001
              <br />
              <a
                className="base-link"
                href="mailto:legal@tracebacks.org ?subject=Traceback%20Platform%20Inquiry"
              >
                legal@tracebacks.org
              </a>
              <br />
            </p>
          </div>
        </div>
      </BaseCard>
    </div>
  );
};

export default Privacy;
