import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { userTypes } from '../enum/userTypes';
import { Reputation } from '../interfaces/reputation';
import { PersonalInfo } from '../interfaces/user';
import { stateMappings } from '../redux/stateMappings';
import { HoverCardContent, HoverCardRoot, HoverCardTrigger } from './HoverCard';

export const tbStatus = (state: number) => {
  let tracebackStatus =
    state === 1
      ? 'OPEN'
      : state === 2
        ? 'COMPLETED'
        : state === 3
          ? 'NOT FOUND'
          : state === 4
            ? 'NO RESPONSE'
            : state === 5
              ? 'ARCHIVED'
              : 'NONE';

  return tracebackStatus;
};

interface Props {
  reputation?: Reputation;
  user: PersonalInfo;
}

const ReputationDetails: React.FC<Props> = ({ reputation, user }) => {
  return reputation ? (
    <HoverCardRoot openDelay={150}>
      <HoverCardTrigger asChild={true}>
        <span
          className={
            reputation.label === 'Yellow'
              ? 'fas fa-triangle-exclamation'
              : reputation.label === 'Orange'
                ? 'fa-solid fa-circle-stop orange'
                : ''
          }
          style={{ color: reputation.color }}
        />
      </HoverCardTrigger>
      <HoverCardContent sideOffset={5} style={{ fontSize: '16px' }}>
        {user.roleType === userTypes.GovUser ||
        user.roleType === userTypes.ProviderManager ||
        user.roleType === userTypes.Provider ? (
          <>
            {reputation.label === 'Yellow'
              ? 'Warning - identified with tracebacks of prerecorded or artificial illegal robocalls'
              : 'Alert - more frequently identified with tracebacks of prerecorded or artificial illegal robocalls'}
          </>
        ) : (
          <>
            SCORE - {reputation.score}
            {reputation.no_origin ? <br /> : null}
            {reputation.no_origin ? `ORG - ${reputation.no_origin}` : ''}
            {reputation.no_noResp ? <br /> : null}
            {reputation.no_noResp ? `NO RESP - ${reputation.no_noResp}` : ''}
            {reputation.no_poe ? <br /> : null}
            {reputation.no_poe ? `POE -  ${reputation.no_poe}` : ''}
            {reputation.no_dsOrigin ? <br /> : null}
            {reputation.no_dsOrigin ? `DS ORG -  ${reputation.no_dsOrigin}` : ''}
            {reputation.no_dsNr ? <br /> : null}
            {reputation.no_dsNr ? `DS NR - ${reputation.no_dsNr}` : ''}
          </>
        )}
      </HoverCardContent>
    </HoverCardRoot>
  ) : (
    <Fragment />
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return {
    user: sm.user
  };
};

export default connect(mapStateToProps)(ReputationDetails);
