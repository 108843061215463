import React from 'react';
import { Col, Row } from 'reactstrap';

import RmdInfoElement from './RmdInfoElement';

import { Provider } from '../interfaces/provider';
import { RMDEntry } from '../interfaces/rmd';

import { getProviderTypeName } from '../lib/utilities';

interface IProps {
  provider: Provider;
}

const GovProviderDetails: React.FC<IProps> = ({ provider }) => {
  return (
    <div className="d-flex flex-wrap justify-content-evenly section-provider m-0">
      <Col lg="3">
        <h5 className="traceback-sub-title">Contact</h5>
        <p className="ps-0 telecom-label">Manager Name</p>
        <p>{provider.contactName}</p>
        <p className="ps-0  telecom-label">Manager Email</p>
        <p>{provider.contactEmail}</p>
        <p className="ps-0  telecom-label">Manager Phone</p>
        <p>{!provider.contactPhone ? 'None' : provider.contactPhone}</p>
      </Col>

      <Col lg="6">
        <h5 className="traceback-sub-title">Address</h5>
        <Row className="custom-flex-column">
          <Col>
            <p className="ps-0 telecom-label">Country</p>
            <p>{provider.country}</p>
            <p className="ps-0  telecom-label">Address 1</p>
            <p>{!provider.address1 ? 'None' : provider.address1}</p>
            <p className="ps-0  telecom-label">Address 2</p>
            <p>{!provider.address2 ? 'None' : provider.address2}</p>
          </Col>
          <Col>
            <p className="ps-0 telecom-label">City</p>
            <p>{!provider.city ? 'None' : provider.city}</p>
            <p className="ps-0  telecom-label">State / Region</p>
            <p>{!provider.state ? 'None' : provider.state}</p>
            <p className="ps-0 telecom-label">Zip / Postal Code</p>
            <p>{provider.zipCode}</p>
          </Col>
        </Row>
      </Col>

      <Col lg="3">
        <h5 className="traceback-sub-title">Provider details</h5>
        <p className="ps-0  telecom-label">Provider Type</p>
        <p>{getProviderTypeName(provider)}</p>
        <p className="ps-0  telecom-label">FCC Filer</p>
        <p>{!provider.fccFiler ? 'None' : provider.fccFiler}</p>
        <p className="ps-0  telecom-label">RMD</p>
        {provider.RMDs ? (
          <div
            className={`rmdContainer ${provider.RMDs && provider.RMDs.length > 3 && 'bodyHeight'}`}
          >
            {provider.RMDs.map((item: RMDEntry) => (
              <RmdInfoElement rmdEntry={item} key={item.id} />
            ))}
          </div>
        ) : (
          <p className="text-red">NONE</p>
        )}
      </Col>
    </div>
  );
};

export default GovProviderDetails;
