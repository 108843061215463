export enum StirShaken {
  toggleStirShaken = 'toggleStirShaken',
  stirShakenToken = 'stirShakenToken',
  signedStirShaken = 'signedStirShaken',
  attestationRadio = 'attestationRadio',
  destinationNumber = 'destinationNumber',
  callingNumber = 'callingNumber',
  timeStamp = 'timeStamp',
  originationIdentifier = 'originationIdentifier',
  isCallSigner = 'isCallSigner'
}

export enum StirShakenStatus {
  StirShakenSigned = 0,
  StirShakenManual,
  StirShakenNotSigned,
  StirShakenMissing,
  StirShakenNotValid,
  NoStirShaken
}
