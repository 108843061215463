export enum Messaging {
  ADD_MESSAGE = 'ADD_MESSAGE',
  GET_MESSAGES = 'GET_MESSAGES',
  EDIT_MESSAGE = 'EDIT_MESSAGE',
  CANCEL_MESSAGE = 'CANCEL_MESSAGE',
  RELOAD_DATA = 'RELOAD_DATA',
  SET_LOADING = 'SET_LOADING',
  SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE',
  SET_MESSAGE = 'SET_MESSAGE',
  GET_EMAIL_EVENTS = ' GET_EMAIL_EVENTS'
}
