import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Label } from 'reactstrap';
import { DateFormat } from '../../enum/DateFormat';
import { DisputeInfo, emptyCampaignAttributes } from '../../interfaces/campaign';
import { DisputeStatus, Hop, HopDispute } from '../../interfaces/hop';
import { Traceback } from '../../interfaces/traceback';
import { PersonalInfo } from '../../interfaces/user';
import { getClientFormattedDate, getElapsedTime } from '../../lib/utilities';
import { addCommentObject } from '../../redux/comment/thunks';
import { stateMappings } from '../../redux/stateMappings';
import Dispute, { attributesExistAndAllowed } from './Dispute';
import ResolveDispute from './ResolveDispute';

interface IProps {
  hop: Hop;
  traceback: Traceback;
  dispute: HopDispute | null;
  updateHopObject: Function;
  user: PersonalInfo;
}
interface INprops {
  hop: Hop;
  traceback: Traceback;
}
interface ICprops {
  hop: Hop;
  traceback: Traceback;
  dispute: HopDispute | null;
  updateHopObject: Function;
  user: PersonalInfo;
}
const NFnrSubmit: React.FC<INprops> = ({ hop, traceback }) => (
  <div className="highlighted-background mb-3">
    <h5 className="p-0 traceback-sub-title mb-3">Call Source Details</h5>
    <div className="row mb-1">
      <div className="col-6 col-md-3">
        <label className="telecom-label">Reason</label>
        {hop.nfNr === 'isRecordUn' ? (
          <span>Record Unavailable</span>
        ) : hop.nfNr === 'isCallOld' ? (
          <span>Call is too old</span>
        ) : hop.nfNr === 'isRefusing' ? (
          <span>We refuse to share this information</span>
        ) : (
          <span>Provider is non-responsive</span>
        )}
      </div>
      <div className="col-6 col-md-3">
        <label className="telecom-label">Date & Time Completed</label>
        {getClientFormattedDate(hop.completed, DateFormat.MediumBoth)}
      </div>
      <div className="col-6 col-md-3">
        <label className="telecom-label">Total Time Elapsed</label>
        {getElapsedTime(traceback.elapsed)}
      </div>
    </div>
    <div>
      <label className="telecom-label">Explanation</label>
      {hop.nfnrReason}
    </div>
  </div>
);

interface FProps {
  field: string | undefined;
  reason: string | undefined;
}
const FieldOrReason: React.FC<FProps> = ({ field, reason }) =>
  field ? <span>{field}</span> : <span className="text-secondary">{capitalize(reason || '')}</span>;

const capitalize = (words: string) =>
  words
    .split('_')
    .map((w: string) => w.substring(0, 1).toUpperCase() + w.substring(1))
    .join(' ');

const CustomerSubmitForm: React.FC<ICprops> = ({ hop, traceback, dispute, updateHopObject }) => {
  const [disputeInfo, setDisputeInfo] = useState<DisputeInfo>({
    disputeVisible: false,
    attributes: emptyCampaignAttributes(),
    comment: '',
    consentGiven: false,
    attachments: []
  });
  const canSubmit = (): boolean => {
    return disputeInfo.consentGiven
      ? !!disputeInfo.comment || (disputeInfo.attachments && disputeInfo.attachments.length > 0)
      : true;
  };
  const submitDispute = async () => {
    const hopToSubmit: any = {};
    if (disputeInfo.disputeVisible) {
      const commentId = await addCommentObject(
        {
          commentType: 'hop',
          contentText: 'Dispute: ' + disputeInfo.comment,
          relatedObjectId: hop.hopId,
          directedTo: 'admin'
        },
        disputeInfo.attachments
      );
      if (commentId && Number(commentId)) {
        const dispute: HopDispute = {
          hopId: hop.hopId,
          status: DisputeStatus.OpenDispute,
          commentId,
          attributes: disputeInfo.attributes,
          consentGiven: disputeInfo.consentGiven
        };
        hopToSubmit.dispute = dispute;
        hopToSubmit.hopId = hop.hopId;
        await updateHopObject(hopToSubmit);
      }
    }
  };
  return (
    <div className="highlighted-background mb-3">
      <h5 className="p-0 traceback-sub-title mb-3">Call Source Details</h5>
      <div className="row mb-1">
        <div className="col-6 col-md-4">
          <label className="telecom-label">Business Name</label>
          {hop.customer}
        </div>
        <div className="col-6 col-md-4">
          <label className="telecom-label">Country</label>
          {hop.country}
        </div>
        <div className="col-6 col-md-4">
          <label className="telecom-label">Address</label>
          <FieldOrReason field={hop.address} reason={hop.refuseReason} />
        </div>
      </div>
      <div className="row mb-1">
        <div className="col-12 col-md-4">
          <label className="telecom-label">Contact Email</label>
          {hop.contactEmail}
          {(hop.contactEmailValidation.status === 'invalid' ||
            hop.contactEmailValidation.aws_reason) && (
            <span className="ms-2" style={{ color: 'red' }}>
              {hop.contactEmailValidation.aws_reason
                ? 'SES Blocked'
                : hop.contactEmailValidation.status}{' '}
              {hop.contactEmailValidation.domain}
            </span>
          )}
        </div>
        <div className="col-6 col-md-4">
          <label className="telecom-label">IP Address</label>
          {hop.ipAddress}
        </div>
        <div className="col-6 col-md-4">
          <label className="telecom-label">City</label>
          <FieldOrReason field={hop.city} reason={hop.refuseReason} />
        </div>
      </div>
      <div className="row mb-1">
        <div className="col-6 col-md-4">
          <label className="telecom-label">Contact Phone</label>
          {hop.contactPhone}
        </div>
        <div className="col-6 col-md-4">
          <label className="telecom-label">Customer Type</label>
          {hop.customerType}
        </div>
        <div className="col-6 col-md-4">
          <label className="telecom-label">State / Region</label>
          <FieldOrReason field={hop.state} reason={hop.refuseReason} />
        </div>
      </div>
      <div className="row mb-1">
        <div className="col-6 col-md-4">
          <label className="telecom-label">Contact Name</label>
          {hop.contactName}
        </div>
        <div className="col-6 col-md-4">
          <label className="telecom-label">Average Daily Traffic</label>
          {hop.averageTraffic}
        </div>
        <div className="col-6 col-md-4">
          <label className="telecom-label">Zip / Postal Code</label>
          <FieldOrReason field={hop.zipCode} reason={hop.refuseReason} />
        </div>
      </div>
      <div className="row mb-1">
        <div className="col-12 col-md-4">
          <label className="telecom-label">Both Originator+Calling Party</label>
          {hop.isCallOrig ? 'Yes' : 'No'}
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-md-4">
          <label className="telecom-label">Action Taken</label>
          {hop.actionTaken}
        </div>
        <div className="col-12 col-md-8">
          <label className="telecom-label">Steps Taken</label>
          {hop.explanation}
        </div>
      </div>
      {attributesExistAndAllowed(traceback.campaignAttributes) && dispute ? (
        <ResolveDispute hop={hop} campaignAttribute={traceback.campaignAttributes} />
      ) : (
        attributesExistAndAllowed(traceback.campaignAttributes) &&
        !dispute &&
        hop.isAllowedToDispute && (
          <Fragment>
            <div className="p-2">
              <Label className="form-label checkbox-label">
                Dispute <span className="text-secondary">Basis for Traceback</span>
                <input
                  type="checkbox"
                  onChange={() => {
                    setDisputeInfo((v) => ({ ...v, disputeVisible: !v.disputeVisible }));
                  }}
                  value="disputeVisible"
                  checked={disputeInfo.disputeVisible}
                />
                <span className="checkmark" />
              </Label>
            </div>
            {disputeInfo.disputeVisible && (
              <Fragment>
                <Dispute
                  campaignAttribute={traceback.campaignAttributes}
                  info={disputeInfo}
                  setDispute={setDisputeInfo}
                />
                <div className="d-flex justify-content-center">
                  <Button
                    className="telecom-btn"
                    style={{ minWidth: '130px', paddingBottom: '30px' }}
                    onClick={async () => await submitDispute()}
                    disabled={!canSubmit()}
                  >
                    Submit Dispute
                  </Button>
                </div>
              </Fragment>
            )}
          </Fragment>
        )
      )}
    </div>
  );
};

const CallSourceDetailsRow: React.FC<IProps> = ({
  hop,
  traceback,
  dispute,
  updateHopObject,
  user
}) => {
  return hop.status !== 1 ? (
    hop.customer ? (
      <CustomerSubmitForm
        hop={hop}
        traceback={traceback}
        dispute={dispute}
        updateHopObject={updateHopObject}
        user={user}
      />
    ) : hop.nfNr ? (
      <NFnrSubmit hop={hop} traceback={traceback} />
    ) : null
  ) : null;
};
const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return {
    dispute: sm.hop.hopDispute,
    user: sm.user
  };
};

export default connect(mapStateToProps)(CallSourceDetailsRow);
