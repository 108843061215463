import { Hops } from './actionEnum';

export const getHops = (response: any) => ({
  type: Hops.GET_HOPS,
  payload: response
});

export const getHop = (response: any) => ({
  type: Hops.GET_HOP,
  payload: response
});

export const addHop = (response: any) => ({
  type: Hops.ADD_HOP,
  payload: response
});

export const editHop = (response: any) => ({
  type: Hops.EDIT_HOP,
  payload: response
});

export const getAttachments = (response: any) => ({
  type: Hops.GET_ATTACHMENTS,
  payload: response
});

export const addAttachments = (response: any) => ({
  type: Hops.ADD_ATTACHMENTS,
  payload: response
});

export const setLoadingStatus = (candidate: boolean) => ({
  type: Hops.SET_HOP_LOADING_STATUS,
  payload: candidate
});

export const setErrorMessage = (message: string) => ({
  type: Hops.SET_HOP_ERROR_MESSAGE,
  payload: message
});

export const getHopStatuses = (response: any) => ({
  type: Hops.GET_HOP_STATUSES,
  payload: response
});

export const getHopComments = (response: any) => ({
  type: Hops.GET_HOP_COMMENTS,
  payload: response
});

export const getHopsCount = (response: any) => ({
  type: Hops.GET_HOPS_COUNT,
  payload: response
});

export const getOriginStatsAction = (response: any) => ({
  type: Hops.GET_ORIGIN_ACTION_STATS,
  payload: response
});

export const getHopsCountriesAction = (response: any) => ({
  type: Hops.GET_HOPS_COUNTRIES,
  payload: response
});

export const setInvalidHopId = (id: number | null) => ({
  type: Hops.SET_INVALID_HOP_ID,
  payload: id
});

export const getDisputeAction = (response: any) => ({
  type: Hops.GET_DISPUTE,
  payload: response
});
