import { Dno } from './actionsEnum';
import { DNO } from '../../interfaces/dno';

export const getAllDnoAction = (dnos: any) => ({
  type: Dno.GET_ALL_DNO,
  payload: dnos.data
});

export const getDnoAction = (dno: DNO) => ({
  type: Dno.GET_DNO,
  payload: dno
});

export const addNewDnoAction = (dno: DNO) => ({
  type: Dno.ADD_NEW_DNO,
  payload: dno
});

export const setDnoAction = (dno: any) => ({
  type: Dno.SET_DNO,
  payload: dno
});

export const setDnoErrorMessage = (error: string) => ({
  type: Dno.SET_DNO_ERROR_MESSAGE,
  payload: error
});

export const setDnoLoadingStatus = (loading: boolean) => ({
  type: Dno.SET_DNO_LOADING_STATUS,
  payload: loading
});
